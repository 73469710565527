import React from "react";

export interface SelfGuidedEventIndicatorProps {
    className?: string
}

export default function SelfGuidedEventIndicator(props: SelfGuidedEventIndicatorProps): JSX.Element {
    return (<>
        <svg version="1.1" viewBox="0 0 495 495" className={props.className}>
            <g>
                <path
                    d="M422.509,72.491C375.762,25.745,313.609,0,247.5,0S119.238,25.745,72.491,72.491C25.745,119.238,0,181.391,0,247.5   s25.745,128.262,72.491,175.009C119.238,469.255,181.39,495,247.5,495s128.262-25.745,175.009-72.491   C469.255,375.762,495,313.61,495,247.5S469.255,119.238,422.509,72.491z M411.902,411.902C367.989,455.816,309.603,480,247.5,480   s-120.489-24.184-164.402-68.098C39.184,367.989,15,309.603,15,247.5S39.184,127.011,83.098,83.098   C127.011,39.184,185.397,15,247.5,15s120.489,24.184,164.402,68.098C455.816,127.011,480,185.397,480,247.5   S455.816,367.989,411.902,411.902z"/>
                <path
                    d="M399.881,95.119C359.179,54.416,305.062,32,247.5,32S135.821,54.416,95.118,95.119C54.416,135.821,32,189.938,32,247.5   s22.416,111.679,63.118,152.382C135.821,440.584,189.938,463,247.5,463s111.679-22.416,152.381-63.119S463,305.062,463,247.5   S440.584,135.821,399.881,95.119z M255,447.846V431.5c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16.345   C135.397,443.978,51.022,359.603,47.155,255H63.5c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H47.155   C51.022,135.397,135.397,51.022,240,47.155V63.5c0,4.142,3.358,7.5,7.5,7.5s7.5-3.358,7.5-7.5V47.155   C359.603,51.022,443.978,135.397,447.845,240H431.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16.345   C443.978,359.603,359.603,443.978,255,447.846z"/>
                <path
                    d="m383.5,240h-77.411l51.956-92.837c1.64-2.93 1.132-6.592-1.242-8.966-2.375-2.374-6.038-2.882-8.966-1.241l-92.837,51.964v-77.42c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v85.815l-14.296,8.002c-0.986,0.512-1.957,1.047-2.901,1.624l-.2,.112c-0.296,0.166-0.571,0.354-0.835,0.552-0.716,0.464-1.42,0.944-2.109,1.445l-30.854-30.854c-2.929-2.929-7.678-2.929-10.606,0-2.929,2.929-2.929,7.677 0,10.606l30.854,30.854c-1.378,1.898-2.617,3.901-3.707,5.995-0.04,0.066-0.084,0.127-0.122,0.195l-7.922,14.154h-85.802c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h77.407l-51.952,92.837c-1.639,2.929-1.132,6.591 1.241,8.965 1.443,1.444 3.364,2.198 5.306,2.198 1.251,0 2.511-0.313 3.659-0.955l92.839-51.925v77.38c0,4.142 3.358,7.5 7.5,7.5s7.5-3.358 7.5-7.5v-85.77l15.361-8.592c0.112-0.063 0.216-0.135 0.323-0.203 1.613-0.906 3.168-1.904 4.658-2.986l30.854,30.854c1.464,1.465 3.384,2.197 5.303,2.197s3.839-0.732 5.303-2.197c2.929-2.929 2.929-7.678 0-10.606l-30.854-30.854c1.408-1.939 2.67-3.987 3.777-6.131 0.032-0.054 0.072-0.102 0.103-0.158l7.866-14.054h85.806c4.142,0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5zm-153.095-20.124l2.527-1.415c4.385-2.209 9.332-3.461 14.568-3.461 17.92,0 32.5,14.579 32.5,32.5s-14.58,32.5-32.5,32.5-32.5-14.579-32.5-32.5c0-11.652 6.167-21.886 15.405-27.624zm62.357,13.223c-4.664-14.624-16.229-26.191-30.851-30.857l70.071-39.221-39.22,70.078zm-90.525,28.799c4.667,14.64 16.252,26.218 30.896,30.876l-70.123,39.221 39.227-70.097z"/>
                <path
                    d="m247.5,263c8.547,0 15.5-6.953 15.5-15.5s-6.953-15.5-15.5-15.5-15.5,6.953-15.5,15.5 6.953,15.5 15.5,15.5zm0-16c0.276,0 0.5,0.224 0.5,0.5s-0.224,0.5-0.5,0.5-0.5-0.224-0.5-0.5 0.224-0.5 0.5-0.5z"/>
            </g>
        </svg>
    </>);
}