import { useState, useEffect } from "react";
import getPreferredContact from '../api/getPreferredContact'

const useGetPreferredContact = (): [any | undefined, boolean] => {
    const [preferredContact, setPreferredContact] = useState<any | undefined>({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getPreferredContact()
            .then(data => {
                setPreferredContact(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [preferredContact, isLoading]
}

export default useGetPreferredContact
