import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { DataGatheringAnswer } from '../pages/newMemberRegistration/types';

const GET_CUSTOM_ENROLLMENT_INFO_URL = '/get-custom-enrollment-info'

export interface CustomEnrollmentUserActivityRecord {
    year: number
    month: number
    day: number
    activityTotalInPerson: number
    activityTotalVirtual: number
}

export interface CustomEnrollmentUserActivities {
    activityRecords: CustomEnrollmentUserActivityRecord[]
}

export interface CustomEnrollmentInfo {
    firstName?: string
    lastName?: string
    userReportedActiveMembership: boolean
    memberId?: string
    externalActivityProfileUrl?: string
    agreementsAgreedTo?: string[]
    userActivities: CustomEnrollmentUserActivities,
    additionalEnrollmentQuestionsAnswers?: DataGatheringAnswer[]
}

const getCustomEnrollmentInfo = async (shortCode: string): Promise<CustomEnrollmentInfo | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_CUSTOM_ENROLLMENT_INFO_URL}/${shortCode}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export default getCustomEnrollmentInfo
