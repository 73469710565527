import {loadConfig} from "../services/ConfigService";

export interface SanityTextObject {
    _type: string;
    children: SanityTextObjectChildren[];
    markDefs: SanityMarkDefs[];
}

export interface SanityMarkDefs {
    _key: string;
    _type: string;
    href: string;
}

export interface SanityTextObjectChildren {
    text: string;
    marks: string[];
}

export interface SanityBackgroundColorObject {
    _type: string;
    hex: string;
    alpha: number;
}

export const getSanityResponse = async (query: string): Promise<any | undefined> => {
    const config = await loadConfig();
    // If sanityProductionModeEnabled is true, then we are in production mode and should not use the previewDrafts perspective and use the CDN-distributed, cached API.
    const projectUrl = `https://${config.sanityProjectId}.${config.sanityProductionModeEnabled ? "apicdn" : "api"}.sanity.io/v2021-10-21/data/query/${config.sanityDataset}?query=${query}&perspective=${config.sanityProductionModeEnabled ? "published" : "previewDrafts"}`;
    const request = new Request(projectUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await fetch(request);

    if (response?.ok) {
        return response.json();
    }
}
