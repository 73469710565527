import {
  getSanityResponse,
  SanityBackgroundColorObject,
  SanityTextObject,
} from './SanityUtils';
import { ClubAssociationAgreementSanityData } from './getClubAssociationAgreementViaSanity';
import i18next from 'i18next';

export interface ClubAssociationSanityResponse {
  ms: number;
  query: string;
  result: ClubAssociationSanityData[];
}

export interface ClubAssociationSanityData {
  _id: string;
  slug: string;
  title: string;
  name: string;
  shortName: string;
  associationLogoUrl: string;
  associationLogoLightUrl: string;
  associationLandingPage: ClubAssociationLandingPageSanityData[];
  associationFaqPage: ClubAssociationFaqPageSanityData[];
  associationEnrollmentConfiguration: ClubAssociationEnrollmentConfigurationSanityData[];
  associationImageUrl: string;
}

export interface ClubAssociationLandingPageSanityData {
  additionalContent: SanityTextObject[];
  backgroundColor: SanityBackgroundColorObject;
  faqRef: string;
  heroBannerImageUrl: string;
  heroImageUrl: string;
  heroText: SanityTextObject[];
  whyYouShouldEnroll: string[];
  whyYouShouldEnrollHeaderImageUrls: string[];
  landingVideo: string;
  moonshotEnabled: boolean;
}

export interface ClubAssociationFaqPageSanityData {
  title: string;
  content: SanityTextObject[];
  heroBannerImageUrl: string;
}

export interface ClubAssociationEnrollmentConfigurationSanityData {
  activityCollectionEnabled: boolean;
  askForExternalActivityTrackerProfileUrl: boolean;
  askIfCurrentMemberEnabled: boolean;
  confirmationPageExternalLinkEnabled: boolean;
  howDidYouHearAboutUsEnabled: boolean;
  enrollmentMessage: string;
  memberIdEnabled: boolean;
  memberIdRequired: boolean;
  memberIdFieldLabel: string;
  memberIdHelpOverlayContent: SanityTextObject[];
  referralCodeEnabled: boolean;
  referralCodeRequired: boolean;
  confirmationPageContent: SanityTextObject[];
  confirmationPageContinueButtonText: string;
  confirmationPageExternalLinkUrl: string;
  externalActivityTrackerProfileUrlHelpOverlay: SanityTextObject[];
  userAgreementsToShow: ClubAssociationAgreementSanityData[];
  reportActivityPageContent: SanityTextObject[];
  dataGatheringQuestionCheckboxEnabled: boolean;
  dataGatheringQuestionCheckboxLabel: string;
}

const getClubAssociationContentViaSanity = async (
  shortCode: string,
): Promise<ClubAssociationSanityResponse | undefined> => {
  const LANGUAGE = i18next.language?.split('-')[0] ?? 'en';
  const query = encodeURIComponent(`
        *[_type == "association" && lower(slug) == "${shortCode}"]
        {
            title, slug, _id, name, shortName,
            "associationLandingPage": *[_type == "association_landing_page" && references(^._id)]
            {
                additionalContent, heroText, whyYouShouldEnroll, backgroundColor, moonshotEnabled,
                "faqRef": faq._ref,
                "heroBannerImageUrl": heroBannerImage.asset->url,
                "heroImageUrl": heroImage.asset->url,
                "whyYouShouldEnrollHeaderImageUrls": whyYouShouldEnrollHeaderImages[].asset->url,
                "landingVideo": landingVideo.asset->url,
            },
            "associationFaqPage": *[_type == "association_faq_page" && references(^._id)]
            {
                title, content,
                "heroBannerImageUrl": heroBannerImage.asset->url
            },
            "associationEnrollmentConfiguration":  *[_type == "association_enrollment_configuration" && references(^._id)]
            {
                activityCollectionEnabled, askForExternalActivityTrackerProfileUrl,
                  askIfCurrentMemberEnabled, confirmationPageExternalLinkEnabled,
                  howDidYouHearAboutUsEnabled, enrollmentMessage, memberIdEnabled, 
                  memberIdRequired, memberIdFieldLabel, memberIdHelpOverlayContent,
                  referralCodeEnabled, referralCodeRequired,
                  confirmationPageContent, confirmationPageContinueButtonText, confirmationPageExternalLinkUrl,
                  externalActivityTrackerProfileUrlHelpOverlay,
                  "reportActivityPageContent": coalesce(reportActivityPageContent[_key == "${LANGUAGE}"][0].value, reportActivityPageContent),
                  dataGatheringQuestionCheckboxEnabled,
                  "dataGatheringQuestionCheckboxLabel": coalesce(dataGatheringQuestionCheckboxLabel.${LANGUAGE}, dataGatheringQuestionCheckboxLabel),
                  "userAgreementsToShow": userAgreementsToShow[]->
            },
            "associationLogoUrl": associationLogo.asset->url,
            "associationLogoLightUrl": associationLogoLight.asset->url,
            "associationImageUrl": associationImage.asset->url
        }
    `);

  return getSanityResponse(query);
};

export default getClubAssociationContentViaSanity;
