import React from "react"
import { USER_ACTIVITY_SOURCE_TYPE } from "../../../../constants/dashboard"
import MonitorIcon from '@material-ui/icons/Tv';
import PersonIcon from '@material-ui/icons/Person';
import { IActivityRecord } from "../../../../types/dashboard";
import { useTranslation } from "react-i18next";
import {
    DateTimeFormatter,
    OffsetDateTime
} from "@js-joda/core";

const getTooltipText = (source: USER_ACTIVITY_SOURCE_TYPE | undefined, t: Function) =>
    source === USER_ACTIVITY_SOURCE_TYPE.MEMBER ? t('userActivitySourceType.selfReported') :
        source === USER_ACTIVITY_SOURCE_TYPE.LEADER ? t('userActivitySourceType.clubReported') :
            t('userActivitySourceType.autoReported')

const getFormattedDate = (date: string | undefined) => {
    if (date !== undefined) {
        return OffsetDateTime.parse(date).format(DateTimeFormatter.ofPattern('M/d/yy'))
    } else {
        return null
    }
}

export const TooltipTitle = ({ currentDateRecord }: { currentDateRecord?: IActivityRecord }) => {
    const { t } = useTranslation('ignite/dashboard');
    const isVirtualPresent = currentDateRecord?.sourceTypesByActivityType.VIRTUAL !== undefined
    const isInPersonPresent = currentDateRecord?.sourceTypesByActivityType.IN_PERSON !== undefined
    const virtualTooltipText = getTooltipText(currentDateRecord?.sourceTypesByActivityType.VIRTUAL, t)
    const inPersonTooltipText = getTooltipText(currentDateRecord?.sourceTypesByActivityType.IN_PERSON, t)
    const inPersonFormattedDate = getFormattedDate(currentDateRecord?.modificationDateInPerson)
    const virtualFormattedDate = getFormattedDate(currentDateRecord?.modificationDateVirtual)
    var inPersonDateTooltipText = t('inPersonTooltipDate', { inPersonFormattedDate })
    var virtualDateTooltipText = t('virtualTooltipDate', { virtualFormattedDate })

    return <div>
        {isVirtualPresent && <div className="tooltip-item">
            <MonitorIcon className="icon" />
            {virtualTooltipText} <br />
            {virtualDateTooltipText}
        </div>}
        {isInPersonPresent && <div className="tooltip-item">
            <PersonIcon className="icon" />
            {inPersonTooltipText}<br />
            {inPersonDateTooltipText}
        </div>}
    </div>
}