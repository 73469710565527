import { loadConfig } from '../services/ConfigService'
import { IClub } from '../types/clubFinder';

export const CLUB_BY_ID_URL = '/ignite/club/get/'

const getClubById = async (id: number | undefined): Promise<IClub | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${CLUB_BY_ID_URL}${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await fetch(request);

    if (response.ok) {
        return response.json()
    }
}

export default getClubById
