import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './AcblLandingPage.scss';
import '../clubLeaderLanding/ClubLeaderLanding.scss';
import Button, { ButtonStyles } from '../../components/common/Button';
import { UserContext } from '../../contexts/UserContext';
import { storeRegistrationParametersToSession } from '../../util/Util';
import ArrowButton from '../../components/icon/ArrowButton';
import Plus from '../../components/icon/Plus';
import ArrowText from '../../components/icon/ArrowText';
import { UserAgreementModel } from '../../services/Models';
import { getUserAgreement } from '../../services/UserAgreementService';
import getClubAssociationContentViaSanity, {
  ClubAssociationLandingPageSanityData,
} from '../../sanity/getClubAssociationContentViaSanity';
import PortableText from 'react-portable-text';
import logo from '../../assets/logo.svg';
import { Box } from '@material-ui/core';
import Loading from '../../components/common/Loading';

/**
 * A page with static content that contains a link to register as a member.
 */
export default function AcblLandingPage(
  props: RouteComponentProps,
): JSX.Element {
  const { t } = useTranslation('pages');
  const { history } = props;
  const { user } = useContext(UserContext);
  const [userAgreement, setUserAgreement] = useState<UserAgreementModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageConfiguration, setPageConfiguration] = useState<
    ClubAssociationLandingPageSanityData | undefined
  >();
  const [acblLogoUrl, setAcblLogoUrl] = useState<string | undefined>();
  const [associationLogoUrl, setAssociationLogoUrl] = useState<
    string | undefined
  >();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    storeRegistrationParametersToSession(history.location);
  }, []);

  useEffect(() => {
    (async () => {
      const data = await getClubAssociationContentViaSanity('acbl');
      setPageConfiguration(
        data?.result[0]?.associationLandingPage[0] ?? undefined,
      );
      setAcblLogoUrl(data?.result[0]?.associationLogoUrl);
      setAssociationLogoUrl(data?.result[0]?.associationImageUrl);
      setUserAgreement(
        await getUserAgreement({ slug: 'acbl-launch-sweepstakes' }),
      );
      setLoading(false);
    })();
  }, []);

  useEffect(() => {}, []);

  const onClickCheckEligibilityButton = () => {
    history.push('/acbl/new-registration');
  };

  const onClickLearnMoreButton = () => {
    window.open('/acbl/faq', '_blank');
  };

  if (loading) {
    return (
      <div style={{ marginTop: 24 }}>
        <Loading loading={loading} />
      </div>
    );
  }

  return (
    <div className="AcblLandingPage">
      <div className={'AcblLandingPage_Banner'}>
        <div className={'AcblLandingPage_Banner_Top1'}></div>
        <div className={'AcblLandingPage_Banner_Top2'}>
          <img
            className={'AcblLandingPage_Banner_Top_Logo'}
            src={acblLogoUrl ?? require('../../assets/acbl-logo-circle.png')}
          />
        </div>
        <div
          className={'AcblLandingPage_Banner_Bottom'}
          style={{
            backgroundImage: `url(${pageConfiguration?.heroBannerImageUrl})`,
          }}
        >
          <div className={'AcblLandingPage_Banner_Bottom_Body'}>
            <div className={'AcblLandingPage_Banner_Text'}>
              <PortableText content={pageConfiguration?.heroText ?? []} />
            </div>
          </div>
        </div>
      </div>
      <div className={'AcblLandingPage_Body'}>
        <div className={'AcblLandingPage_Body1'}>
          <div className={'AcblLandingPage_Body1_Info'}>
            <div className={'AcblLandingPage_Body1_Info_Text'}>
              {t('acblLandingPage.body1')}
            </div>
            <Button
              className={'AcblLandingPage_Banner_Bottom_Button'}
              clickHandler={onClickCheckEligibilityButton}
              type={'button'}
              buttonStyle={ButtonStyles.FilledPrimary}
            >
              {t('acblLandingPage.buttonText')}
              <ArrowButton
                className={'AcblLandingPage_Banner_Bottom_Button_Icon'}
              />
            </Button>
          </div>
          <div className={'AcblLandingPage_Body1_Logos'}>
            <div
              className={
                'AcblLandingPage_Body1_Logo AcblLandingPage_Body1_ACBLLogo'
              }
            />
            <Box
              sx={{
                display: { xs: 'grid', md: 'flex' },
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <img
                src={associationLogoUrl}
                className="AcblLandingPage_Body1_ACBLLogo"
              />
              <Plus className={'AcblLandingPage_Body1_Logos_Icon'} />
              <img src={logo} className="AcblLandingPage_Body1_GrouperLogo" />
            </Box>
          </div>
        </div>
        <div className={'AcblLandingPage_BodyDivider'} />
        <div className={'AcblLandingPage_Body2'}>
          <div className={'AcblLandingPage_Body2_TitleAndLink'}>
            <div className={'AcblLandingPage_Body2_Title'}>
              {t('acblLandingPage.body2Title')}
            </div>
            <div
              className={'AcblLandingPage_Body2_Link'}
              onClick={onClickLearnMoreButton}
            >
              <span className={'AcblLandingPage_Body2_Link_Text'}>
                {t('acblLandingPage.body2Link')}
              </span>
              <ArrowText className={'AcblLandingPage_Body2_Link_Icon'} />
            </div>
          </div>
          <div className={'AcblLandingPage_Body2_InfoSection'}>
            {pageConfiguration?.whyYouShouldEnroll?.map(
              (enrollContent: string, index) => (
                <div className={'AcblLandingPage_Body2_Info'}>
                  <img
                    className={'AcblLandingPage_Body2_Info_Icon'}
                    src={
                      pageConfiguration.whyYouShouldEnrollHeaderImageUrls[index]
                    }
                  />
                  <div className={'AcblLandingPage_Body2_Info_Text'}>
                    {enrollContent}
                  </div>
                </div>
              ),
            )}
          </div>
          <div
            className={
              'AcblLandingPage_Body2_Link AcblLandingPage_Body2_LinkBottom'
            }
            onClick={onClickLearnMoreButton}
          >
            <span className={'AcblLandingPage_Body2_Link_Text'}>
              {t('acblLandingPage.body2Link')}
            </span>
            <ArrowText className={'AcblLandingPage_Body2_Link_Icon'} />
          </div>
        </div>
      </div>
    </div>
  );
}
