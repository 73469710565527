import React, { useState, useEffect } from 'react'; // importing FunctionComponent
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import { useFormState } from "react-use-form-state";
import { loadConfig } from "../../../services/ConfigService";
import Loading from "../../../components/common/Loading";
import LoadingMessage from "../../../components/common/LoadingMessage";
import { FormControl } from '@material-ui/core';
import {
    FormHelperText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import PlayButton from "../../../components/common/PlayButton";
import { ButtonStyles } from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
    getAllPayersEventsPortal,
    GetPayerResponseBody
} from "../../../services/MemberService";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { LocalDateUtils } from "../../../util/FixedJsJodaUtils";
import { Locale } from "@js-joda/locale_en-us";
import {
    convert,
    DateTimeParseException,
    LocalDate
} from "@js-joda/core";
import { AcblRegistrationResponseBody } from "../../../services/UserService";
import InputMask from "react-input-mask";
import {getPhoneUnmask, PHONE_REGEX} from "../../../util/Util";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import { UserAgreementModel } from "../../../services/Models";

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void,
    acblMember: AcblRegistrationResponseBody | null
    userAgreement: UserAgreementModel
}

export function MemberSelectPayerStep(props: StepProp): JSX.Element {
    const {t} = useTranslation('pages');

    const initialState = {
        payerId: props.acblMember?.payerId ?? undefined,
        insuranceId: props.acblMember?.renewId,
        healthPlanName: props.acblMember?.healthPlanName,
        zipCode: props.acblMember?.zipCode,
        phone: props.acblMember?.phone,
        dateOfBirth: props.acblMember?.dateOfBirth,
        optInSweepstakes: props.acblMember?.optInSweepstakes ?? false,
    }
    const initialDateOfBirth = initialState.dateOfBirth
        ? LocalDate.parse(initialState.dateOfBirth)
        : null
    const [formState, {text, select, tel, checkbox}] = useFormState(initialState);
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(initialDateOfBirth);
    const {payerId, insuranceId} = formState.values;
    const initialInsuranceId = initialState.insuranceId ?? "";
    const [changedInsuranceId, setChangedInsuranceId] = useState<string>(initialInsuranceId);

    const elementConfig: Record<string, any> = {
        payerId: {
            name: "payerId",
            onChange: () => {
                formState.setField("insuranceId", "");
            },
            validate: (value: string | number): string | undefined => {
                if (typeof value === "number" && allPayers.find(p => p.id === value)) {
                    return;
                }
                return t('acblMemberSelectPayerStep.selectPayer');
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (changedInsuranceId === "") {
                    return t('acblMemberSelectPayerStep.enterPayerId', {"payerName": selectedPayer.payerName});
                }
            },
            validateOnBlur: true,
            onBlur: () => {
                if (changedInsuranceId.length === 0) {
                    formState.setField('insuranceId', initialInsuranceId);
                    setChangedInsuranceId(initialInsuranceId);
                }
            }
        },
        healthPlanName: {
            name: "healthPlanName",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (selectedPayer.unlistedPayerPlaceholder) {
                    if (!value) {
                        return t('acblMemberSelectPayerStep.enterHealthPlanName');
                    }
                    return;
                }
            },
            validateOnBlur: true
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string | null) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value?.trim()) {
                    //return 'Zip Code is required';
                    return " ";
                }
                if (!value.match(zipCodeRegex)) {
                    //return "Invalid zip code";
                    return " ";
                }
            },
            validateOnBlur: true
        },
        phone: {
            name: 'phone',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return ' ';
                }
            },
            validateOnBlur: true
        },
        optInSweepstakes: {
            name: 'optInSweepstakes'
        },
    }

    // borrowed from the original form
    const submitEnabled = () => {
        console.log("continueEnabled: values=",formState.values);
        // Validity is not evaluated on form load for pre-populated values, so
        // this is good enough for that initial state.
        const allFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        });
        return allFieldsValid && Object.entries(formState.validity).every(([key, value]) => value);
    }

    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const selectedPayer: GetPayerResponseBody | null =
        allPayers.find(p => p.id === payerId) || null;
    const [insuranceIdLabel, setInsuranceIdLabel] = useState<string>('');

    useEffect(() => {
        if (selectedPayer) {
            if (selectedPayer.unlistedPayerPlaceholder) {
                setInsuranceIdLabel(t('acblMemberSelectPayerStep.healthPlanId'));
            } else {
                setInsuranceIdLabel(selectedPayer.payerName + " ID");
            }
        }
    }, [selectedPayer, t])

    const onContinueSelected = () => {
        props.onContinue({
            payerId: selectedPayer?.id,
            insuranceId: changedInsuranceId,
            healthPlanName: formState.values.healthPlanName,
            zipCode: formState.values.zipCode,
            phone: getPhoneUnmask(formState.values.phone),
            dateOfBirth: formState.values.dateOfBirth,
            optInSweepstakes: formState.values.optInSweepstakes,
        });
    }

    useEffect(() => {

        const loadAllPayers = async () => {
            try {
                const responseBody = await getAllPayersEventsPortal();
                const sortedPayers = responseBody.allPayers.sort((left, right) => left.payerName.localeCompare(right.payerName))
                sortedPayers.push(sortedPayers.splice(sortedPayers.findIndex(payer => payer.unlistedPayerPlaceholder), 1)[0])
                setAllPayers(sortedPayers);
                setLoading(false);
                setLoadingErrorMessage(null);
            } catch (e) {
                setLoading(false);
                setLoadingErrorMessage(t('acblMemberSelectPayerStep.unexpectedError'));
            }
        };

        loadAllPayers();

    }, [t]);

    useEffect(() => {
        if (!loading && !selectedPayer?.unlistedPayerPlaceholder) {
            formState.setField("healthPlanName", null);
        }
    }, [loading, selectedPayer]);

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const validateDateOfBirth = (date: LocalDate) => {
        const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
        const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

        return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
    }

    useEffect(() => {
        // The widget validation was inconsistent during development. Perform explicit validation on the parsed LocalDate before passing off the value to the form.
        if (dateOfBirth instanceof LocalDate && validateDateOfBirth(dateOfBirth)) {
            formState.setField('dateOfBirth', dateOfBirth);
        } else {
            // Otherwise invalidate the date on the form
            formState.setField('dateOfBirth', null);
        }
    }, [dateOfBirth]);

    const onFocusInsuranceId = () => {
        if (initialInsuranceId === changedInsuranceId) {
            setChangedInsuranceId('');
            formState.setField('insuranceId', '');
        }
    }
    const onChangeInsuranceId = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChangedInsuranceId(event.target.value);
    }

    return (
        <div className={"MemberRegistrationWizard_Wrapper"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                    <IconButton className={"MemberRegistrationWizard_Previous"}
                                aria-label="Go Back"
                                onClick={props.onPrevious}>
                        <ArrowBackIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <h2 dangerouslySetInnerHTML={{__html: t('acblMemberSelectPayerStep.header')}}></h2>
                </Grid>
            </Grid>
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <LoadingMessage message={loadingErrorMessage}/>
            )}
            <Grid container spacing={2} direction="row" alignItems="center"
                  className="MemberSelectPayerPage_Fields">
                <Grid container spacing={1} direction="column" alignItems="center">
                    <Grid item xs={8} style={{width: '100%'}}>
                        <Select
                            style={{width: '100%'}} {...select(elementConfig.payerId)}
                            className="MemberSelectPayerPage_PayerSelect"
                            variant="outlined"
                            disabled={loading}
                            displayEmpty
                            required>
                            <MenuItem value="" disabled>
                                {t('acblMemberSelectPayerStep.selectProvider')}
                            </MenuItem>
                            {allPayers.map(payer => (
                                <MenuItem key={payer.id} value={payer.id}>
                                    {payer.payerName}
                                </MenuItem>
                            ))}
                        </Select>
                        {selectedPayer !== null && selectedPayer.unlistedPayerPlaceholder &&
                            <>
                                <TextField {...text(elementConfig.healthPlanName)}
                                           error={formState.errors.healthPlanName !== undefined}
                                           className="MemberSelectPayerPage_HealthPlanNameField"
                                           required
                                           helperText={formState.errors.healthPlanName}
                                           label={t("acblMemberSelectPayerStep.healthPlanName")}
                                           variant="outlined"
                                           style={{marginBottom: '10px', width: '100%'}}
                                />
                            </>
                        }
                        {selectedPayer !== null &&
                            <>
                                <TextField {...text(elementConfig.insuranceId)}
                                           error={formState.errors.insuranceId !== undefined}
                                           className="MemberSelectPayerPage_InsuranceIdField"
                                           required
                                           value={changedInsuranceId}
                                           onFocus={onFocusInsuranceId}
                                           onChange={onChangeInsuranceId}
                                           placeholder={initialInsuranceId}
                                           helperText={formState.errors.insuranceId}
                                           inputProps={{maxLength: 50}}
                                           label={insuranceIdLabel}
                                           variant="outlined"
                                           style={{marginBottom: '10px', width: '100%'}}
                                />
                            </>
                        }
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth margin="normal">
                            <TextField {...text(elementConfig.zipCode)}
                                       className="MemberRegistrationWizard_Input"
                                       label={t('acblMemberSelectPayerStep.zipCodeLabel')}
                                       required
                                       variant={"outlined"}
                                       error={formState.errors.zipCode !== undefined}
                                       size="small"/>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputMask
                                mask='(999) 999-9999'
                                maskChar='*'
                                {...tel(elementConfig.phone)}
                            >
                                {(inputProps: any) =>
                                    <TextField {...inputProps}
                                               className="MemberRegistrationWizard_Input"
                                               label={t('acblMemberSelectPayerStep.phoneLabel')}
                                               required
                                               variant={"outlined"}
                                               error={formState.errors.phone !== undefined}
                                               size="small"
                                               helperText={t('acblMemberSelectPayerStep.phoneHelperText')}
                                               FormHelperTextProps={{error: false}}
                                    />
                                }
                            </InputMask>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <MuiPickersUtilsProvider utils={LocalDateUtils}
                                                     locale={Locale.US}>
                                <KeyboardDatePicker
                                    className="MemberRegistrationWizard_Input"
                                    value={dateOfBirth}
                                    label={t('acblMemberSelectPayerStep.dob')}
                                    required
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    views={["year", "month", "date"]}
                                    openTo="year"
                                    disableFuture
                                    minDate="1900-01-02" // Treated as 1/1/1900
                                    minDateMessage={t('acblMemberSelectPayerStep.dobMin')}
                                    maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                                    maxDateMessage={t('acblMemberSelectPayerStep.dobMax')}
                                    initialFocusedDate="1900-01-02"
                                    onChange={date => setDateOfBirth(date)}/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                        {!props.userAgreement.expired && <FormControl>
                            <div className={"MemberRegistrationWizard_OptInSweepstakes"}>
                                <Checkbox {...checkbox(elementConfig.optInSweepstakes)}
                                          style={{paddingLeft: 0}}/>
                                <FormLabel>
                                    <span dangerouslySetInnerHTML={{__html: t('acblMemberNameStep.optInSweepstakes')}}/>
                                </FormLabel>
                            </div>
                        </FormControl>}
                        <Grid item container justify="flex-end">
                            <FormControl>
                                <PlayButton type="button"
                                            clickHandler={onContinueSelected}
                                            disabled={!submitEnabled()}
                                            text={t('acblMemberSelectPayerStep.continueButtonText')}
                                            className="MemberSelectPayerPage_SubmitButton"
                                            buttonStyle={ButtonStyles.FilledPrimary}
                                            id="HealthPlanSubmit"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>

                {Object.entries(formState.errors).length > 0 &&
                    <div className="MemberSelectPayerPage_FormErrors">
                        {Object.entries(formState.errors).map(([key, value]) =>
                            <div key={key}
                                 className="MemberSelectPayerPage_FormError">
                                {value}
                            </div>)
                        }
                    </div>}
            </Grid>
        </div>
    );
}
