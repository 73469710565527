import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const IMPORT_MEMBERS_STEP_TWO_URL = '/ignite/leader/import-members/step-two/'

const postImportMembersStepTwo = async (body: any, clubId: number): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${IMPORT_MEMBERS_STEP_TWO_URL}${clubId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request);
}

export default postImportMembersStepTwo
