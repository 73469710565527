import React, { useState, useEffect, useCallback } from "react";
import postConfirmReferral from "../api/postConfirmReferral";
import getClubById from "../api/getClubById";
import { useParams } from "react-router-dom";
import { IClub } from '../types/clubFinder';
import postRequestReferral from "../api/postRequestReferral";
import useQuery from "./useQuery";
import { CLUB_ROLES_STRINGS } from "../constants/dashboard";

const useReferral = (clubStatus?: CLUB_ROLES_STRINGS | string): [IClub | undefined, boolean, () => Promise<void | IClub | undefined>] => {
    const [referralId, setReferralId] = useState<number | undefined>()
    const [club, setClub] = useState<IClub | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const { clubId } = useParams()
    const query = useQuery()
    const userToken = query.get('userToken')

    useEffect(() => {
        setIsLoading(true)

        getClubById(clubId)
            .then((club) => {
                setClub(club)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (club && clubStatus === CLUB_ROLES_STRINGS.UNKNOWN) {
            postRequestReferral(String(club?.shortCode), userToken)
                .then((data) => {
                    setReferralId(data?.referralId)
                })
        }
    }, [club, clubStatus])

    const confirmReferral = useCallback(() => postConfirmReferral(referralId, club?.id), [referralId])


    return [club, isLoading, confirmReferral]
}

export default useReferral
