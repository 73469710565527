import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import './FieldStatusIcon.scss';

function FieldStatusIcon(props: any): JSX.Element | null {
    const { shouldShow, isError } = props
    return (
        isError ?
            <ErrorIcon
                className={`field-indicator error ${!shouldShow && "hidden"}`}
            />
            : <CheckCircleIcon
                className={`field-indicator validated ${!shouldShow && "hidden"}`}
            />
    );
}

export default FieldStatusIcon
