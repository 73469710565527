import { getLanguageSelection } from "./UserService";

export type AuthenticatedFetch = (request: Request) => Promise<Response | null>

interface RequestErrorProps {
    notLoggedIn?: boolean;
    notOk?: boolean;
    data?: string;
    status?: number;
}

export interface AuthParams {
    authenticatedFetch: AuthenticatedFetch
}

export interface SearchAuthParams<T> {
    authenticatedFetch: AuthenticatedFetch,
    searchParams: T
}

export interface BodyAuthParams<T> {
    authenticatedFetch: AuthenticatedFetch,
    body: T
}

function objToSearchParams(obj: Record<string, any>): URLSearchParams {
    const searchParams = new URLSearchParams();
    Object.keys(obj).forEach(key => {
        const value = obj[key];
        if (value instanceof Array) {
            value.forEach(entry => {
                searchParams.append(key, entry);
            })
        } else if (value !== null && value !== undefined) {
            searchParams.set(key, value);
        }
    });
    return searchParams;
}

export class RequestError extends Error {
    public readonly notLoggedIn: boolean;
    public readonly notOk: boolean;
    public readonly data?: string;
    public readonly status?: number;

    constructor(props: RequestErrorProps) {
        super();
        this.notLoggedIn = props.notLoggedIn ?? false;
        this.notOk = props.notOk  ?? false;
        this.data = props.data;
        this.status = props.status;
    }
}

interface GetJsonParams {
    url: string;
    searchParams?: URLSearchParams | Record<string, any>;
}

interface GetJsonAuthParams extends GetJsonParams {
    authenticatedFetch: AuthenticatedFetch;
}

async function readJsonNullable<T>(response: Response | null): Promise<T | null> {
    if (response === null) {
        // Login required.  The user just became null, so the page is about to
        // be re-rendered.
        throw new RequestError({
            notLoggedIn: true
        });
    }
    if (!response.ok) {
        const responseBodyText = await response.text();
        console.error(
            `Non-successful response from API: `
            + `${response.status} ${response.statusText} `
            + `from ${response.url}\n\n${responseBodyText}`);
        throw new RequestError({
            notOk: true,
            data: responseBodyText,
            status: response.status,
        });
    }
    if (response.status === 204) {
        return null;
    }
    return response.json();
}

/** Use this if the response status may be 204 No Content, such that it does
 * not return any JSON, otherwise use {@link getJsonAuth}. */
export async function getNullableJsonAuth<T>({
                                         authenticatedFetch,
                                         url,
                                         searchParams
                                     }: GetJsonAuthParams): Promise<T | null> {
    if (searchParams && !(searchParams instanceof URLSearchParams)) {
        // Must be a standard object and should be converted to URLSearchParams
        searchParams = objToSearchParams(searchParams);
    }
    if (searchParams) {
        url = `${url}?${searchParams.toString()}`
    }
    const request = new Request(url, {
        method: "GET",
        headers: {"Accept": "application/json"}
    });
    const response = await authenticatedFetch(request);
    return readJsonNullable(response);
}

/** Use this if the response status may be 204 No Content, such that it does
 * not return any JSON, otherwise use {@link getJson}. */
export async function getNullableJson<T>({
                                         url,
                                         searchParams
                                     }: GetJsonParams): Promise<T | null> {
    if (searchParams && !(searchParams instanceof URLSearchParams)) {
        // Must be a standard object and should be converted to URLSearchParams
        searchParams = objToSearchParams(searchParams);
    }
    if (searchParams) {
        url = `${url}?${searchParams.toString()}`
    }
    const request = new Request(url, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Accept-Language": getLanguageSelection()
        }
    });
    const response = await fetch(request);
    return readJsonNullable(response);
}

export async function getJsonAuth<T>(params: GetJsonAuthParams): Promise<T> {
    // Assume non-null
    return (await getNullableJsonAuth(params)) as T
}

export async function getJson<T>(params: GetJsonParams): Promise<T> {
    // Assume non-null
    return (await getNullableJson(params)) as T
}

interface PostJsonParams {
    url: string;
    data: Record<string, any>;
}

interface PostJsonAuthParams extends PostJsonParams {
    authenticatedFetch: AuthenticatedFetch;
}

interface PostFormDataAuthParams {
    url: string;
    formData: FormData;
    authenticatedFetch: AuthenticatedFetch;
}

export async function postFormDataAuth({
                                         authenticatedFetch,
                                         url,
                                         formData
                                     }: PostFormDataAuthParams): Promise<Response> {
    const request = new Request(url, {
        method: "POST",
        body: formData
    });
    const response = await authenticatedFetch(request);
    return readPostResponse(response);
}

export async function postJsonAuth({
                                       authenticatedFetch,
                                       url,
                                       data
                                   }: PostJsonAuthParams): Promise<Response> {
    const body = data && JSON.stringify(data);
    const request = new Request(url, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body
    });
    const response = await authenticatedFetch(request);
    return readPostResponse(response);
}

export async function postJson({
                                   url,
                                   data,
                               }: PostJsonParams): Promise<Response> {
    const body = data && JSON.stringify(data);
    const request = new Request(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": getLanguageSelection()
        },
        body
    });
    const response = await fetch(request);
    return readPostResponse(response);
}

async function readPostResponse(response: Response | null): Promise<Response> {
    if (response === null) {
        // Login required.  The user just became null, so the page is about to
        // be re-rendered.
        throw new RequestError({
            notLoggedIn: true
        });
    }
    if (!response.ok) {
        const responseBodyText = await response.text();
        console.error(
            `Non-successful response from API: `
            + `${response.status} ${response.statusText} `
            + `from ${response.url}\n\n${responseBodyText}`);
        throw new RequestError({
            notOk: true,
            data: responseBodyText,
            status: response.status
        });
    }
    return response;
}
