import React from "react";
import "./EventSearchResults.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
    EventSearchResponseBody, 
    EventSearchResultExtended
} from "../../pages/findAnEvent/FindAnEventPage";
import SelfGuidedEventIndicator from "../common/SelfGuidedEventIndicator";
import { CloudCircle } from "@material-ui/icons";
import NoResults from "./NoResults";
import MonthSelect from "./MonthSelect";

interface ResultsMonthListProps {
    searchResponseBody: EventSearchResponseBody;
    results: EventSearchResultExtended[];
    month: number;
    setMonth: (month: number) => void;
    usingFilters: boolean;
    resetFilters: Function;
    viewVirtualOnly: Function;
}

interface ResultsMonthDay {
    localDate: string;
    dayOfTheWeek: string;
    dayNumber: string;
    today: boolean;
    month: number;
    events: EventSearchResultExtended[];
}

export default function ResultsMonthList(props: ResultsMonthListProps): JSX.Element {
    const { t: tCommon } = useTranslation('common');
    const dates: Record<string, ResultsMonthDay> = {};
    const  { month, setMonth } = props;
    const history = useHistory();

    props.results.forEach(event => {
        if (!dates[event.ext.localDate]) {
            dates[event.ext.localDate] = {
                localDate: event.ext.localDate,
                dayOfTheWeek: event.ext.dayOfWeek,
                dayNumber: event.ext.dayNumber,
                events: [],
                month: event.ext.monthRawNumber,
                today: event.ext.today
            }
        }
        dates[event.ext.localDate].events.push(event);
    });

    const days = Object.values(dates)
        .filter(day => day.month === month);

    function goToEvent(event: EventSearchResultExtended) {
        history.push(event.ext.url);
    }

    function onChangeMonth(month: number) {
        setMonth(month);
    }

    return (
        <div className={"ResultsMonthList"}>
            <MonthSelect onChangeMonth={onChangeMonth} month={month} />
            {!props.results.length && <NoResults
                usingFilters={props.usingFilters}
                resetFilters={props.resetFilters}
                viewVirtualOnly={props.viewVirtualOnly} />}
            <div className={"ResultsMonthList_Days"}>
                {days.map(day => (
                    <div key={day.localDate}
                         className={"ResultsMonthList_Day"}>
                        <div className={"ResultsMonthList_Day_Date"}>
                            <div className={"ResultsMonthList_Day_Date_DayOfTheWeek"}>{day.dayOfTheWeek}</div>
                            <div className={`ResultsMonthList_Day_Date_Number ${day.today ? "Today" : ""}`}>{day.dayNumber}</div>
                        </div>
                        <div className={"ResultsMonthList_Day_Events"}>
                            {day.events.map(event => (
                                <div key={event.eventUrlFragment}
                                     onClick={() => goToEvent(event)}
                                     className={`ResultsMonthList_Day_Event ${event.eventIsVirtual ? "Virtual" : ""}`}>
                                    <div className={"ResultsMonthList_Day_Event_Name"}>
                                        {event.eventIsVirtual && <span title={tCommon('listVirtualIndicator.virtualLabel')}>
                                            <CloudCircle className={"VirtualIndicator"} />
                                        </span>}
                                        {event.ext.isSelfGuided && <span title={tCommon('listSelfGuidedIndicator.selfGuidedLabel')}>
                                            <SelfGuidedEventIndicator className={"SelfGuidedIndicator"} />
                                        </span>}
                                        {event.eventName}
                                    </div>
                                    <div className={"ResultsMonthList_Day_Event_TimeFrame"}>
                                        <span className={"ResultsMonthList_Day_Event_Time"}>{event.ext.timeStart}</span>
                                        {' '}-{' '}
                                        <span className={"ResultsMonthList_Day_Event_Time"}>{event.ext.timeEnd}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
