import React, { useContext, useEffect } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useHistory } from "react-router-dom";

export function Logout(): JSX.Element {
    const {user, logout} = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            if (user) {
                await logout();
            }
            history.push("/login");
        })();
    }, [user])

    return <></>
}
