import React from "react";
import "./LoadingMessage.scss";
import PlayButton from "./PlayButton";
import {ButtonStyles} from "./Button";

export interface LoadingMessageProps {
    message: string;
    showLoginLink?: boolean;
    className?: string;
}

export default function LoadingMessage(props: LoadingMessageProps): JSX.Element {
    let classes = "LoadingMessage";
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    let message = props.message;

    return (
        <div className={`${classes}`}>
            <div className={"LoadingMessage_Message"}>{message}</div>
            {props.showLoginLink &&
            <PlayButton text={"Login"}  className={"LoadingMessage_LoginButton"}
                        buttonStyle={ButtonStyles.FilledPrimary}
                        to={"/login"}
            />
            }
        </div>
    );
}
