import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import Loading from "../../../components/ignite/layout/Loading";
import { Link, useHistory, useParams } from "react-router-dom";
import useReferral from '../../../hooks/useReferral';
import { Box, Button } from '@material-ui/core';
import PlayIcon from '@material-ui/icons/PlayArrow';

import './ClubRequesting.scss';
import useMyClubStatus from "../../../hooks/useMyClubStatus";
import { CLUB_ROLES_STRINGS } from "../../../constants/dashboard";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { UserContext } from "../../../contexts/UserContext";
import { getClubLeaderData } from "../../../util/ignite/Dashboard.utils";

const CLUB_REQUESTED_URL = "/ignite/club-requested/"

const ClubRequesting = () => {
    const { t } = useTranslation('ignite/clubFinder');
    const { clubId } = useParams()
    const { syncUser } = useContext(UserContext);
    const myClubStatus = useMyClubStatus({ id: Number(clubId) })
    const [club, isLoading, confirmReferral] = useReferral(myClubStatus)
    const history = useHistory()

    if (isLoading) return <Loading loading={isLoading} />

    if (!club) return <div>{t('noSuchClub')}</div>

    const onClick = () => {
        let route = null
        switch (myClubStatus) {
            case CLUB_ROLES_STRINGS.UNKNOWN:
                confirmReferral().finally(() => {
                    syncUser()
                    route = `${CLUB_REQUESTED_URL}${club?.id}`
                    history.push(route)
                })
                break
            case CLUB_ROLES_STRINGS.MEMBER:
                route = getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': clubId })
                history.push(route)
                break
            default:
                route = getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': clubId })
                history.push(route)
                break
        }
    }

    const { clubName, location, clubImage } = club
    const { city, countrySubdivision } = location || {}

    const clubLeaderName = getClubLeaderData(club)
    const locationRow = [city, countrySubdivision].filter(Boolean).join(', ')

    return (
        <div>
            <div className="ignite-page-content club-finder">
                <div className='request-club'>
                    <h1>{t('title')}</h1>
                    <div className='request-club__description'>
                        <div>{t('status.requesting')}</div>
                        <b className="request-club__name">{clubName},</b>
                        <div>{t('description.requesting')}</div>
                    </div>
                    <Box className="request-club__info">
                        <div><img className="request-club__logo" src={"/images/passions/" + clubImage} alt="" /></div>
                        <div>
                            <b className="request-club__name" >{clubName}</b>
                            {locationRow && <div>{locationRow}</div>}
                            {clubLeaderName && <div>{t('clubLeader')} <b>{clubLeaderName}</b></div>}
                        </div>
                    </Box>
                    <Button
                        className='request-button'
                        variant="outlined"
                        onClick={onClick}
                    >
                        {t('request')}
                        <PlayIcon className='request-button__icon' />
                    </Button>
                </div>
            </div>
            <Box className="not-right-club">
                <b>{t('notRightClub')}</b>
                <div>{t('findBestClub')}</div>
                <Link
                    className="not-right-club__finder"
                    to="/ignite/club-finder"
                >
                    {t('clubFinder')}
                </Link>
            </Box>
        </div>
    )
}

export default ClubRequesting
