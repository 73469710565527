export interface FrontendConfig {
    apiOrigin: string
    authApiOrigin: string
    grouperUrl: string
    gtmId: string
    gtmEnv: string
    gtmAuth: string
    sanityProjectId: string
    sanityDataset: string
    sanityProductionModeEnabled: boolean
    flags?: Record<string, boolean>
}

const clone = <T extends object>(object: T): T => JSON.parse(JSON.stringify(object));

function checkResponseOk(response: Response) {
    if (!response.ok) {
        throw new Error(`Non-ok response ${response.status} ${response.statusText} from URL ${response.url}`);
    }
}

function checkKeyIsPresent<T extends object, K extends keyof T>(object: T, key: K) : T[K] {
    if (!object.hasOwnProperty(key)) {
        throw new Error(`Missing required key '${key}'`);
    }
    return object[key];
}

let cachedConfig: FrontendConfig;

export async function loadConfig(): Promise<FrontendConfig> {
    if (cachedConfig !== undefined) {
        return clone(cachedConfig);
    }

    // The config file is in the "public" directory, not the "src" directory, so that it can be replaced at runtime.  If
    // we used the "src" directory instead, configuration would have to occur at build time.
    const url = process.env.PUBLIC_URL + "/config/configv2.json";
    const response = await fetch(url);
    checkResponseOk(response);

    const config: FrontendConfig = await response.json();

    // TODO: There is probably a more elegant way to validate the shape of the config, but the
    //       solutions we found in initial searches were not appealing.  If we do find a good
    //       solution, perhaps we can also use it for verifying the response bodies from our API and
    //       for verifying serialized objects in local storage.
    checkKeyIsPresent(config, "apiOrigin");
    checkKeyIsPresent(config, "authApiOrigin");
    checkKeyIsPresent(config, "grouperUrl");
    checkKeyIsPresent(config, "gtmId");
    checkKeyIsPresent(config, "gtmEnv");
    checkKeyIsPresent(config, "gtmAuth");
    checkKeyIsPresent(config, "sanityProjectId");
    checkKeyIsPresent(config, "sanityDataset");
    checkKeyIsPresent(config, "sanityProductionModeEnabled");

    cachedConfig = config;
    return clone(cachedConfig);
}
