import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getMyEventsPaged, PagedMyEvents } from "../../services/MemberService";

import "./MyEvents.scss";
import {
    EventSearchResponseBody,
    EventSearchSort
} from "../../pages/findAnEvent/FindAnEventPage";
import EventSearchResults from "./EventSearchResults";
import { EventView } from "../findAnEvent/FindAnEventForm";
import Button, { ButtonStyles } from "../common/Button";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { UserContext } from "../../contexts/UserContext";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ArrowRight } from "@material-ui/icons";
import { useWindowSize } from "../../util/Util";
import {
    endOfCurrentOrSoonMonth,
    EventSearchResult,
    startOfCurrentOrSoonMonth
} from "../../services/EventSearchService";
import CurrentlyNoEvents from "./CurrentlyNoEvents";

export interface MyEvents {
    className?: string;
    view?: EventView;
    setView: (view: EventView) => void;
    favoritePassionsSelected: boolean;
    hasRegisteredEvents: boolean;
}

export default function MyEvents(props: MyEvents): JSX.Element {
    const { t } = useTranslation('event');
    const [loading, setLoading] = useState(true);
    const [previousLoading, setPreviousLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [results, setResults] = useState<EventSearchResponseBody | null>(null);
    const {hasRegisteredEvents} = props;
    const [skip, setSkip] = useState(0);
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const {authenticatedFetch} = useContext(UserContext);

    // Number of results per page on MyEvents increased from 2 to 10
    const limit = 10;
    const isPreviousButtonEnabled = skip > 0
        && props.view !== EventView.MONTH;
    const isNextButtonEnabled = skip + limit < totalResultCount
        && props.view !== EventView.MONTH;

    function onClickPreviousButton() {
        setPreviousLoading(true);
        setSkip(Math.max(0, skip - limit));
    }

    function onClickNextButton() {
        setNextLoading(true);
        setSkip(skip + limit);
    }

    function scrollToEvent(eventUrlFragment: string, startOfDate: string) {
        if (!results) {
            return;
        }
        const view = EventView.LIST;
        const event = results.results
            .find(event => event.eventUrlFragment === eventUrlFragment);
        if (!event) {
            return;
        }
        const newSkip = event.rowIndex;
        props.setView(view);
        setSkip(newSkip);
    }

    useEffect(() => {
            (async () => {
                window.scrollTo(0, 0);

                const params = new URLSearchParams();
                params.set("skip", skip.toString());
                params.set("limit", limit.toString());

                let start: number | null = null;
                let end: number | null = null;

                if (props.view === EventView.MONTH) {
                    start = startOfCurrentOrSoonMonth(month);
                    end = endOfCurrentOrSoonMonth(month);
                }

                let paged: PagedMyEvents;
                try {
                    paged = await getMyEventsPaged({
                        authenticatedFetch,
                        start,
                        end,
                        view: props.view,
                        skip,
                        limit
                    });
                } catch (e) {
                    setLoadingMessage(t('myEvents.loadingError'));
                    setLoading(false);
                    setPreviousLoading(false);
                    setNextLoading(false);
                    return;
                }

                setTotalResultCount(paged.totalResultCount);
                setResults({
                    results: paged.results.map(result => {
                        const {club, event} = result.eventAndClub;
                        return {
                            eventIsVirtual: event.isVirtual,
                            clubIsVirtual: club.isVirtual,
                            eventEndsAt: event.endsAtInstant,
                            passion: club.passion,
                            clubName: club.clubName,
                            eventIsPaid: event.isPaid,
                            eventName: event.eventName,
                            eventStartsAt: event.startsAtInstant,
                            eventTimeZone: event.timeZone,
                            clubUrlFragment: club.urlFragment,
                            distanceInMiles: -1,
                            eventTypeOne: event.typeOne,
                            eventTypeTwo: event.typeTwo,
                            eventUrlFragment: event.urlFragment,
                            location: event.location,
                            locationId: null, // Only needed server-side (hopefully)
                            rowIndex: result.rowIndex,
                            occurrenceId: null,
                            eventIsRecurring: event.isRecurring,
                            recurrence: null,
                            passionId: club.passion?.id,
                            userEventRelationshipStatus: result.status
                        } as EventSearchResult
                    }),
                    limit,
                    sort: EventSearchSort.EventStartsAt,
                    skip,
                    anyEventIsVirtual: false,
                    reverse: false,
                    totalResultCount: paged.totalResultCount,
                    postalCode: ""
                });

                setLoading(false);
                setPreviousLoading(false);
                setNextLoading(false);
            })();
        },
        [skip, limit, month, props.view, t]);

    return (
        <>
            {loading && <Loading className="PageLoading" loading={loading}/>}
            {loadingMessage !== null && (
                <LoadingMessage className={"PageLoadingMessage"}
                                message={loadingMessage}/>
            )}
            {!loading && <div className={`MyEvents ${props.className || ''}`}>
                {/* maxEventsPerDay set to an arbitrarily high number to be mostly unused. */}
                {hasRegisteredEvents && <EventSearchResults
                                    resultsLoading={false}
                                    searchResponseBody={results}
                                    sortByEventStartsAt={() => {}}
                                    sortByDistance={() => {}}
                                    onSortSelected={() => {}}
                                    view={props.view ?? EventView.LIST}
                                    usingFilters={false}
                                    resetFilters={() => {}}
                                    viewVirtualOnly={() => {}}
                                    month={month}
                                    setMonth={setMonth}
                                    maxEventsPerDay={100}
                                    scrollToEvent={scrollToEvent}/>}
                {!hasRegisteredEvents &&
                    <CurrentlyNoEvents
                        favoritePassionsSelected={props.favoritePassionsSelected}/>}
                {(isPreviousButtonEnabled || isNextButtonEnabled) && (
                    <div className="MyEvents_PreviousAndNextButtons">
                        <Button clickHandler={onClickPreviousButton}
                                buttonStyle={ButtonStyles.Unfilled}
                                disabled={!isPreviousButtonEnabled}
                                saving={previousLoading}
                                className="PreviousButton">
                            <SkipPreviousIcon/>
                            {t('myEvents.previousButtonText')}
                        </Button>
                        <Button clickHandler={onClickNextButton}
                                buttonStyle={ButtonStyles.Unfilled}
                                disabled={!isNextButtonEnabled}
                                saving={nextLoading}
                                className="NextButton">
                            {t('myEvents.nextButtonText')}
                            <SkipNextIcon/>
                        </Button>
                    </div>
                )}
            </div>}
        </>
    );
}