import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { useFormState } from "react-use-form-state";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@material-ui/core";

import {UserContext} from "../../contexts/UserContext";
import {loadConfig} from "../../services/ConfigService";
import {
    ClubStatus, getAllPassions,
    GetAllPassionsResponseBody,
    GetClubLeaderClubResponseBody, getMyClub
} from "../../services/ClubLeaderService";
import states from "../../util/UnitedStates";
import {CCStyles} from "../../components/form/Theme";
import Loading from "../../components/common/Loading";
import FieldWithBottomText from "../../components/form/FieldWithBottomText";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import SaveIndicator from "../../components/common/SaveIndicator";
import {RouteComponentProps} from "react-router-dom";
import LoadingMessage from "../../components/common/LoadingMessage";
import {labelForTimeZone, SupportedTimeZone} from "../../util/SupportedTimeZone";
import {refreshUsersClubs} from "../../services/UserService";
import {GetPassionResponseBody} from "../../services/Models";
import { LocationState } from "../../constants/contact";
import { POSTAL_CODE_REGEX } from "../../constants/regex";

import "./EditClubPage.scss";

// Use this type for the `onChange` of a <Select>.
//
//     function onChangeFoo(event: ChangeEvent<MuiSelectElement>) {
//        ...
//     }
//
//     ...
//
//     <Select onChange={onChangeFoo}>
//
interface MuiSelectElement {
    name?: string | undefined;
    value: unknown; // wish this could be a more specific type such as string
}

interface ClubPageParams{
    clubUrlFragment: string;
}

export default function EditClubPage({match, history}: RouteComponentProps<ClubPageParams>): JSX.Element {
    const clubUrlFragment = match.params.clubUrlFragment;
    const { t } = useTranslation('pages');
    const {user, authenticatedFetch, refreshUser} = useContext(UserContext);
    const classes = CCStyles();

    const [loadingMessage, setLoadingMessage] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [savingMessage, setSavingMessage] = useState<string | null>("");
    const [savingSuccess, setSavingSuccess] = useState(false);
    const [savingAttempted, setSavingAttempted] = useState(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [saveButtonText, setSaveButtonText] = useState<string>(t('editClubPage.submitButton'));

    const [clubName, setClubName] = useState("");
    const [description, setDescription] = useState("");
    const [passionId, setPassionId] = useState<number | null>(null);
    const [timeZone, setTimeZone] = useState("");
    const [city, setCity] = useState("");
    const [countrySubdivisionWithDefault, setCountrySubdivisionWithDefault] = useState("");
    const [allPassions, setAllPassions] = useState<GetPassionResponseBody[]>([]);

    const [isInactive, setIsInactive] = useState(true); // new clubs start as inactive
    const [isInitallyInactive, setIsInitallyInactive] = useState(true);
    const [clubExists, setClubExists] = useState(false);
    const [locationState, setLocationState] = useState(LocationState.Offline);

    const isBothLocationState = locationState === LocationState.Both
    const isOnlineLocationState = locationState === LocationState.Online
    const [formState, { text }] = useFormState({ postalCode: "" });
    const postalCode = formState.values.postalCode

    const validationConfig = {
        postalCode: {
            name: 'postalCode',
            validate: (value: string) => {
                if (!value.match(POSTAL_CODE_REGEX) && !isOnlineLocationState) {
                    return t('editClubPage.zipcodeValidationError');
                }
            },
            validateOnBlur: true,
            onBlur: () => {
                const value = formState.values.postalCode.trim()

                value && formState.setField('postalCode', value)
                if (!value.match(POSTAL_CODE_REGEX) && !isOnlineLocationState)
                    formState.setFieldError('postalCode', t('editClubPage.zipcodeValidationError'));
            }
        },
    }

    const submitEnabled = () => {
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value || (key === 'postalCode' && isOnlineLocationState));
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value || (key === 'postalCode' && isOnlineLocationState));
        const isPostalCodeValid = isOnlineLocationState ? postalCode.match(POSTAL_CODE_REGEX) || postalCode === '' : postalCode.match(POSTAL_CODE_REGEX)
        return clubUrlFragment === undefined ? allInputsTouched && allValuesValid : isPostalCodeValid
    }

    useEffect(
        () => {
            const loadMyClubFromApi = async () => {
                window.scrollTo(0,0);
                if (user === null) {
                    setLoggedOut(true);
                    setLoadingMessage(t('editClubPage.loginError'));
                    return;
                }
                setLoading(true);

                let allPassionsResponseBody: GetAllPassionsResponseBody;
                let myClub: GetClubLeaderClubResponseBody | null;
                try {
                    allPassionsResponseBody = await getAllPassions()
                    myClub = await getMyClub({authenticatedFetch, clubUrlFragment, omitLeaders: true});

                    if (clubUrlFragment && myClub === null) {
                        history.replace('/');
                    }
                } catch (e) {
                    setLoadingMessage(t('editClubPage.unexpectedError'));
                    return;
                }

                setLoading(false);

                setAllPassions(allPassionsResponseBody.allPassions.sort(
                    (passion1, passion2) => {
                        return passion1.passionName.toLowerCase().localeCompare(passion2.passionName.toLowerCase());
                    }));

                if (myClub === null) {
                    // The user is authenticated but they have no club.

                    setClubName("");
                    setDescription("");
                    setPassionId(allPassionsResponseBody.allPassions[0].id);
                    setTimeZone("");
                    setCity("");
                    setCountrySubdivisionWithDefault("XX");
                    setIsInactive(true);
                    setIsInitallyInactive(true);
                    setClubExists(false);

                } else {
                    // The user already has a club.
                    if (!myClub.isPrimaryClubLeader) {
                        setLoadingMessage("Invalid permission to edit this club.");
                        return;
                    }
                    const newLocationState = myClub.trackingInPersonActivitiesEnabled && myClub.trackingOnlineActivitiesEnabled ? LocationState.Both
                        : ( myClub.trackingOnlineActivitiesEnabled ? LocationState.Online : LocationState.Offline)

                    setClubName(myClub.clubName);
                    setDescription(myClub.description);
                    setPassionId((myClub.passion === null) ? allPassionsResponseBody.allPassions[0]?.id : myClub.passion?.id);
                    setTimeZone(myClub.timeZone);
                    setLocationState(newLocationState)
                    setCity(myClub.location?.city ?? "");
                    setCountrySubdivisionWithDefault(myClub.location?.countrySubdivision ?? "");

                    const postalCode = myClub.location?.postalCode ?? "";
                    formState.setField('postalCode', postalCode);
                    if (!isOnlineLocationState) formState.setFieldError('postalCode', validationConfig.postalCode.validate(postalCode));

                    setIsInactive(myClub.status === ClubStatus.INACTIVE);
                    setIsInitallyInactive(myClub.status === ClubStatus.INACTIVE);
                    setClubExists(true);
                    setSaveButtonText(t('editClubPage.saveButtonText'));
                }

                setLoadingMessage(null);
            };
            loadMyClubFromApi();
        },
        [user, clubUrlFragment, authenticatedFetch, history, t]);

    function onChangeClubName(event: ChangeEvent<HTMLInputElement>) {
        setClubName(event.target.value);
        setSavingAttempted(false);
    }

    function onChangeDescription(event: ChangeEvent<HTMLInputElement>) {
        setDescription(event.target.value);
        setSavingAttempted(false);
    }

    function onChangePassion(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'number'
            && allPassions.map(p => p.id).includes(event.target.value)) {

            setPassionId(event.target.value);
        } else {
            setPassionId(null);
        }
    }

    function onChangeTimeZone(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string'
            && Object.values(SupportedTimeZone).map(tz => tz.toString()).includes(event.target.value)) {

            setTimeZone(event.target.value);
        } else {
            setTimeZone("");
        }
    }

    function onChangeCity(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setCity(event.target.value);
    }

    function onChangeCountrySubdivision(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string'
            && states.some(state => state.id === event.target.value)) {
            setCountrySubdivisionWithDefault(event.target.value);
        } else {
            setCountrySubdivisionWithDefault("XX");
        }
    }

    function onChangeIsActive(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setIsInactive(!event.target.checked);
    }

    function onLocationStateChange(state: LocationState){
        setLocationState(state)
        if (locationState === LocationState.Online) formState.setFieldError('postalCode', null)
    }

    async function onSubmitEditMyClubForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setSaving(true);

        const config = await loadConfig();

        let url = `${config.apiOrigin}/club-leader/my-club/${encodeURIComponent(clubUrlFragment)}`;
        // If the clubUrlFragment is undefined, we are creating a new club
        if (clubUrlFragment === undefined) {
            url = `${config.apiOrigin}/club-leader/my-club`;
        }

        let countrySubdivision = countrySubdivisionWithDefault === "XX" ? "" : countrySubdivisionWithDefault;
        let clubStatus = isInactive ? ClubStatus.INACTIVE : ClubStatus.ACTIVE;

        const request = new Request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                clubName,
                description,
                passionId,
                timeZone,
                city,
                countrySubdivision,
                clubStatus,
                trackingInPersonActivitiesEnabled: !isOnlineLocationState,
                trackingOnlineActivitiesEnabled: isOnlineLocationState || isBothLocationState,
                postalCode: formState.values.postalCode,
            })
        });

        const response = await authenticatedFetch(request);

        setSaving(false);
        setSavingAttempted(true);

        if (!isInactive)
        {
            setIsInitallyInactive(false);
        }

        if (response === null) {
            setSavingMessage(t('editClubPage.loginRequired'));
            setSavingSuccess(false);
            return;
        }

        if (!response.ok) {
            // TODO: Look for server-side validation errors?
            //       In general, there should be none, because client-side validation should catch everything.
            const responseBodyText = await response.text();
            console.error(
                `Non-successful response from API: `
                + `${response.status} ${response.statusText} `
                + `from ${response.url}\n\n${responseBodyText}`);
            setSavingMessage(t('editClubPage.saveError'));
            setSavingSuccess(false);
            return;
        }
        await refreshUser();
        await refreshUsersClubs();
        const responseJson = await response.json();
        history.push(`/my-club/${encodeURIComponent(responseJson.clubUrlFragment)}`);
    }

    return (
        <div className="EditClubPage">
            {user !== null && (
                <>
                    {loading && <Loading className="PageLoading" loading={loading}/>}
                    {loadingMessage !== null && (
                        <LoadingMessage className={"PageLoadingMessage"} showLoginLink={loggedOut}
                                        message={loadingMessage}/>
                    )}
                    {!loading && loadingMessage === null && (
                        <form className={classes.root} onSubmit={onSubmitEditMyClubForm}>
                            {!clubExists && <h1>Add Your Club</h1>}
                            {clubExists && <h1>Edit Club</h1>}

                            {!clubExists && (
                                <>
                                    <div className="EditClubPage_AlmostDone">
                                        <div>
                                            {t('editClubPage.almostDone', { name: user.firstName })}
                                        </div>
                                        <div>
                                            {t('editClubPage.continueSetup')}
                                        </div>
                                    </div>
                                </>
                            )}
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditClubPage_FormControl`}
                                component="div">
                                <div className="EditClubPage_Label">
                                    <FormLabel required component="legend"
                                               className={`${classes.formLabel} ${classes.editPageFormLabel}`}>
                                        {t('editClubPage.clubName')}
                                    </FormLabel>
                                </div>
                                <div className="EditClubPage_Field">
                                    <FieldWithBottomText instructionText={`${clubName.length}/200 ` + t('editClubPage.characterCount')}
                                                         className={'EditClubPage_Field'}>
                                    <TextField value={clubName} onChange={onChangeClubName} required
                                               inputProps={{maxLength: 200}}
                                               className={`${classes.root}`} variant="outlined"
                                               placeholder={t('editClubPage.clubNamePlaceholder')}/>
                                    </FieldWithBottomText>
                                </div>
                            </FormControl>
                            <hr/>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditClubPage_FormControl`}
                                component="div">
                                <div className="EditClubPage_Label">
                                    <FormLabel required
                                               className={`${classes.formLabel} ${classes.editPageFormLabel}`}
                                               component="legend">{t('editClubPage.clubDescription')}</FormLabel>
                                </div>
                                <div className="EditClubPage_Field">
                                    <FieldWithBottomText instructionText={`${description.length}/200 ` + t('editClubPage.characterCount')}
                                                         className={'EditClubPage_Field'}>
                                        <TextField value={description} className={`${classes.root}`}
                                                   rows={4}
                                                   onChange={onChangeDescription}
                                                   inputProps={{maxLength: 200}}
                                                   required multiline variant="outlined"
                                                   placeholder={t('editClubPage.clubDescriptionPlaceholder')}/>
                                    </FieldWithBottomText>
                                </div>
                                <div className="EditClubPage_FormHelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        {t('editClubPage.clubDescriptionHelperText')}
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <hr/>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditClubPage_FormControl`}
                                component="div">
                                <div className="EditClubPage_Label">
                                    <FormLabel required className={`${classes.formLabel} ${classes.editPageFormLabel}`}
                                               component="legend">
                                        {t('editClubPage.clubPassion')}
                                    </FormLabel>
                                </div>
                                <div className="EditClubPage_Field">
                                    <Select required value={passionId}
                                            className={`${classes.root}`} variant="outlined"
                                            onChange={onChangePassion}>
                                        {Object.values(allPassions).map(aPassion => (
                                            <MenuItem key={aPassion.id} value={aPassion.id}>
                                                {aPassion.passionName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="EditClubPage_FormHelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        {t('editClubPage.clubPassionHelperText')}
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <hr/>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditClubPage_FormControl`}
                                component="div">
                                <div className="EditClubPage_Label">
                                    <FormLabel required className={`${classes.formLabel} ${classes.editPageFormLabel}`}
                                               component="legend">
                                        {t('editClubPage.timeZone')}
                                    </FormLabel>
                                </div>
                                <div className="EditClubPage_Field">
                                    <Select required displayEmpty value={timeZone}
                                            className={`${classes.root}`} variant="outlined"
                                            onChange={onChangeTimeZone}>
                                        <MenuItem value="" disabled/>
                                        {Object.values(SupportedTimeZone).map(supportedTimeZone => (
                                            <MenuItem key={supportedTimeZone}
                                                      value={supportedTimeZone}>
                                                {labelForTimeZone(t, supportedTimeZone)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="EditClubPage_FormHelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        {t('editClubPage.timeZoneHelperText')}
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <hr/>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditClubPage_FormControl`}>
                                <div className="EditClubPage_Label">
                                    <FormLabel component="legend"
                                               className={`${classes.formLabel}`}>
                                        <h3>{t('editClubPage.activeToggle')}</h3></FormLabel>
                                </div>
                                <div className="EditClubPage_Field">
                                    <Switch checked={!isInactive} disabled={!isInitallyInactive} onChange={onChangeIsActive}/>
                                </div>
                                <div className="EditClubPage_FormHelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        <span dangerouslySetInnerHTML={{__html: t('editClubPage.activeToggleHelperText')}}></span> {isInitallyInactive && <span className={"EditClubPage_Inactive"}>{t('editClubPage.inactiveText')}</span>}
                                        {!isInitallyInactive && <span className="EditClubPage_Active">{t('editClubPage.activeText')}</span>}.
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <hr/>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditClubPage_FormControl`}
                                component="div">
                                <div className="EditClubPage_Label">
                                    <FormLabel required className={`${classes.formLabel} ${classes.editPageFormLabel}`}
                                               component="legend">{t('editClubPage.location')}</FormLabel>
                                </div>
                                <div className="EditClubPage_AddressContainer">
                                    <div>
                                        <Checkbox
                                            checked={locationState === LocationState.Offline}
                                            onChange={() => onLocationStateChange(LocationState.Offline)}
                                            id="location-offline"
                                        />
                                        <label htmlFor="location-offline">{t('editClubPage.inPerson')}</label>
                                    </div>
                                    <div>
                                        <Checkbox
                                            checked={locationState === LocationState.Online}
                                            onChange={() => onLocationStateChange(LocationState.Online)}
                                            id="location-online"
                                        />
                                        <label htmlFor="location-online">{t('editClubPage.online')}</label>
                                    </div>
                                    <div>
                                        <Checkbox
                                            checked={locationState === LocationState.Both}
                                            onChange={() => onLocationStateChange(LocationState.Both)}
                                            id="location-both"
                                        />
                                        <label htmlFor="location-both">{t('editClubPage.both')}</label>
                                    </div>
                                    {!isOnlineLocationState && (
                                        <>
                                            <div className="EditClubPage_Field">
                                                <TextField
                                                    value={city}
                                                    onChange={onChangeCity}
                                                    className={`${classes.root}`}
                                                    variant="outlined"
                                                    placeholder={t('editClubPage.cityPlaceholder')}
                                                    inputProps={{maxLength: 50}}
                                                />
                                            </div>
                                            <div className="EditClubPage_Field">
                                                <Select
                                                    className={`${classes.root}`}
                                                    onChange={onChangeCountrySubdivision}
                                                    variant="outlined"
                                                    value={countrySubdivisionWithDefault}
                                                >
                                                    <MenuItem key={"XX"}
                                                            value={"XX"}>
                                                        {"State"}
                                                    </MenuItem>
                                                    {states.map(state => (
                                                        <MenuItem key={state.id}
                                                                value={state.id}>
                                                            {state.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="EditClubPage_Field">
                                                <TextField {...text(validationConfig.postalCode)}
                                                    variant="outlined"
                                                    required={!isOnlineLocationState}
                                                    className={`${classes.root}`}
                                                    placeholder={t('editClubPage.zipcodePlaceholder')}
                                                    error={Boolean(formState.errors.postalCode) && !isOnlineLocationState}
                                                    helperText={!isOnlineLocationState ? formState.errors.postalCode : null}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="EditClubPage_FormHelperText">
                                    {locationState !== LocationState.Online && (
                                        <FormHelperText className={classes.formHelperText}>
                                            {t('editClubPage.locationHelperText')}
                                        </FormHelperText>
                                    )}
                                </div>
                            </FormControl>
                            <hr/>
                            <FormControl
                                className={`${classes.formControl} EditClubPage_ButtonContainerFormControl`}
                                component="div">
                                <div className="EditClubPage_ButtonContainer">
                                    <div className="EditClubPageButton_WithSaving">
                                    <PlayButton type="submit"
                                                text={saveButtonText}
                                                saving={saving}
                                                disabled={!submitEnabled()}
                                                buttonStyle={ButtonStyles.FilledPrimary}
                                                className="EditClubPage_Button"/>
                                    </div>
                                </div>
                                {savingAttempted && <SaveIndicator className="EditClubPage_SaveIndicator" message={savingMessage} success={savingSuccess}/>}
                            </FormControl>
                        </form>
                    )}
                </>
            )}
        </div>
    );
}
