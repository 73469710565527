import React, {useContext, useEffect} from "react";
import {RouteComponentProps, Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./Events.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import clubLeaderLanding from "../../assets/club-leader-landing.jpg";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import CollapsibleFAQ from "../../components/home/CollapsibleFAQ";
import {UserContext} from "../../contexts/UserContext";
import { url } from "inspector";
import eventBanner from "../../assets/eventBanner.jpeg";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Mary from '../../assets/mary.jpeg';
import Andrea from '../../assets/andrea.jpeg';
import Cindy from '../../assets/cindy.jpeg';
import Jennifer from '../../assets/jennifer.jpeg';
import Karen from '../../assets/karen.jpeg';
import Energy from '../../assets/energy.png';
import Happiness from '../../assets/happiness.png';
import Heart from '../../assets/heart.png';
import Sleep from '../../assets/sleep.png';
import Network from '../../assets/network.png';
import { Button, createTheme } from "@material-ui/core";

/**
 * A page with static content that contains a link to register as a member.
 */
export default function Events(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {history} = props;
    const {user} = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const onClickSignUpButton = () => {
        history.push("/member/new-registration");
    }

    const onClickLogin = () => {
        history.push("/member/login");
    }

    return (
       <div style={{flexGrow: 1}}>
          <Grid container spacing={2} style={{display: 'flex'}}>
            <Grid item className="image-wrapper" xs={12} style={{backgroundImage: `url(${require("../../assets/eventBanner.jpeg")})`, backgroundSize: 'cover', height: 550, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
              <button id="joinFunButton" style={{marginTop: '250px'}} onClick={() => onClickSignUpButton()} className="customButton">
                  {t('events.joinFun')}
              </button>
            </Grid>
            <Grid item xs={12} direction="row" id={"CCMemberLanding_FAQ_Wrapper"} style={{textAlign: "center", backgroundColor:'#0097ea', color: '#ffffff', padding: 50, paddingRight: 120, paddingLeft: 120}}>
              <div dangerouslySetInnerHTML={{__html: t('events.getActive')}}></div>
            </Grid>
            <Grid item xs={12} direction="row">
              <Grid item xs={6} style={{paddingLeft: '50px'}}>
                <div dangerouslySetInnerHTML={{__html: t('events.joinCommunity')}}></div>
              </Grid>
            </Grid>
          
              <Grid item xs={6} style={{paddingLeft: '50px'}}>
                  <iframe  height="100%" width="100%" src="https://www.youtube.com/embed/l_fkRGt6Qt0">
                  </iframe>
              </Grid>
              <Grid item xs={6} style={{textAlign: 'center'}}>
                  <div dangerouslySetInnerHTML={{__html: t('events.highlights')}}></div>
              </Grid>
             {/* Our Clubs Section */}
                <Grid item xs={12} className="greyBackground">
                  <h1 className="e3BlueText">{t('events.ourClubs')}</h1>
                </Grid>
                <Grid item xs={1} className="greyBackground"/>
                <Grid item xs={2} className="greyBackground">
                  <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/artsAndCrafts.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.arts')}</p>
                    </div>
                  </div> 
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/birdingWithoutBorders.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.birding')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/dance.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.dance')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/drawingAndPainting.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.drawing')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/gardening.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.gardening')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} className="greyBackground" />
                <Grid item xs={1} className="greyBackground" />
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/lifeStoryClub.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.lifeStory')}</p>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/mindAndBody.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.mind')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/music.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.music')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/photography.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.photography')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/pickleball.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.pickleball')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} className="greyBackground" />
                <Grid item xs={1} className="greyBackground" />
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/sewing.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.sewing')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/soloAging.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.soloAging')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/technologyWithKevin.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.technology')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                  <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/walking.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.walking')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className="greyBackground">
                <div className="activityBlock" style={{backgroundImage: `url(${require("../../assets/yourE3Benefits.jpeg")})`, backgroundSize: 'cover', height: 200}}>
                    <div className="activityText">
                      <p>{t('events.benefits')}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} className="greyBackground" />
             {/* Featured Virtual Club Leaders */}
             <Grid item xs={12} style={{textAlign: 'center'}}>
               <h1 className="e3BlueText">{t('events.featured')}</h1>
              </Grid>
             <Grid item xs={2} />
                <Grid item xs={2} className="partners" >
                  <div className="partners">
                    <div className="image-cropper">
                      <img src={Mary} className="profile-pic"/>
                    </div>
                    <div dangerouslySetInnerHTML={{__html: t('events.featuredMind')}}></div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="partners">
                  <div className="image-cropper">
                    <img src={Cindy} className="profile-pic"/>
                  </div>
                  <div dangerouslySetInnerHTML={{__html: t('events.featuredDance')}}></div>
                  </div>
                </Grid>
               
                <Grid item xs={2}>
                  <div className="partners">
                  <div className="image-cropper">
                    <img src={Jennifer} className="profile-pic"/>
                  </div>
                  <div dangerouslySetInnerHTML={{__html: t('events.featuredPaint')}}></div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <div className="partners">
                  <div className="image-cropper">
                    <img src={Karen} className="profile-pic"/>
                  </div>
                  <div dangerouslySetInnerHTML={{__html: t('events.featuredArts')}}></div>
                  </div>
                </Grid>
                <Grid item xs={2} />
                {/* What our members say */}
                <Grid item xs={12} className="greyBackground" style={{paddingRight: 50, paddingLeft: 50}}>
                  <div dangerouslySetInnerHTML={{__html: t('events.ourMembers')}}></div>
                </Grid>
                {/* How Being Active Changes Lives */}
                <Grid item xs={12} className="e3BlueBackground" style={{paddingTop: 15}}>
                  <h1 className="whiteText">{t('events.change')}</h1>
                </Grid>
                <Grid item xs={1} className="e3BlueBackground" />
                <Grid item xs={2} className="e3BlueBackground" >
                  <div className="image">
                    <img src={Energy}/>
                  </div>
                  <h2 className="whiteText">{t('events.energy')}</h2>
                </Grid>

                <Grid item xs={2} className="e3BlueBackground" >
                  <div className="image">
                    <img src={Sleep}/>
                  </div>
                  <h2 className="whiteText">{t('events.sleep')}</h2>
                </Grid>
                <Grid item xs={2} className="e3BlueBackground" >
                  <div className="image">
                    <img src={Heart}/>
                  </div>
                  <h2 className="whiteText">{t('events.risk')}</h2>
                </Grid>
                <Grid item xs={2} className="e3BlueBackground" >
                  <div className="image">
                    <img src={Network}/>
                  </div>
                  <h2 className="whiteText">{t('events.connections')}</h2>
                </Grid>
                <Grid item xs={2} className="e3BlueBackground" >
                  <div className="image">
                    <img src={Happiness}/>
                  </div>
                  <h2 className="whiteText">{t('events.thinking')}</h2>
                </Grid>
                <Grid item xs={1} className="e3BlueBackground"  />
                <Grid item xs={12} className="e3BlueBackground">
                  <button id="takeStepButton" onClick={() => onClickSignUpButton()} className="customButton">{t('events.takeStepButton')}</button>
                  <h3 className="whiteText" style={{paddingLeft: 100, paddingRight: 100}}>
                      {t('events.basicInformation')}
                  </h3>
                </Grid>
        </Grid>
       </div>
    );
}
