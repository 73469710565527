import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./InstructorBioModal.scss";

import { Avatar, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import { getClubLeaderClubs } from "../../api/getClubLeaderClubs";
import getVirtualClubAssociationContentViaSanity from "../../sanity/getVirtualClubAssociationContentViaSanity";

import RelatedClubCard from "./RelatedClubCard";

export interface InstructorBioModalProps {
    className?: string
    openModal: boolean
    closeModalClickHandler: Function
    instructorDataObject: any
    maximumRelatedClubsToDisplay: number
    children?: any
    currentClubId: number
}

/**
 * InstructorBioModal
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=296-3917&mode=design&t=Gs9IdcDyGMIOnhOI-0
 * current uses: see src/pages/virtualClubs/VirtualClubsClubDetailPage
 * 
 * Modal displaying info for a given club instructor/leader
 */

export default function InstructorBioModal(props: InstructorBioModalProps): JSX.Element {
    const { t } = useTranslation('pages');

    let classes = 'InstructorBioModal';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
   
    let openModal = props.openModal
    let data = props.instructorDataObject;
    let maximumRelatedClubsToDisplay = (props.maximumRelatedClubsToDisplay) ? props.maximumRelatedClubsToDisplay : 2;
    const showEmail = data?.sanityData?.sanityData?.showEmailInBioPopup === true;
    let instructorFullName = data?.apiData?.firstName + ' ' + data?.apiData?.lastName;
    let instructorEmail = showEmail ? data?.apiData?.email : '';
    let avatarImgUrl = (data?.sanityData?.sanityData?.hasOwnProperty('avatar')) ? data.sanityData.sanityData.avatar : '';

    let instructorFunFact: string = (data?.sanityData?.sanityData?.funFact?.hasOwnProperty('en')) ? data.sanityData.sanityData.funFact.en : ''
    let instructorBioText: Array<any> = (data?.sanityData?.sanityData?.bio[0].hasOwnProperty('children')) ? data.sanityData.sanityData.bio[0].children : [];
    let instructorQuote: string = (data?.sanityData?.sanityData?.personalQuote?.hasOwnProperty('en')) ? data.sanityData.sanityData.personalQuote.en : '';

    const [clubs, setClubs] = useState<any>([])

    useEffect(() => {
        let controller: any = new AbortController();

        async function loadClubs() {
            if (data?.apiData?.email) {
                const clubsForLeader = await getClubLeaderClubs(data.apiData.email);
                // only use maximum of 2 clubs
                let clubs = [];

                // @ts-ignore: need to be able to use for / of loop on myClubs array
                for (const [index, clubForLeader] of clubsForLeader.myClubs.entries()) {
                    if (clubForLeader.shortCode !== null && clubForLeader.id !== props.currentClubId && clubs.length < maximumRelatedClubsToDisplay) {
                        var copyOfClubForLeader = { ...clubForLeader };
                        const relatedClubSanityData = await getVirtualClubAssociationContentViaSanity(clubForLeader.shortCode)
                        if (relatedClubSanityData && relatedClubSanityData.result && relatedClubSanityData.result.length > 0) {
                            copyOfClubForLeader.sanityData = relatedClubSanityData.result[0]
                            clubs.push(copyOfClubForLeader);
                        }
                    }
                }
                setClubs(clubs);
            }
            controller = null;
        }

        loadClubs().then();
        return () => controller?.abort();
    }, [data])

    function closeModal(e: any) {
        if (props.closeModalClickHandler !== undefined) {
            props.closeModalClickHandler(e);
        }
    }

    return (
        <Dialog
            className="InstructorBioModal"
            open={openModal}
            onClose={closeModal}
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle disableTypography>
                <h2 className="invisible">{"Leela K"}</h2>
                <IconButton onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <div className="InstructorBioModal_Content equal-columns">
                    <div>
                        <div className="HorizontalMediaCard pb-24">
                            {avatarImgUrl ? (
                                <Avatar
                                    className="MuiAvatar_100px"
                                    alt={instructorFullName}
                                    src={avatarImgUrl}
                                />
                            ) :
                                <Avatar className="MuiAvatar_100px" alt={instructorFullName} >
                                    <div style={{ backgroundColor: "rgba(25, 118, 210, 0.08)", width: "100%", height: "100%" }}></div>
                                </Avatar>
                            }

                            <div className="HorizontalMediaCard__info">
                                <h4 className="pb-6">{t('virtualClubsClubDetailPage.instructorBio.aboutText')}</h4>
                                <div className="fz-24 weight-700 pb-8">{instructorFullName}</div>

                                <div className="fz-20 weight-400"><a href={"mailto:" + instructorEmail}>{instructorEmail}</a></div>
                            </div>
                        </div>

                        {instructorFunFact &&
                            <>
                                <h4 className="pb-8">{t('virtualClubsClubDetailPage.instructorBio.funFactText')}</h4>
                                <div className="fz-20 weight-600">
                                    {instructorFunFact}
                                </div>
                            </>
                        }
                    </div>
                    <div>
                        {instructorBioText &&
                            <div className="pb-24">
                                {instructorBioText.map((span, index) => (
                                    <span key={index}>{span.text}</span>
                                ))
                                }
                            </div>
                        }
                        {instructorQuote &&
                            <div>
                                {instructorQuote}
                            </div>
                        }
                    </div>
                </div>

                {clubs !== null && clubs?.length > 0 && (
                    <div>
                        <h3>{t('virtualClubsClubDetailPage.instructorBio.alsoFromThisInstructorText')}</h3>
                        <div className="relatedClubs RelatedClubCard">
                            {clubs.map((club: any) => (
                                <RelatedClubCard key={club.id} clubData={club} instructorName={instructorFullName} closeModal={closeModal} />
                            ))}
                        </div>
                    </div>
                )}

            </DialogContent>
        </Dialog>
    )
};