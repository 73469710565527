import React from "react";
import "./SelectButtonGroup.scss"
import { Button, ButtonGroup } from "@material-ui/core";

interface ButtonGroupProps {
    options: {label: string, value: string}[];
    value: string;
    onChangeValue: (value: string) => void;
}

export default function SelectButtonGroup(props: ButtonGroupProps): JSX.Element {
    return (
        <ButtonGroup className={"SelectButtonGroup"}>
            {props.options.map(option => {
                const selected = option.value === props.value;
                return (
                    <Button key={option.value}
                            type="button"
                            className={`${selected ? "selected" : ""} SelectButtonGroup_Button`}
                            onClick={() => props.onChangeValue(option.value)}>
                        {option.label}
                    </Button>
                )
            })}
        </ButtonGroup>
    )
}
