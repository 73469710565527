import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { TextField, Button, MenuItem, Grid } from "@material-ui/core";
import {
    convert,
    DateTimeFormatter,
    DateTimeParseException,
    LocalDate
} from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { LocalDateUtils } from "../../../util/FixedJsJodaUtils";
import { useFormState } from "react-use-form-state";
import { getMemberInfoFormConfig } from './profileFormConfigs'

import cn from 'classnames'
import useResetPassword from "../../../hooks/useResetPassword";

import { getFormattedDate } from "../../../util/ignite/date.utils";
import { ProfilePanel } from "./ProfilePanel";
import { SupportedLanguages } from "../../../util/SupportedLanguages";
import FieldStatusIcon from "../registration/FieldStatusIcon";
import { states } from "../../../util/ignite/ClubSetup.utils";
import { IProfileInfo } from "../../../types/profile";
import postUpdateProfileMemberInfo from "../../../api/postUpdateProfileMemberInfo";
import useGetMyProfileInfo from "../../../hooks/useGetMyProfileInfo";

const initialState = {
    firstName: "",
    lastName: "",
    postalCode: "",
    phoneNumber: "",
    dateOfBirth: null,
    language: '',
    city: '',
    street: '',
    state: ''
}

export const MemberInfoPanel = () => {
    const { t } = useTranslation('ignite/myInfo');
    const [isPasswordResetSuccessful, resetPassword] = useResetPassword()
    const [isEditing, setIsMemberInfoEditing] = useState(false)
    const [profileInfo] = useGetMyProfileInfo()

    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [formState, { text, select }] = useFormState(initialState)
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const getSubmitEnabled = () => Object.entries(formState.validity).every(([key, value]) => value);

    const isValid = !dateOfBirthError && getSubmitEnabled()

    const isValidateDateOfBirth = (date: LocalDate | DateTimeParseException | null) => {
        if (date instanceof LocalDate) {
            const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
            const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

            return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate
        } else {
            return false
        }
    }

    const formInitialization = () => {
        const { firstName, lastName, postalCode, dateOfBirth, emailLocaleResponseBody, city, street, state } = profileInfo

        const getFormattedDate = () => {
            if (!dateOfBirth) {
                return null
            } else {
                return LocalDate.parse(dateOfBirth, DateTimeFormatter.ISO_LOCAL_DATE)
            }
        }

        formState.setField('postalCode', postalCode)
        formState.setField('firstName', firstName)
        formState.setField('lastName', lastName)
        formState.setField('language', emailLocaleResponseBody?.emailLocale)
        formState.setField('city', city)
        formState.setField('street', street)
        formState.setField('state', state)

        if (dateOfBirth) formState.setField('dateOfBirth', getFormattedDate())
    }

    useEffect(() => {
        formInitialization()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileInfo])

    const elementConfig = getMemberInfoFormConfig({ t })

    const onMemberInfoEdit = () => {
        setIsMemberInfoEditing(true)
    }

    const cancelEditing = () => {
        setIsMemberInfoEditing(false)
        formInitialization()
    }

    const checkValidation = (date: LocalDate) => {
        if (!isValidateDateOfBirth(date)) {
            setDateOfBirthError(t('errors.dateOfBirth'))
        } else {
            setDateOfBirthError('')
        }
    }

    const updateMemberInfo = () => {
        setIsMemberInfoEditing(false)
        const formattedDoB = formState.values.dateOfBirth?.toString()
        postUpdateProfileMemberInfo({
            FirstName: formState.values.firstName,
            LastName: formState.values.lastName,
            DateOfBirth: formattedDoB,
            PostalCode: formState.values.postalCode,
            Street: formState.values.street,
            City: formState.values.city,
            State: formState.values.state,
            EmailLocale: formState.values.language
        })
    }

    const renderBirthDate = () => (
        isEditing || Boolean(dateOfBirthError) ? (
            <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
                <KeyboardDatePicker
                    className={cn('date-of-birth-picker', { 'read-only': !isEditing })}
                    value={formState.values.dateOfBirth}
                    label={isEditing ? t('dob') : ''}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    views={["year", "month", "date"]}
                    openTo="year"
                    disableFuture
                    size="small"
                    id="date-of-birth-id"
                    InputAdornmentProps={{
                        classes: {
                            root: 'icon',
                        }
                    }}
                    minDate="1900-01-02"
                    minDateMessage={t('dobMin')}
                    maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                    maxDateMessage={t('dobMax')}
                    initialFocusedDate="1900-01-02"
                    error={isEditing && Boolean(dateOfBirthError)}
                    readOnly={!isEditing}
                    InputLabelProps={{
                        htmlFor: 'date-of-birth-id',
                        required: false,
                        classes: {
                            root: 'label-root dob',
                            error: 'label-error',
                            focused: 'label-focused'
                        }
                    }}

                    FormHelperTextProps={{
                        classes: {
                            root: 'error-label'
                        }
                    }}
                    InputProps={{
                        readOnly: !isEditing,
                        classes: {
                            input: 'profile-item__input dob',
                            notchedOutline: 'wrapper',
                        },
                    }}
                    helperText={isEditing && dateOfBirthError}
                    onBlur={() => checkValidation(formState.values.dateOfBirth)}
                    onChange={date => {
                        formState.setField('dateOfBirth', date)
                        checkValidation(date)
                    }}
                    style={{ width: '100%'}}
                />
            </MuiPickersUtilsProvider>
        ) :
        <>
        <label className='profile-item__label'>
            {t('dob')}
        </label>
        {getFormattedDate(formState.values.dateOfBirth?.toString(), { month: 'long', day: 'numeric', year: 'numeric' })}
        </>

        
    )

    const renderState = () => {
        if(!isEditing){
            return states.find(({id, label}) => { 
                if(formState.values.state === id) return label;
            })?.label ?? "";
        }
        return(
            <TextField
                {...select(elementConfig.state)}
                label={isEditing ? t('state') : ''}
                variant="outlined"
                select={true}
                classes={{ root: 'select' }}
                InputProps={{
                    id: "language-id",
                    readOnly: !isEditing,
                    classes: {
                        notchedOutline: 'wrapper',
                        root: 'base-input'
                    },
                }}
                SelectProps={{
                    classes: {
                        root: 'select-base',
                        icon: 'icon'
                    }
                }}
                InputLabelProps={{
                    classes: {
                        root: "profile-item__input-label",
                        error: "error",
                        focused: 'focused',
                    }
                }}
                size='small'
                style={{width: '100%', pointerEvents: 'all'}}
                error={isEditing && formState.errors.state !== undefined}
                helperText={isEditing && formState.errors.state}
            >
                {states.map(state => (
                    <MenuItem
                        key={state.id}
                        value={state.id}
                    >
                        {state.label}
                    </MenuItem>
                ))}
            </TextField>
        );
    }

    const renderLanguage = () => {
        if(!isEditing) {
            if(formState.values.language === 'es'){
                return SupportedLanguages.es;
            }
            return SupportedLanguages.en;
        }
        return (
            <TextField
                {...select(elementConfig.language)}
                label={isEditing ? t('preferredLanguage') : ''}
                variant="outlined"
                select={true}
                classes={{ root: 'select' }}
                InputProps={{
                    id: "language-id",
                    readOnly: !isEditing,
                    classes: {
                        notchedOutline: 'wrapper',
                        root: 'base-input'
                    },
                }}
                SelectProps={{
                    classes: {
                        root: 'select-base',
                        icon: 'icon'
                    }
                }}
                InputLabelProps={{
                    classes: {
                        root: "profile-item__input-label",
                        error: "error",
                        focused: 'focused',
                    }
                }}
                error={isEditing && formState.errors.language !== undefined}
                helperText={isEditing && formState.errors.language}
                size='small'
                style={{width: '100%', pointerEvents: 'all'}}
            >
                {Object.entries(SupportedLanguages).map(supportedLanguage => (
                    <MenuItem
                        key={supportedLanguage[0]}
                        value={supportedLanguage[0]}
                    >
                        {supportedLanguage[1]}
                    </MenuItem>
                ))}
            </TextField>
        );
    }

    const renderTextField = (config: {name: string, validate: (value: string) => any; }) => (
        <TextField
            {...text(config)}
            error={isEditing && Boolean(formState.errors[config.name])}
            helperText={isEditing && formState.errors[config.name]}
            id={`${config.name}-id`}
            fullWidth
            size="small"
            variant="outlined"
            label={isEditing ? t(config.name) : ''}
            InputLabelProps={{
                htmlFor: `${config.name}-id`,
                required: false,
                classes: {
                    root: 'label-root',
                    error: 'label-error',
                    focused: 'label-focused'
                }
            }}
            FormHelperTextProps={{
                classes: {
                    root: 'error-label'
                }
            }}
            InputProps={{
                readOnly: !isEditing,
                classes: {
                    input: 'profile-item__input',
                    notchedOutline: 'wrapper',
                },
                endAdornment: isEditing && <FieldStatusIcon shouldShow={formState.touched[config.name]} isError={formState.errors[config.name]} />
                
            }}
            
        />
    )

    return (
        <>
            <ProfilePanel
                className='personal-info'
                title={t('tabs.memberInfo')}
                isEditing={isEditing}
                onEdit={onMemberInfoEdit}
            >
                <>
                <Grid container spacing={2}>
                    {isEditing && (
                    <Grid item xs={12}>
                        <div className="subtitle">
                            {t('updatePersonalInfo')}
                        </div>
                    </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                        {!isEditing && (
                        <label className='profile-item__label' htmlFor="first-name-id">
                            {t('firstName')}
                        </label>
                        )}
                        {renderTextField(elementConfig.firstName)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!isEditing && (
                        <label className='profile-item__label' htmlFor="lastName-id">
                            {t('lastName')}
                        </label>
                        )}
                        {renderTextField(elementConfig.lastName)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {renderBirthDate()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!isEditing && (
                        <label className='profile-item__label' htmlFor="postalCode-id">
                            {t('postalCode')}
                        </label>
                        )}
                        {renderTextField(elementConfig.postalCode)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!isEditing && (
                        <label className='profile-item__label' htmlFor="street-id">
                            {t('street')}
                        </label>
                        )}
                        {renderTextField(elementConfig.street)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!isEditing && (
                        <label className='profile-item__label' htmlFor="city-id">
                            {t('city')}
                        </label>
                        )}
                        {renderTextField(elementConfig.city)}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!isEditing && (
                        <label className='profile-item__label' htmlFor="state-id">
                            {t('state')}
                        </label>
                        )}
                        {renderState()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!isEditing &&
                            <label className='profile-item__label' style={{width: '100%'}}>
                                {t('preferredLanguage')}
                            </label>
                        }
                        {renderLanguage()}
                    </Grid>
                    {!isEditing && (
                    <Grid item xs={12}>
                        <label className='profile-item__label'>
                            {t('passwordReset')}
                        </label>
                        <Button
                            className='reset-button'
                            variant="outlined"
                            color="primary"
                            onClick={() => resetPassword(profileInfo.email)}
                            disabled={isPasswordResetSuccessful}
                            classes={{
                                disabled: 'disabled-button'
                            }}
                        >
                            {isPasswordResetSuccessful ? t('resetSent') : t('sendResetLinkText')}
                        </Button>
                    </Grid>
                    )}
                </Grid>

                {isEditing && (
                    <div className='button-wrapper'>
                        <Button
                            className='button'
                            variant="outlined"
                            color="primary"
                            onClick={() => cancelEditing()}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            className='button update'
                            variant="outlined"
                            color="primary"
                            classes={{
                                disabled: 'disabled'
                            }}
                            disabled={!isValid}
                            onClick={() => {
                                updateMemberInfo()
                            }}
                        >
                            {t('update')}
                        </Button>
                    </div>
                )}
                </>
            </ProfilePanel>
        </>
    )
}
