import React, { useContext } from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import { E3_ROUTES } from "../constants/routes";
import { UserContext } from "../contexts/UserContext";

const UnregisteredRoute = ({
    component: Component,
    ...rest
}: any) => {
    const { isLoggedIn } = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={(props: RouteProps) => !isLoggedIn() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={E3_ROUTES.ROOT}
                />
            )
            }
        />
    );
};

export default UnregisteredRoute
