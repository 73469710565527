import React from "react";

export interface PdfDocumentIconProps {
    className?: string
}

function PdfDocumentIcon(props: PdfDocumentIconProps): JSX.Element {
    return (<>
        <svg viewBox="0 0 49 64" width={49} height={64} className={props.className}>
            <g fillRule="evenodd">
                <path
                    d="M49 16.842v42.105C49 61.738 46.73 64 43.931 64H5.069C2.269 64 0 61.738 0 58.947V5.053C0 2.262 2.269 0 5.069 0h27.034L49 16.842z"
                    fill="#cb0606"
                />
                <path
                    d="M49 15.899v2.096H35.187c-2.86 0-4.179-2.32-4.179-5.181V0H33.1L49 15.899z"
                    fill="#fb8d8d"
                />
                <path
                    d="M37.606 34.047a4.015 4.015 0 00-1.261-.217c-.46 0-.776.095-.949.285-.172.19-.258.526-.258 1.009v.923h1.933v1.854h-1.933v7.635h-2.342v-7.635h-1.207v-1.854h1.207v-.923c0-1.176.292-2.026.876-2.551.584-.525 1.462-.787 2.636-.787.551 0 1.135.075 1.752.226l-.454 2.035zm-9.81 10.566a5.849 5.849 0 01-1.38.878c-.484.217-1.034.325-1.651.325-1.198 0-2.115-.431-2.75-1.293-.636-.863-.953-2.081-.953-3.655 0-1.61.349-2.861 1.048-3.754.699-.892 1.68-1.339 2.945-1.339.448 0 .907.092 1.379.276.472.184.863.418 1.171.701v-4.686h2.342v13.47h-2.151v-.923zm-.191-5.934a3.757 3.757 0 00-.921-.593 2.624 2.624 0 00-1.121-.257c-.629 0-1.134.27-1.515.809-.382.54-.572 1.271-.572 2.194 0 .911.156 1.625.467 2.144.312.519.794.778 1.448.778.387 0 .782-.089 1.184-.267a3.794 3.794 0 001.03-.656v-4.152zm-10.01 6.522c-.575.41-1.261.615-2.06.615-1.053 0-1.951-.328-2.695-.986v4.279h-2.342V36.047h2.16v.931a5.623 5.623 0 011.375-.882c.481-.214 1.033-.321 1.656-.321 1.204 0 2.121.422 2.75 1.267.629.844.944 2.071.944 3.681 0 1.02-.154 1.918-.463 2.696-.309.778-.75 1.372-1.325 1.782zm-1.107-6.599c-.321-.516-.799-.773-1.434-.773-.381 0-.773.089-1.175.266a3.927 3.927 0 00-1.039.656v4.152c.248.224.555.421.921.593s.742.258 1.13.258c.641 0 1.148-.273 1.52-.819.372-.546.558-1.271.558-2.175 0-.923-.161-1.642-.481-2.158z"
                    fill="#fff"
                />
            </g>
        </svg>
    </>);
}

export default PdfDocumentIcon;