
import { loadConfig } from '../services/ConfigService'
import { ITerm } from '../types/clubSetup';
import i18next from "i18next";

export const GET_ALL_TERMS_URL = '/all-terms/'
   
export const getAllVirtualActivityTypes = async (): Promise<{ allTerms: ITerm[] } | undefined> => {
    const config = await loadConfig();
    const termType = 1;
    const request = new Request(`${config.apiOrigin}${GET_ALL_TERMS_URL}${termType}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18next.language || 'en-US'
        }
    });

    const response = await fetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export const getAllInPersonActivityTypes = async (): Promise<{ allTerms: ITerm[] } | undefined> => {
    const config = await loadConfig();
    const termType = 2;
    const request = new Request(`${config.apiOrigin}${GET_ALL_TERMS_URL}${termType}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18next.language || 'en-US'
        }
    });

    const response = await fetch(request);
    if (response?.ok) {
        return response.json()
    }
}
