import { loadConfig } from '../services/ConfigService'
import { IClub } from '../types/clubFinder'

export const CLUB_BY_SHORT_CODE_URL = '/ignite/club/get/short-code/'

const getClubByShortCode = async (shortCode: string, requireIgnite?: boolean): Promise<IClub | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${CLUB_BY_SHORT_CODE_URL}${shortCode}?at=${requireIgnite ?? true}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await fetch(request);

    if (response.ok) {
        return response.json()
    }
}

export default getClubByShortCode
