import React from "react";
import "./BorderedRow.scss";
import {RouteComponentProps, withRouter} from "react-router-dom";


export interface BorderedRowProps extends RouteComponentProps {
    className?: string;
    showTopBorder: boolean;
    link?: string;
    onClick?: Function;
    children: any;
}

function BorderedRow(props: BorderedRowProps): JSX.Element {
    let classes = 'BorderedRow';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    if (props.showTopBorder) {
        classes += " BorderedRow_TopBorder";
    }

    let makeClickable = false;
    let link = props.link;

    if (link !== undefined || props.onClick !== undefined) {
        classes += " BorderedRow_Link";
        makeClickable = true;
    }

    function onClick(e: any) {
        if (link === undefined && props.onClick === undefined) {
            e.stopPropagation();
            return;
        }

        if (link !== undefined) {
            props.history.push(link);

            e.stopPropagation();
            return;
        }

        if (props.onClick !== undefined) {
            props.onClick();

            e.stopPropagation();
            return;
        }
    }

    return (
        <>
            {!makeClickable && <div className={`${classes}`}>{props.children}</div>}
            {makeClickable && <div onClick={onClick} className={`${classes}`}>{props.children}</div>}
        </>
    );
}


export interface BorderedRowItemProps {
    className?: string;
    children: any;
}

export function BorderedRowItem(props: BorderedRowItemProps): JSX.Element {
    let classes = 'BorderedRowItem';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (
        <div className={`${classes}`}>
            {props.children}
        </div>
    );
}


export function BorderedRowLastItem(props: BorderedRowItemProps): JSX.Element {
    let classes = 'BorderedRowLastItem';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (
        <div className={`${classes}`}>
            {props.children}
        </div>
    );
}

export default withRouter(BorderedRow);