import React from "react"

const ActivityIcon = ({ className}: { className?: string }) => (
    <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_696_51902)">
            <path className="path" d="M0.5 21.3815C0.5 20.5492 1.11553 19.8563 1.92075 19.7886L7.4218 19.3107L10.2981 12.2064C10.6427 11.3622 11.4789 10.8445 12.3654 10.9241C13.2558 11.0038 13.9913 11.6688 14.1771 12.5688L16.4457 23.5956L21.0022 2.29459C21.2035 1.35079 22.0009 0.673812 22.9378 0.645937C23.8901 0.610096 24.734 1.26319 24.9624 2.21494L29.3757 19.3824L34.0831 19.8523C34.8922 19.932 35.5077 20.6368 35.5 21.4771C35.4923 22.3134 34.8612 23.0063 34.0483 23.066L27.8194 23.5558C26.8942 23.5558 26.0774 22.9187 25.8373 21.9988L23.1701 11.6449L18.3813 33.9973C18.1723 34.957 17.3438 35.638 16.3915 35.642C15.4353 35.638 14.6107 34.949 14.4094 33.9853L11.5486 20.0475L10.6737 22.2058C10.3601 22.9864 9.6168 23.4961 8.79222 23.4961L1.94011 22.9744C1.12714 22.9187 0.5 22.2218 0.5 21.3815Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_696_51902">
                <rect width="35" height="35" fill="white" transform="translate(0.5 0.645996)" />
            </clipPath>
        </defs>
    </svg>
)

export default ActivityIcon
