import { loadConfig } from "../../services/ConfigService";
import React, { useEffect } from "react";

// This is a JSX file to avoid TypeScript errors with undefined global variables in the code below.

// Not really related to this file, but the multi-env GA setup was largely
// based on https://organicdigital.co/blog/how-to-set-up-google-analytics-and-ga4-on-multiple-environments/

export default function GoogleTagManager() {

    useEffect(() => {
        (async () => {
            const {
              gtmId,
              gtmEnv,
              gtmAuth
            } = await loadConfig();
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                                              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl+
                `&gtm_auth=${gtmAuth}&gtm_preview=${gtmEnv}&gtm_cookies_win=x`;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer',gtmId);
        })();
    }, []);

    return <></>
}
