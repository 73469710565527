import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./RsvpList.scss";
import {
    GetUserEventRelationshipResponseBody,
    GetUserEventRelationshipsResponseBody,
    UserEventRelationshipStatus
} from "../../services/ClubLeaderService";
import {FormControl, Switch} from "@material-ui/core";
import {CCStyles} from "../form/Theme";
import BorderedRow, {BorderedRowItem, BorderedRowLastItem} from "../common/BorderedRow";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/UserService";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import Loading from "../common/Loading";
import SaveIndicator from "../common/SaveIndicator";
import {userNameSort} from "../../util/Util";

interface ChangeUserEventRelationshipRequestBody {
    userId: number;
    status: UserEventRelationshipStatus;
}

export interface RsvpListProps {
    className?: string;
    eventUrlFragment: string;
    sharedStateUpdater: string;
    setSharedStateUpdater: Function;
}

function combine(responseBody: GetUserEventRelationshipsResponseBody) {
    let tempPresentUsers = new Array<GetUserEventRelationshipResponseBody>();
    responseBody.userEventRelationships.map((rsvp) => {
        if (rsvp.status === UserEventRelationshipStatus.Rsvp
            || rsvp.status === UserEventRelationshipStatus.Attended
            || rsvp.status === UserEventRelationshipStatus.AttendedWithoutRsvp) {
            tempPresentUsers.push(rsvp);
        }
        return true;
    });

    return {present: tempPresentUsers.sort(userNameSort)};
}

function count(allUsers: Array<GetUserEventRelationshipResponseBody>) {
    let newRsvpCount = 0;
    allUsers.map((responseBody) => {
        if (responseBody.status === UserEventRelationshipStatus.Rsvp) {
            newRsvpCount++;
        }
        return true;
    })

    return {rsvpCount: newRsvpCount};
}

function getGetRequest(apiOrigin: string, eventUrlFragment: string) {
    const getUrl = `${apiOrigin}/club-leader/events/get-user-event-relationships/${eventUrlFragment}/false`;

    return new Request(getUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default function RsvpList(props: RsvpListProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let initialClasses = 'RsvpList';
    if (props.className !== undefined) {
        initialClasses += ' ' + props.className;
    }

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [savingSuccessful, setSavingSuccessful] = useState(false);
    const [savingMessage, setSavingMessage] = useState<string | null>(null);
    const [savingAttempted, setSavingAttempted] = useState(false);
    const [numberOfRsvps, setNumberOfRsvps] = useState(0);
    const [changes, setChanges] = useState(new Array<ChangeUserEventRelationshipRequestBody>());
    const [presentUsers, setPresentUsers] = useState(new Array<GetUserEventRelationshipResponseBody>());

    useEffect(
        () => {
            async function load() {
                setLoading(true);
                const config = await loadConfig();
                const getRequest = getGetRequest(config.apiOrigin, props.eventUrlFragment);
                const getResponse = await authenticatedFetch(getRequest);

                if (getResponse === null || !getResponse?.ok) {
                    setLoading(false);
                    setLoadingMessage(t('rsvpList.loadingError'));
                    return;
                }

                const responseBody: GetUserEventRelationshipsResponseBody = await getResponse.json();

                setPresentUsers(combine(responseBody).present);
                setNumberOfRsvps(count(combine(responseBody).present).rsvpCount);
                setLoading(false);
            }

            load().then();
        },
        [props.eventUrlFragment, props.sharedStateUpdater, t]);

    async function submitAttendance() {
        let requestBody = {
            eventUrlFragment: props.eventUrlFragment,
            ChangeUserEventRelationshipRequestBodyList: changes
        };

        setChanges([]);

        setSavingAttempted(true);
        setSaving(true);

        const config = await loadConfig();

        const url = `${config.apiOrigin}/club-leader/events/change-user-event-relationship`;

        const request = new Request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        const response = await authenticatedFetch(request);

        if (response === null || !response?.ok) {
            setSaving(false);
            setSavingSuccessful(false);
            setSavingMessage(t('rsvpList.submitError'));
            console.log(response);

            return;
        }

        const getRequest = getGetRequest(config.apiOrigin, props.eventUrlFragment);
        const getResponse = await authenticatedFetch(getRequest);

        if (getResponse === null || !getResponse?.ok) {
            setSaving(false);
            setSavingSuccessful(false);
            setSavingMessage(t('rsvpList.submitError'));
            console.log(getResponse);

            return;
        }

        setSaving(false);
        setSavingSuccessful(true);
        setSavingMessage(null);

        const responseBody: GetUserEventRelationshipsResponseBody = await getResponse.json();

        setPresentUsers(combine(responseBody).present);
        props.setSharedStateUpdater(new Date().toString());
    }


    return (
        <div className={`${initialClasses}`}>
            {(saving) &&
            <Loading className={"RsvpList_SavingLoading"} loading={(saving)}/>}
            {(numberOfRsvps <= 0) &&
            <div>
                <div className="RsvpList_Title">{numberOfRsvps} {t('rsvpList.RSVPText')}</div>
                <div className="RsvpList_Instructions">
                    {t('rsvpList.noRSVP')}
                </div>
            </div> }
            {(numberOfRsvps > 0) && <div
                className={`'RsvpList_Content' ${saving && 'RsvpList_NowSaving'}`}>
                {(loading) && <Loading loading={(loading)}/>}
                {loadingMessage !== null &&
                <div className={"RsvpList_LoadingMessage"}>{loadingMessage}</div>}
                {!(loading) && loadingMessage === null &&
                <>
                    <div className="RsvpList_Title">{numberOfRsvps} {t('rsvpList.RSVPText')}</div>
                    <div className="RsvpList_Instructions">
                        {t('rsvpList.rsvpListInstructions')}
                    </div>
                    <Update savingAttempted={savingAttempted} savingMessage={savingMessage}
                            savingSuccessful={savingSuccessful} submitAttendance={submitAttendance}
                            numberOfRsvps={numberOfRsvps}/>
                    {presentUsers.map((rsvp, index) =>
                        <RsvpRow changes={changes} setChanges={setChanges} key={rsvp.userEmail}
                                 rsvp={rsvp}
                                 index={index} setSavingAttempted={setSavingAttempted}/>
                    )}
                </>
                }
                <Update savingAttempted={savingAttempted} savingMessage={savingMessage}
                        savingSuccessful={savingSuccessful} submitAttendance={submitAttendance}
                        numberOfRsvps={numberOfRsvps}/>
            </div>
            }
        </div>
    );
}

interface UpdateProps {
    savingAttempted: boolean;
    savingMessage: string | null;
    savingSuccessful: boolean;
    submitAttendance: Function;
    numberOfRsvps: number;
}

function Update(props: UpdateProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    return (<div
            className="RsvpList_AttendanceUpdate">
            <PlayButton buttonStyle={ButtonStyles.FilledPrimary}
                        clickHandler={props.submitAttendance}
                        text={t('rsvpList.updateRSVPButtonText')}
                        className="RsvpList_AttendanceUpdateButton"/>
            {props.savingAttempted && !props.savingSuccessful &&
            <SaveIndicator success={props.savingSuccessful}
                           className="RsvpList_SavingSuccess"
                           message={props.savingMessage}/>}
            <div className="RsvpList_AttendanceUpdateTitle">
                {props.numberOfRsvps}&nbsp;
                {props.numberOfRsvps === 1 && " " + t('rsvpList.singularRSVP')}
                {props.numberOfRsvps !== 1 && " " + t('rsvpList.multipleRSVP')}
            </div>
        </div>
    );
}

export interface RsvpRowProps {
    className?: string;
    index: number;
    changes: Array<ChangeUserEventRelationshipRequestBody>;
    setChanges: Function;
    setSavingAttempted: Function;
    rsvp: GetUserEventRelationshipResponseBody
}

export function RsvpRow(props: RsvpRowProps) {
    let classes = 'RsvpRow';

    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let didRsvp = (props.rsvp.status === UserEventRelationshipStatus.Rsvp);

    const [checked, setChecked] = useState(didRsvp);
    //const [currentStatus, setCurrentStatus] = useState(props.rsvp.status);

    const muiClasses = CCStyles();

    function makeChange(userId: number, status: UserEventRelationshipStatus) {
        let newChanges = new Array<ChangeUserEventRelationshipRequestBody>();
        props.changes.map((change) => {
            if (change.userId !== userId) {
                newChanges.push(change);
            }
            return true;
        });
        newChanges.push({userId: userId, status: status});
        props.setChanges(newChanges);
    }

    function toggle() {
        props.setSavingAttempted(false);
        if (checked) {
            makeChange(props.rsvp.userId, UserEventRelationshipStatus.NoRsvp);
            setChecked(false);
            //setCurrentStatus(UserEventRelationshipStatus.NoRsvp);
        } else {
            setChecked(true);
            makeChange(props.rsvp.userId, UserEventRelationshipStatus.Rsvp);
        }
    }

    return (
        <BorderedRow onClick={toggle} className={`${classes}`} showTopBorder={props.index === 0}>
            <BorderedRowItem className="RsvpRow_Switch">
                <FormControl className={`${muiClasses.root} ${muiClasses.formControl} `}
                             component="fieldset">
                    <Switch checked={checked} onChange={toggle}/>
                </FormControl>
            </BorderedRowItem>
            <BorderedRowItem className="RsvpRow_Name">
                {props.rsvp.userFirstName} {props.rsvp.userLastName}
            </BorderedRowItem>
            <BorderedRowLastItem className="RsvpRow_Contact">
                <div><a href={`mailto:${props.rsvp.userEmail}`}>{props.rsvp.userEmail}</a></div>
                <div><a href={`tel:${props.rsvp.userPhone}`}>{props.rsvp.userPhone}</a></div>
            </BorderedRowLastItem>
        </BorderedRow>
    );
}
