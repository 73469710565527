import { useState, useEffect } from "react";
import getClubByShortCode from "../api/getClubByShortCode";
import { IClub } from "../types/clubFinder";

const useClubByShortCode = (shortCode: string, skipRequest?: boolean, requireIgnite?: boolean): [IClub | undefined, boolean] => {
    const [club, setClub] = useState<IClub | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!skipRequest) {
            setIsLoading(true)

            getClubByShortCode(shortCode, requireIgnite)
                .then(data => setClub(data))
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [])

    return [club, isLoading]
}

export default useClubByShortCode
