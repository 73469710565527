import {FormControl, MenuItem, Select, TextField} from "@material-ui/core";
import Button, {ButtonStyles} from "../common/Button";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import {GetPassionsResponseBody} from "../../services/MemberService";
import {loadConfig} from "../../services/ConfigService";
import {UserContext} from "../../contexts/UserContext";
import {CCStyles} from "../form/Theme";
import MuiSelectElement from "../form/MuiSelectElement";
import {ClubSearchParams, ClubSearchResponseBody} from "../../pages/findAClub/FindAClubPage";
import {RouteComponentProps, withRouter} from "react-router-dom";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";
import PlayButton from "../common/PlayButton";
import {GetPassionResponseBody} from "../../services/Models";

export interface FindAClubFormProps extends RouteComponentProps {
    isNextButtonEnabled: boolean;
    isPreviousButtonEnabled: boolean;
    defaultLimit: number | null;
    searchResponseBody: ClubSearchResponseBody | null;
    searching: boolean;
    initialSearchParams?: ClubSearchParams | null | undefined;
    children?: any;
    searchingEnabled: boolean;
    parentPage: ParentPage;
}

export enum ParentPage {
    MyEventsPage,
    FindClubPage
}

function FindAClubForm(props: FindAClubFormProps): JSX.Element {
    const { t } = useTranslation('findAClub');
    const {user, authenticatedFetch} = useContext(UserContext);

    const [postalCode, setPostalCode] = useState<string | null>((user !== null && user.postalCode !== null) ? user.postalCode : null);
    const [activePassions, setActivePassions] = useState<GetPassionResponseBody[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [passionId, setPassionId] = useState<number | null>(null);
    const [searchingEnabled, setSearchingEnabled] = useState<boolean>(true);

    const classes = CCStyles();

    let searchResponseBody = props.searchResponseBody;
    let defaultLimit = props.defaultLimit;
    let searching = props.searching;
    let isPreviousButtonEnabled = props.isPreviousButtonEnabled;
    let children = props.children;
    let isNextButtonEnabled = props.isNextButtonEnabled;
    let externalSearchingEnabled = props.searchingEnabled;
    let parentPage = props.parentPage;

    const searchButtonText = (searchResponseBody === null) ? t('findAClubForm.searchButtonText') : t('findAClubForm.searchAgainButtonText');

    async function onClickPreviousButton() {
        if (searchResponseBody === null) {
            // TODO: Throw an error here?
            return;
        }

        const {skip, limit} = searchResponseBody;
        const newSkip = Math.max(0, skip - limit);
        updateSearchParams({postalCode, passionId, skip: newSkip, limit});
        window.scrollTo(0,0);
    }

    async function onClickNextButton() {
        if (searchResponseBody === null) {
            // TODO: Throw an error here?
            return;
        }

        const {skip, limit} = searchResponseBody;
        const newSkip = skip + limit;
        updateSearchParams({postalCode, passionId, skip: newSkip, limit});
        window.scrollTo(0,0);
    }

    function onChangePassion(event: ChangeEvent<MuiSelectElement>) {
        setSearchingEnabled(true);
        if (typeof event.target.value === 'number'
            && activePassions !== null
            && activePassions.map(p => p.id).includes(event.target.value)) {

            setPassionId(event.target.value);
        } else {
            setPassionId(null);
        }
    }

    function onChangePostalCode(event: ChangeEvent<HTMLInputElement>) {
        setSearchingEnabled(true);
        setPostalCode(event.target.value);
    }

    async function onSubmitSearchForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const skip = null;

        const limit =
            (searchResponseBody === null || searchResponseBody.limit === defaultLimit)
                ? null
                : searchResponseBody.limit;

        updateSearchParams({postalCode, passionId, skip, limit});
    }

    function updateSearchParams({postalCode, passionId, skip, limit}: ClubSearchParams) {
        const searchParams = new URLSearchParams(props.location.search);

        if (postalCode === null) {
            searchParams.delete("postalCode");
        } else {
            searchParams.set("postalCode", postalCode);
        }

        if (passionId === null) {
            searchParams.delete("passionId");
        } else {
            searchParams.set("passionId", passionId.toString());
        }

        if (skip === null || skip === 0) {
            searchParams.delete("skip");
        } else {
            searchParams.set("skip", skip.toString());
        }

        if (limit === null && defaultLimit !== null) {
            searchParams.set("limit", defaultLimit.toString());
        } else if (limit === null) {
            searchParams.delete("delete");
        } else {
            searchParams.set("limit", limit.toString());
        }

        const newUrl = `/find-a-club?${searchParams.toString()}`;
        props.history.push(newUrl);
        window.scrollTo(0,0);
    }

    useEffect(
        () => {
            const loadSearchParams = async () => {
                if (activePassions === null) {
                    return;
                }

                const searchParams = new URLSearchParams(props.location.search);
                const postalCode = searchParams.get("postalCode");
                const passionIdString = searchParams.get("passionId");

                if (postalCode === null) {
                    return;
                }

                setPostalCode(postalCode);

                if (passionIdString !== null) {
                    const parsed = Number.parseInt(passionIdString, 10);
                    if (activePassions.some(passion => passion.id === parsed)) {
                        setPassionId(parsed);
                    }
                }
            };

            loadSearchParams();
        },
        [props.location, authenticatedFetch, activePassions]);

    useEffect(
        () => {
            const loadPassionsFromApi = async () => {
                if (activePassions !== null) {
                    return;
                }

                setLoading(true);
                setLoadingErrorMessage(null);

                const config = await loadConfig();

                const activePassionsRequest =
                    new Request(
                        `${config.apiOrigin}/active-passions`,
                        {method: "GET", headers: {"Accept": "application/json"}});

                const activePassionsResponse = await authenticatedFetch(activePassionsRequest);

                if (activePassionsResponse === null) {
                    // Login required.  The user just became null, so this page is about to be re-rendered.
                    return;
                }

                if (!activePassionsResponse.ok) {
                    const responseBodyText = await activePassionsResponse.text();
                    console.error(
                        `Non-successful response from API: `
                        + `${activePassionsResponse.status} ${activePassionsResponse.statusText} `
                        + `from ${activePassionsResponse.url}\n\n${responseBodyText}`);
                    setLoading(false);
                    setLoadingErrorMessage(t('findAClubForm.unexpectedError'));
                    return;
                }

                const passionsResponseBody: GetPassionsResponseBody =
                    await activePassionsResponse.json();

                setActivePassions(passionsResponseBody.passions);

                setLoading(false);
                setLoadingErrorMessage(null);
            };

            loadPassionsFromApi();
        },
        [user, authenticatedFetch, activePassions, t]);

    return (
        <>
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <LoadingMessage message={loadingErrorMessage}/>
            )}
            {!loading && loadingErrorMessage === null && parentPage === ParentPage.MyEventsPage &&
            <>
                <form className={`${classes.root} FindAClubForm`} onSubmit={onSubmitSearchForm}>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} FindAClubForm_FormControl`}
                        component="div">
                        <div className={"FindAClubForm_SearchFields"}>
                            <div className="FindAClubForm_PassionSelectWrapper">
                                <PassionSelect
                                    passionId={passionId}
                                    passions={activePassions}
                                    loading={loading}
                                    onChangePassion={onChangePassion}/>
                            </div>
                            <div className="FindAClubForm_ZipCodeWrapper">
                                <ZipCodeSelect
                                    onChangePostalCode={onChangePostalCode}
                                    postalCode={postalCode}
                                />
                            </div>
                        </div>
                        <div className="FindAClubForm_SubmitButtonWrapper">
                            <SubmitButton
                                searching={searching}
                                searchingEnabled={searchingEnabled}
                                externalSearchingEnabled={externalSearchingEnabled}
                                searchButtonText={searchButtonText}
                                buttonStyle={ButtonStyles.UnfilledWithBorder}/>
                        </div>
                    </FormControl>
                </form>
            </>
            }
            {!loading && loadingErrorMessage === null && parentPage === ParentPage.FindClubPage &&
            <>
                <form className={`${classes.root} FindAClubForm`} onSubmit={onSubmitSearchForm}>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} ClubSearchPage_FormControl`}
                        component="fieldset">
                        <div className={"ClubSearchFields"}>
                            <div className="ClubSearchPage_PassionSelectWrapper">
                                <PassionSelect
                                    passionId={passionId}
                                    passions={activePassions}
                                    loading={loading}
                                    onChangePassion={onChangePassion}/>
                            </div>
                            <div className="ClubsNearText">
                                {t('findAClubForm.clubsNear')}
                            </div>
                            <div className="ClubSearchPage_ZipCodeWrapper">
                                <ZipCodeSelect
                                    onChangePostalCode={onChangePostalCode}
                                    postalCode={postalCode}
                                />
                            </div>
                            <SubmitButton
                                searching={searching}
                                searchingEnabled={searchingEnabled}
                                externalSearchingEnabled={externalSearchingEnabled}
                                searchButtonText={searchButtonText}
                                buttonStyle={ButtonStyles.FilledPrimary}
                            />
                        </div>
                    </FormControl>
                </form>
                {children}
                {(isPreviousButtonEnabled || isNextButtonEnabled) && (
                    <>
                        <div className="PreviousAndNextButtons">
                            <Button clickHandler={onClickPreviousButton}
                                    buttonStyle={ButtonStyles.Unfilled}
                                    saving={searching}
                                    disabled={!isPreviousButtonEnabled}
                                    className="PreviousButton">
                                <SkipPreviousIcon/>
                                {t('findAClubForm.previousButtonText')}
                            </Button>
                            <Button clickHandler={onClickNextButton}
                                    buttonStyle={ButtonStyles.Unfilled}
                                    saving={searching}
                                    disabled={!isNextButtonEnabled}
                                    className="NextButton">
                                {t('findAClubForm.nextButtonText')}
                                <SkipNextIcon/>
                            </Button>
                        </div>
                    </>
                )}
            </>
            }
        </>
    );
}

interface ZipCodeSelectProps {
    postalCode: string | null;
    onChangePostalCode: Function;
    className?: string;
}

function ZipCodeSelect(props: ZipCodeSelectProps): JSX.Element {
    const { t } = useTranslation('findAClub');
    const classes = CCStyles();

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        props.onChangePostalCode(event);
    }

    return (
        <TextField value={(props.postalCode === null) ? "" : props.postalCode}
                   onChange={onChange}
                   variant="outlined"
                   required
                   className={`${classes.root} ${props.className !== undefined && props.className}`}
                   placeholder={t('findAClubForm.zipCodePlaceholder')}
                   inputProps={{maxLength: 10}}/>
    );
}

interface PassionSelectProps {
    loading: boolean;
    onChangePassion: Function;
    passionId: number | null;
    passions: GetPassionResponseBody[] | null;
}

function PassionSelect(props: PassionSelectProps): JSX.Element {
    const { t } = useTranslation('findAClub');
    const classes = CCStyles();

    function onChange(event: ChangeEvent<MuiSelectElement>) {
        props.onChangePassion(event);
    }

    return (
        <Select value={(props.passionId === null) ? "" : props.passionId}
                className={`${classes.root} ClubSearchPage_PassionSelect`}
                variant="outlined"
                disabled={props.loading}
                displayEmpty
                onChange={onChange}>
            <MenuItem value="">
                {t('findAClubForm.passionsPlaceholder')}
            </MenuItem>
            {props.passions !== null && Object.values(props.passions).map(passion => (
                <MenuItem key={passion.id} value={passion.id}>
                    {passion.passionName}
                </MenuItem>
            ))}
        </Select>
    );
}

interface SubmitButtonProps {
    searchButtonText: string;
    externalSearchingEnabled: boolean;
    searchingEnabled: boolean;
    searching: boolean;
    buttonStyle: ButtonStyles;
}

function SubmitButton(props: SubmitButtonProps): JSX.Element {

    return (
        <PlayButton type="submit"
                    buttonStyle={props.buttonStyle}
                    saving={props.searching}
                    disabled={!props.searchingEnabled && props.externalSearchingEnabled}
                    className="SearchButton"
                    text={props.searchButtonText}/>

    );
}

export default withRouter(FindAClubForm);

