import React, { useState, useEffect } from "react";
import { useParams, Route, Switch } from "react-router-dom";
import Tabs from "../../../components/ignite/Profile/Tabs";
import { MemberInfo } from "../../../components/ignite/Profile/MemberInfo";
import Clubs from "../../../components/ignite/Profile/Clubs";
import useClubById from "../../../hooks/useClubById";
import { IGNITE_ROUTE_PATTERNS } from '../../../constants/routes'
import Loading from "../../../components/ignite/layout/Loading";

import EligibilityStatusModal from "../../../components/eligibilityStatusModal/EligibilityStatusModal";
import { Notifications } from "../../../components/ignite/Profile/Notifications";
import SubHeader from "../../../components/ignite/SubHeader/SubHeader";
import useGetMyClubs from "../../../hooks/useGetMyClubs";
import useGetMyProfileInfo from "../../../hooks/useGetMyProfileInfo";
import "../../../components/ignite/Profile/Profile.scss";
import { HealthPlan } from "../../../components/ignite/Profile/HealthPlan";
import ClubJoinedModal from "../../clubDirectory/registration/ClubJoinedModal";

export const Profile = () => {
    const { clubId } = useParams()
    const [profileInfo, isProfileInfoLoading] = useGetMyProfileInfo()
    const [isEligibilityModalOpen, setEligibilityModalOpenStatus] = useState(false);
    const [isClubJoinedModalOpen, setIsClubJoinedModalOpen] = useState(false);

    const [club, isClubLoading] = useClubById(clubId)
    const [myClubs, isMyClubLoading, fetchClubs] = useGetMyClubs()

    const { clubName, clubImage } = club || {}

    const isLoading = isClubLoading || isProfileInfoLoading || isMyClubLoading;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const showClubJoinedMessage = queryParams.get("showClubJoinedMessage");
        if (showClubJoinedMessage === "true") {
            setIsClubJoinedModalOpen(true);
        }
    }, [])

    if (isLoading) return <Loading loading={isLoading} />

    function closeClubJoinedModal() {
        window.history.pushState({}, document.title,  window.location.pathname);
        setIsClubJoinedModalOpen(false);
    }

    return (
        <div>
            {isEligibilityModalOpen && (
                <EligibilityStatusModal isOpen={isEligibilityModalOpen} onClose={() => setEligibilityModalOpenStatus(false)} />
            )}
            {isClubJoinedModalOpen && (
                <ClubJoinedModal open={isClubJoinedModalOpen} onClose={() => closeClubJoinedModal()} />
            )}
            <div className='profile-page'>
                {club && (
                    <SubHeader
                        clubName={clubName}
                        myClubs={myClubs}
                        clubImage={clubImage}
                        location={club.location}
                    />
                )}
                <div className="content">
                    <Tabs />
                    <div className="content-main">
                        <Switch>
                            <Route path={IGNITE_ROUTE_PATTERNS.MEMBER_INFO} render={() => <MemberInfo profileInfo={profileInfo} />} />
                            <Route path={IGNITE_ROUTE_PATTERNS.MY_CLUBS} render={() => <Clubs email={profileInfo.email} showAssociations={false} />} />
                            <Route path={IGNITE_ROUTE_PATTERNS.MY_ASSOCIATIONS} render={() => <Clubs email={profileInfo.email} showAssociations={true} />} />
                            <Route path={IGNITE_ROUTE_PATTERNS.NOTIFICATIONS} render={() => <Notifications />} />
                            <Route path={IGNITE_ROUTE_PATTERNS.HEALTH_PLAN} render={() => <HealthPlan setEligibilityModalOpenStatus={setEligibilityModalOpenStatus}/>} />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}
