import React, { useContext } from "react";
import {Route, RouteProps} from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import ExternalRedirect from '../components/common/ExternalRedirect';

/**
 * For use when you generally want to redirect to an external site, but want
 * the ability to render different logged in/out components, instead of
 * redirecting, when in "forced CC mode."
 *
 * Forced CC Mode is enabled if there is a URL search parameter of name `mode`
 * and value `cc` (`?mode=cc`).
 *
 * In summary, this allows for different components to be rendered when:
 * - Logged in and in forced CC mode: render a component
 * - Logged in and not in forced CC mode: redirect to chosen URL
 * - Logged out and in forced CC mode: render a different component
 * - Logged out and not in forced CC mode: redirect to different chosen URL
 *
 * Additionally, you can choose to forward search parameters. If set to true,
 * any search parameters on the CC route will be combined with any search
 * parameters that exist on the chosen redirect URL, allowing for use cases
 * such as forwarding a memberCode parameter.
 */
const ConditionalRedirectRoute = ({
    /* Component to render when logged in, if not redirecting (or when mode=cc) */
    loggedInComponent: LoggedInComponent,
    /* Component to render when logged out, if not redirecting (or when mode=cc) */
    loggedOutComponent: LoggedOutComponent,
    /* Redirect URL when logged in (if mode=cc is not passed in). Leave undefined for no redirection when logged in. */
    loggedInRedirectUrl,
    /* Redirect URL when logged out (if mode=cc is not passed in). Leave undefined for no redirection when logged out. */
    loggedOutRedirectUrl,
    /* Should we pass along any search parameters on this page when forwarding? */
    forwardSearchParameters,
    ...rest
}: any) => {
    const { isLoggedIn } = useContext(UserContext)
    const ccMode = new URLSearchParams(window.location.search).get('mode') === 'cc'
    return (
        <Route
            {...rest}
            render={(props: RouteProps) =>
                isLoggedIn() ? (
                    (ccMode || !loggedInRedirectUrl) ? (
                        <LoggedInComponent {...props} />
                    ) : (
                        <ExternalRedirect redirectUrl={loggedInRedirectUrl}
                                          forwardSearchParameters={forwardSearchParameters}
                                          {...props} />
                    )
                ) : (
                    (ccMode || !loggedOutRedirectUrl) ? (
                        <LoggedOutComponent {...props} />
                    ) : (
                        <ExternalRedirect redirectUrl={loggedOutRedirectUrl}
                                          forwardSearchParameters={forwardSearchParameters}
                                          {...props} />
                    )
                )
            }
        />
    );
};

export default ConditionalRedirectRoute
