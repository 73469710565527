import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, FormControl, IconButton, InputAdornment, OutlinedInput } from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

interface ISearchPanelProps {
    searchValue: string
    onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
    onCleanSearch: () => void
    onCloseSearching: (e: React.MouseEvent) => void
    onClickSearch: (e: React.MouseEvent | React.KeyboardEvent) => void
}

const SearchPanel = ({
    searchValue,
    onChangeSearch,
    onCleanSearch,
    onCloseSearching,
    onClickSearch
}: ISearchPanelProps): JSX.Element => {

    const { t } = useTranslation('ignite/dashboard');

    return (
        <div className="search-box-row">
            <FormControl variant="standard" className="form-control">
            <label htmlFor="search-id" hidden aria-hidden="true">{t('search')}</label>
                <SearchIcon className="search-icon" />
                <OutlinedInput
                    className='search-input'
                    fullWidth
                    id="search-id"
                    value={searchValue}
                    placeholder={t('searchPlaceholder')}
                    onChange={onChangeSearch}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.stopPropagation();
                            onClickSearch(e)
                        }
                    }}
                    endAdornment={(
                        <InputAdornment position="end">
                            <IconButton
                                onClick={onCleanSearch}
                                edge="end"
                            >
                                <CloseIcon className='close-icon' />
                                <span hidden aria-label="Close">close</span>
                            </IconButton>
                        </InputAdornment>
                    )}
                />

                <Button
                    className='search-button'
                    onClick={onClickSearch}
                >
                    {t('search')}
                </Button>
                <CloseIcon className='big-close-icon' onClick={onCloseSearching} />
            </FormControl>
        </div>
    )
}

export default SearchPanel
