import React, {useContext} from "react";
import {GetPayerResponseBody} from "../services/MemberService";

export type StepProps = {
    onContinue: () => void,
    onPrevious: () => void
}

export interface RegistrationState<T> {
    wizardStep: T;
    setWizardStep: (step: T) => void;
}

export interface MemberRegistrationInitialState {
    email: string;
    password: string;
    selectedPayer: GetPayerResponseBody | null;
    planType: string;
    insuranceId: string;
    firstName: string;
    lastName: string;
    phone: string;
    zipCode: string;
    dateOfBirth: string;
}

export const RegistrationContext: React.Context<RegistrationState<number>> =
    React.createContext({
        wizardStep: 1,
        setWizardStep: (step: number) => {}
    });

export function useRegistrationWizard<T>(): RegistrationState<T> {
    // TypeScript will be upset that this is actually a number, not whatever enum was
    // passed in, but it's "overridden" mostly as a convenience for type intelligence.
    // @ts-ignore
    return useContext<RegistrationState<T>>(RegistrationContext);
}
