import { loadConfig } from '../services/ConfigService'
import { GetClubResponseBody } from '../services/MemberService';
import { getJsonAuth } from '../services/RequestService';
import { authenticatedFetch } from '../services/UserService';

export const CLUB_BY_ID_URL = '/clubs/get/'

// Differs from src/api/getClubByUrlFragment.ts because src/api/getClubByUrlFragment.ts only fetches ignite enabled clubs
const getMemberClubByUrlFragment = async (urlFragment: string): Promise< GetClubResponseBody | undefined> => {

    const config = await loadConfig();
    return await getJsonAuth({
        url: `${config.apiOrigin}${CLUB_BY_ID_URL}${urlFragment}`,
        searchParams: {},
        authenticatedFetch
    });
}

export default getMemberClubByUrlFragment
