import {TFunction} from "i18next";

export enum EventType {
    // TODO: Maybe we should be using numeric values defined by the API here.
    //       Note that the strings on the right hand side are what gets sent to
    //       the API.
    SOCIAL = "SOCIAL",
    SOLO = "SOLO",
    EDUCATIONAL = "EDUCATIONAL",
    GROUP_ACTIVITY = "GROUP_ACTIVITY",
    SELF_GUIDED = "SELF_GUIDED"
}

export function labelForEventType(t: TFunction, eventType: EventType): string {
    switch (eventType) {
        case EventType.EDUCATIONAL:
            return t('eventTypes.educational')
        case EventType.GROUP_ACTIVITY:
            return t('eventTypes.groupActivity');
        case EventType.SELF_GUIDED:
            return t('eventTypes.selfGuided');
        case EventType.SOCIAL:
            return t('eventTypes.social');
        case EventType.SOLO:
            return t('eventTypes.solo');
    }
}

export function isDeprecatedEventType(eventType : EventType) {
    return eventType === EventType.EDUCATIONAL || eventType === EventType.GROUP_ACTIVITY || eventType === EventType.SELF_GUIDED;
}