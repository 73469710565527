import { loadConfig } from '../services/ConfigService'
import { IClub } from '../types/clubFinder'
import {authenticatedFetch, IsIgniteEnabledResponseBody, setIsIgniteEnabled} from '../services/UserService'

const CONFIRM_REFERRAL_URL = '/ignite/confirm-referral'

const postConfirmReferral = async (referralId?: number, clubId?: number): Promise<IClub | undefined | void> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${CONFIRM_REFERRAL_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ReferralId: referralId, ClubId: clubId,
        })
    })

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        const responseBody: IsIgniteEnabledResponseBody = await response.json().catch(err => console.log(err));
        const isIgniteEnabled: IsIgniteEnabledResponseBody = {
            isLeader: responseBody.isLeader,
            isReferral: responseBody.isReferral,
            clubId: responseBody.clubId
        };
        setIsIgniteEnabled(isIgniteEnabled);
    }
}

export default postConfirmReferral
