import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./MyEventsPage.scss";
import { UserContext } from "../../contexts/UserContext";
import UpcomingEvents from "../../components/event/UpcomingEvents";
import MyEvents from "../../components/event/MyEvents";
import SectionHeader from "../../components/common/SectionHeader";
import { EventView } from "../../components/findAnEvent/FindAnEventForm";
import EventViewTypeSelect from "../../components/event/EventViewTypeSelect";
import FeaturedEvents from "../../components/event/FeaturedEvents";
import HaveQuestions from "../../components/haveQuestions/HaveQuestions";
import Loading from "../../components/common/Loading";
import LoadingMessage from "../../components/common/LoadingMessage";
import { getFavoritePassions } from "../../services/MyProfileService";
import {
    getHasRegisteredEvents, GetHasRegisteredEventsResponseBody,
    GetPassionsResponseBody
} from "../../services/MemberService";
import Button, { ButtonStyles } from "../../components/common/Button";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { EventSearchResponseBody } from "../findAnEvent/FindAnEventPage";
import { USE_EDP_44_VIRTUAL_CLUBS } from "../../constants/flags";
import { useFeatureFlag } from "../../services/FeatureFlagService";
import MyEventsNoPagination from "../../components/event/MyEventsNoPagination";

interface MyEventsPageParams {
}


export default function MyEventsPage(props: MyEventsPageParams): JSX.Element {
    const { t } = useTranslation('pages');
    const {user} = useContext(UserContext);
    const [view, setView] = useState<EventView>(EventView.LIST);
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const {authenticatedFetch} = useContext(UserContext);
    const [hasRegisteredEvents, setHasRegisteredEvents] = useState<boolean>(false);
    const [favoritePassionsSelected, setFavoritePassionsSelected] = useState<boolean>(false);
    const [featuredEventIds, setFeaturedEventIds] = useState<number[]>([]);
    const [featuredEventsLoaded, setFeaturedEventsLoaded] = useState<boolean>(false);

    const useEdp44VirtualClubs : boolean = useFeatureFlag(USE_EDP_44_VIRTUAL_CLUBS, false);

    function onFeaturedEventsLoaded(responseBody: EventSearchResponseBody) {
        setFeaturedEventIds(responseBody.results.map(result => result.eventId));
        setFeaturedEventsLoaded(true);
    }

    useEffect(() => {
        (async () => {
            let favoritePassions: GetPassionsResponseBody;
            let responseBody: GetHasRegisteredEventsResponseBody;
            try {
                [
                    favoritePassions,
                    responseBody
                ] = await Promise.all([
                    getFavoritePassions({ authenticatedFetch }),
                    getHasRegisteredEvents({ authenticatedFetch })
                ]);
            } catch (e) {
                setLoadingMessage(t('myEvents.loadingError'));
                setLoading(false);
                return;
            }

            setFavoritePassionsSelected(!!favoritePassions.passions.length);
            setHasRegisteredEvents(responseBody.hasRegisteredEvents);

            setLoading(false);
        })();
    }, [t]);

    return (
        <>
            {loading && <Loading className="PageLoading" loading={loading}/>}
            {loadingMessage !== null && (
                <LoadingMessage className={"PageLoadingMessage"}
                                message={loadingMessage}/>
            )}
            {user !== null &&
            <div className="MyEventsPage">
                <div className={"MyEventsPage_WelcomeBack"}>
                    {t('myEventsPage.welcome', {"name": user.firstName})}
                </div>
                <div className={"MyEventsPage_HeaderLine"}>
                    <SectionHeader title={t('myEventsPage.registeredEvents')} />
                    <EventViewTypeSelect view={view} setView={setView} />
                </div>
                    {
                        useEdp44VirtualClubs
                            ? <MyEventsNoPagination
                                view={view}
                                setView={setView}
                                favoritePassionsSelected={favoritePassionsSelected}
                                hasRegisteredEvents={hasRegisteredEvents}
                            />
                            : <MyEvents view={view}
                                setView={setView}
                                favoritePassionsSelected={favoritePassionsSelected}
                                hasRegisteredEvents={hasRegisteredEvents}
                            />
                    }
                {
                    // We are not sure why non-registered upcoming events are displayed on My Schedule page
                    /*
                    hasRegisteredEvents && featuredEventsLoaded && <>
                        <SectionHeader title={t('myEventsPage.upcomingEvents')}
                                    className={"MyEventsPage_UpcomingEventsHeader"}/>
                        <UpcomingEvents excludeIds={featuredEventIds}/>
                    </>
                    */
                }
        
                <SectionHeader title={t('myEventsPage.eventsYouMightLike')}
                               className={"MyEventsPage_FeaturedEventsHeader"}/>
                <FeaturedEvents onLoad={onFeaturedEventsLoaded}/>
                {favoritePassionsSelected && <FindVirtualEvents />}
            </div>
            }
        </>
    );
}

interface FindVirtualEventsProps extends RouteComponentProps {
}

const FindVirtualEvents = withRouter(function(props: FindVirtualEventsProps): JSX.Element {
    const { t } = useTranslation('pages');

    function goToFindVirtualEvents() {
        props.history.push(`/find-an-event?virtualOnly=true`);
    }

    return (
        <div className={"MyEventsPage_FindVirtualEvents"}>
            <div
                className={"MyEventsPage_FindVirtualEvents_Top"}
                dangerouslySetInnerHTML={{__html: t('myEventsPage.areYouAHomebody') }}/>
            <Button
                className={"MyEventsPage_FindVirtualEvents_Button"}
                buttonStyle={ButtonStyles.FilledPrimary}
                clickHandler={goToFindVirtualEvents}>{t('myEventsPage.findVirtualEvents')}</Button>
        </div>
    )
})

