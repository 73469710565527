import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import {useFormState} from "react-use-form-state";
import { postJsonAuth } from '../../../services/RequestService';
import { loadConfig } from '../../../services/ConfigService';
import { authenticatedFetch } from '../../../services/UserService';
import { useTranslation } from 'react-i18next';
import "./RecordActivityForm.scss";
import useCheckDevice from '../../../hooks/useCheckDevice';
import getActivityRecordingFormViaSanity, { ActivityRecordingFormSanityData, ActivityRecordingFormSanityResponse } from '../../../sanity/getActivityRecordingFormViaSanity';
import Loading from '../../../components/common/Loading';
import PortableText from 'react-portable-text';
import { UserContext } from '../../../contexts/UserContext';

interface RecordActivityFormProps{
    onNext: () => void;
    clubId: number;
}

type ActivityRecord = {
    month: number;
    year: number;
    activityTotalInPerson: number;
}

interface ReportActivityParams {
    activityRecords : ActivityRecord[];
    clubId: number;
}

const DTC_ACTIVITY_RECORDING_FORM_SLUG = "dtcarf";

export default function RecordActivityForm(props: RecordActivityFormProps){
    const {t: monthTranslations} = useTranslation('pages');
    const {t: formTranslations} = useTranslation('directToConsumer/activityRecording');
    const [months, setMonths] = useState<{[name: string]: {month: number, year: number}}>({});
    const [error, setError] = useState<string | undefined>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const { isDesktop } = useCheckDevice();
    const {refreshUser} = useContext(UserContext);

    const [formState, {number}] = useFormState();
    const [formConfig, setFormConfig] = useState<ActivityRecordingFormSanityData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {

        const months = [
            monthTranslations("newMemberRegistration.months.1"),
            monthTranslations("newMemberRegistration.months.2"),
            monthTranslations("newMemberRegistration.months.3"),
            monthTranslations("newMemberRegistration.months.4"),
            monthTranslations("newMemberRegistration.months.5"),
            monthTranslations("newMemberRegistration.months.6"),
            monthTranslations("newMemberRegistration.months.7"),
            monthTranslations("newMemberRegistration.months.8"),
            monthTranslations("newMemberRegistration.months.9"),
            monthTranslations("newMemberRegistration.months.10"),
            monthTranslations("newMemberRegistration.months.11"),
            monthTranslations("newMemberRegistration.months.12")
        ];
        const dateIterator = new Date();
        dateIterator.setMonth(dateIterator.getMonth() - 1);
        let previousMonths: {[key: string]: {month: number, year: number}} = {};
        for (let i = 0; i < 6; i++) {
            previousMonths[months[dateIterator.getMonth()]] = {
                month: dateIterator.getMonth(), 
                year: dateIterator.getFullYear(),
            };
            dateIterator.setMonth(dateIterator.getMonth() - 1);
        }
        setMonths(previousMonths);

        getActivityRecordingFormViaSanity(DTC_ACTIVITY_RECORDING_FORM_SLUG).then((data: ActivityRecordingFormSanityResponse | undefined) => {
            setFormConfig(data?.result[0]);
        }).finally(() => setLoading(false))
    },[]);

    const submitEnabled = () => {
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);
        return allInputsTouched && allValuesValid && !submitting;
    }

    const handleSubmitActivity = async () => {
        let activityRecords : Array<ActivityRecord> = [];
        for (const activityMonth in months) {
            const {month, year} = months[activityMonth];
            activityRecords.push({
                month: month + 1,
                year,
                activityTotalInPerson: formState.values[`month${month}inperson`],
            });
        }
        try{
            const parameters : ReportActivityParams = {
                activityRecords,
                clubId: props.clubId,
            };

            setSubmitting(true);
            const config = await loadConfig();
            await postJsonAuth({
                authenticatedFetch: authenticatedFetch,
                url: `${config.apiOrigin}/ignite/submit-member-activity-with-enrollment`,
                data: parameters
            });
            await refreshUser();

            props.onNext();
        }catch(e){
            setError(e as string);
        } finally{
            setSubmitting(false);
        }
    }

    const commonValidator = (value: string): string | undefined => {
        if (!value?.trim()) {
            return (' ');
        }

        if(parseInt(value) > 200){
            return (' ');
        }
    }

    const renderMonths = () => {
        let monthRows: Array<JSX.Element> = [];
        for (const month in months)
            monthRows.push(<Grid item xs={12} sm={6} md={4} key={month}>{renderMonthRow(month, months[month].month)}</Grid>)
        return monthRows;
    }
    
    const renderMonthRow = (month: string, monthIterator: number) => {
        return(
            <Grid container spacing={2}>
                <Grid item xs={3} sm={6} style={{fontWeight: 600, fontSize: 14, paddingTop: 18, textAlign: 'right'}}>
                    {month}
                </Grid>
                <Grid item xs={9} sm={6}>
                    <Box sx={{display: 'flex'}}>
                <TextField
                    variant="outlined"
                    size='small'
                    inputProps={{min: 0}}
                    required
                    {...number({
                        name: `month${monthIterator}inperson`,
                        validate: commonValidator,
                        validateOnBlur: true
                    })}
                    error={formState.errors[`month${monthIterator}inperson`] !== undefined}
                />
                </Box>
                </Grid>
            </Grid>
        );
    }
    return(
        loading ?
        <Loading loading={loading} />
        :        
        <div className='record-activity'>
            {
                Array.isArray(formConfig?.title) ?
                <div className='portable-title'><PortableText content={formConfig!.title} /> </div>
                :
                <div className='title'>{formConfig?.title ?? formTranslations("title")}</div>
            }
        <Grid
            container
            spacing={isDesktop ? 4 : 0}
            className='abc'
        >
            <Grid item xs={12}>
                <div style={{marginBottom: 20, fontSize: 16, fontWeight: 700 }}>
                    {formConfig?.headline ?? formTranslations("fieldInstructions")}
                </div>
            </Grid>
            <Grid item xs={12}>
                {
                    Array.isArray(formConfig?.description) ?
                    <PortableText content={formConfig!.description} />
                    :
                    <div
                        style={{fontSize: 14, fontWeight: 400}}
                        dangerouslySetInnerHTML={{__html: formTranslations("helperInstructions")}}
                    />
                }
            </Grid>

            <Grid item xs={12} >
                <Grid container spacing={2} style={{marginTop: 16}}>
                    {months && renderMonths()}
                    <Grid item xs={12}>
                    {  Object.keys(formState.errors).length > 0 && (
                        <div style={{color: '#f00', marginTop: 24}}>
                            {formTranslations("errors.activityValidation")}
                        </div>
                    )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <div 
            className="direct-to-consumer-landing-page"
            style={{display: 'flex', marginTop: 32, justifyContent: 'flex-end'}}
        > 
            <Button
                size="large"
                color="primary"
                variant="contained"
                className="button"
                onClick={handleSubmitActivity}
                disabled={!submitEnabled()}
            >
                {submitting &&  <CircularProgress size={18} style={{ position: 'absolute' }}/>}
                {formTranslations("buttons.submit")}
            </Button>
        </div>
        </div>
    );
};