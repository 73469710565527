import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {useFormState} from "react-use-form-state";
import {TextField} from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

export interface MemberZipCode {
  zipCode: string
}

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void
}

export function MemberZipStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const initialState = {
        zipCode: "",
    }

    const [formState, {text}] = useFormState(initialState);

    const elementConfig = {
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value.trim()) {
                    //return 'Zip Code is required';
                    return " ";
                }
                if (!value.match(zipCodeRegex)) {
                    //return "Invalid zip code";
                    return " ";
                }
            },
            validateOnBlur: true
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",formState.values);
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = () => {
        props.onContinue({zip: formState.values.zipCode});
    }

    return (
        <div className={"MemberRegistrationWizard_Wrapper"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                    <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <h2>{t('memberZipStep.zipCodePrompt')}</h2>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth margin="normal">
                        <TextField {...text(elementConfig.zipCode)}
                                            className="MemberRegistrationWizard_Input"
                                            label={t('memberZipStep.zipCode')}
                                            required
                                            variant={"outlined"}
                                            error={formState.errors.zipCode !== undefined}
                                            size="small"/>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                    <FormControl>
                        <PlayButton type="button"
                                    clickHandler={onContinueSelected}
                                    text={t('memberZipStep.continueButtonText')}
                                    className="MemberRegistrationWizard_SubmitButton"
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={!continueEnabled()}
                                    id="ZipSubmit"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
    }
