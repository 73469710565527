import {BorderedRowItem} from "../common/BorderedRow";
import React from "react";

import "./EventListDateTimeItem.scss";
import DateView from "../common/DateView";
import TimeView from "../common/TimeView";
import { EventRecurrence } from "../../services/ClubLeaderService";
import { formatEventRecurrence } from "../../util/Event.utils";


export interface EventListDateTimeItemProps{
    endsAtInstant: string;
    startsAtInstant: string;
    recurrence?: EventRecurrence | null;
}

export default function EventListDateTimeItem(props: EventListDateTimeItemProps) : JSX.Element {

    return (
          <BorderedRowItem className="EventListDateTimeItem">
              <div className="EventListDateTimeItem_StartsAtDate">
                  <DateView date={props.startsAtInstant} format={{day: "numeric", month: "long", year: "numeric", weekday:'long'}}/>
              </div>
              <div className="EventListDateTimeItem_StartsAtTime">
                  <TimeView time={props.startsAtInstant} format={{hour: 'numeric', minute: '2-digit'}}/>-<TimeView
                  time={props.endsAtInstant} format={{hour: 'numeric', minute: '2-digit', timeZoneName: "short"}}/>
              </div>
              {
                props.recurrence && 
                <div>{formatEventRecurrence(props.recurrence)}</div>
              }
          </BorderedRowItem>
    );
}