import React, {  useContext, useEffect, useState } from "react";
import { Button, Grid } from '@material-ui/core';
import "./DirectToConsumerLandingPage.scss";
import getEnrollmentFlowLandingPageViaSanity, { 
    EnrollmentFlowLandingPageSanityData 
} from "../../sanity/getEnrollmentFlowLandingPageViaSanity";
import RegistrationForm from './form/RegistrationForm';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loading from "../../components/common/Loading";
import { UserContext } from "../../contexts/UserContext";
import MemberOfClubModal from "./components/MemberOfClubModal";
import { useHistory } from "react-router-dom";
import { storeRegistrationParametersToSession } from "../../util/Util";
import { ArrowRight } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const D2C_ENROLLMENT_SLUG = 'd2c';

interface DirectToConsumerLandingPageProps {
}

export default function DirectToConsumerLandingPage(props: DirectToConsumerLandingPageProps): JSX.Element {
    const {user} = useContext(UserContext);
    const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);
    const [landingPageConfig, setLandingPageConfig] = useState<EnrollmentFlowLandingPageSanityData | undefined>(undefined);
    const history = useHistory();
    const { t } = useTranslation('pages');

    useEffect(() => {
        getEnrollmentFlowLandingPageViaSanity(D2C_ENROLLMENT_SLUG).then(data => {
            setLandingPageConfig(data?.result[0]);
            setQueryResultsLoading(false);
        });

        storeRegistrationParametersToSession(history.location);

    }, []);

    const handleRedirectToRegistration = () => history.push("/register-member/byoc");

    if (queryResultsLoading) return <Loading loading={queryResultsLoading} />

    return (
        <>
        <div className="direct-to-consumer-landing-page">
                <Grid container spacing={10}>
                    <Grid item xs={12} sm={6}>
                        <div className={"title"}>
                            {landingPageConfig?.title}
                        </div>
                        <div className={"statement"}>
                            {landingPageConfig?.subtitle}
                        </div>
                        <Button
                            size="large"
                            color="primary"
                            variant="contained"
                            className="button"
                            onClick={handleRedirectToRegistration}
                        >
                            {landingPageConfig?.buttonText}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Carousel
                            autoPlay
                            infiniteLoop
                            showThumbs={false}
                            interval={5000}
                            className={'carousel'}
                        >
                            {
                                landingPageConfig?.headerImageUrls?.map(imageUrl => (
                                    <div key={imageUrl}>
                                        <img src={imageUrl} alt="" className="image"/>
                                    </div>
                                ))
                            }
                        </Carousel>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="promotion-logos">
                            {
                                landingPageConfig?.promotionImagesUrls?.map(imageUrl => (
                                    <div key={imageUrl}>
                                        <img src={imageUrl} alt="" style={{maxHeight: 91, maxWidth: 127}}/>
                                    </div>
                                ))
                            }
                        </div>
                    </Grid>

                    {landingPageConfig?.section1 &&!!landingPageConfig?.section1?.image &&
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <div className="section-header">
                                    {landingPageConfig.section1.title}
                                </div>
                                <div className="section-text">
                                    {landingPageConfig.section1.description1 && <><div>{landingPageConfig.section1.description1}</div><br/></>}
                                    {landingPageConfig.section1.description2 && <><div>{landingPageConfig.section1.description2}</div><br/></>}
                                    {landingPageConfig.section1.note &&<b>{landingPageConfig.section1.note}</b>}
                                </div>

                                <Button
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    className="button"
                                    onClick={() => user ? setOpen(true) : handleRedirectToRegistration()}
                                >
                                    {landingPageConfig?.section1.button}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img
                                    src={landingPageConfig.section1.image}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        borderRadius: 6,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                    {landingPageConfig?.incentive && !!landingPageConfig?.incentive?.image && (
                        <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div className="section-header">
                                    {landingPageConfig.incentive.title}
                                </div>
                                <div className="section-text">
                                    {landingPageConfig.incentive.description1 && <><div>{landingPageConfig.incentive.description1}</div><br/></>}
                                    {landingPageConfig.incentive.description2 && <><div>{landingPageConfig.incentive.description2}</div><br/></>}
                                    {landingPageConfig.incentive.note &&<b>{landingPageConfig.incentive.note}</b>}
                                </div>

                                {landingPageConfig.incentive.button && (
                                    <Button
                                        size="large"
                                        color="primary"
                                        variant="contained"
                                        className="button"
                                        onClick={() => {
                                            // TODO: this will be implemented in the future, for now it will be left
                                            // as a placeholder for incentive section.  Will not display as long as 
                                            // nothing populates corresponding sanity configuration   
                                        }}
                                    >
                                        {landingPageConfig?.incentive.button}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <img
                                    src={landingPageConfig.incentive.image}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        borderRadius: 6,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    )}
                </Grid>

                <div
                    style={{
                        margin: 48,
                        textAlign: 'center',
                        color: '#0177c0',
                        fontSize: '18px',
                        lineHeight: '24px',
                        cursor: 'pointer',
                        fontWeight: 700,
                        paddingLeft: '12px'
                    }}
                    onClick={() => window.open('https://hellogrouper.com/get-help/', "_blank")}
                >
                    <span>
                        {t('customEnrollmentLandingPage.faqLinkText')}
                    </span>
                    <ArrowRight style={{height: '24px', verticalAlign: 'text-top'}} />
                </div>
            
        </div>
        
        </>
    );
}
