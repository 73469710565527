import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import FieldStatusIcon from "../../../components/ignite/registration/FieldStatusIcon";
import { Button, TextField } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import Loading from "../../../components/ignite/layout/Loading";
import { useFormState } from "react-use-form-state";
import { useHistory, useParams } from "react-router-dom";
import { getElementConfig } from './formConfig'
import useCheckEligibility from '../../../hooks/useCheckEligibility'
import useGetPayers from "../../../hooks/useGetPayers";
import { getPayerAdditionalData } from "../../../util/ignite/Verification.utils";

import './CheckEligibility.scss';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocalDateUtils } from "../../../util/FixedJsJodaUtils";
import { Locale } from "@js-joda/locale_en-us";
import { convert, DateTimeFormatter, LocalDate } from "@js-joda/core";
import useGetMyProfileInfo from "../../../hooks/useGetMyProfileInfo";
import {UserContext} from "../../../contexts/UserContext";
import {getIgniteRoute} from "../../../util/ignite/routes.utils";
import {IGNITE_ROUTE_KEY} from "../../../constants/routes";

const VERIFICATION_STATUS_URL = '/ignite/verification-status/'
const OTHER_SLUG = 'other'

export default function CheckEligibility() {
    const { t } = useTranslation('ignite/verification');

    const initialState = {
        firstName: "",
        lastName: "",
        groupNumber: "",
        memberId: "",
        zipCode: "",
        dateOfBirth: null,
    }

    const [dateOfBirthError, setDateOfBirthError] = useState('')
    const [myProfileInfo, isMyProfileInfoLoading] = useGetMyProfileInfo()
    const [payers, isGetPayersLoading] = useGetPayers()
    const { payerSlug, payerId, clubId } = useParams()
    const history = useHistory()
    const { refreshUser } = useContext(UserContext);

    const payer = payers?.find(item => item.id === Number(payerId))
    const isOther = payer?.payerSlug === OTHER_SLUG

    const { logoSrc, cardSrc } = getPayerAdditionalData(payerSlug)
    const [formState, { text }] = useFormState(initialState);
    const elementConfig = getElementConfig(t)

    const submitEnabled = () => {
        const allInputsTouched = Object.entries(formState.touched).every(([, value]) => value);
        const allValuesValid = Object.entries(formState.validity).every(([, value]) => value);

        return allInputsTouched && allValuesValid && !dateOfBirthError;
    }

    const [, check] = useCheckEligibility()

    function onBackClick() {
        history.push(getIgniteRoute(IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_SELECTION, { ':clubId': clubId }))
    }

    async function handleSubmit() {
        const parameters: any = {
            FirstName: formState.values.firstName,
            LastName: formState.values.lastName,
            MemberId: formState.values.memberId,
            GroupNumber: formState.values.groupNumber,
            ZipCode: formState.values.zipCode,
            PayerId: Number(payerId),
            DateOfBirth: formState.values.dateOfBirth.toString(),
            ...(isOther ? { UserSuppliedPayerName: formState.values.userSuppliedPayerName } : {})
        };

        check(parameters).then(async data => {
            await refreshUser();
            history.push(`${VERIFICATION_STATUS_URL}${clubId}/${payerSlug}/${data?.id}`);
        })
    }

    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const validateDateOfBirth = (date: LocalDate) => {
        if (date instanceof LocalDate) {
            const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
            const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

            if (!isEqualOrAfterMinDate || !isEqualOrBeforeMaxDate) {
                setDateOfBirthError(t('eligibility.errors.dateOfBirth'))
            } else {
                setDateOfBirthError('')
            }
        } else {
            setDateOfBirthError(t('eligibility.errors.dateOfBirth'))
        }
    }

    useEffect(() => {
        const { firstName, lastName, postalCode, dateOfBirth, memberId, groupNumber, userSuppliedPayerName } = myProfileInfo
        const getFormattedDate = () => {
            if (!dateOfBirth) {
                return null
            } else {
                return LocalDate.parse(dateOfBirth, DateTimeFormatter.ISO_LOCAL_DATE)
            }
        }

        formState.setField('zipCode', postalCode)
        formState.setField('firstName', firstName)
        formState.setField('lastName', lastName)
        // Ensure that userSuppliedPayerName and memberId is only "touched" if there are values
        if (userSuppliedPayerName) {
            formState.setField('userSuppliedPayerName', userSuppliedPayerName)
        }
        if (memberId) {
            formState.setField('memberId', memberId)
        }
        formState.setField('groupNumber', groupNumber)
        if (dateOfBirth) formState.setField('dateOfBirth', getFormattedDate())

    }, [myProfileInfo])

    if (isGetPayersLoading || isMyProfileInfoLoading) return <Loading loading={isGetPayersLoading || isMyProfileInfoLoading} />

    return (
            <div className='check-eligibility'>
                <div className="check-eligibility__title">
                    {t('eligibility.letsCheckEligibility', { userSuppliedPayerName: isOther ? t('eligibility.payer') : payer?.payerName })}
                </div>

                <div className="check-eligibility-content">
                    {
                        !isOther && (
                            <div className="payer-info">
                                <div className="logo-wrapper">
                                    {Boolean(logoSrc) && (
                                        <div>
                                            <img className="logo" src={logoSrc} alt={t('payerSelection.logoAlt', { userSuppliedPayerName: payer?.payerName })} />
                                        </div>
                                    )}
                                </div>
                                <div className="main-info">
                                    <div className="main-info__description">
                                        {t('payerSelection.offers', { userSuppliedPayerName: payer?.payerName })}
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="form">
                        <div className="form__fields">
                            <div className='form__field'>
                                <TextField
                                    {...text(elementConfig.firstName)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: "first-name-id",
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            error: 'label-error'
                                        }
                                    }}
                                    fullWidth
                                    required
                                    label={t('eligibility.firstName')}
                                    helperText={formState.errors.firstName}
                                    error={Boolean(formState.errors.firstName)}
                                    variant='outlined'
                                    size='small'
                                    id="first-name-id"
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.firstName}
                                        isError={formState.errors.firstName}
                                    />
                                </div>
                            </div>

                            <div className='form__field'>
                                <TextField
                                    {...text(elementConfig.lastName)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: "last-name-id",
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            error: 'label-error'
                                        }
                                    }}
                                    fullWidth
                                    required
                                    label={t('eligibility.lastName')}
                                    helperText={formState.errors.lastName}
                                    error={Boolean(formState.errors.lastName)}
                                    variant='outlined'
                                    size='small'
                                    id="last-name-id"
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.lastName}
                                        isError={formState.errors.lastName}
                                    />
                                </div>
                            </div>

                            <div className='form__field'>
                                <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
                                    <KeyboardDatePicker
                                        {...text(elementConfig.dateOfBirth)}
                                        value={formState.values.dateOfBirth}
                                        label={t('eligibility.dateOfBirth')}
                                        inputVariant="outlined"
                                        format="MM/dd/yyyy"
                                        views={["year", "month", "date"]}
                                        openTo="year"
                                        disableFuture
                                        required
                                        minDate="1900-01-02"
                                        maxDate={convert(dateOfBirthMaxDate).toDate()}
                                        size="small"
                                        fullWidth
                                        helperText={dateOfBirthError}
                                        error={Boolean(dateOfBirthError)}
                                        InputLabelProps={{
                                            htmlFor: 'dob-id',
                                            required: false,
                                            classes: {
                                                root: 'label-root',
                                                focused: 'label-focused',
                                                error: 'label-error'
                                            }
                                        }}
                                        InputProps={{
                                            id: "dob-id",
                                            classes: {
                                                input: 'date-of-birth-input'
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            classes: {
                                                error: 'label-error'
                                            }
                                        }}
                                        onChange={date => {
                                            formState.setField('dateOfBirth', date)
                                            validateDateOfBirth(date)
                                        }}
                                        onBlur={(event: any) => {
                                            if (!event.target.value) {
                                                validateDateOfBirth(event.target.value)
                                            }
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.dateOfBirth || Boolean(dateOfBirthError)}
                                        isError={Boolean(dateOfBirthError)}
                                    />
                                </div>
                            </div>

                            {
                                isOther && (
                                    <div className='form__field'>
                                        <TextField
                                            {...text(elementConfig.userSuppliedPayerName)}
                                            classes={{
                                                root: 'registration-form__field_bold',
                                            }}
                                            InputLabelProps={{
                                                htmlFor: "payer-name-id",
                                                required: false,
                                                classes: {
                                                    root: 'label-root',
                                                    focused: 'label-focused',
                                                    error: 'label-error'
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: 'registration-form__field_bold'
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                classes: {
                                                    error: 'label-error'
                                                }
                                            }}
                                            fullWidth
                                            required
                                            label={t('eligibility.userSuppliedPayerName')}
                                            helperText={formState.errors.userSuppliedPayerName}
                                            error={Boolean(formState.errors.userSuppliedPayerName)}
                                            variant='outlined'
                                            size='small'
                                            id="payer-name-id"
                                        />
                                        <div className='field-indicator-wrapper'>
                                            <FieldStatusIcon
                                                shouldShow={formState.touched.userSuppliedPayerName}
                                                isError={formState.errors.userSuppliedPayerName}
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            <div className='form__field'>
                                <TextField
                                    {...text(elementConfig.memberId)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: "member-id",
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            error: 'label-error'
                                        }
                                    }}
                                    fullWidth
                                    required
                                    label={t('eligibility.memberId')}
                                    helperText={formState.errors.memberId}
                                    error={Boolean(formState.errors.memberId)}
                                    variant='outlined'
                                    size='small'
                                    id="member-id"
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.memberId}
                                        isError={formState.errors.memberId}
                                    />
                                </div>
                            </div>

                            <div className='form__field'>
                                <TextField
                                    {...text(elementConfig.groupNumber)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: "group-id",
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            error: 'label-error'
                                        }
                                    }}
                                    fullWidth
                                    label={t('eligibility.groupNumber')}
                                    helperText={formState.errors.groupNumber}
                                    error={Boolean(formState.errors.groupNumber)}
                                    variant='outlined'
                                    size='small'
                                    id="group-id"
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.groupNumber}
                                        isError={formState.errors.groupNumber}
                                    />
                                </div>
                            </div>

                            <div className='form__field'>
                                <TextField
                                    {...text(elementConfig.zipCode)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: "zip-code-id",
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            error: 'label-error'
                                        }
                                    }}
                                    fullWidth
                                    required
                                    label={t('eligibility.zipCode')}
                                    helperText={formState.errors.zipCode}
                                    error={Boolean(formState.errors.zipCode)}
                                    variant='outlined'
                                    size='small'
                                    id="zip-code-id"
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.zipCode}
                                        isError={formState.errors.zipCode}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card-wrapper">
                            {Boolean(cardSrc) && <img src={cardSrc} alt={`${payer?.payerName} ${t('eligibility.cardAlt')} `} className="card-img" />}
                        </div>
                    </div>
                </div>
                <div className="button-wrapper">
                    <Button
                        className='button'
                        variant="outlined"
                        onClick={onBackClick}
                        size='large'
                        color='primary'
                        disabled={false}
                    >
                        <PlayIcon className='button__icon rotate-icon' />
                        {t('eligibility.checkEligibilityBackButton')}
                    </Button>
                    <Button
                        className='button'
                        variant="outlined"
                        onClick={handleSubmit}
                        size='large'
                        color='primary'
                        classes={{
                            disabled: 'disabled'
                        }}
                        disabled={!submitEnabled()}
                    >
                        {t('eligibility.checkEligibility')}
                        <PlayIcon className='button__icon' />
                    </Button>
                </div>
            </div>
    );
}
