

import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const GET_MEMBER_CONTACT_INFO_URL = '/ignite/leader/get-member-contact-info/'

const getMemberContactInfo = async (clubId: number, userId: number): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_MEMBER_CONTACT_INFO_URL}${clubId}?userId=${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }

    const error = await response?.text()

    throw new Error(error)
}

export default getMemberContactInfo
