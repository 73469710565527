import React from 'react';
import {useTranslation} from 'react-i18next';

import "../../../components/registrationWizard/steps/RegistrationWizardStep.scss";
import {FormControl} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {EmailInput} from "../../../components/registrationWizard/inputs/EmailInput";
import {PasswordInput} from "../../../components/registrationWizard/inputs/PasswordInput";
import {TermsOfUseCheckbox} from "../../../components/registrationWizard/inputs/TermsOfUseCheckbox";
import {checkValidity, useCreateSubForm, SubFormContext} from "../../../util/Forms";
import {RegisterButton} from "../../../components/registrationWizard/inputs/RegisterButton";
import {StepProps} from "../../../util/registration-util";
import {BackButton} from "../../../components/registrationWizard/misc/BackButton";

export function MemberAccountStep(props: StepProps): JSX.Element {
    const {t} = useTranslation('pages');
    const {elementConfig, context, formState} = useCreateSubForm();

    const continueEnabled = () => checkValidity({elementConfig, formState});

    return (
        <SubFormContext.Provider value={context}>
            <div className={"RegistrationWizardStep MemberAccountStep"}>
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={2}>
                        <BackButton onClick={props.onPrevious} />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1} direction="column" alignItems="stretch">
                            <Grid item>
                                <h2 dangerouslySetInnerHTML={{__html:
                                        t('payerMemberRegistrationWizard.memberAccountStep.title')}}/>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <EmailInput/>
                                </FormControl>
                                <FormControl fullWidth margin="normal">
                                    <PasswordInput/>
                                </FormControl>
                                <FormControl>
                                    <TermsOfUseCheckbox/>
                                </FormControl>
                            </Grid>
                            <Grid item container justify="flex-end">
                                <FormControl>
                                    <RegisterButton onClick={props.onContinue}
                                                    enabled={continueEnabled()}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </SubFormContext.Provider>
    );
}
