import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./RsvpAttendanceList.scss";
import {
    GetUserEventRelationshipResponseBody,
    GetUserEventRelationshipsResponseBody,
    UserEventRelationshipStatus
} from "../../services/ClubLeaderService";
import {
    CircularProgress,
    FormControl,
    Switch,
    TextField
} from "@material-ui/core";
import {CCStyles} from "../form/Theme";
import BorderedRow, {BorderedRowItem, BorderedRowLastItem} from "../common/BorderedRow";
import {loadConfig} from "../../services/ConfigService";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import Loading from "../common/Loading";
import SaveIndicator from "../common/SaveIndicator";
import {userNameSort} from "../../util/Util";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {UserContext} from "../../contexts/UserContext";
import LoadingMessage from "../common/LoadingMessage";

interface ChangeUserEventRelationshipRequestBody {
    userId: number;
    status: UserEventRelationshipStatus;
}

export interface RsvpAttendanceListProps {
    className?: string;
    eventUrlFragment: string;
}

function combine(responseBody: GetUserEventRelationshipsResponseBody) {
    let tempPresentUsers = new Array<GetUserEventRelationshipResponseBody>();
    let tempPastUsers = new Array<GetUserEventRelationshipResponseBody>();
    responseBody.userEventRelationships.map((rsvp) => {
        if (rsvp.status === UserEventRelationshipStatus.Rsvp
            || rsvp.status === UserEventRelationshipStatus.Attended
            || rsvp.status === UserEventRelationshipStatus.AttendedWithoutRsvp) {
            tempPresentUsers.push(rsvp);
        }
        // We no longer ask the API to include past attendees for this page, so
        // we don't expect to see NoRsvp here.  But keep these out of the
        // `present` list just in case.
        else if (rsvp.status === UserEventRelationshipStatus.NoRsvp) {
            tempPastUsers.push(rsvp);
        }
        // this control doesn't use Cancelled RSVPs
        else if (rsvp.status === UserEventRelationshipStatus.RsvpCancelled) {}
        return true;
    });

    return {present: tempPresentUsers.sort(userNameSort), past: tempPastUsers.sort(userNameSort)};
}

function count(allUsers: Array<GetUserEventRelationshipResponseBody>) {
    let newAttendeeCount = 0;
    let newRsvpCount = 0;
    allUsers.map((responseBody) => {
        if (responseBody.status === UserEventRelationshipStatus.Attended
            || responseBody.status === UserEventRelationshipStatus.AttendedWithoutRsvp) {
            newAttendeeCount++;
        }
        if (responseBody.status === UserEventRelationshipStatus.Attended
            || responseBody.status === UserEventRelationshipStatus.Rsvp) {
            newRsvpCount++;
        }
        return true;
    })

    return {attendeeCount: newAttendeeCount, rsvpCount: newRsvpCount};
}

function getGetRequest(apiOrigin: string, eventUrlFragment: string) {
    const getUrl = `${apiOrigin}/club-leader/events/get-user-event-relationships/${eventUrlFragment}/false`;

    return new Request(getUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
}

/**
 * Properties for the {@link ManualAttendance} component.
 */
interface ManualAttendanceProps {
    /**
     * The URL fragment of the event for which attendance is being displayed.
     */
    eventUrlFragment: string

    /**
     * A callback function to be invoked when the club leader manually marks a
     * user as attended using this form.
     *
     * This function allows the {@link ManualAttendance} component to notify its
     * parent component that the attendance list should be refreshed.
     */
    onAttendanceChanged: () => void
}

/**
 * A component for finding users to manually add to an event's attendance list.
 */
const ManualAttendance = (props: ManualAttendanceProps) : JSX.Element => {
    const { t } = useTranslation('clubLeaderEvent');
    /**
     * A selectable option for the autocomplete widget, representing one user.
     */
    interface UserOption {
        /**
         * The label text for the user; a combination of their full name and
         * email address.  Note that upon selecting an option, the autocomplete
         * widget sets the input text to this label text.
         */
        userLabelText: string

        /**
         * The user's email address
         */
        userEmail: string

        /**
         * `true` if the user is already displayed in the attendance list.
         */
        isDisabled: boolean
    }

    interface MemberSearchRequestBody {
        eventUrlFragment: string
        query: string
    }

    interface MemberSearchResponseBody {
        results: MemberSearchResult[]
    }

    interface MemberSearchResult {
        email: string
        firstName: string
        lastName: string
        status: UserEventRelationshipStatus | null
    }

    const {eventUrlFragment, onAttendanceChanged} = props;

    const {authenticatedFetch} = useContext(UserContext);

    const [isOpen, setIsOpen] = useState(false);
    const [inputText, setInputText] = useState("");
    const [selectedOption, setSelectedOption] = useState<UserOption | null>(null);
    const [options, setOptions] = useState<UserOption[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [wasSaveAttempted, setWasSaveAttempted] = useState(false);
    const [wasSaveSuccessful, setWasSaveSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Respond to changes in the input text.
    // Searches for users matching the input.
    useEffect(() => {
        if (inputText === "" || (selectedOption !== null && inputText === selectedOption.userLabelText)) {
            setIsSearching(false);
            setOptions([]);
            return;
        }

        setIsSearching(true);
        let cancelled = false;

        const search = async () => {
            if (cancelled) return;

            const requestBody: MemberSearchRequestBody = {
                eventUrlFragment,
                query: inputText
            }

            const config = await loadConfig();

            const request = new Request(`${config.apiOrigin}/search-members`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(requestBody)
            });

            const response = await authenticatedFetch(request);
            if (cancelled) return;

            if (response === null) {
                // The user became logged out.  Wait for re-render.
                return;
            }

            if (!response.ok) {
                setIsSearching(false);
                setErrorMessage(t('rsvpAttendanceList.loadingError'));

                const responseBodyText = await response.text();
                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);
                return;
            }

            const responseBody: MemberSearchResponseBody = await response.json();
            if (cancelled) return;

            const results = responseBody.results;

            const newOptions: UserOption[] =
                results.map(user => {
                    let userLabelText = `${user.firstName} ${user.lastName} <${user.email}>`;
                    let isDisabled: boolean;

                    if (user.status === UserEventRelationshipStatus.Attended
                        || user.status === UserEventRelationshipStatus.AttendedWithoutRsvp) {
                        userLabelText += " " + t('rsvpAttendanceList.alreadyMarked');
                        isDisabled = true;

                    } else if (user.status === UserEventRelationshipStatus.Rsvp) {
                        userLabelText += " " + t('rsvpAttendanceList.rsvpDidNotAttend');
                        isDisabled = false;

                    } else {
                        isDisabled = false;
                    }

                    return {
                        userLabelText,
                        userEmail: user.email,
                        isDisabled
                    };
                });

            setIsSearching(false);
            setErrorMessage(null);
            setOptions(newOptions);
        };

        setTimeout(search, 500); // throttled

        return () => {
            cancelled = true;
        };

    }, [inputText, selectedOption, eventUrlFragment, authenticatedFetch, t]);

    // Response to the submit button being pressed.
    // Marks the selected user as Attended.
    const onSubmit = async () => {

        if (selectedOption === null) {
            return;
        }

        const userEmail = selectedOption.userEmail;

        setIsSaving(true);

        const config = await loadConfig();

        const request = new Request(`${config.apiOrigin}/club-leader/events/add-attendee`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                userEmail,
                eventUrlFragment
            })
        });

        const response = await authenticatedFetch(request);

        setIsSaving(false);

        if (response === null) {
            // The user became logged out.  Wait for re-render.
            return;
        }

        if (!response.ok) {
            setWasSaveAttempted(true);
            setWasSaveSuccessful(false);
            setErrorMessage(t('rsvpAttendanceList.addingError'));
            return;
        }

        setWasSaveAttempted(true);
        setWasSaveSuccessful(true);
        setErrorMessage(null);

        setSelectedOption(null);
        setInputText("");

        onAttendanceChanged();
    };

    return (
        <div className={"ManualAttendance"}>
            <div className={"ManualAttendance_Instructions"}>
                <div className={"ManualAttendance_InstructionsTitle"}>
                    {t('rsvpAttendanceList.instructionsTitle')}
                </div>
                <div className={"ManualAttendance_InstructionsText"}>
                    {t('rsvpAttendanceList.instructionsText')}
                </div>
            </div>
            <div className={"ManualAttendance_Form"}>
                <div className={"ManualAttendance_Form_Autocomplete"}>
                    <FormControl
                        className={`ManualAttendance_Form_AutocompleteFormControl`}
                        component="fieldset">
                        <Autocomplete<UserOption>
                            open={isOpen}
                            style={{width: "100%"}}
                            onOpen={() => {
                                setIsOpen(true);
                            }}
                            onClose={() => {
                                setIsOpen(false);
                            }}
                            onInputChange={(event, newInputText) => {
                                setInputText(newInputText);
                            }}
                            onChange={(event, value) => {
                                if (value !== undefined && value !== null && value.userEmail !== null) {
                                    setWasSaveAttempted(false);
                                }
                                setSelectedOption(value);
                            }}
                            value={selectedOption}
                            getOptionDisabled={option => option.isDisabled}
                            getOptionSelected={(option, value) => option.userLabelText === value.userLabelText}
                            getOptionLabel={option => option.userLabelText}
                            options={options}
                            loading={isSearching}
                            disabled={isSaving}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {isSearching && <CircularProgress color="inherit" size={20}/>}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>
                </div>
                <PlayButton
                    saving={isSaving}
                    text={t('rsvpAttendanceList.moveButtonText')}
                    buttonStyle={ButtonStyles.FilledPrimary}
                    clickHandler={onSubmit}
                    className={"ManualAttendance_Form_Button"}/>
                {wasSaveAttempted && !wasSaveSuccessful &&
                <SaveIndicator
                    success={wasSaveSuccessful}
                    className="ManualAttendance_Form_SavingSuccess"/>
                }
            </div>
            {errorMessage !== null &&
            <LoadingMessage
                className={"ManualAttendance_ErrorMessage"}
                message={errorMessage}/>
            }
        </div>
    )
};

export default function RsvpAttendanceList(props: RsvpAttendanceListProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let initialClasses = 'RsvpAttendanceList';
    if (props.className !== undefined) {
        initialClasses += ' ' + props.className;
    }

    const {authenticatedFetch} = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [savingSuccessful, setSavingSuccessful] = useState(false);
    const [savingMessage, setSavingMessage] = useState<string | null>(null);
    const [savingAttempted, setSavingAttempted] = useState(false);
    const [numberOfRsvps, setNumberOfRsvps] = useState(0);
    const [numberOfAttendees, setNumberOfAttendees] = useState(0);
    const [changes, setChanges] = useState(new Array<ChangeUserEventRelationshipRequestBody>());
    const [presentUsers, setPresentUsers] = useState(new Array<GetUserEventRelationshipResponseBody>());
    const [updatedAt, setUpdatedAt] = useState(Date.now());

    useEffect(
        () => {
            async function load() {
                setLoading(true);
                const config = await loadConfig();
                const getRequest = getGetRequest(config.apiOrigin, props.eventUrlFragment);
                const getResponse = await authenticatedFetch(getRequest);

                if (getResponse === null || !getResponse?.ok) {
                    setLoading(false);
                    setLoadingMessage(t('rsvpAttendanceList.rsvpLoadingError'));
                    console.log(getResponse);
                    return;
                }

                const responseBody: GetUserEventRelationshipsResponseBody = await getResponse.json();

                setPresentUsers(combine(responseBody).present);
                setNumberOfAttendees(count(combine(responseBody).present).attendeeCount);
                setNumberOfRsvps(count(combine(responseBody).present).rsvpCount);
                setLoading(false);
            }

            load().then();
        },
        [props.eventUrlFragment, authenticatedFetch, updatedAt, t]);

    async function submitAttendance() {
        let requestBody = {
            eventUrlFragment: props.eventUrlFragment,
            ChangeUserEventRelationshipRequestBodyList: changes
        };

        setChanges([]);

        setSavingAttempted(true);
        setSaving(true);

        const config = await loadConfig();

        const url = `${config.apiOrigin}/club-leader/events/change-user-event-relationship`;

        const request = new Request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        const response = await authenticatedFetch(request);

        if (response === null || !response?.ok) {
            setSaving(false);
            setSavingSuccessful(false);
            setSavingMessage(t('rsvpAttendanceList.submitError'));
            console.log(response);

            return;
        }

        const getRequest = getGetRequest(config.apiOrigin, props.eventUrlFragment);
        const getResponse = await authenticatedFetch(getRequest);

        if (getResponse === null || !getResponse?.ok) {
            setSaving(false);
            setSavingSuccessful(false);
            setSavingMessage(t('rsvpAttendanceList.submitError'));
            console.log(getResponse);

            return;
        }

        setSaving(false);
        setSavingSuccessful(true);
        setSavingMessage(null);

        const responseBody: GetUserEventRelationshipsResponseBody = await getResponse.json();

        setPresentUsers(combine(responseBody).present);
        setNumberOfAttendees(count(combine(responseBody).present).attendeeCount);
    }

    return (
        <div className={`${initialClasses}`}>
            {(saving) &&
            <Loading className={"RsvpAttendanceList_SavingLoading"} loading={(saving)}/>}
            <div
                className={`'RsvpAttendanceList_Content' ${saving && 'RsvpAttendanceList_NowSaving'}`}>
                {(loading) && <Loading loading={(loading)}/>}
                {loadingMessage !== null &&
                <div className={"RsvpAttendanceList_LoadingMessage"}>{loadingMessage}</div>}
                {!(loading) && loadingMessage === null &&
                <>
                    {presentUsers.length <= 0 && <>
                        <div className="RsvpAttendanceList_Instructions">
                            {t('rsvpAttendanceList.noRSVPs')}
                        </div>
                    </> }
                    {presentUsers.length > 0 && <>
                        <div className="RsvpAttendanceList_Instructions"
                             dangerouslySetInnerHTML={{__html: t('rsvpAttendanceList.updateAttendanceNote')}}>
                        </div>
                        <Update savingAttempted={savingAttempted} savingMessage={savingMessage}
                                savingSuccessful={savingSuccessful}
                                submitAttendance={submitAttendance}
                                numberOfRsvps={numberOfRsvps}
                                numberOfAttendees={numberOfAttendees}/>
                        <div
                            className="RsvpAttendanceList_Title">{numberOfRsvps}&nbsp;
                            {numberOfRsvps === 1 && " " + t('rsvpAttendanceList.singularRSVP')}
                            {numberOfRsvps !== 1 && " " + t('rsvpAttendanceList.multipleRSVP')}
                        </div>
                        {presentUsers.map((rsvp, index) =>
                            <RsvpAttendanceRow changes={changes} setChanges={setChanges}
                                               key={rsvp.userEmail}
                                               rsvp={rsvp}
                                               index={index}
                                               setSavingAttempted={setSavingAttempted}/>
                        )}
                    </>}
                </>
                }
                <Update savingAttempted={savingAttempted} savingMessage={savingMessage}
                        savingSuccessful={savingSuccessful} submitAttendance={submitAttendance}
                        numberOfRsvps={numberOfRsvps} numberOfAttendees={numberOfAttendees}/>
                <ManualAttendance
                    eventUrlFragment={props.eventUrlFragment}
                    onAttendanceChanged={() => {
                        setUpdatedAt(Date.now());
                    }}
                />
            </div>
        </div>
    );
}

interface UpdateProps {
    numberOfAttendees: any;
    savingAttempted: boolean;
    savingMessage: string | null;
    savingSuccessful: boolean;
    submitAttendance: Function;
    numberOfRsvps: number;
}

function Update(props:UpdateProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    return (<div
            className="RsvpAttendanceList_AttendanceUpdate">
            <div className="RsvpAttendanceList_AttendanceUpdateTitle">
                {props.numberOfAttendees} {t('rsvpAttendanceList.attendedText')}
            </div>
            <PlayButton buttonStyle={ButtonStyles.FilledPrimary}
                        clickHandler={props.submitAttendance}
                        text={t('rsvpAttendanceList.updateAttendanceButtonText')}
                        className="RsvpAttendanceList_AttendanceUpdateButton"/>
            {props.savingAttempted && !props.savingSuccessful && <SaveIndicator success={props.savingSuccessful}
                                               className="RsvpAttendanceList_SavingSuccess"
                                               message={props.savingMessage}/>}
        </div>
    );
}

export interface RsvpAttendanceRowProps {
    className?: string;
    index: number;
    changes: Array<ChangeUserEventRelationshipRequestBody>;
    setChanges: Function;
    setSavingAttempted: Function;
    rsvp: GetUserEventRelationshipResponseBody
}

export function RsvpAttendanceRow(props: RsvpAttendanceRowProps) {
    const { t } = useTranslation('clubLeaderEvent');
    let classes = 'RsvpAttendanceRow';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    let didAttend = (props.rsvp.status === UserEventRelationshipStatus.Attended
        || props.rsvp.status === UserEventRelationshipStatus.AttendedWithoutRsvp);

    const [checked, setChecked] = useState(didAttend);
    const [currentStatus, setCurrentStatus] = useState(props.rsvp.status);
    let message = t('rsvpAttendanceList.didntAttendText');
    if (didAttend) {
        message = t('rsvpAttendanceList.attendedText');
    }
    const [attendedMessage, setAttendedMessage] = useState(message);

    const muiClasses = CCStyles();

    function makeChange(userId: number, status: UserEventRelationshipStatus) {
        let newChanges = new Array<ChangeUserEventRelationshipRequestBody>();
        props.changes.map((change) => {
            if (change.userId !== userId) {
                newChanges.push(change);
            }
            return true;
        });
        newChanges.push({userId: userId, status: status});
        props.setChanges(newChanges);
    }

    function toggle() {
        props.setSavingAttempted(false);
        if (currentStatus === UserEventRelationshipStatus.AttendedWithoutRsvp) {
            makeChange(props.rsvp.userId, UserEventRelationshipStatus.NoRsvp);
            setAttendedMessage(t('rsvpAttendanceList.didntAttendText'));
            setChecked(false);
            setCurrentStatus(UserEventRelationshipStatus.NoRsvp);
        } else if (currentStatus === UserEventRelationshipStatus.NoRsvp) {
            makeChange(props.rsvp.userId, UserEventRelationshipStatus.AttendedWithoutRsvp);
            setAttendedMessage(t('rsvpAttendanceList.attendedText'));
            setChecked(true);
            setCurrentStatus(UserEventRelationshipStatus.AttendedWithoutRsvp);
        } else if (currentStatus === UserEventRelationshipStatus.Rsvp) {
            makeChange(props.rsvp.userId, UserEventRelationshipStatus.Attended);
            setAttendedMessage(t('rsvpAttendanceList.attendedText'));
            setChecked(true);
            setCurrentStatus(UserEventRelationshipStatus.Attended);
        } else if (currentStatus === UserEventRelationshipStatus.Attended) {
            makeChange(props.rsvp.userId, UserEventRelationshipStatus.Rsvp);
            setAttendedMessage(t('rsvpAttendanceList.didntAttendText'));
            setChecked(false);
            setCurrentStatus(UserEventRelationshipStatus.Rsvp);
        }
    }

    return (
        <BorderedRow onClick={toggle} className={`${classes}`} showTopBorder={props.index === 0}>
            <BorderedRowItem className="RsvpAttendanceRow_Switch">
                <FormControl className={`${muiClasses.root} ${muiClasses.formControl} `}
                             component="fieldset">
                    <Switch checked={checked} onChange={toggle}/>
                </FormControl>
            </BorderedRowItem>
            <BorderedRowItem className="RsvpAttendanceRow_Name">
                {props.rsvp.userFirstName} {props.rsvp.userLastName}
            </BorderedRowItem>
            <BorderedRowItem className="RsvpAttendanceRow_Contact">
                <div><a href={`mailto:${props.rsvp.userEmail}`}>{props.rsvp.userEmail}</a></div>
                <div><a href={`tel:${props.rsvp.userPhone}`}>{props.rsvp.userPhone}</a></div>
            </BorderedRowItem>
            <BorderedRowLastItem className="RsvpAttendanceRow_AttendedOrNot">
                {attendedMessage}
            </BorderedRowLastItem>
        </BorderedRow>
    );
}
