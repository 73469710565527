import React from "react";
import { Button } from '@material-ui/core'
import cn from 'classnames'

import './CardItem.scss';

interface ICardItemProps {
    className?: string
    text: string
    buttonText: string
    Icon: React.FC<{ className: string }>
    description?: string
    onClick?: React.MouseEventHandler
    amount?: number
    helpText?: string
    descriptionClassName?: string
    disabled?: boolean
}

const CardItem = ({
    className,
    text,
    buttonText,
    Icon,
    description,
    onClick,
    amount,
    helpText,
    descriptionClassName,
    disabled
}: ICardItemProps) => {
    return (
        <div className='card-item'>
            <div className={cn('item-content', className)}>
                <Icon className='icon' />
                {(amount != undefined && amount >= 0) &&
                    <div className="amount">
                        {amount}
                    </div>
                }

                <div className="text">
                    {text}
                </div>

                {helpText &&
                    <div className="help-text">
                        {helpText}
                    </div>
                }

                <Button
                    className="button"
                    disabled={disabled}
                    onClick={onClick}
                    classes={{
                        disabled: 'disabled'
                    }}
                >
                    {buttonText}
                </Button>
            </div>
            <div className={cn("description", descriptionClassName)}>
                {description}
            </div>
        </div>
    )
}

export default CardItem
