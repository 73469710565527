import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const UPDATE_MEMBER_DETAILS_URL = '/ignite/leader/update-member-details'

const postUpdateMemberDetails = async (body: object, clubId: number): Promise<{ userId: number } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_MEMBER_DETAILS_URL}/${clubId}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postUpdateMemberDetails
