import React, { useEffect, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react";
import InfoIcon from '@material-ui/icons/Info';
import dayGridPlugin from "@fullcalendar/daygrid";
import { useTranslation } from "react-i18next";
import './Calendar.scss'
import MonitorIcon from '@material-ui/icons/Tv';
import PersonIcon from '@material-ui/icons/Person';
import { MenuItem, Snackbar, TextField, Tooltip } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import cn from "classnames";
import { IClub } from "../../../../types/clubFinder";
import ChooseActivityTypeModal from "../ChooseActivityTypeModal";
import i18n from "../../../../i18n";
import postUpdateMemberActivity from "../../../../api/postUpdateMemberActivity";
import { ACTIVITY_DATE_RECORD_TYPE, ACTIVITY_MONTH_STATUS } from "../../../../constants/dashboard";
import { IActivityMonth, IActivityRecord } from "../../../../types/dashboard";
import InfoTrackingActivityModal from "../InfoTrackingActivityModal";
import { createDate, differenceInMonths, getEvents } from "../../../../util/ignite/Dashboard.utils";
import { memberDashboardConfig } from "../../../../configs/memberDashboard";
import { SanityTextObject } from "../../../../sanity/SanityUtils";
import { TooltipTitle } from "./TooltipTitle";

const lastMonthIndex = 11
const firstMonthIndex = 0
const monthIndexShift = 1
const firstDateOfMonth = 1

interface ICalendarProps {
    club: IClub
    userId?: number
    activityRecords: IActivityRecord[]
    refetchActivity: Function
    refetchLeaderboard?: Function
    isLeaderDashboard?: boolean
    activityMonths?: IActivityMonth[]
    activityTrackingModalContent?: SanityTextObject[] | undefined
}

export const Calendar = ({
    club,
    userId,
    activityRecords = [],
    refetchActivity,
    refetchLeaderboard,
    isLeaderDashboard = false,
    activityMonths = [],
    activityTrackingModalContent
}: ICalendarProps) => {
    const { t } = useTranslation('ignite/dashboard');
    const currentDate = createDate()
    const [month, setMonth] = useState<number>(currentDate.getMonth());
    const [date, setDate] = useState(currentDate)
    const [chooseActivityModalIsOpen, setChooseActivityModalIsOpen] = useState(false)
    const [isOpenInfoTrackingActivity, setIsOpenInfoTrackingActivity] = useState(false)
    const [notification, setNotification] = useState<any | null>()
    const { trackingInPersonActivitiesEnabled, trackingOnlineActivitiesEnabled, shortCode } = club
    const [year, setYear] = useState(date.getFullYear())
    const [day, setDay] = useState(firstDateOfMonth)
    const currentYear = date.getFullYear()

    const currentMonthRecords = activityRecords?.filter((record: IActivityRecord) => record.month === month + monthIndexShift
        && record.year === year) || [] as IActivityRecord[]

    // Ignore the monthly marked record if we are looking at the first day of the month
    const currentDayRecord = currentMonthRecords.find(record => record.day == day && record.activityDateRecordType === ACTIVITY_DATE_RECORD_TYPE.DAILY_RECORD)

    const getCurrentDayRecord = (day: number) => currentMonthRecords.find(record => record.day == day)
    const activityTotalInPerson = currentMonthRecords.reduce((acc, record) => acc + record.activityTotalInPerson, 0)
    const activityTotalVirtual = currentMonthRecords.reduce((acc, record) => acc + record.activityTotalVirtual, 0)
    const activityTotal = currentMonthRecords.reduce((acc, record) => acc + record.activityTotal, 0)

    const inPersonActivityPresentInCurrentMonth = currentMonthRecords.some(record => record.activityTotalInPerson !== undefined)
    const virtualActivityPresentInCurrentMonth = currentMonthRecords.some(record => record.activityTotalVirtual !== undefined)

    const thresholdDate = new Date
    thresholdDate.setDate(firstDateOfMonth)
    thresholdDate.setMonth(thresholdDate.getMonth() - memberDashboardConfig.DEFAULT_MAX_REPORTING_PERIOD)

    const isOutOfMaxReportingPeriod = differenceInMonths(date, thresholdDate) <= 0
    const isFutureMonth = date.getMonth() > currentDate.getMonth() && date.getFullYear() >= currentDate.getFullYear()
    const isLocked = activityMonths?.find(activityMonth => activityMonth.month === month + monthIndexShift && activityMonth.year === year)?.status
        === ACTIVITY_MONTH_STATUS.LOCKED || isOutOfMaxReportingPeriod || isFutureMonth
    const draftYears = activityRecords?.map(data => data.year) || []
    const years = Array.from(new Set(draftYears?.length ? [...draftYears, year, currentDate.getFullYear()] : [])).sort((a, b) => b - a)

    const onSelect = (arg: any) => {
        if (isLocked) return;
        const currentDayRecord = getCurrentDayRecord(Number(arg.dayNumberText))
        setDay(Number(arg.dayNumberText))
        if (trackingInPersonActivitiesEnabled && trackingOnlineActivitiesEnabled) {
            setChooseActivityModalIsOpen(true)
        } else if (trackingInPersonActivitiesEnabled) {
            saveActivity({
                day: Number(arg.dayNumberText),
                activityTotalInPerson: Number(currentDayRecord?.activityTotalInPerson || 0) + 1
            })
        } else {
            saveActivity({
                day: Number(arg.dayNumberText),
                activityTotalVirtual: Number(currentDayRecord?.activityTotalVirtual || 0) + 1
            })
        }
    }
    const ref = useRef<FullCalendar>(null);

    const changeMonth = (value: number) => {
        let y = year;
        if (value > 0) {
            if(month === 11) {
                setYear(year+1);
                y += 1;
            }
            setMonth(prevMonth => prevMonth === lastMonthIndex ? firstMonthIndex : prevMonth + value);
        } else {
            if(month === 0) {
                setYear(year-1);
                y -= 1;
            }
            setMonth(prevMonth => prevMonth === firstMonthIndex ? lastMonthIndex : prevMonth + value)
        }

        const date = createDate({ date: firstDateOfMonth, month: month + value, year: y})
        setDate(date)
        ref.current?.getApi().gotoDate(date);
    }

    const onClickNextMonth = () => changeMonth(1)

    const onClickPrevMonth = () => changeMonth(-1)

    const onChangeYear = (e: any) => {
        const date = createDate({ date: firstDateOfMonth, month, year: e.target.value })
        setDate(date)
        setYear(Number(e.target.value))
        ref.current?.getApi().gotoDate(date);
    }

    const onSuccessUpdateActivity = () => {
        refetchActivity && refetchActivity()
        refetchLeaderboard && refetchLeaderboard()
    }

    const onErrorUpdateActivity = (e: { message: string }) => {
        setNotification({ message: e.message, isError: true })
        setTimeout(() => {
            setNotification(null)
        }, memberDashboardConfig.NOTIFICATION_HIDE_TIME)
    }

    const saveActivity = ({ activityTotalInPerson, activityTotalVirtual, status, day }:
        { activityTotalInPerson?: number, activityTotalVirtual?: number, status?: any, day?: any }) => {
        if (!year || Number.isNaN(month)) {
            return
        }
        const body = {
            UserId: userId,
            ClubId: Number(club.id),
            Year: currentYear,
            Month: month + monthIndexShift,
            ...(activityTotalInPerson !== undefined && { ActivityTotalInPerson: activityTotalInPerson }),
            ...(activityTotalVirtual !== undefined && { ActivityTotalVirtual: activityTotalVirtual }),
            Status: status ?? 0,
            Day: day
        }

        return postUpdateMemberActivity(body)
            .then(onSuccessUpdateActivity)
            .catch(onErrorUpdateActivity)
    }

    const events = getEvents({ currentMonthRecords })
    const getHelpText = () => {
        if (isFutureMonth) return t('calendarHelpTextForFutureMonth')
        if (isLocked) {
            return isLeaderDashboard ? t('calendarHelpTextForLockedMonthForLeader') : t('calendarHelpTextForLockedMonth')
        } else {
            return t('calendarHelpText')
        }
    }

    const dayCellContent = (arg: { start: string, end: string, dayNumberText: string, date: Date, isOther: boolean }) => {
        let isWhite = false
        let shouldShowTooltip = false
        const currentDateRecord = getCurrentDayRecord(Number(arg.dayNumberText))

        events.forEach(event => {
            const eventStart = event.start
            const eventEnd = event.end
            const isEventInCurrentYear = eventStart.getFullYear() === currentYear
            const isEventInCurrentMonth = arg.date.getMonth() === eventEnd.getMonth()

            if (!isEventInCurrentYear || !isEventInCurrentMonth) return
            if (eventStart.getDate() === Number(arg.dayNumberText) || eventEnd.getDate() === Number(arg.dayNumberText)) {
                isWhite = true
                shouldShowTooltip = true
            } else if (eventStart.getDate() <= Number(arg.dayNumberText) && eventEnd.getDate() >= Number(arg.dayNumberText)) {
                shouldShowTooltip = true
            }
        })

        return (shouldShowTooltip ?
            <Tooltip
                placement="top"
                title={<TooltipTitle currentDateRecord={currentDateRecord} />}
                classes={{ tooltip: 'calendar-tooltip', arrow: 'arrow' }}
                arrow={true}
            >
                <div
                    onClick={() => onSelect(arg)}
                    className={cn("cell-custom", { 'is-white': isWhite, 'is-disabled': arg.isOther })}
                >
                    {arg.dayNumberText}
                </div >
            </Tooltip>
            : <div
                onClick={() => onSelect(arg)}
                className={cn("cell-custom", { 'is-white': isWhite, 'is-disabled': arg.isOther })}
            >
                {arg.dayNumberText}
            </div >
        )
    }

    return (
        <div className={cn("dashboard-calendar", { locked: isLocked })}>
            <Snackbar
                open={Boolean(notification?.isError)}
                onClose={() => setNotification(null)}
                message={notification?.message}
                ContentProps={{ className: 'notification-error' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
            <div className="title">
                <div className="text">
                    {t('myActivityTitle')}
                    <InfoIcon
                        className='info-icon'
                        onClick={() => setIsOpenInfoTrackingActivity(true)}
                    />
                </div>
                {
                    !!years.length && <TextField
                        onChange={onChangeYear}
                        value={year}
                        className='year-select'
                        variant="outlined"
                        select={true}
                        InputProps={{
                            classes: { root: 'input-root' }
                        }}
                        size='small'
                    >
                        {years.map(year => (
                            <MenuItem
                                key={year}
                                value={year}
                            >
                                {year}
                            </MenuItem>
                        ))}
                    </TextField>
                }
            </div>
            <ChooseActivityTypeModal
                isOpen={chooseActivityModalIsOpen}
                onClose={() => setChooseActivityModalIsOpen(false)}
                onVirtualClick={() => {
                    saveActivity({ day, activityTotalVirtual: Number(currentDayRecord?.activityTotalVirtual || 0) + 1 })
                    setChooseActivityModalIsOpen(false)
                }}
                onInPersonClick={() => {
                    saveActivity({ day, activityTotalInPerson: Number(currentDayRecord?.activityTotalInPerson || 0) + 1 })
                    setChooseActivityModalIsOpen(false)
                }}
            />
            <InfoTrackingActivityModal
                isOpen={isOpenInfoTrackingActivity}
                setIsOpenInfoTrackingActivity={setIsOpenInfoTrackingActivity}
                modalContent={activityTrackingModalContent}
                clubType={club.type}
            />
            <div className="toolbar">
                <KeyboardArrowLeft onClick={onClickPrevMonth} className="arrow" />
                {date.toLocaleDateString(i18n.language || 'en', { month: 'long', year: 'numeric' })}
                <KeyboardArrowRight onClick={onClickNextMonth} className="arrow" />
            </div>
            <div className="header">
                <div className="total-activity-title">
                    <div className="left-content">
                        {t('totalActivityForMonth')}
                    </div>
                    <div className="right-content">
                        {activityTotal}
                        {isLocked && <LockIcon className='icon' />}
                    </div>
                </div>
                <div className="activity-amount-panel">
                    {(trackingInPersonActivitiesEnabled || inPersonActivityPresentInCurrentMonth) && (
                        <div className={cn("in-person", {
                            "full-width": !trackingOnlineActivitiesEnabled,
                            'disabled-view': !trackingInPersonActivitiesEnabled && inPersonActivityPresentInCurrentMonth
                        })}
                        >
                            <div className="text">
                                {t('inPersonActivity')}
                            </div>
                            <div className="value">
                                {activityTotalInPerson || 0}
                                <PersonIcon className="icon" />
                            </div>
                        </div>
                    )}
                    {(trackingOnlineActivitiesEnabled || virtualActivityPresentInCurrentMonth) && (
                        <div className={cn("virtual-activity", {
                            "full-width": !trackingInPersonActivitiesEnabled,
                            'disabled-view': !trackingOnlineActivitiesEnabled && virtualActivityPresentInCurrentMonth
                        })}>
                            <div className="text">
                                {t('virtualActivity')}
                            </div>
                            <div className="value">
                                {activityTotalVirtual || 0}
                                <MonitorIcon className="icon" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="calendar-content">
                <div className="help-text">
                    {getHelpText()}
                </div>
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    displayEventTime={false}
                    ref={ref}
                    locale={'en'}
                    selectable={false}
                    dayCellContent={dayCellContent}
                    customButtons={{
                        toPrevious: {
                            icon: 'chevron-left',
                            click: onClickPrevMonth
                        },
                        toNext: {
                            icon: 'chevron-right',
                            click: onClickNextMonth
                        }
                    }}
                    headerToolbar={false}
                />
            </div>
        </div>
    )
}
