import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {TFunction} from "i18next";

import "./FindAClubPage.scss";
import {RouteComponentProps} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";
import {loadConfig} from "../../services/ConfigService";
import FindAClubForm, {ParentPage} from "../../components/findAClub/FindAClubForm";
import BorderedRow, {
    BorderedRowItem,
    BorderedRowLastItem
} from "../../components/common/BorderedRow";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import BackLink from "../../components/common/BackLink";
import LocationViewCityState from "../../components/common/LocationViewCityState";

export interface ClubSearchParams {
    postalCode: string | null;
    passionId: number | null;
    skip: number | null;
    limit: number | null;
}

export interface ClubSearchResponseBody {
    skip: number;
    limit: number;
    totalResultCount: number;
    results: ClubSearchResult[];
}

interface ClubSearchResult {
    distanceInMiles: number;
    clubUrlFragment: string;
    clubName: string;
    passionId: number;
    passionName: string;
    city: string | null;
    countrySubdivision: string | null;
    eventCount: number;
}

function totalResultCountText(t: TFunction, searchResponseBody: ClubSearchResponseBody): string {
    const {totalResultCount} = searchResponseBody;
    switch (totalResultCount) {
        case 0:
            return t('findAClub.noResults');
        case 1:
            return t('findAClub.oneResult');
        default:
            return `${totalResultCount} ` + t('findAClub.multipleResult');
    }
}

export default function FindAClubPage({location}: RouteComponentProps): JSX.Element {
    // TODO: Load a default postal code for this user from somewhere?
    const { t } = useTranslation('pages');
    const {authenticatedFetch} = useContext(UserContext);

    const [searching, setSearching] = useState<boolean>(false);
    const [searchingErrorMessage, setSearchingErrorMessage] = useState<string | null>(null);
    const [searchingEnabled, setSearchingEnabled] = useState<boolean>(true);

    const [searchResponseBody, setSearchResponseBody] = useState<ClubSearchResponseBody | null>(null);

    const [defaultLimit, setDefaultLimit] = useState<number | null>(10);

    const isPreviousButtonEnabled =
        searchResponseBody !== null
        && searchResponseBody.skip > 0;

    const isNextButtonEnabled =
        searchResponseBody !== null
        && searchResponseBody.skip + searchResponseBody.limit < searchResponseBody.totalResultCount;

    window.scrollTo(0,0);

    useEffect(
        () => {
            const loadSearchParams = async () => {

                const searchParams = new URLSearchParams(location.search);

                if (searchParams.toString().length === 0) {
                    return;
                }

                setSearching(true);
                setSearchingErrorMessage(null);

                const config = await loadConfig();

                const searchRequest =
                    new Request(
                        `${config.apiOrigin}/club-search?${searchParams.toString()}`,
                        {method: "GET", headers: {"Accept": "application/json"}});

                const searchResponse = await authenticatedFetch(searchRequest);

                if (searchResponse === null) {
                    // Login required.  The user just became null, so this page is about to be re-rendered.
                    return;
                }

                if (!searchResponse.ok) {
                    const responseBodyText = await searchResponse.text();
                    console.error(
                        `Non-successful response from API: `
                        + `${searchResponse.status} ${searchResponse.statusText} `
                        + `from ${searchResponse.url}\n\n${responseBodyText}`);
                    setSearching(false);
                    setSearchingErrorMessage(t('findAClub.unexpectedError'));
                    return;
                }

                const newSearchResponseBody = await searchResponse.json();

                if (!searchParams.has("limit")) {
                    setDefaultLimit(newSearchResponseBody.limit);
                }

                setSearchResponseBody(newSearchResponseBody);
                setSearching(false);
                setSearchingErrorMessage(null);
                setSearchingEnabled(newSearchResponseBody.skip !== 0);
            };

            loadSearchParams();
        },
        [location, authenticatedFetch, t]);

    return (
        <div className="FindAClubPage">
            <BackLink to={"/my-events"}>{t('findAClub.backToEvents')}</BackLink>
            <FindAClubForm
                parentPage={ParentPage.FindClubPage}
                searchingEnabled={searchingEnabled}
                defaultLimit={defaultLimit}
                searching={searching}
                searchResponseBody={searchResponseBody}
                isNextButtonEnabled={isNextButtonEnabled}
                isPreviousButtonEnabled={isPreviousButtonEnabled}>
                <>
                    {searchingErrorMessage !== null && (
                        <div className="SearchingErrorMessage">
                            {searchingErrorMessage}
                        </div>
                    )}
                    <SearchResults searchResponseBody={searchResponseBody}/>
                </>
            </FindAClubForm>
        </div>
    );
}


interface SearchResultProps {
    searchResponseBody:ClubSearchResponseBody | null;
}

function SearchResults(props:SearchResultProps) : JSX.Element {
    const { t } = useTranslation('pages');
    let searchResponseBody = props.searchResponseBody;

    return (
        <div className="Results">
            {searchResponseBody !== null && (
                <>
                    <div className="TotalResultCount">
                        {totalResultCountText(t, searchResponseBody)}
                    </div>
                    {searchResponseBody.results.map((result, i) => (
                        <BorderedRow
                            key={result.clubUrlFragment}
                            className="Result"
                            showTopBorder={i === 0}
                            link={`/club/${result.clubUrlFragment}`}>
                            <BorderedRowItem className="ClubName">
                                {result.clubName}
                            </BorderedRowItem>
                            <BorderedRowItem className="Passion">
                                {result.passionName}
                            </BorderedRowItem>
                            <BorderedRowItem className="Location">
                                <LocationViewCityState
                                    city={result.city}
                                    state={result.countrySubdivision}/>
                            </BorderedRowItem>
                            <BorderedRowLastItem className="Events">
                                <PlayButton
                                    to={`/club/${result.clubUrlFragment}`}
                                    buttonStyle={ButtonStyles.UnfilledWithBorder}
                                    text={t('findAClub.playButtonText') + ` (${result.eventCount})`}/>
                            </BorderedRowLastItem>
                        </BorderedRow>
                    ))}
                </>
            )}
        </div>
    );
}