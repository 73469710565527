import {getJson, getNullableJson} from "./RequestService";
import { UserAgreementModel } from "./Models";
import { loadConfig } from "./ConfigService";

export async function getUserAgreement({ slug } : { slug: string }): Promise<UserAgreementModel> {
    const config = await loadConfig();
    return getJson<UserAgreementModel>({ url: `${config.apiOrigin}/user-agreement/${slug}` });
}

export async function getUserAgreementNullable({ slug } : { slug: string }): Promise<UserAgreementModel|null> {
    const config = await loadConfig();
    return getNullableJson<UserAgreementModel>({ url: `${config.apiOrigin}/user-agreement/${slug}` });
}
