import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom'

import Loading from "../../../components/ignite/layout/Loading";
import { UserContext } from "../../../contexts/UserContext";
import LeaderboardIcon from '@material-ui/icons/Equalizer';

import useClubById from "../../../hooks/useClubById";

import MoneyInfoPanel from "../../../components/ignite/Dashboard/MoneyInfoPanel";
import PersonalRankingPanel from "../../../components/ignite/Dashboard/PersanalRankingPanel";
import { Leaderboard } from "../../../components/ignite/Dashboard/Leaderboard";
import useCheckDevice from '../../../hooks/useCheckDevice'

import useGetLeaderboard from "../../../hooks/useGetLeaderboard";
import useGetMyActivity from "../../../hooks/useGetMyActivity";
import TrackingActivityModal from '../../../components/ignite/TrackingActivityModal/TrackingActivityModal'


import './Dashboard.scss';
import InfoTrackingActivityModal from "../../../components/ignite/Dashboard/InfoTrackingActivityModal";
import useBadge from "../../../hooks/useBadge";
import WelcomeToDashboardPanel from "../../../components/ignite/Dashboard/WelcomeToDashboardPanel";
import useGetMyClubs from "../../../hooks/useGetMyClubs";
import { GetHelpPanel } from "../../../components/ignite/Dashboard/GetHelpPanel";
import { Calendar } from "../../../components/ignite/Dashboard/Calendar/Calendar";
import { IActivityMonth, IActivityRecord } from "../../../types/dashboard";
import SubHeader from "../../../components/ignite/SubHeader/SubHeader";
import EligibilityStatusModal from "../../../components/eligibilityStatusModal/EligibilityStatusModal";
import getClubAssociationDashboardContentViaSanity from "../../../sanity/getClubAssociationDashboardContentViaSanity";
import { SanityTextObject } from "../../../sanity/SanityUtils";
import getAllMembersActivity from "../../../api/getAllMembersActivity";
import ActivityTrackingFirstTimeWelcome from "./components/ActivityTrackingFirstTimeWelcome";
import BYOCWelcomeModal from "./components/BYOCWelcomeModal";

const IS_WELCOME_MESSAGE_HIDDEN = 'IS_WELCOME_MESSAGE_HIDDEN';

export default function Dashboard(): JSX.Element {
    const {state} = useLocation<{ShowDTCWelcomeMessage: boolean | undefined}>();
    const { getContactPhoneNumber, viewState, setUserViewState } = useContext(UserContext);
    const [isOpenTrackingActivity, setIsOpenTrackingActivity] = useState(false)
    const [isOpenInfoTrackingActivity, setIsOpenInfoTrackingActivity] = useState(false)
   
    const [firstBadgeWasShow, setFirstBadgeWasShow] = useState(false)
    const [shouldShowWelcomePanel, setShouldShowWelcomePanel] = useState(true)
    const [activityTrackingModalContent, setActivityTrackingModalContent] = useState<SanityTextObject[] | undefined>(undefined)
    const [activityTrackingModalContentLoading, setActivityTrackingModalContentLoading] = useState(true)
    const [clubIncome, setClubIncome] = useState(0)
    const { clubId } = useParams()
    const { t } = useTranslation('ignite/dashboard');

    const [club, isClubLoading] = useClubById(clubId)
    const { leaderboardList, isLoading: isLeaderboardLoading, fetchLeaderboard, leaderboardTotalCount } = useGetLeaderboard(clubId)
    const [myActivity, isMyActivityLoading, getMyActivity, prevActivity] = useGetMyActivity(clubId)
    const [myClubs] = useGetMyClubs()
    const { clubName, location: clubLocation, clubImage } = club || {};

    const {
        shouldShowFirstBadge,
        setShouldShowFirstBadge
    } = useBadge(myActivity?.badgesEarned, isMyActivityLoading)

    const hasNewBadge = prevActivity && (prevActivity?.badgesEarned?.length !== myActivity?.badgesEarned?.length)
    const welcomeBannerHiddenStatusJson = localStorage.getItem(IS_WELCOME_MESSAGE_HIDDEN);
    const initialWelcomeBannerHiddenStatus = welcomeBannerHiddenStatusJson
        ? JSON.parse(welcomeBannerHiddenStatusJson)
        : false;

    useEffect(() => {
        setActivityTrackingModalContentLoading(true);
        if (club?.shortCode) {
            getClubAssociationDashboardContentViaSanity(club.shortCode).then(data => {
                if (data?.result && data?.result.length > 0) {
                    setActivityTrackingModalContent(data?.result[0].associationActivityTrackerDashboard[0]?.activityTrackerHelperText);
                }
                else {
                    setActivityTrackingModalContent(undefined);
                }
                setActivityTrackingModalContentLoading(false);
            });
        } else {
            setActivityTrackingModalContent(undefined);
            setActivityTrackingModalContentLoading(false);
        }
    }, [club]);


    useEffect(() => {
        if (club && myClubs?.length){
            // find clubs joined date
            const match = myClubs?.find(myClub => myClub.id === club.id) ?? undefined;
            if(match && match?.joinedDate){
                const daysSinceJoined = (new Date().getTime() - new Date(match.joinedDate).getTime())/(1000 * 3600 * 24);
                if(daysSinceJoined < 7)
                    setShouldShowWelcomePanel(true);
            }
        }
    }, [club, myClubs])

    const additionalActionOnFirstBadgeClick = useCallback(() => {
        if (hasNewBadge && firstBadgeWasShow) {
            setIsOpenTrackingActivity(false)
            setShouldShowFirstBadge(false)
            setFirstBadgeWasShow(false)
        }
    }, [shouldShowFirstBadge, firstBadgeWasShow])

    useEffect(() => {
        if (prevActivity && hasNewBadge && !isOpenTrackingActivity) {
            setIsOpenTrackingActivity(true)
            setFirstBadgeWasShow(true)
        }
    }, [myActivity?.badgesEarned?.length])

    useEffect(() => {
        getAllMembersActivity(clubId).then(data => setClubIncome(data?.clubIncome))
    }, [])

    const { isDesktop } = useCheckDevice()

    const isLoading = isClubLoading || isLeaderboardLoading || isMyActivityLoading || activityTrackingModalContentLoading
    if (isLoading) return <Loading loading={isLoading} />

    if (!club) return <div>{t('noSuchClub')}</div>

    return (
        <div className='layout member-dashboard'>
            <TrackingActivityModal
                isOpen={isOpenTrackingActivity}
                myActivity={myActivity}
                setIsOpenTrackingActivity={setIsOpenTrackingActivity}
                getMyActivity={getMyActivity}
                fetchLeaderboard={fetchLeaderboard}
                additionalActionOnFirstBadgeClick={additionalActionOnFirstBadgeClick}
            />
            <InfoTrackingActivityModal
                isOpen={isOpenInfoTrackingActivity}
                setIsOpenInfoTrackingActivity={setIsOpenInfoTrackingActivity}
                modalContent={activityTrackingModalContent}
                clubType={club.type}
            />

            <EligibilityStatusModal retryRequest />

            <BYOCWelcomeModal />
            
            <div className='dashboard-content'>
                <div className="top-content">
                    <SubHeader
                        clubName={clubName}
                        myClubs={myClubs}
                        clubImage={clubImage}
                        location={clubLocation}
                        toClub
                    />
                </div>
                <div className="bottom-content">
                    <div className='main-content'>
                        {/* Hidden according to requirements from the task https://hellogrouperhealth.atlassian.net/browse/Grouper-259
                        {isNotEligible && <VerifyEligibilityPanel
                            onClick={() => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_SELECTION, { ':clubId': clubId }))}
                        />} */}

                        {(!myActivity?.activityRecords?.length || shouldShowWelcomePanel) &&
                            !initialWelcomeBannerHiddenStatus &&
                            (
                                <WelcomeToDashboardPanel
                                    onClose={() => {
                                        setShouldShowWelcomePanel(false);
                                        localStorage.setItem(IS_WELCOME_MESSAGE_HIDDEN, JSON.stringify(true));
                                    }}
                                    type={club.type}
                                />
                        )}
         
                        <Calendar
                            club={club}
                            userId={myActivity?.userRecord?.userId}
                            activityRecords={myActivity?.activityRecords as IActivityRecord[]}
                            activityMonths={myActivity?.activityMonths as IActivityMonth[]}
                            refetchActivity={getMyActivity}
                            refetchLeaderboard={fetchLeaderboard}
                            activityTrackingModalContent={activityTrackingModalContent}
                        />
                        {!isDesktop && (
                            <>
                                {
                                    club &&
                                    <MoneyInfoPanel
                                        income={myActivity?.income}
                                        clubMoney={clubIncome}
                                        clubType={club.type}
                                    />
                                }
                                <PersonalRankingPanel myActivity={myActivity} />
                                <div className="leaderboard__title">
                                    <LeaderboardIcon className="icon" />
                                    <div className="text">
                                        {club.clubName}&nbsp;{t('clubLeaderboard')}
                                    </div>
                                </div>
                                <Leaderboard
                                    list={leaderboardList}
                                    totalCount={leaderboardTotalCount}
                                    fetchLeaderboard={fetchLeaderboard}
                                />
                                <GetHelpPanel getContactPhoneNumber={getContactPhoneNumber} />
                            </>
                        )}
                    </div>
                    {isDesktop ? (
                        <div className="leaderboard">
                            <MoneyInfoPanel
                                income={myActivity?.income}
                                clubMoney={clubIncome}
                                clubType={club.type}
                            />
                            <PersonalRankingPanel myActivity={myActivity} />

                            <div className="leaderboard__title" style={{wordWrap: 'break-word'}}>
                                <LeaderboardIcon className="icon" />

                                <div >
                                    {club.clubName}&nbsp;{t('clubLeaderboard')}
                                </div>
                            </div>
                            <Leaderboard
                                list={leaderboardList}
                                totalCount={leaderboardTotalCount}
                                fetchLeaderboard={fetchLeaderboard}
                            />
                            <GetHelpPanel getContactPhoneNumber={getContactPhoneNumber} />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
