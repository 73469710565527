import { POSTAL_CODE_REGEX, PHONE_REGEX, STREET_REGEX, CITY_REGEX, MEMBER_ID_REGEX, GROUP_NUMBER_REGEX, MEMBER_CODE_REGEX, OPTIONAL_GROUP_NUMBER_REGEX, PAYER_ID_REGEX } from '../../../constants/regex'
import { getPhoneUnmask } from '../../../util/ignite/MyInfo.utils';

export const getMemberInfoFormConfig = ({ t }: { t: Function }) => ({
    firstName: {
        name: "firstName",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('errors.firstNameRequired');
            }
        },
    },

    lastName: {
        name: "lastName",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('errors.lastNameRequired');
            }
        },
    },
    postalCode: {
        name: 'postalCode',
        validate: (value: string) => {
            if (!value.match(POSTAL_CODE_REGEX)) {
                return t('errors.postalCode');
            }
        },
    },
    phoneNumber: {
        name: 'phoneNumber',
        validate: (value: string) => {
            const formattedValue = getPhoneUnmask(value)
            if (!formattedValue.match(PHONE_REGEX)) {
                return t('errors.phoneNumber');
            }
        },
    },
    street: {
        name: 'street',
        validate: (value: string) => {
            if (!value.match(STREET_REGEX)) {
                return t('errors.street');
            }
        },
    },
    city: {
        name: 'city',
        validate: (value: string) => {
            if (!value.match(CITY_REGEX)) {
                return t('errors.city');
            }
        },
    },
    state: {
        name: 'state',
        validate: (value: string) => {
            if (!value) {
                return t('errors.state');
            }
        },
        validateOnBlur: true
    },
    language: {
        name: 'language',
        validate: (value: string) => {
            if (!value) {
                return t('errors.language');
            }
        },
    },
    dateOfBirth: {
        name: 'dateOfBirth',
    }
})

export const getContactInfoFormConfig = ({ t }: { t: Function }) => ({
    phoneNumber: {
        name: 'phoneNumber',
        validate: (value: string) => {
            const formattedValue = getPhoneUnmask(value)
            if (!formattedValue.match(PHONE_REGEX)) {
                return t('errors.phoneNumber');
            }
        },
    },
    preferredContactMethod: {
        name: 'preferredContactMethod',
        validate: (value: string) => {
            if (value === undefined) {
                return t('errors.preferredContactMethod');
            }
        },
    },
    email: {
        name: 'email',
    },
})

export const getHealthPlanFormConfig = () => ({
    primaryPayerId: {
        name: 'primaryPayerId'
    },
    secondaryPayerId: {
        name: 'secondaryPayerId'
    },
    confirmationCode: {
        name: 'confirmationCode'
    },
    memberId: {
        name: 'memberId'
    },
    groupNumber: {
        name: 'groupNumber'
    }
})
