import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useState
} from "react";
import { useHistory } from "react-router-dom";

import useQuery from "../../hooks/useQuery";
import { UserContext } from "../../contexts/UserContext";
import Loading from "../common/Loading";
import { checkFeatureFlag } from '../../services/FeatureFlagService';
import { TOKEN_LOGIN_ENABLED } from '../../constants/flags';

export default function TokenBasedLogin({ children }: PropsWithChildren<{}>) {
    const [isLoading, setLoadingStatus] = useState(false);

    const history = useHistory();
    const redirectTo = history.location.pathname;

    const { tokenBasedLogin, tokenLogin, logout, isLoggedIn } = useContext(UserContext);

    const query = useQuery()

    const userIdString = query.get('userId');
    const loginToken = query.get('loginToken');

    const login = (loginToken: string, userIdString: string | null) => userIdString
        ? tokenBasedLogin(parseInt(userIdString, 10), loginToken)
        : tokenLogin(loginToken);

    useEffect(() => {
        async function loginViaToken() {
            const useTokenLogin = await checkFeatureFlag(TOKEN_LOGIN_ENABLED, false);

            if (loginToken && (userIdString || useTokenLogin)) {
                try {
                    setLoadingStatus(true);

                    await login(loginToken, userIdString);

                    history.replace(redirectTo);
                } catch (e) {
                    if (isLoggedIn()) {
                        await logout();
                    }

                    history.push("/login");
                } finally {
                    setLoadingStatus(false);
                }
            }
        }

        loginViaToken();
    }, []);

    return isLoading ? <Loading loading/> : <>{children}</>;
}
