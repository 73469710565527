import React, {
  ComponentType,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import './Layout.scss';
import { UserContext } from '../../contexts/UserContext';
import Header from './Header';
import Footer from './Footer';
import Version from '../common/Version';
import NeedHelpCallout from './NeedHelpCallout';
import EmailVerificationNag from './EmailVerificationNag';
import { refreshClubCustomerService } from '../../services/UserService';
import {
  customEnrollmentLandingRegex,
  customEnrollmentRegex,
} from '../../util/CustomEnrollment';
import { emailConfig } from '../../configs/email';

export default function Layout({ children }: { children: any }): JSX.Element {
  const location = useLocation();
  const [navigationShown, setNavigationShown] = useState(false);
  const { user } = useContext(UserContext);

  const acblLanding = /^\/acbl(\/?|\/faq)$/i.test(location.pathname);
  const isIgnite = location.pathname.startsWith('/ignite');
  const isCustomEnrollmentLanding = customEnrollmentLandingRegex.test(
    location.pathname,
  );
  const customEnrollmentMatching = location.pathname.match(
    customEnrollmentRegex,
  );
  const customEnrollmentShortCode = customEnrollmentMatching
    ? customEnrollmentMatching[1]
    : undefined;

  useEffect(() => {
    // Recording customer service information for custom enrollment based pages
    if (customEnrollmentShortCode) {
      (async () => {
        await refreshClubCustomerService(customEnrollmentShortCode);
      })();
    }
  }, [location.pathname]);

  if (location.pathname === '/events') {
    return (
      <>
        <Header
          navigationShown={navigationShown}
          setNavigationShown={setNavigationShown}
        />
        <div>{children}</div>
        <Footer />
        <Version />
      </>
    );
  }

  return (
    <div className="Layout">
      {!acblLanding && !isCustomEnrollmentLanding && (
        <>
          <div className={`TopAccent`} />
          <NeedHelpCallout hideLanguageSelection={isIgnite} />
          <Header
            navigationShown={navigationShown}
            setNavigationShown={setNavigationShown}
            customEnrollmentShortCode={customEnrollmentShortCode}
          />
        </>
      )}
      <div
        className={cn('Page', { Page_Dim: user != null && navigationShown })}
      >
        <div
          className={`PageContent ${
            acblLanding || isCustomEnrollmentLanding ? 'NoHeader' : ''
          }`}
        >
          {children}
        </div>
      </div>
      <Footer hideLanguageSelection={isIgnite} />
      <Version />
    </div>
  );
}

export const withLayout = <T extends object>(
  WrappedComponent: ComponentType<T>,
) => {
  const ComponentWithLayout = (props: T) => {
    const { user, isLoggedIn } = useContext(UserContext);

    const location = useLocation();
    const { state } = useLocation<{
      ShowDTCWelcomeMessage: boolean | undefined;
    }>();
    const [showEmailVerificationNag, setShowEmailVerificationNag] = useState(
      emailConfig.ENABLE_VERIFICATION_NAG &&
        isLoggedIn() &&
        user !== null &&
        !user.isEmailVerified,
    );

    const showMemberJustRegisteredMessage =
      location.pathname === '/find-an-event' &&
      new URLSearchParams(location.search).get('justRegistered') === 'true';

    const showMemberJustRegisteredGetActiveCustomMessage =
      location.pathname === '/find-an-event' &&
      new URLSearchParams(location.search).get('justRegistered') === 'true' &&
      new URLSearchParams(location.search).get('getActive') === 'true';

    useEffect(() => {
      const acblFlow = location.pathname.toLowerCase().startsWith('/acbl');
      const newMemberRegFlow =
        location.pathname.toLowerCase().indexOf('/register/') > -1;
      const associationRegFlow = location.pathname
        .toLowerCase()
        .startsWith('/p/');
      const verifyEmailPage = location.pathname
        .toLowerCase()
        .startsWith('/verify-email/');
      const dtcRegFlow =
        location.pathname.toLowerCase().indexOf('/register-') > -1;
      setShowEmailVerificationNag(
        emailConfig.ENABLE_VERIFICATION_NAG &&
          user !== null &&
          !user.isEmailVerified &&
          !acblFlow &&
          !associationRegFlow &&
          !newMemberRegFlow &&
          !verifyEmailPage &&
          !dtcRegFlow &&
          !state?.ShowDTCWelcomeMessage,
      );
    }, [user, location]);

    return (
      <Layout>
        <>
          {showEmailVerificationNag && (
            <EmailVerificationNag
              showMemberJustRegisteredMessage={showMemberJustRegisteredMessage}
              getActive={showMemberJustRegisteredGetActiveCustomMessage}
              email={user?.email}
              className="Layout_EmailVerification"
            />
          )}
          <WrappedComponent {...props} />
        </>
      </Layout>
    );
  };

  return ComponentWithLayout;
};
