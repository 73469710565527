import {
    FormControl,
    MenuItem, Select, Switch,
    TextField
} from "@material-ui/core";
import Button, {ButtonStyles} from "../common/Button";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import {CCStyles} from "../form/Theme";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {
    DISTANCE_OPTIONS,
    EventSearchParams,
    EventSearchSort
} from "../../pages/findAnEvent/FindAnEventPage";
import SectionHeader from "../common/SectionHeader";
import PassionMultiSelect from "./PassionMultiSelect";
import MuiSelectElement from "../form/MuiSelectElement";
import LocationOn from "@material-ui/icons/LocationOn";
import {GetPassionResponseBody} from "../../services/Models";
import EventViewTypeSelect from "../event/EventViewTypeSelect";
import {SkipPrevious} from "@material-ui/icons";

export interface FindAnEventFormProps extends RouteComponentProps {
    isTodayButtonEnabled: boolean;
    onClickTodayButton: Function;
    onChangeSelectedPassions: Function;
    allPassions: GetPassionResponseBody[],
    selectedPassionIds: number[],
    defaultLimit: number | null;
    searching: boolean;
    initialSearchParams?: EventSearchParams | null | undefined;
    children?: any;
    parentPage: ParentPage;
    sort: EventSearchSort | null;
    reverse: boolean | null;
    view: EventView;
    setView: (view: EventView) => void;
    postalCode: string | null;
    distance: number;
    setPostalCodeAndDistance: (postalCode: string | null, distance: number) => void;
    virtualOnly: boolean;
    setVirtualOnly: (virtualOnly: boolean) => void;
    submitSearch: Function;
}

export enum ParentPage {
    MyEventsPage,
    FindEventPage
}

export enum EventView {
    LIST = 'LIST',
    MONTH = 'MONTH'
}

function FindAnEventForm(props: FindAnEventFormProps): JSX.Element {
    const {t} = useTranslation('findAnEvent');
    const [postalCode, setPostalCode] = useState<string | null>(props.postalCode);
    const [distance, setDistance] = useState<number>(props.distance);
    const {view, setView} = props;
    const {virtualOnly, setVirtualOnly} = props;
    const {
        children,
        parentPage
    } = props;

    const classes = CCStyles();

    function onToggleVirtualOnly() {
        setVirtualOnly(!virtualOnly);
    }

    function onChangePostalCode(event: ChangeEvent<HTMLInputElement>) {
        setPostalCode(event.target.value);
    }

    async function onSubmitSearchForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        props.setPostalCodeAndDistance(postalCode, distance);
    }

    useEffect(() => {
        setDistance(props.distance);
        setPostalCode(props.postalCode);
    }, [props.distance, props.postalCode]);

    return (
        <>
            {parentPage === ParentPage.MyEventsPage &&
            <>
                <form className={`${classes.root} FindAnEventForm`} onSubmit={onSubmitSearchForm}>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} FindAnEventForm_FormControl`}
                        component="div">
                        <div className={"FindAnEventForm_SearchFields"}>
                            <div className="FindAnEventForm_ZipCodeWrapper">
                                <ZipCodeSelect
                                    onChangePostalCode={onChangePostalCode}
                                    postalCode={postalCode}
                                    disable={virtualOnly}
                                />
                            </div>
                            <div className="FindAnEventForm_DistanceWrapper">
                                <DistanceSelect
                                    onChangeDistance={setDistance}
                                    distance={distance}
                                />
                            </div>
                        </div>
                        <div className="FindAnEventForm_SubmitButtonWrapper">
                            <Button
                                type="submit"
                                buttonStyle={ButtonStyles.UnfilledWithBorder}
                                className={"FindAnEventForm_SubmitButton"}>
                                {t('findAnEventForm.searchButtonText')}
                            </Button>
                        </div>
                    </FormControl>
                </form>
            </>
            }
            {parentPage === ParentPage.FindEventPage &&
            <>
                <form className={`${classes.root} FindAnEventForm`}
                      onSubmit={onSubmitSearchForm}>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EventSearchPage_FormControl`}
                        component="fieldset">
                        <div className={"EventSearchPage_FormControl_Row1"}>
                            <SectionHeader title={t('findAnEventForm.activities')}/>
                            <div className={"EventSearchPage_FormControl_Row1_Right"}>
                                <div className={"EventSearchPage_FormControl_Location"}>
                                    <LocationOn className={"EventSearchPage_FormControl_LocationIcon"} />
                                    <div className="EventSearchPage_ZipCodeWrapper">
                                        <ZipCodeSelect
                                            onChangePostalCode={onChangePostalCode}
                                            postalCode={postalCode}
                                            disable={virtualOnly}
                                        />
                                    </div>
                                    <div className="FindAnEventForm_DistanceWrapper">
                                        <DistanceSelect
                                            onChangeDistance={setDistance}
                                            distance={distance}
                                        />
                                    </div>
                                </div>
                                <Button
                                    type="submit"
                                    buttonStyle={ButtonStyles.UnfilledWithBorder}
                                    className={"FindAnEventForm_SubmitButton"}>
                                    {t('findAnEventForm.searchButtonText')}
                                </Button>
                            </div>
                        </div>
                        <div className={"EventSearchPage_FormControl_Row2"}>
                            <div className={"EventSearchPage_FormControl_Row2_Left"}>
                                <EventViewTypeSelect view={view} setView={setView} />
                                {props.isTodayButtonEnabled &&
                                    <Button clickHandler={props.onClickTodayButton}
                                            buttonStyle={ButtonStyles.Unfilled}
                                            saving={props.searching}
                                            disabled={!props.isTodayButtonEnabled}
                                            className="TodayButton button">
                                        {t('findAnEventForm.todayButtonText')}
                                    </Button>
                                }
                                <div className={"EventSearchFields"}>
                                    <div className={"EventSearchPage_FormControl_VirtualOnly"}>
                                        <Switch
                                            checked={virtualOnly}
                                            onChange={onToggleVirtualOnly}
                                        />
                                        {t('findAnEventForm.virtualOnly')}
                                    </div>
                                </div>
                            </div>
                            <div className={"EventSearchFields"}>
                                <PassionMultiSelect
                                    onChangeSelectedPassions={props.onChangeSelectedPassions}
                                    passionIds={props.selectedPassionIds}
                                    passions={props.allPassions}/>
                            </div>
                        </div>
                    </FormControl>
                </form>
                {children}
            </>
            }
        </>
    );
}

interface ZipCodeSelectProps {
    postalCode: string | null;
    onChangePostalCode: Function;
    className?: string;
    disable: boolean;
}

function ZipCodeSelect(props: ZipCodeSelectProps): JSX.Element {
    const { t } = useTranslation('findAnEvent');
    const classes = CCStyles();
    const [errored, setErrored] = useState<boolean>(!isValid());

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        props.onChangePostalCode(event);
    }

    function isValid(): boolean {
        return (!!props.postalCode && /^\d{5}$/.test(props.postalCode)) || props.postalCode === null || props.disable;
    }

    function onBlur() {
        setErrored(!isValid());
    }

    useEffect(() => {
        setErrored(!isValid());
    }, [props.disable]);

    return (
        <TextField value={(props.postalCode === null) ? "" : props.postalCode}
                    
                   onChange={onChange}
                   onBlur={onBlur}
                   variant="outlined"
                   disabled={props.disable ?? false}
                   error={errored}
                   className={`${classes.root} ${props.className !== undefined && props.className}`}
                   label={t('findAnEventForm.zipCodeLabel')}
                   InputLabelProps={{ required: false }}
                   inputProps={{maxLength: 10}}/>
    );
}

interface DistanceSelectProps {
    distance: number,
    onChangeDistance: Function
}

function DistanceSelect(props: DistanceSelectProps): JSX.Element {
    const {t} = useTranslation('findAnEvent');

    function onChange(event: ChangeEvent<MuiSelectElement>) {
        props.onChangeDistance(event.target.value);
    }

    return (
        <Select onChange={onChange}
                value={props.distance}
                variant="outlined"
                required
                label={t('findAnEventForm.distanceLabel')}>
            {DISTANCE_OPTIONS.map(distance => (
                <MenuItem key={distance} value={distance}>
                    {t('findAnEventForm.distance', { distance })}
                </MenuItem>
            ))}
        </Select>
    )
}

export default withRouter(FindAnEventForm);

