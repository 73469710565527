import {
    InputConfig,
    RegistrationInputProps,
    useRootFormValues,
    useSubFormInput
} from "../../../util/Forms";
import {useTranslation} from "react-i18next";
import {FormHelperText, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {GetPayerResponseBody} from "../../../services/MemberService";
import "./InsuranceIdInput.scss";

export function InsuranceIdInput(props: RegistrationInputProps) {
    const { t } = useTranslation('pages');
    const { selectedPayer } = useRootFormValues<{selectedPayer: GetPayerResponseBody}>();
    const [insuranceIdLabel, setInsuranceIdLabel] = useState<string>('');

    const inputConfig: InputConfig<string> = {
        name: "insuranceId",
        initialValue: "",
        validate: (value: string): string | undefined => {
            if (selectedPayer === null) {
                return;
            }

            if (selectedPayer.payerSlug === "united-healthcare") {
                if (value === "") {
                    return t('registration.insuranceId.errors.confCodeRequired');
                }
                const validRenewIdRegex = /^[SAsa][0-9]{9}$/;
                if (!value.match(validRenewIdRegex)) {
                    return t('registration.insuranceId.errors.confCodeFormat');
                }
                return;
            }

            if (value === "") {
                return t('registration.insuranceId.errors.payerIdRequired', {payerName: selectedPayer.payerName});
            }
        },
        validateOnBlur: true
    }

    const [formState, {text}] = useSubFormInput(inputConfig);
    useEffect(() => {
        if (selectedPayer) {
            if (selectedPayer.payerSlug === "united-healthcare") {
                setInsuranceIdLabel(t('registration.insuranceId.labelConfCode'));
            }
            else if (selectedPayer.unlistedPayerPlaceholder){
                setInsuranceIdLabel(t('registration.insuranceId.labelHealthPlanId'));
            }
            else {
                setInsuranceIdLabel(t('registration.insuranceId.labelPayerId', {payerName: selectedPayer.payerName}));
            }
        }
    }, [selectedPayer, t])

    return (
        <>
            <TextField {...text(inputConfig)}
                       error={formState.errors.insuranceId !== undefined}
                       helperText={formState.errors.insuranceId}
                       className={`RegistrationWizard_Input InsuranceIdInput ${props.className || ''}`}
                       required
                       inputProps={{maxLength: 50}}
                       label={insuranceIdLabel}
                       variant="outlined"
            />
            {selectedPayer.payerSlug === "united-healthcare" &&
                <FormHelperText>
                    {t('registration.insuranceId.helperTextConfCode')}
                </FormHelperText>
            }
        </>
    )
}