export enum ClubVerificationStatus {
    VALID = 1,
    INVALID = 2,
    UNVERIFIED = 3
}

export enum ClubType {
    CLUB = 1,
    ASSOCIATION = 2
}
export interface IClubId {
    clubId: number
}

export enum PlanOptions {
    Monthly = 1,
    Annual = 2
}