import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import "./ClubDetailCard.scss";

interface ClubDetailCardProps {
    club: any
    className?: string,
}

/* 
 * ClubDetailCard component
 *  Jira: https://hellogrouperhealth.atlassian.net/browse/EDP-268
 *  Figma: https://www.figma.com/file/0ZCdv7tDtSxIdiakBRVx3e/Club-directory-(redesign)?type=design&node-id=494-2461&mode=design&t=cydnt88p4icMIuEh-0
 *
 *  ClubDetailCard components hold the individual club data in the ClubDirectory's search results
*/

export default function ClubDetailCard(props: ClubDetailCardProps): JSX.Element {
    const { t } = useTranslation('club');
    const history = useHistory();
    let classes = 'ClubDirectory_ClubDetailCard';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    const clubRoute = "/clubs/" + props.club.id

    // clubs can have one or many terms / activity types
    const terms = props.club.terms.join(", ");
   
    const getLocation = () => {
        let location = "";
        if (props.club.locations && props.club.locations.length > 0) {
            if (props.club.locations[0].city || props.club.locations[0].state) {
                location += (props.club.locations[0].city) ? (props.club.locations[0].city) : "";
                if (props.club.locations[0].state) {
                    location += (location.length > 0) ? ", " + props.club.locations[0].state : props.club.locations[0].state;
                }
            }
        }
        return location;
    }
    return (

        <Card className={"ClubDetailCard"}>
            <div className="ClubDetailCard_ClickableContainer"
                onClick={() => history.push(clubRoute)}
            >
                <CardContent className="ClubDetailCard_Content">
                    <div className="ClubDetailCard_ActivityTypes">{terms}</div>
                    <div className="ClubDetailCard_ClubName">{props.club.name}</div>
                    <div className="ClubDetailCard_Text ClubDetailCard_Text--Host">{(props.club.organizationName) && "Hosted by " + props.club.organizationName}</div>
                    <div className="ClubDetailCard_Text ClubDetailCard_Text--Location">{getLocation()}</div>
                    <div className="ClubDetailCard_Text ClubDetailCard_Text--Description">
                        {props.club.description}
                    </div>

                </CardContent>
                    <CardMedia
                        className="ClubDetailCard_Image"
                        image={"/images/passions-thumbnail-wide/" + ((props.club.clubImage)? props.club.clubImage : 'other.jpg')}
                        title="{props.club.clubName}"
                    />
                

            </div>
        </Card>
    );
}





