import React from "react";
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import { useParams, Link, useHistory } from "react-router-dom";
import Loading from "../../../components/ignite/layout/Loading"
import useClubById from '../../../hooks/useClubById'

import '../clubRequesting/ClubRequesting.scss'
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { getClubLeaderData } from "../../../util/ignite/Dashboard.utils";

const ClubRequested = () => {
    const { t } = useTranslation('ignite/clubFinder');

    const { clubId } = useParams()
    const history = useHistory()

    const [club, isLoading] = useClubById(clubId)

    if (isLoading) return <Loading loading={isLoading} />

    if (!club) return <div>{t('noSuchClub')}</div>

    const onClick = () => {
        const route = getIgniteRoute(IGNITE_ROUTE_KEY.CONTACT_METHODS, { ':clubId': clubId })
        history.push(route)
    }

    const { clubName, location, clubImage } = club
    const { city, countrySubdivision } = location || {}
    const locationRow = [city, countrySubdivision].filter(Boolean).join(', ')
    const clubLeaderName = getClubLeaderData(club)

    return (
        <div>
            <div className="ignite-page-content club-finder">
                <div className='request-club'>
                    <h1>{t('title')}</h1>
                    <div className='request-club__description'>
                        <div>{t('description.requested')}</div>
                    </div>
                    <Box className="request-club__info">
                        <div><img className="request-club__logo" src={"/images/passions/" + clubImage} alt="" /></div>
                        <div>
                            <b className="request-club__name" >{clubName}</b>
                            {locationRow && <div>{locationRow}</div>}
                            {clubLeaderName && <div>{t('clubLeader')} <b>{clubLeaderName}</b></div>}
                        </div>
                    </Box>
                    <Button
                        className='request-button'
                        variant="outlined"
                        onClick={onClick}
                    >
                        {t('continue')}
                        <PlayIcon className='request-button__icon' />
                    </Button>
                </div>
            </div>
            <Box className="not-right-club">
                <b>{t('notRightClub')}</b>
                <div>{t('findBestClub')}</div>
                <Link
                    className="not-right-club__finder"
                    to={"/ignite/club-finder"}
                >
                    {t('clubFinder')}
                </Link>
            </Box>
        </div>
    )
}

export default ClubRequested
