import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { INotificationPreference } from '../types/profile';

export const UPDATE_MY_PROFILE_NOTIFICATION_PREFERENCE_URL = '/my-profile/notification-preference'

const postUpdateNotificationPreference = async (body: INotificationPreference): Promise<undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_MY_PROFILE_NOTIFICATION_PREFERENCE_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postUpdateNotificationPreference
