import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {TextField, Button, MenuItem} from "@material-ui/core";
import Loading from "../layout/Loading";
import { useFormState } from "react-use-form-state";
import { getHealthPlanFormConfig } from './profileFormConfigs'
import { ProfilePanel } from "./ProfilePanel";
import FieldStatusIcon from "../registration/FieldStatusIcon";
import useLatestEligibilityStatus from "../../../hooks/useLatestEligibilityStatus";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import { PayerEligibilityVerificationStatus } from "../../../api/getEligibilityStatus";
import Switcher from "../Switcher/Switcher";
import postUpdateProfileHealthPlan from "../../../api/postUpdateProfileHealthPlan";
import cn from "classnames";
import {
    MEMBER_CODE_MASK_REGEX,
    MEMBER_CODE_REGEX, MEMBER_ID_MASK_REGEX,
    MEMBER_ID_REGEX, OPTIONAL_GROUP_NUMBER_MASK_REGEX,
    OPTIONAL_GROUP_NUMBER_REGEX
} from "../../../constants/regex";
import {VERIFICATION_STATUS} from "../../../constants/verification";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getAllPayersByPayerListSlug,
    getChildPayersForParentList,
    GetPayerResponseBody
} from "../../../services/MemberService";
import LoadingMessage from "../../common/LoadingMessage";

const initialState = {
    primaryPayerId: "",
    secondaryPayerId: '',
    memberId: '',
    groupNumber: '',
    confirmationCode: '',
}

interface Option {
    name: string;
    value: number;
    slug: string;
}

const UHC_SLUG = 'united-healthcare'

export const HealthPlan = ( {setEligibilityModalOpenStatus}: {setEligibilityModalOpenStatus: Function} ) => {
    const { t } = useTranslation('ignite/myInfo');
    const [isEditing, setIsContactInfoEditing] = useState(false);
    const [haveConfirmationCode, setHaveConfirmationCode] = useState(false);
    const [latestEligibility, isLatestEligibilityLoading, getLatestEligibility] = useLatestEligibilityStatus(true);

    const [formState, { text, select }] = useFormState(initialState)
    const [otherPayerId, setOtherPayerId] = useState<number>(0);
    const [primaryPayerId, setPrimaryPayerId] = useState<number>(0);
    const [secondaryPayerId, setSecondaryPayerId] = useState<number>(0);
    const [secondaryPayerError, setSecondaryPayerError] = useState<boolean>(false);

    const [placeholderMemberId, setPlaceholderMemberId] = useState<string>('');
    const [updatedMemberId, setUpdatedMemberId] = useState<string>(placeholderMemberId);
    const [memberIdError, setMemberIdError] = useState<boolean>(false);

    const [placeholderGroupNumber, setPlaceholderGroupNumber] = useState<string>('');
    const [updatedGroupNumber, setUpdatedGroupNumber] = useState<string>(placeholderGroupNumber);
    const [groupNumberError, setGroupNumberError] = useState<boolean>(false);

    const [uhcPayerId, setUhcPayerId] = useState<number | undefined>(undefined);
    const [placeholderConfirmationCode, setPlaceholderConfirmationCode] = useState<string>('');
    const [updatedConfirmationCode, setUpdatedConfirmationCode] = useState<string>(placeholderConfirmationCode);
    const [confirmationCodeError, setConfirmationCodeError] = useState<boolean>(false);

    const [payersLoading, setPayersLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [displayedPayers, setDisplayedPayers] = useState<GetPayerResponseBody[]>([]);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const [payerName, setPayerName] = useState<string>('');

    // Autocomplete health plan
    const [options, setOptions] = useState(new Array<Option>());
    const [autoCompleteValue, setAutoCompleteValue] = useState<Option | string | null >(null);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [isUHC, setIsUHC] = useState(false);

    const getSubmitEnabled = () => {
        return !secondaryPayerError &&
            ((haveConfirmationCode && !confirmationCodeError) ||
            (!haveConfirmationCode && !memberIdError && !groupNumberError));
    }

    const onUpdate = () => {
        postUpdateProfileHealthPlan({
            payerId: primaryPayerId === otherPayerId ? (secondaryPayerId === 0 ? otherPayerId : secondaryPayerId) : primaryPayerId,
            userSuppliedPayerName: primaryPayerId === otherPayerId && secondaryPayerId === 0 ? inputValue : "",
            memberId: haveConfirmationCode ? '' : updatedMemberId,
            groupNumber: haveConfirmationCode ? '' : updatedGroupNumber,
            memberCode: haveConfirmationCode ? updatedConfirmationCode : ''
        }).then(() => {
            getLatestEligibility()
            setEligibilityModalOpenStatus(true)
        })
    }

    const validateSecondaryPayer = () => {
        setSecondaryPayerError(primaryPayerId === otherPayerId && autoCompleteValue === null && inputValue.length === 0);
    }

    useEffect(() => {
        const loadAllPayers = async () => {
            try {
                // Displayed payers
                const displayedPayersResponseBody = await getAllPayersByPayerListSlug();
                setDisplayedPayers(displayedPayersResponseBody.allPayers.sort((a,b) => {
                    if (!a.unlistedPayerPlaceholder && !b.unlistedPayerPlaceholder)
                        return a.payerName.localeCompare(b.payerName);
                    else if (a.unlistedPayerPlaceholder)
                        return 1;
                    else
                        return -1;
                }));

                const otherId = displayedPayersResponseBody.allPayers.find(p => p.unlistedPayerPlaceholder)?.id;
                setUhcPayerId(displayedPayersResponseBody.allPayers.find(p => p.payerSlug === UHC_SLUG)?.id);
                setOtherPayerId(otherId ?? 0);
                if (otherId !== undefined) {
                    const allPayersResponseBody = await getChildPayersForParentList(otherId);
                    setAllPayers(allPayersResponseBody.allPayers.sort((a,b) => a.payerName.localeCompare(b.payerName)));
                }
                setPayersLoading(false);
                setLoadingErrorMessage(null);
            } catch (e) {
                setPayersLoading(false);
                setLoadingErrorMessage(t('memberSelectPayerStep.unexpectedError'));
            }
        };

        loadAllPayers();
    }, []);

    const validateMemberId = () => {
        if (placeholderMemberId === updatedMemberId || updatedMemberId === null || updatedMemberId.length === 0) {
            setMemberIdError(placeholderMemberId === null || (placeholderMemberId?.match(MEMBER_ID_MASK_REGEX) ?? null) === null);
        } else {
            setMemberIdError((updatedMemberId?.match(MEMBER_ID_REGEX) ?? null) === null);
        }
    }

    const onFocusMemberId = () => {
        if (placeholderMemberId === updatedMemberId) {
            setUpdatedMemberId('');
            formState.setField('memberId', '');
        }
    }

    const onBlurMemberId = () => {
        if (updatedMemberId.length === 0) {
            setUpdatedMemberId(placeholderMemberId);
            formState.setField('memberId', placeholderMemberId);
        }
        validateMemberId();
    }

    useEffect(() => {
        if (!haveConfirmationCode) {
            validateMemberId();
        }
    }, [updatedMemberId]);

    const validateGroupNumber = () => {
        if (placeholderGroupNumber === updatedGroupNumber || updatedGroupNumber === null || updatedGroupNumber.length === 0) {
            setGroupNumberError((placeholderGroupNumber?.match(OPTIONAL_GROUP_NUMBER_MASK_REGEX) ?? null) === null);
        } else {
            setGroupNumberError((updatedGroupNumber?.match(OPTIONAL_GROUP_NUMBER_REGEX) ?? null) === null);
        }
    }

    const onFocusGroupNumber = () => {
        if (placeholderGroupNumber === updatedGroupNumber) {
            setUpdatedGroupNumber('');
            formState.setField('groupNumber', '');
        }
    }

    const onBlurGroupNumber = () => {
        if (updatedGroupNumber.length === 0) {
            formState.setField('groupNumber', placeholderGroupNumber);
            setUpdatedGroupNumber(placeholderGroupNumber);
        }

        validateGroupNumber();
    }

    useEffect(() => {
        if (!haveConfirmationCode) {
            validateGroupNumber();
        }
    }, [updatedGroupNumber]);

    const onFocusConfirmationCode = () => {
        if (placeholderConfirmationCode === updatedConfirmationCode) {
            setUpdatedConfirmationCode('');
            formState.setField('confirmationCode', '');
        }
    }

    const validateConfirmationCode = () => {
        if (!haveConfirmationCode) {
            setConfirmationCodeError(false);
            return;
        }

        if (placeholderConfirmationCode === updatedConfirmationCode) {
            setConfirmationCodeError(!(placeholderConfirmationCode?.match(MEMBER_CODE_MASK_REGEX)));
        } else {
            setConfirmationCodeError(!updatedConfirmationCode.match(MEMBER_CODE_REGEX));
        }
    }

    const onBlurConfirmationCode = () => {
        validateConfirmationCode();

        if (updatedConfirmationCode.length === 0) {
            formState.setField('confirmationCode', placeholderConfirmationCode);
            setUpdatedConfirmationCode(placeholderConfirmationCode);
        }
    }

    useEffect(() => {
        if (haveConfirmationCode) {
            validateConfirmationCode();
        } else {
            validateMemberId();
        }
    }, [updatedConfirmationCode, haveConfirmationCode]);

    useEffect(() => {
        const loadAllPayers = async () => {
            try {
                // Displayed payers
                const displayedPayersResponseBody = await getAllPayersByPayerListSlug();
                setDisplayedPayers(displayedPayersResponseBody.allPayers.sort((a,b) => {
                    if (!a.unlistedPayerPlaceholder && !b.unlistedPayerPlaceholder)
                        return a.payerName.localeCompare(b.payerName);
                    else if (a.unlistedPayerPlaceholder)
                        return 1;
                    else
                        return -1;
                }));

                const otherId = displayedPayersResponseBody.allPayers.find(p => p.unlistedPayerPlaceholder)?.id;
                setUhcPayerId(displayedPayersResponseBody.allPayers.find(p => p.payerSlug === UHC_SLUG)?.id);
                setOtherPayerId(otherId ?? 0);
                if (otherId !== undefined) {
                    const allPayersResponseBody = await getChildPayersForParentList(otherId);
                    setAllPayers(allPayersResponseBody.allPayers.sort((a,b) => a.payerName.localeCompare(b.payerName)));
                }
                setPayersLoading(false);
                setLoadingErrorMessage(null);
            } catch (e) {
                setPayersLoading(false);
                setLoadingErrorMessage(t('memberSelectPayerStep.unexpectedError'));
            }
        };

        loadAllPayers();
    }, []);

    useEffect(() => {
        // Prepopulate autocomplete field if applicable
        if (latestEligibility) {
            const primaryPayer = displayedPayers.find(p => p.id === latestEligibility.payerId);
            let payerName = '';
            let isUHC = false;
            if (primaryPayer) {
                setPrimaryPayerId(latestEligibility.payerId);
                payerName = primaryPayer.payerName;
                if (primaryPayer?.payerSlug === UHC_SLUG) {
                    isUHC = true;
                } else if (primaryPayer.unlistedPayerPlaceholder) {
                    payerName = latestEligibility.userSuppliedPayerName ?? primaryPayer.payerName;
                    setInputValue(payerName);
                    setAutoCompleteValue(payerName);
                }
            } else {
                const secondaryPayer = allPayers.find(p => p.id === latestEligibility.payerId);
                if (secondaryPayer) {
                    const selectedOption : Option = {
                        name: secondaryPayer.payerName,
                        value: secondaryPayer.id,
                        slug: secondaryPayer.payerSlug
                    };
                    setPrimaryPayerId(otherPayerId);
                    setAutoCompleteValue(selectedOption);
                    payerName = secondaryPayer.payerName;
                    if (secondaryPayer?.parentId === uhcPayerId || secondaryPayer?.payerSlug === UHC_SLUG) {
                        isUHC = true;
                    }
                }
            }
            setIsUHC(isUHC);
            if (isUHC) {
                if (latestEligibility.memberCode && latestEligibility.memberCode.length > 0) {
                    setHaveConfirmationCode(true);
                }
            }

            if (payerName.length === 0 && latestEligibility.userSuppliedPayerName) {
                payerName = latestEligibility.userSuppliedPayerName;
            }
            setPayerName(payerName);
            setPlaceholderMemberId(latestEligibility.memberId);
            setUpdatedMemberId(latestEligibility.memberId);
            setPlaceholderGroupNumber(latestEligibility.groupNumber);
            setUpdatedGroupNumber(latestEligibility.groupNumber);
            setPlaceholderConfirmationCode(latestEligibility.memberCode ?? '');
            setUpdatedConfirmationCode(latestEligibility.memberCode ?? '');
        }
    }, [allPayers, latestEligibility]);

    // Compute the options for the autocomplete dropdown.
    useEffect(() => {
        const results: GetPayerResponseBody[] = allPayers.filter(p => p.payerName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

        const newOptions: Option[] =
            results.map(payer => {
                return {
                    name: payer.payerName,
                    value: payer.id,
                    slug: payer.payerSlug
                };
            });

        setOptions(newOptions);

    }, [inputValue, allPayers]);

    useEffect(() => {
        validateMemberId();
        validateSecondaryPayer();

        if (primaryPayerId !== otherPayerId) {
            const selectedPayer = allPayers.find(p => p.id === primaryPayerId);
            setPayerName(selectedPayer?.payerName ?? '');
            setSecondaryPayerId(0);
            const isUhc = selectedPayer?.payerSlug === UHC_SLUG;
            setIsUHC(isUhc);
            if (isUhc) {
                setHaveConfirmationCode(placeholderConfirmationCode.length > 0 || updatedConfirmationCode.length > 0);
            } else {
                setHaveConfirmationCode(false);
            }
            setAutoCompleteValue(null);
            return;
        }

        if (autoCompleteValue === null || typeof autoCompleteValue === 'string' ) {
            setSecondaryPayerId(0);
            setIsUHC(false);
            setHaveConfirmationCode(false);
            setPayerName(inputValue);
            return;
        }

        const selectedPayer = allPayers.find(p => p.id === autoCompleteValue.value);
        const isUhc = selectedPayer?.parentId === uhcPayerId || selectedPayer?.payerSlug === UHC_SLUG;
        setIsUHC(isUhc);
        if (isUhc) {
            setHaveConfirmationCode(placeholderConfirmationCode.length > 0 || updatedConfirmationCode.length > 0);
        }
        setSecondaryPayerId(autoCompleteValue.value);
        setPayerName(autoCompleteValue.name);
    }, [autoCompleteValue, inputValue, primaryPayerId, secondaryPayerId]);

    const elementConfig = getHealthPlanFormConfig()

    const onContactInfoEdit = () => setIsContactInfoEditing(true)

    const getVerificationStatusIcon = () => {
        const status = latestEligibility?.verificationStatus
        if (status === PayerEligibilityVerificationStatus.ACTIVE
            || status === PayerEligibilityVerificationStatus.LEGACY_ACTIVE
        ) return <CheckCircleIcon className="icon success" />
        return <RadioButtonCheckedIcon className="icon pending" />
    }

    const getVerificationStatusMessageKey = () => {
        const verificationStatus = latestEligibility?.verificationStatus ?? undefined;
        if(verificationStatus){
            const notConsideredInProgress: Array<PayerEligibilityVerificationStatus> = [
                PayerEligibilityVerificationStatus.ACTIVE,
                PayerEligibilityVerificationStatus.LEGACY_ACTIVE
            ];
            if(notConsideredInProgress.includes(verificationStatus)) {

            }
        }
        return PayerEligibilityVerificationStatus.IN_PROGRESS;
    }

    const isLoading = isLatestEligibilityLoading || payersLoading

    return (
        <>
            <ProfilePanel
                title={t('selectYourHealthPlan')}
                className="health-plan"
                isEditing={isEditing}
                onEdit={onContactInfoEdit}
            >
                <>
                    {isLoading && loadingErrorMessage === null && (
                        <Loading loading={isLoading}/>
                    )}
                    {!isLoading && loadingErrorMessage !== null && (
                        <LoadingMessage message={loadingErrorMessage}/>
                    )}
                    <div className={cn("profile-group", { 'with-code': isUHC && haveConfirmationCode })}>
                        <div className='profile-item payer-id'>
                            <label className='profile-item__label' htmlFor="payer-id">
                                {t('provider')}
                            </label>
                            <div className='profile-item__main'>
                                {!isEditing &&
                                    <div className='profile-item__value'>
                                        {payerName}
                                    </div>
                                }
                                {isEditing &&
                                    <TextField
                                        {...select(elementConfig.primaryPayerId)}
                                        variant="outlined"
                                        error={isEditing && Boolean(formState.errors.payerId)}
                                        helperText={isEditing && formState.errors.payerId}
                                        onChange={(e) => setPrimaryPayerId(Number(e.target.value))}
                                        value={primaryPayerId}
                                        InputLabelProps={{
                                            htmlFor: 'primary-payer-id',
                                        }}
                                        select={true}
                                        classes={{ root: 'select preferred-contact-method-select', }}
                                        InputProps={{
                                            id: "primary-payer-id",
                                            readOnly: !isEditing,
                                            classes: {
                                                notchedOutline: 'wrapper',
                                                root: 'base-input'
                                            }
                                        }}
                                        SelectProps={{
                                            classes: {
                                                root: 'select',
                                                icon: 'icon'
                                            }
                                        }}
                                        size='small'
                                    >
                                        {displayedPayers.map(payer => (
                                            <MenuItem key={payer.id} value={payer.id}>
                                                {payer.payerName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            </div>
                        </div>

                        <div className='profile-item additional-payer-id'>
                            {primaryPayerId === otherPayerId &&
                                <>
                                <label className='profile-item__label' htmlFor="additional-payer-id">
                                    {t('selectYourHealthPlan')}
                                </label>
                                <div className='profile-item__main'>
                                    <Autocomplete
                                        open={open}
                                        freeSolo={true}
                                        style={{width: "100%"}}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        onChange={(event, value) => {
                                            setAutoCompleteValue(value);
                                        }}
                                        value={autoCompleteValue}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                                        options={options}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                required
                                                style={{marginBottom: '0px', width: '100%', padding: 0}}
                                                classes={{ root: 'select preferred-contact-method-select' }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                    classes: {
                                                        notchedOutline: 'wrapper',
                                                        root: 'base-input auto-complete'
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                {isEditing && (
                                    <div className='field-indicator-wrapper' style={{top: '26px'}}>
                                        <FieldStatusIcon shouldShow isError={secondaryPayerError} />
                                    </div>
                                )}
                            </>
                        }
                        </div>
                        <div className="profile-item confirmation-code-switcher">
                            {
                                isUHC && isEditing && <div>
                                    {t('DoYouHaveCode')}
                                    <Switcher
                                        isChecked={haveConfirmationCode}
                                        onChange={() => {
                                            setHaveConfirmationCode(prev => !prev)
                                        }}
                                        positiveText={t('positiveNotificationText')}
                                        negativeText={t('negativeNotificationText')}
                                    />
                                </div>
                            }
                        </div>
                        <div className='profile-item confirmation-code'>
                            {haveConfirmationCode && isUHC && (
                                <>
                                    <label className='profile-item__label' htmlFor="confirmation-code">
                                        {t('memberCodeSubtitle')}
                                    </label>
                                    <div className='profile-item__main'>
                                        <TextField
                                            {...text(elementConfig.confirmationCode)}
                                            label={isEditing ? t('memberCodeLabel') : ''}
                                            variant="outlined"
                                            fullWidth
                                            error={isEditing && confirmationCodeError}
                                            helperText={isEditing && confirmationCodeError ? t('errors.confirmationCode') : ''}
                                            value={updatedConfirmationCode}
                                            onFocus={onFocusConfirmationCode}
                                            onChange={(e) => setUpdatedConfirmationCode(e.target.value)}
                                            onBlur={onBlurConfirmationCode}
                                            placeholder={placeholderConfirmationCode}
                                            id="confirmation-code"
                                            size="small"
                                            InputLabelProps={{
                                                htmlFor: 'confirmation-code',
                                                required: false,
                                                classes: {
                                                    root: 'label-root',
                                                    error: 'label-error',
                                                    focused: 'label-focused'
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: 'error-label'
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: !isEditing,
                                                classes: {
                                                    input: 'profile-item__input',
                                                    notchedOutline: 'wrapper',
                                                }
                                            }}
                                        />
                                    </div>
                                    {isEditing && (
                                        <div className='field-indicator-wrapper' style={{top: '26px'}}>
                                            <FieldStatusIcon shouldShow isError={confirmationCodeError} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {!haveConfirmationCode && (
                            <div className='profile-item member-id'>
                                {!isEditing && <label className='profile-item__label' htmlFor="member-id">
                                    {t('memberId')}
                                </label>}

                                <div className='profile-item__main'>
                                    <TextField
                                        {...text(elementConfig.memberId)}
                                        error={isEditing && memberIdError}
                                        helperText={isEditing && memberIdError ? t('errors.memberId') : ''}
                                        id="member-id"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={isEditing ? t('memberId') : ''}
                                        value={updatedMemberId}
                                        onFocus={onFocusMemberId}
                                        onChange={(e) => setUpdatedMemberId(e.target.value)}
                                        onBlur={onBlurMemberId}
                                        placeholder={placeholderMemberId}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: 'error-label'
                                            }
                                        }}
                                        InputLabelProps={{
                                            htmlFor: 'member-id',
                                            required: false,
                                            classes: {
                                                error: 'label-error',
                                                focused: 'label-focused'
                                            }
                                        }}
                                        InputProps={{
                                            readOnly: !isEditing,
                                            classes: {
                                                input: 'profile-item__input',
                                                notchedOutline: 'wrapper',
                                            }
                                        }}
                                    />
                                </div>
                                {isEditing && (
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon shouldShow isError={memberIdError} />
                                    </div>
                                )}
                            </div>
                        )}

                        {!haveConfirmationCode && (
                            <div className="profile-item">
                                {!isEditing &&
                                    <div className="profile-item__label">
                                        {t('groupNumber')}
                                    </div>
                                }
                                <div className="profile-item__main">
                                    <TextField
                                        {...text(elementConfig.groupNumber)}
                                        label={isEditing ? t('groupNumber') : ''}
                                        variant="outlined"
                                        fullWidth
                                        error={isEditing && groupNumberError}
                                        helperText={isEditing && groupNumberError ? t('errors.groupNumber') : ''}
                                        value={updatedGroupNumber}
                                        onFocus={onFocusGroupNumber}
                                        onChange={(e) => setUpdatedGroupNumber(e.target.value)}
                                        onBlur={onBlurGroupNumber}
                                        placeholder={placeholderGroupNumber}
                                        id="group-number-id"
                                        size="small"
                                        FormHelperTextProps={{
                                            classes: {
                                                root: 'error-label'
                                            }
                                        }}
                                        InputLabelProps={{
                                            htmlFor: 'group-number-id',
                                            required: false,
                                            classes: {
                                                error: 'label-error',
                                                focused: 'label-focused'
                                            }
                                        }}
                                        InputProps={{
                                            readOnly: !isEditing,
                                            classes: {
                                                input: 'profile-item__input',
                                                notchedOutline: 'wrapper',
                                            }
                                        }}
                                    />
                                </div>
                                {isEditing && (
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon shouldShow isError={groupNumberError} />
                                    </div>
                                )}
                            </div>
                        )}

                        <div className='profile-item eligibility'>
                            {isEditing && <div className="subtitle">
                                {t('eligibilityStatus')}
                            </div>}
                            <label className='profile-item__label eligibility-status-icon'
                                   htmlFor="eligibility-id"
                                   onClick={() => setEligibilityModalOpenStatus(true)}>
                                {getVerificationStatusIcon()}&nbsp;{t('verificationStatus.' + getVerificationStatusMessageKey())}
                            </label>
                        </div>
                    </div>

                    {isEditing && (
                        <div className='button-wrapper'>
                            <Button
                                className='button cancel'
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    setIsContactInfoEditing(false)
                                    getLatestEligibility()
                                }}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                className='button update'
                                variant="outlined"
                                color="primary"
                                classes={{
                                    disabled: 'disabled'
                                }}
                                disabled={!getSubmitEnabled()}
                                onClick={() => {
                                    setIsContactInfoEditing(false)
                                    onUpdate()
                                }}
                            >
                                {t('update')}
                            </Button>
                        </div>
                    )}
                </>
            </ProfilePanel>
        </>
    )
}
