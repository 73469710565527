import React from "react";

const infoIcon = require("../../assets/info.svg");

interface InfoIconProps {
    className?: string
    onClick: Function
}

export default function InfoIcon(props: InfoIconProps) {
    return <img className={props.className} src={infoIcon} alt="circle info icon" onClick={() => props.onClick()}/>
}
