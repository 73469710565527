import React from "react";
import "./ImportedFieldPreview.scss";
import "./EventImportWizard.tsx";
import {IImportedEvent, IValidation} from "./EventImportWizard";

export default function ImportedFieldPreview(props: { ImportedEvent: IImportedEvent, DisplayName: string, FieldName: string, Value: string | Date, Pre: string, Post: string, longValue?: boolean|undefined }): JSX.Element {

    let pre: string = props.Pre;
    let post: string = props.Post;
    let value = props.Value;
    let displayValue = value;
    let fieldName = props.FieldName;
    let e: IImportedEvent = props.ImportedEvent;
    let className = fieldName;
    let tooltip: string = '';
    let show: boolean = (value !== null);
    let longValue: boolean|undefined = props.longValue;

    e.validationResults.forEach(function (validation: IValidation) {

        if (uncap(validation.displayName) === uncap(props.DisplayName) && !validation.success) {
            displayValue = validation.displayValue;
            className += ' ValidationErrored';
            if (tooltip !== '') {
                tooltip += ', ' + validation.message;
            } else {
                tooltip = validation.message;
            }
        }
    });

    let displayValueClassName = "displayvalue";
    if (longValue) {
        displayValueClassName = "displayvalue long"
    }


    function uncap(s: string) {
        return s.charAt(0).toLowerCase() + s.slice(1);
    }

    return (
        <span
            className={"ImportedFieldPreview"}>{show &&
        <div title={tooltip} id={e.id + fieldName} className={className}>
            <span className="pre">{pre}</span>
            <span className={displayValueClassName}>{displayValue}</span>
            <span className="post">{post}</span>
        </div>}</span>
    )

}