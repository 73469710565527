import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import Plus from '../icon/Plus';
import wellcareLogo from '../../assets/wellcare-logo.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderLogoProps {
  acblPage: boolean;
  isCustomEnrollmentFlow: boolean;
  isCustomEnrollmentFlowComplete: boolean;
  headerLogoImageUrl?: string;
}

const payerSlugsToLogos: Record<string, string> = {
  aetna: require('../../assets/aetna-registration-logo.png'),
  'allina-health': require('../../assets/allina-health-registration-logo.png'),
  'innovation-health': require('../../assets/innovation-health-registration-logo.png'),
};

export function HeaderLogo(props: HeaderLogoProps) {
  const location = useLocation();
  const { t } = useTranslation('pages');

  const payerRegistration =
    location.pathname.startsWith('/registration/') ||
    location.pathname.startsWith('/welcome/');
  const payerSlug = payerRegistration
    ? location.pathname.split('/')[2]?.toLowerCase()
    : null;
  const payerLogo = payerSlug
    ? payerSlugsToLogos[
        payerSlug === 'allina-health-aetna' ? 'allina-health' : payerSlug
      ]
    : null;

  return (
    <div className={'HeaderLogo'}>
      {!props.acblPage && !props.isCustomEnrollmentFlow && (
        <Link to={'/'} className={'LogoLink'}>
          <img className="Logo" src={logo} alt={t('header.logoAlt')} />
        </Link>
      )}
      {props.acblPage && (
        <>
          <img
            className="AcblLogo"
            src={require('../../assets/acbl-logo-full.png')}
            alt="ACBL logo"
          />
          <Plus className={'AcblLogoPlus'} />
          <img className="Logo" src={logo} alt="logo" />
        </>
      )}
      {props.isCustomEnrollmentFlow && props.headerLogoImageUrl && (
        <>
          {props.isCustomEnrollmentFlowComplete && (
            <Link to={'/'} className={'LogoLink'}>
              <CustomEnrollmentLogo
                headerLogoImageUrl={props.headerLogoImageUrl}
              />
            </Link>
          )}
          {!props.isCustomEnrollmentFlowComplete && (
            <CustomEnrollmentLogo
              headerLogoImageUrl={props.headerLogoImageUrl}
            />
          )}
        </>
      )}
      {payerLogo && (
        <img
          className={`Logo ${
            payerSlug === 'allina-health-aetna' ? 'allina-health' : payerSlug
          }`}
          src={payerLogo}
          alt="logo"
        />
      )}
      {location.pathname == '/wellcare' && (
        <img
          className={`Logo`}
          src={wellcareLogo}
          alt={t('header.wellCareLogoAlt')}
        />
      )}
    </div>
  );
}

interface CustomEnrollmentLogoProps {
  headerLogoImageUrl: string;
}

function CustomEnrollmentLogo(props: CustomEnrollmentLogoProps) {
  return (
    <>
      <img
        className="ClubAssociationLogo"
        src={`${props.headerLogoImageUrl}?w=80`}
        alt="Club/Association logo"
      />
      <Plus className={'ClubAssociationLogoPlus'} />
      <img className="Logo" src={logo} alt="logo" />
    </>
  );
}
