import React, {useState, useEffect, useContext} from 'react';
import RegistrationForm from '../form/RegistrationForm';
import { UserContext } from '../../../contexts/UserContext';
import MemberOfClubModal from './MemberOfClubModal';
import { sendPageView } from '../../../withTracker';
import { useHistory } from 'react-router-dom';
import AddClub from '../form/AddClub';
import { IClub } from '../../../types/clubFinder';
import RecordActivityForm from '../form/RecordActivityForm';
import HealthplaInfoForm from '../form/HealthplaInfoForm';
import ConfirmClub from './ConfirmClub';
import useGetClubs from '../../../hooks/useGetClubs';
import { Box, Button, debounce, Grid } from '@material-ui/core';
import ClubSearch from '../form/ClubSearch';
import ClubList from './ClubList';
import Loading from '../../../components/common/Loading';
import "./ClubFinderPage.scss";
import getClubFinderViaSanity, { ClubFinderFormSanityData } from '../../../sanity/getClubFinderViaSanity';
import PortableText from 'react-portable-text';
import { useTranslation } from 'react-i18next';

export enum BYOCSteps {
    Register = 1,
    ClubFinder = 2,
    AddClub = 3,
    RecordActivity = 4,
    EligibilityCheck = 5,
    ConfirmClub = 6,
}

const PageTitle = [
    "Register",
    "Club Finder",
    "Bring Your Own Club",
    "Record Club Activity",
    "Check Your Eligibility",
    "Confirm Your Club or Association"
]

const DTC_CLUB_FINDER_SLUG = 'dtccf';

export default function ClubFinderPage(){
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState<boolean>(false);
    const [byocStep, setByocStep] = useState<BYOCSteps>();
    const [club, setClub] = useState<IClub | Partial<IClub>>();
    const [filteredClubs, setFilteredClubs] = useState<Array<IClub> | undefined>();
    const {syncUser, isLoggedIn, user} = useContext(UserContext);
    const history = useHistory();
    const [clubs=[], isLoading] = useGetClubs();
    const [sanityConfig, setSanityConfig] = useState<ClubFinderFormSanityData>();
    const [loadingSanity, setLoadingSanity] = useState<boolean>(true);
    const [openConfirmClub, setOpenConfirmClub] = useState<boolean>(false);
    const { t } = useTranslation('directToConsumer/clubFinder');

    useEffect(() => {
        if(byocStep) {
            sendPageView(
                `${history.location.pathname}?step=${byocStep}`,
                `BYOC Registration Step ${PageTitle[byocStep - 1]}`
            );
        }
        window.scrollTo(0, 0);
    }, [byocStep]);

    useEffect(() => {
        if(isLoggedIn()){
            setByocStep(BYOCSteps.ClubFinder);
        } else{
            setByocStep(BYOCSteps.Register);
        }
    }, []);

    useEffect(() => {
        debounce(() => {
            searchValue.length ? 
                setFilteredClubs(
                    clubs.filter(club => club.clubName.toLowerCase().includes(searchValue.toLowerCase()))
                )
                : setFilteredClubs(clubs);
        }, 200)()

    }, [searchValue, clubs]);

    useEffect(() => {
        getClubFinderViaSanity(DTC_CLUB_FINDER_SLUG).then(data => {
            setSanityConfig(data?.result[0]);
        }).finally(() => setLoadingSanity(false));
    }, []);

    const handleRedirectToActivityDashboard = () => (
        history.push({
            pathname: club?.isIgniteEnabled ? `/ignite/dashboard/${club!.id}` : '/find-an-event',
            state: {ShowDTCWelcomeMessage: true},
            ...(!club?.isIgniteEnabled && {search: '?passionsSelected'})
        })
    )

    const onSelectedClub = (selectedClub: IClub) => {
        setClub(selectedClub);
        setOpenConfirmClub(true);
    }

    const renderFindClub = () => (
        <>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <h1 style={{
                        fontFamily: 'Open Sans',
                        fontSize: 32,
                        fontWeight: 800,
                        letterSpacing: 0,
                        marginTop: 32, 
                    }}
                >
                    {sanityConfig?.title}
                </h1>
            </Grid>
            
            <Grid item xs={12}>
                <PortableText content={sanityConfig?.description ?? []}></PortableText>
            </Grid>
        </Grid>
        {
            isLoading || loadingSanity ? 
            <Loading loading={isLoading} />
            : (
                <>
                
                {Array.isArray(sanityConfig?.resultsCta) ? 
                        <PortableText content={sanityConfig!.resultsCta}/>
                        :
                        <Box sx={{ fontSize: 20, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: 'center'}}>
                            <div 
                                className="cta-message"
                                dangerouslySetInnerHTML={{
                                    __html: `${t("section.clubFinder.notSeeingClub")}${t("section.clubFinder.addClub")}`
                                }}
                            />
                            <div style={{ flex: 1, textAlign: 'right'}}>
                                <Button
                                    style={{
                                        fontWeight: 700,
                                        flex: 1,
                                    }}
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setByocStep(BYOCSteps.AddClub)}
                                >
                                    {sanityConfig?.ctaLabel ?? t("buttons.yesAddClub.label")}
                                </Button>
                            </div>
                        </Box>
                        }
                <ClubSearch
                    searchValue={searchValue}
                    onChange={(searchValue: string) => setSearchValue(searchValue)}
                    clubs={filteredClubs ?? []} 
                    onOptionSelected={onSelectedClub}
                />
                <div className="bottom-content">
                    <ClubList
                        clubs={filteredClubs}
                        onClubSelected={onSelectedClub}
                        onAddClub={() => setByocStep(BYOCSteps.AddClub)}
                        searchValue={searchValue}
                        ctaLabel={sanityConfig?.ctaLabel}
                        resultsCtaMessage={sanityConfig?.resultsCta ?? undefined}
                        noResultsCtaMessage={sanityConfig?.noResultsCta ?? undefined}
                    />
                </div>
                </>
            )
        }
        </>
    )
    const renderStep = () => {
        switch(byocStep){
            case BYOCSteps.Register: 
                return <RegistrationForm
                    handleSubmit={() => {
                        syncUser();
                        setByocStep(BYOCSteps.EligibilityCheck);
                    }}
                    onBack={() => history.push('/register-member')}
                />;
            case BYOCSteps.ClubFinder:
                return renderFindClub();
            case BYOCSteps.AddClub:
                return (
                    <AddClub
                        clubSearchTerm={searchValue}
                        onBack={() => setByocStep(BYOCSteps.ClubFinder)}
                        onNext={(club: Partial<IClub>) => {
                            setClub(club);
                            setByocStep(BYOCSteps.RecordActivity);
                        }}
                    />
                );
            case BYOCSteps.RecordActivity:
                return (
                    <RecordActivityForm
                        onNext={() => {
                            handleRedirectToActivityDashboard();
                        }}
                        clubId={club!.id!}
                    />
                );
            case BYOCSteps.EligibilityCheck:
                return (
                    <HealthplaInfoForm
                        onSubmit={() => setByocStep(BYOCSteps.ClubFinder)}
                    />
                );
        }
    }

    return(
        <>
        {renderStep()}
        <ConfirmClub
            confirmClub={club as IClub}
            onClubConfirm={() => {
                setOpenConfirmClub(false);
                setByocStep(BYOCSteps.RecordActivity);
            }}
            onBack={() => {
                setClub(undefined);
                setOpenConfirmClub(false)
            }}
            open={openConfirmClub}
        />
        </>
    );
}