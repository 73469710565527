import React from "react";

import { formatEventRecurrence } from "../../util/Event.utils";
import { EventRecurrence } from "../../services/ClubLeaderService";

export interface RecurrenceViewProps {
    recurrence: EventRecurrence
    className?: string;
}

export default function RecurrenceView(props: RecurrenceViewProps): JSX.Element {

    let classes = 'CCDateView';
    let className = props.className;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    return (
        <>
            <div className={classes}>{formatEventRecurrence(props.recurrence)}</div>
        </>
    );
}
