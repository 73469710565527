import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import {CircularProgress, FormControl, TextField} from "@material-ui/core";
import {CCStyles} from "../form/Theme";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {loadConfig} from "../../services/ConfigService";
import LoadingMessage from "../common/LoadingMessage";
import "./ManualRsvp.scss";
import SaveIndicator from "../common/SaveIndicator";
import RsvpList from "./RsvpList";
import {UserContext} from "../../contexts/UserContext";
import {UserEventRelationshipStatus} from "../../services/ClubLeaderService";

interface ManualRsvpProps {
    eventUrlFragment: string;
    className?: string;
}

interface Option {
    name: string;
    value: string;
    disabled: boolean;
}

interface MemberSearchRequestBody {
    eventUrlFragment: string
    query: string
}

interface MemberSearchResponseBody {
    results: MemberSearchResult[]
}

interface MemberSearchResult {
    email: string
    firstName: string
    lastName: string
    status: UserEventRelationshipStatus | null
}

export default function ManualRsvp(props: ManualRsvpProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    const { authenticatedFetch } = useContext(UserContext);

    let initialClasses = "ManualRsvp";
    if (props.className !== undefined) {
        initialClasses += ' ' + props.className;
    }
    let eventUrlFragment = props.eventUrlFragment;

    const muiClasses = CCStyles();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [savingSuccessful, setSavingSuccessful] = useState(false);
    const [savingMessage, setSavingMessage] = useState<string | null>(null);
    const [savingAttempted, setSavingAttempted] = useState(false);
    const [searching, setSearching] = useState(false);
    const [open, setOpen] = useState(false);
    const [toMove, setToMove] = useState<null | string>(null);
    const [options, setOptions] = useState(new Array<Option>());
    const [autoCompleteValue, setAutoCompleteValue] = useState<Option | null>(null);
    const [sharedStateUpdater, setSharedStateUpdater] = useState("");
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (inputValue === "" || (autoCompleteValue !== null && inputValue === autoCompleteValue.name)) {
            setSearching(false);
            setOptions([]);
            return;
        }

        setSearching(true);
        let cancelled = false;

        const search = async () => {
            if (cancelled) return;

            const requestBody: MemberSearchRequestBody = {
                eventUrlFragment,
                query: inputValue
            }

            const config = await loadConfig();

            const request = new Request(`${config.apiOrigin}/search-members`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(requestBody)
            });

            const response = await authenticatedFetch(request);
            if (cancelled) return;

            if (response === null) {
                // The user became logged out.  Wait for re-render.
                return;
            }

            if (!response.ok) {
                setSearching(false);
                setErrorMessage(t('manualRSVP.loadingError'));

                const responseBodyText = await response.text();
                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);
                return;
            }

            const responseBody: MemberSearchResponseBody = await response.json();
            if (cancelled) return;

            const results = responseBody.results;

            const newOptions: Option[] =
                results.map(user => {
                    const alreadyRsvped = user.status === UserEventRelationshipStatus.Rsvp;
                    return {
                        name: `${user.firstName} ${user.lastName} <${user.email}>`
                            + (alreadyRsvped ? t('manualRSVP.alreadyRSVPedOptionText') : ""),
                        value: user.email,
                        disabled: alreadyRsvped
                    };
                });

            setSearching(false);

            setOptions(newOptions);
        };

        setTimeout(search, 500); // throttled

        return () => {
            cancelled = true;
        };

    }, [inputValue, autoCompleteValue, eventUrlFragment, authenticatedFetch, t]);

    function onClick() {
        async function load() {
            if (toMove === null || toMove === undefined) {
                return;
            }

            const config = await loadConfig();
            const postUrl = `${config.apiOrigin}/club-leader/events/manual-rsvp`;

            setSaving(true);

            let postRequest = new Request(postUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({"userEmail": toMove, "eventUrlFragment": eventUrlFragment})
            });

            let postResponse = await authenticatedFetch(postRequest);

            if (postResponse === null || !postResponse.ok) {
                console.log(postResponse);
                setSavingAttempted(true);
                setSaving(false);
                setSavingSuccessful(false);
                setSavingMessage(t('manualRSVP.addingError'));
                return;
            }

            setAutoCompleteValue(null);
            setInputValue("");
            setSaving(false);
            setSavingSuccessful(true);
            setSharedStateUpdater(new Date().toString());
        }

        load().then();
    }

    return (
        <>
            {errorMessage !== null && <LoadingMessage message={errorMessage}/>}
            <div className={initialClasses}>
                <RsvpList
                    setSharedStateUpdater={setSharedStateUpdater}
                    sharedStateUpdater={sharedStateUpdater}
                    eventUrlFragment={props.eventUrlFragment}
                    className="RsvpList"/>
                    <div className={"ManualRsvp_Instructions"}>
                        <div className={"ManualRsvp_InstructionsTitle"}>
                            {t('manualRSVP.instructionsTitle')}
                        </div>
                        <div className={"ManualRsvp_InstructionsText"}>
                            {t('manualRSVP.instructionsText')}
                        </div>
                    </div>
                    <div className={"ManualRsvp_Form"}>
                        <div className={"ManualRsvp_Autocomplete"}>
                            <FormControl
                                className={`${muiClasses.root} ${muiClasses.formControl} ManualRsvp_AutocompleteFormControl`}
                                component="fieldset">
                                <Autocomplete
                                    open={open}
                                    style={{width: "100%"}}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    onChange={(event, value) => {
                                        if (value !== undefined && value !== null && value.value !== null) {
                                            setSavingAttempted(false);
                                            setToMove(value.value);
                                        }
                                        setAutoCompleteValue(value);
                                    }}
                                    value={autoCompleteValue}
                                    getOptionDisabled={option => option.disabled}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name}
                                    options={options}
                                    loading={searching}
                                    disabled={saving}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {searching && <CircularProgress color="inherit" size={20}/>}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </div>
                        <PlayButton saving={saving} text={t('manualRSVP.moveButtonText')}
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    clickHandler={onClick}
                                    className={"ManualRsvp_Button"}/>
                        {savingAttempted && !savingSuccessful && <SaveIndicator success={savingSuccessful}
                                                                                className="ManualRsvp_SavingSuccess"
                                                                                message={savingMessage}/>}
                    </div>
            </div>
        </>
    );
}
