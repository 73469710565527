import React from "react";
import { Box, Button } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { IClub } from '../../../types/clubFinder'

interface IClubFinderItemProps {
    club: IClub
}

const ClubFinderItem = ({ club }: IClubFinderItemProps): JSX.Element => {
    const { t } = useTranslation('ignite/clubFinder');
    const history = useHistory()

    const onClick = () => {
        history.push(`/ignite/club-requesting/${club.id}`)
    }

    return (
        <Box className="found-club-item">
            <div className="found-club-item__content">
                <img src={"/images/passions/" + club.clubImage} alt="" className='found-club-item__logo' />
                {club.clubName}
            </div>
            <Button
                className='request-button'
                variant="outlined"
                onClick={onClick}
            >
                {t('request')}
                <PlayIcon className='request-button__icon' />
            </Button>
        </Box>
    )
}

export default ClubFinderItem
