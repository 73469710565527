import React, { useContext, useEffect } from "react";
import "./LoginPage.scss";
import LoginForm from "../../components/loginForm/LoginForm";
import { UserContext } from "../../contexts/UserContext";
import {useHistory} from "react-router-dom";

export default function LoginPage(props: any): JSX.Element {
    const history = useHistory();
    const { user } = useContext(UserContext);
    const state = props.location.state;

    useEffect(
        () => {
            window.scrollTo(0,0);
        }, []);

    useEffect(() => {
        if (user) {
            const referrer = state?.referrer;
            if (referrer) {
                history.push(state?.referrer);
            }
            else {
                history.push("/");
            }
        }
    }, [user]);

    return (
        <div className="LoginPage">
            <LoginForm/>
        </div>
    );
}
