import React from "react";

export interface WordDocumentIconProps {
    className?: string
}

function WordDocumentIcon(props: WordDocumentIconProps): JSX.Element {
    return (<>
        <svg viewBox="0 0 252 362" preserveAspectRatio="xMaxYMax" xmlns="http://www.w3.org/2000/svg"
             height={362} width={252} className={props.className}>
            <g transform="translate(-125.475 -608.862)">
                <rect y={540.362} width={512} ry={0} height={512} fill="none"/>
                <path
                    d="M309.168 610.862H144.975c-9.695 0-17.5 7.805-17.5 17.5v323c0 9.695 7.805 17.5 17.5 17.5h213c9.695 0 17.5-7.805 17.5-17.5V677.17"
                    fill="#2a7fff"
                    stroke="#2a7fff"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M375.476 677.168l-66.307-66.306v48.806c0 9.695 7.805 17.5 17.5 17.5z"
                    fill="#fff"
                    stroke="#2a7fff"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    style={{
                        lineHeight: "normal",
                        textIndent: 0,
                        textAlign: "start",
                        textDecorationLine: "none",
                        textDecorationStyle: "solid",
                        textDecorationColor: "#000",
                        textTransform: "none",
                        whiteSpace: "normal",
                        isolation: "auto",
                        mixBlendMode: "normal",
                    }}
                    d="M166.475 693.474l15.783 67.4h16.726l10.233-43.906 10.508 43.907h16.726l15.781-67.4h-15.388l-9.446 46.396-10.271-46.397h-15.074l-10.783 46.397-9.012-46.397zm96.746 33.188a8 8 0 100 16h65.14a8 8 0 100-16zm-88.631 47.861a8 8 0 100 16H328.36a8 8 0 100-16zm0 47.863a8 8 0 100 16H328.36a8 8 0 100-16zm0 47.864a8 8 0 100 16H328.36a8 8 0 100-16z"
                    color="#000"
                    fontWeight={400}
                    fontFamily="sans-serif"
                    overflow="visible"
                    fill="#fff"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    </>);
}

export default WordDocumentIcon;