import "./PassionMultiSelect.scss"
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Button, { ButtonStyles } from "../common/Button";
import SelectPassionsModal from "./SelectPassionsModal";
import {
    Cancel,
    ChevronLeftOutlined,
    ChevronRightOutlined
} from "@material-ui/icons";
import {GetPassionResponseBody} from "../../services/Models";
import {useWindowSize} from "../../util/Util";

interface PassionChipProps {
    passion: GetPassionResponseBody;
    removePassion: Function;
}

function PassionChip(props: PassionChipProps): JSX.Element {

    function onClickRemove() {
        props.removePassion(props.passion.id);
    }

    return (
        <div className="PassionMultiSelect_PassionChip">
            <div className={"PassionMultiSelect_PassionChip_Name"}>{props.passion.passionName}</div>
            <Cancel
                className={"PassionMultiSelect_PassionChip_Remove"}
                onClick={onClickRemove} />
        </div>
    )
}

interface PassionCarouselProps {
    passionIds: number[];
    passions: GetPassionResponseBody[];
    onChangeSelectedPassions: Function;
}

function PassionCarousel(props: PassionCarouselProps): JSX.Element {
    const { isMobile } = useWindowSize();
    const [scrollIndex, setScrollIndex] = useState<number>(0);
    const passionsById: Record<number, GetPassionResponseBody> = Object.assign({},
        ...props.passions.map(passion => ({[passion.id]: passion})));
    const selectedPassions = props.passionIds.map(id => passionsById[id]);
    const maxVisiblePassions = isMobile ? 6 : 3;
    const numberOfVisiblePassions = Math.min(maxVisiblePassions,
        props.passionIds.length);
    const maxScrollIndex = props.passionIds.length - maxVisiblePassions;
    const actualScrollIndex = Math.max(0, Math.min(scrollIndex, maxScrollIndex));
    const visiblePassions = selectedPassions.slice(actualScrollIndex,
        actualScrollIndex + numberOfVisiblePassions);

    const canScrollLeft = actualScrollIndex > 0
    const canScrollRight = actualScrollIndex < maxScrollIndex;

    function onClickScrollLeft() {
        setScrollIndex(actualScrollIndex - 1);
    }

    function onClickScrollRight() {
        setScrollIndex(actualScrollIndex + 1);
    }

    function removePassion(passionId: number) {
        props.onChangeSelectedPassions(
            props.passionIds.filter(id => id !== passionId));
    }

    return (
        <div className={"PassionMultiSelect_CarouselWrapper"}>
            <div className={`${!canScrollLeft ? "PassionMultiSelect_Disabled" : ""} PassionMultiSelect_ScrollWrapper`}>
                <ChevronLeftOutlined
                    className={"PassionMultiSelect_Scroll"}
                    onClick={onClickScrollLeft}/>
            </div>
            <div className="PassionMultiSelect_Carousel">
                {visiblePassions.map(passion =>
                    <PassionChip
                        key={passion.id}
                        passion={passion}
                        removePassion={removePassion}
                    />
                )}
            </div>
            <div className={`${!canScrollRight ? "PassionMultiSelect_Disabled" : ""} PassionMultiSelect_ScrollWrapper`}>
                <ChevronRightOutlined
                    className={"PassionMultiSelect_Scroll"}
                    onClick={onClickScrollRight}/>
            </div>
        </div>
    )
}

interface PassionMultiSelectProps {
    onChangeSelectedPassions: Function;
    passionIds: number[];
    passions: GetPassionResponseBody[];
}

function PassionMultiSelect(props: PassionMultiSelectProps): JSX.Element {
    const {t} = useTranslation('pages');
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    function openPassionsModal() {
        setModalOpen(true);
    }

    function closePassionsModal() {
        setModalOpen(false)
    }

    return (
        <div className="PassionMultiSelect">
            <div
                className="PassionMultiSelect_Title">{t('findAnEvent.passions')}</div>
            <PassionCarousel passionIds={props.passionIds}
                             passions={props.passions}
                             onChangeSelectedPassions={props.onChangeSelectedPassions}/>
            <Button
                type="button"
                buttonStyle={ButtonStyles.Link}
                clickHandler={openPassionsModal}
                className={"PassionMultiSelect_AddPassionsButton"}
            >{t('findAnEvent.addPlus')}</Button>
            {modalOpen && <SelectPassionsModal open={modalOpen}
                                               onClose={closePassionsModal}
                                               onChangeSelectedPassions={props.onChangeSelectedPassions}
                                               selectedPassionIds={props.passionIds}
                                               allPassions={props.passions}/>}
        </div>
    )
}

export default PassionMultiSelect;
