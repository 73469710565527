import { useState } from "react"
import { IUseSelectLogicReturn, IUser } from "../../../../types/dashboard";

export const useSelectLogic = (rows: IUser[]): IUseSelectLogicReturn => {
    const [selected, setSelected] = useState<string[]>([]);

    const handleSelectAllClickFromCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n: any) => n.email);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelectAllClick = () => setSelected(rows.map(row => row.email))
    const reset = () => setSelected([])

    const handleSelectClick = (email: string) => {
        const selectedIndex = selected.indexOf(email);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, email);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (email: string) => selected.indexOf(email) !== -1;

    const selectedCount = selected.length

    return {
        handleSelectAllClick,
        handleSelectAllClickFromCheckbox,
        handleSelectClick,
        isSelected,
        selectedCount,
        selected,
        reset
    }
}
