import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import { DoubleArrow } from "@material-ui/icons";
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import Loading from "../../../components/ignite/layout/Loading";
import { UserContext } from "../../../contexts/UserContext";

import './MemberLanding.scss';
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { E3_ROUTES, IGNITE_ROUTE_KEY } from "../../../constants/routes";
import useQuery from "../../../hooks/useQuery";
import useClubByShortCode from "../../../hooks/useClubByShortCode";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useCheckDevice from "../../../hooks/useCheckDevice";

export default function MemberLanding() {
    const { t } = useTranslation('ignite/memberLanding');
    const { clubShortCode } = useParams()
    const location = useLocation()
    const [club, isLoading] = useClubByShortCode(clubShortCode)
    const whoWeAreRef = useRef<HTMLDivElement>(null);
    const query = useQuery()
    const userToken = query.get('userToken')

    const { user } = useContext(UserContext);

    const history = useHistory()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onClickMoreInfoButton = () => {
        window.scrollTo({
            top: whoWeAreRef.current!.offsetTop,
            behavior: "smooth"
        })
    }

    const { isDesktop } = useCheckDevice()

    const shouldShowLoginBlock = user === null

    const goToSignup = () => {
        let url
        if (user === null) {
            url = getIgniteRoute(IGNITE_ROUTE_KEY.MEMBER_REGISTRATION, { ':clubShortCode': clubShortCode });
        } else {
            url = getIgniteRoute(IGNITE_ROUTE_KEY.SUCCESSFUL_SIGNUP, { ':clubId': String(club?.id) })
        }
        const urlWithQuery = userToken ? `${url}?userToken=${userToken}` : url

        history.push(urlWithQuery)
    }

    if (isLoading) return <Loading loading={isLoading} />

    return (
            <>
                <div className='member-landing-content'>
                    <div className="section1">
                        <div className="section1__title">
                            {t('section1.title')}
                        </div>
                        <div className="img-wrapper">
                            <img className="section1__img desktop" src={require("../../../assets/get-active-banner-desktop.jpg")} />
                            <img className="section1__img mobile" src={require("../../../assets/get-active-banner-mobile.jpg")} />
                        </div>
                        <div className="section1__subtitle">
                            {t('section1.subtitle')}
                        </div>
                        <div className="button-wrapper">
                            <Button
                                className='sign-up-button'
                                onClick={goToSignup}
                            >
                                {t('section1.signUpButtonText')}
                            </Button>
                            <Button
                                className='tell-more-button'
                                onClick={onClickMoreInfoButton}
                            >
                                {t('section1.tellMoreButtonText')}
                            </Button>
                        </div>
                        {shouldShowLoginBlock && (
                            <div className="login-block">
                                <div className="have-account">
                                    {t('section1.alreadyHaveAccountText')}
                                </div>
                                <Link className="login" to={{ pathname: E3_ROUTES.LOGIN, state: { referrer: location.pathname } }}>
                                    {t('section1.loginText')}
                                </Link>
                            </div>
                        )}
                        <div className="arrow-wrapper">
                            <ArrowDownIcon className='icon' />
                        </div>
                    </div>
                    <div className="section2">
                        <div className="section2__title">
                            {t('section2.title')}
                        </div>

                        {!isDesktop ? (<Carousel
                            autoPlay
                            infiniteLoop
                            showThumbs={false}
                            interval={5000}
                            className="testimony-carousel"
                        >
                            <Testimony number={1} />
                            <Testimony number={2} />
                            <Testimony number={3} />
                        </Carousel>) : (
                            <div className="testimony-wrapper">
                                <Testimony number={1} />
                                <Testimony number={2} />
                                <Testimony number={3} />
                            </div>
                        )}
                    </div>
                    <div className="section3" ref={whoWeAreRef}>
                        <div className="section3__title">
                            {t('section3.title')}
                        </div>
                        <div className="content">
                            <div className="content__text">
                                <div className="content__subtitle">
                                    {t('section3.subtitle')}
                                </div>
                                <div className="content__description" dangerouslySetInnerHTML={{ __html: t('section3.text') }} />
                            </div>
                            <img src={require("../../../assets/get-active-who-we-are-desktop.jpg")} className='content__img desktop' />
                            <img src={require("../../../assets/get-active-who-we-are-mobile.jpg")} className='content__img mobile' />
                        </div>
                    </div>
                    <div className="section4">
                        <div className="section4__title">
                            {t('section4.title')}
                        </div>
                        <div className="steps-wrapper">
                            <HowItWorksStep number={1} />
                            <HowItWorksStep number={2} />
                            <HowItWorksStep number={3} />
                        </div>
                    </div>
                    <div className="section5">
                        <div className="button-wrapper">
                            <Button
                                className='sign-up-button'
                                onClick={goToSignup}
                            >
                                {t('section5.signUpButtonText')}
                            </Button>
                            <Link className="faq" to={E3_ROUTES.ABOUT} target="_blank">
                                {t('section5.readFAQText')}
                                <DoubleArrow className='icon' />
                            </Link>
                        </div>
                    </div>
                </div>
            </>
    );
}

function Testimony({ number }: { number: number }): JSX.Element {
    const { t } = useTranslation('ignite/memberLanding');
    return (
        <div className="testimony-item">
            <img src={require(`../../../assets/get-active-testimony-${number}.jpg`)}
                className="img" />
            <div className="text">
                {t(`section2.testimony${number}`)}
            </div>
        </div>
    )
}

function HowItWorksStep({ number }: { number: number }): JSX.Element {
    const { t } = useTranslation('ignite/memberLanding');
    return (
        <div className="step-item">
            <div className="step-label">
                {t(`section4.step${number}.label`)}
            </div>
            <div className="step-title">
                {t(`section4.step${number}.title`)}
            </div>
            <div className="step-content">
                {t(`section4.step${number}.content`)}
            </div>
        </div>
    )
}
