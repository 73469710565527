import React, {useContext, useEffect, useState} from "react";
import {RegistrationContext, RegistrationState, StepProps} from "../../util/registration-util";
import {UserContext} from "../../contexts/UserContext";
import {RootFormContext, useCreateRootForm} from "../../util/Forms";
import WizardProgress from "./wizardProgress/WizardProgress";
import {MemberRegistrationParameters, registerMember} from "../../services/UserService";
import {sendPageView} from "../../withTracker";
import {useHistory} from "react-router-dom";
import {readUtmRegistrationParametersFromSession} from "../../util/Util";
import {UserRegistrationFlow} from "../../services/Models";

type RegistrationStepComponent = (props: StepProps) => JSX.Element;

interface RegistrationWizardProps {
    className?: string;
    initialState: Record<string, any>;
    gaFlowName: string;
    registrationFlow: UserRegistrationFlow;
    // Because TypeScript numerical enums map backwards and forwards
    steps: Record<number | string, string | number>;
    initialStep: number;
    stepsToComponent: Record<number, RegistrationStepComponent>;
    onContinueSelected?: () => void;
    onPreviousSelected?: () => void;
    register?: () => Promise<{ zipCode: string }>;
    analytics?: {
        sendStepPageView: () => void;
        sendCompletePageView: () => void;
    }
}

export function RegistrationWizard(props: RegistrationWizardProps) {
    const {login} = useContext(UserContext);
    const history = useHistory();

    const {values, context} = useCreateRootForm(props.initialState);

    // current wizard step
    let [wizardStep, setWizardStep] = useState<number>(props.initialStep);

    const registrationState: RegistrationState<number> = {
        wizardStep,
        setWizardStep
    }

    const maxStepNumber = Math.max(...Object.values(props.steps)
        .filter(val => typeof val === "number")
        .map(val => val as number));

    async function postSubmit({ zipCode }: { zipCode: string }) {
        if (props.analytics) {
            props.analytics.sendCompletePageView();
        } else {
            sendPageView(
                `${history.location.pathname}?step=complete`,
                ` - ${props.gaFlowName} Registration Complete`);
        }

        const searchParams = new URLSearchParams();
        searchParams.set("justRegistered", "true");
        searchParams.set("getActive", "true");
        searchParams.set("postalCode", zipCode);
        history.push(`/find-an-event?${searchParams.toString()}`);
    }

    const register = props.register || async function(): Promise<{ zipCode: string }> {
        const parameters: MemberRegistrationParameters = {
            email: values.email,
            phone: values.phone,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            zipCode: values.zipCode,
            renewId: values.insuranceId,
            dateOfBirth: values.dateOfBirth,
            payerId: values.selectedPayer?.id,
            ...readUtmRegistrationParametersFromSession(),
            registrationFlow: props.registrationFlow
        };
        await registerMember(parameters);
        await login(values.email, values.password);
        return {
            zipCode: parameters.zipCode
        }
    }

     async function onRegister() {
        try {
            const result = await register();
            await postSubmit(result);
        } catch (e) {
            // setLoading(false);
            // setRegistrationError("There was an error registering." +
            //     "  If this persists, please contact support.");
            console.error('RegistrationError: ',e)
        }
    }

    useEffect(() => {
        if (props.analytics) {
            props.analytics.sendStepPageView();
        } else {
            sendPageView(
                `${history.location.pathname}?step=${wizardStep.valueOf() + 1}`,
                ` - ${props.gaFlowName} Registration Step ${wizardStep.valueOf() + 1}`);
        }
    }, [wizardStep]);

    async function onContinueSelected() {
        switch (wizardStep) {
            case maxStepNumber:
                // this would be the final "submit" button; handle this a bit differently
                await onRegister();
                break;
            default:
                setWizardStep(wizardStep + 1);
        }
    }

    function onPreviousSelected() {
        switch (wizardStep) {
            // This is just `0` so that it doesn't need to be updated if a new step is added as the first step.
            case 0:
                break;
            default:
                setWizardStep(wizardStep - 1);
        }
    }

    const stepProps: StepProps = { onContinue: onContinueSelected, onPrevious: onPreviousSelected };
    // TypeScript will generate the keys backwards and forwards for numerical enums, so divide the length by 2
    const totalSteps = Object.keys(props.steps).length / 2;

    function renderWizardStep() {
        if (props.stepsToComponent[wizardStep]) {
            // If this is rendered by simply calling the function, then React will
            // error up saying that the order of the hooks changed. So React has to
            // be the one to render it.
            return React.createElement(props.stepsToComponent[wizardStep], stepProps);
        } else {
            return <></>
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        if (!props.stepsToComponent[wizardStep]) {
            setWizardStep(props.initialStep);
        }
    }, [wizardStep]);

    return (
        <RegistrationContext.Provider value={registrationState}>
            <RootFormContext.Provider value={context}>
                <div className={`RegistrationWizard ${props.className || ''}`}>
                    {renderWizardStep()}
                    <WizardProgress currentStep={wizardStep + 1} totalSteps={totalSteps}/>
                </div>
            </RootFormContext.Provider>
        </RegistrationContext.Provider>
    )
}
