import React from "react";
import { useTranslation } from 'react-i18next';

import "./ListSelfGuidedIndicator.scss";
import SelfGuidedEventIndicator from "./SelfGuidedEventIndicator";

export interface ListSelfGuidedIndicatorProps {
    children?: any;
}

export default function ListSelfGuidedIndicator(props: ListSelfGuidedIndicatorProps): JSX.Element {
    const { t } = useTranslation('common');
    return (
        <>
            <div className={"ListSelfGuidedIndicator"}>
                <SelfGuidedEventIndicator className={"SelfGuidedIndicator"}/>
                <div className={"SelfGuidedEvent"}>{t('listSelfGuidedIndicator.selfGuidedLabel')}</div>
            </div>
        </>
    );
}