import {getSanityResponse} from "./SanityUtils";

export interface ClubAssociationLogoSanityResponse {
    ms: number;
    query: string;
    result: ClubAssociationLogoSanityData[];
}

export interface ClubAssociationLogoSanityData {
    logoImage: string;
}

const getClubAssociationLogoViaSanity = async (shortCode: string): Promise<ClubAssociationLogoSanityResponse | undefined> => {
    let query = encodeURIComponent(
        `*[_type == "association" && lower(slug) == "${shortCode}"]
        {
            associationLogo,
            "logoImage": associationLogo.asset->url
        }`);

    return getSanityResponse(query);
}

export default getClubAssociationLogoViaSanity
