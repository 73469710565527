import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./MyClubPage.scss";
import {Link, RouteComponentProps} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";
import {GetClubLeaderClubResponseBody} from "../../services/ClubLeaderService";
import {loadConfig} from "../../services/ConfigService";
import ClubView from "../../components/club/ClubView";
import Loading from "../../components/common/Loading";
import EventList from "../../components/club/EventList";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import {MyClubLeaders} from "./MyClubLeaders";
import ListSeparator from "../../components/common/ListSeparator";
import LoadingMessage from "../../components/common/LoadingMessage";
import {getClubImageUrl} from "../../util/Util";
import {getUsersClubs} from "../../services/UserService";
import useGetMyClubs from "../../hooks/useGetMyClubs";
import SubHeader from "../../components/ignite/SubHeader/SubHeader";
import { useFeatureFlag } from "../../services/FeatureFlagService";
import { USE_EDP_44_VIRTUAL_CLUBS } from "../../constants/flags";
import EventListNoPagination from "../../components/club/EventListNoPagination";
import { LocalDate } from "@js-joda/core";

interface ClubPageParams{
    clubUrlFragment: string;
}

export default function MyClubPage({match, history}: RouteComponentProps<ClubPageParams>): JSX.Element {
    const { t } = useTranslation('pages');
    const useEdp44VirtualClubs : boolean = useFeatureFlag(USE_EDP_44_VIRTUAL_CLUBS, false);
    const {user, authenticatedFetch} = useContext(UserContext);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);
    const [myClub, setMyClub] = useState<GetClubLeaderClubResponseBody | null>(null);
    const [myClubs] = useGetMyClubs()
    const clubUrlFragment = match.params.clubUrlFragment;

    useEffect(
        () => {
            const loadMyClubFromApi = async () => {
                window.scrollTo(0,0);
                setLoadingMessage("");
                setLoading(true);

                if (user === null) {
                    setLoading(false);
                    setLoadingMessage(t('myClubPage.loginRequired'));
                    setLoggedOut(true);
                    return;
                }

                // Default to the first club in the user's list of clubs and rewrite history with the URL fragment representing this club
                if (clubUrlFragment === undefined || clubUrlFragment.length === 0) {
                    const clubs = await getUsersClubs();
                    let firstClub = clubs?.myClubs[0];
                    if (firstClub) {
                        history.replace(`/my-club/${firstClub.urlFragment}`);
                    }
                    else {
                        setMyClub(null);
                        history.replace(`/edit-club`);
                    }
                    return;
                }
                const config = await loadConfig();

                const url = `${config.apiOrigin}/club-leader/my-club/${encodeURIComponent(clubUrlFragment)}?omitEvents=true`;
                const myClubRequest = new Request(url, {
                    method: "GET",
                    headers: {"Accept": "application/json"}
                });

                const myClubResponse = await authenticatedFetch(myClubRequest);

                setLoading(false);

                if (myClubResponse === null) {
                    setLoadingMessage(t('myClubPage.loginRequired'));
                    setLoggedOut(true);
                    return;
                }

                if (!myClubResponse.ok) {
                    const responseBodyText = await myClubResponse.text();
                    console.error(
                        `Non-successful response from API: `
                        + `${myClubResponse.status} ${myClubResponse.statusText} `
                        + `from ${myClubResponse.url}\n\n${responseBodyText}`);
                    setLoadingMessage(t('myClubPage.unexpectedError'));
                    return;
                }

                if (myClubResponse.status === 204) {
                    // The user is authenticated but they have no club.
                    setMyClub(null);
                    history.push('/');
                } else {
                    // The user already has a club.
                    const myClub: GetClubLeaderClubResponseBody = await myClubResponse.json();
                    setMyClub(myClub);
                }
                setLoadingMessage(null);
            };

            loadMyClubFromApi();
        },
        [user, clubUrlFragment, history, t]);

  
    
    return (
        <div className="MyClubPage">
            <SubHeader
                clubName={myClub?.clubName}
                myClubs={myClubs}
                clubImage={myClub?.passion?.imageFile}
                location={myClub?.location}
            />
            {user !== null && (
                <>
                    {loading && <Loading className="PageLoading" loading={loading}/>}
                    {loadingMessage !== null && (
                        <LoadingMessage className={"PageLoadingMessage"} showLoginLink={loggedOut}
                                        message={loadingMessage}/>
                    )}
                    {!loading && loadingMessage === null && (
                        <>
                            {myClub !== null && (
                                <>
                                    <ClubView
                                        clubLeaderView={true}
                                        clubType={myClub.type}
                                        primaryClubLeaderView={myClub.isPrimaryClubLeader}
                                        clubLeaderFirstName={myClub.primaryClubLeaders[0]?.firstName ?? myClub.deputyClubLeaders[0]?.firstName}
                                        clubLeaderLastName={myClub.primaryClubLeaders[0]?.lastName ?? myClub.deputyClubLeaders[0]?.lastName}
                                        clubLeaderEmail={myClub.primaryClubLeaders[0]?.email ?? myClub.deputyClubLeaders[0]?.email}
                                        clubImage={getClubImageUrl(myClub.passion).standard}
                                        clubName={myClub.clubName}
                                        isVirtual={myClub.isVirtual}
                                        clubDescription={myClub.description}
                                        locationCity={myClub.location?.city}
                                        locationState={myClub.location?.countrySubdivision}
                                        locationZip={myClub.location?.postalCode}
                                        passion={myClub.passion?.passionName}
                                        timeZone={myClub.timeZone} clubStatus={myClub.status}
                                        urlFragment={myClub.urlFragment}/>
                                    <div className="MyClubPage_UpcomingEventsTopButtons">
                                        <PlayButton className={"MyClubPage_UploadMultipleEvent"} to={`/import-events/${myClub.urlFragment}`} text={t('myClubPage.uploadMultipleEvents')} buttonStyle={ButtonStyles.FilledPrimary}/>
                                        <PlayButton to={`/add-activity/${myClub.urlFragment}`} text={t('myClubPage.addSingleEvent')} buttonStyle={ButtonStyles.FilledPrimary}/>
                                    </div>
                                    <div className="MyClubPage_UpcomingEventsTitle">{t('myClubPage.upcomingEvents')}
                                    </div>
                                    <ListSeparator className="MyClubPage_UpcomingEventsSeparator"/>
                                    {
                                        useEdp44VirtualClubs 
                                            ?
                                            <EventListNoPagination
                                                className="MyClubPage_Upcoming"
                                                club={myClub}
                                                maxDate={null}
                                                minDate={LocalDate.now()}
                                                contentIfEmpty={
                                                    <div className="MyClubPage_No_UpcomingEvents">
                                                        <div
                                                            className="MyClubPage_No_UpcomingEventsMessage"> {t('myClubPage.noUpcoming')}
                                                        </div>
                                                        <Link to={`/add-activity/${myClub.urlFragment}`}>{t('myClubPage.addEventNow')}</Link>
                                                    </div>
                                                }
                                                past={false}
                                            /> 
                                            : 
                                            <EventList
                                                className="MyClubPage_Upcoming"
                                                clubUrlFragment={myClub.urlFragment}
                                                past={false}
                                                contentIfEmpty={
                                                    <div className="MyClubPage_No_UpcomingEvents">
                                                        <div
                                                            className="MyClubPage_No_UpcomingEventsMessage"> {t('myClubPage.noUpcoming')}
                                                        </div>
                                                        <Link to={`/add-activity/${myClub.urlFragment}`}>{t('myClubPage.addEventNow')}</Link>
                                                    </div>
                                                }
                                            />

                                    }
                                    <div className="MyClubPage_PastEventsTitle">{t('myClubPage.pastEvents')}</div>
                                    <ListSeparator className="MyClubPage_PastEventsSeparator"/>
                                    {
                                        useEdp44VirtualClubs
                                            ?
                                            <EventListNoPagination
                                                className="MyClubPage_Upcoming"
                                                club={myClub}
                                                maxDate={LocalDate.now()}
                                                minDate={null}
                                                contentIfEmpty={
                                                    <div className="MyClubPage_No_PastEvents">
                                                        {t('myClubPage.noPastEvents')}
                                                    </div>
                                                }
                                                past={true}
                                            />
                                            :
                                            <EventList
                                                className="MyClubPage_Past"
                                                clubUrlFragment={myClub.urlFragment}
                                                past={true}
                                                contentIfEmpty={
                                                    <div className="MyClubPage_No_PastEvents">
                                                        {t('myClubPage.noPastEvents')}
                                                    </div>
                                                }
                                            />

                                    }
                                    
                                    <MyClubLeaders
                                        myClub={myClub}
                                        // TODO: Re-fetch My Club from the API when it's mutated
                                        //       instead of trying to replicate the mutations locally?
                                        onAdded={(email) => {
                                            if (myClub === null) return;
                                            const openDeputyInvitations = myClub.openDeputyInvitations.concat({ toEmailAddress: email });
                                            setMyClub({ ...myClub, openDeputyInvitations });
                                        }}
                                        onRemoved={(email) => {
                                            if (myClub === null) return;
                                            const deputyClubLeaders = myClub.deputyClubLeaders.filter(clubLeader => clubLeader.email !== email);
                                            setMyClub({ ...myClub, deputyClubLeaders });
                                        }}
                                        onCancelled={(email) => {
                                            if (myClub === null) return;
                                            const openDeputyInvitations = myClub.openDeputyInvitations.filter(deputyInvitation => deputyInvitation.toEmailAddress !== email);
                                            setMyClub({ ...myClub, openDeputyInvitations });
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}
