import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./EventImportWizard.scss";
import {UserState} from "../../contexts/UserContext";
import EventImportReview from "./EventImportReview";
import EventImportUpload from "./EventImportUpload";
import EventImportConfirm from "./EventImportConfirm";
import { sendPageView } from "../../withTracker";
import { useHistory } from "react-router-dom";

export interface IImportResult {
    fields: IField[];
    wasSuccessful: boolean;
    status: string;
    events: IImportedEvent[],
    attachmentsZipFileS3Key: string;
}

export interface IImportedEvent {
    id: string;
    eventName: string;
    clubName: string;
    venueName: string;
    eventStreetAddress: string;
    eventStreetAddress2: string;
    eventCity: string;
    eventState: string;
    eventZIP: string;
    venueCounty: string;
    eventStartDate: string;
    eventEndDate: string;
    eventStartTime: string;
    eventEndTime: string;
    classDescription: string;
    eventType1: string;
    eventType2: string;
    importantDetails: string;
    eventLeaderFirstName: string;
    eventLeaderLastName: string;
    eventLeaderEmail: string;
    eventLeaderPhone: string;
    status: string;
    isPaid: string;
    isVirtual: string;
    externalId: string;
    wasSuccessful: boolean;
    validationResults: IValidation[];
    attachment1FileName: string;
    attachment1Title: string;
    attachment2FileName: string;
    attachment2Title: string;
    attachment3FileName: string;
    attachment3Title: string;
    attachment4FileName: string;
    attachment4Title: string;
    attachment5FileName: string;
    attachment5Title: string;
    attachment6FileName: string;
    attachment6Title: string;
    attachment7FileName: string;
    attachment7Title: string;
    attachment8FileName: string;
    attachment8Title: string;
    virtualMedium: string;
    virtualUrl: string;
    virtualUrlPasscode: string;
    virtualDialInNumber: string;
    virtualDialInPasscode: string;

    // needed for dynamic property access
    [key: string]: any;
}

export interface IField {
    columnName: string;
    propertyName: string
}

export interface IValidation {
    message: string;
    success: boolean;
    value: string;
    displayValue: string;
    displayName: string;
}

export default function EventImportWizard(props: { importParams: string, UserState: UserState }): JSX.Element {
    const { t } = useTranslation('eventImportWizard');
    let userState: UserState = props.UserState;
    let clubUrlFragment = props.importParams;

    const [importResult, setImportResult] = useState<IImportResult | null>(null);
    const history = useHistory();
    const [step, setStep] = useState<number>(0);

    useEffect(() => {
        sendPageView(
            `${history.location.pathname}?step=${step + 1}`,
            ` - Event Import Step ${step + 1}`);
    }, [step]);

    return (
        <>
            <div className="EventUploadFormMobile">
                {t('eventImportWizard.mobileNotSupported')}
            </div>
            <div className="EventUploadForm">
                {step === 0 &&
                <div id="stepOneUpload">
                    <EventImportUpload clubUrlFragment={clubUrlFragment}
                                       UserState={userState}
                                       ImportResultSetter={setImportResult}
                                       StepSetter={setStep}/>
                </div>
                }
                {step === 1 &&
                <div id="stepTwoReview">
                    {importResult != null &&
                    <EventImportReview clubUrlFragment={clubUrlFragment}
                                       UserState={userState}
                                       ImportResultSetter={setImportResult}
                                       ImportResult={importResult} StepSetter={setStep}/>}
                </div>
                }
                {step === 2 &&
                <div id="stepThreeConfirm">
                    {importResult != null &&
                    <EventImportConfirm clubUrlFragment={clubUrlFragment}
                                        ImportResult={importResult} UserState={userState}
                                        StepSetter={setStep}/>}
                </div>
                }
                <div className="EventImportWizard_Spacer">&nbsp;</div>
            </div>
        </>
    );
}

