import React from "react";
import { useTranslation } from 'react-i18next';
import './GeneralInfoPanel.scss'

interface IGeneralInfoPanelProps {
    activityCount?: number,
    bonuses?: number,
    invited?: number,
    eligible?: number,
    signedUp?: number,
}

const GeneralInfoPanel = ({
    activityCount,
    bonuses,
    invited,
    eligible,
    signedUp,
}: IGeneralInfoPanelProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');

    return (
        <div className="leaderboard-general-info">
            <div className="top-content">
                <div>
                    <div className="amount">
                        {activityCount}
                    </div>
                    <div className="description">
                        {t('activities')}
                    </div>
                </div>

                <div>
                    <div className="amount">
                        {bonuses}
                    </div>
                    <div className="description">
                        {t('bonuses')}
                    </div>
                </div>
            </div>
            <div className="bottom-content">
                <div>
                    <div className="amount">
                        {invited}
                    </div>
                    <div className="description">
                        {t('invited')}
                    </div>
                </div>
                <div>
                    <div className="amount">
                        {eligible}
                    </div>
                    <div className="description">
                        {t('eligible')}
                    </div>
                </div>
                <div>
                    <div className="amount">
                        {signedUp}
                    </div>
                    <div className="description">
                        {t('signedUp')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneralInfoPanel
