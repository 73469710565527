import { CLUB_ROLES } from '../constants/dashboard';
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const UPDATE_USER_ROLE_URL = '/ignite/leader/update-user-role'

const postUpdateUserRole = async (body: { UserId: number, ClubId: number, ConfirmChange: boolean, UserClubRelationshipTypeId: CLUB_ROLES }) => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_USER_ROLE_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }
    const error = await response?.text()

    throw new Error(error)
}

export default postUpdateUserRole
