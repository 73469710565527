import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const UPDATE_PREFERRED_CONTACT_URL = '/ignite/update-preferred-contact-method'

const postUpdatePreferredContact = async (body: any): Promise<{ userId: number } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_PREFERRED_CONTACT_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postUpdatePreferredContact
