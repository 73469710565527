import React from 'react';
import { Close } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import { Trans, useTranslation } from 'react-i18next';
import "./ClubJoinedModal.scss";
import Button, { ButtonStyles } from '../../../components/common/Button';
import { GetContactPhoneNumber, PhoneNumberOverride } from '../../../components/common/ContactPhoneNumber';

interface Props {
    open: boolean;
    onClose: () => void;
}
export default function ClubJoinedModal(props: Props) {
    const { t } = useTranslation('pages');
    const PHONE_NUMBER = GetContactPhoneNumber(PhoneNumberOverride.ANONYMOUS).display;
    return (
        <Dialog open={props.open} fullWidth={true} maxWidth="md" className="ClubJoinedModal">

            <DialogActions className="actions">
                <IconButton onClick={() => props.onClose()}>
                    <Close />
                </IconButton>
            </DialogActions>

            <DialogContent className="content">
                <h2 className="title">
                    {t('clubJoinedModal.titleNotReady')}
                </h2>
                <p>
                    <Trans
                        t={t}
                        values={{ phoneNumber: PHONE_NUMBER, email: 'info@hellogrouper.com' }}
                        i18nKey="clubJoinedModal.notReady"
                        components={{
                        callLink: <a href={`tel:${PHONE_NUMBER}`} />,
                        }}
                    />
                </p>
            </DialogContent>

            <DialogActions className="actions">
                <Button buttonStyle={ButtonStyles.FilledPrimary}
                    clickHandler={() => props.onClose()}>
                    {t('clubJoinedModal.okButton')}
                </Button>
            </DialogActions>

        </Dialog>
    );
}