import React from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'

import Button, { ButtonStyles } from "../common/Button";
import { Card, CardContent, CardMedia, Typography } from '@material-ui/core';

import "./RelatedClubCard.scss";
import { IClubWithDesc } from "../../types/clubFinder";
import { getClubPageRouteLink } from "../../util/ignite/routes.utils";

export interface RelatedClubCardProps {
    className?: string
    clubData: IClubWithDesc
    instructorName: string
    closeModal: Function
}

/**
 * RelatedClubCard
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=296-3917&mode=design&t=Gs9IdcDyGMIOnhOI-0
 * current uses: see src/components/virtualClubs/InstructorBioModal
 * 
 * Modal displaying info for a given club instructor/leader
 */

export default function RelatedClubCard(props: RelatedClubCardProps): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation('pages');

    let classes = 'RelatedClubCard';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let clubGallery: Array<string> = (!!props.clubData.sanityData?.memberGalleryEnabled && props.clubData.sanityData?.memberGalleryImages?.length > 0) ? props.clubData.sanityData.memberGalleryImages.slice(0, 2) : [];
    let clubName = props.clubData.clubName;
    let clubImage = props.clubData.sanityData?.associationImage ?? '';
    let clubInstructor = props.instructorName;
    let clubURL = getClubPageRouteLink(true, props.clubData.urlFragment);

    let trimmedClubDescription: string = (props.clubData.description).substr(0, 70);
    trimmedClubDescription = trimmedClubDescription.substr(0, Math.min(trimmedClubDescription.length, trimmedClubDescription.lastIndexOf(" ")));
    if (trimmedClubDescription.length > 0) { trimmedClubDescription += "... "; } else { trimmedClubDescription += " "; }

    function navigateToClub() {
        history.push(clubURL);
        props.closeModal();
    }

    const hasClubImage = () => {
        return !!clubImage && clubImage.length > 0;
    };

    return (
        <div className="Card_Container">
            <Card className={classes}>
                {hasClubImage ?
                    <CardMedia
                        component="img"
                        alt={clubName}
                        height="190"
                        image={clubImage}
                        title={clubName}
                        className="ClubCard_Img"
                    /> :
                    <div className="ClubCard_Img no-img MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img" />
                }

                {clubGallery.length > 0 &&
                    <div className="ClubCard_Gallery_Container equal-columns has-3">
                        {clubGallery.map((galleryItem: any, galleryIndex: number) => (
                            <div key={galleryIndex}
                                className="ClubCard_Gallery_Image"
                                style={{ backgroundImage: 'url("' + galleryItem + '")' }}
                            />
                        ))}

                        <div className="ClubCard_Gallery_Image">
                            <a href={clubURL + "?section=gallery"}>
                                {t('virtualClubsClubDetailPage.relatedClubCard.viewGalleryLink')}
                            </a>
                        </div>
                    </div>
                }

                <CardContent className="ClubCard_CardContent">
                    <h3>
                        {clubName}
                    </h3>
                    <div className="flex-columns ">
                        <Typography variant="body1" component="p" className="RelatedClubCard_Instructor">
                            {t('virtualClubsClubDetailPage.relatedClubCard.withText')} {clubInstructor}
                        </Typography>

                        <Button buttonStyle={ButtonStyles.FilledPrimary}
                            clickHandler={navigateToClub}
                            className="column-end">
                            {t('virtualClubsClubDetailPage.relatedClubCard.joinText')}
                        </Button>
                    </div>
                    <Typography variant="body1" component="p" className="RelatedClubCard_Description">
                        {trimmedClubDescription}<a href={clubURL}>{t('virtualClubsClubDetailPage.relatedClubCard.fullDetailsLink')}</a>
                    </Typography>
                </CardContent>
            </Card >
        </div >
    )
};