import React, {useContext, useEffect} from "react";
import {RouteComponentProps, Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./ClubMemberLandingPage.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import {UserContext} from "../../contexts/UserContext";
import { storeRegistrationParametersToSession } from "../../util/Util";
import ClubMemberLandingPageComponent from "./ClubMemberLandingPageComponent";
/**
 * A page with static content that contains a link to register as a member.
 */
export default function ClubMemberLandingPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {history} = props;
    const {user} = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    
    return (
        <ClubMemberLandingPageComponent />        
    );
   
}
