export const memberDashboardConfig = {
    MAX_ACTIVITY_PER_MONTH: 200,
    MIN_ACTIVITY_PER_MONTH: 0,

    MONTHS_AMOUNT_FOR_DESKTOP: 6,
    MONTHS_AMOUNT_FOR_MOBILE_IN_MY_ACTIVITY: 3,
    MONTHS_AMOUNT_FOR_MOBILE_IN_MEMBERS_ACTIVITIES: 4,
    MONTHS_AMOUNT_FOR_MOBILE_FOR_LEADER: 3,
    MONTHS_AMOUNT_FOR_DESKTOP_EXPANDED: 12,
    MONTHS_AMOUNT_FOR_MOBILE_EXPANDED: 8,

    MAX_MONTHS_AMOUNT_FOR_ACTIVE_SECTION: 6,
    YEARS_AMOUNT_FOR_SUBMITTED_SECTION: 10,

    ITEMS_PER_PAGE_IN_LEADERBOARD: 10,

    DEFAULT_MAX_REPORTING_PERIOD: 6,

    TOOLTIP_HIDE_TIME: 3000,
    BUTTON_DELAY_TIME: 2000,
    SAVED_TEXT_HIDE_TIME: 5000,

    MEMBERS_AMOUNT_PER_PAGE: 20,

    NOTIFICATION_HIDE_TIME: 3000,

    SEARCH_MEMBERS_INPUT_DEBOUNCE_DELAY: 1000
}
