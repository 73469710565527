import React from "react";
import { useTranslation } from 'react-i18next';
import { Button } from "@material-ui/core";
import Badge from "../Dashboard/Badge";
import { BADGE_KEYS } from "../../../constants/dashboard";

const TrophyBadge = ({ onClick }:
    { onClick: (e: React.MouseEvent) => void }
): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');

    return (
        <div className="badge first">
            <div className="title" dangerouslySetInnerHTML={{__html: t('trackingCompletedBadgeTitle')}} />
            <Badge slug={BADGE_KEYS.TRACKING_COMPLETED} />
            <div className="subtitle" dangerouslySetInnerHTML={{__html: t('trackingCompletedBadgeBody')}} />
            <Button
                className="button"
                classes={{ label: 'button__label' }}
                onClick={onClick}
            >
                {t('dashboard')}
            </Button>
        </div>
    )
}

export default TrophyBadge
