export enum ContactMethod {
    EMAIL,
    PHONE,
    BOTH
}

export enum LocationState {
    Online = 'Online',
    Offline = 'Offline',
    Both = 'Both'
}
