import React, {useState} from "react";

interface ImageWithFallbackProps {
    className?: string,
    src: string,
    alt: string,
    fallbackSrc: string;
}

export default function ImageWithFallback(props: ImageWithFallbackProps): JSX.Element {
    let alt = props.alt;
    let fallbackSrc = props.fallbackSrc;

    let [triedToLoadFallback, setTriedToLoadFallback] = useState(false);
    let [src, setSrc] = useState(props.src);

    let className = "ImageWithFallback";
    if (props.className !== undefined) {
        className += " " + props.className;
    }

    function loadFallback(e: React.SyntheticEvent<HTMLImageElement>) {

        if (e.target instanceof HTMLImageElement) {

            if (triedToLoadFallback) {
                return;
            }

            setTriedToLoadFallback(true);

            setSrc(fallbackSrc);
        }
    }

    return (<img className={className}
                 alt={alt}
                 src={src}
                 onError={(e) => {
                     loadFallback(e);
                 }}/>);
}
