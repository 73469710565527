import React, { useRef, useState } from 'react';
import {
  IClubV2,
  IClubV2SearchParams,
  IClubV2SortByColumns,
} from '../../../../../types/clubFinder';
import { Avatar } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import './GroupWorks.scss';
import ClubCard from '../common/ClubCard/ClubCard';
import useGetClubsV2 from '../../../../../hooks/useGetClubsV2';
import { SortOrder } from '../../../../../types/sortOrder';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import ClubCardSkeleton from '../common/ClubCardSkeleton/ClubCardSkeleton';
import Search from '../common/Search/Search';
import { IconButton } from '@material-ui/core';
import CrossBold from '../../../../../components/icon/CrossBold';

export interface GroupWorksProps {
  onGroupSelected: (selectedClub: IClubV2 | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      backgroundColor: '#F4F6FA',
    },
    title: {
      flex: 1,
      color: '#000',
    },
  }),
);

const PAGE_SIZE = 10;
const GroupWorks = (props: GroupWorksProps) => {
  const classes = useStyles();
  const { t } = useTranslation('club');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchParams, setSearchParams] = useState<IClubV2SearchParams>({
    type: null,
    sortBy: IClubV2SortByColumns.NAME,
    sortOrder: SortOrder.ASC,
    terms: undefined,
    offset: 0,
    isVirtual: false,
    limit: PAGE_SIZE,
    'location.postalCode': undefined,
    'location.radiusInMiles': undefined,
    'location.onlyMatchedLocations': false,
    isGroupworksSearch: true,
    searchTerm: '',
  });
  const [clubSearchResponse, clubSearchErrorText, isLoadingClubs] =
    useGetClubsV2(searchParams);
  const isClubsAvailable = clubSearchResponse && clubSearchResponse?.total > 0;
  const divRef = useRef<HTMLDivElement | null>(null);
  const [clubSelected, setClubSelected] = useState<IClubV2 | undefined>(
    undefined,
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const onPaginationChange = (event: any, page: number) => {
    let newSearchParams = JSON.parse(JSON.stringify(searchParams));
    newSearchParams.offset = (page - 1) * PAGE_SIZE;
    setSearchParams(newSearchParams);
    setCurrentPage(page);
    divRef!.current!.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleClubSelected = (club: IClubV2) => {
    if (club.id === clubSelected?.id) {
      setClubSelected(undefined);
      props.onGroupSelected(undefined);
    } else {
      setClubSelected(club);
      props.onGroupSelected(club);
    }
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div
        style={{ fontWeight: 400, marginBottom: 16 }}
        dangerouslySetInnerHTML={{ __html: t('groupworks.usageInfo') }}
      />
      {clubSelected && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 16,
            border: '1px #555555 solid',
            padding: 16,
          }}
        >
          <div style={{ fontWeight: 700 }}>{t('groupworks.myClub')}</div>
          <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
            <div style={{ flexGrow: 1, textAlign: 'right', marginRight: 16 }}>
              {clubSelected?.passion && (
                <div style={{ fontWeight: 500, fontSize: 10 }}>
                  {clubSelected.passion}
                </div>
              )}
              <div style={{ fontWeight: 700, fontSize: 16 }}>
                {clubSelected.name}
              </div>
            </div>
            <Avatar
              alt={clubSelected.name}
              src={
                '/images/passions-thumbnail-wide/' +
                (clubSelected.clubImage ?? 'other.jpg')
              }
            />
            <div style={{ marginLeft: 8 }}>
              <IconButton
                size="small"
                onClick={() => {
                  props.onGroupSelected(undefined);
                  setClubSelected(undefined);
                }}
              >
                <CrossBold />
              </IconButton>
            </div>
          </div>
        </div>
      )}
      <Search
        clubs={clubSearchResponse?.items ?? []}
        onChange={setSearchValue}
        onOptionSelected={handleClubSelected}
        searchValue={searchValue}
        handleSearch={() => {
          setSearchParams({
            ...searchParams,
            offset: 0,
            searchTerm: searchValue?.toLowerCase() ?? '',
          });
          setCurrentPage(1);
        }}
      />
      <div></div>
      <div style={{ overflowY: 'scroll', height: 300, gap: 24 }} ref={divRef}>
        {!isLoadingClubs
          ? clubSearchResponse?.items?.map((club) => (
              <div key={club.id} style={{ marginBottom: 16 }}>
                <ClubCard club={club} onClubSelect={handleClubSelected} />
              </div>
            ))
          : [...Array(PAGE_SIZE)].map((numberKey) => (
              <ClubCardSkeleton
                key={numberKey}
                style={{ margin: '16px 0px' }}
              />
            ))}
      </div>
      {isClubsAvailable && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Pagination
            count={
              clubSearchResponse
                ? Math.ceil(clubSearchResponse.total / clubSearchResponse.limit)
                : 0
            }
            page={currentPage}
            onChange={onPaginationChange}
            color="primary"
            siblingCount={0}
            disabled={isLoadingClubs}
          />
        </div>
      )}
    </div>
  );
};

export default GroupWorks;
