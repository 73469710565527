import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./MyEvents.scss";
import {
    EventSearchResponseBody,
    EventSearchSort
} from "../../pages/findAnEvent/FindAnEventPage";
import EventSearchResults from "./EventSearchResults";
import { EventView } from "../findAnEvent/FindAnEventForm";
import { UserContext } from "../../contexts/UserContext";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";
import {
    endOfCurrentOrSoonMonthInstant,
    EventSearchResult,
    startOfCurrentOrSoonMonthInstant
} from "../../services/EventSearchService";
import CurrentlyNoEvents from "./CurrentlyNoEvents";
import { DateTimeParseException, Instant, LocalDate, LocalTime, ZoneId, ZonedDateTime } from "@js-joda/core";
import { getEventsForClubAndMemberInDateRange } from "../../api/getEventsForClubAndMemberInDateRange";
import Events from "../../pages/events/Events";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocalDateUtils } from "../../util/FixedJsJodaUtils";
import { Locale } from "@js-joda/locale_en-us";

export interface MyEvents {
    className?: string;
    view?: EventView;
    setView: (view: EventView) => void;
    favoritePassionsSelected: boolean;
    hasRegisteredEvents: boolean;
}

export default function MyEventsNoPagination(props: MyEvents): JSX.Element {
    const { t } = useTranslation('event');
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [results, setResults] = useState<EventSearchResponseBody | null>(null);
    const {hasRegisteredEvents} = props;
    const [month, setMonth] = useState<number>(new Date().getMonth());
    const {authenticatedFetch} = useContext(UserContext);

    const [searchStartDate, setSearchStartDate] = useState<LocalDate | DateTimeParseException | null>(LocalDate.now());
    const [searchEndDate, setSearchEndDate] = useState<LocalDate | DateTimeParseException | null>(LocalDate.now().plusMonths(1));

    function scrollToEvent(eventUrlFragment: string, startOfDate: string) {
        if (!results) {
            return;
        }
        const view = EventView.LIST;
        const event = results.results
            .find(event => event.eventUrlFragment === eventUrlFragment);
        if (!event) {
            return;
        }
        const newSkip = event.rowIndex;
        props.setView(view);
    }

    function isSearchStartDateError() {
        return !searchStartDate || !(searchStartDate instanceof LocalDate) || searchStartDate < LocalDate.now();
    }

    function searchStartDateErrorText() {
        if (!searchStartDate) {
            return t("myEvents.requiredLabel");
        }
        else if (searchStartDate.toString() < LocalDate.now().toString()) {
            return t("myEvents.minDateError", {minDate: LocalDate.now().toString()});
        }
        if (searchStartDate instanceof DateTimeParseException) {
            return "MM/DD/YYYY";
        }
        return "";
    }

    useEffect(() => {
            if (props.view === EventView.MONTH || (!isSearchStartDateError() && !(searchEndDate instanceof DateTimeParseException))) {
                (async () => {
                    window.scrollTo(0, 0);
    
                    let start: string | null = null;
                    let end: string | null = null;
    
                    if (props.view === EventView.MONTH) {
                        start = startOfCurrentOrSoonMonthInstant(month).toString();
                        end = endOfCurrentOrSoonMonthInstant(month).toString();
                    } else if (props.view === EventView.LIST) {
                        if (searchStartDate instanceof LocalDate) {
                            start = ZonedDateTime.of(searchStartDate, LocalTime.MIN ,ZoneId.SYSTEM).toInstant().toString();
                        }
                        if (searchEndDate instanceof LocalDate) {
                            end = ZonedDateTime.of(searchEndDate, LocalTime.MAX ,ZoneId.SYSTEM).toInstant().toString();
                        }
                    }
    
                    const searchResults = await getEventsForClubAndMemberInDateRange(null, start, end);
                    
                    searchResults.sort((a: any, b: any) => {
                        return a.eventAndClub.event.startsAtInstant.toString().localeCompare(b.eventAndClub.event.startsAtInstant.toString())
                    });
    
                    setResults({
                        results: searchResults.map((result: { eventAndClub: { club: any; event: any; }; status: any; }) => {
                            const {club, event} = result.eventAndClub;
                            return {
                                eventIsVirtual: event.isVirtual,
                                clubIsVirtual: club.isVirtual,
                                eventEndsAt: event.endsAtInstant,
                                passion: club.passion,
                                clubName: club.clubName,
                                eventIsPaid: event.isPaid,
                                eventName: event.eventName,
                                eventStartsAt: event.startsAtInstant,
                                eventTimeZone: event.timeZone,
                                clubUrlFragment: club.urlFragment,
                                distanceInMiles: -1,
                                eventTypeOne: event.typeOne,
                                eventTypeTwo: event.typeTwo,
                                eventUrlFragment: event.urlFragment,
                                location: event.location,
                                locationId: null, // Only needed server-side (hopefully)
                                eventIsRecurring: event.isRecurring,
                                recurrence: event.recurrence,
                                rowIndex: -1,
                                occurrenceId: event.occurrenceId,
                                passionId: club.passion?.id,
                                userEventRelationshipStatus: result.status
                            } as EventSearchResult
                        }),
                        limit: searchResults.length,
                        sort: EventSearchSort.EventStartsAt,
                        skip: 0,
                        anyEventIsVirtual: false,
                        reverse: false,
                        totalResultCount: searchResults.length,
                        postalCode: ""
                    });
    
                    setLoading(false);
                })();
            }
            
        },
        [month, props.view, searchStartDate, searchEndDate, t]);

    return (
        <>
            {loading && <Loading className="PageLoading" loading={loading}/>}
            {loadingMessage !== null && (
                <LoadingMessage className={"PageLoadingMessage"}
                                message={loadingMessage}/>
            )}
            {
                props.view === EventView.LIST && 
                <>
                    <div className="EventList_dateRange">
                        <div>
                            <MuiPickersUtilsProvider
                                utils={LocalDateUtils}
                                locale={Locale.US}>
                                <KeyboardDatePicker
                                    value={searchStartDate}
                                    label={t("myEvents.fromLabel")}
                                    minDate={new Date()}
                                    error={isSearchStartDateError()}
                                    helperText={searchStartDateErrorText()}
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    required
                                    onChange={(x) => { setSearchStartDate(x) }} 
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div>
                            <MuiPickersUtilsProvider
                                utils={LocalDateUtils}
                                locale={Locale.US}>
                                <KeyboardDatePicker
                                    value={searchEndDate}
                                    label={t("myEvents.toLabel")}
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    onChange={(x) => { setSearchEndDate(x) }} />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </>
            }
            {!loading && <div className={`MyEvents ${props.className || ''}`}>
                {/* maxEventsPerDay set to an arbitrarily high number to be mostly unused. */}
                {hasRegisteredEvents && <EventSearchResults
                                    resultsLoading={false}
                                    searchResponseBody={results}
                                    sortByEventStartsAt={() => {}}
                                    sortByDistance={() => {}}
                                    onSortSelected={() => {}}
                                    view={props.view ?? EventView.LIST}
                                    usingFilters={false}
                                    resetFilters={() => {}}
                                    viewVirtualOnly={() => {}}
                                    month={month}
                                    setMonth={setMonth}
                                    maxEventsPerDay={100}
                                    scrollToEvent={scrollToEvent}/>}
                {!hasRegisteredEvents &&
                    <CurrentlyNoEvents
                        favoritePassionsSelected={props.favoritePassionsSelected}/>}
            </div>}
        </>
    );
}
