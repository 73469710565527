import { loadConfig } from '../services/ConfigService'
import { getJson } from '../services/RequestService'
import { IClubId } from '../types/club'

export const URL = '/clubs/id/'

const getClubIdByUrlFragment = async (clubUrlFragment: string): Promise<number> => {
    const config = await loadConfig();

    const json : IClubId = await getJson({
        url: `${config.apiOrigin}${URL}${clubUrlFragment}`
    });

    return json?.clubId ?? 0;
}

export default getClubIdByUrlFragment
 