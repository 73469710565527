import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./ClubView.scss";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";
import {UserContext} from "../../contexts/UserContext";
import ClubView from "./ClubView";
import {getClubImageUrl} from "../../util/Util";
import {GetClubResponseBody} from "../../services/MemberService";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/UserService";

interface ClubViewProps {
    clubUrlFragment: string,
    className?: string,
}

export default function ClubViewAsync(props: ClubViewProps): JSX.Element {
    const { t } = useTranslation('club');
    let classes = 'CCClubViewAsync';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    const {user} = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [theClub, setTheClub] = useState<GetClubResponseBody|null>(null);

    let clubUrlFragment = props.clubUrlFragment;

    useEffect( () => {
        const load = async () => {

            const config = await loadConfig();

            const request =
                new Request(
                    `${config.apiOrigin}/clubs/get/${clubUrlFragment}`,
                    {method: "GET", headers: {"Accept": "application/json"}});

            const response = await authenticatedFetch(request);

            if (response === null) {
                setLoadingMessage(t('clubViewAsync.loginRequired'));
                return;
            }

            if (!response.ok) {
                setLoadingMessage(t('clubViewAsync.error'));
                return;
            }

            let club:GetClubResponseBody = await response.json();

            setTheClub(club);
            setLoadingMessage(null);
        };

        setLoading(true);
        load();
        setLoading(false);
    }, [clubUrlFragment, t]);

    return (
        <>
            {user !== null &&
            <>
                {loading && <Loading loading={loading}/>}
                {!loading && loadingMessage !== null && <LoadingMessage message={loadingMessage}/>}
                {!loading && loadingMessage === null && theClub !== null &&
                <div className={classes}>
                    <ClubView
                        clubLeaderView={false}
                        clubType={theClub.type}
                        primaryClubLeaderView={false}
                        clubLeaderFirstName={theClub.primaryClubLeaders[0]?.firstName ?? theClub.deputyClubLeaders[0]?.firstName}
                        clubLeaderLastName={theClub.primaryClubLeaders[0]?.lastName ?? theClub.deputyClubLeaders[0]?.lastName}
                        clubLeaderEmail={theClub.primaryClubLeaders[0]?.email ?? theClub.deputyClubLeaders[0]?.email}
                        clubImage={getClubImageUrl(theClub.passion).standard}
                        clubName={theClub.clubName}
                        clubDescription={theClub.description}
                        locationCity={theClub.location?.city}
                        locationState={theClub.location?.countrySubdivision}
                        locationZip={theClub.location?.postalCode}
                        passion={theClub.passion?.passionName}
                        timeZone={theClub.timeZone}
                        clubStatus={theClub.status}
                            urlFragment={clubUrlFragment}/>
                </div>
                }
            </>
            }
        </>
    );
}
