import i18next from "i18next";
import {getSanityResponse} from "./SanityUtils";

export interface EnrollmentFlowLandingPageSanityResponse {
    ms: number;
    query: string;
    result: EnrollmentFlowLandingPageSanityData[];
}

export interface DirectToConsumerSection {
    image: string;
    title: string;
    description1: string;
    description2: string;
    note: string;
    button: string;
}
export interface EnrollmentFlowLandingPageSanityData {
    flow: string;
    title: string;
    subtitle: string;
    description: string;
    helpText: string;
    buttonText: string;
    landingImageUrl: string;
    headerImageUrls: [string];
    section1: DirectToConsumerSection;
    incentive: DirectToConsumerSection;
    promotionImagesUrls: [string];
}

const getEnrollmentFlowLandingPageViaSanity = async (flow: string): Promise<EnrollmentFlowLandingPageSanityResponse | undefined> => {
    const LANGUAGE = i18next.language?.split('-')[0] ?? 'en';
    let query = encodeURIComponent(`
        *[_type == "enrollment_flow_landing_page" && slug == "${flow}"]
        {
            slug,
            "title": landingPageTitle.${LANGUAGE},
            "subtitle": subtitle.${LANGUAGE},
            "buttonText": buttonText.${LANGUAGE},
            "headerImageUrls": headerImages[].asset->url,
            "promotionImagesUrls": promotionImages[].asset->url,
            "section1":
                {
                    "title": section1->sectionTitle.${LANGUAGE},
                    "description1": section1->description1.${LANGUAGE},
                    "description2": section1->description2.${LANGUAGE},
                    "image": section1->image.asset->url,
                    "note": section1->note.${LANGUAGE},
                    "button": section1->button.${LANGUAGE},
                },
            "incentive":
                {
                    "title": incentive->sectionTitle.${LANGUAGE},
                    "description1": incentive->description1.${LANGUAGE},
                    "description2": incentive->description2.${LANGUAGE},
                    "image": incentive->image.asset->url,
                    "note": incentive->note.${LANGUAGE},
                    "button": incentive->button.${LANGUAGE},
                },
        }
    `);

    return getSanityResponse(query);
}

export default getEnrollmentFlowLandingPageViaSanity;
