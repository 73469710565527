import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import "./CustomEnrollmentComplete.scss";
import { UserContext } from "../../contexts/UserContext";
import Button, { ButtonStyles } from "../../components/common/Button";
import ArrowButton from "../../components/icon/ArrowButton";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import getClubAssociationContentViaSanity, {
    ClubAssociationEnrollmentConfigurationSanityData
} from "../../sanity/getClubAssociationContentViaSanity";
import Loading from "../../components/ignite/layout/Loading";
import NotFoundPage from "../notFound/NotFoundPage";
import PortableText from "react-portable-text";

interface CustomEnrollmentCompleteProps {
}
export default function CustomEnrollmentComplete(props: CustomEnrollmentCompleteProps): JSX.Element {
    const {t} = useTranslation('pages');
    const history = useHistory();
    const { customEnrollmentShortCode } = useRouteMatch<{customEnrollmentShortCode: string}>().params;
    const { user, logout } = useContext(UserContext);

    const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);
    const [clubAssociationEnrollmentConfiguration, setClubAssociationEnrollmentConfiguration] = useState<ClubAssociationEnrollmentConfigurationSanityData | undefined>(undefined);
    const [targetUrl, setTargetUrl] = useState<string>("/");

    useEffect(() => {
        getClubAssociationContentViaSanity(customEnrollmentShortCode).then(data => {
            setClubAssociationEnrollmentConfiguration(data?.result[0].associationEnrollmentConfiguration[0]);
            setQueryResultsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (clubAssociationEnrollmentConfiguration && clubAssociationEnrollmentConfiguration.confirmationPageExternalLinkEnabled && clubAssociationEnrollmentConfiguration.confirmationPageExternalLinkUrl.length > 0) {
            setTargetUrl(clubAssociationEnrollmentConfiguration!.confirmationPageExternalLinkUrl);
        }
    }, [clubAssociationEnrollmentConfiguration]);

    useEffect(() => {
        (async () => {
            if (user && !user.isPasswordSet) {
                await logout();
            }
        })();
    }, [user]);

    function onClick() {
        if (targetUrl === '/') {
            history.push('/');
        }
        else {
            window.open(targetUrl, "_blank");
        }
    }

    if (queryResultsLoading) return <Loading loading={queryResultsLoading} />

    if (!queryResultsLoading && !clubAssociationEnrollmentConfiguration) {
        return (
            <NotFoundPage></NotFoundPage>
        );
    }

    return (
        <>
            <div className="CustomEnrollmentRegistrationComplete">
                <div className={"CustomEnrollmentRegistrationComplete_Line1"}>
                    {t('customEnrollmentComplete.title')}
                </div>
                <div className={"CustomEnrollmentRegistrationComplete_Line2"}>
                    {t('customEnrollmentComplete.subtitle')}
                </div>
                <div>
                    <Button
                        className={"CustomEnrollmentRegistrationComplete_Button"}
                        type={"button"}
                        clickHandler={onClick}
                        buttonStyle={ButtonStyles.FilledPrimary}>
                        {clubAssociationEnrollmentConfiguration!.confirmationPageContinueButtonText ?
                            clubAssociationEnrollmentConfiguration!.confirmationPageContinueButtonText : t('customEnrollmentComplete.defaultButtonText')}
                        <ArrowButton className={"CustomEnrollmentRegistrationComplete_Button_Icon"}/>
                    </Button>
                </div>
                {clubAssociationEnrollmentConfiguration!.confirmationPageContent &&
                    <div className={"CustomEnrollmentRegistrationComplete_BottomSection"}>
                        <PortableText content={clubAssociationEnrollmentConfiguration!.confirmationPageContent}></PortableText>
                    </div>
                }
            </div>
        </>
    )
}
