import {
    FormOptions,
    FormState,
    InputOptions,
    Inputs,
    useFormState
} from "react-use-form-state";
import {createContext, useContext, useEffect, useState} from "react";

export type SubFormState = FormState<any, Record<string, string>>;
export type FormInputs = Inputs<any>;
export type ElementConfig = Record<string, InputConfig<any>>;
export type InputConfig<T> = Record<string, any> & {
    initialValue: T;
    toInputValue?: (initialRootValue: any) => T;
    fromInputValue?: (inputValue: T) => any;
    fromInputValueDependencies?: any[],
    validate: (value: T, values: any) => string | undefined;
} & Omit<InputOptions<any, any>, "validate">;

type RootFormContextValue = {
    values: Record<string, any>;
    setValuesPartial: (valuesPartial: Record<string, any>) => void;
    inputValues: Record<string, any>;
    setInputValuesPartial: (valuesPartial: Record<string, any>) => void;
}

type SubFormContextValue = {
    elementConfig: ElementConfig;
    addInputConfig: (inputConfig: InputConfig<any>) => void;
    removeInputConfig: (inputConfig: InputConfig<any>) => void;
    formState: SubFormState;
}

export const RootFormContext = createContext<RootFormContextValue>({
    setValuesPartial: () => {},
    values: {},
    setInputValuesPartial: () => {},
    inputValues: {}
});

export const SubFormContext = createContext<SubFormContextValue>({
    elementConfig: {},
    addInputConfig: () => {},
    removeInputConfig: () => {},
    // It'll be populated later.
    formState: {} as SubFormState
});

interface UseCreateRootFormValue {
    values: Record<string, any>;
    context: RootFormContextValue;
}

export function useCreateRootForm(initialValues?: Record<string, any>): UseCreateRootFormValue {
    const [values, setValues] = useState(initialValues ?? {});
    // While not technically accurate for all inputs, since some will have
    // custom to/from functions, those elements will overwrite these on init.
    const [inputValues, setInputValues] = useState(initialValues ?? {});
    let valuesForTick = { ...values };
    let inputValuesForTick = { ...inputValues };

    function setValuesPartial(valuesPartial: Record<string, any>) {
        // Probably not React best-practice, but necessary to avoid issues caused by
        // multiple components writing to values in the same tick overwriting each other.
        valuesForTick = {
            ...valuesForTick,
            ...valuesPartial
        }
        setValues(valuesForTick);
    }

    function setInputValuesPartial(inputValuesPartial: Record<string, any>) {
        // Probably not React best-practice, but necessary to avoid issues caused by
        // multiple components writing to values in the same tick overwriting each other.
        inputValuesForTick = {
            ...inputValuesForTick,
            ...inputValuesPartial
        }
        setInputValues(inputValuesForTick);
    }

    return {
        values,
        context: {
            values,
            setValuesPartial,
            inputValues,
            setInputValuesPartial
        }
    }
}

interface UseCreateSubFormValue {
    formState: SubFormState;
    elementConfig: ElementConfig;
    context: SubFormContextValue;
}

export function useCreateSubForm(initialState?: Record<string, any>, options?: FormOptions<any>): UseCreateSubFormValue {
    const {inputValues} = useRootForm();
    const fullInitialValues = {
        ...inputValues,
        ...initialState
    }
    const [formState] = useFormState<any, Record<string, string>>(fullInitialValues, options);

    const [elementConfig, setElementConfig] = useState<ElementConfig>({});
    let elementConfigForTick = {
        ...elementConfig
    }

    function addInputConfig(inputConfig: InputConfig<any>) {
        // Probably not React best-practice, but necessary to avoid issues caused by
        // multiple components in the same tick overwriting each other.
        elementConfigForTick[inputConfig.name] = inputConfig;
        setElementConfig(elementConfigForTick);
    }

    function removeInputConfig(inputConfig: InputConfig<any>) {
        // Probably not React best-practice, but necessary to avoid issues caused by
        // multiple components in the same tick overwriting each other.
        delete elementConfigForTick[inputConfig.name];
        setElementConfig(elementConfigForTick);
    }

    return {
        formState,
        elementConfig,
        context: {
            formState,
            elementConfig,
            addInputConfig,
            removeInputConfig
        }
    }
}

export function useRootForm(): RootFormContextValue {
    return useContext(RootFormContext);
}

// A convenience version of useRootForm for the most common use-case.
export function useRootFormValues<T>(): T {
    return useRootForm().values as T;
}

export function useSubForm(): SubFormState {
    return useContext(SubFormContext).formState;
}

export function useSubFormInput<T>(inputConfig: InputConfig<T>): [SubFormState, FormInputs] {
    useRegisterElementConfig(inputConfig);
    const subFormState = useSubForm();
    const {inputValues, values, setValuesPartial, setInputValuesPartial} = useRootForm();
    let initialFormValue: T;
    if (inputValues[inputConfig.name]) {
        initialFormValue = inputConfig.toInputValue
            ? inputConfig.toInputValue(inputValues[inputConfig.name])
            : inputValues[inputConfig.name];
    } else {
        initialFormValue = inputConfig.initialValue;
    }
    const [formState, inputs] = useFormState<any, Record<string, string>>({
        [inputConfig.name]: initialFormValue
    });

    // Total hack. Not best practice at all. Essentially, to ensure the inputConfig (which is only registered once to
    // avoid re-renders every frame) can validate based on the form values, we make use of a state object to serve as a
    // consistent reference to those values across frames.
    const rootFormValues = useState(values);
    useEffect(() => {
        Object.assign(rootFormValues, values);
    }, [values])

    const baseValidate = inputConfig.validate;
    inputConfig.validate = (value: any) => {
        return baseValidate(value, rootFormValues);
    }

    useEffect(() => {
        const {name} = inputConfig;
        const value = formState.values[name];
        // This sets the 'clean' version of the value for the root form
        if (inputConfig.fromInputValue) {
            setValuesPartial({ [name]: inputConfig.fromInputValue(value) });
        } else {
            setValuesPartial({ [name]: value });
        }
        // This sets the raw values form the root form, for persistence between sub-forms.
        setInputValuesPartial({ [name]: value });
        subFormState.setField(inputConfig.name, formState.values[inputConfig.name]);
    }, [formState.values[inputConfig.name], ...(inputConfig.fromInputValueDependencies ?? [])]);

    useEffect(() => {
        subFormState.setFieldError(inputConfig.name, formState.errors[inputConfig.name]);
    }, [formState.errors[inputConfig.name]]);

    return [formState, inputs];
}

function useRegisterElementConfig(inputConfig: InputConfig<any>) {
    const { addInputConfig, removeInputConfig } = useContext(SubFormContext);

    useEffect(() => {
        addInputConfig(inputConfig);
        return () => removeInputConfig(inputConfig);
    }, []);
}

export function checkValidity({
                                  elementConfig,
                                  formState
                              }: {
    elementConfig: ElementConfig,
    formState: SubFormState
}): boolean {
    // Validity is not evaluated on form load for pre-populated values, so
    // this simply runs it by hand.
    return Object.values(elementConfig).every(field => {
        if (field.validate) {
            // @ts-ignore
            return !field.validate(formState.values[field.name]);
        }
        return true;
    });
}

export interface RegistrationInputProps {
    className?: string;
}




