import React, { useContext, useEffect, useState } from "react";
import {Redirect} from "react-router-dom";

import "./HomePage.scss";
import {UserContext} from "../../contexts/UserContext";
import {getHomePage, refreshUsersClubs} from "../../services/UserService";

export default function HomePage(): JSX.Element {
    const { user, refreshUser } = useContext(UserContext);

    const [homePage, setHomePage] = useState<string | null>(null);

    // todo: maybe redirect to placeholder when site is halfway up?
    useEffect(() => {
        if (user === null) {
            setHomePage("/login");
        } else {
            (async () => {
                await refreshUser();
                await refreshUsersClubs();

                setHomePage(await getHomePage(user));
            })();
        }
    },[]);

    return (
        <div className="HomePage">
            {homePage !== null && <Redirect to={homePage}/>}
        </div>
    );
}
