import React from "react";
import "./TimeView.scss";
import i18n from "i18next";


export interface TimeViewProps {
    time: string | Date;
    format: object;
    className?: string;
}

export default function TimeView(props: TimeViewProps): JSX.Element {

    let classes = 'CCTimeView';
    let className = props.className;
    var time: Date;

    if (typeof props.time === 'string') {
        time = new Date(props.time.toString());
    } else {
        time = props.time as Date;
    }

    let formattedTime = time.toLocaleTimeString(i18n.language || 'en', props.format);

    if (className !== undefined) {
        classes += ' ' + className;
    }

    return (
        <span className={classes}>{formattedTime}</span>
    );
}