import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useFormState} from "react-use-form-state";
import {FormControl, MenuItem, Select, TextField, Button, Grid} from "@material-ui/core";
import {Locale} from "@js-joda/locale_en-us";
import {convert, DateTimeParseException, LocalDate} from "@js-joda/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputMask from "react-input-mask";
import {
    getAllPayersByPayerListSlug,
    getChildPayersForParentList,
    GetPayerResponseBody
} from "../../../services/MemberService";
import { getPhoneUnmask, PHONE_REGEX } from '../../../util/Util';
import Loading from '../../../components/common/Loading';
import FieldStatusIcon from '../../../components/ignite/registration/FieldStatusIcon';
import { payerConfig } from '../../../configs/payers';
import { LocalDateUtils } from '../../../util/FixedJsJodaUtils';
import useCheckEligibility from '../../../hooks/useCheckEligibility';
import { Autocomplete } from '@material-ui/lab';
import LoadingMessage from '../../../components/common/LoadingMessage';

type HealthPlanInfoFormProps = {
    onSubmit: () => void;
}

interface Option {
    name: string;
    value: number;
    slug: string;
}

export default function HealthplaInfoForm (props: HealthPlanInfoFormProps): JSX.Element {
    const { t } = useTranslation('pages');
    const initialState = {
        payerId: undefined,
        insuranceId: "",
        healthPlanName: null,
        dateOfBirth: null,
        phone: "",
        zipCode: ""
    }

    const [formState, {text, select, tel}] = useFormState(initialState);
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(null);
    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [dateOfBirthTouched, setDateOfBirthTouched] = useState(false);
    const {payerId, healthPlanName} = formState.values;
    const [saving, setSaving] = useState<boolean>(false);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const [displayedPayers, setDisplayedPayers] = useState<GetPayerResponseBody[]>([]);
    const selectedPayer: GetPayerResponseBody | null =
        displayedPayers.find(p => p.id === payerId) || null;
    const [cardSrc, setCardSrc] = useState<string>('');
    const [, check] = useCheckEligibility();
    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [options, setOptions] = useState(new Array<Option>());
    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);
    const [autoCompleteValue, setAutoCompleteValue] = useState<Option | null>(null);
    const [otherPayerId, setOtherPayerId] = useState<number>(0);

    const elementConfig: Record<string, any> = {
        payerId: {
            name: "payerId",
            validate: (value: string | number): string | undefined => {
                if (typeof value === "number" && displayedPayers?.find(p => p.id === value)) {
                    return;
                }
                return " ";
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (value === "") {
                    return t('newMemberRegistration.memberIdError');
                }
            },
            validateOnBlur: true
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string | null) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value?.trim()) {
                    return t('newMemberRegistration.zipCodeError');
                }
                if (!value.match(zipCodeRegex)) {
                    return t('newMemberRegistration.zipCodeError');
                }
            },
            validateOnBlur: true
        },
        phone: {
            name: 'phone',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return t('newMemberRegistration.phoneError');
                }
            },
            validateOnBlur: true
        }
    }

    useEffect(() => {
        // The widget validation was inconsistent during development. Perform explicit validation on the parsed LocalDate before passing off the value to the form.
        if (dateOfBirth instanceof LocalDate && validateDateOfBirth(dateOfBirth)) {
            formState.setField('dateOfBirth', dateOfBirth);
        } else {
            // Otherwise invalidate the date on the form
            formState.setField('dateOfBirth', null);
        }
    }, [dateOfBirth]);

    useEffect(() => {
        // Gather the correct images for selected payer
        let p: keyof typeof payerConfig;
        for (p in payerConfig) {
            if (p === selectedPayer?.payerSlug) {
                setCardSrc(payerConfig[p].cardSrc);
                return;
            }
        }
        setCardSrc('');
    }, [selectedPayer]);

    useEffect(() => {

        const loadAllPayers = async () => {
            try {
                // Displayed payers for the default member payer list slug
                const displayedPayersResponseBody = await getAllPayersByPayerListSlug('member');
                setDisplayedPayers(displayedPayersResponseBody.allPayers.sort((a,b) => {
                    if (!a.unlistedPayerPlaceholder && !b.unlistedPayerPlaceholder)
                        return a.payerName.localeCompare(b.payerName);
                    else if (a.unlistedPayerPlaceholder)
                        return 1;
                    else
                        return -1;
                }));
                
                const otherId = displayedPayersResponseBody.allPayers.find(p => p.unlistedPayerPlaceholder)?.id;
                if (otherId !== undefined) {
                    const allPayersResponseBody = await getChildPayersForParentList(otherId);
                    setAllPayers(allPayersResponseBody.allPayers.sort((a,b) => a.payerName.localeCompare(b.payerName)));
                }

                setLoading(false);
                setLoadingErrorMessage(null);
            } catch (e) {
                setLoading(false);
                setLoadingErrorMessage(t('memberSelectPayerStep.unexpectedError'));
            }
        };

        loadAllPayers();

    }, []);

    useEffect(() => {
        const results: GetPayerResponseBody[] = allPayers.filter(p => p.payerName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

        const newOptions: Option[] =
            results.map(payer => {
                return {
                    name: payer.payerName,
                    value: payer.id,
                    slug: payer.payerSlug
                };
            });

        setOptions(newOptions);

    }, [inputValue, allPayers]);

    useEffect(() => {
        if (selectedPayer?.unlistedPayerPlaceholder && autoCompleteValue?.slug) {
            setOtherPayerId(Number(autoCompleteValue.value));
        }
        else if (selectedPayer?.payerSlug) {
            setOtherPayerId(0);
        }
    }, [selectedPayer, autoCompleteValue]);

    const continueEnabled = () => {
        // Validity is not evaluated on form load for pre-populated values, so
        // this is good enough for that initial state.
        const allFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        });

        return allFieldsValid &&
            !Boolean(dateOfBirthError) &&
            validateHealthPlanName() &&
            Object.entries(formState.validity).every(([key, value]) => value);
    }

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const validateDateOfBirth = (date: LocalDate) => {
        if (date instanceof LocalDate) {
            const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
            const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

            if (!isEqualOrAfterMinDate || !isEqualOrBeforeMaxDate) {
                setDateOfBirthError(t('newMemberRegistration.dobError'));
            }
            else {
                setDateOfBirthError('');
            }
            return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
        } else {
            setDateOfBirthError(t('newMemberRegistration.dobError'));
            return false;
        }
    }

    const handleOnSubmit = async () => {
        try{
            setSaving(true);
            await check({
                FirstName: "",
                LastName: "",
                MemberId: formState.values.insuranceId,
                GroupNumber: "",
                ZipCode: formState.values.zipCode,
                PayerId: otherPayerId === 0 ? Number(selectedPayer?.id) : otherPayerId,
                DateOfBirth: formState.values.dateOfBirth.toString(),
            });
            props.onSubmit();
        }finally{
            setSaving(false);
        }
    }

    const validateHealthPlanName = () => {
        if (selectedPayer !== null && selectedPayer.unlistedPayerPlaceholder) {
            return autoCompleteValue?.value !== undefined && autoCompleteValue.value > 0;
        }
        return true;
    }

    return (
        <div className="NewMemberRegistrationWizard_Wrapper">
            <h2>{t('newMemberRegistration.personalInformationHeader')}</h2>
            <div style={{padding: 0, margin: 24}}>
                {loading === null && (
                    <Loading loading={loading}/>
                )}
                {!loading && loadingErrorMessage !== null && (
                    <LoadingMessage message={loadingErrorMessage}/>
                )}
                {!loading &&
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={cardSrc ? 5 : 12}>
                            <FormControl fullWidth>
                                <Select
                                    style={{width: '100%'}} {...select(elementConfig.payerId)}
                                    variant="outlined"
                                    margin={"dense"}
                                    disabled={loading}
                                    displayEmpty
                                    required>
                                    <MenuItem value="" disabled>
                                        {t('newMemberRegistration.selectPayer')}
                                    </MenuItem>
                                    {displayedPayers?.map(payer => (
                                        <MenuItem key={payer.id} value={payer.id}>
                                            {payer.payerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                            {selectedPayer !== null && selectedPayer.unlistedPayerPlaceholder && (
                                <div className={"NewMemberPersonalInformationStep_HealthPlanNameField_Autocomplete"}>
                                <FormControl fullWidth
                                    className="NewMemberPersonalInformationStep_HealthPlanNameField">
                                    <Autocomplete
                                        open={open}
                                        style={{width: "100%"}}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        onChange={(event, value) => {
                                            setAutoCompleteValue(value);
                                        }}
                                        value={autoCompleteValue}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        getOptionLabel={(option) => option.name}
                                        options={options}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                margin={"dense"}
                                                required
                                                error={!validateHealthPlanName()}
                                                label={t('newMemberRegistration.selectPayer')}
                                                style={{marginBottom: '10px', width: '100%'}}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            )}

                        {selectedPayer !== null && (!selectedPayer.unlistedPayerPlaceholder || healthPlanName !== '') &&
                            <>
                                <div style={{display: 'flex', marginTop: 8}}>
                                <FormControl fullWidth style={{flex: 1}}>
                                    <TextField style={{width: '100%'}} {...text(elementConfig.insuranceId)}
                                                error={formState.errors.insuranceId !== undefined}
                                                helperText={formState.errors.insuranceId}
                                                className="NewMemberPersonalInformationStep_InsuranceIdField"
                                                margin={"dense"}
                                                required
                                                inputProps={{maxLength: 50}}
                                                label={t('newMemberRegistration.enterPayerId')}
                                                variant="outlined"
                                    />
                                </FormControl>
                                {formState.touched.insuranceId && (
                                    <div className='field-indicator-wrapper' style={{marginLeft: 8, marginTop:8}}>
                                        <FieldStatusIcon shouldShow={formState.touched.insuranceId}
                                                         isError={formState.errors.insuranceId}/>
                                    </div>
                                )}
                                </div>
                                {cardSrc && (
                                <span style={{ marginTop: 8 }} className={"PersonalInformation_MemberIDNote"}>
                                    {t('newMemberRegistration.exampleCardNote')}
                                </span>
                                )}
                                <div style={{display: 'flex', marginTop: 8}}>
                                    <FormControl fullWidth style={{flex: 1}}>
                                        <MuiPickersUtilsProvider utils={LocalDateUtils}
                                                                locale={Locale.US}>
                                            <KeyboardDatePicker
                                                className="MemberRegistrationWizard_Input"
                                                value={dateOfBirth}
                                                label={t('memberPersonalInformationStep.dob')}
                                                required
                                                error={Boolean(dateOfBirthError)}
                                                helperText={dateOfBirthError}
                                                inputVariant="outlined"
                                                margin={"dense"}
                                                format="MM/dd/yyyy"
                                                views={["year", "month", "date"]}
                                                openTo="year"
                                                disableFuture
                                                minDate="1900-01-02" // Treated as 1/2/1900
                                                minDateMessage={t('memberPersonalInformationStep.dobMin')}
                                                maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                                                maxDateMessage={t('memberPersonalInformationStep.dobMax')}
                                                initialFocusedDate="1900-01-02"
                                                onChange={date => {
                                                    setDateOfBirthTouched(true);
                                                    setDateOfBirth(date);
                                                    validateDateOfBirth(date);
                                                }}
                                                onBlur={(event: any) => {
                                                    setDateOfBirthTouched(true);
                                                    if (!event.target.value) {
                                                        dateOfBirth instanceof LocalDate ?
                                                            validateDateOfBirth(event.target.value) : setDateOfBirthError(t('newMemberRegistration.dobError'));
                                                    }
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                    {dateOfBirthTouched && (
                                    <div className='field-indicator-wrapper' style={{marginLeft: 8, marginTop:8}}>
                                        <FieldStatusIcon
                                            shouldShow={dateOfBirthTouched || Boolean(dateOfBirthError)}
                                            isError={Boolean(dateOfBirthError)}
                                        />
                                    </div>
                                    )}
                                </div>
                                <div style={{display: 'flex', marginTop: 8}}>
                                    <FormControl fullWidth style={{flex: 1}}>
                                        <InputMask
                                            mask='(999) 999-9999'
                                            maskChar='*'
                                            {...tel(elementConfig.phone)}
                                        >
                                            {(inputProps: any) =>
                                                <TextField {...inputProps}
                                                    className="MemberRegistrationWizard_Input"
                                                    label={t('newMemberRegistration.phoneLabel')}
                                                    margin={"dense"}
                                                    required
                                                    variant={"outlined"}
                                                    error={formState.errors.phone !== undefined}
                                                    helperText={formState.errors.phone}
                                                    size="small"
                                                    FormHelperTextProps={{error: formState.errors.phone !== undefined}}
                                                />
                                            }
                                        </InputMask>
                                    </FormControl>
                                    {formState.touched.phone && (
                                    <div className='field-indicator-wrapper' style={{marginLeft: 8, marginTop:8}}>
                                        <FieldStatusIcon shouldShow={formState.touched.phone} isError={formState.errors.phone} />
                                    </div>
                                    )}
                                </div>

                                <div style={{display: 'flex', marginTop: 8}}>
                                    <FormControl fullWidth style={{flex: 1}}>
                                         <TextField {...text(elementConfig.zipCode)}
                                                className="MemberRegistrationWizard_Input"
                                                margin={"dense"}
                                                label={t('newMemberRegistration.zipCodeLabel')}
                                                required
                                                error={formState.errors.zipCode !== undefined}
                                                helperText={formState.errors.zipCode}
                                                variant={"outlined"}
                                                size="small"
                                                inputProps={{
                                                    length: 5,
                                                    minLength: 5,
                                                    maxLength: 5
                                                }}
                                            />
                                    </FormControl>
                                    {formState.touched.zipCode &&
                                    <div className='field-indicator-wrapper' style={{marginLeft: 8, marginTop:8}}>
                                        <FieldStatusIcon shouldShow={formState.touched.zipCode} isError={formState.errors.zipCode} />
                                    </div>
                                    }
                                </div>
                            </>
                        }
                        </Grid>
                        {cardSrc && (
                        <Grid item xs={12} md={7} style={{objectFit: 'contain'}}>
                            { Boolean(cardSrc) && ( 
                                <img
                                    src={cardSrc}
                                    alt={`${selectedPayer?.payerName}`}
                                    style={{height: '100%', width: '100%'}}
                                />
                            )}
                        </Grid>
                        )}

                        <Grid item xs={12} style={{textAlign: 'right'}}>
                            <Button
                                disabled={!continueEnabled() || saving}
                                size="large"
                                color="primary"
                                variant="outlined"
                                className="button"
                                onClick={handleOnSubmit}
                            >
                                {t('newMemberRegistration.submitButton')}
                            </Button> 
                        </Grid>
                    </Grid>
                }
            </div>
        </div>
    );
}