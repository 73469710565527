import React from "react";

import { MemberInfoPanel } from "./MemberInfoPanel";
import { ContactMethodsPanel } from "./ContactMethodsPanel";
import { IProfileInfo } from "../../../types/profile";

export const MemberInfo = ({ profileInfo }: { profileInfo: IProfileInfo }) => {
    return (
        <>
            <MemberInfoPanel  />
            <ContactMethodsPanel profileInfo={profileInfo} />
        </>
    )
}
