import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { ITerm } from '../../types/clubSetup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import "./ActivityTypePicker.scss";


interface ActivityTypePickerProps {
    terms: ITerm[] | undefined,
    selectedTerms: string[],
    setSelectedTerms: Function,
    className?: string,
}

/* 
 * ActivityTypePicker component
 *  Jira: https://hellogrouperhealth.atlassian.net/browse/EDP-268
 *  Figma: https://www.figma.com/file/0ZCdv7tDtSxIdiakBRVx3e/Club-directory-(redesign)?type=design&node-id=494-2461&mode=design&t=cydnt88p4icMIuEh-0
 *
 *  ActivityTypePicker is used in the club filter area of the ClubDirectory page.
 *  Note that what we call "Activity Types" on the platform are "terms" in code
*/

export default function ActivityTypePicker(props: ActivityTypePickerProps): JSX.Element {
    const { t } = useTranslation('club');
    let classes = 'ClubDirectory_ActivityTypePicker';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    
    const terms: Array<ITerm> = (props.terms !== undefined) ? props.terms : []
    const [selectAllTerms, setSelectAllTerms] = useState<boolean>(true);

    useEffect(() => {
        // onload - default is to have all terms selected from load
        handleSelectAllTermsChange();
    }, [terms])

    // checkbox is toggled
    function handleSelectAllTermsChange() {
        setSelectAllTerms(!selectAllTerms);
        if (!selectAllTerms) {
            const currentTermsArr = terms.map((term) => term.termName)
            props.setSelectedTerms(currentTermsArr);
        } else {
            // due to how endpoint works on backend, you have to send an empty string 
            // (not null) to search explicitly for no terms
            props.setSelectedTerms(['']);
        }
    }
    
    // an ActivityTypeToggleChip was toggled
    function updateSelectedTerms(termName: string, isSelected: Boolean) {
        let local_allSelectedTerms = props.selectedTerms;

        const termName_i = local_allSelectedTerms.indexOf(termName)
        if (isSelected) {
            // add termName to list of all selected terms, if necessary
            if (termName_i === -1) {
                local_allSelectedTerms.push(termName);
            }
        } else {
            // remove termName if necessary
            if (termName_i !== -1) {
                local_allSelectedTerms.splice(termName_i, 1);
            }
        }

        // currently if no tags are selected we send an empty string to the search params
        // but we want to eject that empty string as soon as we don't need it anymore
        let emptyString_i = local_allSelectedTerms.indexOf('');
        if (local_allSelectedTerms.length > 1 && emptyString_i !== -1) {
            // empty string is in array, remove it
            local_allSelectedTerms.splice(emptyString_i, 1);
        }

        if (local_allSelectedTerms.length === terms.length) {
            setSelectAllTerms(true);
        } else {
            setSelectAllTerms(false);
        }

        if (local_allSelectedTerms.length == 0) {
            props.setSelectedTerms(['']);
        } else {
            props.setSelectedTerms(local_allSelectedTerms);
        }
    }

    return (
        <div className={classes} >
            <FormControlLabel
                className="ActivityTypePicker_ToggleSelectAllTerms-container"
                control={
                    <Checkbox
                        checked={selectAllTerms}
                        onChange={handleSelectAllTermsChange}
                        name="toggleSelectAllTerms"
                        color="primary"
                        inputProps={{ 'aria-label': t('activityTypePicker.selectAllCheckboxLabel') }}
                    />
                }
                label={t('activityTypePicker.selectAllCheckboxLabel')}
            />
            <div className="ActivityTypePicker_Container">
                {
                    terms.map((term) => (
                        <ActivityTypeToggleChip
                            key={term.id}
                            term={term}
                            updateSelectedTerms={updateSelectedTerms}
                            selected={props.selectedTerms.includes(term.termName)}
                        />
                    ))
                }

            </div>
        </div>
    );
}

interface ActivityTypeToggleChip {
    term: ITerm;
    updateSelectedTerms: Function;
    selected: boolean;
}

function ActivityTypeToggleChip(props: ActivityTypeToggleChip): JSX.Element {
    function updateSelected() {
        const newSelectedStatus = !props.selected;
        props.updateSelectedTerms(props.term.termName, newSelectedStatus);
    }

    return (
        <div key={props.term.id}
            onClick={updateSelected}
            className={"ActivityTypeToggleChip " + ((props.selected) ? "selected" : "")}
        >
            {props.term.displayName ?? props.term.termName}
        </div>
    )
}
