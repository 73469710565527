import { useState, useEffect } from "react";
import { getAllVirtualActivityTypes } from '../api/getAllTerms'
import { ITerm } from '../types/clubSetup'

const useGetAllTerms = (): [ITerm[] | undefined, boolean] => {
    const [terms, setTerms] = useState<ITerm[] | []>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        getAllVirtualActivityTypes()
            .then(data => {
                if (data?.allTerms) {
                    const { allTerms } = data;
                    allTerms.sort((term1: ITerm, term2: ITerm) => {
                        return term1.termName.toLowerCase().localeCompare(term2.termName.toLowerCase());
                    });
                    setTerms(allTerms);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [])

    return [terms, isLoading]
}

export default useGetAllTerms

