import React, { useMemo, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import StarIcon from '@material-ui/icons/Stars';
import { useHistory, useParams } from "react-router-dom";
import { VERIFICATION_STATUS } from '../../../constants/verification'
import useVerificationData from '../../../hooks/useVerificationData'
import useGetPayers from "../../../hooks/useGetPayers";
import Loading from "../../../components/ignite/layout/Loading";
import { IGNITE_ROUTE_KEY } from '../../../constants/routes'

import './VerificationStatus.scss'
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { UserContext } from "../../../contexts/UserContext";
import useClubById from "../../../hooks/useClubById";
import { setUsersEligibilityModalShownState } from "../../../services/UserService";

export default function VerificationStatus() {
    const { t } = useTranslation('ignite/verification');
    const { id, payerSlug, clubId } = useParams()
    const { user } = useContext(UserContext)
    const [ club ] = useClubById(clubId)
    const isCurrentUserLeader = club?.primaryClubLeaders.some(leader => leader.email === user?.email) || club?.secondaryClubLeaders.some(leader => leader.email === user?.email)
    const history = useHistory()
    const dashboardRoute = getIgniteRoute(isCurrentUserLeader ? IGNITE_ROUTE_KEY.LEADER_DASHBOARD : IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': clubId })

    const [verificationData, isLoading] = useVerificationData({ id, payerSlug })
    const [payers] = useGetPayers()

    const {
        isNotEligible,
        isEligible,
        verificationStatus,
        userSuppliedPayerName: otherPayerName,
        logoSrc,
        isOther,
        firstName,
        lastName,
        payerId
    } = verificationData

    const userSuppliedPayerName = useMemo(() => (
        isOther
            ? otherPayerName
            : (payers?.find((payer) => payer.id === payerId)?.payerName || '')
    ), [isOther, otherPayerName, payers, payerId])

    const title = useMemo(() => {
        switch (verificationStatus) {
            case VERIFICATION_STATUS.ACTIVE:
                return t('eligibility.eligible')
            case VERIFICATION_STATUS.IN_PROGRESS:
                return t('eligibility.pending')
            case VERIFICATION_STATUS.INVALID:
            case VERIFICATION_STATUS.EXPIRED:
                return t('eligibility.notEligible')
            default:
                return t('eligibility.notEligible')
        }
    }, [verificationStatus])

    const onGoToDashboard = () => {
        history.push(dashboardRoute);

        if (user) {
            setUsersEligibilityModalShownState(user.id, false);
        }
    }

    if (isLoading || !verificationStatus) return <Loading loading={isLoading} />

    return (
            <div className='verification-status'>
                <div className={`verification-status__title ${isNotEligible ? 'not-eligible' : ''}`}>
                    {title}
                </div>

                <div className="verification-status-content">
                    <div className={`verification-status-row ${isOther ? "other" : ''}`}>
                        <div className="logo-wrapper">
                            {Boolean(logoSrc) && <div className="logo">
                                <img src={logoSrc} alt={t('payerSelection.logoAlt', { userSuppliedPayerName })} />
                            </div>}
                        </div>
                        <div className="main-info">
                            {isOther ? (
                                <div className="main-info__name">
                                    {userSuppliedPayerName}
                                </div>
                            ) : (
                                <div className="main-info__description">
                                    {t('payerSelection.offers', { userSuppliedPayerName })}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="verification-status-row second">
                        <div className="main-info">
                            {isEligible && (
                                <div className='help-text'>
                                    <StarIcon className='help-text__icon' />
                                    {t('eligibility.eligibleWithName', { firstName, lastName })}
                                </div>
                            )}
                            {isNotEligible && (
                                <div className="help-text">
                                    {t('eligibility.notEligibleWithName', { firstName, lastName })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`button-wrapper ${isNotEligible ? 'not-eligible' : ''}`}>
                    {isNotEligible && (
                        <Button
                            className='button white'
                            variant="outlined"
                            onClick={() => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_SELECTION, { ':clubId': clubId }))}
                            size='large'
                            color='primary'
                        >
                            {t('eligibility.tryAgain')}
                            <PlayIcon className='button__icon' />
                        </Button>
                    )}
                    <Button
                        className='button'
                        variant="outlined"
                        size='large'
                        color='primary'
                        onClick={onGoToDashboard}
                    >
                        {t('eligibility.goToDashboard')}
                        <PlayIcon className='button__icon' />
                    </Button>
                </div>
            </div>
    );
}
