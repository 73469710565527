import React from "react";
import "./DetailsBoxContainer.scss";

interface DetailsBoxContainerProps {
    children?: any
    className?: string;
}

export default function DetailsBoxContainer(props: DetailsBoxContainerProps): JSX.Element {

    let classes = 'CCDetailsBoxContainer';
    let className = props.className;
    let children = props.children;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    return (
        <div className={classes}>
            {children}
        </div>)

}