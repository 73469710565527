import { useState, useEffect, useCallback } from "react";
import getClubLeaderboard from '../api/getClubLeaderboard'
import { IActivityItem } from '../types/dashboard'
import { useHistory } from "react-router-dom";
import { IGNITE_ROUTE_PATTERNS } from "../constants/routes";

interface IUseGetLeaderboardResult {
    leaderboardList: IActivityItem[]
    leaderboardTotalCount: number
    isLoading: boolean
    fetchLeaderboard: Function
}

const useGetLeaderboard = (clubId: number): IUseGetLeaderboardResult => {
    const [leaderboardList, setLeaderboardList] = useState<IActivityItem[]>([])
    const [leaderboardTotalCount, setLeaderboardTotalCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const fetchLeaderboard = useCallback((skip = 0) => {
        return getClubLeaderboard({id: clubId, skip}).then(data => {
            setLeaderboardList(data.results)
            setLeaderboardTotalCount(data.totalResultCount)
        })
    }, [clubId])

    useEffect(() => {
        setIsLoading(true)

        fetchLeaderboard()
            .catch(() => history.push(IGNITE_ROUTE_PATTERNS.ERROR))
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return {leaderboardList, leaderboardTotalCount, isLoading, fetchLeaderboard}
}

export default useGetLeaderboard
