import { useState, useEffect } from "react";
import getAllPassions from '../api/getAllPassions'
import { IPassion } from '../types/clubSetup'

const useGetAllPassions = (): [IPassion[] | [], boolean] => {
    const [passions, setPassions] = useState<IPassion[] | []>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getAllPassions()
            .then(data => {
                const sortedPassions = data.allPassions.sort(
                    (passion1: IPassion, passion2: IPassion) => {
                        return passion1.passionName.toLowerCase().localeCompare(passion2.passionName.toLowerCase());
                    })

                setPassions(sortedPassions)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [passions, isLoading]
}

export default useGetAllPassions
