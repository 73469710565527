import React, {SyntheticEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';

import "../NewMemberRegistrationWizard.scss";
import {useFormState} from "react-use-form-state";
import Checkbox from '@material-ui/core/Checkbox';
import {FormControl, IconButton, InputAdornment, TextField} from "@material-ui/core";
import FormLabel from '@material-ui/core/FormLabel';
import Button, {ButtonStyles} from "../../../components/common/Button";
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {isValidEmail} from "../../../util/Util";
import {testIfEmailRegistered} from "../../../services/UserService";
import FieldStatusIcon from "../../../components/ignite/registration/FieldStatusIcon";
import Loading from "../../../components/common/Loading";
import { Visibility, VisibilityOff } from '@material-ui/icons';

export type MemberAccount = {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    termsOfUse: boolean
}

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void,
    registrationPath: string,
    isEnrollmentFlow: boolean
}

export function NewMemberAccountStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');

    const [showPassword, setShowPasswordStatus] = useState(false);
    const [showConfirmPassword, setShowConfirmPasswordStatus] = useState(false);

    const initialState = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        termsOfUse: false
    }

    const [formState, {text, password, email, checkbox}] = useFormState(initialState);
    const [saving, setSaving] = useState<boolean>(false);

    async function testEmailRegistered(email: string) {
        if (email) {
            try {
                const emailIsRegisteredResponse = await testIfEmailRegistered(email);
                if (emailIsRegisteredResponse.isRegistered) {
                    formState.setFieldError("email", t('newMemberRegistration.duplicateEmailError'));
                }
            } catch (e) {
                formState.setFieldError("email", t('newMemberRegistration.unexpectedError'));
            }
        }
    }

    const confirmEmailValidate = (value: string) => {
        if (value.trim() === "") {
            return t('newMemberRegistration.emailError');
        }
        if (value?.toLowerCase() !== formState.values.email?.toLowerCase()) {
            return t('newMemberRegistration.confirmEmailError');
        }
    }

    const confirmPasswordValidate = (value: string) => {
        if (!value.trim()) {
            return t('newMemberRegistration.confirmPasswordError');
        }
        if (value !== formState.values.password) {
            return t('newMemberRegistration.confirmPasswordError');
        }
    }

    const elementConfig = {
        renewId: {
            name: "renewId",
            validate: (value: string) => {
                const validRenewIdRegex = /^[SAsa][0-9]{9}$/;
                if (value.trim() && !value.match(validRenewIdRegex)) {
                    return t('newMemberRegistration.invalidConfCode');
                }
            },
            validateOnBlur: true
        },
        firstName: {
            name: "firstName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t('newMemberRegistration.firstNameError');
                }
            },
            validateOnBlur: true
        },
        lastName: {
            name: "lastName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t('newMemberRegistration.lastNameError');
                }
            },
            validateOnBlur: true
        },
        email: {
            name: 'email',
            validate: (value: string) => {
                const errorMessageConfirmEmail = confirmEmailValidate(formState.values.confirmEmail);

                if (errorMessageConfirmEmail && formState.touched.confirmEmail) {
                    formState.setFieldError('confirmEmail', errorMessageConfirmEmail);
                } else if (formState.touched.confirmEmail) {
                    formState.setField('confirmEmail', formState.values.confirmEmail)
                }
                if (value.trim() === "") {
                    return t('newMemberRegistration.emailError');
                }
                if (!isValidEmail(value)) {
                    return t('newMemberRegistration.emailError');
                }
                if (value?.toLowerCase() !== formState.values.confirmEmail?.toLowerCase() && formState.touched.confirmEmail) {
                    formState.setFieldError('confirmEmail', t('newMemberRegistration.confirmEmailError'));
                }
            },
            onBlur: ((e: SyntheticEvent) => {
                testEmailRegistered((e.target as HTMLInputElement).value).then(() => {
                })
                return undefined;
            }),
            validateOnBlur: true
        },
        confirmEmail: {
            name: 'confirmEmail',
            validate: confirmEmailValidate,
            validateOnBlur: true
        },
        password: {
            name: 'password',
            validate: (value: string) => {
                const errorMessageConfirmPassword = confirmPasswordValidate(formState.values.confirmPassword);

                if (errorMessageConfirmPassword && formState.touched.confirmPassword) {
                    formState.setFieldError('confirmPassword', errorMessageConfirmPassword);
                } else if (formState.touched.confirmPassword) {
                    formState.setField('confirmPassword', formState.values.confirmPassword)
                }

                const trimmedInput = value.trim();
                if (!trimmedInput) {
                    return t('newMemberRegistration.passwordError');
                }
                if (trimmedInput.length < 8) {
                    return t('newMemberRegistration.passwordError');
                }
                if (trimmedInput.search(/[A-Z]/) === -1 || trimmedInput.search(/[0-9]/) === -1) {
                    return t('newMemberRegistration.passwordError');
                }
            },
            validateOnBlur: true
        },
        confirmPassword: {
            name: 'confirmPassword',
            validate: confirmPasswordValidate,
            validateOnBlur: true
        },
        termsOfUse: {
            name: 'termsOfUse'
        }
    }

    const continueEnabled = () => {
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => key === 'renewId' || !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = () => {
        setSaving(true);
        props.onContinue({
            renewId: formState.values.renewId,
            firstName: formState.values.firstName,
            lastName: formState.values.lastName,
            email: formState.values.email,
            password: formState.values.password
        });
    }

    return (
        <div className={"NewMemberRegistrationWizard_Wrapper NewMemberAccountStep"}>
            <h2>{t('newMemberRegistration.accountHeader')}</h2>
            <div className={"NewMemberRegistrationWizard_Wrapper_InnerContainer"}>
                <Grid container spacing={2} direction="row" justify="space-between">
                    {props.registrationPath === 'optum' &&
                        <>
                            <Grid item xs={12} sm={8}>
                                <div className={"NewMemberRegistrationWizard_AccountInnerHeader"}>{t('newMemberRegistration.enterConfCode')}</div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <span className={"NewMemberRegistrationWizard_AccountNote"}>{t('newMemberRegistration.confirmationCodeNoteText')}</span>
                            </Grid>
                            <Grid item xs={10} sm={10} md={5}>
                                <FormControl fullWidth margin="dense">
                                    <TextField {...text(elementConfig.renewId)}
                                               className="MemberRegistrationWizard_Input"
                                               label={t('newMemberRegistration.confCodePlaceholder')}
                                               variant={"outlined"}
                                               error={formState.errors.renewId !== undefined}
                                               helperText={formState.errors.renewId}
                                               size="small"/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.renewId} isError={formState.errors.renewId} />
                                </div>
                            </Grid>
                            <Grid item xs={1} sm={1} md={6}></Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense">
                                    <p style={{marginTop: '0', fontSize: '14px'}}>{t('newMemberRegistration.codeHelperText')}</p>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{padding: "10px"}}></Grid>
                        </>
                    }
                    <Grid item xs={12} sm={6}>
                        <div className={"NewMemberRegistrationWizard_AccountInnerHeader"}>{t('newMemberRegistration.accountFormHeaderText')}</div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <span className={"NewMemberRegistrationWizard_AccountNote"}>{t('newMemberRegistration.fieldsRequiredText')}</span>
                    </Grid>
                    <Grid item xs={11} sm={5}>
                        <FormControl fullWidth margin="dense">
                            <TextField {...text(elementConfig.firstName)}
                                       className="MemberRegistrationWizard_Input"
                                       label={t('newMemberRegistration.firstNamePlaceholder')}
                                       required
                                       variant={"outlined"}
                                       error={formState.errors.firstName !== undefined}
                                       helperText={formState.errors.firstName}
                                       size="small"/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.firstName} isError={formState.errors.firstName} />
                        </div>
                    </Grid>
                    <Grid item xs={11} sm={5}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                {...text(elementConfig.lastName)}
                                className="MemberRegistrationWizard_Input"
                                label={t('newMemberRegistration.lastNamePlaceholder')}
                                variant="outlined"
                                error={formState.errors.lastName !== undefined}
                                helperText={formState.errors.lastName}
                                required
                                size="small"/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.lastName} isError={formState.errors.lastName} />
                        </div>
                    </Grid>
                    <Grid item xs={11} sm={5}>
                        <FormControl fullWidth margin="dense">
                            <TextField {...email(elementConfig.email)}
                                className="MemberRegistrationWizard_Input"
                                label={t('newMemberRegistration.emailPlaceholder')}
                                required
                                variant={"outlined"}
                                error={formState.errors.email !== undefined}
                                helperText={formState.errors.email}
                                size="small"/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.email} isError={formState.errors.email} />
                        </div>
                    </Grid>
                    <Grid item xs={11} sm={5}>
                        <FormControl fullWidth margin="dense">
                            <TextField {...email(elementConfig.confirmEmail)}
                                       className="MemberRegistrationWizard_Input"
                                       label={t('newMemberRegistration.confirmEmailPlaceholder')}
                                       required
                                       variant={"outlined"}
                                       error={formState.errors.confirmEmail !== undefined}
                                       helperText={formState.errors.confirmEmail}
                                       size="small"/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.confirmEmail} isError={formState.errors.confirmEmail} />
                        </div>
                    </Grid>
                    <Grid item xs={11} sm={5}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                {...password(elementConfig.password)}
                                className="MemberRegistrationWizard_Input"
                                label={t('newMemberRegistration.passwordPlaceholder')}
                                variant="outlined"
                                error={formState.errors.password !== undefined}
                                helperText={formState.errors.password}
                                required
                                size="small"
                                InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPasswordStatus(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.password} isError={formState.errors.password} />
                        </div>
                    </Grid>
                    <Grid item xs={11} sm={5}>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                {...password(elementConfig.confirmPassword)}
                                className="MemberRegistrationWizard_Input"
                                label={t('newMemberRegistration.confirmPasswordPlaceholder')}
                                variant="outlined"
                                error={formState.errors.confirmPassword !== undefined}
                                helperText={formState.errors.confirmPassword}
                                required
                                size="small"
                                InputProps={{
                                    type: showConfirmPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPasswordStatus(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.confirmPassword} isError={formState.errors.confirmPassword} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="dense">
                            <p style={{marginTop: '0', fontSize: '14px'}}>{t('newMemberRegistration.passwordInstructions')}</p>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl>
                            <div className={"NewMemberRegistrationWizard_Wrapper_TermsAndAgreements"}>
                                    <Checkbox {...checkbox(elementConfig.termsOfUse)} style={{paddingLeft: 0}}/>
                                    <FormLabel>{t('newMemberRegistration.acceptText')} <Link to={{pathname: "https://hellogrouper.com/app-terms-of-use"}}
                                                    target="_blank"
                                                     className={"RegistrationForm_TermsOfUseLink"}
                                                     >{t('newMemberRegistration.termsOfUseLinkText')}</Link></FormLabel>
                            </div>
                        </FormControl>
                    </Grid>
                </Grid>
                <div className={"NewMemberAccountStep_NextContainer"}>
                    {!saving && <Button type="button"
                            id="AccountSubmit"
                            clickHandler={onContinueSelected}
                            text={t('newMemberRegistration.nextButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                            disabled={!continueEnabled()}
                    />}
                    {saving && <Loading className={"NewMemberRegistrationWizard_SubmitButtonLoading"} loading={saving}/>}
                    {!props.isEnrollmentFlow &&
                        <Button type="button"
                                clickHandler={props.onPrevious}
                                text={t('newMemberRegistration.backButton')}
                                className="NewMemberRegistrationWizard_SubmitButton"
                                buttonStyle={ButtonStyles.UnfilledWithBorder}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
