import React from "react";
import { useTranslation } from 'react-i18next';
import { ReactMultiEmail } from '../../../libs/react-multi-email/ReactMultiEmail'
import '../../../libs/react-multi-email/ReactMultiEmail.scss'

const MultiEmailWrapper = ({ setEmails, emails, disabledInput }: { setEmails: Function, disabledInput?: boolean, emails?: string[] }) => {
    const { t } = useTranslation('ignite/dashboard')

    return (
        <div>
            <form>
                <label htmlFor="multi-email-id" hidden>{t('initialContent.actionText')}</label>
                <ReactMultiEmail
                    delimiter={'[ ,;]'}
                    placeholder={
                        <span className='react-multi-email-placeholder'>
                            {t('multiEmailPlaceholder')}
                        </span>
                    }
                    id="multi-email-id"
                    style={{ minHeight: '100px' }}
                    emails={emails}
                    onChange={(_emails: string[]) => {
                        setEmails(_emails);
                    }}
                    disabledInput={disabledInput}
                    getLabel={(email, index, removeEmail) => {
                        return (
                            <div className="react-multi-email-item" data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    ×
                                </span>
                            </div>
                        );
                    }}
                />
            </form>
        </div>
    );
}

export default MultiEmailWrapper;
