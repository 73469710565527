import React from "react";
import { useTranslation } from 'react-i18next';

import "./NotFoundPage.scss";
import {Link} from "react-router-dom";

export default function NotFoundPage(): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <div className="NotFoundPage">
            <div className={"Title"}>{t('notFoundPage.title')}</div>
            <div className={"Message"}>{t('notFoundPage.message1')}
                <br/>{t('notFoundPage.message2')} <Link to={"/"}>{t('notFoundPage.homePageLink')}</Link></div>
        </div>
    );
}
