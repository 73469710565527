import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const UPDATE_MEMBER_ACTIVITY_URL = '/ignite/update-member-activity'

const postUpdateMemberActivity = async (body: any): Promise<Response | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_MEMBER_ACTIVITY_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response
    }
    const error = await response?.text()

    throw new Error(error)
}

export default postUpdateMemberActivity
