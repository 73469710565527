import React from "react";
import { useTranslation } from 'react-i18next';
import { LocalDate } from "@js-joda/core";
import { formatEventOccurrenceDateOnOrAfter } from "../../util/Event.utils";
import { EventOccurrence } from "../../services/ClubLeaderService";
import { SupportedTimeZone } from "../../util/SupportedTimeZone";

export interface RecurrenceViewProps {
    timeZone: SupportedTimeZone
    occurrences: EventOccurrence[]
    className?: string;
}

export default function OccurrenceNextMeetingView(props: RecurrenceViewProps): JSX.Element {
    const { t } = useTranslation('event');

    let classes = 'CCDateView';
    let className = props.className;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    return (
        <>
            <div className={classes}>
                <em>
                    {t('eventRecurrence.nextMeeting')}
                </em>
                &nbsp;
                {formatEventOccurrenceDateOnOrAfter(props.occurrences, LocalDate.now())}
            </div>
        </>
    );
}
