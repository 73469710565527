import React, { useEffect, useState } from 'react';
import './AcblFaq.scss';
import getClubAssociationContentViaSanity, {
  ClubAssociationFaqPageSanityData,
  ClubAssociationSanityResponse,
} from '../../sanity/getClubAssociationContentViaSanity';
import Loading from '../../components/common/Loading';
import PortableText from 'react-portable-text';

export function AcblFaq(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [pageConfiguration, setPageConfiguration] = useState<
    Array<ClubAssociationFaqPageSanityData>
  >([]);

  useEffect(() => {
    let isCancelled = false;
    const getPageConfiguration = async () => {
      const data: ClubAssociationSanityResponse | undefined =
        await getClubAssociationContentViaSanity('acbl');
      if (!isCancelled && data) {
        setPageConfiguration(data?.result[0]?.associationFaqPage ?? undefined);
        setLoading(false);
      }
    };

    getPageConfiguration();

    return () => {
      isCancelled = true;
    };
  }, []);

  if (loading) return <Loading loading={loading} />;

  return (
    <div className={'AcblFaq'}>
      <img
        src={pageConfiguration[0].heroBannerImageUrl}
        className={'AcblFaq_Banner'}
      />
      <PortableText content={pageConfiguration[0].content ?? []} />
    </div>
  );
}
