import { CONTACT_METHOD } from '../constants/dashboard'
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const LEADER_UPDATE_PREFERRED_CONTACT_URL = '/ignite/leader/update-profile/preferred-contact-method'

const postLeaderUpdatePreferredContact = async (body: { UserId: number, ClubId: number, PreferredContactMethod: CONTACT_METHOD }): Promise<{ userId: number } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${LEADER_UPDATE_PREFERRED_CONTACT_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postLeaderUpdatePreferredContact
