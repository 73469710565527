import React, {useContext, useEffect} from "react";
import {useTranslation} from 'react-i18next';
import "./AboutPage.scss";
import {useTArray} from "../../util/Util";
import {UserContext} from "../../contexts/UserContext";
import {PhoneNumberOverride} from "../../components/common/ContactPhoneNumber";
import {useLocation} from "react-router-dom";

export default function AboutPage() : JSX.Element {
    const { t } = useTranslation('pages');
    const tArray = useTArray(t);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className={"AboutPage"}>
            <h1 className={"AboutPage_Title"}>{t('about.title')}</h1>
            <div className={"AboutPage_ImagePanel AboutPage_ImagePanel2"}>
                <img src={require("../../assets/about-1.png")}/>
                <img src={require("../../assets/about-2.png")}/>
            </div>
            <p className={"AboutPage_Section1"}>{t('about.section1.content')}</p>
            <ol className={"AboutPage_Section1Items"}>
                {tArray('about.section1.items').map((item: any) => (
                    <li>{item}</li>
                ))}
            </ol>
            <div className={"AboutPage_ImagePanel AboutPage_ImagePanel3"}>
                <img src={require("../../assets/about-3.png")}/>
                <img src={require("../../assets/about-4.png")}/>
                <img src={require("../../assets/about-5.png")}/>
            </div>
            <AboutPageGeneralSection path={"about.section2"} />
            <AboutPageGeneralSection path={"about.section3"} />
            <div className={"AboutPage_ImagePanel AboutPage_ImagePanel3"}>
                <img src={require("../../assets/about-6.png")}/>
                <img src={require("../../assets/about-7.png")}/>
                <img src={require("../../assets/about-8.png")}/>
            </div>
            <AboutPageGeneralSection path={"about.section4"} />
            <AboutPageGeneralSection path={"about.section5"} />
        </div>
    )
}

function AboutPageGeneralSection(props: { path: string }) {
    const { t } = useTranslation('pages');
    const tArray = useTArray(t);
    const {path} = props;
    const {getContactPhoneNumber} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({override: PhoneNumberOverride.MEMBER, pathname: location.pathname});

    return (
        <div className={"AboutPage_GeneralSection"}>
            <h2 className={"AboutPage_GeneralTitle"}>{t(`${path}.title`)}</h2>
            <ol className={"AboutPage_Questions"}>
                {tArray(`${path}.questions`, { memberContactPhoneNumber: phoneNumber }).map((item: any, i: number) => (
                    <li key={i}>
                        <div className={"AboutPage_Question"}>{item.question}</div>
                        <div className={"AboutPage_Answer"}>
                            <div className={"AboutPage_AnswerContent"}
                                 dangerouslySetInnerHTML={{__html: item.answer.content}}/>
                            {item.answer.items && (
                                <ol className={"AboutPage_AnswerItems"}>
                                    {item.answer.items.map((subItem: any, j: any) => (
                                        <li key={j}>{subItem}</li>
                                    ))}
                                </ol>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </div>
    )
}
