import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IconButton, Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import MultiEmailWrapper from "./MultiEmailWrapper";
import { IClub } from "../../../types/clubFinder";
import { UserContext } from "../../../contexts/UserContext";
import postInviteByEmail from "../../../api/postInviteByEmail";

interface IInviteContent {
    onBackClick: (e: React.MouseEvent) => void
    club: IClub
    fetchInvitedMembers: Function
}

const InviteContent = ({ onBackClick, club, fetchInvitedMembers }: IInviteContent) => {
    const { t } = useTranslation('ignite/dashboard');
    const { user } = useContext(UserContext);

    const [emails, setEmails] = useState<string[]>([]);

    const { clubName } = club
    const onClick = () => {
        postInviteByEmail({
            ClubId: Number(club.id),
            Emails: emails
        }).then(() => {
            setEmails([])
            fetchInvitedMembers()
        })
    }
    const leaderName = `${user?.firstName} ${user?.lastName}`

    const signupIgniteRoute = getIgniteRoute(IGNITE_ROUTE_KEY.MEMBER_ONBOARDING, { ':clubShortCode': String(club.shortCode) })

    const invitationMessage = t('copyEmailContent.invitationMessageText', { leaderName, clubName, link: window.location.origin + signupIgniteRoute })

    return (
        <div className="invite-content">
            <IconButton
                className="icon-button"
                onClick={onBackClick}
            >
                <ArrowBackIcon className="icon" />
                {t('backButtonText')}
            </IconButton>

            <div className="action-text">
                {t('initialContent.actionText')}
            </div>
            <MultiEmailWrapper
                emails={emails}
                setEmails={setEmails}
            />
            <Button
                className="button"
                onClick={onClick}
                disabled={!emails.length}
                classes={{ disabled: 'disabled' }}
            >
                {t('inviteContent.buttonText')}
            </Button>
            <div className="content-inner">
                <div className="subtitle">
                    {t('inviteContent.subtitle')}
                </div>
                <div className="main-part">
                    <div className="text" dangerouslySetInnerHTML={{__html: invitationMessage}} />
                </div>
            </div>
        </div>
    )
}

export default InviteContent;
