import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

interface IGetMembersArgs {
    id: number
    skip?: number
    withMissingActivitiesOnly?: boolean
    sortOrder?: string
    sortBy?: string
    searchValue?: string
}

export const GET_MEMBERS_URL = '/ignite/leader/get-members/'

const getMembers = async ({ id, skip = 0, withMissingActivitiesOnly = false, sortBy = 'lastActivity', sortOrder = 'asc', searchValue }:
    IGetMembersArgs): Promise<any | undefined> => {
    const config = await loadConfig();
    const url = `${config.apiOrigin}${GET_MEMBERS_URL}${id}?skip=${skip}&withMissingActivitiesOnly=${withMissingActivitiesOnly}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${searchValue}`

    const request = new Request(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }
}

export default getMembers
