import React from 'react';
import { useTranslation } from 'react-i18next';

import "./ImportantDetails.scss"
import Info from "@material-ui/icons/Info";

interface ImportantDetailsProperties {
    children: any;
}

export default function ImportantDetails(props: ImportantDetailsProperties) {
    const { t } = useTranslation('event');
    let children = props.children;

    return (<>
        <div className={"ImportantDetails"}>
            <div className={"ImportantDetails_Title"}>
                <Info/>
                <div className={"ImportantDetails_TitleText"}>
                    {t('importantDetails.importantDetailHeaderText')}
                </div>
            </div>
            <div className={"ImportantDetails_Body"}>
                {children}
            </div>
        </div>
    </>);
}