import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Button, { ButtonStyles } from "../common/Button";
import {
    isClubInAvaOrganization,
    isEmptyVirtualEventDetails,
    isEventSelfGuided,
    useWindowSize
} from "../../util/Util";
import {
    EventStatus,
    GetEventAndClubResponseBody,
    GetEventFileResponseBody
} from "../../services/MemberService";

import "./EventView.scss";
import PassionImage from "../passionImage/PassionImage";
import { Instant, LocalDate, ZoneId } from "@js-joda/core";
import i18n from "i18next";
import HaveQuestions from "../haveQuestions/HaveQuestions";
import LocationView from "../common/LocationView";
import Chip from "@material-ui/core/Chip";
import VirtualEventDetails, { NoVirtualEventDetails, VirtualEventDetailsProps } from "../common/VirtualEventDetails";
import MapLink from "../common/MapLink";
import LocationOn from "@material-ui/icons/LocationOn";
import { UserContext } from "../../contexts/UserContext";
import Email from "@material-ui/icons/Email";
import Grade from "@material-ui/icons/Grade";
import { EventFileList } from "../eventFile/EventFile";
import SelfGuidedEventText from "./SelfGuidedEventText";
import Done from "@material-ui/icons/Done";
import FeaturedEvents from "./FeaturedEvents";
import ListSelfGuidedIndicator from "../common/ListSelfGuidedIndicator";
import { EventType } from "../../util/EventType";
import { Link } from "react-router-dom";
import VirtualEventMedium from "../../util/VirtualEventMedium";
import { getClubPageRouteLink } from "../../util/ignite/routes.utils";
import { formatEventOccurrenceDateOnOrAfter, formatEventRecurrence , getEventOccurrenceDateOnOrAfter} from "../../util/Event.utils";
import { getZoomJoinUrl } from "../../api/getZoomJoinUrl";
import { formatEventTime } from "../../util/Event.utils";
interface EventViewProps {
    eventUrlFragment?: string;
    eventAndClub?: GetEventAndClubResponseBody
    isGoing: boolean;
    children?: any;
    rsvp: Function
    cancelReservation: Function
    className?: string
    source?: string;
    postalCode?: string;
}

export default function EventView(props: EventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    const { isAltMobile } = useWindowSize();
    const { user } = useContext(UserContext);
    const isLoggedIn = user !== null;
    const showAllEventDetails = isLoggedIn;
    const showEventLeader = showAllEventDetails;
    const showImportantDetails = showAllEventDetails;

    let eventAndClub: GetEventAndClubResponseBody | undefined = props.eventAndClub;
    let cancelled = eventAndClub?.event.status === EventStatus.CANCELLED;

    const isSelfGuided = !!eventAndClub && isEventSelfGuided(eventAndClub.event);
    const isAvaClub = !!eventAndClub && isClubInAvaOrganization(eventAndClub.club);


    const checkedProps: CheckedEventViewProps | undefined = props.eventAndClub && {
        ...props,
        eventAndClub: props.eventAndClub,
        isLoggedIn,
        showAllEventDetails,
        showEventLeader,
        showImportantDetails,
        isSelfGuided,
        isAvaClub,
    };

    return (
        <>
            {!checkedProps && <></>}
            {checkedProps && <>
                <div className={`EventView ${props.className || ''}`}>
                    {cancelled &&
                        <div className="EventView_CancelledWarning">
                            <div className="EventView_CancelledWarningTitle">
                                {t('eventView.eventCancelledTitle')}
                            </div>
                            <div className="EventView_CancelledWarningMessage">
                                {t('eventView.eventCancelledWarningMessage')}
                            </div>
                        </div>}
                    {checkedProps.eventAndClub.club.passion
                        && <PassionImage passion={checkedProps.eventAndClub.club.passion}
                            wide={!isAltMobile}
                            className={`EventView_Image`} />}
                    <div className={`EventView_Desktop`}>
                        <div className={"EventView_Desktop_Column"}>
                            <EventTitle {...checkedProps} />
                            <EventLocationAndTime {...checkedProps} />
                            <EventDetailsBody {...checkedProps} />
                            <VirtualEventWhereSection {...checkedProps} />
                            <WhoSection {...checkedProps} />
                            <ImportantDetailsSection {...checkedProps} />
                            <WhatYoullNeedSection {...checkedProps} />
                            <SelfGuidedSection {...checkedProps} />
                        </div>
                        <div className={"EventView_Desktop_Column Column2"}>
                            <RegisterButton {...checkedProps} />
                            <HaveQuestionsSection />
                            <FeaturedEvents mini={true}
                                excludeIds={[checkedProps.eventAndClub.event.eventId]} />
                        </div>
                    </div>
                    <div className={`EventView_Mobile`}>
                        <EventTitle {...checkedProps} />
                        <RegisterButton {...checkedProps} />
                        <EventLocationAndTime {...checkedProps} />
                        <EventDetailsBody {...checkedProps} />
                        <VirtualEventWhereSection {...checkedProps} />
                        <WhoSection {...checkedProps} />
                        <ImportantDetailsSection {...checkedProps} />
                        <WhatYoullNeedSection {...checkedProps} />
                        <SelfGuidedSection {...checkedProps} />
                        <HaveQuestionsSection />
                        <FeaturedEvents mini={true}
                            excludeIds={[checkedProps.eventAndClub.event.eventId]} />
                    </div>
                </div>
            </>}
        </>
    );
}

interface CheckedEventViewProps extends EventViewProps {
    eventAndClub: GetEventAndClubResponseBody;
    isLoggedIn: boolean;
    showAllEventDetails: boolean;
    showEventLeader: boolean;
    showImportantDetails: boolean;
    isSelfGuided: boolean;
    isAvaClub: boolean;
}

function EventTitle(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    const event = props.eventAndClub.event;
    const passion = props.eventAndClub.club.passion;
    let eventStartAtInstant = event.startsAtInstant;
    let eventEndAtInstant = event.endsAtInstant;
    let firstOccurence;
    if (event.isRecurring){
        firstOccurence = getEventOccurrenceDateOnOrAfter(event.occurrences, LocalDate.now());
        if (firstOccurence){
            eventStartAtInstant = firstOccurence.startsAtInstant;
            eventEndAtInstant = firstOccurence.endsAtInstant;
        }
    }
    
    const today = Instant.parse(eventStartAtInstant)
        .atZone(ZoneId.SYSTEM)
        .toLocalDate()
        .isEqual(LocalDate.now(ZoneId.SYSTEM));
    const date = new Date(eventStartAtInstant)
        .toLocaleDateString(i18n.language || 'en', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' });
    const start = formatEventTime(eventStartAtInstant, false);
    const end = formatEventTime(eventEndAtInstant,true);
 
    return (
        <div className={"EventView_EventTitle"}>
            <div className={"EventView_EventTitle_PassionName"}>{passion?.passionName}</div>
            <div className={"EventView_EventTitle_EventName"}>{event.eventName}</div>
            <div className={"EventView_EventTitle_DateLine"}>
                {today && !event.isRecurring && <Chip className={"EventView_EventTitle_DateLine_Today"} label={t('eventView.today')} />}
                {
                    !event.isRecurring && <div className={"EventView_EventTitle_DateLine_Date"}>{date}</div>
                }
                {
                    event.isRecurring &&
                    <span className={"EventView_EventTitle_Time"}>{formatEventRecurrence(event.recurrence)}&nbsp;</span>
                }
                <span className={"EventView_EventTitle_Time"}>{start} - {end}</span>
                {
                    event.isRecurring &&
                    <div className={"EventView_EventTitle_DateLine_Date"}>
                        <em>{t('eventRecurrence.nextMeeting')}</em>
                        <span>{formatEventOccurrenceDateOnOrAfter(event.occurrences, LocalDate.now())}</span>
                    </div>
                }
                {event.typeOne === EventType.SELF_GUIDED && <ListSelfGuidedIndicator />}
            </div>
        </div>
    )
}

function EventLocationAndTime(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    const showEventLeader = props.showEventLeader;
    const isVirtual = props.eventAndClub.event.isVirtual;
    const location = props.eventAndClub.event.location;
    return (
        <>
            {!isVirtual && location && <div className={"EventView_EventLocationAndTime"}>
                {showEventLeader && <LocationView location={location} />}
                {!showEventLeader && <LocationView locationCity={location?.city}
                    locationState={location?.countrySubdivision}
                    locationName={location?.locationName} />}
                <MapLink className={`WhereMapLink`}
                    location={location}><LocationOn />{t('eventViewWithDetails.mapItText')}</MapLink>
            </div>}
        </>
    )
}

function RegisterButton(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');

    return (
        <>
            {props.isGoing &&
                <div className={"EventView_Registered"}>
                    <Done className={"EventView_Registered_Icon"} />
                    {t('eventView.youAreGoingText')}
                </div>}

            {props.eventAndClub.club.isVirtual &&
                <Button type={"button"}
                    buttonStyle={ButtonStyles.FilledPrimary}
                    className={"EventView_RegisterButton"}
                    to={getClubPageRouteLink(true, props.eventAndClub.club.urlFragment)}
                    >                   
                    {t('eventView.manageReservationButtonText')}
                </Button>
            }

            {props.eventAndClub.club.isVirtual === false &&
                <Button type={"button"}
                    buttonStyle={ButtonStyles.FilledPrimary}
                    className={"EventView_RegisterButton"}
                    clickHandler={!props.isGoing ? props.rsvp : props.cancelReservation}>
                    {!props.isGoing
                        ? t('eventView.registerNow')
                        : t('eventView.cancelReservationButtonText')}
                </Button>
            }




        </>
    )
}

function HaveQuestionsSection(): JSX.Element {
    return (
        <HaveQuestions alwaysMobile={true} className={"EventView_HaveQuestionsSection"} />
    )
}

function EventDetailsBody(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    return (
        <TextBody className={"EventView_EventDetailsBody"}
            title={t('eventView.eventDetails')}
            body={props.eventAndClub.event.description} />
    )
}

function VirtualEventWhereSection(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    const { isLoggedIn } = useContext(UserContext);

    const virtualEventDetails = props.eventAndClub.event.virtualEventDetails;

    const [joinUrl, setJoinUrl] = useState(props.eventAndClub.event.virtualEventDetails?.url);

    useEffect(() => {
        getZoomJoinUrl(props.eventAndClub.event.urlFragment).then((response) => {
            setJoinUrl(response);
        });
        
    }, [props.eventAndClub.event.urlFragment])

    return (
        <>
            {props.eventAndClub.event.isVirtual
                && !isEmptyVirtualEventDetails(virtualEventDetails) &&
                <TextBody title={t('eventViewWithDetails.whereLabel')}>
                    {
                        virtualEventDetails
                            ? (isLoggedIn() && props.isGoing)
                                ? <VirtualEventDetails {...{
                                    medium: virtualEventDetails.medium, 
                                    url: joinUrl ?? virtualEventDetails.url, 
                                    urlPasscode: virtualEventDetails.urlPasscode, 
                                    dialInNumber: virtualEventDetails.dialInNumber,
                                    dialInPasscode: virtualEventDetails.dialInPasscode,
                                    meetingId: virtualEventDetails.meetingId
                                }}/>
                                : <VirtualEventWhereHiddenSection {...virtualEventDetails} />
                            : <NoVirtualEventDetails />
                    }
                </TextBody>
            }
        </>
    )
}

interface VirtualEventWhereHiddenSectionProps {
    medium: VirtualEventMedium
}

function VirtualEventWhereHiddenSection(props: VirtualEventWhereHiddenSectionProps): JSX.Element {
    const { t } = useTranslation('event');
    return (
        <div className={"EventView_VirtualEventWhereHiddenSection"}>
            {t(`eventViewWithDetails.virtualEventHidden.${props.medium}`)}
        </div>
    )
}

function WhoSection(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    return (
        <>
            <TextBody title={t('eventViewWithDetails.whoLabel')}>
                {
                    props.showEventLeader && <>
                        <div>
                            <div>
                                <Link to={getClubPageRouteLink(props.eventAndClub.club.isVirtual, props.eventAndClub.club.urlFragment)}
                                    className={"WhoSection_ClubLink"}>
                                    {props.eventAndClub.club.clubName}
                                </Link>

                                {"  "}
                                {props.eventAndClub.club.isVirtual === true && <Chip className={"EventView_Chip Virtual_Full"} label={t('eventViewWithDetails.virtualClub')}/>}

                               
                            </div>
                            <div><a
                                href={`mailto:${props.eventAndClub.event.leaderEmail}`}>
                                {props.eventAndClub.event.leaderFirstName} {props.eventAndClub.event.leaderLastName},
                                {' '}{t('eventViewWithDetails.eventLeaderText')}</a>
                            </div>
                        </div>
                        {props.eventAndClub.club.isVirtual !== true  &&
                        <a href={`mailto:${props.eventAndClub.event.leaderEmail}`}><Email />{t('eventViewWithDetails.emailEventLeaderText')}</a> }
                    </>
                }
                {!props.showEventLeader &&
                    <div>
                        <div>{props.eventAndClub.club.description}</div>
                    </div>
                }
            </TextBody>
        </>
    )
}

function ImportantDetailsSection(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    return (
        <>
            {
                props.showImportantDetails && props.eventAndClub.event.importantDetails &&
                <TextBody title={t('importantDetails.importantDetailHeaderText')}>
                    {props.eventAndClub.event.importantDetails}
                </TextBody>
            }
        </>
    )
}

function WhatYoullNeedSection(props: CheckedEventViewProps): JSX.Element {
    const { t } = useTranslation('event');
    let [eventFiles, setEventFiles] = useState<GetEventFileResponseBody[] | null>(null);
    return (
        <>
            {
                props.showImportantDetails &&
                <TextBody title={t('eventViewWithDetails.eventFileListTitle')}
                    className={`WhatYoullNeedSection ${(eventFiles?.length || 0) > 0 ? "" : "Hidden"}`}>
                    <EventFileList instructions={t('eventViewWithDetails.eventFileListInstructions')}
                        icon={<Grade />}
                        showAllEventFiles={false}
                        setEventFiles={setEventFiles}
                        eventUrlFragment={props.eventAndClub.event.urlFragment} />
                </TextBody>

            }
        </>
    )
}

interface TextBodyProps {
    className?: string;
    title: string;
    body?: string;
    children?: any;
}

function TextBody(props: TextBodyProps): JSX.Element {
    return (
        <div className={`EventView_TextBody ${props.className || ''}`}>
            <div className={"EventView_TextBody_Title"}>{props.title}</div>
            <div className={"EventView_TextBody_Description"}>{props.body || props.children}</div>
        </div>
    )
}

function SelfGuidedSection(props: CheckedEventViewProps): JSX.Element {
    return (
        <>
            {props.isSelfGuided && props.isAvaClub && <>
                <div className="SelfGuidedMessage"><SelfGuidedEventText
                    eventClubUrl={props.eventAndClub.event.externalUrl} /></div>
            </>}
        </>
    )
}
