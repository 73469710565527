import React from "react";

const mobileMenuToggle = require("../../assets/mobile_menu_toggle.svg");

interface MobileMenuToggleProps {
  className?: string
}

export default function MobileMenuToggle(props: MobileMenuToggleProps): JSX.Element {
  return <img className={`MobileMenuToggle ${props.className || ''}`} src={mobileMenuToggle} />
}
