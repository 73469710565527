import { POSTAL_CODE_REGEX, PHONE_REGEX, EMAIL_REGEX } from '../../../constants/regex'
import { getPhoneUnmask } from '../../../util/ignite/MyInfo.utils';

const getElementConfig = (t: Function, formState: any) => {
    const confirmEmailValidate = (value: string) => {
        if (!value) {
            return t('registrationForm.errors.email');
        }
        if (value?.toLowerCase() !== formState.values.email?.toLowerCase()) {
            return t('registrationForm.errors.confirmEmail');
        }
    };

    const confirmPasswordValidate = (value: string) => {
        if (value !== formState.values.password) {
            return t('registrationForm.errors.confirmPassword');
        }
    };

    return ({
        firstName: {
            name: "firstName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t('registrationForm.errors.firstNameRequired');
                }
            },
            validateOnBlur: true,
            onBlur: () => formState.values.firstName.trim() && formState.setField('firstName', formState.values.firstName.trim())
        },
        lastName: {
            name: "lastName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t('registrationForm.errors.lastNameRequired');
                }
            },
            validateOnBlur: true,
            onBlur: () => formState.values.lastName.trim() && formState.setField('lastName', formState.values.lastName.trim())
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                if (!value.match(POSTAL_CODE_REGEX)) {
                    return t('registrationForm.errors.zipCode');
                }
            },
            validateOnBlur: true,
            onBlur: () => {
                const value = formState.values.zipCode.trim()

                value && formState.setField('zipCode', value)
                if (!value.match(POSTAL_CODE_REGEX))
                    formState.setFieldError('zipCode', t('registrationForm.errors.zipCode'));
            }
        },
        phoneNumber: {
            name: 'phoneNumber',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)

                if (!formattedValue.match(PHONE_REGEX)) {
                    return t('registrationForm.errors.phoneNumber');
                }
            },
            validateOnBlur: true
        },
        email: {
            name: 'email',
            validate: (value: string) => {
                const errorMessageConfirmEmail = confirmEmailValidate(formState.values.confirmEmail);

                if (errorMessageConfirmEmail && formState.touched.confirmEmail) {
                    formState.setFieldError('confirmEmail', errorMessageConfirmEmail);
                } else if (formState.touched.confirmEmail) {
                    formState.setField('confirmEmail', formState.values.confirmEmail)
                }

                if (!value.match(EMAIL_REGEX)) {
                    return t('registrationForm.errors.email');
                }
            },
            validateOnBlur: true,
            onBlur: function () {
                const value = formState.values.email.trim()
                value && formState.setField('email', value)

                const errorMessage = this.validate(value)

                if (errorMessage) formState.setFieldError('email', errorMessage);
            }
        },
        confirmEmail: {
            name: 'confirmEmail',
            validate: confirmEmailValidate,
            validateOnBlur: true,
            onBlur: () => {
                const value = formState.values.confirmEmail.trim()

                const errorMessageConfirmEmail = confirmEmailValidate(value);

                value && formState.setField('confirmEmail', value)

                if (errorMessageConfirmEmail) formState.setFieldError('confirmEmail', errorMessageConfirmEmail);
            }
        },
        password: {
            name: 'password',
            validate: (value: string) => {
                const errorMessageConfirmPassword = confirmPasswordValidate(formState.values.confirmPassword);

                if (errorMessageConfirmPassword && formState.touched.confirmPassword) {
                    formState.setFieldError('confirmPassword', errorMessageConfirmPassword);
                } else if (formState.touched.confirmPassword) {
                    formState.setField('confirmPassword', formState.values.confirmPassword)
                }

                const trimmedInput = value.trim();
                if (trimmedInput.length < 8) {
                    return t('registrationForm.errors.passwordAtLeast8Characters');
                }
                if (trimmedInput.search(/[A-Z]/) === -1) {
                    return t('registrationForm.errors.passwordAtLeastOneUpperLetter');
                }
                if (trimmedInput.search(/[0-9]/) === -1) {
                    return t('registrationForm.errors.passwordAtLeastOneNumber');
                }
            },
            validateOnBlur: true,
            onBlur: function () {
                const value = formState.values.password.trim()
                value && formState.setField('password', value)
                const errorMessage = this.validate(value)

                if (errorMessage) formState.setFieldError('password', errorMessage)
            }
        },
        confirmPassword: {
            name: 'confirmPassword',
            validate: confirmPasswordValidate,
            validateOnBlur: true,
            onBlur: () => {
                const value = formState.values.confirmPassword.trim()
                value && formState.setField('confirmPassword', value)
                const errorMessage = confirmPasswordValidate(value)

                if (errorMessage) formState.setFieldError('confirmPassword', errorMessage)
            }
        },
        termsOfUse: {
            name: 'termsOfUse'
        },
    });
};

export { getElementConfig }
