// @ts-nocheck
import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import "./VirtualClubsClubDetailPage.scss";

import Loading
 from "../../components/common/Loading";
// Material UI
import {
    Avatar,
    Button,
    Link,
    Popover
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";

import {
    usePopupState,
    bindTrigger,
    bindPopover
} from 'material-ui-popup-state/hooks';

// JS-Joda
import {
    Instant,
    LocalDate,
    LocalTime,
    ZonedDateTime,
    ZoneId
} from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import { LocalDateUtils } from "../../util/FixedJsJodaUtils";

import { getClubEventsByDateRange } from "../../api/getClubEventsByDateRange";
import getClubMemberCount from "../../api/getClubMemberCount";
import { getEventsForClubAndMemberInDateRange } from "../../api/getEventsForClubAndMemberInDateRange";

import postCancelRsvpEvent from "../../api/postCancelRsvpEvent";
import postJoinClub from "../../api/postJoinClub";
import postLeaveClub from "../../api/postLeaveClub";
import postRsvpEvent from "../../api/postRsvpEvent";

import useGetClubByUrlFragment from "../../hooks/useGetClubByUrlFragment";

import getVirtualClubAssociationContentViaSanity from "../../sanity/getVirtualClubAssociationContentViaSanity";
import getUserViaSanity, { UserSanityData, UserSanityResponse } from "../../sanity/getUserContentViaSanity";

// Components
import Carousel from "react-elastic-carousel";
import GalleryFullscreenModal from "../../components/virtualClubs/GalleryFullscreenModal";
import InstructorBioModal from "../../components/virtualClubs/InstructorBioModal";
import JoinClubModal from '../../components/virtualClubs/JoinClubModal';
import VirtualClubEventButtonList from '../../components/virtualClubs/VirtualClubEventButtonList';
import VirtualClubEventLiveNow from "../../components/virtualClubs/VirtualClubEventLiveNow";

// Types
import { UserContext } from "../../contexts/UserContext";
import { EventStatus, GetClubLeaderEventOrOccurrenceResponseBody } from "../../services/ClubLeaderService";

/**
 * 
 * VirtualClubsClubPage
 * route: /virtual-clubs/club/:clubUrlFragment
 * jira: https://element3health.atlassian.net/browse/EDP-140
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&mode=design
 * 
 * Page for all details + signup for a given Virtual Club
 * 
 */


interface IUserData {
    sanityData?: UserSanityData;
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string
}

export default function VirtualClubsClubDetailPage(): JSX.Element {
    const { t } = useTranslation('pages');

    const [showJoinClubModal, setShowJoinClubModal] = useState(false);
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
    const [showFullscreenGalleryImageModal, setShowFullscreenGalleryImageModal] = useState(false);
    const [fullscreenGalleryImageUrl, setFullscreenGalleryImageUrl] = useState('');
    const [showInstructorBioModal, setShowInstructorBioModal] = useState(false);
    const [instructorForBioModal, setInstructorForBioModal] = useState(null);
    const [loading, setLoading] = useState<boolean>(false);
    const params = useParams();
    const [club] = useGetClubByUrlFragment(params.clubUrlFragment);
    const [sanityClubData, setSanityClubData] = useState({});
    const [allClubLeaders, setAllClubLeaders] = useState([]);
    const [allSanityInstructorData, setAllSanityInstructorData] = useState({});
    const [memberCount, setMemberCount] = useState(0);
    const [isClubMember, setIsClubMember] = useState(false);
    const [isLeaveClubMenuOpen, setIsLeaveClubMenuOpen] = useState(false);
    const [userHasLeadershipRole, setUserHasLeadershipRole] = useState(false);
    const { user } = useContext(UserContext);

    // status in each of these is 1 if rsvpd and 2 if cancel rsvpd - if user never did either, they are not going to have an entry here
    const [userEventsInClub, setUserEventsInClub] = useState<GetEventAndClubAndUserEventRelationshipResponseBody[]>([])  

    const [eventsInRange, setEventsInRange] = useState<GetClubLeaderEventResponseBody[]>([]);
    const [searchStartDate, setSearchStartDate] = useState<LocalDate | DateTimeParseException | null>(LocalDate.now());
    const [searchEndDate, setSearchEndDate] = useState<LocalDate | DateTimeParseException | null>(LocalDate.now().plusMonths(1));

    // Override disabled button styling
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                '&.Mui-disabled': {
                    color: '#0177C0',
                    border: '1px solid #0177C0'
                }
            }
        }),
    );
    const muiClasses = useStyles();

    const getEventsForMember = async (club) => {
        const zone = ZoneId.of(club.timeZone);
        const searchStart = searchStartDate !== null ? ZonedDateTime.of(searchStartDate, LocalTime.MIN, zone).toInstant().toString() : Instant.now().toString();
        const searchEnd = searchEndDate !== null ? ZonedDateTime.of(searchEndDate, LocalTime.MAX, zone).toInstant().toString() : null;
        const eventsForMemberInClub = await getEventsForClubAndMemberInDateRange(club.id, searchStart, searchEnd);

        if (eventsForMemberInClub) {
            setUserEventsInClub(eventsForMemberInClub);
        }
    }

    const getMemberCount = async (club) => {
        const numMembers = await getClubMemberCount(club.id);
        setMemberCount(numMembers.count);
    }

    const afterEventRSVPReset = () => {
        async function load() {
            await getEventsForMember(club);
        }
        load().then();
    }

    useEffect(() => {
        let controller: any = new AbortController();
        async function load() {
            if (club) {
                if (club.memberCount){
                    setMemberCount(club.memberCount);
                }
                if (club.isCurrentUserMember){
                    setIsClubMember(club.isCurrentUserMember);
                }
                if (club.isCurrentUserLeader){    
                    setUserHasLeadershipRole(club.isCurrentUserLeader)
                }
           
                await getEventsForMember(club);
    
                let clubLeaders = []
                if (club.primaryClubLeaders) {
                    for (const i in club.primaryClubLeaders) {
                        clubLeaders.push(club.primaryClubLeaders[i]);
                    }
                }
                if (club.deputyClubLeaders) {
                    for (const i in club.deputyClubLeaders) {
                        clubLeaders.push(club.deputyClubLeaders[i]);
                    }
                }
                setAllClubLeaders(clubLeaders);

                const sanityInfo = await getVirtualClubAssociationContentViaSanity(club.shortCode)
                if (sanityInfo && sanityInfo.result && sanityInfo.result.length > 0) {
                    setSanityClubData(sanityInfo.result[0]);
                }
            }
            controller = null;
        }
        load().then();
        return () => controller?.abort();
    }, [club, setMemberCount, setIsClubMember,setUserHasLeadershipRole])


    useEffect(() => {
        let controller: any = new AbortController();

        async function load() {
            let sanityInstructorDataCopy = {};
            for (var leader of allClubLeaders) {
                if (leader.email && !sanityInstructorDataCopy.hasOwnProperty(leader.email)) {
                    var person: IUserData = { ...leader }

                    const sanityInfo: UserSanityResponse = await getUserViaSanity(leader.email);
                    if (sanityInfo && sanityInfo.result && sanityInfo.result.length > 0) {
                        person.sanityData = sanityInfo.result[0];
                    }
                    sanityInstructorDataCopy[leader.email] = person;

                }
            }

            setAllSanityInstructorData(sanityInstructorDataCopy);
            controller = null;
        }
        load().then();
        return () => controller?.abort();
    }, [allClubLeaders])


    /******************************************************************** 
     * Join Club Modal
    ********************************************************************/
    const openJoinClubModalOrLeaveClub = async () => {
        if (userHasLeadershipRole) {
            // Cannot join or leave club if you are the leader
            return;
        }
        // code to pop open Join Club modal - https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=445-6236&mode=design&t=vq7tlYO4Am9o0uoQ-0
        if (club) {
            if (isClubMember) {
                setLoading(true);
                try {
                    await postLeaveClub(Number(club.id))   
                    setIsClubMember(false);
                    await getMemberCount(club);
    
                    let clubEvents: GetClubLeaderEventOrOccurrenceResponseBody[] = eventsInRange
                    /* actually should get my events and cancel those for this club */
                    for (var i = 0; i < clubEvents.length; i++) {
                        try {
                            await postCancelRsvpEvent(clubEvents[i].urlFragment);
                            afterEventRSVPReset();
                        } catch {
    
                        }
                    }
                    await getEventsForMember(club);
    
                } catch{
              
                }
                setLoading(false)
                popupState.close();
            } else {
                setShowJoinClubModal(true);
            }
        }
    }

    const joinClubWithEvents = async (eventUrlFragmentsToSignUpFor: any, eventReminders: Boolean) => {
        if (club) {
            setLoading(true);
            setShowJoinClubModal(false)
            try {
                await postJoinClub(Number(club.id))  
                setIsClubMember(true);
                await getMemberCount(club);
                await getEventsForMember(club);

                for (var eventUrlFragment in eventUrlFragmentsToSignUpFor) {
                    try {
                        await postRsvpEvent(eventUrlFragment);
                    }
                    catch {
                    }
                   
                    afterEventRSVPReset();
                }

            } catch {

            }
            setLoading(false);
            setShowJoinClubModal(false)

        } else {
            setLoading(false);
            setShowJoinClubModal(false)
        }
    }
  
    /******************************************************************** 
     * Activity Search
    ********************************************************************/

    useEffect(() => {
        if (!!club && !isSearchStartDateError() && (searchEndDate === null || searchEndDate instanceof LocalDate)) {
            const zone = ZoneId.of(club.timeZone);
            const searchStart = searchStartDate !== null ? ZonedDateTime.of(searchStartDate, LocalTime.MIN, zone).toInstant().toString() : Instant.now().toString();
            const searchEnd = searchEndDate !== null ? ZonedDateTime.of(searchEndDate, LocalTime.MAX, zone).toInstant().toString() : null;
            getEventsForClubAndMemberInDateRange(club.id, searchStart, searchEnd).then((events) => {
                if (!!events) {
                    setUserEventsInClub(events);
                }
            });
            getClubEventsByDateRange(club.id, null, EventStatus.ACTIVE, searchStart, searchEnd).then((events) => {
                if (!!events) {
                    setEventsInRange(events);
                }
            });
        }
    }, [club, searchStartDate, searchEndDate])

    function isSearchStartDateError() {
        return !searchStartDate || !(searchStartDate instanceof LocalDate) || searchStartDate < LocalDate.now().toString();
    }

    function searchStartDateErrorText() {
        if (!searchStartDate) {
            return t("virtualClubsClubDetailPage.requiredLabel");
        } else if (!(searchStartDate instanceof LocalDate)) {
            return "MM/DD/YYYY";
        } else if (searchStartDate < LocalDate.now().toString()) {
            return t("virtualClubsClubDetailPage.pastDateError");
        } else {
            return "";
        }
    }

    /******************************************************************** 
     * Instructor Bio Modal
    ********************************************************************/
    const openInstructorBioModal = (instructorIndex: number) => {
        // code to open the club instructor bio modal - https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=296-3917&mode=design&t=QNk7tLGLfXKPFWNE-0
        let instructorData = { 'apiData': {}, 'sanityData': {} };
        instructorData.apiData = allClubLeaders[instructorIndex];
        let email = allClubLeaders[instructorIndex]['email'];
        instructorData.sanityData = allSanityInstructorData[email];
        setInstructorForBioModal(instructorData);
        setShowInstructorBioModal(true);
    }

    const closeInstructorBioModal = () => {
        setShowInstructorBioModal(false);
    }

    const clickableInstructor = (instructor, index) => {
        return (
            <div
                key={index}
                className="clubInstructor"
                onClick={() => openInstructorBioModal(index)}
            >

                {(allSanityInstructorData[instructor.email] && allSanityInstructorData[instructor.email].sanityData && allSanityInstructorData[instructor.email].sanityData.avatar) ? (
                    <div className="clubInstructor_biopic">
                        <Avatar className="MuiAvatar_100px MuiAvatar_centered" alt={instructor.firstName + ' ' + instructor.lastName} src={allSanityInstructorData[instructor.email].sanityData.avatar} />
                    </div>
                ) : (
                    <div className="clubInstructor_biopic">
                        <Avatar className="MuiAvatar_100px MuiAvatar_centered" alt={instructor.firstName + ' ' + instructor.lastName} >
                            <div style={{ backgroundColor: "rgba(25, 118, 210, 0.08)", width: "100%", height: "100%" }}></div>
                        </Avatar>
                    </div>
                )}
                <p className="title">{instructor.firstName + ' ' + instructor.lastName}</p>

            </div>
        )
    }


    /******************************************************************** 
     * Gallery / Gallery Fullscreen Modal
    ********************************************************************/
    const memberGalleryRef = useRef<any>();
    const galleryCarouselRef = useRef<any>();
    const galleryCarouselBreakpoints = [
        { width: 1, itemsToShow: 1 },
        { width: 320, itemsToShow: 2 },
        { width: 600, itemsToShow: 4 },
        { width: 750, itemsToShow: 5 }
    ];
    const openFullscreenGalleryImageModal = (imageUrl) => {
        setFullscreenGalleryImageUrl(imageUrl);
        setShowFullscreenGalleryImageModal(true);
    }
    const closeFullscreenGalleryImageModal = () => {
        setShowFullscreenGalleryImageModal(false);
    }

    useEffect(() => {
        setTimeout(() => {
            const queryParams = new URLSearchParams(window.location.search);
            const sectionQueryParam = queryParams.get("section");
            if (sectionQueryParam && sectionQueryParam === "gallery" && !!memberGalleryRef) {
                window.scrollTo({top: memberGalleryRef?.current?.offsetTop ?? 0, behavior: "smooth"});
            }
        }, 1000);
    }, [sanityClubData])

    /******************************************************************** 
     * Testimonial Carousel
    ********************************************************************/
    const testimonialCarouselRef = useRef<any>();
    const testimonialCarouselBreakpoints = [
        { width: 1, itemsToShow: 1 },
        { width: 400, itemsToShow: 2 },
        { width: 600, itemsToShow: 3 }
    ];
    
    if (loading) {
        return <Loading loading={loading}/>
    }
    return (
        
        <div className="VirtualClubsClubDetailPage">
            {/* Join / Leave Club Modal */}
    
            {!!club && !loading  &&  <JoinClubModal
                open={showJoinClubModal}
                onClose={() => {
                    setShowJoinClubModal(false);
                }}

                joinClubWithEvents={joinClubWithEvents}
                club={club}
                memberEmailAddress={user?.email}
                eventsInRange={eventsInRange}
                afterEventRSVPReset={afterEventRSVPReset}
                searchStartDate={searchStartDate}
                searchEndDate={searchEndDate}
            />}

            {/* Instructor Bio Modal */}
            {(allClubLeaders && allClubLeaders.length > 0)
                ? (
                    <InstructorBioModal
                        openModal={showInstructorBioModal}
                        closeModalClickHandler={closeInstructorBioModal}
                        instructorDataObject={instructorForBioModal}
                        currentClubId={club.id}
                    />
                ) : null
            }

            {/* Gallery Modal */}
            <GalleryFullscreenModal
                openModal={showFullscreenGalleryImageModal}
                closeModalClickHandler={closeFullscreenGalleryImageModal}
                galleryImageURL={fullscreenGalleryImageUrl}
                clubName={club?.clubName}
            />
            
            {/* THE ACTUAL PAGE MARKUP */}
            <div className="ClubDetail_Container">
                <Link
                    className="goBack"
                    underline="always"
                    href={(club) ? "/virtual-clubs/categories/" + club?.terms[0]?.id : "#"}>
                    {t("virtualClubsClubDetailPage.backToTermText") + " " + club?.terms[0]?.termName}
                </Link>
                <div className="flex-columns">
                    <h1>{club?.clubName}</h1>

                    <div className="floatRight strong">
                        <span>{memberCount} {t("virtualClubsClubDetailPage.membersText")}</span>
                        {
                            isClubMember ?
                                <Button
                                    id="VirtualClub_JoinedButton"
                                    className="VirtualClub_MUIButton_outlined join-leave-club-menu"
                                    variant="outlined"
                                    size="large"
                                    onClick={() => { setIsLeaveClubMenuOpen(!isLeaveClubMenuOpen) }}
                                    disabled={userHasLeadershipRole}
                                    classes={{ root: muiClasses.root }}
                                    {...bindTrigger(popupState)}
                                >
                                    {t("virtualClubsClubDetailPage.joinedClubButton")}
                                    {
                                        !userHasLeadershipRole &&
                                        <div>
                                            {popupState.isOpen ? <ArrowUpIcon className="icon" /> :
                                                <ArrowDownIcon className="icon" />}
                                        </div>
                                    }
                                </Button> :
                                <Button
                                    className="VirtualClub_MUIButton_outlined join-leave-club-menu"
                                    variant="outlined"
                                    size="large"
                                    onClick={openJoinClubModalOrLeaveClub}
                                >
                                    {t("virtualClubsClubDetailPage.joinClubButton")}
                                </Button>
                        }
                    </div>

                    <Popover {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Button className="join-leave-club-menu"
                            onClick={openJoinClubModalOrLeaveClub}>
                            {t("virtualClubsClubDetailPage.leaveClubButton")}
                        </Button>
                    </Popover>
                </div>

                {sanityClubData && sanityClubData.clubDownloadableFileURL && (
                    <Link
                        className="fileDownload"
                        underline="always"
                        download
                        target="_blank"
                        href={sanityClubData.clubDownloadableFileURL}>
                        {t("virtualClubsClubDetailPage.viewClubCalendarText")} &amp; {t("virtualClubsClubDetailPage.activityDescriptionsText")}
                    </Link>
                )}

                <div className="roundedOutlined eventInfo">
                    <VirtualClubEventLiveNow events={eventsInRange} />
                    <h2 className="eventInfo_title">
                        {t("virtualClubsClubDetailPage.clubActivitiesLabel")}  
                        <div className="eventInfo_dateRange">
                            <div>
                                <MuiPickersUtilsProvider
                                    utils={LocalDateUtils}
                                    locale={Locale.US}>
                                    <KeyboardDatePicker
                                        value={searchStartDate}
                                        label={t("virtualClubsClubDetailPage.fromLabel")}
                                        minDate={new Date()}
                                        error={isSearchStartDateError()}
                                        helperText={searchStartDateErrorText()}
                                        inputVariant="outlined"
                                        format="MM/dd/yyyy"
                                        required
                                        onChange={(x) => { setSearchStartDate(x) }} 
                                        />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div>
                                <MuiPickersUtilsProvider
                                    utils={LocalDateUtils}
                                    locale={Locale.US}>
                                    <KeyboardDatePicker
                                        value={searchEndDate}
                                        label={t("virtualClubsClubDetailPage.toLabel")}
                                        minDate={new Date()}
                                        inputVariant="outlined"
                                        format="MM/dd/yyyy"
                                        onChange={(x) => { setSearchEndDate(x) }} />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </h2>
                    <h3 className="eventInfo_subtitle">
                        {isClubMember ? t("virtualClubsClubDetailPage.clubActivitiesSignMeUp") : t("virtualClubsClubDetailPage.clubActivitiesJoinClubToSignMeUp")}
                    </h3>
                    <VirtualClubEventButtonList
                        events={eventsInRange}
                        userEventsInClub={userEventsInClub}
                        afterEventRSVPReset={afterEventRSVPReset}
                        nextOccurrenceOnOrAfter={searchStartDate}
                        isClubMember={isClubMember}
                    />
                </div>

                <div className="clubInfo">
                    <div
                        aria-label={t("virtualClubsClubDetailPage.clubInfoAriaHeroLabel") + " " + club?.clubName}
                        className="clubInfo_heroImage"
                        style={{ backgroundImage: "url(" + sanityClubData?.associationImage + ")" }}
                    />
                    <p>{club?.description}</p>
                    {club && sanityClubData && sanityClubData.materialsNeededEnabled && (
                        <>
                            <h3>{t("virtualClubsClubDetailPage.materialsNeededText")}</h3>
                            <p>{sanityClubData.materialsNeeded}</p>
                        </>
                    )}

                    <h4>{t("virtualClubsClubDetailPage.clubInstructorsText")}</h4>
                    <div className="clubInstructor_container">
                        {allClubLeaders && allClubLeaders.length > 0 && allSanityInstructorData && Object.keys(allSanityInstructorData).length > 0 && (
                            <>
                                {
                                    allClubLeaders.map((instructor, index) => (
                                        clickableInstructor(instructor, index, true)
                                    ))
                                }
                            </>
                        )}
                    </div>

                    {sanityClubData && sanityClubData.memberGalleryEnabled && !!sanityClubData.memberGallery && (<>
                        <div className="fz-24 weight-700 pb-16 color-e3blue" ref={memberGalleryRef}>
                            {t("virtualClubsClubDetailPage.memberGalleryText")}
                        </div>
                        <Carousel
                                isRTL={false}
                                breakPoints={galleryCarouselBreakpoints}
                                showEmptySlots
                                ref={galleryCarouselRef}
                                className="recCarousel"
                            >
                                {
                                    sanityClubData.memberGallery.map((gallery, index) => (
                                        <div
                                            key={index}
                                            className="galleryBlock"
                                        >
                                            <div
                                                className="galleryImage"
                                                style={{ backgroundImage: "url('" + sanityClubData.memberGalleryImages[index] + "')" }}
                                                onClick={() => openFullscreenGalleryImageModal(sanityClubData.memberGalleryImages[index])}
                                            ></div>
                                            <p className="galleryBody">{gallery.caption || ''}</p>
                                        </div>
                                    ))
                                }
                            </Carousel>
                       
                    </>)}


                    
               
                    {sanityClubData && sanityClubData.whatOurMembersAreSayingEnabled && (
                        <div>
                            <div className="fz-24 weight-700 pb-24 color-e3blue">
                                {t("virtualClubsClubDetailPage.testimonialsText")}
                            </div>

                            <Carousel
                                isRTL={false}
                                breakPoints={testimonialCarouselBreakpoints}
                                showEmptySlots
                                ref={testimonialCarouselRef}
                                className="recCarousel testimonials"
                            >
                                {
                                    sanityClubData.whatOurMembersAreSaying.map((testimonial, index) => (
                                        <div
                                            key={index}
                                            className="testimonialBlock"
                                        >   
                                            {  sanityClubData.whatOurMembersAreSayingImages[index] ?
                                            <div>
                                            <div
                                                className="testimonialImg"
                                                style={{ backgroundImage: "url('" + sanityClubData.whatOurMembersAreSayingImages[index] + "')" }}
                                            ></div>
                                            <p className="testimonialBody">&ldquo;{testimonial.caption?.en}&rdquo;</p>
                                            </div> : <div>
                                            <p className="testimonialBody">&ldquo;{testimonial.caption?.en}&rdquo;</p>
                                            </div>}
                                        </div>
                                    ))
                                }
                            </Carousel>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}
