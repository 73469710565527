
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { IEligibility } from './getEligibilityStatus';

export const UPDATE_PROFILE_HEALTH_PLAN_URL = '/my-profile/health-care'

const postUpdateProfileHealthPlan = async (body: Partial<IEligibility>) => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_PROFILE_HEALTH_PLAN_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postUpdateProfileHealthPlan
