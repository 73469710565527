import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';

export default function TermsOfUsePage(): JSX.Element {
    const { t } = useTranslation('pages');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={"TermsOfUsePage"} dangerouslySetInnerHTML={{__html: t('termsOfUsePage.content')}}></div>
        </>
    )
}
