import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';

import "./EventViewFullDesktop.scss";
import {ButtonStyles} from "../common/Button";
import StandardDateView from "../common/StandardDateView";
import PlayButton from "../common/PlayButton";
import {UserContext} from "../../contexts/UserContext";
import {isEventTypeSelfGuided} from "../../util/Util";
import SelfGuidedEventTextShort from "../event/SelfGuidedEventTextShort";

interface EventViewFullDesktopProps {
    eventDescription: string;
    eventUrlFragment: string;
    eventName: string;
    eventDate: string | Date;
    clubName: string;
    clubUrlFragment: string;
    buttonText: string;
    buttonClickHandler: Function;
    className?: string;
    eventType: string | null;
}


export default function EventViewFullDesktop(props: EventViewFullDesktopProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let eventName = props.eventName;
    let eventDate = props.eventDate;
    let clubName = props.clubName;
    let className = props.className;
    let buttonClickHandler = props.buttonClickHandler;
    let buttonText = props.buttonText;
    let eventType = props.eventType;

    const {user, hasLeadershipRole} = useContext(UserContext);

    let classes = 'CCEventViewFullDesktop';
    if (className !== undefined) {
        classes += ' ' + className;
    }

    const isSelfGuided = isEventTypeSelfGuided(eventType);

    return (
        <div className={`CCEventViewFullDesktop ${classes}`}>
            <div className={`CCEventViewFullDesktop_Presents`}>
                {t('eventViewFull.onDate')} <StandardDateView date={eventDate}/>,<br/>
                {t('eventViewFull.presents', {clubName: clubName})}
            </div>
            <div className={`CCEventViewFullDesktop_EventName`}>
                {eventName}
            </div>
            <div className={`CCEventViewFullDesktop_EventDescription`}>
                {props.eventDescription}
            </div>
            {isSelfGuided && <>
                <div className={`CCEventViewFullDesktop_SelfGuided`}>
                    <SelfGuidedEventTextShort/>
                </div>
            </>}
            {!hasLeadershipRole(props.clubUrlFragment) &&
            <PlayButton buttonStyle={ButtonStyles.Unfilled} clickHandler={buttonClickHandler}
                        text={buttonText} className={`CCEventViewFullDesktop_CountMeInButton`}/>}
        </div>
    );
}