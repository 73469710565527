import React, { useState, useEffect } from "react";
import Pagination from '../layout/Pagination'
import { IActivityItem } from '../../../types/dashboard'
import Badge from "./Badge";
import { memberDashboardConfig } from "../../../configs/memberDashboard";

import './Leaderboard.scss'

interface ILeaderboardProps {
    list: IActivityItem[]
    totalCount?: number
    fetchLeaderboard: Function
}

export const Leaderboard = ({
    list,
    totalCount,
    fetchLeaderboard
}: ILeaderboardProps): JSX.Element => {
    const [page, setPage] = useState(1)

    const onChange = (page: number) => {
        const skip = (page * memberDashboardConfig.ITEMS_PER_PAGE_IN_LEADERBOARD) - memberDashboardConfig.ITEMS_PER_PAGE_IN_LEADERBOARD
        fetchLeaderboard(skip)
        setPage(page)
    }

    return (
        <>
            <div className="leaderboard-list">
                {list.map((member: IActivityItem) => {
                    return (
                        <div
                            className="leaderboard-item"
                            key={`${member.userId}`}
                        >
                            <div className="leaderboard-item__start">
                                <div className="leaderboard-item__place">
                                    {member.ranking}
                                </div>
                                <div className="leaderboard-item__name">
                                    {`${member.firstName} ${member.lastName}`}
                                </div>
                            </div>
                            <div className="leaderboard-item__end">
                                <div className="leaderboard-item__medals">
                                    {member.badgesEarned?.map(
                                        badgeData => <Badge slug={badgeData.key} key={badgeData.key} />
                                    )}
                                </div>
                                <div className="leaderboard-item__rank">
                                    {member.score}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <Pagination
                className='pagination'
                pageSize={memberDashboardConfig.ITEMS_PER_PAGE_IN_LEADERBOARD}
                totalCount={totalCount}
                onPageChange={onChange}
                currentPage={page}
            />
        </>
    )
}
