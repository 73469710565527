import React, { useState, useEffect } from "react";
import NavigationElement from "./NavigationElement";
import {
  usePopupState,
  bindHover,
  bindPopover
} from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import { RouteComponentProps, withRouter } from "react-router-dom";

interface NavigationDropdownElementProps extends RouteComponentProps {
  title: String;
  menuClassName?: string;
  childURLs?: Array<string>;
  children?: JSX.Element[];
}

function NavigationDropdownElement(props: NavigationDropdownElementProps): JSX.Element {

  const [topLevelClasses, setTopLevelClasses] = useState("");
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

  /* both inner submenu nav elements and this top level element need to know when they are the current item.
     we pass in the prop 'childURLs', an array of all child URLs. hack, please help me find a better solution! */
  useEffect(() => {
    if (props.childURLs) {
      for (var i in props.childURLs) {
        if (props.childURLs[i] == props.location.pathname || props.location.pathname.startsWith(String(props.childURLs[i]))) {
          setTopLevelClasses("NavigationElement_Current");
        }
      }
    }
  }, [props])

  return (
    <div >
      <div
        aria-haspopup="true"
        {...bindHover(popupState)}
      >
        <NavigationElement className={topLevelClasses}>{props.title}</NavigationElement>
      </div>
      <HoverPopover
        {...bindPopover(popupState)}
        onClick={popupState.close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

        className={props.menuClassName}
      >
        {props.children}
      </HoverPopover>
    </div>
  );
}

export default withRouter(NavigationDropdownElement);
