import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { IClubStats } from '../types/dashboard'

export const GET_CLUB_STATS_URL = '/ignite/leader/get-club-stats/'

const getClubStats = async (clubId: number): Promise<IClubStats | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_CLUB_STATS_URL}${clubId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    } else {
        const error = await response?.text()

        throw new Error(error)
    }
}

export default getClubStats
