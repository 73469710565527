import React from "react";
import "./ListSeparator.scss";

interface ListSeparatorProps {
    className?: string
    title?: string
}

export default function ListSeparator(props: ListSeparatorProps): JSX.Element {

    let classes = "ListSeparator " + (props.className === undefined ? "" : props.className);
    let title = props.title;

    return (
        <div className={classes}>
            { title !== undefined &&
                <div className="ListSeparator_Title">
                    {title}
                </div>
            }
            <div className="ListSeparator_LineContainer">
                <div className="ListSeparator_AccentBox"/>
                <div className="ListSeparator_Underline"/>
            </div>
        </div>
    );
}