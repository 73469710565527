import React from 'react';
import { useTranslation } from 'react-i18next';

import "./SelfGuidedEventTextShort.scss"
import SelfGuidedEventIndicator from "../common/SelfGuidedEventIndicator";

export default function SelfGuidedEventTextShort() {
    const { t } = useTranslation('event');
    return (<>
        <div className={`SelfGuidedEventTextShort`}>
            <SelfGuidedEventIndicator/>
            <div>
                {t('selfGuidedEventTextShort.selfGuidedNote')}
            </div>
        </div>
    </>);
}