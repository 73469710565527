import { CLUB_ROLES_STRINGS } from '../constants/dashboard'
import { ClubAssociationSanityData } from '../sanity/getVirtualClubAssociationContentViaSanity'
import { ClubType, ClubVerificationStatus } from './club'
import { SortOrder } from './sortOrder'

export interface IClub {
    clubName: string
    primaryClubLeaders: Array<{ firstName: string, email: string, lastName: string }>
    secondaryClubLeaders: Array<{ firstName: string, email: string, lastName: string }>
    clubImage: string
    location: { city: string, countrySubdivision: string }
    id: number
    role?: CLUB_ROLES_STRINGS
    shortCode?: string
    urlFragment?: string
    isIgniteEnabled: boolean
    trackingInPersonActivitiesEnabled?: boolean
    trackingOnlineActivitiesEnabled?: boolean
    customerServiceEmail?: string
    customerServicePhone?: string
    verificationStatus?: ClubVerificationStatus
    type: ClubType
    joinedDate?: string
    isVirtual? : boolean
}

export interface IClubWithDesc {
    clubName: string
    id: number
    description : string
    shortCode : string
    urlFragment: string
    sanityData?: ClubAssociationSanityData
}

export const GET_CLUBS_V2_URL = '/v2/clubs';

export interface IClubV2 {
  id: number
  type: ClubType
  urlFragment: string
  name: string
  description: string
  organizationName: string
  hostsInPersonEvents: boolean
  hostsVirtualEvents: boolean
  clubShortcode: string
  salesforceId: string
  terms: [string]
  clubImage: string
  locations: [{
    postalCode: string
    city: string
    state: string
    distanceInMiles: number
  }],
  phone?: string,
  email?: string,
  clubWebsiteUrl?: string,
  //add club leaders
  passion?: string,
}

export enum IClubV2SortByColumns {
  ID = "id",
  NAME = "name",
  LOCATION = "location"
}

export interface IClubV2SearchParams {
  type: ClubType | undefined | null,
  sortBy: IClubV2SortByColumns | undefined | null,
  sortOrder: SortOrder | undefined | null,
  terms: string[] | undefined | null,
  offset: number,
  limit: number,
  isVirtual: boolean,
  "location.postalCode": string | undefined | null,
  "location.radiusInMiles": number | undefined | null,
  "location.onlyMatchedLocations": boolean,
  isGroupworksSearch?: boolean,
  searchTerm?: string,
}

export interface IClubV2SearchResponse {
  items: IClubV2[] | undefined | null,
  limit: number,
  skip: number,
  total: number
}