
import React from "react"

const UploadIcon = ({ className, isWhite }: { className: string, isWhite?: boolean }) => (
    <svg className={className} width="86" height="62" viewBox="0 0 86 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_664_55972)">
            <path fill={isWhite ? '#fff' : '#04598D'} d="M43.4036 20.841C43.9274 20.841 44.2931 20.9394 44.728 21.3329L55.6001 31.1716C56.371 31.8603 56.5193 33.2082 55.7582 33.9658C55.0268 34.6939 53.7123 34.7824 52.9512 34.0839L45.3803 27.2263V48.3795C45.3803 49.4716 44.5007 50.3473 43.4036 50.3473C42.3065 50.3473 41.4268 49.4716 41.4268 48.3795V27.2361L33.8559 34.0937C33.085 34.7923 31.7408 34.7431 31.0489 33.9757C30.3571 33.2082 30.3966 31.88 31.2071 31.1815L42.0791 21.3428C42.3658 21.0869 42.8797 20.8607 43.4036 20.8508V20.841ZM43.4036 0.179688C37.078 0.179688 30.7524 2.57049 25.9193 7.37178C21.8274 11.445 19.4652 16.6005 18.8425 21.9134C8.78089 22.8284 0.903564 31.2405 0.903564 41.5023C0.903564 52.374 9.74949 61.1797 20.671 61.1797H69.1012C78.4117 61.1797 85.9036 53.7219 85.9036 44.4539C85.9036 35.1858 78.4117 27.7281 69.1012 27.7281H67.9251C68.7948 20.4868 66.4326 12.9307 60.8483 7.37178C56.0251 2.57049 49.7291 0.179688 43.4036 0.179688Z" />
        </g>
        <defs>
            <clipPath id="clip0_664_55972">
                <rect width="85" height="61" fill="white" transform="translate(0.903564 0.179688)" />
            </clipPath>
        </defs>
    </svg>
)

export default UploadIcon 
