import { AuthParams, getJsonAuth } from './RequestService';
import {
  GetEventAndClubResponseBody,
  GetUserEventRelationshipStatusResponseBody,
  UserEventRelationshipStatus,
} from './MemberService';
import { loadConfig } from './ConfigService';
import { EventSearchSort } from '../pages/findAnEvent/FindAnEventPage';
import { GetLocationResponseBody, GetPassionResponseBody } from './Models';
import { SupportedTimeZone } from '../util/SupportedTimeZone';
import { Instant, LocalTime, ZonedDateTime } from '@js-joda/core';
import { EventView } from '../components/findAnEvent/FindAnEventForm';
import { EventRecurrence } from './ClubLeaderService';
import { User, authenticatedFetch, getUser } from './UserService';

export function startOfCurrentOrSoonMonthDateTime(
  month: number,
): ZonedDateTime {
  const currentMonth = new Date().getMonth();
  return ZonedDateTime.now()
    .withMonth(month + 1)
    .plusYears(currentMonth > month ? 1 : 0)
    .withDayOfMonth(1)
    .with(LocalTime.MIN);
}

export function startOfCurrentOrSoonMonth(month: number) {
  return startOfCurrentOrSoonMonthInstant(month).toEpochMilli();
}

export function startOfCurrentOrSoonMonthInstant(month: number) {
  const currentMonth = new Date().getMonth();
  return ZonedDateTime.now()
    .withMonth(month + 1)
    .plusYears(currentMonth > month ? 1 : 0)
    .withDayOfMonth(1)
    .with(LocalTime.MIN)
    .toInstant();
}

export function endOfCurrentOrSoonMonth(month: number) {
  return endOfCurrentOrSoonMonthInstant(month).toEpochMilli();
}

export function endOfCurrentOrSoonMonthInstant(month: number) {
  const currentMonth = new Date().getMonth();
  return ZonedDateTime.now()
    .withMonth(month + 1)
    .plusMonths(1)
    .plusYears(currentMonth > month ? 1 : 0)
    .withDayOfMonth(1)
    .minusDays(1)
    .with(LocalTime.MAX)
    .toInstant();
}

export interface EventSearchResult {
  distanceInMiles: number;
  clubUrlFragment: string;
  clubName: string;
  clubIsVirtual: boolean;
  rowIndex: number;
  passionId: number;
  passion: GetPassionResponseBody;
  locationId: number | null;
  location: GetLocationResponseBody | null;
  eventIsPaid: boolean;
  eventIsVirtual: boolean;
  eventTypeOne: string | null;
  eventTypeTwo: string | null;
  eventStartsAt: string;
  eventEndsAt: string;
  eventTimeZone: SupportedTimeZone;
  eventUrlFragment: string;
  eventName: string;
  eventId: number;
  occurrenceId: string | null;
  eventIsRecurring: boolean;
  recurrence: EventRecurrence | null;
  userEventRelationshipStatus: UserEventRelationshipStatus | null;
}

interface EventSearchResponseBody {
  postalCode: string;
  skip: number;
  limit: number;
  sort: EventSearchSort;
  reverse: boolean;
  totalResultCount: number;
  results: EventSearchResult[];
  anyEventIsVirtual: boolean;
}

interface SearchEventsParams extends AuthParams {
  postalCode?: string | null;
  view?: EventView;
  passionIds?: number[];
  virtualOnly?: boolean;
  distance?: number;
  startDate?: string | null;
  endDate?: string | null;
  sort?: EventSearchSort;
  reverse?: boolean;
  skip?: number | null;
  limit?: number | null;
}

export async function searchEvents({
  authenticatedFetch,
  ...params
}: SearchEventsParams): Promise<EventSearchResponseBody> {
  const config = await loadConfig();
  return await getJsonAuth({
    authenticatedFetch,
    url: `${config.apiOrigin}/event-search`,
    searchParams: params,
  });
}

export async function searchEventsAndSeparateOccurrences({
  authenticatedFetch,
  ...params
}: SearchEventsParams): Promise<EventSearchResponseBody> {
  const config = await loadConfig();
  return await getJsonAuth({
    authenticatedFetch,
    url: `${config.apiOrigin}/event-search/separate-occurrences`,
    searchParams: params,
  });
}

interface FindUpcomingParams extends AuthParams {
  excludeIds?: number[];
  skip?: number | null;
  limit?: number | null;
}

export async function findUpcoming({
  authenticatedFetch,
  ...params
}: FindUpcomingParams): Promise<EventSearchResponseBody> {
  const config = await loadConfig();
  return await getJsonAuth({
    authenticatedFetch,
    url: `${config.apiOrigin}/find-upcoming`,
    searchParams: params,
  });
}

interface FindFeaturedParams extends AuthParams {
  excludeIds?: number[];
  skip?: number | null;
  limit?: number | null;
}

export async function findFeatured({
  authenticatedFetch,
  ...params
}: FindFeaturedParams): Promise<EventSearchResponseBody> {
  const config = await loadConfig();
  return await getJsonAuth({
    authenticatedFetch,
    url: `${config.apiOrigin}/find-featured`,
    searchParams: params,
  });
}

export async function getEventAndClub(eventUrlFragment: string): Promise<
  | {
      error: string;
      eventAndClub: null;
    }
  | {
      error: null;
      eventAndClub: GetEventAndClubResponseBody | null;
    }
> {
  const config = await loadConfig();
  const getEventAndClubRequest = new Request(
    `${config.apiOrigin}/events/get-with-club/${eventUrlFragment}`,
    { method: 'GET', headers: { Accept: 'application/json' } },
  );

  const getEventAndClubAnonymousRequest = new Request(
    `${config.apiOrigin}/events/get-with-club-anonymous/${eventUrlFragment}`,
    { method: 'GET', headers: { Accept: 'application/json' } },
  );

  let response;
  const user = getUser();
  if (user !== null) {
    response = await authenticatedFetch(getEventAndClubRequest);
  } else {
    response = await fetch(getEventAndClubAnonymousRequest);
  }

  if (response === null || !response.ok) {
    const responseBody = await response?.body?.getReader().read();
    const responseBodyString = responseBody
      ? new TextDecoder('utf-8')
          .decode(responseBody.value)
          .replace(/^"|"$/g, '')
      : null;
    let errorMessage = 'eventViewWithDetails.notFoundError';
    if (responseBodyString) {
      const errorsMap = {
        'Event with URL fragment': 'eventViewWithDetails.notFoundError',
        'No club for event with URL fragment':
          'eventViewWithDetails.eventWithoutClubError',
        'No event with URL fragment': 'eventViewWithDetails.notFoundError',
        'Request for cancelled event':
          'eventViewWithDetails.cancelledEventError',
        'You are not authorized to view the event with URL fragment':
          'eventViewWithDetails.unauthorizedError',
      };
      for (const [key, value] of Object.entries(errorsMap)) {
        if (responseBodyString.startsWith(key)) {
          errorMessage = value;
          break;
        }
      }
    }

    return { error: errorMessage, eventAndClub: null };
  }

  const eventAndClub: GetEventAndClubResponseBody | null =
    await response.json();

  if (eventAndClub === null) {
    return { error: 'eventViewWithDetails.notFoundError', eventAndClub: null };
  }

  return { error: null, eventAndClub };
}

export async function getRsvpStatusOnEvent(eventUrlFragment: string ) {
  const config = await loadConfig();
  const getRsvpRequest = new Request(
    `${config.apiOrigin}/events/get-user-event-relationship/${eventUrlFragment}`,
    { method: 'GET', headers: { Accept: 'application/json' } },
  );
  const responseRsvp: Response | null = await authenticatedFetch(
    getRsvpRequest,
  );

  if (
    // 404 is relationship not found, which is ok. that just means
    // there is no such relationship.
    responseRsvp === null ||
    (!responseRsvp.ok && responseRsvp.status !== 404)
  ) {
    return false;
  } else {
    const rsvp: GetUserEventRelationshipStatusResponseBody =
      await responseRsvp.json();

    if (rsvp !== null) {
      if (
        rsvp.status === UserEventRelationshipStatus.Rsvp ||
        rsvp.status === UserEventRelationshipStatus.AttendedWithoutRsvp ||
        rsvp.status === UserEventRelationshipStatus.Attended
      ) {
        return true;
      }
    }
  }

  return false;
}
