import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';

import "../../../components/registrationWizard/steps/RegistrationWizardStep.scss";
import "./MemberPersonalInformationStep.scss";
import {FormControl} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {checkValidity, SubFormContext, useCreateSubForm, useRootFormValues} from "../../../util/Forms";
import {DateOfBirthInput} from "../../../components/registrationWizard/inputs/DateOfBirthInput";
import {ContinueButton} from "../../../components/registrationWizard/inputs/ContinueButton";
import {ZipCodeInput} from "../../../components/registrationWizard/inputs/ZipCodeInput";
import {PhoneInput} from "../../../components/registrationWizard/inputs/PhoneInput";
import {StepProps} from "../../../util/registration-util";
import {GetPayerResponseBody} from "../../../services/MemberService";
import {InsuranceIdInput} from "../../../components/registrationWizard/inputs/InsuranceIdInput";
import {BackButton} from "../../../components/registrationWizard/misc/BackButton";
import {UserContext} from "../../../contexts/UserContext";
import {PhoneNumberOverride} from "../../../components/common/ContactPhoneNumber";

export function MemberPersonalInformationStep(props: StepProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {getContactPhoneNumber} = useContext(UserContext);

    const { firstName, selectedPayer } = useRootFormValues<{
        firstName: string,
        selectedPayer: GetPayerResponseBody
    }>();
    const {elementConfig, context, formState} = useCreateSubForm();

    const continueEnabled = () => checkValidity({ elementConfig, formState });

    return (
        <SubFormContext.Provider value={context}>
            <div className="RegistrationWizardStep MemberPersonalInformationStep">
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={2}>
                        <BackButton onClick={props.onPrevious} />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={1} direction="column" alignItems="stretch">
                            <Grid item>
                                <h2>{t('payerMemberRegistrationWizard.memberPersonalInformationStep.thankYouMessage',
                                    {name: firstName})}</h2>
                                <p className="MemberPersonalInformationStep_StepSubcontent">{
                                    t('payerMemberRegistrationWizard.memberPersonalInformationStep.stepSubcontent')
                                }</p>
                                <p dangerouslySetInnerHTML={{__html:
                                        t('payerMemberRegistrationWizard.memberPersonalInformationStep.preferPhone',
                                            {phoneNumber: getContactPhoneNumber({override: PhoneNumberOverride.MEMBER})})}}/>
                            </Grid>
                            <Grid item>
                                <p>
                                    {t('payerMemberRegistrationWizard.memberPersonalInformationStep.healthPlanInfo',
                                        { payerName: selectedPayer.payerName })}
                                </p>
                                <InsuranceIdInput />
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <ZipCodeInput />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <PhoneInput/>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth margin="normal">
                                    <DateOfBirthInput />
                                </FormControl>
                            </Grid>
                            <Grid item container justify="flex-end">
                                <FormControl>
                                    <ContinueButton
                                        onClick={props.onContinue}
                                        enabled={continueEnabled()}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}/>
                </Grid>
            </div>
        </SubFormContext.Provider>
    );
}
