import React, {useContext, useEffect, useRef, useState} from "react";
import { useTranslation } from 'react-i18next';

import Button, {ButtonStyles} from "../../components/common/Button";
import RegistrationModal from "../../components/registrationModal/RegistrationModal";
import {RegistrationFormType} from "../../components/registrationModal/RegistrationForm";
import {UserContext} from "../../contexts/UserContext";
import {EventType} from "../../util/EventType";
import "./LoggedOutVirtualClubsRegistrationPage.scss";
import {Link, useHistory, useLocation} from "react-router-dom";
import {DoubleArrow, ExpandMore} from "@material-ui/icons";
import {Carousel} from "react-responsive-carousel";
import {storeRegistrationParametersToSession} from "../../util/Util";

export default function LoggedOutVirtualClubsRegistrationPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const history = useHistory();
    const {pathname, hash, search} = useLocation();
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const {user} = useContext(UserContext);
    const whoWeAreRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const onClickSignUpButton = () => {
        setShowRegistrationModal(true);
    }

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    function afterRegistration() {
        // after registration, the page will reload as the context has changed.
        // this will "take" the user to the real virtual clubs page, rather than this
        // registration page.  So, there is nothing to do here.
    }

    const onClickMoreInfoButton = () => {
        window.scrollTo({
            top: whoWeAreRef.current!.offsetTop,
            behavior: "smooth"
        })
    }

    return (

        <div className="LoggedOutVirtualClubsRegistrationPage">
            <h1 className={"OptumMemberLandingPage_Title"}>
                {t("optumMemberLandingPage.title")}
            </h1>
            <div className={"OptumMemberLandingPage_Section1"}>
                <img className={"OptumMemberLandingPage_BannerImage OptumMemberLandingPage_BannerImageDesktop"}
                     src={require("../../assets/get-active-banner-desktop.jpg")}/>
                <img className={"OptumMemberLandingPage_BannerImage OptumMemberLandingPage_BannerImageMobile"}
                     src={require("../../assets/get-active-banner-mobile.jpg")}/>
                <div className={"OptumMemberLandingPage_Section1_Panel"}>
                    <div className={"OptumMemberLandingPage_SignUpSubsection"}>
                        <div className={"OptumMemberLandingPage_SignUpSubsection_AboveText"}>
                            {t("optumMemberLandingPage.section1.signUpAboveText")}
                        </div>
                        <div className={"OptumMemberLandingPage_SignUpSubsection_ButtonPanel"}>
                            <Button text={t('optumMemberLandingPage.section1.signUpLinkText')}
                                    className={"OptumMemberLandingPage_SignUpSubsection_SignUpButton"}
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={user !== null}
                                    clickHandler={onClickSignUpButton}
                            />
                        </div>
                        <div className={"OptumMemberLandingPage_SignUpSubsection_Below"}>
                            <span>{t("optumMemberLandingPage.section1.signUpBelowText")}</span>
                            <Link to={"/login"} className={"OptumMemberLandingPage_SignUpSubsection_Below_Link"}>
                                {t("optumMemberLandingPage.section1.signUpBelowTextLink")}
                            </Link>
                        </div>
                    </div>
                    <div className={"OptumMemberLandingPage_Section1_DividerSection"}>
                        <div className={"OptumMemberLandingPage_Section1_Divider"}/>
                        <ExpandMore className={"OptumMemberLandingPage_Section1_DividerIcon"} />
                    </div>
                    <div className={"OptumMemberLandingPage_GymSubsection"}>
                        <div className={"OptumMemberLandingPage_GymSubsection_AboveText"}>
                            {t("optumMemberLandingPage.section1.gymAboveText")}
                        </div>
                        <div className={"OptumMemberLandingPage_GymSubsection_BelowText"}>
                            {t("optumMemberLandingPage.section1.gymBelowText")}
                        </div>
                    </div>
                </div>
            </div>
            <RegistrationModal type={RegistrationFormType.MEMBER}
                               eventType={EventType.GROUP_ACTIVITY}
                               open={showRegistrationModal}
                               onClose={() => setShowRegistrationModal(false)}
                               onSuccessfulSubmit={afterRegistration}/>
        </div>
    );
}

function Testimony({number}: {number: number}): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <div className={"OptumMemberLandingPage_Testimony"}>
            <img src={require(`../../assets/get-active-testimony-${number}.jpg`)}
                 className={"OptumMemberLandingPage_Testimony_Image"}/>
            <div className={"OptumMemberLandingPage_Testimony_Text"}>
                {t(`optumMemberLandingPage.section2.testimony${number}`)}
            </div>
        </div>
    )
}

function HowItWorksStep({number}: {number: number}): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <>
            <td className={"OptumMemberLandingPage_Section4_Step_Label"}>
                {t(`optumMemberLandingPage.section4.step${number}.label`)}
            </td>
            <td className={"OptumMemberLandingPage_Section4_Step_Title"}>
                {t(`optumMemberLandingPage.section4.step${number}.title`)}
            </td>
            <td className={"OptumMemberLandingPage_Section4_Step_Content"}>
                {t(`optumMemberLandingPage.section4.step${number}.content`)}
            </td>
        </>
    )
}

function LandingPageClub({number}: {number: number}): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <div className={"OptumMemberLandingPage_Club"}>
            <img src={require(`../../assets/get-active-club-${number}.jpg`)}
                 className={"OptumMemberLandingPage_Club_Image"}/>
            <div className={"OptumMemberLandingPage_Club_Title"}>
                {t(`optumMemberLandingPage.section5.club${number}.title`)}
            </div>
            <div className={"OptumMemberLandingPage_Club_Content"}>
                {t(`optumMemberLandingPage.section5.club${number}.content`)}
            </div>
        </div>
    );
}