import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const REMOVE_USERS_FROM_CLUB_URL = '/ignite/leader/remove-users-from-club'

const postRemoveUsersFromClub = async (body: object) => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${REMOVE_USERS_FROM_CLUB_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postRemoveUsersFromClub
