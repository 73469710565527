import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./VerifyEmail.scss";
import Loading from "../../components/common/Loading";
import {loadConfig} from "../../services/ConfigService";
import {UserContext} from "../../contexts/UserContext";

export default function VerifyEmailPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const [verifyDone, setVerifyDone] = useState(false);
    const [verifySuccess, setVerifySuccess] = useState(false);
    const [verifyTitle, setVerifyTitle] = useState(t('verifyEmail.state'));

    const { refreshUser } = useContext(UserContext);

    let token = '';
    let pathParts = window.location.pathname.split("/");
    if (pathParts.length > 2) {
        token = pathParts[2];
    }

    useEffect(
        () => {
            const verify = async () => {
                if (verifyDone) {
                    return;
                }
                const config = await loadConfig();
                const request = new Request(`${config.apiOrigin}/verify-email`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        token
                    })
                });

                let response = await fetch(request);

                setVerifyDone(true);

                if (response?.ok) {
                    setVerifySuccess(true);
                    setVerifyTitle(t('verifyEmail.success'));
                    refreshUser();
                } else {
                    setVerifySuccess(false);
                    setVerifyTitle(t('verifyEmail.failure'));
                }
            }

            verify();
        },
        [setVerifyTitle, setVerifySuccess, token, verifyDone]
    );

    return (
        <div className="VerifyEmailPage">
            <h1>{verifyTitle}</h1>
            {!verifyDone && <Loading loading={verifyDone}/>}
            {verifyDone && verifySuccess && <div className="VerifyEmailPage_Success">
                {t('verifyEmail.verified')}
            </div>}
            {verifyDone && !verifySuccess && <div className="VerifyEmailPage_Failure">
                {t('verifyEmail.problem')}
            </div>}
        </div>
    );
}
