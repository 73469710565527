import React from "react";
import { useTranslation } from 'react-i18next';
import cn from 'classnames'
import { TextField, Button, IconButton } from "@material-ui/core";

import PlayIcon from '@material-ui/icons/PlayArrow';
import EditIcon from "@material-ui/icons/Edit";
//@ts-ignore
import InputMask from 'react-input-mask';
import { ContactMethod } from "../../../constants/contact";
import SaveIcon from "../icons/SaveIcon";

interface IContactMethodsFormProps {
    isEditing: boolean
    email: string
    onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void
    emailError: string
    phone: string
    phoneError: string
    onChangePhone: (event: React.ChangeEvent<HTMLInputElement>) => void
    preferredContactMethod?: number
    onCheck: (n: number) => (event: React.MouseEvent) => void
    onSave: (event: React.MouseEvent) => void
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

const ContactMethodsForm = ({
    isEditing,
    email,
    onChangeEmail,
    emailError,
    phone,
    phoneError,
    onChangePhone,
    preferredContactMethod,
    onCheck,
    onSave,
    setIsEditing
}: IContactMethodsFormProps): JSX.Element => {
    const { t } = useTranslation('ignite/contactMethods');

    const disabled = Boolean(emailError || phoneError)
    const helpText = preferredContactMethod === ContactMethod.PHONE ? t('helpText.text') :
        (preferredContactMethod === ContactMethod.EMAIL ? null : t('helpText.both'))
    return (
        <>
            <div className={cn('contact-methods-content', { editing: isEditing })}>
                <div className='contact-methods-top'>
                    <div className='contact-methods-top__title'>
                        {t('contactMethods')}
                    </div>
                    <div className='button-wrapper'>
                        {t('edit')}
                        <IconButton
                            className='edit-button'
                            onClick={() => setIsEditing(isEditing => !isEditing)}
                        >
                            {isEditing ? <SaveIcon /> : <EditIcon />}
                            <span hidden aria-hidden="true">{t('edit')}</span>
                        </IconButton>
                    </div>
                </div>
                <div className='contact-info'>
                    <label htmlFor="email-id" className='contact-info__label'>
                        {t('email')}
                    </label>
                    <div className='contact-info__main'>
                        <TextField
                            value={email}
                            onChange={onChangeEmail}
                            error={Boolean(emailError)}
                            helperText={emailError}
                            required
                            fullWidth={true}
                            size="small"
                            InputProps={{
                                id: 'email-id',
                                readOnly: true,
                                disableUnderline: true,
                                classes: {
                                    input: 'contact-info__input',
                                    root: 'contact-info__base'
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='contact-info'>
                    <label htmlFor="phone-id" className='contact-info__label'>
                        {t('phoneNumber')}
                    </label>
                    <div className='contact-info__main'>
                        <InputMask
                            mask='(999) 999-9999'
                            value={phone}
                            onChange={onChangePhone}
                            maskChar='*'
                        >
                            {(inputProps: any) =>
                                <TextField
                                    error={Boolean(phoneError)}
                                    helperText={phoneError}
                                    required
                                    size="small"
                                    variant={(isEditing ? "outlined" : undefined) as "outlined"}
                                    InputProps={{
                                        ...inputProps,
                                        id: 'phone-id',
                                        readOnly: !isEditing,
                                        classes: {
                                            input: 'contact-info__input',
                                            root: cn('contact-info__base', { 'Mui-focused': isEditing })
                                        },
                                    }}
                                />}
                        </InputMask>
                    </div>
                </div>

                <div className='contact-info'>
                    <label htmlFor="phone-id" className='contact-info__label'>
                        {t('preferred')}
                        <br />
                        {t('method')}
                    </label>
                    <div className={cn('contact-info__main preferred-method', { 'is-editing': isEditing })}>
                        <div className="button-wrapper">
                            <Button
                                className={cn('button', { active: preferredContactMethod === ContactMethod.PHONE })}
                                onClick={onCheck(ContactMethod.PHONE)}
                            >
                                {t('text')}
                            </Button>
                            <Button
                                className={cn('button', { active: preferredContactMethod === ContactMethod.EMAIL })}
                                onClick={onCheck(ContactMethod.EMAIL)}
                            >
                                {t('email')}
                            </Button>
                            <Button
                                className={cn('button', { active: preferredContactMethod === ContactMethod.BOTH })}
                                onClick={onCheck(ContactMethod.BOTH)}
                            >
                                {t('both')}
                            </Button>
                        </div>
                        <div className='contact-info__help-text'>
                            {helpText}
                        </div>
                    </div>
                </div>
            </div>

            <Button
                className='club-button'
                onClick={onSave}
                disabled={disabled}
                classes={{
                    disabled: 'disabled-button'
                }}
            >
                {t('saveButtonText')}
                < PlayIcon className='club-button__icon' />
            </Button>
        </>
    )
}

export default ContactMethodsForm
