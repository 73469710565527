import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import Button, {ButtonStyles} from "../common/Button";
import {useFormState} from "react-use-form-state";
import {TextField} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Checkbox from '@material-ui/core/Checkbox';
import "./PayerDetailsForm.scss";
import FormLabel from '@material-ui/core/FormLabel';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {
    authenticatedFetch,
    PayerInformationParameters,
} from "../../services/UserService";
import Loading from "../common/Loading";
import ContactPhoneNumber from "../common/ContactPhoneNumber";
import MemberSelectPayer from "../payer/MemberSelectPayer";
import {LocalDateUtils} from "../../util/FixedJsJodaUtils";
import {Locale} from "@js-joda/locale_en-us";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {convert, DateTimeParseException, LocalDate} from "@js-joda/core";
import {RegistrationFormType} from "./RegistrationForm";
import {loadConfig} from "../../services/ConfigService";
import {UserContext} from "../../contexts/UserContext";
import InputMask from "react-input-mask";
import {getPhoneUnmask, PHONE_REGEX} from "../../util/Util";

interface PayerDetailsFormProps extends RouteComponentProps {
    postSubmit: Function,
    id?: string
}

function PayerDetailsForm(props: PayerDetailsFormProps) {
    const { t } = useTranslation('registrationModal');
    const initialState = {
        payerId: "",
        insuranceId: "",
        zipCode: "",
        phone: "",
        dateOfBirth: null,
        termsOfUse: false
    }

    let id = props.id === undefined ? 'buttonId': props.id;

    const {hasLeadershipRoles, refreshUser} = useContext(UserContext);
    const [formState, {text, checkbox, tel}] = useFormState(initialState);
    const [formError, setFormError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(null);
    const [payerState, setPayerState] = useState();

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const elementConfig: Record<string, any> = {
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value.trim()) {
                    //return 'Zip Code is required';
                    return " ";
                }
                if (!value.match(zipCodeRegex)) {
                    //return "Invalid zip code";
                    return " ";
                }
            },
            validateOnBlur: true
        },
        phoneNumber: {
            name: 'phoneNumber',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return ' ';
                }
            },
            validateOnBlur: true
        },
        termsOfUse: {
            name: 'termsOfUse',
            validateOnBlur: true
        }
    }

    const submitEnabled = () => {
        // The default behavior isn't very ideal, this works better.
        const allFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        });
        const termsOfUseAccepted = formState.values.termsOfUse;
        return termsOfUseAccepted && allFieldsValid && Object.entries(formState.validity).every(([key, value]) => value);
    }

    useEffect(() => {
        if (payerState) {
            formState.setField('payerId', payerState.payerId);
            formState.setField('insuranceId', payerState.insuranceId);
            formState.setField('healthPlanName', payerState.healthPlanName);
        }
    }, [payerState]);

    const validateDateOfBirth = (date: LocalDate) => {
        const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
        const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

        return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
    }

    useEffect(() => {
        // The widget validation was inconsistent during development. Perform explicit validation on the parsed LocalDate before passing off the value to the form.
        if (dateOfBirth instanceof LocalDate && validateDateOfBirth(dateOfBirth)) {
            formState.setField('dateOfBirth', dateOfBirth);
        }
        else {
            // Otherwise invalidate the date on the form
            formState.setField('dateOfBirth', null);
        }
    }, [dateOfBirth]);

    async function submitPayerDetails(parameters : PayerInformationParameters) {
        const config = await loadConfig();
        const url = `${config.apiOrigin}/submit-payer-details`;
        const request = new Request(url, {
            method: 'POST',
            body: JSON.stringify(parameters),
            headers: {
                "Content-Type": "application/json"
            }
        });
        const response = await authenticatedFetch(request);
        setLoading(false);

        if (response === null || !response.ok) {
            setFormError(t('payerDetailsForm.formError'));
            return;
        }
        await refreshUser();
        props.postSubmit(parameters);
    }

    async function handleSubmit() {
        try {
            setLoading(true);
            const parameters : PayerInformationParameters = {
                insuranceId: formState.values.insuranceId,
                payerId: formState.values.payerId,
                phoneNumber: getPhoneUnmask(formState.values.phoneNumber),
                zipCode: formState.values.zipCode,
                dateOfBirth: formState.values.dateOfBirth,
                healthPlanName: formState.values.healthPlanName
            };

            await submitPayerDetails(parameters);
        } catch (e) {
            setLoading(false);
            console.log(e);
            setFormError(t('payerDetailsForm.formError'));
        }
    }

    return (
        <div className={"PayerDetailsForm_Wrapper"}>
            {loading && <><Loading loading={loading}/></>}
            {!loading && <>
                <MemberSelectPayer onChange={(state: object) => setPayerState(state)}></MemberSelectPayer>
                <form>
                    <div className={"PayerDetailsForm_FieldWrapper"}>
                        <div className={"PayerDetailsForm_Field"}>
                            <TextField
                                {...text(elementConfig.zipCode)}
                                label={t('payerDetailsForm.zipCodePlaceholder')}
                                variant="outlined"
                                className="PayerDetailsForm_Input"
                                error={formState.errors.zipCode !== undefined}
                                required
                                size="small"
                                inputProps={{
                                    length: 5,
                                    minLength: 5,
                                    maxLength: 5
                                }}
                            />
                            <div className={"PayerDetailsForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`PayerDetailsForm_FieldIndicator ${formState.touched.zipCode && (formState.errors.zipCode ? "error" : "validated")}`}/>
                            </div>
                        </div>
                        <div className={"PayerDetailsForm_Field"}>
                            <InputMask
                                mask='(999) 999-9999'
                                maskChar='*'
                                {...tel(elementConfig.phoneNumber)}
                            >
                                {(inputProps: any) =>
                                    <TextField {...inputProps}
                                label={t('payerDetailsForm.phoneNumberPlaceholder')}
                                variant="outlined"
                                className="PayerDetailsForm_Input"
                                error={formState.errors.phoneNumber !== undefined}
                                required
                                size="small"/>
                                }
                            </InputMask>
                            <div className={"PayerDetailsForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`PayerDetailsForm_FieldIndicator ${formState.touched.phoneNumber && (formState.errors.phoneNumber ? "error" : "validated")}`}/>
                            </div>
                            <div className={"PayerDetailsForm_ElementHelpText"}>
                                {t('payerDetailsForm.phoneNumberHelperText')}
                            </div>
                        </div>
                    </div>
                    <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
                        <KeyboardDatePicker
                            className="MemberRegistrationWizard_Input"
                            value={dateOfBirth}
                            label={t('payerDetailsForm.dob')}
                            required
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            views={[ "year", "month", "date"]}
                            openTo="year"
                            disableFuture
                            minDate="1900-01-02" // Treated as 1/1/1900
                            minDateMessage={t('payerDetailsForm.dobMin')}
                            maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                            maxDateMessage={t('payerDetailsForm.dobMax')}
                            initialFocusedDate="1900-01-02"
                            onChange={date => setDateOfBirth(date)} />
                    </MuiPickersUtilsProvider>
                    <div className={"PayerDetailsFormField_TermsOfUseField"}>
                        <div className={"PayerDetailsFormField_TermsOfUseWrapper"}>
                            <Checkbox {...checkbox(elementConfig.termsOfUse)}/>
                            <FormLabel>{t('payerDetailsForm.acceptTOS')} <Link target="_blank"
                                                          className={"PayerDetailsForm_TermsOfUseLink"}
                                                          to={"/terms-of-use"}>{t('payerDetailsForm.TOS')}</Link></FormLabel>
                        </div>
                    </div>
                    {(formError !== null || Object.entries(formState.errors).length > 0) &&
                    <div className="RegistrationModal_FormErrors">
                        <div className="PayerDetailsForm_FormError">{formError}</div>
                        {Object.entries(formState.errors).map(([key, value]) =>
                            <div key={key} className="PayerDetailsForm_FormError">{value}</div>)}
                    </div>}
                    <div className="PayerDetailsForm_Bottom">
                        <Button clickHandler={handleSubmit}
                                id={id}
                                className="PayerDetailsForm_SubmitButton"
                                buttonStyle={ButtonStyles.FilledPrimary}
                                disabled={!submitEnabled()}>{t('payerDetailsForm.submitButtonText')}</Button>
                        <div className="PayerDetailsForm_FormHelpText">{t('payerDetailsForm.needHelpText')}
                            <span className="PayerDetailsForm_FormHelpText_ContactPhoneNumber">
                                {hasLeadershipRoles() &&
                                    <ContactPhoneNumber formType={RegistrationFormType.LEADER}/>
                                }
                                {!hasLeadershipRoles() &&
                                    <ContactPhoneNumber formType={RegistrationFormType.MEMBER}/>
                                }
                            </span>
                        </div>
                    </div>
                </form>
            </>}
        </div>
    )
}

export default withRouter(PayerDetailsForm);
