export const getPhoneUnmask = (value: string) => value.replace(/[^\d]/g, '');

export function formatPhoneNumberForDisplay(phoneNumber: string) {
    if (!phoneNumber || phoneNumber.length < 10) {
        return phoneNumber;
    }
    let startingIndex = 0;
    if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
        startingIndex = 1;
    }
    const areaCode = phoneNumber.slice(startingIndex, startingIndex+3);
    const firstPart = phoneNumber.slice(startingIndex+3, startingIndex+6);
    const secondPart = phoneNumber.slice(startingIndex+6, startingIndex+10);

    return `(${areaCode}) ${firstPart}-${secondPart}`;
}

export function formatPhoneNumberForTel(phoneNumber: string) {
    if (!phoneNumber || phoneNumber.length < 10) {
        return phoneNumber;
    }
    let startingIndex = 0;
    if (phoneNumber.length === 11 && phoneNumber[0] === '1') {
        startingIndex = 1;
    }
    const countryCode = '+1';
    const areaCode = phoneNumber.slice(startingIndex, startingIndex+3);
    const firstPart = phoneNumber.slice(startingIndex+3, startingIndex+6);
    const secondPart = phoneNumber.slice(startingIndex+6, startingIndex+10);

    return `${countryCode}-${areaCode}-${firstPart}-${secondPart}`;
}