import { useState, useEffect } from "react";
import { IMember } from '../types/dashboard'
import getMemberReferrals from "../api/getMemberReferrals";


interface IUseGetInvitedMembersReturn {
    notInvitedMembers: IMember[] | undefined
    isLoading: boolean
    fetchNotInvitedMembers: Function
    totalNotInvitedResultCount: number
}


const useGetNotInvitedMembers = (clubId: number): IUseGetInvitedMembersReturn => {
    const [notInvitedMembers, setNotInvitedMembers] = useState<IMember[] | undefined>()
    const [totalNotInvitedResultCount, setTotalNotInvitedResultCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const fetchNotInvitedMembers = ({ skip = 0, sortBy = 'fullName', sortOrder = 'asc', searchValue = '' } = {}) => {
        return getMemberReferrals({ clubId, skip, sortBy, sortOrder, searchValue })
            .then(data => {
                setNotInvitedMembers(data?.results)
                setTotalNotInvitedResultCount(Number(data?.totalResultCount))
            })
    }

    useEffect(() => {
        setIsLoading(true)

        fetchNotInvitedMembers({})
            .finally(() => {
                setIsLoading(false)
            })
    }, [clubId])

    return { notInvitedMembers, isLoading, fetchNotInvitedMembers, totalNotInvitedResultCount }
}

export default useGetNotInvitedMembers
