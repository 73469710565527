// TODO: This is not really a "service" as the file name implies.  What is it?

import {convert, Duration, Instant, LocalDate, LocalDateTime, LocalTime, ZonedDateTime, ZoneId} from "@js-joda/core";

import "@js-joda/timezone";
import { SupportedTimeZone } from "../util/SupportedTimeZone";
import { loadConfig } from "./ConfigService";
import { AuthParams, getJson, getJsonAuth } from "./RequestService";
import { GetLocationResponseBody, GetPassionResponseBody } from "./Models";
import { EventView } from "../components/findAnEvent/FindAnEventForm";
import { EventOccurrence, EventRecurrence, VirtualEventDetailsModel } from "./ClubLeaderService";
import { ITerm } from "../types/clubSetup";
import { ClubType, ClubVerificationStatus } from "../types/club";


export interface GetEventResponseBody {
    eventId: number;
    eventName: string;
    description: string;
    importantDetails: string;
    urlFragment: string;
    leaderFirstName: string;
    leaderLastName: string;
    leaderEmail: string;
    leaderPhone: string;
    startsAtDate: string; // yyyy-mm-dd
    startsAtTime: string; // HH:mm
    startsAtInstant: string, // 2020-12-25T14:00:00Z
    endsAtInstant: string, // 2020-12-25T14:00:00Z
    timeZone: SupportedTimeZone;
    howLongInHours: number;
    location: GetLocationResponseBody | null;
    status: EventStatus;
    typeOne: string | null;
    typeTwo: string | null;
    isPaid: boolean;
    isVirtual: boolean;
    virtualEventDetails: VirtualEventDetailsModel | null;
    isRecurring: boolean;
    recurrence: EventRecurrence | null;
    occurrences: EventOccurrence[];
    endsAtTime: string;  // HH:mm
    externalUrl: string;
}

export enum ClubStatus {
    ACTIVE = 1,
    INACTIVE = 2
}

export enum EventStatus {
    ACTIVE = 1,
    CANCELLED = 2
}

export enum UserEventRelationshipStatus {
    Rsvp = 1,
    RsvpCancelled = 2,
    NoRsvp = 3,
    Attended = 4,
    AttendedWithoutRsvp = 5
}

export interface GetUserEventRelationshipStatusResponseBody {
    status: UserEventRelationshipStatus;
}

export interface GetAllPassionsResponseBody {
    allPassions: GetPassionResponseBody[];
}

export interface GetPassionsResponseBody {
    passions: GetPassionResponseBody[];
}

export interface GetEventAndUserEventRelationshipStatusResponseBody
{
    event: GetEventResponseBody;
    status: GetUserEventRelationshipStatusResponseBody|null;
}

export interface GetClubResponseBody {
    id: number;
    type: ClubType;
    urlFragment: string;
    shortCode: string | null;
    clubName: string;
    description: string;
    customerServiceEmail: string;
    customerServicePhone: string;
    organization: GetOrganizationResponseBody | null;
    passion: GetPassionResponseBody | null;
    timeZone: SupportedTimeZone;
    location: GetLocationResponseBody | null;
    status: ClubStatus;
    events: GetEventResponseBody[];
    primaryClubLeaders: GetClubLeaderResponseBody[];
    deputyClubLeaders: GetClubLeaderResponseBody[];
    openDeputyInvitations: GetDeputyInvitationResponseBody[];
    term: ITerm;
    memberCount? : number,
    isCurrentUserMember? : boolean,
    isCurrentUserLeader? : boolean,
    isVirtual: boolean
}


export interface UserViewState {
    isLeaderView: boolean;
    currentClubId?: number;
    currentClubUrlFragment?: string;
    currentClubSupportEmail?: string|undefined;
    currentClubSupportPhone?: string|undefined;
    clubVerificationStatus?: ClubVerificationStatus | undefined;
}

export interface ClubAssociationCustomerServiceResponseBody {
    clubId: number;
    clubShortCode: string;
    customerServiceEmail?: string|undefined;
    customerServicePhone?: string|undefined;
}

export interface GetOrganizationResponseBody {
    organizationName: string;
}

export interface GetClubLeaderResponseBody {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export interface GetDeputyInvitationResponseBody {
    toEmailAddress: string;
}

export interface GetAllPayersResponseBody {
    allPayers: GetPayerResponseBody[]
}

export interface GetPayerResponseBody {
    id: number;
    payerName: string;
    unlistedPayerPlaceholder: boolean;
    payerSlug: string;
    parentId: number | null;
}

export interface GetEventAndClubResponseBody {
    event: GetEventResponseBody;
    club: GetClubResponseBody;
}

export interface GetEventAndClubAndUserEventRelationshipResponseBody {
    eventAndClub: GetEventAndClubResponseBody;
    status: UserEventRelationshipStatus;
    rowIndex: number;
}

/**
 * The data structure returned by {@link getEventTimeInfo}.
 */
export interface EventTimeInfo {
    howLong: Duration;
    timeZone: ZoneId;
    startsAtZonedDateTime: ZonedDateTime;
    startsAtInstant: Instant;
    startsAtNativeJsDate: Date;
    endsAtZonedDateTime: ZonedDateTime;
    endsAtInstant: Instant;
    endsAtNativeJsDate: Date;
}

/**
 * Returns various representations of the time-related properties of the event for convenience.
 */
export function getEventTimeInfo(event: GetEventResponseBody): EventTimeInfo {
    // nb: can't use ofHours here as that fails on fractional values
    const howLong = Duration.ofMinutes(event.howLongInHours * 60);
    const timeZone = ZoneId.of(event.timeZone);
    const startsAtDate = LocalDate.parse(event.startsAtDate);
    const startsAtTime = LocalTime.parse(event.startsAtTime);
    const startsAtDateTime = LocalDateTime.of(startsAtDate, startsAtTime)
    const startsAtZonedDateTime = startsAtDateTime.atZone(timeZone);
    const startsAtInstant = startsAtZonedDateTime.toInstant();
    const startsAtNativeJsDate = convert(startsAtZonedDateTime).toDate();
    const endsAtZonedDateTime = startsAtZonedDateTime.plus(howLong);
    const endsAtInstant = endsAtZonedDateTime.toInstant();
    const endsAtNativeJsDate = convert(endsAtZonedDateTime).toDate();

    return {
        howLong,
        timeZone,
        startsAtZonedDateTime,
        startsAtInstant,
        startsAtNativeJsDate,
        endsAtZonedDateTime,
        endsAtInstant,
        endsAtNativeJsDate
    };
}

/**
 * Response body for EventFiles
 *
 */
export interface GetEventFileResponseBody {
    fileName: string;
    url: string | null;
    title: string;
    contentType: string;
    sizeInByte: number;
    syncStatus: EventFileSyncStatus;
    thumbnails: GetEventFileThumbnailResponseBody[];
}

export interface GetEventFileThumbnailResponseBody {
    width: number;
    height: number;
    url: string;
}

/**
 * S3 sync status for the actual files
 * represented by EventFiles
 *
 */
export enum EventFileSyncStatus {
    Pending = 1,
    Success = 2,
    Failure = 3
}

interface GetMyEventsPagedParams extends AuthParams {
    start: number | null;
    end: number | null;
    view: EventView | undefined;
    skip: number;
    limit: number;
}

export interface PagedMyEvents {
    totalResultCount: number;
    results: GetEventAndClubAndUserEventRelationshipResponseBody[]
}

export interface GetHasRegisteredEventsResponseBody {
    hasRegisteredEvents: boolean;
}

export async function getMyEventsPaged({
                                           authenticatedFetch,
                                           start,
                                           end,
                                           view,
                                           skip,
                                           limit
} : GetMyEventsPagedParams): Promise<PagedMyEvents> {
    const config = await loadConfig();
    return getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/events/get-my-events/paged`,
        searchParams: {
            "startDate": start,
            "endDate": end,
            "view": view,
            "skip": skip,
            "limit": limit
        }
    });
}

export async function getHasRegisteredEvents({
                                                 authenticatedFetch
                                             }: AuthParams): Promise<GetHasRegisteredEventsResponseBody> {
    const config = await loadConfig();
    return getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/events/has-registered-events`
    });
}

export async function getAllPayersEventsPortal(): Promise<GetAllPayersResponseBody> {
    const config = await loadConfig();
    return getJson({
        url: `${config.apiOrigin}/all-payers`
    });
}

export async function getChildPayersForParentList(parentId: number): Promise<GetAllPayersResponseBody> {
    const config = await loadConfig();
    return getJson({
        url: `${config.apiOrigin}/get-child-payers-for-parent-list/${parentId}`
    });
}

export async function getAllPayersByPayerListSlug(payerListSlug = 'member'): Promise<GetAllPayersResponseBody> {
    const config = await loadConfig();
    return getJson({
        url: `${config.apiOrigin}/all-payers/${payerListSlug}`
    });
}

export async function getPayerBySlug({ payerSlug }: { payerSlug: string }): Promise<GetPayerResponseBody> {
    const config = await loadConfig();
    return getJson({
        url: `${config.apiOrigin}/payer/${payerSlug}`
    });
}
