import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizard.scss";
import Loading from "../../../components/common/Loading";
import LoadingMessage from "../../../components/common/LoadingMessage";
import {RouteComponentProps} from "react-router-dom";
import {
    FormHelperText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import {useFormState} from "react-use-form-state";
import {SelectedPayerContext} from "../../../contexts/SelectedPayerContext";
import {MemberName, MemberNameStep} from "../steps/MemberNameStep";
import {MemberPhone, MemberPhoneStep} from "../steps/MemberPhoneStep";
import {MemberZipCode, MemberZipStep} from "../steps/MemberZipStep";
import {MemberPayer, MemberSelectPayerStep} from "../steps/CentenePayerStep";
import {MemberAccount, MemberAccountStep} from "../steps/MemberAccountStep";

import MemberPassionsStep from "../steps/MemberPassionsStep";

//Adding for Registration Submission - Refactor later 
import {MemberRegistrationParameters, registerMember} from "../../../services/UserService";
import {UserContext} from "../../../contexts/UserContext";
import { sendPageView } from "../../../withTracker";
import {
    GetPassionResponseBody,
    UserRegistrationFlow
} from "../../../services/Models";
import { readUtmRegistrationParametersFromSession } from "../../../util/Util";


/**
 * The first step in the member registration process in which the user selects
 * their payer and enters their insurance id.
 *
 * The user arrives here from the `MemberLandingPage`.
 */
export default function CenteneRegis(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    //////////////////////////////////////////////////////
    const {login} = useContext(UserContext);
    //////////////////////////////////////////////////////
    const {history} = props;

    const initialState = {
        payerId: 0,
        insuranceId: "",
        passions: [],
        firstName: "",
        lastName: "",
        phone: "",
        zip: ""
    }

    async function postSubmit(parameters: MemberRegistrationParameters) {
        setPayerDetails(null);

        sendPageView(
            `${history.location.pathname}?step=complete`,
            ` - Centene Member Registration Complete`);

        const searchParams = new URLSearchParams();
        searchParams.set("justRegistered", "true");
        searchParams.set("postalCode", parameters.zipCode);
        history.push(`/find-an-event?${searchParams.toString()}`);
    }

    // for capturing state of each wizard step...
    const [memberName, setMemberName] = useState<MemberName>({firstName:"", lastName:""});
    const [memberPhone, setMemberPhone] = useState<MemberPhone>({phone:""});
    const [memberZipCode, setMemberZipCode] = useState<MemberZipCode>({zipCode:""});
    const [memberPayer, setMemberPayer] = useState<MemberPayer>({payerId:0, insuranceId:""});
    const [memberAccount, setMemberAccount] = useState<MemberAccount>({email:"", password:"", termsOfUse: false});

    const [formState, {text, select}] = useFormState(initialState);

    // steps in the registration flow provided by Erick
    enum WizardSteps {
        Passions = 1,
        FullName,
        PhoneNumber,
        ZipCode,
        HealthPlans,
        Account
    };

    // current wizard step
    const [wizardStep, setWizardStep] = useState(WizardSteps.Passions);

    useEffect(() => {
        sendPageView(
            `${history.location.pathname}?step=${wizardStep.valueOf()}`,
            ` - Centene Member Registration Step ${wizardStep.valueOf()}`);
    }, [wizardStep]);

    const {setPayerDetails} = useContext(SelectedPayerContext);

    // analagous to the former form's submit payer button; this probably should change, not sure why the context is being used here
    function onSubmitChoosePayerForm(data: any) {
        if (memberPayer.payerId === null || memberPayer.insuranceId === null) {
            // TODO: How to add validation message?
            return;
        }
        formState.setField('payerId', data.payer.id);
        formState.setField('insuranceId', data.insuranceId);
        setPayerDetails({ payerId: memberPayer.payerId, insuranceId: memberPayer.insuranceId });
        
        //history.push("/member/create-account");
    }

    // "continue" selected from Wizard sections; just changes the current 
    async function onContinueSelected(data: any) {
        
        switch(wizardStep) {
            case WizardSteps.Passions:
                formState.setField('passions', data.passions);
               
                setWizardStep(WizardSteps.FullName);
                break;
            case WizardSteps.FullName:
                
                setMemberName({...data});
                formState.setField('firstName', data.firstName);
                formState.setField('lastName', data.lastName);
               
                setWizardStep(WizardSteps.PhoneNumber);
                break;
            case WizardSteps.PhoneNumber:
                setMemberPhone({...data});
                formState.setField('phone', data.phone);
                
                setWizardStep(WizardSteps.ZipCode);
                break;
            case WizardSteps.ZipCode:
                setMemberZipCode({...data});
                formState.setField('zip', data.zip);
                
                setWizardStep(WizardSteps.HealthPlans);
               
                break;
            case WizardSteps.HealthPlans:
                //setMemberZipCode({...data});
                onSubmitChoosePayerForm({...data});
                setWizardStep(WizardSteps.Account);
                break;
            case WizardSteps.Account: // this would be the final "submit" button; handle this a bit differently
                //setMemberAccount({...data});
                await onRegister({...data});
                //setWizardStep(WizardSteps.HealthPlans);
                break;
            default:
                setWizardStep(WizardSteps.FullName);
          }
    }

    // "previous" selected from Wizard sections; just changes the current step to the previous step
    function onPreviousSelected() {
        switch(wizardStep) {
            case WizardSteps.Passions:
                setWizardStep(WizardSteps.Passions);
                break;
            case WizardSteps.FullName:
                setWizardStep(WizardSteps.Passions);
                break;
            case WizardSteps.PhoneNumber:
                setWizardStep(WizardSteps.FullName);
                break;
            case WizardSteps.ZipCode:
                setWizardStep(WizardSteps.PhoneNumber);
                break;
            case WizardSteps.HealthPlans:
                setWizardStep(WizardSteps.ZipCode);
                break;
            case WizardSteps.Account:
                setWizardStep(WizardSteps.HealthPlans);
                break;
            default:
                setWizardStep(WizardSteps.Passions);
            }
    }

    // TODO - here we gather all the data and register like the current RegistrationForm.tsx does it
    async function onRegister(data: any) {
       try{
            const parameters : MemberRegistrationParameters = {
                email: data.email,
                phone: formState.values.phone,
                password: data.password,
                firstName: formState.values.firstName,
                lastName: formState.values.lastName,
                zipCode: formState.values.zip,
                renewId: formState.values.insuranceId,
                payerId: formState.values.payerId,
                passionIds: (formState.values.passions as GetPassionResponseBody[])
                    .map(passion => passion.id),
                ...readUtmRegistrationParametersFromSession(),
                registrationFlow: UserRegistrationFlow.WELLCARE
            };
            await registerMember(parameters).then(
                // good
                () => {
                    login(data.email, data.password).then(
                        // good
                        () => {
                            //setLoading(false);
                            postSubmit(parameters);
                        },
                        // nope
                        () => {
                            // setLoading(false);
                            // setRegistrationError("There was an error registering." +
                            //     "  If this persists, please contact support.");
                        });
                })
       }
       catch(e){
           console.error('RegistationError: ',e)
       }
    }

    function renderHealthPlans() {
        return (
            <div className="MemberRegistrationWizard">
                <p className="WizardStepHeading">{t('centeneRegis.registration')}</p>
                <MemberSelectPayerStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberSelectPayerStep>
            </div>
        );
    }

    function renderPassions() {
        return (
            <div className="MemberRegistrationWizard">
                <p className="WizardStepHeading">{t('centeneRegis.registration')}</p>
                <MemberPassionsStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberPassionsStep>
            </div>
        );
    }

    function renderFullName() {
        return (
            <div className="MemberRegistrationWizard">
                <p className="WizardStepHeading">{t('centeneRegis.registration')}</p>
                <MemberNameStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberNameStep>
            </div>
        );
    }

    function renderPhoneNumber() {
        return (
            <div className="MemberRegistrationWizard">
                <p className="WizardStepHeading">{t('centeneRegis.registration')}</p>
                <MemberPhoneStep firstName={memberName.firstName} onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberPhoneStep>
            </div>
        );
    }

    function renderZipCode() {
        return (
            <div className="MemberRegistrationWizard">
                <p className="WizardStepHeading">{t('centeneRegis.registration')}</p>
                <MemberZipStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberZipStep>
            </div>
        );
    }

    function renderAccount() {
        return (
            <div className="MemberRegistrationWizard">
                <p className="WizardStepHeading">{t('centeneRegis.registration')}</p>
                <MemberAccountStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberAccountStep>
            </div>
        );
    }

    function renderWizardStep() {
        switch(wizardStep) {
          case WizardSteps.Passions:
            return renderPassions();
          case WizardSteps.FullName:
            return renderFullName();
          case WizardSteps.PhoneNumber:
            return renderPhoneNumber();
          case WizardSteps.ZipCode:
            return renderZipCode();
          case WizardSteps.HealthPlans:
            return renderHealthPlans();
          case WizardSteps.Account:
            return renderAccount();
          default:
            return renderPassions();
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return renderWizardStep();
}
