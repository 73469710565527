import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, Menu } from '@material-ui/core'
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FILTER_FIELDS, FILTER_KEYS } from "../../../constants/dashboard";

interface ISelectOrderProps {
    filter: string
    filterOptions: { key: FILTER_KEYS, field: FILTER_FIELDS, isAsc: boolean }[]
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SelectOrder = ({
    filter,
    filterOptions,
    onChange
}: ISelectOrderProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="sorting__order">
            <div className="sorting__help-text">
                {t('sortedBy')}
            </div>
            <div className="select-order">
                <Button
                    aria-controls={open ? 'select-order' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={onClick}
                    className="menu__button"
                    classes={{
                        label: 'menu__label'
                    }}
                >
                    <ArrowDownIcon className='menu__icon' />
                    <div
                        className='option-item'
                        dangerouslySetInnerHTML={{ __html: t(`filter.${filter}`) }}
                    />
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={onClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {filterOptions.map((option: any) =>
                        <MenuItem
                            className='menu__item'
                            key={option.key}
                            onClick={() => {
                                onChange(option.key)
                                onClose()
                            }}
                        >
                            <div
                                className='option-item'
                                dangerouslySetInnerHTML={{ __html: t(`filter.${option.key}`) }}
                            />
                        </MenuItem>)}
                </Menu>
            </div>
        </div>
    );
}

export default SelectOrder
