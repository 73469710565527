export enum PayerSlug {
  Aetna = 'aetna',
  AllinaHealthAetna = 'allina-health-aetna',
  InnovationHealth = 'innovation-health',
  SilverScript = 'silverscript',
  Optum = 'optum',
  UnitedHealthcare = 'united-healthcare',
  EHealth = 'e-health',
  OakStreetHealth = 'oak-street-health',
  AnthemBlueCrossAndBlueShield = 'anthem-blue-cross-and-blue-shield',
  Centene = 'centene',
  EmblemHealth = 'emblem-health',
  HealthFirst = 'health-first',
  HomeThrive = 'home-thrive',
  Humana = 'humana',
  Kaiser = 'kaiser',
  Scan = 'scan',
  Cigna = 'cigna',
  Wellcare = 'wellcare',
  AllinaHealth = 'allina-health',
  MedicareHealthInsurance = 'medicare-health-insurance',
  UnitedHealthcareAarpMedicareSupplement = 'united-healthcare-aarp-medicare-supplement',
  UnitedHealthcareMedicareAdvantage = 'united-healthcare-medicare-advantage',
  Other = 'other',
}

export const payerConfig: Record<
  PayerSlug,
  { logoSrc: string; cardSrc: string }
> = {
  optum: {
    logoSrc: '/images/ignite/payers/optum/logo.png',
    cardSrc: '/images/ignite/payers/optum/card.png',
  },
  'united-healthcare': {
    logoSrc: '/images/ignite/payers/united-healthcare/logo.png',
    cardSrc: '/images/ignite/payers/united-healthcare/card.png',
  },
  aetna: {
    logoSrc: '/images/ignite/payers/aetna/logo.png',
    cardSrc: '/images/ignite/payers/aetna/card.png',
  },
  'e-health': {
    logoSrc: '/images/ignite/payers/e-health/logo.png',
    cardSrc: '/images/ignite/payers/e-health/card.png',
  },
  'oak-street-health': {
    logoSrc: '/images/ignite/payers/oak-street-health/logo.png',
    // should be added later
    cardSrc: '',
  },
  'anthem-blue-cross-and-blue-shield': {
    logoSrc: '/images/ignite/payers/anthem-blue-cross-and-blue-shield/logo.png',
    cardSrc: '/images/ignite/payers/anthem-blue-cross-and-blue-shield/card.png',
  },
  centene: {
    logoSrc: '/images/ignite/payers/centene/logo.png',
    // should be added later
    cardSrc: '',
  },
  'emblem-health': {
    logoSrc: '/images/ignite/payers/emblem-health/logo.png',
    cardSrc: '/images/ignite/payers/emblem-health/card.png',
  },
  'health-first': {
    logoSrc: '/images/ignite/payers/health-first/logo.png',
    cardSrc: '/images/ignite/payers/health-first/card.png',
  },
  'home-thrive': {
    // should be added later
    logoSrc: '',
    cardSrc: '',
  },
  humana: {
    logoSrc: '/images/ignite/payers/humana/logo.png',
    cardSrc: '/images/ignite/payers/humana/card.png',
  },
  kaiser: {
    logoSrc: '/images/ignite/payers/kaiser/logo.png',
    cardSrc: '/images/ignite/payers/kaiser/card.png',
  },
  scan: {
    logoSrc: '/images/ignite/payers/scan/logo.png',
    cardSrc: '/images/ignite/payers/scan/card.png',
  },
  cigna: {
    logoSrc: '/images/ignite/payers/cigna/logo.png',
    cardSrc: '/images/ignite/payers/cigna/card.png',
  },
  wellcare: {
    logoSrc: '/images/ignite/payers/wellcare/logo.png',
    cardSrc: '/images/ignite/payers/wellcare/card.png',
  },
  'allina-health': {
    logoSrc: '/images/ignite/payers/allina-health/logo.png',
    cardSrc: '/images/ignite/payers/allina-health/card.png',
  },
  'innovation-health': {
    logoSrc: '/images/ignite/payers/innovation-health/logo.png',
    cardSrc: '/images/ignite/payers/innovation-health/card.png',
  },
  'medicare-health-insurance': {
    logoSrc: '',
    cardSrc: '/images/ignite/payers/medicare-health-insurance/card.png',
  },
  'united-healthcare-aarp-medicare-supplement': {
    logoSrc: '',
    cardSrc:
      '/images/ignite/payers/united-healthcare-aarp-medicare-supplement/card.png',
  },
  'united-healthcare-medicare-advantage': {
    logoSrc: '',
    cardSrc:
      '/images/ignite/payers/united-healthcare-medicare-advantage/card.png',
  },
  'allina-health-aetna': { logoSrc: '', cardSrc: '' }, // this was not defined in the original config, but it is used in the code. that's why it has no values
  silverscript: { logoSrc: '', cardSrc: '' }, // this was not defined in the original config, but it is used in the code. that's why it has no values
  other: { logoSrc: '', cardSrc: '' },
};
