import {createTheme, makeStyles} from "@material-ui/core/styles";

export const CCTheme = createTheme({
    overrides: {
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: "black"
                }
            },

            focused: {}
        }
    },    typography: {
        "fontFamily": `"Open Sans", sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }, "palette": {
        "common": {"black": "#000", "white": "#fff"},
        "background": {"paper": "#fff", "default": "#fafafa"},
        "primary": {
            "light": "rgba(0, 145, 233, 1)",
            "main": "rgba(0, 145, 233, 1)",
            "dark": "rgba(2, 128, 205, 1)",
            "contrastText": "#fff"
        },
        "secondary": {
            "light": "rgba(0, 145, 233, 1)",
            "main": "rgba(0, 145, 233, 1)",
            "dark": "rgba(2, 128, 205, 1)",
            "contrastText": "#fff"
        },
        "error": {"light": "#e57373", "main": "#f44336", "dark": "#d32f2f", "contrastText": "#fff"},
        "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
});

export const CCStyles = makeStyles(theme => ({
    customErrored: {
        color: 'red ! important',
        fontWeight: 'bold',
        borderColor: '#ff0000 ! important'
    },
    formControl: {
        fontSize: '16px',
    },
    formLabel: {
        color: 'black',
    },
    editPageFormLabel: {
        color: 'black',
        fontSize: '.8em',
        fontWeight: 'bold',
    },
    '&$focused': {
        color: 'black',
    },
    formHelperText: {
        fontSize: '16px',
        color: 'black ! important',
    },
    formGroup: {},
    errored: {
        color: "red",
    },
    input: {},
    root: {},
    focused: {},
}), {index: 1});

