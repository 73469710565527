import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {useFormState} from "react-use-form-state";
import Checkbox from '@material-ui/core/Checkbox';
import {InputAdornment, TextField} from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {Link} from "react-router-dom";
import { isValidEmail } from "../../../util/Util";
import { Visibility, VisibilityOff } from '@material-ui/icons';

export type MemberAccount = {
  email: string,
  password: string,
  termsOfUse: boolean
}

type StepProp = {
    onContinue: (data: any) => Promise<unknown>,
    onPrevious: () => void
}

export function MemberAccountStep(props: StepProp): JSX.Element {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { t } = useTranslation('pages');
    const initialState = {
        email: "",
        password: "",
        termsOfUse: false
    }

    const [formState, {password, email, checkbox}] = useFormState(initialState);

    const elementConfig = {
        email: {
            name: 'email',
            validate: (value: string) => {
                if (value.trim() === "") {
                    //return "Email is required.";
                    return " ";
                }
                // Basic validation to prevent simple misentered values
                if (!isValidEmail(value)) {
                    return t('clubMemberAccountStep.invalidEmail');
                }
            },
            validateOnBlur: true
        },
        password: {
            name: 'password',
            validate: (value: string) => {
                const trimmedInput = value.trim();
                if (!trimmedInput) {
                    //return "Password required."
                    return " ";
                }
                if (trimmedInput.length < 8) {
                    return t('clubMemberAccountStep.passwordLengthError');
                }
                if (trimmedInput.search(/[A-Z]/) === -1 || trimmedInput.search(/[0-9]/) === -1) {
                    return t('clubMemberAccountStep.passwordFormatError');
                }
            },
            validateOnBlur: true
        },
        termsOfUse: {
            name: 'termsOfUse'
        }
    }

    const handleShowPassword = () => setShowPassword(!showPassword);

    // borrowed from the original form
    const continueEnabled = () => {
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = async () => {
        try {
            await props.onContinue({email: formState.values.email, password: formState.values.password});
        } catch (e) {
            if (e instanceof Error && e.message && e.message === 'Email already exists.') {
                formState.setFieldError('email', e.message);
            }
        }
    }

    return (
        <div className={"ClubMemberRegistrationWizard_Wrapper MemberAccountStep"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                    <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} direction="column" alignItems="stretch">
                        <Grid item>
                            <h2 dangerouslySetInnerHTML={{__html: t('clubMemberAccountStep.title')}}></h2>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                            <TextField {...email(elementConfig.email)}
                                className="MemberRegistrationWizard_Input"
                                label={t('clubMemberAccountStep.emailLabel')}
                                required
                                variant={"outlined"}
                                error={formState.errors.email !== undefined}
                                helperText={formState.errors.email}
                                size="small"/>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <TextField
                                {...password(elementConfig.password)}
                                className="MemberRegistrationWizard_Input"
                                label={t('clubMemberAccountStep.passwordLabel')}
                                variant="outlined"
                                error={formState.errors.password !== undefined}
                                required
                                helperText={t('clubMemberAccountStep.passwordHelperText')}
                                FormHelperTextProps={{error: false}}
                                size="small"
                                InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </FormControl>
                        <FormControl>
                        <div className={"RegistrationFormField_SelfGuidedEventWaiverWrapper"}>
                                    <Checkbox {...checkbox(elementConfig.termsOfUse)} style={{paddingLeft: 0}}/>
                                    <FormLabel>
                                        {t('clubMemberAccountStep.acceptTOS')}&nbsp;
                                        <Link to={{pathname: "https://hellogrouper.com/app-terms-of-use"}}
                                                    target="_blank"
                                                    className={"RegistrationForm_TermsOfUseLink"}
                                                    >{t('clubMemberAccountStep.tosLink')}</Link>
                                    </FormLabel>
                                </div>
                        </FormControl>
                        </Grid>
                        <Grid item container justify="flex-end">
                            <FormControl>
                                <PlayButton type="button"
                                            clickHandler={onContinueSelected}
                                            text={t('clubMemberAccountStep.registerButtonText')}
                                            className="MemberRegistrationWizard_SubmitButton"
                                            buttonStyle={ButtonStyles.FilledPrimary}
                                            disabled={!continueEnabled()}
                                            id="EmailPassSubmit"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    }
