import React, { useContext } from "react";
import {Route, RouteProps} from "react-router-dom";
import { UserContext } from "../contexts/UserContext";

const ConditionalRoute = ({
    loggedInComponent: LoggedInComponent,
    loggedOutComponent: LoggedOutComponent,
    ...rest
}: any) => {
    const { isLoggedIn } = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={(props: RouteProps) =>
                isLoggedIn() ? (
                    <LoggedInComponent {...props} />
                ) : (
                    <LoggedOutComponent {...props} />
                )
            }
        />
    );
};

export default ConditionalRoute
