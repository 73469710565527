import { loadConfig } from '../services/ConfigService'

export const REGISTER_INVITED_MEMBER_URL = '/ignite/register-invited-member/'

const postRegisterInvitedMember = async (body: any): Promise<{ userId: number } | undefined | { message: string }> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${REGISTER_INVITED_MEMBER_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    const response = await fetch(request);

    if (response.ok) {
        return response.json()
    }

    const error = await response.text()

    throw new Error(error)
}

export default postRegisterInvitedMember
