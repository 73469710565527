import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import clubLeaderLanding from "../../../assets/club-leader-landing.jpg";
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import PlayIcon from '@material-ui/icons/PlayArrow';

import "./LeaderOnboardingWelcome.scss";
import LeaderOnboardingRegistrationModal from "../leaderOnboardingRegistration/LeaderOnboardingRegistration";

export default function LeaderOnboardingWelcome() {
    const { t } = useTranslation('ignite/leaderOnboarding');
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    return (
        <>
            <LeaderOnboardingRegistrationModal
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <div className="leader-onboarding-welcome-page">
                <div className="inner-part">
                    <div className='title'>
                        {t('welcome.title')}
                    </ div>
                    <div className="main-content">
                        <div className="main-content__image-wrapper">
                            <img className="image" src={clubLeaderLanding} alt={t('welcome.imageAlt')}/>
                        </div>
                        <div className="main-content__text-wrapper">
                            <div className="subtitle">
                                {t('welcome.subtitle')}
                            </div>
                            <div className="description">
                                {t('welcome.description')}
                            </div>
                            <div className="help-text">
                                {t('welcome.helpText')}
                            </div>
                            <Button
                                color="primary"
                                variant="contained"
                                className="button"
                                onClick={() => setIsModalOpen(true)}
                            >
                                {t('welcome.buttonText')}
                                <PlayIcon className="icon" />
                            </Button>
                            <div className="already-member">
                                {t('welcome.buttonHelpText')}
                                {' '}
                                <Link
                                    className="already-member__link"
                                    to="/login"
                                >
                                    {t('welcome.logIn')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="inner-part">
                        <div className="title">
                            {t('welcome.footerTitle')}
                        </div>
                        <div className="links-list">
                            <Link
                                className="link-item"
                                to="/about"
                            >
                                {t('welcome.link1Text')}
                            </Link>

                            <Link
                                className="link-item"
                                to="/about"
                            >
                                {t('welcome.link2Text')}
                            </Link>

                            <Link
                                className="link-item"
                                to="/about"
                            >
                                {t('welcome.link3Text')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
