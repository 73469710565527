import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';
import {UserContext} from "../../../contexts/UserContext";
import {useLocation} from "react-router-dom";

interface IGetHelpPanelProps {
    getContactPhoneNumber: Function
}

export const GetHelpPanel = ({
    getContactPhoneNumber,
}: IGetHelpPanelProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');
    const {getContactEmail} = useContext(UserContext);
    const location = useLocation();

    return (
        <div className="get-help-panel">
            <div className="title">
                {t('getHelpTitle')}
            </div>
            <div className="label">
                {t('emailLabel')}:
            </div>
            <a className="link" style={{ overflowWrap: 'break-word'}} href={`mailto:${getContactEmail(location.pathname)}`}>
                {getContactEmail(location.pathname)}
            </a>
            <div className="label">
                {t('phoneLabel')}:
            </div>
            <div className="info">
                {getContactPhoneNumber().display}
            </div>
            <a className="link" href={`tel:${getContactPhoneNumber().tel}`}>
                {t('phoneActionText')}
            </a>
        </div>
    )
}
