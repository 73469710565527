import { useState, useEffect, useCallback } from "react";
import getAllMembersActivity from '../api/getAllMembersActivity'
import { IActivityItem } from '../types/dashboard'
import { useHistory } from "react-router-dom";
import { IGNITE_ROUTE_PATTERNS } from "../constants/routes";
import getMembers from "../api/getMembers";

interface IUseGetMembersResult {
    members: IActivityItem[]
    clubIncome: number
    isLoading: boolean
    fetchMembers: Function
    totalMembersResultCount: number
}

const useGetMembers = (clubId: number): IUseGetMembersResult => {
    const [members, setMembers] = useState<IActivityItem[]>([])
    const [totalMembersResultCount, setTotalMembersResultCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [clubIncome, setClubIncome] = useState(0)
    const history = useHistory()


    const fetchMembers = useCallback(({ skip = 0, sortBy = 'fullName', sortOrder = 'asc', searchValue = '' } = {}) => {
        return getMembers({ id: clubId, skip, sortBy, sortOrder, searchValue }).then(data => {
            setMembers(data?.results)
            setTotalMembersResultCount(data?.totalResultCount)
        })
    }, [clubId])

    useEffect(() => {
        setIsLoading(true)
        getAllMembersActivity(clubId).then(data => setClubIncome(data?.clubIncome))

        fetchMembers()
            .catch(() => history.push(IGNITE_ROUTE_PATTERNS.ERROR))
            .finally(() => {
                setIsLoading(false)
            })
    }, [clubId])

    return { members, clubIncome, isLoading, fetchMembers, totalMembersResultCount }
}

export default useGetMembers
