import { Box, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./Search.scss";
import { useTranslation } from 'react-i18next';
import { IClubV2 } from '../../../../../../types/clubFinder';

interface SearchProps {
    clubs: IClubV2 [];
    onChange: (searchValue: string) => void;
    onOptionSelected: (club: IClubV2) => void;
    searchValue: string;
    handleSearch: () => void;
}
export default function Search (props: SearchProps) {
    const { t } = useTranslation('directToConsumer/clubFinder');
    let inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        props.onChange('');
    }, [])
    return(
        <div className='club-search'>
        <Box className="club-finder-box" style={{borderRadius: 0, backgroundColor: '#1976D214'}}>
            <div className='form-control'>
            <SearchIcon className="search-icon"/>
            <Autocomplete
                options={props.clubs}
                getOptionLabel={(option) => option.name}
                defaultValue={null}
                filterOptions={x => x}
                className="search-input"
                open={false}
                freeSolo
                renderOption={(option) => <Box>{option.name}</Box>}
                onChange={(_, newValue) => {
                    props.onOptionSelected(newValue as IClubV2);
                }}
                onBlur={props.handleSearch}
                onInputChange={(_, value) => props.onChange(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder={t('section.clubFinder.searchInputPlaceHolder')}
                        size="small"
                        inputProps={{
                            ...params.inputProps,
                            value : props.searchValue,
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                    e.currentTarget.blur();
                                }
                            },
                        }}
                    />

                )}
            />
            </div>
        </Box>
        </div>
    );
}
