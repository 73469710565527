import { GetClubResponseBody } from './../services/MemberService';
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService';

export const CLUB_BY_URL_FRAGMENT_URL = '/clubs/get/'

const getClubByUrlFragment = async (urlFragment: string): Promise<GetClubResponseBody | undefined> => {
    const config = await loadConfig();
    
    const request = new Request(`${config.apiOrigin}${CLUB_BY_URL_FRAGMENT_URL}${urlFragment}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }
}

export default getClubByUrlFragment
