import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { getJsonAuth } from '../services/RequestService'
import { IClubWithDesc } from '../types/clubFinder';
export async function getClubLeaderClubs(leaderEmail: string)
    : Promise<{ myClubs: [IClubWithDesc] }> {
    const params = new URLSearchParams();
    params.set("leaderEmail", leaderEmail);

    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/clubs-for-leader-email?${params.toString()}`,
    });
}
