import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import Button, { ButtonStyles } from "../../common/Button";
import { useFormState } from "react-use-form-state";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { Link, useHistory, useParams } from "react-router-dom";
import Loading from "../layout/Loading";
import FieldStatusIcon from "./FieldStatusIcon";
import { getElementConfig } from './registrationFormConfig'
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PlayIcon from '@material-ui/icons/PlayArrow';
import useClubByShortCode from '../../../hooks/useClubByShortCode'
import useRegisterInvitedUser from '../../../hooks/useRegisterInvitedUser'
//@ts-ignore
import InputMask from 'react-input-mask';

import './RegistrationForm.scss'
import { IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS } from "../../../constants/routes";
import useQuery from "../../../hooks/useQuery";
import { UserContext } from "../../../contexts/UserContext";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import useMyClubStatus from "../../../hooks/useMyClubStatus";
import { CLUB_ROLES_STRINGS } from "../../../constants/dashboard";
import {UserRegistrationFlow} from "../../../services/Models";

export interface MemberRegistrationParameters {
    email: string,
    phone: string,
    password: string,
    firstName: string,
    lastName: string,
    zipCode: string,
    dateOfBirth?: string,
    phoneNumber?: number,
    ClubId?: number
}

function RegistrationForm({ isLeader }: { isLeader?: boolean }) {
    const { t } = useTranslation('ignite/registration');
    const { user } = useContext(UserContext);
    const query = useQuery()
    const userToken = query.get('userToken')
    const { clubShortCode } = useParams<{ clubShortCode: string }>()
    const myClubStatus = useMyClubStatus({ urlFragment: clubShortCode })

    const initialState = {
        firstName: "",
        lastName: "",
        renewId: "",
        zipCode: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        termsOfUse: false
    }

    const [formState, { text, password, checkbox }] = useFormState(initialState);

    const elementConfig = getElementConfig(t, formState)
    const history = useHistory()

    const submitEnabled = () => {
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);
        const termsOfUseAccepted = formState.values.termsOfUse;
        return allInputsTouched && allValuesValid && termsOfUseAccepted;
    }

    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        showConfirmPassword: false,
    });

    const [isDisabled, setIsDisabled] = useState(false);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const [club, isLoading] = useClubByShortCode(clubShortCode, isLeader)
    const { registerMember, registerLeader, error } = useRegisterInvitedUser({
        onSuccess: () => setIsDisabled(true),
        onFailure: () => setIsDisabled(false),
    })

    useEffect(() => {
        if (error) {
            formState.setFieldError('email', error)
        }
    }, [error])

    useEffect(() => {
        if (!isLoading && user !== null && club && myClubStatus) {
            let route = null

            switch (myClubStatus) {
                case CLUB_ROLES_STRINGS.UNKNOWN:
                    const url = getIgniteRoute(IGNITE_ROUTE_KEY.SUCCESSFUL_SIGNUP, { ':clubId': String(club?.id) })
                    route = userToken ? `${url}?userToken=${userToken}` : url
                    break
                case CLUB_ROLES_STRINGS.MEMBER:
                    route = getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': String(club?.id) })
                    break
                default:
                    route = getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': String(club?.id) })
                    break
            }
            history.push(route)
        }

    }, [isLoading, myClubStatus])

    async function handleSubmit() {
        const parameters: any = {
            Email: formState.values.email,
            Phone: formState.values.phoneNumber.replace(/[^\d]/g, ''),
            Password: formState.values.password,
            FirstName: formState.values.firstName,
            LastName: formState.values.lastName,
            ZipCode: formState.values.zipCode,
            ClubShortCode: clubShortCode,
            registrationFlow: UserRegistrationFlow.PROJECT_LIGHTNING_BOLT,
        };

        if (isLeader) {
            registerLeader(parameters)
        } else {
            registerMember({
                ...parameters,
                utmReferringClub: club?.clubName,
                ...(Boolean(userToken) && { userToken })
            }, club?.id)
        }
    }

    if (!isLeader && !club && !isLoading) return <div>{t('registrationForm.noSuchClub')}</div>

    return (
        <div>
            {isLoading ?
                <Loading loading={isLoading} /> :
                <>
                    <form className='registration-form'>
                        <div className="required">{t('registrationForm.required')}</div>
                        <div className='registration-form__field-wrapper'>
                            <div className='registration-form__field'>
                                <TextField {...text(elementConfig.firstName)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        required: false,
                                        htmlFor: 'first-name-id',
                                        classes: {
                                            root: 'label-root',
                                            error: 'label-error',
                                            focused: 'label-focused'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold',
                                        }
                                    }}
                                    id='first-name-id'
                                    label={t('registrationForm.firstNamePlaceholder')}
                                    required
                                    variant='outlined'
                                    error={formState.errors.firstName !== undefined}
                                    helperText={formState.errors.firstName}
                                    size='small'
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.firstName}
                                        isError={formState.errors.firstName}
                                    />
                                </div>
                            </div>
                            <div className='registration-form__field'>
                                <TextField
                                    {...text(elementConfig.lastName)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: 'last-name-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            error: 'label-error',
                                            focused: 'label-focused'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    id='last-name-id'
                                    label={t('registrationForm.lastNamePlaceholder')}
                                    variant='outlined'
                                    error={formState.errors[elementConfig.lastName.name] !== undefined}
                                    helperText={formState.errors.lastName}
                                    required
                                    size='small' />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon
                                        shouldShow={formState.touched.lastName}
                                        isError={formState.errors.lastName}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='registration-form__field-wrapper'>
                            <div className='registration-form__field'>
                                <TextField
                                    {...text(elementConfig.zipCode)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: 'zip-code-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    id='zip-code-id'
                                    label={t('registrationForm.zipCodePlaceholder')}
                                    variant='outlined'
                                    error={formState.errors.zipCode !== undefined}
                                    helperText={formState.errors.zipCode}
                                    required
                                    size='small'
                                    inputProps={{
                                        length: 5,
                                        minLength: 5,
                                        maxLength: 5
                                    }}
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.zipCode} isError={formState.errors.zipCode} />
                                </div>
                            </div>
                            <div className='registration-form__field'>
                                <InputMask
                                    {...text(elementConfig.phoneNumber)}
                                    mask='(999) 999-9999'
                                    maskChar='*'
                                >
                                    {(inputProps: any) => (
                                        <TextField
                                            classes={{
                                                root: 'registration-form__field_bold',
                                            }}
                                            InputLabelProps={{
                                                htmlFor: 'phone-number-id',
                                                required: false,
                                                classes: {
                                                    root: 'label-root',
                                                    error: 'label-error',
                                                    focused: 'label-focused'
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: 'registration-form__field_error'
                                                }
                                            }}
                                            InputProps={{
                                                ...inputProps,
                                                classes: {
                                                    input: 'registration-form__field_bold'
                                                }
                                            }}
                                            id='phone-number-id'
                                            label={t('registrationForm.phoneNumberPlaceholder')}
                                            variant='outlined'
                                            error={formState.errors.phoneNumber !== undefined}
                                            helperText={formState.errors.phoneNumber}
                                            required
                                            size='small' />
                                    )}
                                </InputMask>

                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.phoneNumber} isError={formState.errors.phoneNumber} />
                                </div>
                                <div className='registration-form__help-text'>
                                    {t('registrationForm.phoneNumberHelperText')}
                                </div>
                            </div>
                        </div>
                        <div className='registration-form__field-wrapper full-width'>
                            <div className='registration-form__field'>
                                <TextField
                                    {...text(elementConfig.email)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: 'email-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            error: 'label-error',
                                            focused: 'label-focused'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    id='email-id'
                                    label={t('registrationForm.emailPlaceholder')}
                                    error={formState.errors.email !== undefined}
                                    helperText={formState.errors.email}
                                    variant='outlined'
                                    size='small' />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.email} isError={formState.errors.email} />
                                </div>
                            </div>
                        </div>
                        <div className='registration-form__field-wrapper full-width'>
                            <div className='registration-form__field'>
                                <TextField
                                    {...text(elementConfig.confirmEmail)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: 'confirm-email-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            error: 'label-error',
                                            focused: 'label-focused'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        }
                                    }}
                                    id='confirm-email-id'
                                    label={t('registrationForm.confirmEmailPlaceholder')}
                                    error={formState.errors.confirmEmail !== undefined}
                                    helperText={formState.errors.confirmEmail}
                                    variant='outlined'
                                    size='small' />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.confirmEmail} isError={formState.errors.confirmEmail} />
                                </div>
                            </div>
                        </div>
                        <div className="registration-form__password-help-text">
                            {t('registrationForm.passwordHelperText')}
                        </div>
                        <div className='registration-form__field-wrapper full-width'>
                            <div className='registration-form__field'>
                                <TextField
                                    {...password(elementConfig.password)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: 'password-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            error: 'label-error',
                                            focused: 'label-focused'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        },
                                        type: values.showPassword ? 'text' : 'password',
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    id='password-id'
                                    type="password"
                                    label={t('registrationForm.choosePasswordPlaceholder')}
                                    variant='outlined'
                                    error={formState.errors.password !== undefined}
                                    helperText={formState.errors.password}
                                    size='small' />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.password} isError={formState.errors.password} />
                                </div>
                            </div>
                        </div>
                        <div className='registration-form__field-wrapper full-width'>
                            <div className='registration-form__field'>
                                <TextField
                                    {...password(elementConfig.confirmPassword)}
                                    classes={{
                                        root: 'registration-form__field_bold',
                                    }}
                                    InputLabelProps={{
                                        htmlFor: 'confirm-password-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            error: 'label-error',
                                            focused: 'label-focused'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'registration-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            input: 'registration-form__field_bold'
                                        },
                                        type: values.showConfirmPassword ? 'text' : 'password',
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    edge="end"
                                                >
                                                    {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                    id='confirm-password-id'
                                    label={t('registrationForm.confirmPasswordPlaceholder')}
                                    variant='outlined'
                                    error={formState.errors.confirmPassword !== undefined}
                                    helperText={formState.errors.confirmPassword}
                                    size='small'
                                    disabled={!formState.values.password}
                                />
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.confirmPassword} isError={formState.errors.confirmPassword} />
                                </div>
                            </div>
                        </div>
                        <div className='registration-form__terms-of-use-wrapper'>
                            <Checkbox
                                {...checkbox(elementConfig.termsOfUse)}
                                classes={{
                                    root: "registration-form__checkbox"
                                }}
                                id='checkbox-id'
                            />
                            <FormLabel className="registration-form__label" htmlFor='checkbox-id'>
                                <span className="registration-form__checkbox-title">
                                    {t('registrationForm.agreeWithE3')}
                                </span>
                                <Link
                                    target="_blank"
                                    className='registration-form__terms-of-use-link'
                                    to={isLeader ? IGNITE_ROUTE_PATTERNS.LEADER_TERMS_OF_USE : IGNITE_ROUTE_PATTERNS.MEMBER_TERMS_OF_USE}
                                >
                                    {t('registrationForm.termsAndConditions')}
                                </Link>
                            </FormLabel>
                        </div>
                    </form>
                    <div className='registration-form__button-wrapper'>
                        <Button
                            clickHandler={handleSubmit}
                            className="registration-form__button"
                            buttonStyle={ButtonStyles.FilledPrimary}
                            disabled={!submitEnabled() || isDisabled}
                        >
                            {t('registrationForm.signMeUpButtonText')}
                            <PlayIcon className='registration-form__button-icon' />
                        </Button>
                    </div>
                </>}
        </div >
    )
}

export default RegistrationForm
