import React from "react";
import {NewMemberRegistrationWizardSteps} from "../NewMemberRegistrationWizard";
import OneCircleFilled from "../../../components/icon/NumberOneCircleFilled";
import TwoCircleUnfilled from "../../../components/icon/NumberTwoCircleUnfilled";
import TwoCircleFilled from "../../../components/icon/NumberTwoCircleFilled";
import ThreeCircleFilled from "../../../components/icon/NumberThreeCircleFilled";
import ThreeCircleUnfilled from "../../../components/icon/NumberThreeCircleUnfilled";
import IconCircleCheck from "../../../components/icon/IconCircleCheck";

import "./CustomEnrollmentStepTracker.scss";
import {useTranslation} from "react-i18next";

interface CustomEnrollmentStepTrackerProps {
    currentStep: NewMemberRegistrationWizardSteps
}

export default function CustomEnrollmentStepTracker(props: CustomEnrollmentStepTrackerProps) {
    const { t } = useTranslation('pages');

    return (
        <div className={"CustomEnrollmentStepTracker"}>
            <div className={"CustomEnrollmentStepTracker_Step"}>
                {props.currentStep === NewMemberRegistrationWizardSteps.Account &&
                    <OneCircleFilled />
                }
                {props.currentStep === NewMemberRegistrationWizardSteps.PersonalInformation &&
                    <IconCircleCheck />
                }
                {props.currentStep === NewMemberRegistrationWizardSteps.HealthPlanCoverage &&
                    <IconCircleCheck />
                }
                <div>{t('customEnrollmentStepTracker.contactDetails')}</div>
            </div>
            <div className={"CustomEnrollmentStepTracker_Line"}/>
            <div className={"CustomEnrollmentStepTracker_Step"}>
                {props.currentStep === NewMemberRegistrationWizardSteps.Account &&
                    <TwoCircleUnfilled />
                }
                {props.currentStep === NewMemberRegistrationWizardSteps.PersonalInformation &&
                    <TwoCircleFilled />
                }
                {props.currentStep === NewMemberRegistrationWizardSteps.HealthPlanCoverage &&
                    <IconCircleCheck />
                }
                <div>{t('customEnrollmentStepTracker.personalInformation')}</div>
            </div>
            <div className={"CustomEnrollmentStepTracker_Line"}/>
            <div className={"CustomEnrollmentStepTracker_Step"}>
                {props.currentStep === NewMemberRegistrationWizardSteps.Account &&
                    <ThreeCircleUnfilled />
                }
                {props.currentStep === NewMemberRegistrationWizardSteps.PersonalInformation &&
                    <ThreeCircleUnfilled />
                }
                {props.currentStep === NewMemberRegistrationWizardSteps.HealthPlanCoverage &&
                    <ThreeCircleFilled />
                }
                <div>{t('customEnrollmentStepTracker.healthPlanCoverage')}</div>
            </div>
        </div>
    );
}