import React, { useContext } from "react";
import {Redirect, Route, RouteProps, useLocation} from "react-router-dom";

import { E3_ROUTES } from "../constants/routes";
import { UserContext } from "../contexts/UserContext";

const LeaderRoute = ({
    component: Component,
    ...rest
}: any) => {
    const { isLoggedIn, hasLeadershipRoles } = useContext(UserContext)
    const {pathname, hash, search} = useLocation();
    return (
        <Route
            {...rest}
            render={(props: RouteProps) =>
                isLoggedIn() ? (
                    hasLeadershipRoles() ? (
                            <Component {...props} />
                        ) : (
                        <Redirect
                            to={E3_ROUTES.ROOT}
                        />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: E3_ROUTES.LOGIN,
                            state: { referrer: pathname+search+hash }
                        }}
                    />
                )
            }
        />
    );
};

export default LeaderRoute
