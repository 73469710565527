import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { getJsonAuth } from '../services/RequestService'

export async function getZoomJoinUrl(urlFragment: string)
    : Promise<string> {

    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/events/join-url/${urlFragment}`
    });
}