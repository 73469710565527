import React from "react";
import "./StandardDateView.scss";
import DateView from "./DateView";
import {LocalDate} from "@js-joda/core";

export interface StandardDateViewProps {
    date: string | Date | LocalDate;
    className?: string;
}

export default function StandardDateView(props: StandardDateViewProps): JSX.Element {
    const options = {year: 'numeric', month: 'long', day: 'numeric'};

    return (
        <DateView date={props.date} format={options} className={props.className}/>
    );
}
