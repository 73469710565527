import React, { SyntheticEvent, useState } from 'react'; // importing FunctionComponent
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {useFormState} from "react-use-form-state";
import Checkbox from '@material-ui/core/Checkbox';
import {InputAdornment, TextField} from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {Link} from "react-router-dom";
import { isValidEmail } from "../../../util/Util";
import { Visibility, VisibilityOff } from '@material-ui/icons';

export type MemberAccount = {
  email: string,
  password: string,
  termsOfUse: boolean
}

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void
}

export function MemberAccountStep(props: StepProp): JSX.Element {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const { t } = useTranslation('pages');
    const initialState = {
        email: "",
        password: "",
        termsOfUse: false
    }

    const [formState, {password, email, checkbox}] = useFormState(initialState);

    const handleShowPassword = () => setShowPassword(!showPassword);
    async function testEmailRegistered(email: string) {
        // if (email) {
        //     try {
        //         const emailIsRegistered = await testIfEmailRegistered(email);
        //         if (emailIsRegistered) {
        //             formState.setFieldError("email", "We're unable to use that email address.")
        //         }
        //     } catch (e) {
        //         formState.setFieldError("email", "An unknown error occurred while registering.  If this persists" +
        //             " please contact support.")
        //     }
        // }
    }

    const elementConfig = {
        email: {
            name: 'email',
            validate: (value: string) => {
                console.log('Validate Email')
                if (value.trim() === "") {
                    //return "Email is required.";
                    return " ";
                }
                if (!isValidEmail(value)) {
                    return "Email address is not an email address.";
                }
                /*if (value !== formState.values.confirmEmail && formState.touched.confirmEmail) {
                    formState.setFieldError('confirmEmail', 'Email addresses do not match. Please Re-type confirm email.');
                }*/
            },
            onBlur: ((e: SyntheticEvent) => {
                testEmailRegistered((e.target as HTMLInputElement).value).then(() => {
                })
                return undefined;
            }),
            validateOnBlur: true
        },
        password: {
            name: 'password',
            validate: (value: string) => {
                const trimmedInput = value.trim();
                if (!trimmedInput) {
                    //return "Password required."
                    return " ";
                }
                if (trimmedInput.length < 8) {
                    return t('memberAccountStep.invalidPasswordLength');
                }
                if (trimmedInput.search(/[A-Z]/) === -1 || trimmedInput.search(/[0-9]/) === -1) {
                    return t('memberAccountStep.invalidPasswordFormat');
                }
            },
            validateOnBlur: true
        },
        termsOfUse: {
            name: 'termsOfUse'
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        //console.log("continueEnabled: values=",formState.values);
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    // function setShowSelfGuidedWaiver(b: boolean) {
    //     if (props.setShowSelfGuidedWaiver !== null) {
    //         props.setShowSelfGuidedWaiver(b);
    //     }
    // }

    const onContinueSelected = () => {
        props.onContinue({email: formState.values.email, password: formState.values.password});
    }

    return (
        <div className={"MemberRegistrationWizard_Wrapper"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                    <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <h2 dangerouslySetInnerHTML={{__html: t('memberAccountStep.header')}}></h2>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth margin="normal">
                        <TextField {...email(elementConfig.email)}
                            className="MemberRegistrationWizard_Input"
                            label={t('memberAccountStep.emailPlaceholder')}
                            required
                            variant={"outlined"}
                            error={formState.errors.email !== undefined}
                            size="small"/>
                    </FormControl>
                    <FormControl fullWidth  margin="normal">
                        <TextField
                            {...password(elementConfig.password)}
                            className="MemberRegistrationWizard_Input"
                            label={t('memberAccountStep.passwordPlaceholder')}
                            variant="outlined"
                            error={formState.errors.password !== undefined}
                            required
                            size="small"
                            InputProps={{
                                    type: showPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        <p style={{marginTop: '0', fontSize: '12px'}}>{t('memberAccountStep.passwordInstructions')}</p>
                    </FormControl>
                    <FormControl>
                    <div className={"RegistrationFormField_SelfGuidedEventWaiverWrapper"}>
                                <Checkbox {...checkbox(elementConfig.termsOfUse)} style={{paddingLeft: 0}}/>
                                <FormLabel>{t('memberAccountStep.acceptText')} <Link to={{pathname: "https://hellogrouper.com/app-terms-of-use"}}
                                                target="_blank"
                                                 className={"RegistrationForm_TermsOfUseLink"}
                                                 >{t('memberAccountStep.termsOfUseLinkText')}</Link></FormLabel>
                            </div>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
               
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
                <Grid item xs={7}>
                </Grid>
                <Grid item xs={5}>
                    <FormControl>
                        <PlayButton type="button"
                                    clickHandler={onContinueSelected}
                                    text={t('memberAccountStep.registerButtonText')}
                                    className="MemberRegistrationWizard_SubmitButton"
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={!continueEnabled()}
                                    id="EmailPassSubmit"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
    }
