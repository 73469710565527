import React, {useContext, useEffect} from "react";
import {RouteComponentProps, Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./CenteneLandingPage.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import clubLeaderLanding from "../../assets/centene-landing.png";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import {UserContext} from "../../contexts/UserContext";
import { storeRegistrationParametersToSession } from "../../util/Util";

/**
 * A page with static content that contains a link to register as a member.
 */
export default function MemberLandingPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {history} = props;
    const {user} = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    const onClickSignUpButton = () => {
        history.push("/member/wellcare-registration");
    }

    return (
        <div className="MemberLandingPage">
            <div id="MemberLanding_Banner">
                <div id="MemberLanding_Text">
                    <p id="CCClubLeaderLanding_Subtext">
                        <strong>{t('centene.subtext')}</strong>
                    </p>
                    <div id="CCMemberLanding_Register">
                        <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                            <PlayButton text={t('centene.playText')}
                                        buttonStyle={ButtonStyles.FilledPrimary}
                                        disabled={user !== null}
                                        clickHandler={onClickSignUpButton}/>
                        </div>
                    </div>
                    <div id="MemberLanding_Login">
                        <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                            <p id={"CCClubLeaderLanding_SignInText"}>
                                {t('centene.alreadyAccount')}&nbsp;
                                <Link to={"/login"}>{t('centene.logIn')}</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="CenteneLanding_Image_Wrapper">
                    <img src={clubLeaderLanding}
                         alt={"Centene Landing"}/>
                </div>
            </div>
            <div id={"CCMemberLanding_FAQ_Wrapper"}>
                <div id={"CCMemberLanding_FAQ"}>
                    <h2 id={"CCClubLeaderLanding_Learn_More_Header"}>{t('centene.aboutHeader')}</h2>
                    <div id={"CCMemberLanding_FAQ_Text"}>
                        <div className="Member_About_Text"
                             dangerouslySetInnerHTML={{__html: t('centene.about1')}}>
                        </div>

                        <div className="Member_About_Text"
                             dangerouslySetInnerHTML={{__html: t('centene.about2')}}>
                        </div>

                        <div className="Member_About_Text"
                             dangerouslySetInnerHTML={{__html: t('centene.about3')}}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
