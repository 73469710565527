
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const GET_PROFILE_INFO_URL = '/my-profile-info'

const getMyProfileInfo = async (): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_PROFILE_INFO_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export default getMyProfileInfo
