import React, { useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

import "../NewMemberRegistrationWizard.scss";
import {TextField, Tooltip} from "@material-ui/core";
import Button, {ButtonStyles} from "../../../components/common/Button";
import {applyMaxMinChecking, isNumber} from "../../../util/ignite/Dashboard.utils";
import {memberDashboardConfig} from "../../../configs/memberDashboard";
import Loading from "../../../components/common/Loading";
import PortableText from "react-portable-text";
import useGetCustomEnrollmentInfo from "../../../hooks/useGetCustomEnrollmentInfo";
import { ActivityRecordParameters, MemberReportActivity, MonthObject } from '../types'

const MAPS = ['myjongg', 'usbc', 'mga', 'ama', 'rhs', 'uschess']

export default function NewMemberReportActivityStep(props: MemberReportActivity): JSX.Element {
    const { t } = useTranslation('pages');

    const { clubShortCode } = props;
    const [saving, setSaving] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [records, setRecords] = useState<{ [id: number] : ActivityRecordParameters; }>({});
    const [months, setMonths] = useState<MonthObject[]>([]);
    const [customEnrollmentInfo, isCustomEnrollmentInfoLoading] = useGetCustomEnrollmentInfo(clubShortCode, !props.existingUser);
    const isClubInMap = MAPS.includes(clubShortCode);

    useEffect(() => {
        if (customEnrollmentInfo?.userActivities?.activityRecords || !props.existingUser) {
            const dateIterator = new Date();
            dateIterator.setMonth(dateIterator.getMonth() - 1);
            const monthsBuilder : MonthObject[] = [];
            const recordBuilder: { [id: number] : ActivityRecordParameters; } = {};
            for (let i = 0; i < 6; i++) {
                let currentYear = dateIterator.getFullYear();
                const monthIndex = dateIterator.getMonth();
                let activityTotal = undefined;
                customEnrollmentInfo?.userActivities?.activityRecords?.forEach((record: any) => {
                    if (record.month === monthIndex && record.year === currentYear && record.day === 1) {
                        activityTotal = record.activityTotalInPerson;
                        return;
                    }
                });

                recordBuilder[monthIndex] = {
                    month: monthIndex+1,
                    year: currentYear,
                    activityTotal: activityTotal,
                    isValid: false
                };

                dateIterator.setMonth(dateIterator.getMonth() - 1);
                const monthObject : MonthObject = {index: monthIndex, value: activityTotal};
                monthsBuilder.push(monthObject);
            }
            setMonths(monthsBuilder.reverse());
            setRecords(recordBuilder);
        }
    }, [isCustomEnrollmentInfoLoading]);

    const continueEnabled = () => {
        setIsValid(
          !Object.entries(records).some(([, value]) => !value.isValid)
        );
    }

    const onValidation = (data: any) => {
        const updatedRecords = records;
        updatedRecords[data.month].isValid = data.isValid;
        updatedRecords[data.month].activityTotal = Number(data.activityTotal ?? 0);
        setRecords({...updatedRecords});

        continueEnabled();
    }

    const onContinueSelected = () => {
        setSaving(true);
        props.onContinue({activityRecords: Object.values(records)});
    }

    if (isCustomEnrollmentInfoLoading && props.existingUser) {
        return <Loading loading={isCustomEnrollmentInfoLoading} className={"NewMemberRegistrationWizard_Loading"}/>
    }

    return (
        <div className={`NewMemberRegistrationWizard_Wrapper`}>
            {props.experimentCode &&
                <h2>{t('newMemberRegistration.reportActivityHeader')}</h2>
            }
            {!props.experimentCode &&
                <h2>
                    {t(`customEnrollment.${!isClubInMap ? 'reportActivityHeader' : `${clubShortCode}.header`}`)}
                </h2>
            }
            <div className={"NewMemberRegistrationWizard_Wrapper_InnerContainer"}>
                <div className="NewMemberReportActivityStep_SubHeader">{t('newMemberRegistration.reportActivitySubheader')}</div>
                {!props.reportActivityPageContent &&
                    <p className="NewMemberReportActivityStep_Details" dangerouslySetInnerHTML={{__html: t(`${
                        !isClubInMap ?
                        'newMemberRegistration.reportActivityDetails'
                        :
                        `customEnrollment.${clubShortCode}.reportActivityDetails`
                    }`)}}/>
                }
                {props.reportActivityPageContent &&
                    <div className="NewMemberReportActivityStep_Details">
                        {!isClubInMap ?
                        <PortableText content={props.reportActivityPageContent}></PortableText>
                        :
                        <p
                            className="NewMemberReportActivityStep_Details"
                            dangerouslySetInnerHTML={{__html: t(`customEnrollment.${clubShortCode}.reportActivityDetails`)}}
                        />
                        }
                    </div>
                }
                {!isClubInMap && <p className="NewMemberReportActivityStep_DetailsLegal">{t(`newMemberRegistration.reportActivityDetailsLegal`)}</p>}
                <p className="NewMemberReportActivityStep_InfoText">
                    {t(`${
                        !isClubInMap ? 
                        'newMemberRegistration.reportActivityInfoText'
                        :
                        `customEnrollment.${clubShortCode}.reportActivityInfoText`
                    }`)}
                </p>
                <div className={'NewMemberReportActivityStep_MonthInputContainer'}>
                    {months && months.map(month => (
                        <ReportActivityMonthInput month={month.index} key={month.index} value={month.value} onValidation={onValidation}/>
                    ))}
                </div>
                {props.experimentCode &&
                    <div className="NewMemberReportActivityStep_Confirmation"
                         dangerouslySetInnerHTML={{__html: t(`newMemberRegistration.confirmation-${props.experimentCode.replace(/\D/g, '')}`)}}></div>
                }
                {!props.experimentCode &&
                    <div className="NewMemberReportActivityStep_Confirmation">
                        {t('customEnrollment.reportActivityConfirmation')}
                    </div>
                }
                <div className={"NewMemberReportActivityStep_NextContainer"}>
                    {!saving && <Button type="button"
                            id="ReportActivitySubmit"
                            clickHandler={onContinueSelected}
                            text={t('newMemberRegistration.submitButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                            disabled={!isValid}
                    />}
                    {saving && <Loading className={"NewMemberRegistrationWizard_SubmitButtonLoading"} loading={saving}/>}
                    <Button type="button"
                            clickHandler={props.onPrevious}
                            text={t('newMemberRegistration.backButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                    />
                </div>
            </div>
        </div>
    );
}

function ReportActivityMonthInput({month, value, onValidation}: {month: number, value: number|undefined, onValidation: Function}): JSX.Element {
    const { t } = useTranslation('pages');
    const [amount, setAmount] = useState<number|undefined>(value);
    const [error, setError] = useState<string>('');
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

    useEffect(() => {
        // A value of '0' is valid, so we need to check for undefined
        if (value !== undefined) {
            validateTotal(String(value));
        }
    }, []);

    const validateTotal = (value: string) => {
        if (!value.trim()) {
            onValidation({
                month: month,
                isValid: false,
                activityTotal: undefined
            });
            setError(t('newMemberRegistration.invalidMonthlyActivity', { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH }));
            return;
        }
        const regex=/^\d+$/;
        if (!value.match(regex))
        {
            onValidation({
                month: month,
                isValid: false,
                activityTotal: undefined
            });
            setError(t('newMemberRegistration.invalidMonthlyActivity', { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH }));
            return;
        }
        if (Number(value) > memberDashboardConfig.MAX_ACTIVITY_PER_MONTH) {
            showTooltip();
        }
        onValidation({
            month: month,
            isValid: true,
            activityTotal: amount
        });
        setError('');
    }

    const onActivityChange = (data: React.ChangeEvent<HTMLInputElement>) => {
        const value = data.target.value;
        validateTotal(value);
        const trimmedValue = value.trim();

        if(trimmedValue.length === 0 || !isNumber(trimmedValue)) {
            setAmount(undefined);
            data.target.value = '';
            return;
        }
        
        const formattedValue = Number(applyMaxMinChecking(value));
        setAmount(formattedValue);
    }

    const showTooltip = () => {
        setShouldShowTooltip(true);
        setTimeout(() => setShouldShowTooltip(false), memberDashboardConfig.TOOLTIP_HIDE_TIME);
    }
    const tooltipTitle = t('newMemberRegistration.activityMaxLimit', { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH })

    return (
        <Tooltip
            placement="top"
            open={shouldShowTooltip}
            arrow
            title={tooltipTitle}
            classes={{ tooltip: 'dashboard-tooltip', arrow: 'arrow' }} >
            <div className={'NewMemberReportActivityStep_MonthInputElementContainer'}>
                <div className={'NewMemberReportActivityStep_MonthInput'}>
                    <span className={'NewMemberReportActivityStep_MonthInputLabel'}>
                        {t(`newMemberRegistration.months.${month+1}`)}
                    </span>
                    <TextField
                        name={`month-${month}`}
                        className="MemberRegistrationWizard_Input"
                        variant={"outlined"}
                        required
                        value={amount}
                        onChange={onActivityChange}
                        onBlur={() => validateTotal(String(amount))}
                        size="small"/>
                </div>
                {error !== undefined && <div className={'MonthInputLabel_Errored'}>
                    {error}
                </div>}
            </div>
        </Tooltip>
    );
}
