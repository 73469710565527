import i18next from "i18next";
import {SanityTextObject, getSanityResponse} from "./SanityUtils";

export interface EligibilityFormSanityResponse {
    ms: number;
    query: string;
    result: EligibilityFormSanityData[];
}

export interface EligibilityFormSanityData {
    title: SanityTextObject [];
    description: SanityTextObject [];
    memberHelperText: string;
    selectPayerLabel: string;
    memberIDLabel: string;
    dateOfBirthLabel: string;
    phoneNumberLabel: string;
    zipCodeLabel: string;
}

const getEligibilityFormViaSanity = async (formSlug: string): Promise<EligibilityFormSanityResponse | undefined> => {
    const LANGUAGE = i18next.language.split('-')[0];
    let query = encodeURIComponent(`
        *[_type == "eligibility_form" && slug == "${formSlug}"]
        {
            "title": pageTitle[_key == "${LANGUAGE}"][0].value,
            "description": description[_key == "${LANGUAGE}"][0].value,
            "memberHelperText": memberHelperText[_key == "${LANGUAGE}"][0].value,
            "selectPayerLabel": selectPayerLabel.${LANGUAGE},
            "memberIDLabel": memberIDLabel.${LANGUAGE},
            "dateOfBirthLabel": dateOfBirthLabel.${LANGUAGE},
            "phoneNumberLabel": phoneNumberLabel.${LANGUAGE},
            "zipCodeLabel": zipCodeLabel.${LANGUAGE},
        }
    `);

    return getSanityResponse(query);
}

export default getEligibilityFormViaSanity;