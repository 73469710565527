import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { GetClubLeaderEventOrOccurrenceResponseBody, GetClubLeaderEventResponseBody } from '../services/ClubLeaderService'
import { getJsonAuth } from '../services/RequestService'

export async function getClubEventOrOccurrencesByDateRange(
    clubId: number | null,
    leaderEmail: string | null,
    eventStatus: number | null,
    startDate: string | null,
    endDate: string | null)
    : Promise<GetClubLeaderEventOrOccurrenceResponseBody[]> {

    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/events/separate-occurrences`,
        searchParams: {
            clubId,
            leaderEmail,
            eventStatus,
            startDate,
            endDate
        }
    });
}

export async function getClubEventsByDateRange(
    clubId: number | null,
    leaderEmail: string | null,
    eventStatus: number | null,
    startDate: string | null,
    endDate: string | null)
    : Promise<GetClubLeaderEventResponseBody[]> {

    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/events`,
        searchParams: {
            clubId,
            leaderEmail,
            eventStatus,
            startDate,
            endDate
        }
    });
}