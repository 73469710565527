import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { getClubPageRouteLink, getIgniteRoute } from "../../../util/ignite/routes.utils";
import { CLUB_ROLES_STRINGS } from "../../../constants/dashboard";
import { getClubLeaderData } from "../../../util/ignite/Dashboard.utils";
import { ProfilePanel } from "./ProfilePanel";
import { LeaveClubModal } from "./LeaveClubModal";
import { JoinClubModal } from "./JoinClubModal";
import { IClub } from "../../../types/clubFinder";
import postLeaveClub from "../../../api/postLeaveClub";
import postJoinClub from "../../../api/postJoinClub";
import "./Clubs.scss";
import useGetMyClubs from "../../../hooks/useGetMyClubs";
import useGetMyPendingClubs from "../../../hooks/useGetMyPendingClubs";
import { SuccessfulLeavingModal } from "./SuccessfulLeavingModal";
import { SuccessfulJoiningModal } from "./SuccessfulJoiningModal";
import { ClubType } from "../../../types/club";
import Chip from "@material-ui/core/Chip";

export default function Clubs({ email, showAssociations }: { email: string, showAssociations: boolean }) {
    const { t } = useTranslation('ignite/myInfo');
    const [leaveClub, setLeaveClub] = useState<IClub>()
    const [joinClub, setJoinClub] = useState<IClub>()
    const [clubName, setClubName] = useState<string | undefined>(undefined)
    const [leaveClubIndex, setLeaveClubIndex] = useState<number>()
    const [joinClubIndex, setJoinClubIndex] = useState<number>()
    const [isLeavingSuccessful, setIsLeavingSuccessful] = useState<boolean>()
    const [isJoiningSuccessful, setIsJoiningSuccessful] = useState<boolean>()
    const { clubId = '' } = useParams<{ clubId: string }>()
    const { join = '' } = useParams<{ join: string }>()
    const [myClubs, isMyClubLoading, fetchClubs] = useGetMyClubs()
    const [myPendingClubs, isMyPendingClubLoading, fetchPendingClubs] = useGetMyPendingClubs()
    const clubsOrAssociations = showAssociations ? myClubs?.filter(club => club.type === ClubType.ASSOCIATION) : myClubs?.filter(club => club.type === ClubType.CLUB)
    const pendingClubsOrAssociations = showAssociations ? myPendingClubs?.filter(club => club.type === ClubType.ASSOCIATION) : myPendingClubs?.filter(club => club.type === ClubType.CLUB)
    const [inputClubId, setInputClubId] = useState<number>(clubId ? Number(clubId) : 0 )
    const [joinFlag, setJoinFlag] = useState<number>(join ? Number(join) : 0)
    const [clubTojoin, setClubToJoin] = useState<number>(0)
     const history = useHistory()

     useEffect(() => {
        if (joinFlag == 1){
            const clubsForInput = pendingClubsOrAssociations.filter(club => club.id === inputClubId)
            if (clubsForInput.length) {
                setClubToJoin(inputClubId);
            }
        }
    }, [inputClubId, joinFlag, pendingClubsOrAssociations,setClubToJoin]);

    
    const renderMyClubs = () => {
        return (
            <ProfilePanel title={showAssociations ? t('tabs.associations') : t('tabs.clubs')} className="clubs">
                <>
                    <LeaveClubModal
                        clubName={clubName}
                        isOpen={Boolean(leaveClub)}
                        onClose={() => {
                            setLeaveClub(undefined)
                            setClubName(undefined)
                        }}
                        onConfirm={onLeaveClub}
                        isLeaderOrAdmin={Boolean(isLeaderOrAdmin)}
                        isAssociation={showAssociations}
                    />
                    <SuccessfulLeavingModal
                        clubName={clubName}
                        isOpen={Boolean(isLeavingSuccessful)}
                        onClose={() => refreshPage()}
                    />
                    <div className="club-list">
                        {clubsOrAssociations?.map((club: IClub) => {
                            let clubUrlFragment : string = '';
                            if (club.urlFragment){
                                clubUrlFragment = club.urlFragment
                            }
                            const { city, countrySubdivision } = club.location || {}
                            const locationRow = [city, countrySubdivision].filter(Boolean).join(', ')
                            const leaderName = getClubLeaderData(club)
                            const isLeader = club.role !== CLUB_ROLES_STRINGS.MEMBER
                            const igniteRoute = isLeader ? getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': String(club.id) }) :
                                getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': String(club.id) })
                            let eventPortalRoute = isLeader ? `/my-club/${clubUrlFragment}` : getClubPageRouteLink(false, clubUrlFragment);
                            let route = club?.isIgniteEnabled ? igniteRoute : eventPortalRoute
                            if (club.isVirtual=== true){
                                route = getClubPageRouteLink(true, clubUrlFragment);
                            }
     
                            return (
                                <div
                                    key={club.id}
                                    className="club-item"
                                >
                                    <div className="left-content" >
                                        <div className="club-item__image-wrapper" onClick={() => history.push(route)}>
                                            <img src={"/images/passions/" + club.clubImage} alt="" className='club-item__image' />
                                        </div>
                                        <div className="club-item__description" >
                                            <div className="club-item__name" onClick={() => history.push(route)}>
                                                {club.clubName}
                                                &nbsp;&nbsp;
                                                {club.isVirtual === true &&  <Chip className={"ClubView_Chip Virtual_Full"} label={t('virtualClub')}/>}
                                       
                                            </div>
                                             {city && (
                                                <div className="club-item__location" onClick={() => history.push(route)}>
                                                    {locationRow}
                                                </div>
                                            )}
                                            {leaderName &&
                                                <div className="club-item__leader" onClick={() => history.push(route)}>
                                                    {t('leader')}
                                                    {' '}
                                                    {leaderName}
                                                </div>
                                            }
                                            {isLeader && club.isVirtual === true && <div className="Events_Link"  onClick={() => history.push(eventPortalRoute)} >
                                    {t('eventsLink')}
                                </div>}
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <div className="club-item__role">
                                            {t(`role.${club.role}`)}
                                        </div>
                                        <div className="club-item__action" onClick={() => {
                                            setLeaveClub(club)
                                            setClubName(club.clubName)
                                        }}>
                                            {showAssociations ? t('leaveAssociation') : t('leaveClub')}
                                        </div>
                                    </div>
                                </div>)
                        })}

                    </div>

                </>
            </ProfilePanel>
        );

    }

    const renderMyPendingClubs = () => {
        return (
            <ProfilePanel title={showAssociations ? t('tabs.associations') : t('tabs.pendingClubs')} className="clubs">
                <>
                    <JoinClubModal
                        clubName={clubName}
                        isOpen={Boolean(joinClub)}
                        onClose={() => {
                            setJoinClub(undefined)
                            setClubName(undefined)
                        }}
                        onConfirm={onJoinClub}
                        isAssociation={showAssociations}
                    />
                    <SuccessfulJoiningModal
                        clubName={clubName}
                        isOpen={Boolean(isJoiningSuccessful)}
                        onClose={() => refreshPage()}
                    />
                    <div className="club-list">
                        {pendingClubsOrAssociations?.map((club: IClub) => {
                            const showJoinButton = (clubTojoin === club.id)
                            const { city, countrySubdivision } = club.location || {}
                            const locationRow = [city, countrySubdivision].filter(Boolean).join(', ')
                            const leaderName = getClubLeaderData(club)
                            const route = `/club/${club.urlFragment}`
                            let joinButtonText  = ''
                            if (showJoinButton){
                                joinButtonText = showAssociations ? t('joinAssociation') : t('joinClub')
                            }
                             return (
                                <div
                                    key={club.id}
                                    className="club-item"
                                >
                                    <div className="left-content" onClick={() => history.push(route)}>
                                        <div className="club-item__image-wrapper">
                                            <img src={"/images/passions/" + club.clubImage} alt="" className='club-item__image' />
                                        </div>
                                        <div className="club-item__description">
                                            <div className="club-item__name">
                                                {club.clubName}
                                            </div>
                                            {city && (
                                                <div className="club-item__location">
                                                    {locationRow}
                                                </div>
                                            )}
                                            {leaderName &&
                                                <div className="club-item__leader">
                                                    {t('leader')}
                                                    {' '}
                                                    {leaderName}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <div className="club-item__role">
                                        {t('pendingMember')}
                                        </div>                                          
                                         {showJoinButton &&    
                                          <div className="club-item__action" onClick={() => {
                                            setClubToJoin(0)
                                            setJoinClub(club)
                                            setClubName(club.clubName)
                                        }}>
                                            {joinButtonText}
                                        </div>   }         
                                                         
                                    </div>
                                </div>)
                        })}

                    </div>

                </>
            </ProfilePanel>
        )

    }
    const isLeaderOrAdmin = leaveClub?.primaryClubLeaders.some(leader => leader.email === email) ||
        leaveClub?.secondaryClubLeaders.some(leader => leader.email === email) || false

    const onLeaveClub = () => {
        postLeaveClub(Number(leaveClub?.id))
            .then(() => fetchClubs())

        var leavedClub = clubsOrAssociations?.find(club => club.id === leaveClub?.id && club.id === parseInt(clubId))

        if (leavedClub != null) {
            var leaveClubIndex = clubsOrAssociations.indexOf(leavedClub)
            setLeaveClubIndex(leaveClubIndex)
        }
        setLeaveClub(undefined)
        setIsLeavingSuccessful(true)
    }
    const onJoinClub = () => {
        postJoinClub(Number(joinClub?.id))
            .then(() => fetchClubs())

        var joinedClub = pendingClubsOrAssociations?.find(club => club.id === joinClub?.id && club.id === parseInt(clubId))

        if (joinedClub != null) {
            var joinClubIndex = pendingClubsOrAssociations.indexOf(joinedClub)
            setJoinClubIndex(joinClubIndex)
        }
        setJoinClub(undefined)
        setIsJoiningSuccessful(true)
    }

    const refreshPage = () => {
        setClubName(undefined)
        setIsLeavingSuccessful(undefined)

        if (leaveClubIndex != null && clubsOrAssociations?.length > 0) {
            var refreshClubId = clubsOrAssociations[0]?.id

            if (leaveClubIndex > 0) {
                refreshClubId = clubsOrAssociations[leaveClubIndex]?.id
            }
            // Association and club list have different routes
            history.push(`/ignite/profile/${showAssociations ? 'associations' : 'club'}/${refreshClubId}`)
        }
     
        if (joinClubIndex != null && pendingClubsOrAssociations?.length > 0) {
            var refreshClubId = pendingClubsOrAssociations[0]?.id

            if (joinClubIndex > 0) {
                refreshClubId = pendingClubsOrAssociations[joinClubIndex]?.id
            }
        }

        window.location.reload()
    }

    if (!clubsOrAssociations.length && !pendingClubsOrAssociations.length) {
        return (
            <ProfilePanel title="" className="clubs">
                <>
                    <SuccessfulLeavingModal
                        clubName={clubName}
                        isOpen={Boolean(isLeavingSuccessful)}
                        onClose={() => refreshPage()}
                    />
                    <div className="noClubs">
                        {showAssociations ? t('tabs.noAssociations') : t('tabs.noClubs')}
                    </div>
                </>
            </ProfilePanel>
        );
    }
    
    if (!clubsOrAssociations.length) {
        return (
            <>
                {renderMyPendingClubs()}
            </>    
        );
    }

    if (!pendingClubsOrAssociations.length) {
        return (
            <>
                {renderMyClubs()}
            </>    
        );
    }

    return (
        <>
            {renderMyClubs()}
            {renderMyPendingClubs()}
        </>

    );
}

