import React from "react";
import "./DateView.scss";
import { LocalDate } from "@js-joda/core";

import { formatLastEventOccurrenceDate } from "../../util/Event.utils";
import { EventOccurrence } from "../../services/ClubLeaderService";
import { SupportedTimeZone } from "../../util/SupportedTimeZone";
import { useTranslation } from "react-i18next";

export interface RecurrenceViewProps {
    timeZone: SupportedTimeZone
    occurrences: EventOccurrence[]
    className?: string;
}

export default function OccurrenceLastMeetingView(props: RecurrenceViewProps): JSX.Element {
    const { t } = useTranslation('event');

    let classes = 'CCDateView';
    let className = props.className;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    let pastOccurrenceExists = props.occurrences.some(occurrence => occurrence.endsAtInstant < LocalDate.now().toString());

    return (
        pastOccurrenceExists 
            ? <>
                <div className={classes}>
                    <em>
                        {t('eventRecurrence.lastMeeting')}
                    </em>
                    &nbsp;
                    {formatLastEventOccurrenceDate(props.occurrences)}
                </div>
            </>
            : <></>
    );
}
