import React, { useContext, useEffect, useState } from "react";
import "./AcblRegistrationWizard.scss";
import { RouteComponentProps } from "react-router-dom";
import { useFormState } from "react-use-form-state";
import { SelectedPayerContext } from "../../../contexts/SelectedPayerContext";
import { MemberName, MemberNameStep } from "../steps/MemberNameStep";
import { MemberAccount, MemberAccountStep } from "../steps/MemberAccountStep";

//Adding for Registration Submission - Refactor later
import {
    AcblInitialRegistrationRequestBody,
    AcblRegistrationRequestBody, AcblRegistrationResponseBody,
    getAcblMember,
    registerAcblMember,
    updateAcblMember
} from "../../../services/UserService";
import { UserContext } from "../../../contexts/UserContext";

import WizardProgress from "../wizardProgress/WizardProgress";
import { sendPageView } from "../../../withTracker";
import {
    UserAgreementModel,
    UserRegistrationFlow
} from "../../../services/Models";
import { readUtmRegistrationParametersFromSession } from "../../../util/Util";
import { MemberSelectPayerStep } from "../steps/MemberSelectPayerStep";
import { useTranslation } from "react-i18next";
import { AcblBanner } from "../../../components/acblBanner/AcblBanner";
import { getUserAgreement } from "../../../services/UserAgreementService";

export const ACBL_LOGIN_FLOW_STORAGE_KEY = "ClubCalendarACBLLoginFlowStorageKey";

export interface AcblLoginFlowData {
    email: string;
}

/**
 * The first step in the club member registration process in which the user selects
 * their payer and enters their insurance id.
 *
 * The user arrives here from the `AcblLandingPage`.
 */
export default function AcblRegistrationWizard(props: RouteComponentProps): JSX.Element {

    //////////////////////////////////////////////////////
    const {user,authenticatedFetch, tokenLogin} = useContext(UserContext);
    //////////////////////////////////////////////////////
    const {history, location} = props;

    const initialState = {
        phone: "",
        firstName: "",
        lastName: "",
        zipCode: null,
        renewId: null,
        payerId: null,
        dateOfBirth: null,
        acblNumber: null,
        healthPlanName: null
    }

    // for capturing state of each wizard step...
    const [, setMemberAccount] = useState<MemberAccount>({
        email: "",
        termsOfUse: false
    });
    const [, setMemberName] = useState<MemberName>({
        firstName: "",
        lastName: "",
        acblMemberNumber: ""
    });
    const [acblMember, setAcblMember] = useState<AcblRegistrationResponseBody | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const {t} = useTranslation('pages');

    const [formState] = useFormState(initialState);

    // steps in the registration flow provided by Erick
    enum WizardSteps {
        Account,
        FullName,
        Eligibility
    }

    const steps = [
        WizardSteps.Account,
        WizardSteps.FullName,
        WizardSteps.Eligibility
    ];

    // current wizard step
    let [currentStepPosition, setCurrentStepPosition] = useState(0);
    const [wizardStep, setWizardStep] = useState(steps[currentStepPosition]);
    const [emailSent, setEmailSent] = useState(false);
    const [userAgreement, setUserAgreement] = useState<UserAgreementModel>();

    useEffect(() => {
        sendPageView(
            `${history.location.pathname}?step=${wizardStep.valueOf() + 1}`,
            ` - ACBL Registration Step ${wizardStep.valueOf() + 1}`);
    }, [wizardStep]);

    const {setPayerDetails} = useContext(SelectedPayerContext);

    function moveToStep(targetStepPosition: number) {
        setCurrentStepPosition(targetStepPosition);
        setWizardStep(steps[targetStepPosition]);
    }

    // "continue" selected from Wizard sections; just changes the current
    async function onContinueSelected(data: any) {
        switch (wizardStep) {
            case WizardSteps.Account:
                setMemberAccount({...data});
                formState.setField('email', data.email);
                await initialRegistration(data.email);
                break;
            case WizardSteps.FullName:
                setMemberName({...data});
                formState.setField('firstName', data.firstName);
                formState.setField('lastName', data.lastName);
                formState.setField('acblMemberNumber', data.acblMemberNumber);
                // @ts-ignore
                setAcblMember({
                    ...acblMember,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    acblMemberNumber: data.acblMemberNumber
                })
                await updateRegistration({
                    ...formState.values,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    acblMemberNumber: data.acblMemberNumber,
                    completeRegistration: false
                });

                moveToStep(currentStepPosition + 1);

                break;
            case WizardSteps.Eligibility: // this would be the final "submit" button; handle this a bit differently
                await onCompleteRegistration({...data});
                break;
            default:
                moveToStep(0);
        }
    }

    // "previous" selected from Wizard sections; just changes the current step to the previous step
    function onPreviousSelected() {
        switch (wizardStep) {
            case WizardSteps.FullName:
                break;
            case WizardSteps.Eligibility:
                moveToStep(steps.indexOf(WizardSteps.Eligibility) - 1);
                break;
            default:
                moveToStep(0);
        }
    }

    async function initialRegistration(email: string) {
        const parameters: AcblInitialRegistrationRequestBody = {
            email,
            ...readUtmRegistrationParametersFromSession(),
            registrationFlow: UserRegistrationFlow.CUSTOM_ENROLLMENT_FLOW
        };
        if (!parameters.utmReferringClub) {
            parameters.utmReferringClub = "ACBL";
        }
        const responseBody = await registerAcblMember(parameters);
        sendPageView(
            `${history.location.pathname}?step=complete`,
            ` - ACBL Registration Partial Registration Submitted`);
        if (responseBody.emailSent) {
            setEmailSent(true);
        } else if (responseBody.sendToLogin) {
            const loginFlowData: AcblLoginFlowData = { email };
            localStorage.setItem(ACBL_LOGIN_FLOW_STORAGE_KEY, JSON.stringify(loginFlowData));
            history.push("/login", { referrer: location.pathname });
        } else if (responseBody.token) {
            // One of the useEffects will automatically send the user to the
            // next step after login.
            await tokenLogin(responseBody.token);
        }
    }

    async function updateRegistration(data: any) {
        const { utmReferringClub } = readUtmRegistrationParametersFromSession();
        try {
            const body: AcblRegistrationRequestBody = {
                phone: data.phone,
                firstName: data.firstName,
                lastName: data.lastName,
                zipCode: data.zipCode,
                renewId: data.insuranceId,
                payerId: data.payerId,
                dateOfBirth: data.dateOfBirth,
                acblMemberNumber: data.acblMemberNumber,
                healthPlanName: data.healthPlanName,
                completeRegistration: data.completeRegistration,
                optInSweepstakes: data.optInSweepstakes,
                utmReferringClub: utmReferringClub ?? "ACBL"
            };
            await updateAcblMember({
                authenticatedFetch,
                body
            });
        } catch (e) {
            console.error('RegistrationError: ', e)
        }
    }


    async function onCompleteRegistration(data: any) {
        try {
            await updateRegistration({
                ...formState.values,
                phone: data.phone,
                zipCode: data.zipCode,
                insuranceId: data.insuranceId,
                payerId: data.payerId,
                healthPlanName: data.healthPlanName,
                dateOfBirth: data.dateOfBirth,
                optInSweepstakes: data.optInSweepstakes,
                completeRegistration: true,
            });
            setPayerDetails(null);

            sendPageView(
                `${history.location.pathname}?step=complete`,
                ` - ACBL Registration Complete`);

            history.push("/acbl/registration-complete")
        } catch (e) {
            console.error('RegistrationError: ', e)
        }
    }

    function renderAccount() {
        return (
            <div className="AcblRegistrationWizard">
                <MemberAccountStep onContinue={onContinueSelected}
                                   onPrevious={onPreviousSelected}></MemberAccountStep>
                <WizardProgress currentStep={currentStepPosition + 1}
                                totalSteps={steps.length}></WizardProgress>
            </div>
        );
    }

    function renderFullName() {
        return (
            <div className="AcblRegistrationWizard">
                <MemberNameStep onContinue={onContinueSelected}
                                onPrevious={onPreviousSelected}
                                acblMember={acblMember}></MemberNameStep>
                <WizardProgress currentStep={currentStepPosition + 1}
                                totalSteps={steps.length}></WizardProgress>
            </div>
        );
    }

    function renderEligibility() {
        return (
            <div className="AcblRegistrationWizard">
                <MemberSelectPayerStep onContinue={onContinueSelected}
                                onPrevious={onPreviousSelected}
                                acblMember={acblMember}
                                userAgreement={userAgreement!}></MemberSelectPayerStep>
                <WizardProgress currentStep={currentStepPosition + 1}
                                totalSteps={steps.length}></WizardProgress>
            </div>
        );
    }

    function renderWizardStep() {
        switch (wizardStep) {
            case WizardSteps.Account:
                return renderAccount();
            case WizardSteps.FullName:
                return renderFullName();
            case WizardSteps.Eligibility:
                return renderEligibility();
            default:
                return renderAccount();
        }
    }

    function renderEmailSent() {
        return (
            <div className="AcblRegistrationWizard AcblRegistrationWizard__EmailSent">
                <div className={"AcblRegistrationWizard__EmailSent__Title"}>{t("acblEmailSentPage.title")}</div>
                <div className={"AcblRegistrationWizard__EmailSent__Text"}>{t("acblEmailSentPage.text")}</div>
            </div>
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log('userAgreement:', userAgreement);
    }, [userAgreement]);

    useEffect(() => {
        (async () => {
            setUserAgreement(await getUserAgreement({slug: "acbl-launch-sweepstakes"}));
            if (user) {
                const acblMember = await getAcblMember({ authenticatedFetch });
                setAcblMember(acblMember);
                formState.setField("phone", acblMember.phone);
                formState.setField("firstName", acblMember.firstName);
                formState.setField("lastName", acblMember.lastName);
                formState.setField("zipCode", acblMember.zipCode);
                formState.setField("insuranceId", acblMember.renewId);
                formState.setField("payerId", acblMember.payerId);
                formState.setField("dateOfBirth", acblMember.dateOfBirth);
                formState.setField("acblMemberNumber", acblMember.acblMemberNumber);
                formState.setField("healthPlanName", acblMember.healthPlanName);
                formState.setField("optInSweepstakes", acblMember.optInSweepstakes);
                moveToStep(1);
                setLoading(false);
            } else {
                setLoading(false);
            }
        })();
    }, [user, loading]);

    if (loading) {
        return <></>
    }

    if (emailSent) {
        return <>
            <AcblBanner/>
            {renderEmailSent()}
        </>
    }

    return <>
        <AcblBanner/>
        {renderWizardStep()}
    </>
}
