import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import {UserContext} from "../../contexts/UserContext";
import "./ClubPage.scss";
import BackLink from "../../components/common/BackLink";
import ClubViewAsync from "../../components/club/ClubViewAsync";
import {RouteComponentProps} from "react-router-dom";
import ClubEvents from "../../components/event/ClubEvents";
import SubHeader from "../../components/ignite/SubHeader/SubHeader";
import useGetMyClubs from "../../hooks/useGetMyClubs";
import useGetClubByUrlFragment from "../../hooks/useGetClubByUrlFragment";

interface ClubPageParams{
    clubUrlFragment: string;
}

export default function ClubPage({match, location}: RouteComponentProps<ClubPageParams>): JSX.Element {
    const { t } = useTranslation('pages');
    const clubUrlFragment = match.params.clubUrlFragment;
    const {user, hasLeadershipRole} = useContext(UserContext);
    const [isLeader, setIsLeader] = useState<boolean>(true);
    const [club] = useGetClubByUrlFragment(clubUrlFragment)
    const [myClubs] = useGetMyClubs()

    const params = new URLSearchParams(location.search);
    const postalCode = params.get("postalCode") || undefined;

    window.scrollTo(0,0);

    useEffect( () => {
        hasLeadershipRole(clubUrlFragment).then(setIsLeader);
    }, [clubUrlFragment, isLeader]);

    return (
        <>
            {user !== null &&
            <div className="ClubPage">
                {club && <SubHeader
                    clubName={club.clubName}
                    myClubs={myClubs}
                    clubImage={club.passion?.imageFile}
                    location={club.location}
                    toClub
                />}
                { !isLeader &&
                    <BackLink to="-1">{t('clubPage.back')}</BackLink>
                }
                <ClubViewAsync clubUrlFragment={clubUrlFragment}/>
                <ClubEvents clubUrlFragment={clubUrlFragment} postalCode={postalCode}/>
            </div>
            }
        </>
    );
}
