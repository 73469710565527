import { useState, useEffect, useMemo } from "react";
import { IActivityItem } from '../types/dashboard'
import { FILTER_KEYS, filterOptions, FILTER_FIELDS } from '../constants/dashboard'
import { sortByTextField, sortByNumberField } from '../util/ignite/Dashboard.utils'
import { VERIFICATION_STATUS } from "../constants/verification";

const useSortMembersActivity = (
    membersActivity: IActivityItem[],
    myUserId?: number,
): [FILTER_KEYS, IActivityItem[], Function, Function, Function] => {
    const [sortName, setSortName] = useState(FILTER_KEYS.ELIGIBLE);
    const [filteredMembersActivity, setFilteredMembersActivity] = useState<IActivityItem[]>([])

    const setFilteredMembersActivityEnhanced = (members: IActivityItem[]) => {
        const membersActivityWithoutMyActivity = myUserId
            ? members.filter((activity) => activity.userId !== myUserId)
            : members;

        setFilteredMembersActivity(membersActivityWithoutMyActivity);
    }

    useEffect(() => {
        const sortedByLastName = sortByTextField(membersActivity, true, FILTER_FIELDS.LAST_NAME)

        setFilteredMembersActivityEnhanced(sortedByLastName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membersActivity, myUserId])

    const applySort = () => {
        const sortData = filterOptions.find(item => item.key === sortName)
        let newData: IActivityItem[] = []
        if (sortData?.field === FILTER_FIELDS.LAST_NAME) {
            newData = sortByTextField(membersActivity, sortData.isAsc, sortData.field)
        }

        if (sortData?.field === FILTER_FIELDS.SCORE || sortData?.field === FILTER_FIELDS.INCOME) {
            newData = sortByNumberField(membersActivity, sortData.isAsc, sortData.field)
        }

        if (sortData?.field === FILTER_FIELDS.ELIGIBLE) {
            newData = [...membersActivity.sort((prev: IActivityItem, next: IActivityItem) => {
                if (prev.eligibility === VERIFICATION_STATUS.ACTIVE && next.eligibility !== VERIFICATION_STATUS.ACTIVE) return -1
                if (next.eligibility === VERIFICATION_STATUS.ACTIVE && prev.eligibility !== VERIFICATION_STATUS.ACTIVE) return 1
                return prev.lastName?.localeCompare(String(next.lastName), 'en', { sensitivity: "base" }) || 1
            })]
        }

        if (sortData?.field === FILTER_FIELDS.NOT_ELIGIBLE) {
            newData = [...membersActivity.sort((prev, next) => {
                if (prev.eligibility !== VERIFICATION_STATUS.ACTIVE && next.eligibility === VERIFICATION_STATUS.ACTIVE) return -1
                return prev.lastName?.localeCompare(String(next.lastName), 'en', { sensitivity: "base" }) || -1
            })]
        }

        setFilteredMembersActivityEnhanced(newData)
    }

    useEffect(() => {
        applySort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortName])

    return [sortName, filteredMembersActivity, setFilteredMembersActivityEnhanced, setSortName, applySort]
}

export default useSortMembersActivity
