import { useState, useEffect, useRef } from "react";
import getMyActivity from '../api/getMyActivity'
import { IActivityItem } from '../types/dashboard'

const useGetMyActivity = (clubId: number): [IActivityItem | undefined, boolean, Function, IActivityItem | undefined] => {
    const [myActivity, setMyActivity] = useState<IActivityItem | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    const prevMyActivityRef = useRef<IActivityItem | undefined>()

    const getActivity = () => {
        prevMyActivityRef.current = myActivity

        getMyActivity(clubId).then(data => {
            setMyActivity(data)
        })
    }

    useEffect(() => {
        setIsLoading(true)

        getMyActivity(clubId).then(data => {
            prevMyActivityRef.current = data
            setMyActivity(data)
        })
            .finally(() => {
                setIsLoading(false)
            })
    }, [clubId])

    return [myActivity, isLoading, getActivity, prevMyActivityRef.current]
}

export default useGetMyActivity

