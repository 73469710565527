import React from "react";
import { useTranslation } from 'react-i18next';
import PlayIcon from '@material-ui/icons/PlayArrow';
import "./ChangeRoleModal.scss";
import { Button, Modal } from "@material-ui/core";

interface IChangeRoleModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    onConfirm: (event: React.MouseEvent) => void
    name: string
}

export default function ChangeRoleModal({ isOpen, onClose, onConfirm, name }: IChangeRoleModalProps) {
    const { t } = useTranslation('ignite/dashboard');

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className='change-role-modal'
        >
            <div className="change-role-modal-wrapper">
                <div className='content'>
                    <div className="title">
                        {t('changeRoleTitle')}
                    </div>

                    <div className="description">
                        {t('changeRoleDescription', { name })}
                    </div>
                    <Button
                        onClick={onConfirm}
                        className='button confirm'
                    >
                        {t('changeRoleConfirm')}
                        <PlayIcon className='icon' />
                    </Button>
                    <Button
                        onClick={onClose}
                        className='button cancel'
                        variant='outlined'
                    >
                        {t('changeRoleCancel')}
                    </Button>
                </div>

            </div>
        </Modal>
    );
}
