import {BorderedRowItem} from "../common/BorderedRow";
import React from "react";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";

import "./EventListButtonItem.scss";

export interface EventListButtonItemProps {
    link:string;
    buttonText: string;
}

export default function EventListButtonItem(props: EventListButtonItemProps): JSX.Element {

    return (
        <BorderedRowItem className="EventList_Button">
            <PlayButton
                to={props.link}
                text={props.buttonText}
                buttonStyle={ButtonStyles.UnfilledWithBorder}/>
        </BorderedRowItem>
    );
}