import React from "react";
import { useTranslation } from "react-i18next";
import "./AcblLaunchSweepstakes.scss";

export function AcblLaunchSweepstakes(): JSX.Element {
    const {t} = useTranslation('pages');
    return (
        <div className={"AcblLaunchSweepstakes"} dangerouslySetInnerHTML={{__html: t("acblLaunchSweepstakes")}}/>
    )
}
