import React from "react";
import { Box, Button, IconButton } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import { IClub } from '../../../types/clubFinder';
import { useTranslation } from "react-i18next";
import "./ClubItem.scss";

interface IClubItemProps {
    club: IClub,
    onClubSelected: (selectedClub: IClub) => void
}

const ClubItem = ({ club, onClubSelected }: IClubItemProps): JSX.Element => {
    const { t } = useTranslation('directToConsumer/clubFinder');
    const handleOnClick = () => onClubSelected(club);
    const {primaryClubLeaders = [], clubImage, clubName} = club;

    return (
        <Box className="club-item">
        <div className="club-item__content">
            <img src={"/images/passions/" + club.clubImage} alt="" className='club-item__logo' />
            <div>
            {club.clubName}
            {primaryClubLeaders.length > 0 && (
                <div style={{fontSize: 14, fontWeight:  400}}>
                    Club Leader:
                    &nbsp;{primaryClubLeaders[0].firstName}
                    &nbsp;{primaryClubLeaders[0].lastName}
                </div>
            )}
            </div>
        </div>
        <Button
            className="request-button"
            color="primary"
            variant="text"
            onClick={handleOnClick}
            endIcon={<PlayIcon/>}
        >
            {t('buttons.myClub.label')}
        </Button>
        </Box>
    )
}

export default ClubItem
