import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import {TextField} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {isValidEmail} from "../../../util/Util";

export function EmailInput(props: RegistrationInputProps): JSX.Element {
    const {t} = useTranslation('pages');
    const inputConfig: InputConfig<string> = {
        name: 'email',
        initialValue: "",
        validate: (value: string) => {
            if (value.trim() === "") {
                //return "Email is required.";
                return " ";
            }
            // Basic validation to prevent simple misentered values
            if (!isValidEmail(value)) {
                return t('registration.email.errors.format');
            }
        },
        validateOnBlur: true
    };

    const [formState, {email}] = useSubFormInput(inputConfig);

    return (
        <TextField {...email(inputConfig)}
                   className={`RegistrationWizard_Input EmailInput ${props.className || ''}`}
                   label={t('registration.email.label')}
                   required
                   variant={"outlined"}
                   error={formState.errors.email !== undefined}
                   helperText={formState.errors.email}
                   size="small"/>
    );
}