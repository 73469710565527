import i18next from "i18next";
import {SanityTextObject, getSanityResponse} from "./SanityUtils";

export interface ClubFinderFormSanityResponse {
    ms: number;
    query: string;
    result: ClubFinderFormSanityData[];
}

export interface ClubFinderFormSanityData {
    title: string;
    description: SanityTextObject[];
    ctaLabel: string;
    noResultsCta: SanityTextObject[] | undefined;
    resultsCta: SanityTextObject[] | undefined;
}

const getClubFinderViaSanity = async (formSlug: string): Promise<ClubFinderFormSanityResponse | undefined> => {
    const LANGUAGE = i18next.language?.split('-')[0] ?? 'en';
    let query = encodeURIComponent(`
        *[_type == "club_finder_form" && slug == "${formSlug}"]
        {
            "title": pageTitle.${LANGUAGE},
            "ctaLabel": addClubCTALabel.${LANGUAGE},
            "description": description[_key == "${LANGUAGE}"][0].value,
            "noResultsCta": noResultsCta[_key == "${LANGUAGE}"][0].value,
            "resultsCta": resultsCta[_key == "${LANGUAGE}"][0].value,
        }
    `);

    return getSanityResponse(query);
}

export default getClubFinderViaSanity;
