import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import CloseIcon from "@material-ui/icons/Close";
import CCModal from "../common/Modal";
import {EventFileSyncStatus, GetEventFileResponseBody} from "../../services/MemberService";
import {authenticatedFetch} from "../../services/UserService";
import {loadConfig} from "../../services/ConfigService";
import FullSizeThumbnail from "./FullSizeThumbnail";
import WordDocumentIcon from "./WordDocumentIcon";
import PdfDocumentIcon from "./PdfDocumentIcon";
import BrokenImageIcon from "../../assets/hide_image-24px.svg";
import './EventFile.scss';
import ImageWithFallback from "../common/ImageWithFallback";

interface EventFileProps {
    children: any;
    title: string;
    behavior: "download" | "view";
    url: string | null;
    className?: string;
}

export default function EventFile(props: EventFileProps): JSX.Element {

    let [dialogOpen, setDialogOpen] = useState(false);

    let className = "EventFile";
    let behavior = props.behavior;
    let url = props.url;
    let title = props.title;

    if (props.className !== undefined) {
        className += " " + props.className;
    }

    if (url === null) {
        className += " EventFile_Broken";
    }

    function onClick() {
        if (url === null) return;

        if (behavior === "view") {
            setDialogOpen(!dialogOpen);
        } else {
            window.open(url);
        }

    }

    return (<>
        <div onClick={onClick} className={className}>
            <div className={"EventFile_Children"}>{props.children}</div>
            <div className={"EventFile_Title"}>{props.title}</div>
            {url !== null &&
            <CCModal shown={dialogOpen} className={"EventFile_Modal"}>
                <div className={"EventFile_Modal_Close"}>
                    <CloseIcon fontSize="large" className="EventFile_Modal_CloseIcon"
                               onClick={onClick}/>
                </div>
                <img src={url} alt={title}/>
            </CCModal>
            }
        </div>
    </>);
}

interface EventFileListProps {
    title?: string;
    icon: any;
    instructions: string;
    className?: string;
    eventUrlFragment: string;
    showAllEventFiles?: boolean;
    setEventFiles?: Function;
}

export function EventFileList(props: EventFileListProps): JSX.Element {
    const { t } = useTranslation('eventFile');
    let [eventFiles, setEventFiles] = useState<GetEventFileResponseBody[] | null>(null);

    let eventUrlFragment = props.eventUrlFragment;
    let className = "EventFileList";

    let showAllEventFiles = false;
    if (props.showAllEventFiles !== undefined) {
        showAllEventFiles = props.showAllEventFiles;
    }

    if (props.className !== undefined) {
        className += " " + props.className;
    }

    useEffect(() => {
        async function loadEventFiles() {
            const config = await loadConfig();

            let request =
                new Request(
                    `${config.apiOrigin}/event-files/get/${eventUrlFragment}`,
                    {method: "GET", headers: {"Accept": "application/json"}});

            if (showAllEventFiles) {
                request =
                    new Request(
                        `${config.apiOrigin}/event-files/get/${eventUrlFragment}?includePendingAndFailed=true`,
                        {method: "GET", headers: {"Accept": "application/json"}});
            }

            const response = await authenticatedFetch(request);

            if (response === null || !response.ok) {
                console.log("Bad response on ", request);

                return;
            }

            let eventFiles = await response.json();

            setEventFiles(eventFiles);
            if (props.setEventFiles) {
                props.setEventFiles(eventFiles);
            }
        }

        if (eventFiles == null) {
            loadEventFiles();
        }

    }, [eventFiles, setEventFiles, eventUrlFragment, showAllEventFiles]);

    function isImage(eventFile: GetEventFileResponseBody) {
        return eventFile.contentType === "image/jpeg"
            || eventFile.contentType === "image/gif";
    }

    function isWordDocument(eventFile: GetEventFileResponseBody) {

        return eventFile.contentType === "application/vnd.openxmlformats-officedocument.wordprocessing"
            || eventFile.contentType === "application/msword"
            || eventFile.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }

    function isPdfDocument(eventFile: GetEventFileResponseBody) {
        return eventFile.contentType === "application/pdf";
    }

    return (<>
        {eventFiles != null && eventFiles.length > 0 &&
        <div className={className}>
            {props.title && <div className={"EventFileList_Title"}>{props.icon}
                <div className={"EventFileList_Title_Text"}>{props.title}</div>
            </div>}
            <div className={"EventFileList_Instructions"}>{props.instructions}</div>
            <div className={"EventFileList_Children"}>
                {Object.values(eventFiles).map(eventFile => {
                    let eventFileClassName = "EventFile_Status_Success";
                    if (showAllEventFiles && eventFile.syncStatus === EventFileSyncStatus.Pending) {
                        eventFileClassName = "EventFile_Status_Pending";
                    } else if (showAllEventFiles && eventFile.syncStatus === EventFileSyncStatus.Failure) {
                        eventFileClassName = "EventFile_Status_Failure";
                    }
                    if (isImage(eventFile)) {
                        const thumbnail = eventFile.thumbnails.find(t => t.width === 140 && t.height === 140);
                        const thumbnailUrl = (thumbnail === undefined) ? null : thumbnail.url;

                        return <EventFile className={eventFileClassName}
                                          behavior="view"
                                          key={eventFile.fileName}
                                          url={eventFile.url}
                                          title={eventFile.title}>
                            {eventFile.url === null &&
                            <img
                                className={"BrokenImageIcon"}
                                src={BrokenImageIcon}
                                alt={t('eventFile.missingImageAltText')}
                                title={t('eventFile.brokenImageTitleText')}
                            />
                            }
                            {eventFile.url !== null &&
                            <FullSizeThumbnail className={"FullSizeThumbnail"}>
                                <ImageWithFallback
                                    alt={eventFile.title}
                                    src={thumbnailUrl || eventFile.url}
                                    fallbackSrc={eventFile.url}
                                />
                            </FullSizeThumbnail>
                            }
                        </EventFile>
                    } else if (isWordDocument(eventFile)) {
                        return <EventFile className={eventFileClassName}
                                          behavior="download"
                                          key={eventFile.fileName}
                                          url={eventFile.url}
                                          title={eventFile.title}>
                            <WordDocumentIcon className={"WordDocumentIcon"}/>
                        </EventFile>
                    } else if (isPdfDocument(eventFile)) {
                        return <EventFile className={eventFileClassName}
                                          behavior="download"
                                          key={eventFile.fileName}
                                          url={eventFile.url}
                                          title={eventFile.title}>
                            <PdfDocumentIcon className={"PdfDocumentIcon"}/>
                        </EventFile>
                    } else {
                        return <></>
                    }
                })
                }
            </div>
        </div>
        }
    </>);
}
