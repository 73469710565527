import { SanityTextObject } from '../../sanity/SanityUtils'
import { IClubV2 } from '../../types/clubFinder'

export enum InputType {
  CHECKBOX = 'checkbox',
}

export interface DataGatheringQuestion {
    questionText: string
    inputType: InputType
}

export interface DataGatheringAnswer {
    questionText: string
    answer: string
}

export type MemberReportActivity = {
  onContinue: (data: any) => void
  onPrevious: () => void
  experimentCode: string|null
  reportActivityPageContent?: SanityTextObject[]
  clubShortCode: string
  existingUser: boolean
  additionalDataGatheringQuestions?: DataGatheringQuestion[]
  groupworksSelctedClub?: IClubV2 | undefined
}

export interface MonthObjectRecord {
  activityTotalInPerson?: number
  activityTotalVirtual?: number
}

export interface MonthObject {
  index: number
  value?: number
  record?: MonthObjectRecord
}

export interface ActivityRecordParameters {
  month: number
  year: number
  activityTotal?: number
  activityTotalInPerson?: number
  activityTotalVirtual?: number
  isValid?: boolean
}

export interface ReportActivityParameters {
  activityRecords : ActivityRecordParameters[]
  clubShortCode: string
  additionalEnrollmentQuestionsAnswers?: DataGatheringAnswer[]
}
