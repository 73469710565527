export const POSTAL_CODE_REGEX = /^[0-9]{5}$/;
export const SHORT_CODE_REGEX = /^[a-zA-Z0-9-]{4,}$/
export const PHONE_REGEX = /^[0-9]{10}$/
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
export const GROUP_NUMBER_REGEX = /^[0-9a-zA-Z-]{1,20}$/
export const OPTIONAL_GROUP_NUMBER_REGEX = /^[0-9a-zA-Z-]{0,20}$/
export const OPTIONAL_GROUP_NUMBER_MASK_REGEX = /^[*]{0,18}[0-9a-zA-Z-]{0,2}$/
export const MEMBER_ID_REGEX = /^[0-9a-zA-Z-]{4,20}$/
export const MEMBER_ID_MASK_REGEX = /^[*]{0,16}[0-9a-zA-Z-]{4}$/
export const CITY_REGEX = /^[0-9a-zA-Z.!#$%&’*+/=?^_'`"{|,\s}~-]{0,50}$/
export const STREET_REGEX = /^[0-9a-zA-Z.!#$%&’*+/=?^_'`",\s{|}~-]{0,100}$/
export const MEMBER_CODE_REGEX = /^[Aa|Ss]\d{9}$/
export const MEMBER_CODE_MASK_REGEX = /^[*]{6}\d{4}$/
export const PAYER_ID_REGEX = /^[0-9]{1,5}$/
