import React, {ChangeEvent, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import Loading from "../../../components/ignite/layout/Loading";
import ContactMethodsForm from "../../../components/ignite/ContactMethodsForm/ContactMethodsForm";
import getPreferredContact from '../../../api/getPreferredContact'
import postUpdatePreferredContact from '../../../api/postUpdatePreferredContact'
import { getPhoneUnmask } from '../../../util/ignite/MyInfo.utils'
import { IGNITE_ROUTE_KEY } from '../../../constants/routes'
import useClubById from '../../../hooks/useClubById'
import { useParams, useHistory } from 'react-router-dom'
import { PHONE_REGEX, EMAIL_REGEX } from '../../../constants/regex'
import { getIgniteRoute } from "../../../util/ignite/routes.utils";

import "./ContactMethods.scss";
import useGetMyProfileInfo from "../../../hooks/useGetMyProfileInfo";
import {CHECK_ELIGIBILITY_URL} from "../../../api/postCheckEligibility";
import useGetPayers from "../../../hooks/useGetPayers";

export default function ContactMethods(): JSX.Element {
    const { t } = useTranslation('ignite/contactMethods')
    const { clubId } = useParams()
    const history = useHistory()

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");

    const [club, loading] = useClubById(clubId)

    const [isEditing, setIsEditing] = useState(false);

    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('')

    const [preferredContactMethod, setPreferredContactMethod] = useState()
    const [myProfileInfo, isMyProfileInfoLoading] = useGetMyProfileInfo()
    const [payers, isGetPayersLoading] = useGetPayers()

    useEffect(() => {
        getPreferredContact().then(data => {
            const { email, phone, preferredContactMethod } = data || {}

            setEmail(email)
            setPhone(phone)

            setPreferredContactMethod(preferredContactMethod)
        })
    }, [])

    const onCheck = (preferredContactMethod: any) => (event: React.MouseEvent) => {
        !isEditing && setPreferredContactMethod(preferredContactMethod)
    }

    function onChangePhone(event: ChangeEvent<HTMLInputElement>) {
        const phoneValue = getPhoneUnmask(event.target.value)

        setPhone(phoneValue);

        if (phoneValue?.match(PHONE_REGEX)) {
            setPhoneError('')
        } else {
            setPhoneError(t('enterValidPhone'))
        }
    }

    function onChangeEmail(event: ChangeEvent<HTMLInputElement>) {
        const emailValue = event.target.value
        setEmail(emailValue);

        if (emailValue?.match(EMAIL_REGEX)) {
            setEmailError('')
        } else {
            setEmailError(t('enterValidEmail'))
        }
    }

    const onSave = () => {
        postUpdatePreferredContact({
            Email: email,
            Phone: getPhoneUnmask(phone),
            PreferredContactMethod: preferredContactMethod
        }).finally(() => {
            if (myProfileInfo.payerId && payers) {
                if (myProfileInfo.payerSlug && payers.find(p => p.id === Number(myProfileInfo.payerId))) {
                    history.push(`${CHECK_ELIGIBILITY_URL}/${clubId}/${myProfileInfo.payerSlug}/${myProfileInfo.payerId}`)
                } else {
                    history.push(`${CHECK_ELIGIBILITY_URL}/${clubId}/other/${payers.find(p => p.payerSlug === 'other')?.id}`)
                }
            } else {
                history.push(getIgniteRoute(IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_QUALIFICATION, { ':clubId': clubId }))
            }
        })
    }

    if (loading || isMyProfileInfoLoading || isGetPayersLoading) return <Loading loading={loading} />

    if (!club) return <div>{t('noSuchClub')}</div>

    const { clubName } = club

    return (
            <div className="ignite-page-content">
                <div className='title'>
                    {t('title', { clubName })}
                </div>
                <hr className='separator' />
                <div
                    className='intro-text'
                    dangerouslySetInnerHTML={{ __html: t('introText', { clubName }) }}
                />

                <ContactMethodsForm
                    isEditing={isEditing}
                    email={email}
                    onChangeEmail={onChangeEmail}
                    emailError={emailError}
                    phone={phone}
                    phoneError={phoneError}
                    onChangePhone={onChangePhone}
                    preferredContactMethod={preferredContactMethod}
                    onCheck={onCheck}
                    onSave={onSave}
                    setIsEditing={setIsEditing}
                />
            </div>
    );
}
