import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import './MemberRegistrationWizardStep.scss';
import { useFormState } from 'react-use-form-state';
import { FormHelperText, TextField } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import PlayButton from '../../../components/common/PlayButton';
import { ButtonStyles } from '../../../components/common/Button';
import Grid from '@material-ui/core/Grid';
import {
  AcblRegistrationResponseBody,
  checkAcblMemberNumber,
} from '../../../services/UserService';
import { UserContext } from '../../../contexts/UserContext';
import {
  validateAcblMemberId,
  AcblMemberIdValidationStatus,
} from './utils/validateAcblMemberId';

export type MemberName = {
  firstName: string;
  lastName: string;
  acblMemberNumber: string;
};

type StepProp = {
  onContinue: (data: MemberName) => void;
  onPrevious: () => void;
  acblMember: AcblRegistrationResponseBody | null;
};

const stringValidate = (v: string): string | undefined => {
  return v.trim() ? undefined : ' ';
};

export function MemberNameStep({
  acblMember,
  onContinue,
}: StepProp): JSX.Element {
  const { t } = useTranslation('pages');
  const { authenticatedFetch } = useContext(UserContext);
  const [formState, { text }] = useFormState({
    firstName: acblMember?.firstName,
    lastName: acblMember?.lastName,
    acblMemberNumber: acblMember?.acblMemberNumber,
  });

  const tValidateAcblMemberId = useCallback(
    (value: string) => {
      const status = validateAcblMemberId(value);

      switch (status) {
        case AcblMemberIdValidationStatus.BAD_FORMAT:
          return t('acblMemberNameStep.badFormatAcblMemberNumber');
        case AcblMemberIdValidationStatus.INVALID:
          return t('acblMemberNameStep.invalidAcblMemberNumber');
      }
    },
    [t],
  );

  async function checkAcblNumber() {
    const { acblMemberNumber } = formState.values;

    if (!acblMemberNumber) {
      return false;
    }

    if (elementConfig.acblMemberNumber.validate(acblMemberNumber)) {
      return false;
    }

    const { valid } = await checkAcblMemberNumber({
      authenticatedFetch,
      searchParams: { acblMemberNumber },
    });

    if (valid) {
      return true;
    }

    formState.setFieldError(
      elementConfig.acblMemberNumber.name,
      t('acblMemberNameStep.invalidAcblMembership'),
    );

    return false;
  }

  const elementConfig = {
    firstName: {
      name: 'firstName',
      validate: stringValidate,
      validateOnBlur: true,
    },
    lastName: {
      name: 'lastName',
      validate: stringValidate,
      validateOnBlur: true,
    },
    acblMemberNumber: {
      name: 'acblMemberNumber',
      validate: tValidateAcblMemberId,
      validateOnBlur: true,
    },
  };

  const continueEnabled = () => {
    // Validity is not evaluated on form load for pre-populated values, so
    // this is good enough for that initial state.
    const allFieldsValid = Object.values(elementConfig).every((field) => {
      if (field.validate) {
        return !field.validate(formState.values[field.name]);
      }
      return true;
    });

    return allFieldsValid && Object.values(formState.validity).every((x) => x);
  };

  const onContinueSelected = async () => {
    const valid = await checkAcblNumber();

    if (valid) {
      onContinue({
        firstName: formState.values.firstName,
        lastName: formState.values.lastName,
        acblMemberNumber: formState.values.acblMemberNumber,
      });
    }
  };

  return (
    <div className="ClubMemberRegistrationWizard_Wrapper MemberNameStep">
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid container spacing={1} direction="column" alignItems="stretch">
            <Grid item>
              <h2>{t('acblMemberNameStep.namePrompt')}</h2>
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <TextField
                  {...text(elementConfig.firstName)}
                  className="MemberRegistrationWizard_Input"
                  label={t('acblMemberNameStep.firstName')}
                  required
                  variant={'outlined'}
                  error={!!formState.errors.firstName}
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <TextField
                  {...text(elementConfig.lastName)}
                  className="MemberRegistrationWizard_Input"
                  label={t('acblMemberNameStep.lastName')}
                  variant="outlined"
                  error={!!formState.errors.lastName}
                  required
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth margin="normal">
                <TextField
                  {...text(elementConfig.acblMemberNumber)}
                  className="MemberRegistrationWizard_Input"
                  label={t('acblMemberNameStep.acblMemberNumberLabel')}
                  variant="outlined"
                  error={!!formState.errors.acblMemberNumber}
                  required
                  inputProps={{ maxLength: 7 }}
                  size="small"
                />
                {formState.errors.acblMemberNumber && (
                  <FormHelperText
                    error={true}
                    className={'MemberRegistrationWizard_Input_HelperText'}
                    dangerouslySetInnerHTML={{
                      __html: formState.errors.acblMemberNumber,
                    }}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item container justify="flex-end">
              <FormControl>
                <PlayButton
                  type="button"
                  clickHandler={onContinueSelected}
                  text={t('acblMemberNameStep.continueButtonText')}
                  className="MemberRegistrationWizard_SubmitButton"
                  buttonStyle={ButtonStyles.FilledPrimary}
                  disabled={!continueEnabled()}
                  id="NameSubmit"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </div>
  );
}
