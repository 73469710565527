import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';

import "./MyAccount.scss";
import {UserContext} from "../../contexts/UserContext";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Separator from "./Separator";
import {IGNITE_ROUTE_PATTERNS} from "../../constants/routes";

interface MyAccountProps extends RouteComponentProps {
    firstName?: string;
    currentClubId?: number;
}

function MyAccount(props: MyAccountProps): JSX.Element {
    const { t } = useTranslation('layout');
    const {logoutAll} = useContext(UserContext);

    async function onClickLogout(event: any) {
        try {
            logoutAll().then(() =>
                {
                    props.history.push("/login", {'referrer': null});
                }
            );
        } catch (e) {
            console.error("Error", e);
        }
    }

    async function onClickProfile(event: any) {
        props.history.push(IGNITE_ROUTE_PATTERNS.MEMBER_INFO.replace(':clubId', String(props.currentClubId || '' )));
    }

    return (
        <div className="MyAccount">
            <div className="MyAccount_Name">
                {t('myAccount.hiMessage')}, {props.firstName}!
            </div>
            <div className="MyAccount_Links">
                <div className="MyAccount_Profile" onClick={onClickProfile}>{t('myAccount.profile')}</div>
                <Separator/>
                <div className="MyAccount_Logout" onClick={onClickLogout}>{t('myAccount.logOut')}</div>
            </div>
        </div>
    );
}

export default withRouter(MyAccount);
