import React from "react";

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import "./ClubCard.scss";

export interface ClubCardProps {
    className?: string
    termName: (string | undefined)
    clubName: string
    clubDescription: string
    clubImage?: string
    CTAtext: string
    CTAurl: string
    children?: any
}



/**
 * ClubCard
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&mode=design
 * current uses: see src/pages/virtualClubs/VirtualClubsCategoryPage
 * 
 * Media card to display virtual club information. On the component level, relatively unstyled - 
 * import styling className from the parent use. 
 */


export default function ClubCard(props: ClubCardProps): JSX.Element {
    let classes = 'ClubCard';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let termName = props.termName;
    let clubName = props.clubName;
    let clubImage = props.clubImage;
    let CTAurl = (props.CTAurl)? props.CTAurl : "#";
    let clubDescription = props.clubDescription;
    let CTAtext = props.CTAtext;

    return (
        <div className="Card_Container">
            <Card className={classes}>
                <CardActionArea disableRipple={true} href={CTAurl} disabled={(CTAurl === "#")} className="ClubCard_ActionArea">
                    {clubImage &&
                        <CardMedia
                            component="img"
                            alt={clubName}
                            height="190"
                            image={clubImage}
                            title={clubName}
                            className="ClubCard_Img"
                        />
                    }
                    {(clubImage == undefined || !clubImage || clubImage == "") &&
                        <div className="ClubCard_Img no-img MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img"></div>
                    }

                    <CardContent className="ClubCard_CardContent">
                        <Typography gutterBottom variant="h6" component="h6" className="ClubCard_Category">
                            {termName}
                        </Typography>
                        <Typography gutterBottom variant="h4" component="h4" className="ClubCard_Title">
                            {clubName}
                        </Typography>
                        <Typography variant="body1" component="p" className="ClubCard_Description">
                            {clubDescription}
                        </Typography>
                        {CTAurl &&
                            <Button variant="outlined" size="medium" className="ClubCard_CTA">
                                {CTAtext}
                            </Button>
                        }
                    </CardContent>

                </CardActionArea>
            </Card>
        </div>

    );
}





