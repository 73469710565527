import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from "@material-ui/core";
import { IActivityItem } from "../../../types/dashboard";
import cn from "classnames";
import { UserContext } from "../../../contexts/UserContext";
import { IClub } from "../../../types/clubFinder";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import CloseIcon from "@material-ui/icons/Close";
import "./MemberInviteModal.scss";

interface IChangeRoleModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    notInvitedMembers?: IActivityItem[]
    clubId?: string
    selected: string[]
    club: IClub
    inviteUsers: Function
}

export default function MemberInviteModal({
    isOpen,
    onClose,
    selected,
    club,
    inviteUsers,
    notInvitedMembers
}: IChangeRoleModalProps) {
    const { t } = useTranslation('ignite/dashboard');
    const [isDisabled, setIsDisabled] = useState(false)
    const { user } = useContext(UserContext);
    const { clubName } = club
    const leaderName = `${user?.firstName} ${user?.lastName}`

    const [emails, setEmails] = useState(selected);

    useEffect(() => {
        setEmails(selected)
        if (isOpen) setIsDisabled(false)
    }, [isOpen])
    const selectedMembers = notInvitedMembers?.filter(member => emails.includes(String(member?.email)))

    const onClick = (e: React.MouseEvent) => {
        inviteUsers(selectedMembers).then(()=> setIsDisabled(true))
    }

    const onRemove = (email: string) => {
        const newEmails = emails.filter(item => item !== email)
        setEmails(newEmails)
    }
    const signupIgniteRoute = getIgniteRoute(IGNITE_ROUTE_KEY.MEMBER_ONBOARDING, { ':clubShortCode': String(club.shortCode) });
    const reminderMessage = t('copyEmailContent.invitationMessageText', { leaderName, clubName, link: window.location.origin + signupIgniteRoute })

    return (
        <Modal
            open={isOpen}
            className='invite-members-modal'
        >
            <div className="invite-members-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className="title">
                    {t('sendEmail')}
                </div>
                <div className="content">
                    <div className="selected-members">
                        {selectedMembers?.map(member => (
                            <div key={member.email} className="selected-item">
                                <div className="name">
                                    {`${member.firstName} ${member.lastName}`}
                                </div>
                                <div className="remove" onClick={() => onRemove(String(member.email))}>x</div>
                            </div>
                        )
                        )}
                    </div>
                    {Boolean(emails.length) && <div
                        className="description"
                        dangerouslySetInnerHTML={{ __html: t('selectedMembersWillReceiveEmail', { amount: emails.length }) }}
                    />}
                    <div className="content-inner">
                        <div className="subtitle">
                            {t('inviteContent.subtitle')}
                        </div>
                        <div className="main-part">
                            <div className="text" dangerouslySetInnerHTML={{ __html: reminderMessage }} />
                        </div>
                    </div>
                    <div className="button-wrapper">
                        <Button
                            className={cn("button", { disabled: isDisabled || !emails.length })}
                            onClick={onClick}
                            disabled={isDisabled || !emails.length}
                        >
                            {t('sendEmailInvitation')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    );
}
