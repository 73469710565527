import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { getJsonAuth } from '../services/RequestService'

// Date Format Instant.toString() or 1970-01-01T00:00:00Z
export async function getEventsForClubAndMemberInDateRange(
    clubId: number | null,
    startDate: string | null,
    endDate: string | null)
    : Promise<any> {

    const config = await loadConfig();

    return getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/events/get-my-events/separate-occurrences`,
        searchParams: {
            clubId,
            startDate,
            endDate,
        }
    });
}