import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { TextField, Button, MenuItem } from "@material-ui/core";
import Loading from "../layout/Loading";
import InputMask from 'react-input-mask';
import { useFormState } from "react-use-form-state";
import { getContactInfoFormConfig } from './profileFormConfigs'
import useGetPreferredContact from "../../../hooks/useGetPreferredContact";
import { ContactMethod } from "../../../constants/contact";
import { ProfilePanel } from "./ProfilePanel";
import FieldStatusIcon from "../registration/FieldStatusIcon";
import { IProfileInfo } from "../../../types/profile";
import postUpdatePreferredContact from "../../../api/postUpdatePreferredContact";
import { getPhoneUnmask } from "../../../util/Util";

const initialState = {
    email: "",
    preferredContactMethod: '',
    phoneNumber: ''
}

export const ContactMethodsPanel = ({ profileInfo }: { profileInfo: IProfileInfo }) => {
    const { t } = useTranslation('ignite/myInfo');
    const [isEditing, setIsContactInfoEditing] = useState(false);

    const [formState, { text, email, select }] = useFormState(initialState)
    const [data, isPreferredContactLoading] = useGetPreferredContact()

    const preferredContactMethodOptions = [
        { value: ContactMethod.BOTH, label: t('contactMethodsLabel.both') },
        { value: ContactMethod.EMAIL, label: t('contactMethodsLabel.email') },
        { value: ContactMethod.PHONE, label: t('contactMethodsLabel.phone') },
    ]
    const isLoading = isPreferredContactLoading

    const getSubmitEnabled = () => Object.entries(formState.validity).every(([key, value]) => value);

    const currentContactMethod = formState.values.preferredContactMethod

    const helpText = formState.values.preferredContactMethod === ContactMethod.PHONE ? t('contactMethodsHelpText.text') :
        (currentContactMethod === ContactMethod.EMAIL ? null : t('contactMethodsHelpText.both'))

    const formInitialization = () => {
        const { email, phone, preferredContactMethod } = data

        formState.setField('email', email)
        formState.setField('phoneNumber', phone)
        formState.setField('preferredContactMethod', preferredContactMethod)
    }

    useEffect(() => {
        formInitialization()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, profileInfo])

    const elementConfig = getContactInfoFormConfig({ t })

    const onContactInfoEdit = () => setIsContactInfoEditing(true)

    const cancelEditing = () => {
        setIsContactInfoEditing(false)
        formInitialization()
    }

    const updateContactInfo = () => {
        setIsContactInfoEditing(false)
        postUpdatePreferredContact({
            Phone: getPhoneUnmask(formState.values.phoneNumber),
            PreferredContactMethod: formState.values.preferredContactMethod,
            Email: formState.values.email
        })
    }

    if (isLoading) return <Loading loading={isLoading} />

    return (
        <>
            <ProfilePanel
                title={t('contactInfo')}
                className="contact-methods"
                isEditing={isEditing}
                onEdit={onContactInfoEdit}
            >
                <>
                    <div className="profile-group">
                        <div className='profile-item email'>
                            {!isEditing && <label className='profile-item__label' htmlFor="email-id">
                                {t('email')}
                            </label>}
                            <div className='profile-item__main'>
                                <TextField
                                    {...email(elementConfig.email)}
                                    variant="outlined"
                                    id="email-id"
                                    size="small"
                                    label={isEditing ? t('email') : ''}
                                    fullWidth={true}
                                    InputProps={{
                                        readOnly: true,
                                        classes: {
                                            notchedOutline: 'wrapper',
                                            input: 'profile-item__input',
                                            root: 'profile-item__base read-only'
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='profile-item phone'>
                            {!isEditing && <label className='profile-item__label' htmlFor="phone-num-id">
                                {t('phoneNumber')}
                            </label>
                            }
                            <div className='profile-item__main'>
                                <InputMask
                                    {...text(elementConfig.phoneNumber)}
                                    mask='(999) 999-9999'
                                    maskChar='*'
                                >
                                    {(inputProps: any) =>
                                        <TextField
                                            error={isEditing && Boolean(formState.errors.phoneNumber)}
                                            helperText={isEditing ? formState.errors.phoneNumber : ''}
                                            label={isEditing ? t('phoneNumber') : ''}
                                            id="phone-num-id"
                                            size="small"
                                            className="phone-number-base"
                                            variant="outlined"
                                            InputLabelProps={{
                                                htmlFor: 'phone-num-id',
                                                required: false,
                                                classes: {
                                                    root: 'label-root',
                                                    error: 'label-error',
                                                    focused: 'label-focused'
                                                }
                                            }}
                                            FormHelperTextProps={{
                                                classes: {
                                                    root: 'error-label'
                                                }
                                            }}
                                            InputProps={{
                                                readOnly: !isEditing,
                                                classes: {
                                                    input: 'profile-item__input',
                                                    notchedOutline: 'wrapper',
                                                }
                                            }}
                                        />}
                                </InputMask>
                            </div>
                            {isEditing && (
                                <div className='field-indicator-wrapper'>
                                    <FieldStatusIcon shouldShow={formState.touched.phoneNumber} isError={formState.errors.phoneNumber} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="profile-group preferred-method">
                        <div className="profile-item">
                            <div className="left-content">
                                {isEditing ? <div className="subtitle">
                                    {t('preferredMethodOfContact')}
                                </div> :
                                    <div className="profile-item__label">
                                        {t('contactMethod')}
                                    </div>
                                }
                                <TextField
                                    {...select(elementConfig.preferredContactMethod)}
                                    variant="outlined"
                                    InputLabelProps={{
                                        htmlFor: 'preferred-contact-method-id',
                                    }}
                                    select={true}
                                    classes={{ root: 'select preferred-contact-method-select', }}
                                    FormHelperTextProps={{
                                        classes: {
                                            root: 'club-setup-form__field_error'
                                        }
                                    }}
                                    InputProps={{
                                        id: "preferred-contact-method-id",
                                        readOnly: !isEditing,
                                        classes: {
                                            notchedOutline: 'wrapper',
                                            root: 'base-input'
                                        }
                                    }}
                                    SelectProps={{
                                        classes: {
                                            root: 'select preferred-contact-method-select-base',
                                            icon: 'icon'
                                        }
                                    }}
                                    size='small'
                                >
                                    {preferredContactMethodOptions.map(data => (
                                        <MenuItem
                                            key={data.value}
                                            value={data.value}
                                        >
                                            {data.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                            {formState.values.preferredContactMethod !== ContactMethod.EMAIL && isEditing &&
                                <div className="right-content">
                                    {helpText}
                                </div>}
                        </div>
                    </div>

                    {isEditing && (
                        <div className='button-wrapper'>
                            <Button
                                className='button'
                                variant="outlined"
                                color="primary"
                                onClick={() => cancelEditing()}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                className='button update'
                                variant="outlined"
                                color="primary"
                                classes={{
                                    disabled: 'disabled'
                                }}
                                disabled={!getSubmitEnabled()}
                                onClick={() => {
                                    updateContactInfo()
                                }}
                            >
                                {t('update')}
                            </Button>
                        </div>
                    )}
                </>
            </ProfilePanel>
        </>
    )
}
