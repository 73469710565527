import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService';
import { IClubWithDesc } from '../types/clubFinder'

export const CLUBS_BY_TERM_URL = '/clubs-for-term/'


const getClubsForTerm = async (id: number | undefined, controller: AbortController ): Promise<{allClubs:[IClubWithDesc]} | undefined > => {
    const config = await loadConfig();
    const request = new Request(`${config.apiOrigin}${CLUBS_BY_TERM_URL}${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
        signal: controller.signal
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }
}

export default getClubsForTerm;
