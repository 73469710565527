import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import getClubStats from '../api/getClubStats'
import { IGNITE_ROUTE_PATTERNS } from "../constants/routes";
import { IClubStats } from '../types/dashboard'

const useGetClubStats = (clubId: number): [IClubStats | undefined, boolean] => {
    const [clubStats, setClubStats] = useState<IClubStats | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setIsLoading(true)

        getClubStats(clubId)
            .then(data => setClubStats(data))
            .catch(() => history.replace(IGNITE_ROUTE_PATTERNS.ERROR))
            .finally(() => {
                setIsLoading(false)
            })
    }, [clubId])

    return [clubStats, isLoading]
}

export default useGetClubStats
