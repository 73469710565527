import React from "react"

const DownloadIcon = ({ className }: { className?: string }) => (
    <svg width="64" className={className} height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_685_50338)">
            <path d="M32.204 34.4288C30.6235 34.4288 29.3385 35.7076 29.3385 37.289V53.2882L25.6346 49.5892C25.0986 49.0391 24.3702 48.716 23.6006 48.716C22.831 48.7091 22.0957 49.0116 21.5528 49.5548C21.01 50.098 20.7076 50.8405 20.7145 51.6037C20.7213 52.3737 21.0374 53.1026 21.5872 53.6388L30.1768 62.2332C30.2799 62.3157 30.3899 62.3913 30.5067 62.4532C31.2901 63.0995 32.3758 63.2508 33.3103 62.8451C33.5165 62.7351 33.7158 62.6045 33.8944 62.4532C34.0113 62.3913 34.1212 62.3157 34.2243 62.2332L42.8139 53.6388C43.3637 53.1026 43.6798 52.3737 43.6867 51.6037C43.6867 50.8336 43.3912 50.098 42.8483 49.5548C42.3054 49.0116 41.5702 48.7091 40.8005 48.716C40.0309 48.716 39.3025 49.0391 38.7665 49.5892L35.0626 53.2951V37.289C35.0626 36.5258 34.7603 35.8038 34.2243 35.2607C33.6883 34.7244 32.9599 34.4219 32.1971 34.4219L32.204 34.4288Z" fill="black" />
            <path d="M52.187 20.1766C52.2282 19.6747 52.2488 19.1728 52.2488 18.6709C52.242 12.5311 49.2115 6.79691 44.1539 3.3248C39.0895 -0.140445 32.6438 -0.889872 26.9265 1.32403C21.2092 3.53794 16.9419 8.4264 15.5263 14.4012C11.527 14.6625 7.77501 16.4295 5.02632 19.3447C2.27763 22.2667 0.73149 26.117 0.710875 30.1323C0.683388 33.8519 1.98902 37.4684 4.39412 40.308C6.79923 43.1476 10.1458 45.0383 13.8221 45.6227C14.688 45.8084 15.5744 45.8978 16.4609 45.8909H20.7557C22.3362 45.8909 23.6212 44.612 23.6212 43.0307C23.6212 41.4493 22.3362 40.1636 20.7557 40.1636H16.4609C15.9318 40.1636 15.3958 40.1086 14.8804 39.9986C11.527 39.4761 8.66833 37.2828 7.29399 34.1819C5.91277 31.0811 6.20138 27.4921 8.05675 24.6525C9.91212 21.8061 13.08 20.101 16.4677 20.1078C16.7289 20.1147 16.9831 20.1422 17.2374 20.1835L17.6085 20.2316L17.9727 20.2591C18.6323 20.2591 19.2645 20.0253 19.7662 19.6059C20.3709 19.1109 20.7488 18.3958 20.8107 17.6189C21.2024 13.1705 23.8617 9.23771 27.8405 7.23007C31.8261 5.21555 36.5676 5.41494 40.3745 7.74573C44.1814 10.0765 46.5109 14.2156 46.5316 18.6846C46.5247 19.8809 46.3529 21.0773 46.0299 22.2324C45.7963 23.0918 45.9749 24.0131 46.5178 24.7213C47.0607 25.4295 47.899 25.8489 48.7924 25.8489H50.8264C54.7776 25.8489 57.9867 29.0528 57.9867 33.0062C57.9867 36.9596 54.7845 40.1705 50.8264 40.1705H43.6729C42.0924 40.1705 40.8074 41.4562 40.8074 43.0376C40.8074 44.6189 42.0924 45.8978 43.6729 45.8978H50.8264C55.2724 45.8978 59.4023 43.6151 61.7593 39.8473C64.1163 36.0796 64.3706 31.363 62.4259 27.3615C60.4812 23.3599 56.6124 20.651 52.1939 20.1835L52.187 20.1766Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_685_50338">
                <rect width="63" height="63" fill="white" transform="translate(0.710938 0.0722656)" />
            </clipPath>
        </defs>
    </svg>
)

export default DownloadIcon
