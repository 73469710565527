import { useMemo } from 'react';
import { ClubAssociationEnrollmentConfigurationSanityData } from '../../../sanity/getClubAssociationContentViaSanity';

const configurationParser = (
  data?: ClubAssociationEnrollmentConfigurationSanityData,
): ClubAssociationEnrollmentConfigurationSanityData => {
  return {
    activityCollectionEnabled: data?.activityCollectionEnabled ?? false,
    askForExternalActivityTrackerProfileUrl:
      data?.askForExternalActivityTrackerProfileUrl ?? false,
    askIfCurrentMemberEnabled: data?.askIfCurrentMemberEnabled ?? false,
    confirmationPageExternalLinkEnabled:
      data?.confirmationPageExternalLinkEnabled ?? false,
    howDidYouHearAboutUsEnabled: data?.howDidYouHearAboutUsEnabled ?? false,
    enrollmentMessage: data?.enrollmentMessage ?? '',
    memberIdEnabled: data?.memberIdEnabled ?? false,
    memberIdRequired: data?.memberIdRequired ?? false,
    memberIdFieldLabel: data?.memberIdFieldLabel ?? '',
    memberIdHelpOverlayContent: data?.memberIdHelpOverlayContent ?? [],
    referralCodeEnabled: data?.referralCodeEnabled ?? false,
    referralCodeRequired: data?.referralCodeRequired ?? false,
    confirmationPageContent: [],
    confirmationPageContinueButtonText:
      data?.confirmationPageContinueButtonText ?? '',
    confirmationPageExternalLinkUrl:
      data?.confirmationPageExternalLinkUrl ?? '',
    externalActivityTrackerProfileUrlHelpOverlay: [],
    userAgreementsToShow: [],
    reportActivityPageContent: [],
    dataGatheringQuestionCheckboxEnabled: data?.dataGatheringQuestionCheckboxEnabled ?? false,
    dataGatheringQuestionCheckboxLabel: data?.dataGatheringQuestionCheckboxLabel ?? '',
  };
};

export const useConfigurationParser = (
  data?: ClubAssociationEnrollmentConfigurationSanityData,
) => useMemo(() => configurationParser(data), [data]);
