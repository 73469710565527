import React, { ChangeEvent } from "react";
import "./EventSearchResults.scss";
import { useTranslation } from "react-i18next";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import MuiSelectElement from "../form/MuiSelectElement";
import {
    EventSearchSort
} from "../../pages/findAnEvent/FindAnEventPage";

interface SortBySelectProps {
    sort: EventSearchSort;
    onChangeSort: (sort: EventSearchSort) => void;
}

export default function SortBySelect(props: SortBySelectProps): JSX.Element {
    const {t} = useTranslation('pages');
    const label = t('findAnEvent.sortByLabel');
    const options = [
        {
            label: t('findAnEvent.headers.date'),
            value: EventSearchSort.EventStartsAt
        },
        {
            label: t('findAnEvent.headers.location'),
            value: EventSearchSort.Distance
        }
    ];

    function onChange(event: ChangeEvent<MuiSelectElement>) {
        props.onChangeSort(event.target.value as EventSearchSort);
    }

    return (
        <FormControl className={"ResultsList_SortBy"}>
            {/* For whatever reason, it doesn't render the other label correctly without this. This one is hidden by CSS. */}
            <InputLabel className={"ResultsList_SelectLabel"}>{label}</InputLabel>
            <Select onChange={onChange}
                    className={"ResultsList_Select"}
                    value={props.sort}
                    variant="outlined"
                    label={label}>
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
