import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, Tooltip } from '@material-ui/core'
import postUpdateMemberActivity from "../../../api/postUpdateMemberActivity";
import ArrowWithDotIcon from '../../../components/ignite/icons/ArrowWithDotIcon'
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import { memberDashboardConfig } from "../../../configs/memberDashboard";
import cn from "classnames";

interface ITrackingActivityItemProps {
    activeMonth: string
    setActiveMonth: Function
    month: number
    name: string
    slug: string
    year: number
    clubId?: string
    userId?: number
    activityTotalInPerson?: number
    readOnly?: boolean
    getMyActivity: Function
}

const TrackingActivityItem = ({
    activeMonth,
    setActiveMonth,
    month,
    name,
    slug,
    year,
    clubId,
    userId,
    activityTotalInPerson,
    getMyActivity,
    readOnly
}: ITrackingActivityItemProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard')
    const [amount, setAmount] = useState<number | undefined | string>(activityTotalInPerson)
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false)
    const [tooltipText, setTooltipText] = useState(t('activityMaxLimit',  { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH }))
    const [isEditing, setIsEditing] = useState(false)
    const prevAmount = useRef(activityTotalInPerson)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value

        setAmount(Number(value))
    }

    useEffect(() => {
        setAmount(activityTotalInPerson ?? "")
    }, [activityTotalInPerson])

    const onSave = () => {
        if (readOnly) return
        if (amount === '' || amount === undefined) return setAmount(activityTotalInPerson)

        const body = {
            UserId: userId,
            ClubId: Number(clubId),
            Year: year,
            Month: month,
            ActivityTotalInPerson: Number(amount)
        }
        postUpdateMemberActivity(body).then(() => {
            getMyActivity()
            setIsEditing(false)
            prevAmount.current = Number(amount)
        }).catch(error => {
            setTooltipText(error.message)
            setShouldShowTooltip(true)
            setAmount(prevAmount.current)
            setTimeout(() => { setShouldShowTooltip(false) }, memberDashboardConfig.TOOLTIP_HIDE_TIME)
        })
    }

    const onClick = () => {
        if (!readOnly) setIsEditing(true)
    }

    const isAbsentActivity = !amount && amount !== 0
    const isActive = activeMonth === slug
    const shouldShowCheckIcon = !isAbsentActivity && (!isActive || !isEditing)

    return (
        <div
            className={cn('tracking-activity-block', { 'read-only': readOnly, active: isActive })}
            onClick={() => !readOnly && setActiveMonth(slug)}>
            <div className="date">
                <label htmlFor={`month-${name}-modal`}>
                    {t(`months.longName.${name}`)}
                    {' '}
                    {year}
                </label>
            </div>
            <Tooltip
                placement="top"
                open={shouldShowTooltip}
                arrow
                title={tooltipText}
                classes={{ tooltip: 'dashboard-tooltip', arrow: 'arrow' }}
            >
                <TextField
                    hiddenLabel
                    id={`month-${name}-modal`}
                    fullWidth
                    className='tracking-activity-editor'
                    placeholder='?'
                    value={amount}
                    onChange={onChange}
                    onBlur={onSave}
                    error={isAbsentActivity}
                    onClick={onClick}
                    size="small"
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            input: cn('amount-input', {
                                    'activity-absent': isAbsentActivity,
                                    'activity-present': !isAbsentActivity,
                                    'is-not-editing': !isEditing && !isAbsentActivity}),
                            focused: 'label-focused',
                        }
                    }}
                />
            </Tooltip>
            <div className="status">
                {isActive && !isAbsentActivity && !shouldShowCheckIcon &&
                    <Button
                        className="button"
                        onClick={onSave}
                        classes={{
                            label: 'button__label'
                        }}
                    >
                        {t('save')}
                    </Button>
                }
                {isActive && isAbsentActivity &&
                    <ArrowWithDotIcon className="arrow-icon" />
                }
                {shouldShowCheckIcon &&
                    <CheckIcon className="check-icon" />
                }
            </div>
        </div>
    )
}

export default TrackingActivityItem
