import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./EventListNoPagination.scss";
import PlayButton from "../common/PlayButton";
import Button, {ButtonStyles} from "../common/Button";
import {GetClubLeaderClubResponseBody, GetClubLeaderEventOrOccurrenceResponseBody, GetClubLeaderEventResponseBody} from "../../services/ClubLeaderService";
import BorderedRow, {BorderedRowItem} from "../common/BorderedRow";
import EventListNameItem from "../event/EventListNameItem";
import EventListDateTimeItem from "../event/EventListDateTimeItem";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import {UserContext} from "../../contexts/UserContext";
import {loadConfig} from "../../services/ConfigService";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";
import {isEventSelfGuided} from "../../util/Util";
import { getClubEventOrOccurrencesByDateRange, getClubEventsByDateRange } from "../../api/getClubEventsByDateRange";
import { DateTimeParseException, Instant, LocalDate, LocalTime, ZoneId, ZonedDateTime } from "@js-joda/core";
import { EventStatus } from "../../util/EventStatus";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocalDateUtils } from "../../util/FixedJsJodaUtils";
import { Locale } from "@js-joda/locale_en-us";

interface EventListNoPaginationProps {
    club: GetClubLeaderClubResponseBody
    minDate: LocalDate | null
    maxDate: LocalDate | null
    className?: string
    contentIfEmpty: JSX.Element
    past: boolean
}

interface GetPagedEventsResponseBody {
    totalEventCount: number
    events: GetClubLeaderEventResponseBody[]
}

export default function EventListNoPagination(props: EventListNoPaginationProps): JSX.Element {
    const { t } = useTranslation('club');
    const {user, authenticatedFetch} = useContext(UserContext);

    let classes = 'EventList PagedEventList';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    const [club, setClub] = useState<GetClubLeaderClubResponseBody>(props.club);
    const [events, setEvents] = useState<GetClubLeaderEventOrOccurrenceResponseBody[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);

    const [searchStartDate, setSearchStartDate] = useState<LocalDate | DateTimeParseException | null>(props.past ? LocalDate.now().minusMonths(1) : LocalDate.now());
    const [searchEndDate, setSearchEndDate] = useState<LocalDate | DateTimeParseException | null>(props.past ? LocalDate.now() : LocalDate.now().plusMonths(1));

    const loggedOut = user === null;


    useEffect(() => {
        if (!!club && !isSearchStartDateError() && !isSearchEndDateError()) {
            setInitialLoading(true);

            const zone = ZoneId.of(club.timeZone);
            const searchStart = (searchStartDate !== null && searchStartDate instanceof LocalDate) ? ZonedDateTime.of(searchStartDate, LocalTime.MIN, zone).toInstant().toString() : null;
            const searchEnd = (searchEndDate !== null && searchEndDate instanceof LocalDate) ? ZonedDateTime.of(searchEndDate, LocalTime.MAX, zone).toInstant().toString() : null;
            getClubEventOrOccurrencesByDateRange(club.id, null, EventStatus.ACTIVE, searchStart, searchEnd)
            .then((events) => {
                if (!!events) {
                    setEvents(events);
                }
            }).finally(() => {
                setInitialLoading(false);
            });
        }
    }, [club, searchStartDate, searchEndDate])

    function isSearchStartDateError() {
        if (props.minDate) {
            return !searchStartDate || !(searchStartDate instanceof LocalDate) || searchStartDate < props.minDate
        } else {
            return searchStartDate instanceof DateTimeParseException;
        }
    }

    function isSearchEndDateError() {
        if (props.maxDate) {
            return !searchEndDate || !(searchEndDate instanceof LocalDate) || searchEndDate > props.maxDate
        } else {
            return searchEndDate instanceof DateTimeParseException;
        }
    }

    function searchStartDateErrorText() {
        if (props.minDate) {
            if (!searchStartDate) {
                return t("virtualClubsClubDetailPage.requiredLabel");
            }
            else if (props.minDate && searchStartDate.toString() < props.minDate.toString()) {
                return t("eventList.minDateError", {minDate: props.minDate});
            }
        }
        if (searchStartDate instanceof DateTimeParseException) {
            return "MM/DD/YYYY";
        }
        return "";
    }

    function searchEndDateErrorText() {
        if (props.maxDate) {
            if (!searchEndDate) {
                return t("eventList.requiredLabel");
            }
            else if (props.maxDate && searchEndDate.toString() > props.maxDate.toString()) {
                return t("eventList.maxDateError", {maxDate: props.maxDate});
            }
        }
        if (searchEndDate instanceof DateTimeParseException) {
            return "MM/DD/YYYY";
        }
        return "";
    }

    return (
        <div className={classes}>
            {<Loading className="EventList_Loading" loading={initialLoading}/>}
            {loadingErrorMessage !== null && (
                <LoadingMessage
                    className={"EventList_LoadingMessage"}
                    showLoginLink={loggedOut}
                    message={loadingErrorMessage}/>
            )}
            {!initialLoading && !events.length && props.contentIfEmpty}

            <div className="EventList_dateRange">
                <div>
                    <MuiPickersUtilsProvider
                        utils={LocalDateUtils}
                        locale={Locale.US}>
                        <KeyboardDatePicker
                            value={searchStartDate}
                            label={t("eventList.fromLabel")}
                            minDate={props.minDate ? new Date(props.minDate.toString()) : undefined}
                            error={isSearchStartDateError()}
                            helperText={searchStartDateErrorText()}
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            required={!!props.minDate}
                            onChange={(x) => { setSearchStartDate(x) }} 
                            />
                    </MuiPickersUtilsProvider>
                </div>
                <div>
                    <MuiPickersUtilsProvider
                        utils={LocalDateUtils}
                        locale={Locale.US}>
                        <KeyboardDatePicker
                            value={searchEndDate}
                            label={t("eventList.toLabel")}
                            maxDate={props.maxDate ? new Date(props.maxDate.toString()) : undefined}
                            error={isSearchEndDateError()}
                            helperText={searchEndDateErrorText()}
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            required={!!props.maxDate}
                            onChange={(x) => { setSearchEndDate(x) }} />
                    </MuiPickersUtilsProvider>
                </div>
            </div>

            {events.map((e, i) => (
                <BorderedRow className="EventList_Row" link={`/event-details/${encodeURIComponent(e.urlFragment)}`} key={e.urlFragment} showTopBorder={false}>
                    <EventListNameItem isSelfGuided={isEventSelfGuided(e)}
                                       eventName={e.eventName}
                                       isPaid={e.isPaid}
                                       status={e.status}/>
                    <EventListDateTimeItem endsAtInstant={e.endsAtInstant} startsAtInstant={e.startsAtInstant} recurrence={e.recurrence}/>
                    {!props.past &&
                    <BorderedRowItem className="EventList_RSVPs">
                        {!isEventSelfGuided(e) &&
                        <>{e.rsvpCount} {e.rsvpCount === 1 && t('eventList.singularRSVP')} {e.rsvpCount !== 1 && t('eventList.multipleRSVPs')}</>
                        }
                    </BorderedRowItem>
                    }
                    {(props.past) &&
                    <BorderedRowItem className="EventList_Buttons">
                        {!isEventSelfGuided(e) &&
                        <PlayButton text={t('eventList.reportAttendance', {count: e.attendeeCount})}
                                    to={`/event-details/${encodeURIComponent(e.urlFragment)}`}
                                    buttonStyle={ButtonStyles.FilledPrimary}/>
                        }
                        {isEventSelfGuided(e) &&
                        <PlayButton text={t('eventList.viewAttendees', {count: e.attendeeCount})}
                                    to={`/event-details/${encodeURIComponent(e.urlFragment)}`}
                                    buttonStyle={ButtonStyles.FilledPrimary}/>
                        }
                    </BorderedRowItem>
                    }
                    {(!props.past) &&
                    <BorderedRowItem className="EventList_Buttons">
                        <PlayButton text={t('eventList.viewButtonText')}
                                    to={`/event-details/${encodeURIComponent(e.urlFragment)}`}
                                    buttonStyle={ButtonStyles.FilledPrimary}/>
                    </BorderedRowItem>
                    }
                </BorderedRow>
            ))}
        </div>
    );
}
