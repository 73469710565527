import React, { SyntheticEvent, useEffect, useState } from 'react';
import {Checkbox, FormLabel, Grid, IconButton, InputAdornment, TextField, Button, CircularProgress, } from '@material-ui/core';
import './RegistrationForm.scss'
import { getElementConfig } from '../../../components/ignite/registration/registrationFormConfig';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-use-form-state';
import FieldStatusIcon from '../../../components/ignite/registration/FieldStatusIcon';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MemberRegistrationParameters, login, registerNewMember, testIfEmailRegistered } from '../../../services/UserService';
import { getPhoneUnmask, isValidEmail, readUtmRegistrationParametersFromSession, storeRegistrationParametersToSession } from '../../../util/Util';
import { UserRegistrationFlow } from '../../../services/Models';
import { E3_ROUTES } from '../../../constants/routes';
import { formatPhoneNumberForDisplay } from '../../../util/ignite/MyInfo.utils';
import getRegistrationFormViaSanity, { SignUpFormConfigSanityData } from '../../../sanity/getRegistrationFormViaSanity';
import PortableText from 'react-portable-text';
import Loading from '../../../components/common/Loading';

  const initialState = {
    firstName: "",
    lastName: "",
    renewId: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    termsOfUse: false
}

interface RegistrationFormProps {
  handleSubmit: () => void,
  onBack: () => void,
}

// in the future we can make registration forms reusable and
// pass in form slug as a prop from enrollment flow component
const D2C_REGISTRATION_FORM_SLUG = "dtcsf";

function RegistrationForm(props: RegistrationFormProps){
    const { t } = useTranslation('directToConsumer/registration');
    const [formState, { text, password, checkbox }] = useFormState(initialState);
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState<boolean>(false);
    const elementConfig = getElementConfig(t, formState);
    const location = useLocation();
    const [sanityFormConfig, setSanityFormConfig] = useState<SignUpFormConfigSanityData | undefined>(undefined);
    const [loadingFormConfig, setLoadingFormConfig] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        getRegistrationFormViaSanity(D2C_REGISTRATION_FORM_SLUG).then(data => {
            setSanityFormConfig(data?.result[0]);
        }).finally(() => setLoadingFormConfig(false));

        storeRegistrationParametersToSession(history.location);

    }, []);

    const confirmEmailValidate = (value: string) => {
        if (value.trim() === "") {
            return t('registrationForm.errors.email');
        }
        if (value?.toLowerCase() !== formState.values.email?.toLowerCase()) {
            return t('registrationForm.errors.confirmEmail');
        }
    }

    async function testEmailRegistered(email: string) {
        if (email) {
            try {
                const emailIsRegisteredResponse = await testIfEmailRegistered(email);
                if (emailIsRegisteredResponse.isRegistered) {
                    formState.setFieldError("email", t('registrationForm.errors.duplicateEmailError'));
                }
            } catch (e) {
                formState.setFieldError("email", t('registrationForm.errors.unexpectedError'));
            }
        }
    }

    const emailValidation = {
        name: 'email',
        validate: (value: string) => {
            const errorMessageConfirmEmail = confirmEmailValidate(formState.values.confirmEmail);

            if (errorMessageConfirmEmail && formState.touched.confirmEmail) {
                formState.setFieldError('confirmEmail', errorMessageConfirmEmail);
            } else if (formState.touched.confirmEmail) {
                formState.setField('confirmEmail', formState.values.confirmEmail)
            }
            if (value.trim() === "") {
                return t('registrationForm.errors.email');
            }
            if (!isValidEmail(value)) {
                return t('registrationForm.errors.email');
            }
            if (value?.toLowerCase() !== formState.values.confirmEmail?.toLowerCase() && formState.touched.confirmEmail) {
                formState.setFieldError('confirmEmail', t('registrationForm.errors.confirmEmail'));
            }
        },
        onBlur: ((e: SyntheticEvent) => {
            testEmailRegistered((e.target as HTMLInputElement).value).then(() => {
            })
            return undefined;
        }),
        validateOnBlur: true
    }
  const submitEnabled = () => {
    const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
    const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);
    const termsOfUseAccepted = formState.values.termsOfUse;
    return (allInputsTouched && allValuesValid && termsOfUseAccepted);
}

  async function handleSubmit() {

    try {
      
        setSubmitting(true);
          const parameters : MemberRegistrationParameters = {
              email: formState.values.email,
              phone: getPhoneUnmask(formState.values.phoneNumber),
              password: formState.values.password,
              firstName: formState.values.firstName,
              lastName: formState.values.lastName,
              zipCode: formState.values.zipCode,
              renewId: null,
              ...readUtmRegistrationParametersFromSession(),
              registrationFlow: UserRegistrationFlow.BYOC_ENROLLMENT
          };
          await registerNewMember(parameters).then(
              // good
              () => {
                  return login(formState.values.email, formState.values.password).then(
                      // good
                      () => {
                        setSubmitting(false);
                          props.handleSubmit();
                      },
                      () => {
                          console.error('Registration login error');
                      });
              },
              () => console.error('Registration Error')
              );
    } catch (e) {
        console.error(e);
        setSubmitting(false);
    }
  }

  if(loadingFormConfig){
    return <Loading loading={loadingFormConfig} />;
  }
  return(
    <>
    <PortableText content={sanityFormConfig?.title ?? []}/>
    <PortableText content={sanityFormConfig?.body ?? []}/>
    <form className="form-wrapper">
      <Grid container spacing={2}>

        <Grid item xs={12} md={6}>
          <div className='registration-form__field'>
              <TextField
                  {...text(elementConfig.firstName)}
                  InputLabelProps={{
                      htmlFor: 'first-name-id',
                      required: false,
                      classes: {
                          error: 'label-error',
                          focused: 'label-focused'
                      }
                  }}
                  FormHelperTextProps={{
                      classes: {
                          root: 'registration-form__field_error'
                      }
                  }}
                  id='first-name-id'
                  label={sanityFormConfig?.firstNameLabel ?? t('registrationForm.firstNamePlaceholder')}
                  variant='outlined'
                  error={formState.errors[elementConfig.firstName.name] !== undefined}
                  helperText={formState.errors.firstName}
                  required
                  size='small' />
              <div className='field-indicator-wrapper'>
                  <FieldStatusIcon
                      shouldShow={formState.touched.firstName}
                      isError={formState.errors.firstName}
                  />
              </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className='registration-form__field'>
              <TextField
                  {...text(elementConfig.lastName)}
                  InputLabelProps={{
                      htmlFor: 'last-name-id',
                      required: false,
                      classes: {
                          error: 'label-error',
                          focused: 'label-focused'
                      }
                  }}
                  FormHelperTextProps={{
                      classes: {
                          root: 'registration-form__field_error'
                      }
                  }}
                  id='last-name-id'
                  label={sanityFormConfig?.lastNameLabel ?? t('registrationForm.lastNamePlaceholder')}
                  variant='outlined'
                  error={formState.errors[elementConfig.lastName.name] !== undefined}
                  helperText={formState.errors.lastName}
                  required
                  size='small' />
              <div className='field-indicator-wrapper'>
                  <FieldStatusIcon
                      shouldShow={formState.touched.lastName}
                      isError={formState.errors.lastName}
                  />
              </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className='registration-form__field'>
            <TextField
                {...text(emailValidation)}
                InputLabelProps={{
                    htmlFor: 'email-id',
                    required: false,
                    classes: {
                        error: 'label-error',
                        focused: 'label-focused'
                    }
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'registration-form__field_error'
                    }
                }}
                id='email-id'
                label={sanityFormConfig?.emailLabel ?? t('registrationForm.emailPlaceholder')}
                error={formState.errors.email !== undefined}
                helperText={formState.errors.email}
                variant='outlined'
                size='small' />
            <div className='field-indicator-wrapper'>
                <FieldStatusIcon shouldShow={formState.touched.email} isError={formState.errors.email} />
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
        <div className='registration-form__field'>
          <TextField
              {...text(elementConfig.confirmEmail)}
              InputLabelProps={{
                  htmlFor: 'confirm-email-id',
                  required: false,
                  classes: {
                      error: 'label-error',
                      focused: 'label-focused'
                  }
              }}
              FormHelperTextProps={{
                  classes: {
                      root: 'registration-form__field_error'
                  }
              }}
              id='confirm-email-id'
              label={sanityFormConfig?.confirmEmailLabel ?? t('registrationForm.confirmEmailPlaceholder')}
              error={formState.errors.confirmEmail !== undefined}
              helperText={formState.errors.confirmEmail}
              variant='outlined'
              size='small' />
          <div className='field-indicator-wrapper'>
              <FieldStatusIcon shouldShow={formState.touched.confirmEmail} isError={formState.errors.confirmEmail} />
          </div>
        </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className='registration-form__field'>
            <TextField
                {...password(elementConfig.password)}
                InputLabelProps={{
                    htmlFor: 'password-id',
                    required: false,
                    classes: {
                        error: 'label-error',
                        focused: 'label-focused'
                    }
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'registration-form__field_error'
                    }
                }}
                InputProps={{
                    type: isPasswordVisible ? 'text' : 'password',
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                edge="end"
                            >
                                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                }}
                id='password-id'
                type="password"
                label={sanityFormConfig?.passwordLabel ?? t('registrationForm.choosePasswordPlaceholder')}
                variant='outlined'
                error={formState.errors.password !== undefined}
                helperText={formState.errors.password}
                size='small' />
            <div className='field-indicator-wrapper'>
                <FieldStatusIcon shouldShow={formState.touched.password} isError={formState.errors.password} />
            </div>
        </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className='registration-form__field'>
            <TextField
                {...password(elementConfig.confirmPassword)}
                InputLabelProps={{
                    htmlFor: 'confirm-password-id',
                    required: false,
                    classes: {
                        error: 'label-error',
                        focused: 'label-focused'
                    }
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'registration-form__field_error'
                    }
                }}
                InputProps={{
                    type: isConfirmPasswordVisible ? 'text' : 'password',
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                                edge="end"
                            >
                                {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                }}
                id='confirm-password-id'
                label={sanityFormConfig?.confirmPasswordLabel ?? t('registrationForm.confirmPasswordPlaceholder')}
                variant='outlined'
                error={formState.errors.confirmPassword !== undefined}
                helperText={formState.errors.confirmPassword}
                size='small'
                disabled={!formState.values.password}
            />
            <div className='field-indicator-wrapper'>
                <FieldStatusIcon shouldShow={formState.touched.confirmPassword} isError={formState.errors.confirmPassword} />
            </div>
          </div>
        </Grid>

        <Grid className="registration-form__password-help-text" item xs={12}>
          {t('registrationForm.passwordHelperText')}
        </Grid>

        <Grid className='registration-form__terms-of-use-wrapper' item xs={12}>
          <Checkbox
              {...checkbox(elementConfig.termsOfUse)}
              classes={{
                  root: "registration-form__checkbox"
              }}
              id='checkbox-id'
          />
          <FormLabel className="registration-form__label" htmlFor='checkbox-id'>
              <span className="registration-form__checkbox-title">
                  {t('registrationForm.agreeWithE3')}
              </span>
              <Link
                  target="_blank"
                  className='registration-form__terms-of-use-link'
                  to="/terms-of-use"
              >
                  {t('registrationForm.termsAndConditions')}
              </Link>
          </FormLabel>
        </Grid>

        <Grid item xs={12}>
            <div style={{  display: 'flex', justifyContent: 'space-between', paddingTop: 40}}>
                <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    className="button"
                    onClick={props.onBack}
                    disabled={submitting}
                >
                    {t('registrationForm.backButtonText')}
                </Button>

                <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    className="button"
                    onClick={handleSubmit}
                    disabled={!submitEnabled() || submitting}
                >
                    {submitting &&  <CircularProgress size={18} style={{ position: 'absolute' }}/>}
                    {t('registrationForm.nextButtonText')}&nbsp;
                </Button>
              </div>
        </Grid>
      </Grid>
    </form>
    <div>
        <span style={{fontSize: 18, fontWeight: 700}}>{t('registrationForm.alreadyRegistered')}&nbsp;</span>
        <Link
            style={{
                fontSize: 18,
                fontWeight: 700,
                color: '#0280CD',
                textDecoration: 'underline'
            }}
            to={{ 
                pathname: E3_ROUTES.LOGIN,
                state:{ referrer: location.pathname } 
            }}
        >
            {t('registrationForm.logIn')}
        </Link>
    </div>
    </>
  );
}

export default RegistrationForm;