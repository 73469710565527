import React, {
    useState, useEffect
} from "react";
import { useTranslation } from 'react-i18next';
import { TextField, Button, IconButton, Slider, Tooltip } from "@material-ui/core";
//@ts-ignore
import InputMask from 'react-input-mask';
import './MemberDetails.scss'
import EditIcon from "@material-ui/icons/Edit";
import { getFormattedZonedDate } from "../../../util/ignite/date.utils";
import { getPhoneUnmask } from "../../../util/ignite/MyInfo.utils";
import { EMAIL_REGEX, PHONE_REGEX } from "../../../constants/regex";
import cn from "classnames";
import postLeaderUpdateProfilePhone from "../../../api/postLeaderUpdateProfilePhone";
import postLeaderUpdatePreferredContact from "../../../api/postLeaderUpdatePreferredContact";
import postUpdateUserRole from "../../../api/postUpdateUserRole";
import ChangeRoleModal from "./ChangeRoleModal";
import useCheckDevice from "../../../hooks/useCheckDevice";
import { convertRole } from "../../../util/ignite/Dashboard.utils";
import { IMembersDetails } from "../../../types/dashboard";
import { CLUB_ROLES, CONTACT_METHOD } from "../../../constants/dashboard";
import { memberDashboardConfig } from "../../../configs/memberDashboard";
import SaveIcon from "../icons/SaveIcon";

interface IMemberDetailsProps {
    clubId?: number
    userId?: number
    memberDetails: IMembersDetails
    name: string
    isCurrentUser: boolean
}

const MemberDetails = ({
    clubId,
    memberDetails,
    userId,
    name,
    isCurrentUser
}: IMemberDetailsProps): JSX.Element => {
    const { t } = useTranslation('ignite/contactMethods', { useSuspense: false });
    const { preferredContactMethods } = memberDetails || { preferredContactMethods: {} }
    const { preferredContactMethod: initialPreferredContactMethod } = preferredContactMethods

    const [phone, setPhone] = useState(preferredContactMethods.phone);
    const [email, setEmail] = useState(preferredContactMethods.email);
    const [isEditing, setIsEditing] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('')
    const [preferredContactMethod, setPreferredContactMethod] = useState(initialPreferredContactMethod)
    const { isMobile } = useCheckDevice()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [role, setRole] = useState(convertRole(memberDetails.clubRole));
    const [roleError, setRoleError] = useState('')
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false)
    const [pendingRole, setPendingRole] = useState<number>(convertRole(memberDetails.clubRole))

    useEffect(() => {
        setEmail(preferredContactMethods.email)
        setPhone(preferredContactMethods.phone)
        setRole(convertRole(memberDetails.clubRole))
    }, [memberDetails])

    const onRoleChange = (e: any, value: number | number[]) => {
        if (typeof value === 'number') {
            if (isCurrentUser && role === CLUB_ROLES.ADMIN && value === CLUB_ROLES.MEMBER) {
                setRoleError(t('changeMyRoleToMemberTooltipText'))
                setShouldShowTooltip(true)

                setTimeout(() => {
                    setShouldShowTooltip(false)
                }, memberDashboardConfig.TOOLTIP_HIDE_TIME)
            } else {
                postUpdateUserRole({
                    UserId: Number(userId),
                    ClubId: Number(clubId),
                    ConfirmChange: false,
                    UserClubRelationshipTypeId: convertRole(value)
                }).then(data => {
                    if (data?.success) {
                        setRole(value)
                    } else if (data?.confirmationRequired) {
                        setPendingRole(value)
                        setIsModalOpen(true)
                    }
                }).catch(e => {
                    setRoleError(e.message)
                    setShouldShowTooltip(true)

                    setTimeout(() => {
                        setShouldShowTooltip(false)
                    }, memberDashboardConfig.TOOLTIP_HIDE_TIME)
                })
            }
        }
    }

    const onConfirmRoleUpdate = () => {
        postUpdateUserRole({
            UserId: Number(userId),
            ClubId: Number(clubId),
            ConfirmChange: true,
            UserClubRelationshipTypeId: convertRole(pendingRole)
        }).then((data) => {
            if (data?.success) { setRole(pendingRole) }
            setIsModalOpen(false)
        })
    }

    const onCheck = (preferredContactMethod: any) => (event: React.MouseEvent) => {
        setPreferredContactMethod(preferredContactMethod)
        postLeaderUpdatePreferredContact({
            UserId: Number(userId),
            ClubId: Number(clubId),
            PreferredContactMethod: preferredContactMethod
        })
    }

    const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneValue = getPhoneUnmask(event.target.value)

        setPhone(phoneValue);
        if (phoneValue?.match(PHONE_REGEX)) {
            setPhoneError('')
        } else {
            setPhoneError(t('enterValidPhone'))
        }
    }

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = event.target.value
        setEmail(emailValue);

        if (emailValue?.match(EMAIL_REGEX)) {
            setEmailError('')
        } else {
            setEmailError(t('enterValidEmail'))
        }
    }

    const onPhoneUpdate = () => {
        if (isEditing && !phoneError) {
            postLeaderUpdateProfilePhone({
                Phone: getPhoneUnmask(phone),
                UserId: Number(userId),
                ClubId: Number(clubId),
            })
        }
    }

    const onSendText = () => {
        document.location = `tel:${phone}` as unknown as Location
    }
    const onSendEmail = () => {
        document.location = `mailto:${email}` as unknown as Location
    }

    const helpText = preferredContactMethod === CONTACT_METHOD.PHONE ? t('helpText.text') :
        (preferredContactMethod === CONTACT_METHOD.EMAIL ? null : t('helpText.both'))

    const marks = [
        {
            value: CLUB_ROLES.MEMBER,
            label: t('sliderMarks.member'),
        },
        {
            value: CLUB_ROLES.ADMIN,
            label: t('sliderMarks.leader'),
        },
        {
            value: CLUB_ROLES.LEADER,
            label: t('sliderMarks.admin'),
        },
    ];

    return (
        <div className='member-details-content'>
            <ChangeRoleModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={onConfirmRoleUpdate}
                name={name}
            />
            <div className={cn('top-content', { 'is-editing': isEditing })}>
                <div className="description">
                    <div className="description__start">
                        {t('contactMethods')}
                    </div>
                    <div className="description__end">
                        <div className="edit-panel">
                            <div className='button-wrapper'>
                                {isEditing ? t('save') : t('editContactInfo')}
                                <IconButton
                                    className='edit-button'
                                    onClick={() => setIsEditing(isEditing => !isEditing)}
                                >
                                    {isEditing ? <SaveIcon className="save-icon" /> : <EditIcon />}
                                    <span hidden aria-hidden="true">{t('edit')}</span>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-info'>
                    <div className="contact-info-start">
                        <div className='contact-info__label'>
                            {t('email')}
                        </div>
                        <div className='contact-info__main'>
                            <TextField
                                value={email}
                                onChange={onChangeEmail}
                                error={Boolean(emailError)}
                                helperText={emailError}
                                required
                                fullWidth={true}
                                disabled={preferredContactMethod === CONTACT_METHOD.PHONE}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                    classes: {
                                        input: 'contact-info__input',
                                        root: 'contact-info__base'
                                    }
                                }}
                            />

                            {!isEditing && <Button
                                className="button"
                                disabled={preferredContactMethod === CONTACT_METHOD.PHONE}
                                classes={{ disabled: 'disabled' }}
                                onClick={onSendEmail}
                            >
                                {t('sendEmail')}
                            </Button>}
                        </div>
                    </div>
                </div>
                <div className={cn('contact-info', { 'is-editing': isEditing })}>
                    <div className="contact-info-start">
                        <div className='contact-info__label'>
                            {t('phoneNumber')}
                        </div>
                        <div className='contact-info__main'>
                            <InputMask
                                mask='(999) 999-9999'
                                value={phone}
                                onChange={onChangePhone}
                                maskChar='*'
                                onBlur={onPhoneUpdate}
                            >
                                {(inputProps: any) =>
                                    <TextField
                                        error={Boolean(phoneError)}
                                        helperText={phoneError}
                                        required
                                        fullWidth={true}
                                        InputProps={{
                                            ...inputProps,
                                            readOnly: !isEditing,
                                            disableUnderline: true,
                                            classes: {
                                                input: cn('contact-info__input', { disabled: !isEditing && preferredContactMethod === CONTACT_METHOD.EMAIL }),
                                                root: cn('contact-info__base', { 'focused': isEditing })
                                            },
                                        }}
                                    />}
                            </InputMask>
                            {!isEditing && <Button
                                className="button"
                                disabled={preferredContactMethod === CONTACT_METHOD.EMAIL}
                                classes={{ disabled: 'disabled' }}
                                onClick={onSendText}
                            >
                                {t('sendText')}
                            </Button>}
                        </div>
                    </div>
                </div>
                <div className='contact-info preferred-method'>
                    <label htmlFor="phone-id" className='contact-info__label'>
                        {t('preferredMethodTitle')}
                    </label>
                    <div className='contact-info__main'>
                        <div className="button-wrapper">
                            <Button
                                className={cn('button', { active: preferredContactMethod === CONTACT_METHOD.PHONE })}
                                onClick={onCheck(CONTACT_METHOD.PHONE)}
                            >
                                {t('text')}
                            </Button>
                            <Button
                                className={cn('button', { active: preferredContactMethod === CONTACT_METHOD.EMAIL })}
                                onClick={onCheck(CONTACT_METHOD.EMAIL)}
                            >
                                {t('email')}
                            </Button>
                            <Button
                                className={cn('button', { active: preferredContactMethod === CONTACT_METHOD.BOTH })}
                                onClick={onCheck(CONTACT_METHOD.BOTH)}
                            >
                                {t('both')}
                            </Button>
                        </div>
                        <div className='help-text'>
                            {helpText}
                        </div>
                    </div>
                </div>
                <div className="contact-info role-block">
                    <div className='contact-info__label'>
                        {t('role')}
                    </div>
                    <div className="role-panel">
                        <Tooltip
                            open={shouldShowTooltip}
                            title={roleError}
                            placement="top"
                            classes={{ tooltip: 'dashboard-tooltip', arrow: 'arrow' }}
                        >
                            <Slider
                                aria-label="Custom marks"
                                value={role}
                                onChange={onRoleChange}
                                step={1}
                                marks={marks}
                                track={false}
                                min={CLUB_ROLES.MEMBER}
                                max={CLUB_ROLES.LEADER}
                                classes={{
                                    root: 'slider',
                                    rail: 'rail',
                                    track: 'track',
                                    thumb: 'thumb',
                                    mark: 'label',
                                    markActive: 'active'
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="bottom-content">
                    <div className="info-item">
                        <div className="text">{t('recentActivity')}</div>
                        <div className="date">
                            {getFormattedZonedDate(
                                { month: 'short', day: 'numeric', year: 'numeric' },
                                memberDetails.recentActivityDate
                            )}
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="text">{t('enrolled')}</div>
                        <div className="date">
                            {getFormattedZonedDate(
                                { month: 'short', day: 'numeric', year: 'numeric' },
                                memberDetails.enrolledDate
                            )}
                        </div>
                    </div>
                    <div className="info-item">
                        <div className="text">{t('eligible')}</div>
                        <div className="date">
                            {getFormattedZonedDate(
                                { month: 'short', day: 'numeric', year: 'numeric' },
                                memberDetails.eligibleDate
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberDetails
