import React, {useState} from "react";
import {CircularProgress} from "@material-ui/core";
import ConfirmationModal from "./ConfirmationModal";
import {RouteComponentProps, withRouter} from "react-router-dom";

import "./Button.scss";

export interface ButtonProps extends RouteComponentProps {
    buttonStyle: ButtonStyles,
    saving?: boolean,
    useConfirmationModal?: boolean,
    showConfirmationModal?: boolean,
    confirmationModalBody?: any,
    confirmationModalConfirmText?: string,
    confirmationModalDenyText?: string,
    type?: 'submit' | 'reset' | 'button',
    to?: string,
    toState?: unknown,
    clickHandler?: Function,
    className?: string
    children?: any,
    disabled?: boolean,
    onClick?: Function,
    id?: string,
    text?: string;
}

export enum ButtonStyles {
    Unfilled = 1, FilledPrimary, FilledSecondary, GreyScale, UnfilledWithBorder, Link, UnfilledWithWhiteBorder, FilledClubDirectoryPrimary
}

function Button(props: ButtonProps): JSX.Element {
    let style = props.buttonStyle;
    let className = props.className;
    let [rolloverOn, setRolloverOn] = useState(false);
    let [modalOn, setModalOn] = useState(props.showConfirmationModal === undefined ? false : props.showConfirmationModal);
    let type = props.type === undefined ? 'button' : props.type;
    let saving = props.saving === undefined ? false : props.saving;
    let disabled = props.disabled === undefined ? false : props.disabled;
    let id = props.id === undefined ? 'buttonId': props.id;

    function defaultClickHandler(e: any) {
        if (props.useConfirmationModal) {
            setModalOn(true);
        } else if (props.clickHandler !== undefined) {
            props.clickHandler(e);
        } else if (props.to !== undefined) {
            props.history.push(props.to, props.toState);
        } else if (props.onClick !== undefined) {
            props.onClick(e);
        }
        e.stopPropagation();
    }

    function confirmationOk(e: any) {
        setModalOn(false);
        if (props.clickHandler !== undefined) {
            props.clickHandler(e);
        }
    }

    function confirmationCancel() {
        setModalOn(false);
    }

    let classes = 'CCButton';
    if (className !== undefined) {
        classes += ' ' + className;
    }
    if (style === ButtonStyles.FilledPrimary) {
        classes += ' CCButton_FilledPrimary'
    } else if (style === ButtonStyles.FilledSecondary) {
        classes += ' CCButton_FilledSecondary'
    } else if (style === ButtonStyles.UnfilledWithBorder) {
        classes += ' CCButton_UnfilledWithBorder'
    } else if (style === ButtonStyles.GreyScale) {
        classes += ' CCButton_GreyScale'
    } else if (style === ButtonStyles.Link) {
        classes += ' CCButton_Link'
    } else if (style === ButtonStyles.UnfilledWithWhiteBorder) {
        classes += ' CCButton_UnfilledWithWhiteBorder'
    } else if (style === ButtonStyles.FilledClubDirectoryPrimary) {
        classes += ' CCButton_FilledClubDirectoryPrimary'
    }
    if (rolloverOn) {
        classes += ' CCButton_Rollover'
    }

    function onMouseOver() {
        setRolloverOn(true);
    }

    function onMouseOut() {
        setRolloverOn(false);
    }

    return (
        <>
            {props.useConfirmationModal &&
            <ConfirmationModal cancelClickHandler={confirmationCancel}
                               okClickHandler={confirmationOk}
                               shown={modalOn}
                               confirmText={props.confirmationModalConfirmText}
                               denyText={props.confirmationModalDenyText}>
                {props.confirmationModalBody}</ConfirmationModal>}
            <button
                disabled={disabled}
                onClick={(e) => {
                    if (!disabled) {
                        defaultClickHandler(e)
                    }
                }}
                type={type}
                id={id}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                className={classes}>
                <div className="CCButton_ChildContainer">
                    {props.text ?? props.children}
                    {saving && <CircularProgress size={36} className="CCButton_Progress"/>}
                </div>
            </button>
        </>
    );
}

export default withRouter(Button);