

import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const GET_MEMBER_DETAILS_URL = '/ignite/leader/get-member-details/'

interface memberDetails {
    firstName: string
    lastName: string
    email: string
    phone: string
    postalCode: string
    memberId: string
    dateOfBirth: string
}

const getMemberDetails = async (clubId: number, userId: number): Promise<memberDetails> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_MEMBER_DETAILS_URL}${clubId}?userId=${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }

    const error = await response?.text()

    throw new Error(error)
}

export default getMemberDetails
