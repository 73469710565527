import './i18n';
import './index.scss';
import './styles/fonts.scss';
import './styles/common.scss';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CCTheme } from './components/form/Theme';
import GoogleTagManager from './components/googleTagManager/GoogleTagManager.jsx';
import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router/Router';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { SelectedPayerContextProvider } from './contexts/SelectedPayerContext';
import TokenBasedLogin from './components/tokenBasedLogin/TokenBasedLogin';
import { UserContextProvider } from './contexts/UserContext';
import withTracker from './withTracker';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="">
      <GoogleTagManager />
      <MuiThemeProvider theme={CCTheme}>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <SelectedPayerContextProvider>
              <BrowserRouter>
                <TokenBasedLogin>
                  <>
                    <ScrollToTop />
                    <Route component={withTracker(Router)} />
                  </>
                </TokenBasedLogin>
              </BrowserRouter>
            </SelectedPayerContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </MuiThemeProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
