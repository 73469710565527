import React from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import './SubHeader.scss';
import {ClubInfoPanel} from '../Dashboard/ClubInfoPanel';
import {IClub} from "../../../types/clubFinder";
import {IGNITE_ROUTE_PATTERNS} from "../../../constants/routes";

interface ISubHeaderProps {
    clubName?: string
    myClubs: IClub[]
    clubImage?: string
    toClub?: boolean
    location?: any
}

const SubHeader = ({
    clubName,
    myClubs,
    clubImage,
    toClub,
    location,
}: ISubHeaderProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');
    const title = toClub ? t('myDashboard') : t('clubDashboard')

    const url = useLocation();
    const showDashboardTitle = url.pathname.startsWith(IGNITE_ROUTE_PATTERNS.LEADER_DASHBOARD.replace(':clubId', '')) ||
        url.pathname.startsWith(IGNITE_ROUTE_PATTERNS.LEADER_MANAGE_MEMBERS.replace(':clubId', '')) ||
        url.pathname.startsWith(IGNITE_ROUTE_PATTERNS.DASHBOARD.replace(':clubId', ''));

    return (
        <div className="subheader">
            {showDashboardTitle && <div className="title">
                <div className="text">
                    {title}
                </div>
            </div>}
            <ClubInfoPanel
                clubName={clubName}
                myClubs={myClubs}
                clubImage={clubImage}
                location={location}
            />
        </div>
    )
}

export default SubHeader
