import React from "react";
import { useTranslation } from 'react-i18next';

import {UserState} from "../../contexts/UserContext";
import {IImportResult} from "./EventImportWizard";
import Button, {ButtonStyles} from "../common/Button";


export default function EventImportConfirm(
    props: { clubUrlFragment: string, ImportResult:IImportResult, UserState: UserState, StepSetter: Function }): JSX.Element {
    const { t } = useTranslation('eventImportWizard');
    let clubUrlFragment = props.clubUrlFragment;
    let importResult = props.ImportResult;

    return (
        <div>
            <h2 className={`Centered`}
                style={{marginTop:'1em'}}>
                {t('eventImportConfirm.success', {count : importResult.events.length})}</h2>
            <div style={{marginBottom:'2em', marginTop:'2em'}}
                 className={`Centered`}>
                {t('eventImportConfirm.notes')}
            </div>
            <Button buttonStyle={ButtonStyles.FilledPrimary}
                   className={`Button BlueBackground WhiteForeground DisplayBlock MarginAuto PositionRelative`}
                    to={`/my-club/${clubUrlFragment}`} >{t('eventImportConfirm.backToMyClubButtonText')}</Button>
        </div>
    );
}


