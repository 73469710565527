import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import postImportMembersStepOne from "../api/postImportMembersStepOne";
import postImportMembersStepTwo from "../api/postImportMembersStepTwo";

enum ImportStatus {
    Success = 'Success',
    FailureInvalidColumnHeaders = 'FailureInvalidColumnHeaders',
    FailureAtLeastOneFailedMember = 'FailureAtLeastOneFailedMember',
    FailureAtLeastOneRequiredFieldMissing = 'FailureAtLeastOneRequiredFieldMissing',
    FailureNotAnExcelFile = 'FailureNotAnExcelFile',
    FailureException = 'FailureException',
    FailureNoMembers = 'FailureNoMembers',
    NoFiles = 'NoFiles',
    TooManyFiles = 'TooManyFiles'
}

const useUploadFile = (t: Function): { setFiles: Function, isSuccess: boolean, error: string, isLoading: boolean } => {
    const [isLoading, setIsLoading] = useState(false)
    const [files, setFiles] = useState<any>([]);
    const [error, setError] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)
    const { clubId } = useParams()

    useEffect(() => {
        upload()
    }, [files])

    const upload = async () => {
        setIsLoading(true)
        const file = files[0]
        if (!file) {
            return;
        }
        const data = new FormData();

        data.append(`files`, file);

        setError('')
        postImportMembersStepOne(data, clubId)
            .then((res: any) => res.json())
            .then((data: any) => {
                if (data.status === ImportStatus.Success) {
                    postImportMembersStepTwo({ Members: data.members }, clubId)
                        .then(() => setIsSuccess(true))
                } else {
                    setError(t(`uploadContent.${data.status}`))
                }
            })
            .catch((err: string) => console.error(err))
            .finally(() => {
                setIsLoading(false)
            })
    }


    return { setFiles, isSuccess, error, isLoading }
}

export default useUploadFile
