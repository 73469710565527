import React, { useContext, useState, memo, useMemo, useCallback, useEffect } from "react";
import StarIcon from '@material-ui/icons/Stars';
import postUpdateMemberActivity from "../../../api/postUpdateMemberActivity";
import CheckIcon from '@material-ui/icons/Check';
import MonthItem from "../Dashboard/MonthItem";
import { getMonths } from '../../../util/ignite/Dashboard.utils'
import { IActivityItem, IActivityRecord, IMembersDetails } from '../../../types/dashboard'
import { VERIFICATION_STATUS } from "../../../constants/verification";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, Tooltip } from "@material-ui/core";
import cn from "classnames";
import MemberDetails from "./MemberDetails";
import { REFERRAL_STATUS } from "../../../constants/dashboard";
import postRemindInvitedMembers from "../../../api/postRemindInvitedMembers";
import postRemindMissingActivity from "../../../api/postRemindMissingActivity";
import postInviteNotInvitedMembers from "../../../api/postInviteNotInvitedMembers";
import { memberDashboardConfig } from "../../../configs/memberDashboard";
import useCheckDevice from "../../../hooks/useCheckDevice";

import './MemberActivityItemWithDetails.scss'
import { getFormattedZonedDate } from "../../../util/ignite/date.utils";
import { UserContext } from "../../../contexts/UserContext";

interface IMemberActivityItemProps {
    activityRecords?: IActivityRecord[]
    clubId: number
    firstName?: string
    score?: number
    lastName?: string
    income?: number
    RenderAction?: JSX.Element
    myActivity?: IActivityItem
    eligibility?: VERIFICATION_STATUS
    monthsAmount: number
    expanded: boolean
    onExpand: (e: React.MouseEvent, id: any) => Promise<void> | undefined
    memberDetails: IMembersDetails
    referralStatus?: number
    referralId?: number
    invitedAt?: string
    userId?: number
    buttonsDisabled?: boolean
    fetchMembersActivity?: Function
    fetchLeaderboard?: Function
    maxReportingPeriod?: number
    email?: string
    changeInvitedReferralIds?: Function
    changeNotInvitedReferralIds?: Function
    changeMissingActivityMemberIds?: Function
    invitedReferralIds?: number[],
    notInvitedReferralIds?: number[],
    missingActivityMemberIds?: number[],
    fetchMyActivity?: Function
}

const MemberActivityItemWithDetails = ({
    activityRecords,
    clubId,
    firstName,
    score,
    lastName,
    income,
    RenderAction,
    monthsAmount,
    eligibility,
    expanded,
    onExpand,
    memberDetails,
    userId,
    referralStatus,
    referralId,
    invitedAt,
    fetchMembersActivity,
    fetchLeaderboard,
    email,
    maxReportingPeriod = memberDashboardConfig.DEFAULT_MAX_REPORTING_PERIOD,
    changeInvitedReferralIds,
    changeNotInvitedReferralIds,
    changeMissingActivityMemberIds,
    invitedReferralIds,
    notInvitedReferralIds,
    missingActivityMemberIds,
    fetchMyActivity,
}: IMemberActivityItemProps): JSX.Element => {
    const monthsForMember = getMonths({ fixedAmount: monthsAmount, records: activityRecords || [], includeCurrentMonth: true })
    const isMissingActivity = getMonths({ fixedAmount: maxReportingPeriod, records: activityRecords || [], includeCurrentMonth: true }).some(month => month.activityTotal === undefined)
    const { t } = useTranslation('ignite/dashboard')

    const [shouldShowError, setShouldShowError] = useState(false)
    const [error, setError] = useState('')

    const { isMobile } = useCheckDevice()
    const { user } = useContext(UserContext)

    const showTooltipFromError = (e: { message: string }) => {
        setError(e.message)
        setShouldShowError(true)

        setTimeout(() => {
            setShouldShowError(false)
        }, memberDashboardConfig.TOOLTIP_HIDE_TIME)
    }

    const isCurrentUser = email === user?.email

    const onSave = useCallback((e: React.FocusEvent<HTMLInputElement>, year?: number, month?: number) => {
        const target = e.target as HTMLInputElement

        if (target.value === '') return

        const body = {
            UserId: userId,
            ClubId: Number(clubId),
            Year: year,
            Month: month,
            ActivityTotalInPerson: Number(target.value)
        }

        return postUpdateMemberActivity(body).then(() => {
            fetchMembersActivity && fetchMembersActivity()
            fetchLeaderboard && fetchLeaderboard()
            isCurrentUser && fetchMyActivity && fetchMyActivity()
        }).catch(e => {
            showTooltipFromError(e)
            throw Error(e)
        })
    }, [clubId, userId])

    const onClickExpand = (e: React.MouseEvent) => onExpand(e, userId)?.catch((e: { message: string }) => showTooltipFromError(e))

    const getIsDisabled = () => {
        if (referralStatus === REFERRAL_STATUS.INVITED) {
            return !invitedReferralIds?.includes(Number(referralId))
        } else if (isMissingActivity && referralStatus === undefined) {
            return !missingActivityMemberIds?.includes(Number(userId))
        } else {
            return !notInvitedReferralIds?.includes(Number(referralId))
        }
    }

    const isDisabled = getIsDisabled()

    const shouldShowStar = eligibility === VERIFICATION_STATUS.ACTIVE

    const isDisabledExpand = userId === undefined
    const actionButtonText = referralStatus === REFERRAL_STATUS.NOT_INVITED ? t('invite') : t('remind')
    const afterActionButtonText = referralStatus === REFERRAL_STATUS.NOT_INVITED ? t('invited') : t('reminded')
    const shouldShowActionButton = (referralStatus || isMissingActivity) && !isDisabled
    const monthsAmountForExpandedMode = isMobile ? memberDashboardConfig.MONTHS_AMOUNT_FOR_MOBILE_EXPANDED :
        memberDashboardConfig.MONTHS_AMOUNT_FOR_DESKTOP_EXPANDED

    const onActionButtonClick = async () => {
        if (referralStatus === REFERRAL_STATUS.INVITED) {
            changeInvitedReferralIds && changeInvitedReferralIds(referralId)

            await postRemindInvitedMembers({
                ClubId: Number(clubId),
                ReferralIds: [Number(referralId)],
            })
        } else if (isMissingActivity && referralStatus === undefined) {
            changeMissingActivityMemberIds && changeMissingActivityMemberIds(userId)

            await postRemindMissingActivity({
                ClubId: Number(clubId),
                UserIds: [Number(userId)],
            })
        } else {
            changeNotInvitedReferralIds && changeNotInvitedReferralIds(referralId)

            await postInviteNotInvitedMembers({
                ClubId: Number(clubId),
                ReferralIds: [Number(referralId)],
            })
        }
    }

    const months = getMonths({ fixedAmount: monthsAmountForExpandedMode, records: activityRecords || [], includeCurrentMonth: true })

    return (
        <Tooltip
            title={error}
            open={shouldShowError}
            placement="top"
            classes={{ tooltip: 'dashboard-tooltip', arrow: 'arrow' }}
        >
            <div className={cn(`tracking-item with-details`, { 'with-referral-status': referralStatus !== undefined })}>
                <IconButton onClick={onClickExpand} className={cn("arrow-icon-wrapper", { disabled: isDisabledExpand, expanded })}>
                    <ArrowForwardIcon className={cn("arrow-icon", { expanded })} />
                    <span hidden aria-hidden="true">Arrow</span>
                </IconButton>
                <Accordion
                    className="accordion"
                    expanded={expanded}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        classes={{
                            expanded: 'expanded',
                            root: 'accordion-summary',
                            content: 'content'
                        }}
                    >
                        <div className="main-info">
                            <div className="main-info__name">
                                {firstName ? `${firstName} ${lastName}` : email}
                            </div>
                            <div className="main-info__rank-with-money">
                                <div className="main-info__rank">
                                    <span className="icon-wrapper">
                                        {shouldShowStar && <StarIcon className="icon" />}
                                    </span>
                                    {score ?? 0}
                                </div>
                                <div className='dot'>&#183;</div>
                                <div className="main-info__money">
                                    {income ?? 0}
                                </div>
                            </div>
                        </div>
                        {referralStatus === undefined && !expanded &&
                            <div className="months">
                                {monthsForMember.map(monthData => {
                                    // Only showing In person activity for activityTotal for leaders until redesign work is done
                                    return <MonthItem
                                        name={t(`months.shortName.${monthData.name}`)}
                                        readOnly={false}
                                        activityTotalInPerson={monthData.activityTotalInPerson}
                                        key={monthData.name}
                                        year={monthData.year}
                                        month={monthData.month}
                                        onSave={onSave}
                                        count={userId}
                                    />
                                })}
                            </div>
                        }
                        {referralStatus === REFERRAL_STATUS.NOT_INVITED && !isDisabled && <div className="description">
                            {t('notInvited')}
                        </div>}
                        {referralStatus === REFERRAL_STATUS.INVITED && invitedAt && !isDisabled && <div className="description">
                            {t('invited')}
                            {' '}
                            {getFormattedZonedDate({ month: 'numeric', day: 'numeric', year: '2-digit' }, invitedAt)}
                            {' - '}
                            {t('notSignedUp')}
                        </div>}
                        {RenderAction ? RenderAction : (
                            <div className="status">
                                {!isMissingActivity && <CheckIcon />}
                                {shouldShowActionButton &&
                                    <Button onClick={onActionButtonClick} className='button'>
                                        {actionButtonText}
                                    </Button>}
                                {(isDisabled && isMissingActivity) && <div className="action-message">
                                    {afterActionButtonText}
                                </div>}
                            </div>
                        )}
                    </AccordionSummary>
                    <AccordionDetails className="accordion-details">
                        <div className="months">
                            {months.map((monthData, index) => {
                                // Only showing In person activity for activityTotal for leaders until redesign work is done
                                return <MonthItem
                                    name={t(`months.shortName.${monthData.name}`)}
                                    activityTotalInPerson={monthData.activityTotalInPerson}
                                    key={monthData.name}
                                    onSave={onSave}
                                    year={monthData.year}
                                    month={monthData.month}
                                    income={monthData.income}
                                    count={userId}
                                />
                            })}
                        </div>
                        {memberDetails && !isDisabledExpand &&
                            <MemberDetails
                                userId={userId}
                                clubId={clubId}
                                memberDetails={memberDetails}
                                name={`${user?.firstName} ${user?.lastName}`}
                                isCurrentUser={email === user?.email}
                            />}
                    </AccordionDetails>
                </Accordion>
            </div>
        </Tooltip>
    )
}

export default memo(MemberActivityItemWithDetails)
