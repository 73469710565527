import { useState, useEffect } from "react";
import getEligibilityStatus from '../api/getEligibilityStatus'
import { VERIFICATION_STATUS } from '../constants/verification'
import { getPayerAdditionalData } from "../util/ignite/Verification.utils";
import { TPayerSlug } from '../types/verification'

interface IVerificationData {
    verificationStatus?: string
    userSuppliedPayerName?: string
    firstName?: string
    lastName?: string
    payerId?: number
    isEligible?: boolean
    isNotEligible?: boolean
    isOther?: boolean
    logoSrc?: string
}

interface IUseVerificationDataParams {
    payerSlug: TPayerSlug
    id: string
}

const OTHER_SLUG = 'other'

const useVerificationData = ({ payerSlug, id }: IUseVerificationDataParams): [IVerificationData, boolean] => {
    const [verificationData, setVerificationData] = useState<IVerificationData>()
    const [isLoading, setIsLoading] = useState(false)

    const isEligible = verificationData?.verificationStatus === VERIFICATION_STATUS.ACTIVE
    const isNotEligible = !isEligible && verificationData?.verificationStatus !== VERIFICATION_STATUS.IN_PROGRESS
    const isOther = OTHER_SLUG === payerSlug
    const { logoSrc } = getPayerAdditionalData(payerSlug)

    useEffect(() => {
        setIsLoading(true)
        getEligibilityStatus(id)
            .then((data) => setVerificationData(data))
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [{ ...verificationData, isEligible, isNotEligible, isOther, logoSrc }, isLoading]
}

export default useVerificationData
