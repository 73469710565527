import React, { useContext, useEffect, useState } from 'react';
import {FormControl, Grid, TextField, Button, InputAdornment, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { useFormState } from 'react-use-form-state';
import FieldStatusIcon from '../../../components/ignite/registration/FieldStatusIcon';
import { EMAIL_REGEX } from '../../../constants/regex';
import { PHONE_REGEX, WEB_REGEX, getPhoneUnmask } from '../../../util/Util';
import ReactInputMask from 'react-input-mask';
import { states } from '../../../util/ignite/ClubSetup.utils';
import { Autocomplete } from '@material-ui/lab';
import useClubs from '../../../hooks/useClubs';
import { useTranslation } from 'react-i18next';
import { IClub } from '../../../types/clubFinder';
import getClubBYOCFormViaSanity, { ClubBYOCFormSanityData } from '../../../sanity/getClubBYOCFormViaSanity';
import Loading from '../../../components/common/Loading';
import PortableText from 'react-portable-text';
import ClubDuesPlanOptions from '../components/ClubDuesPlanOptions';
import { PlanOptions } from '../../../types/club';
import ClubCard, { DEFAULT_PASSION_IMAGE_NAME } from '../components/ClubCard';
import "./AddClub.scss";
import { UserContext } from '../../../contexts/UserContext';

interface AddClubProps {
    onBack: () => void;
    onNext: (club: Partial<IClub>) => void;
    clubSearchTerm: string;
}

interface ClubCreatedResponse {
    shortCode: string;
}

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const DTC_BYOC_FORM_SLUG = 'dtc'

const AddClub = ({onBack, onNext, clubSearchTerm}: AddClubProps) => {
    const { t } = useTranslation('directToConsumer/addClub');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [createClub] = useClubs();
    const [openConfirmClub, setOpenConfirmClub] = useState<boolean>(false);
    const {refreshUser} = useContext(UserContext);
    const initialState = {
        clubName: clubSearchTerm,
        email: '',
        phone: '',
        city: '',
        dues: null,
        clubPlan: PlanOptions.Annual,
        state: null,
        membershipNumber: '',
    };
    const [loadingConfig, setLaodingConfig] = useState<boolean>(true);
    const [formConfig, setFormConfig] = useState<ClubBYOCFormSanityData>();
    const [formState, {text, email, tel}] = useFormState(initialState);

    useEffect(() => {
        getClubBYOCFormViaSanity(DTC_BYOC_FORM_SLUG).then((data) => {
            setFormConfig(data?.result[0]);
        }).finally(() => setLaodingConfig(false));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [openConfirmClub]);

    const elementConfig = {
        clubName: {
            name: "clubName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t("fields.clubName.error.required");
                }
            },
            validateOnBlur: true,
            onBlur: () => formState.values.clubName.trim() && formState.setField('clubName', formState.values.clubName.trim()),
        },
        email: {
            name: 'email',
            validate: (value: string) => {
                if (!value.match(EMAIL_REGEX) && value.trim().length > 0) {
                    return t("fields.email.error.format");
                }
            },
            validateOnBlur: true
        },
        phoneNumber: {
            name: 'phoneNumber',
        },
        city: {
            // validate: (value: string) => {
            //     const city = value?.trim()  ?? "";
            //     console.log('city: ', city);
            //     if(city === "") return t("fields.city.error.required");
            // },
            // onBlur: () => formState.setField("city", formState.values.city?.trim().replace(/[^aA-zZ ]/g, '').trim() ?? ""),
            // validateOnBlur: true,
            // name: 'city',
            name: "city",
            validate: (value: string) => {
                if (!value.trim().replace(/[^aA-zZ ]/g, '')) {
                    return t("fields.city.error.required");
                }
            },
            validateOnBlur: true,
            onBlur: () => formState.values.city.trim() && formState.setField('city', formState.values.city.trim().replace(/[^aA-zZ ]/g, '')),
        },
        state: {
            validate: (value: string) => {
                const state = value?.trim()  ?? "";
                if(state === "") return t("fields.state.error.required");
                if(!states.find(({label}) => label.toLowerCase() === state.toLowerCase())){
                    return t("fields.state.error.notSupported");
                }
            },
            validateOnBlur: true,
            name: 'state',
        },
        dues: {
            name: 'dues',
            validate: (value: string) => {
                // remove unwanted characters from dues input
                const newValue = value?.length > 0 ? value.replace(/[^0-9.]/g, "") : "";
                // verify value matches correct currency format (i.e.: 9999999.99)
                if(newValue && !newValue.match(/^[+-]?[0-9]{1,3}(?:[0-9]*(?:[.,][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?|(?:\.[0-9]{3})*(?:,[0-9]{2})?)$/)){
                    return t("fields.clubDues.error.format");
                }else if (newValue === "") {
                    formState.setField("dues", "");
                    return t("fields.clubDues.error.required");
                }else{
                    formState.setField("dues", USDollar.format(parseFloat(newValue)))
                }
            }
        },
        website: {
            name: 'website',
            validate: (value: string) => {
                if(value.trim().length > 0){
                    if(!value.match(WEB_REGEX)){
                        return t("fields.websiteUrl.error.format");
                    }
                } else {
                    return t("fields.websiteUrl.error.required");
                }
            },
            validateOnBlur: true
        },
        membershipNumber: {
            name: 'membershipNumber',
        }
    }

    const renderClubAdded = () => {
        const {
            clubName,
            state,
            city
        } = formState.values;
        const clubCardParams: Partial<IClub> = {
            clubName,
            clubImage: undefined,
            location: {
                city,
                countrySubdivision: state
            },
            primaryClubLeaders: []
        }

        return (
            <Dialog
                open={openConfirmClub}
                onClose={() => setOpenConfirmClub(false)}
            >
                <DialogTitle>
                    {t('section.confirmClubModal.title')}
                </DialogTitle>
                <DialogContent>
                    <ClubCard club={clubCardParams as unknown as IClub} />
                </DialogContent>
                <DialogActions>
                <Button disabled={submitting} color="primary" style={{fontWeight: 700}} onClick={() => setOpenConfirmClub(false)}>{t('section.confirmClubModal.cancelButton.label')}</Button>
                <Button disabled={submitting} color="primary" style={{fontWeight: 700}} onClick={handleSubmit}>{t('section.confirmClubModal.confirmButton.label')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    const isSubmitDisabled = () => {
        const touchedAllRequiredInputs = (
            (clubSearchTerm === '' ? !formState.pristine.clubName : true) &&
            !formState.pristine.city &&
            !formState.pristine.state &&
            !formState.pristine.dues &&
            !formState.pristine.website
        );
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return !touchedAllRequiredInputs || !allValuesValid || submitting;
        };

    const handleSubmit = async () => {
        const body = {
            clubName: formState.values.clubName,
            email: formState.values.email,
            phone: getPhoneUnmask(formState.values.phoneNumber),
            dues: formState.values.dues?.replace(/[$,]/g, "") ?? null,
            websiteUrl: formState.values.website ?? null,
            clubPlan: formState.values.clubPlan,
            city: formState.values.city,
            state: formState.values.state,
            membershipNumber: formState.values.membershipNumber,
        }
        setSubmitting(true);
        await createClub(body, { onSuccessCallback: async (response: ClubCreatedResponse) => {
            setSubmitting(false);
            await refreshUser();
            onNext({...response, clubImage: DEFAULT_PASSION_IMAGE_NAME, isIgniteEnabled: true, /**default for BYO(C/G) created clubs/groups */});
        }, onFailCallback: () =>{setSubmitting(false);}});
    }

    const handlePlanOptionSelected = (value: PlanOptions) => formState.setField("clubPlan", value);

    return (
        loadingConfig ? <Loading loading={loadingConfig}/>
        :
        <div className='add-club' style={{marginTop: 32}}>
            {formConfig?.title && <div><PortableText content={formConfig!.title}/></div>}
        <Grid container spacing={3} className={"form-wrapper"}>
            <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                    <FormControl fullWidth style={{flex: 1}}>
                        <TextField
                            {...text(elementConfig.clubName)}
                            error={formState.errors.clubName !== undefined}
                            helperText={formState.errors.clubName}
                            required
                            inputProps={{maxLength: 50}}
                            label={formConfig?.clubNameLabel ?? t("fields.clubName.label")}
                            variant="outlined"
                            size={"small"}
                            fullWidth
                        />
                    </FormControl>
                    {formState.touched.clubName && (
                        <div className='field-indicator-wrapper' >
                            <FieldStatusIcon shouldShow={formState.touched.clubName}
                                                isError={formState.errors.clubName}/>
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{display: 'flex'}}>
                    <FormControl fullWidth style={{flex: 1}}>
                        <TextField 
                            fullWidth
                            {...email(elementConfig.email)}
                            error={formState.errors.email !== undefined}
                            size="small"
                            inputProps={{maxLength: 50}}
                            label={formConfig?.emailLabel ?? t("fields.email.label")}
                            variant="outlined"
                            helperText={formState.errors.email}
                        />
                    </FormControl>
                    {(formState.touched.email || formState.errors.email) && (
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.email || formState.errors.email}
                                                isError={formState.errors.email}/>
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{display: 'flex'}}>
                <ReactInputMask
                    mask='(999) 999-9999'
                    maskChar='*'
                    {...text(elementConfig.phoneNumber)}
                >
                    {(inputProps: any) => (
                        <TextField
                            id='phone-number-id'
                            label={formConfig?.phoneNumberLabel ?? t("fields.phoneNumber.label")}
                            variant='outlined'
                            error={formState.errors.phoneNumber !== undefined}
                            helperText={formState.errors.phoneNumber}
                            size='small' 
                            style={{width: '100%'}}
                        />
                    )}
                </ReactInputMask>

                {(formState.touched.phoneNumber || formState.errors.phoneNumber) && (
                <div className='field-indicator-wrapper'>
                    <FieldStatusIcon shouldShow={(formState.touched.phoneNumber || formState.errors.phoneNumber)} isError={formState.errors.phoneNumber} />
                </div>
                )}
            </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div style={{display: 'flex'}}>
                    <FormControl fullWidth style={{flex: 1}}>
                        <TextField 
                            fullWidth
                            {...text(elementConfig.city)}
                            error={formState.errors.city !== undefined}
                            helperText={formState.errors.city}
                            inputProps={{maxLength: 50}}
                            label={formConfig?.cityLabel ?? t("fields.city.label")}
                            variant="outlined"
                            size="small"
                            required
                        />
                    </FormControl>
                    {formState.touched.city && (
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.city}
                                                isError={formState.errors.city}/>
                        </div>
                    )}
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
            <div style={{display: 'flex'}}>
                        <Autocomplete
                            freeSolo
                            options={states}
                            getOptionLabel={(state) => state.label}
                            fullWidth
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t("fields.state.placeholder")}
                                    variant="outlined"
                                    size="small"
                                    {...text(elementConfig.state)}
                                    required
                                    error={formState.errors.state ? true : false}
                                    helperText={formState.errors.state}
                                />
                            )}
                            renderOption={(state: {id: string, label: string}) => (
                                <div style={{width: '100%'}} onClick={() => formState.setField("state", state.label)}>{state.label}</div>
                            )}
                        />
                        {formState.touched.state && (
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon shouldShow={formState.touched.state}
                                                    isError={formState.errors.state}/>
                            </div>
                        )}
                    </div>
                </Grid>
            <Grid item xs={12} md={6}>
                <div style={{display: 'flex'}}>
                    <FormControl fullWidth style={{flex: 1}}>
                        <TextField 
                            fullWidth
                            {...text(elementConfig.dues)}
                            error={formState.errors.dues !== undefined}
                            helperText={formState.errors.dues}
                            size={"small"}
                            inputProps={{maxLength: 50}}
                            label={formConfig?.clubDuesLabel ?? t("fields.clubDues.label")}
                            variant="outlined"
                            required
                        />
                    </FormControl>
                    {formState.touched.dues && (
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.dues}
                                                isError={formState.errors.dues}/>
                        </div>
                    )}
                </div>
            </Grid>

            <Grid item xs={12} md={6}>
                <ClubDuesPlanOptions
                    onChange={handlePlanOptionSelected}
                    value={formState.values.clubPlan}
                    label={formConfig?.clubPlanLabel}
                />
            </Grid>
            <Grid item xs={12}>
            <div style={{display: 'flex'}}>
                    <FormControl fullWidth style={{flex: 1}}>
                        <TextField 
                            fullWidth
                            {...text(elementConfig.website)}
                            error={formState.errors.website !== undefined}
                            helperText={formState.errors.website}
                            size={"small"}
                            label={formConfig?.websiteURLLabel ?? t("fields.websiteUrl.label")}
                            variant="outlined"
                            required
                        />
                    </FormControl>
                    {formState.touched.website && (
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.website}
                                                isError={formState.errors.website}/>
                        </div>
                    )}
                </div>
            </Grid>
              <Grid item xs={12}>
            <div style={{display: 'flex'}}>
                    <FormControl fullWidth style={{flex: 1}}>
                        <TextField 
                            fullWidth
                            {...text(elementConfig.membershipNumber)}
                            error={formState.errors.membershipNumber !== undefined}
                            helperText={formConfig?.membershipNumberHint ?? t("fields.membershipNumber.hint")}
                            size={"small"}
                            label={formConfig?.membershipNumberLabel ?? t("fields.membershipNumber.label")}
                            variant="outlined"
                        />
                    </FormControl>
                    {formState.touched.membershipNumber && (
                        <div className='field-indicator-wrapper'>
                            <FieldStatusIcon shouldShow={formState.touched.membershipNumber}
                                                isError={formState.errors.membershipNumber}/>
                        </div>
                    )}
                </div>
            </Grid>

            <Grid item xs={12}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    disabled={submitting}
                    size="large"
                    color="primary"
                    variant="outlined"
                    style={{
                        fontWeight: 700,
                    }}
                    onClick={onBack}
                >
                    {t("buttons.backButton.label")}
                </Button> 
                <Button
                    disabled={isSubmitDisabled()}
                    size="large"
                    color="primary"
                    variant="contained"
                    style={{
                        fontWeight: 700,
                    }}
                    onClick={() => setOpenConfirmClub(true)}
                >
                    {submitting &&  <CircularProgress size={18} style={{ position: 'absolute' }}/>}
                    {t("buttons.nextButton.label")}
                </Button>
            </div>
            </Grid>
        </Grid>
        {renderClubAdded()}
        </div>
    );
}

export default AddClub;
