import { Box, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { IClub } from '../../../types/clubFinder';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "./ClubSearch.scss";
import { useTranslation } from 'react-i18next';

interface ClubSearchProps {
    clubs: IClub [];
    onChange: (searchValue: string) => void;
    onOptionSelected: (club: IClub) => void;
    searchValue: string;
}
export default function ClubSearch (props: ClubSearchProps) {
    const { t } = useTranslation('directToConsumer/clubFinder');
    useEffect(() => {
        props.onChange('');
    }, [])
    return(
        <div className='club-search'>
        <Box className="club-finder-box" style={{borderRadius: 0, backgroundColor: '#1976D214'}}>
            <div className='form-control'>
            <Search className="search-icon"/>
            <Autocomplete
                options={props.clubs}
                getOptionLabel={(option) => option.clubName}
                defaultValue={null}
                filterOptions={x => x}
                className="search-input"
                open={false}
                freeSolo
                renderOption={(option) => <Box>{option.clubName}</Box>}
                onChange={(_, newValue) => {
                    props.onOptionSelected(newValue as IClub);
                }}
                onInputChange={(_, value) => props.onChange(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        placeholder={t('section.clubFinder.searchInputPlaceHolder')}
                        size="small"
                        inputProps={{
                            ...params.inputProps,
                            value : props.searchValue,
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                }
                            },
                        }}
                    />

                )}
            />
            </div>
        </Box>
        </div>
    );
}
