import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export enum PayerEligibilityVerificationStatus
{
    INVALID = 'INVALID',
    IN_PROGRESS = 'IN_PROGRESS',
    ACTIVE = 'ACTIVE',
    EXPIRED = 'EXPIRED',
    DELETED = 'DELETED',
    LEGACY_ACTIVE = 'LEGACY_ACTIVE',
    IN_PROGRESS_RETRY = 'IN_PROGRESS_RETRY',
}

export interface IEligibility {
    id: number;
    userId: number;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    payerId: number;
    userSuppliedPayerName: string;
    isUHCPayer: boolean;
    memberId: string;
    memberCode: string | null;
    groupNumber: string;
    zipCode: string;
    beginDate: string;
    endDate: string | null;
    verificationStatus: PayerEligibilityVerificationStatus;
    eligibilityMessage: string | null;
    lastVerifiedDate: string | null;
    creationDate: string;
    modificationDate: string | null;
}

const URL = '/ignite/get-eligibility-status/';

const getEligibilityStatus = async (id: string): Promise<IEligibility | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${URL}${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export default getEligibilityStatus
