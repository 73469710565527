import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import "./ContactUs.scss";
import {UserContext} from "../../contexts/UserContext";
import {useLocation} from "react-router-dom";

export default function ContactUs(): JSX.Element {
    const { t } = useTranslation('layout');
    const {getContactPhoneNumber, getContactEmail} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});
    const contactEmail = getContactEmail(location.pathname);

    return (
        <div className={`ContactUs`}>
            <span className={`ContactUsCallout`}>{t('footer.contactUs')}</span>
            <a className={`FooterLink`}
               href={`mailto:${contactEmail}`}><EmailIcon className={`EmailIcon`}/>{contactEmail}</a>
            <a className={`FooterLink`} href={`tel:${phoneNumber.tel}`}><PhoneIcon
                className={`PhoneIcon`}/>{phoneNumber.display}</a>
        </div>
    );
}
