export enum AcblMemberIdValidationStatus {
  BAD_FORMAT,
  INVALID,
}

export const validateAcblMemberId = (
  value: string,
): AcblMemberIdValidationStatus | undefined => {
  // Ensure that the first character is between 'J' and 'R' (inclusive) or is a digit AND remaining characters are digits
  if (!/^[J-R0-9][0-9]{6}$/i.test(value)) {
    return AcblMemberIdValidationStatus.BAD_FORMAT;
  }

  // Convert non-digit characters to digits
  const supportedChars = 'jklmnopqr';
  const digits = value
    .split('')
    .map((char) =>
      supportedChars.includes(char.toLowerCase())
        ? supportedChars.indexOf(char.toLowerCase()) + 1
        : Number(char),
    );

  // Check if check digit matches last digit of member ID
  const mod11 = 11;
  const product = digits
    .slice(0, 6)
    .reduce((sum, digit, index) => sum + digit * (value.length - index), 0);
  const result = mod11 - (product % mod11);
  const digitCheck = result !== mod11 ? result : 0;

  if (digits[digits.length - 1] !== digitCheck) {
    return AcblMemberIdValidationStatus.INVALID;
  }
};
