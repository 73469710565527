import React from "react";
import "./LocationViewCityState.scss";
import {GetLocationResponseBody} from "../../services/Models";

interface LocationViewCityStateProps {
    city?: string | null,
    state?: string | null,
    className?: string
    location?: GetLocationResponseBody|null;
}

export default function LocationViewCityState(props: LocationViewCityStateProps): JSX.Element {

    let classes = 'LocationViewCityState';
    let className = props.className;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    function check(toCheck: string | null | undefined) {
        return !(toCheck === undefined || toCheck === null || toCheck === '');
    }

    let locationCity = props.city;
    let locationState = props.state;

    if (props.location !== undefined && props.location !== null)
    {
        locationCity = props.location.city;
        locationState = props.location.countrySubdivision;
    }

    return (
        <div className={classes}>
            <div>{check(locationCity) && locationCity}
                {check(locationCity) && check(locationState) && ","}
                {check(locationState) && " "}
                {check(locationState) && locationState}</div>
        </div>
    );
}
