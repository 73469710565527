import React, { ChangeEvent, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import MuiSelectElement from "../form/MuiSelectElement";
import { DISTANCE_OPTIONS } from "../../pages/findAnEvent/FindAnEventPage";
import {
    FormControl,
    MenuItem,
    Select,
    TextField,
    InputAdornment
} from "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore';
import PinDropIcon from '@material-ui/icons/PinDrop';
import "./LocationPicker.scss";

interface LocationPickerProps {
    zipcode: string | null;
    setZipcode: Function;
    placeholderText: string;
    distance: number | null;
    setDistance: Function;
    setFilterZipcodeAndDistance: Function;
    className?: string;
    apiErrors?: string;
}

/* 
 * LocationPicker component
 *  Jira: https://hellogrouperhealth.atlassian.net/browse/EDP-268
 *  Figma: https://www.figma.com/file/0ZCdv7tDtSxIdiakBRVx3e/Club-directory-(redesign)?type=design&node-id=494-2461&mode=design&t=cydnt88p4icMIuEh-0
 *
 *  LocationPicker currently used in ClubDirectory page, to create search params in miles from an entered zipcode
 *  Picker populates outwards via setFilterZipcodeAndDistance as soon as a valid zipcode has been entered
 *  Distance dropdown defaults to closest (10 miles)
*/
export default function LocationPicker(props: LocationPickerProps): JSX.Element {
    const { t } = useTranslation('club');
    let classes = 'ClubDirectory_LocationPicker';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    const [formErrors, setFormErrors] = useState<string | null>('');

    function onChangeZipcode(event: ChangeEvent<HTMLInputElement>) {
        props.setZipcode(event.target.value);
    }

    async function onSubmitSearchForm(newZipcode: string | undefined, newDistance: number | undefined) {
        props.setFilterZipcodeAndDistance(newZipcode, newDistance);
    }

    return (
        <div className={classes} >
            <div className="LocationPicker_Container">
                <form className={`LocationPickerFilter`}>
                    <FormControl
                        className={`LocationPicker_FormWrapper`}
                        component="fieldset">

                        <span className="LocationPicker_InlineText">{t('locationPicker.distanceDesc')}</span>
                        <DistanceSelect
                            onChangeDistance={props.setDistance}
                            distance={props.distance}
                            searchFunction={onSubmitSearchForm}
                        />
                        <span className="LocationPicker_InlineText">{t('locationPicker.zipcodeDesc')}</span>
                        <ZipCodeSelect
                            errorReporterFunction={setFormErrors}
                            onChangeZipcode={onChangeZipcode}
                            searchFunction={onSubmitSearchForm}
                            zipcode={props.zipcode}
                            placeholderText={props.placeholderText}
                        />
                    </FormControl>
                    <span className="LocationPicker_error">{formErrors}</span>
                    {!formErrors?.length &&
                        <span className="LocationPicker_error">{props.apiErrors}</span>
                    }
                </form>
            </div>
        </div>
    );
}


interface ZipCodeSelectProps {
    zipcode: string | null;
    onChangeZipcode: Function;
    searchFunction: Function;
    errorReporterFunction: Function;
    placeholderText: string;
}

function ZipCodeSelect(props: ZipCodeSelectProps): JSX.Element {
    const { t } = useTranslation('club');
    const [errored, setErrored] = useState<boolean>(false);

    function isValid(zipcode: string | null): boolean {
        return (!!zipcode && /^\d{5}$/.test(zipcode)) || zipcode === null || zipcode === '';
    }

    function clearInitialValue(event: any) {
        if (event.target.value === props.placeholderText) {
            event.target.value = '';
        }
    }
    function onBlur() {
        const valid = isValid(props.zipcode)
        if (!valid) {
            props.errorReporterFunction(t('locationPicker.invalidZipcodeError'));
        } else {
            props.errorReporterFunction(null);
        }
        setErrored(!valid);
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        // only have this call search when it's 5 numbers, or 0 (clear)
        props.onChangeZipcode(event);

        const valid = isValid(event.target.value); //zipcodeValidityErrors(event.target.value);
        if (valid) {
            props.searchFunction(event.target.value, undefined);
        }
    }

    function zipcodeValidityErrors(zipcode:(string | null)):boolean {
        const valid = isValid(zipcode)
        if (!valid) {
            props.errorReporterFunction(t('locationPicker.invalidZipcodeError'));
        } else {
            props.errorReporterFunction(null);
        }
        setErrored(!valid);
        return valid;
    }

    useEffect(() => {
        // "Clear filter" is up in the ClubDirectory parent component and is altering zipcode directly
        // so do validity error reporting at the end of the update
        zipcodeValidityErrors(props.zipcode);
    }, [props.zipcode]);

    return (
        <TextField
            value={(props.zipcode === null) ? "" : props.zipcode}
            onChange={onChange}
            onBlur={onBlur}
            variant="outlined"
            onClick={clearInitialValue}
            disabled={false}
            error={errored}
            className={"CD_Textfield"}
            label={props.placeholderText}
            placeholder={props.placeholderText}
            InputLabelProps={{ required: false }}
            inputProps={{ maxLength: 10 }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PinDropIcon style={{ color: '#0042AD' }} />
                    </InputAdornment>
                ),
            }}

        />
    );
}

interface DistanceSelectProps {
    distance: number | null,
    onChangeDistance: Function,
    searchFunction: Function
}

function DistanceSelect(props: DistanceSelectProps): JSX.Element {
    const { t } = useTranslation('club');

    function onChange(event: ChangeEvent<MuiSelectElement>) {
        props.onChangeDistance(event.target.value);
        props.searchFunction(undefined, event.target.value);
    }

    return (
        <Select onChange={onChange}
            IconComponent={() => (
                <ExpandMore style={{ color: '#0042AD', pointerEvents: 'none' }} />
            )}
            className={"CD_Select"}
            value={props.distance}
            variant="outlined"
            required
            label='label'>
            {DISTANCE_OPTIONS.map(distance => (
                <MenuItem key={distance} value={distance}>
                    {distance}{t("locationPicker.distanceMarker")}
                </MenuItem>
            ))}
        </Select>
    )
}
