import React from "react";

const virtualClubEventLive = require("../../assets/virtualclub_event_live.svg");

interface VirtualClubEventLiveProps {
  className?: string;
}

export default function Plus(props: VirtualClubEventLiveProps): JSX.Element {
  return <img className={`VirtualClubEventLive ${props.className || ''}`} src={virtualClubEventLive} />
}
