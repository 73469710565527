import {getSanityResponse, SanityTextObject} from "./SanityUtils";

export interface ClubAssociationDashboardSanityResponse {
    ms: number;
    query: string;
    result: ClubAssociationDashboardSanityData[];
}

export interface ClubAssociationDashboardSanityData {
    _id: string;
    slug: string;
    title: string;
    name: string;
    shortName: string;
    associationLogoUrl: string;
    associationLogoLightUrl: string;
    associationEnrollmentConfiguration: ClubAssociationManageMemberSanityData[];
    associationActivityTrackerDashboard: ClubAssociationActivityTrackerDashboardSanityData[];
}

export interface ClubAssociationManageMemberSanityData {
    memberIdEnabled: boolean;
    memberIdRequired: boolean;
    memberIdFieldLabel: string;
}

export interface ClubAssociationActivityTrackerDashboardSanityData {
    activityTrackerHelperText: SanityTextObject[];
}

const getClubAssociationDashboardContentViaSanity = async (shortCode: string): Promise<ClubAssociationDashboardSanityResponse | undefined> => {
    let query = encodeURIComponent(`
        *[_type == "association" && lower(slug) == "${shortCode}"]
        {
            title, slug, _id, name, shortName,
            "associationActivityTrackerDashboard": *[_type == "association_activity_tracker_dashboard" && references(^._id)]
            {
                activityTrackerHelperText
            },
            "associationEnrollmentConfiguration":  *[_type == "association_enrollment_configuration" && references(^._id)]
            {
                memberIdEnabled, memberIdRequired, memberIdFieldLabel
            },
            "associationLogoUrl": associationLogo.asset->url,
            "associationLogoLightUrl": associationLogoLight.asset->url,
        }
    `);

    return getSanityResponse(query);
}

export default getClubAssociationDashboardContentViaSanity