import React, { useEffect, useState } from 'react';
import './PayerMemberRegistrationWizard.scss';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { MemberNameStep } from '../steps/MemberNameStep';
import { MemberPersonalInformationStep } from '../steps/MemberPersonalInformationStep';
import { MemberAccountStep } from '../steps/MemberAccountStep';
import { UserRegistrationFlow } from '../../../services/Models';
import { MemberRegistrationInitialState } from '../../../util/registration-util';
import { RegistrationWizard } from '../../../components/registrationWizard/RegistrationWizard';
import {
  getPayerBySlug,
  GetPayerResponseBody,
} from '../../../services/MemberService';
import { PayerSlug } from '../../../configs/payers';

export const supportedPayerSlugs: PayerSlug[] = [
  PayerSlug.Aetna,
  PayerSlug.AllinaHealth,
  PayerSlug.AllinaHealthAetna,
  PayerSlug.InnovationHealth,
  PayerSlug.SilverScript,
];

/**
 * The first step in the payer member registration process in which the user enters their insurance id.
 *
 * The user arrives here from the `PayerMemberLandingPage`.
 */
export default function PayerMemberRegistrationWizard(): JSX.Element {
  const { payerSlug } = useRouteMatch<{ payerSlug: PayerSlug }>().params;
  const [payer, setPayer] = useState<GetPayerResponseBody>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    (async () => {
      setPayer(
        await getPayerBySlug({
          payerSlug:
            payerSlug === PayerSlug.AllinaHealthAetna
              ? PayerSlug.AllinaHealth
              : payerSlug,
        }),
      );
      setLoading(false);
    })();
  }, [payerSlug]);

  // Optional. Each specified value will be used as the initial state
  // for the input with the given name.
  const initialState: Partial<MemberRegistrationInitialState> = {
    selectedPayer: payer!,
  };

  enum WizardSteps {
    FullName,
    PersonalInformation,
    Account,
  }

  if (!supportedPayerSlugs.includes(payerSlug)) {
    return <Redirect to={'/'} />;
  }

  if (loading) {
    return <></>;
  }

  return (
    <RegistrationWizard
      className={'PayerMemberRegistrationWizard'}
      initialState={initialState}
      gaFlowName={payer!.payerName}
      registrationFlow={UserRegistrationFlow.AETNA_ENROLLMENT}
      steps={WizardSteps}
      initialStep={WizardSteps.FullName}
      stepsToComponent={{
        [WizardSteps.FullName]: MemberNameStep,
        [WizardSteps.PersonalInformation]: MemberPersonalInformationStep,
        [WizardSteps.Account]: MemberAccountStep,
      }}
    />
  );
}
