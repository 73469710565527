import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./MemberCreateAccountPage.scss";
import RegistrationForm
    , {
    RegistrationFormType
} from "../../components/registrationModal/RegistrationForm";
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router-dom";
import {loadConfig} from "../../services/ConfigService";
import {
    GetAllPayersResponseBody,
    GetPayerResponseBody
} from "../../services/MemberService";
import Loading from "../../components/common/Loading";
import LoadingMessage from "../../components/common/LoadingMessage";
import {MemberRegistrationParameters} from "../../services/UserService";
import {SelectedPayerContext} from "../../contexts/SelectedPayerContext";
import { sendPageView } from "../../withTracker";

/**
 * The first step in the member registration process in which the user selects
 * their payer and enters their insurance id.
 *
 * The user arrives here from the `MemberLandingPage`.
 */
export default function MemberCreateAccountPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {history} = props;

    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const [selectedPayer, setSelectedPayer] = useState<GetPayerResponseBody | null>(null);

    const {payerDetails, setPayerDetails} = useContext(SelectedPayerContext);

    const displayPayerDetails = false; // for debugging, consider removing this

    async function postSubmit(parameters: MemberRegistrationParameters) {
        setPayerDetails(null);

        sendPageView(
            `${history.location.pathname}?step=complete`,
            ` - Member Create Account Registration Complete`);

        const searchParams = new URLSearchParams();
        searchParams.set("justRegistered", "true");
        searchParams.set("postalCode", parameters.zipCode);
        history.push(`/find-an-event?${searchParams.toString()}`);
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        sendPageView(
            `${history.location.pathname}?step=1`,
            ` - Member Create Account Registration Step 1`);
    }, []);

    useEffect(() => {

        const loadAllPayers = async () => {

            const config = await loadConfig();

            const request = new Request(`${config.apiOrigin}/all-payers`);

            // This is one of the rare, non-authenticated API endpoints.
            const response = await fetch(request);

            if (!response.ok) {
                const responseBodyText = await response.text();

                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);

                setLoading(false);
                setLoadingErrorMessage(t('memberCreateAccountPage.unexpectedError'));

                return;
            }

            const responseBody: GetAllPayersResponseBody = await response.json();

            setAllPayers(responseBody.allPayers);
            setLoading(false);
            setLoadingErrorMessage(null);

        };

        loadAllPayers();

    }, [t]);

    useEffect(() => {

        if (payerDetails === null) {
            history.push("/member/select-payer");
            return;
        }

        if (allPayers.length === 0) {
            return;
        }

        const selectedPlayer = allPayers.find(payer => payer.id === payerDetails.payerId);
        if (selectedPlayer === undefined) {
            history.push("/member/select-payer");
            return;
        }

        setSelectedPayer(selectedPlayer);

    }, [ payerDetails, allPayers, history ]);

    return (
        <div className="MemberCreateAccountPage">
            <h1>{t('memberCreateAccountPage.complete')}</h1>
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <>
                    <LoadingMessage message={loadingErrorMessage}/>
                    <p>
                        <Link to="/member/select-payer">{t('memberCreateAccountPage.changeProvider')}</Link>
                    </p>
                </>
            )}
            {payerDetails !== null && selectedPayer !== null && displayPayerDetails &&
                <>
                    <div className="MemberCreateAccountPage_PayerDetails">
                        <div className="MemberCreateAccountPage_PayerDetails_Item">
                            <b>{t('memberCreateAccountPage.provider')}</b>: {selectedPayer.payerName}
                        </div>
                        <div className="MemberCreateAccountPage_PayerDetails_Item">
                            <b>{`${selectedPayer.payerName} ID`}</b>: {payerDetails.insuranceId}
                        </div>
                        <div className="MemberCreateAccountPage_PayerDetails_Item">
                            <Link to="/member/select-payer">{t('memberCreateAccountPage.changeProvider')}</Link>
                        </div>
                    </div>
                   <hr/>
                </>
            }
            {payerDetails !== null &&
                <RegistrationForm postSubmit={postSubmit}
                                  type={RegistrationFormType.MEMBER}
                                  eventType={null}
                                  setShowSelfGuidedWaiver={null}
                                  id="LegacyRegistrationSubmit"
                />
            }
        </div>
    );
}
