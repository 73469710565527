import { CLUB_ROLES_STRINGS } from "../constants/dashboard";
import useGetMyClubs from "./useGetMyClubs";

interface IParams {
    urlFragment?: string
    id?: number
}

const useMyClubStatus = ({ urlFragment, id }: IParams): CLUB_ROLES_STRINGS | undefined | string => {
    const [myClubs, loading] = useGetMyClubs()

    let club = null

    if (urlFragment) {
        club = myClubs?.find(club => club.urlFragment === urlFragment)
    } else if (id) {
        club = myClubs?.find(club => club.id === id)
    }
    
    if (loading) return ''

    return club ? club.role : CLUB_ROLES_STRINGS.UNKNOWN

}

export default useMyClubStatus
