import React, { SyntheticEvent, useContext, useState } from "react";
import { useTranslation } from 'react-i18next';

import Button, { ButtonStyles } from "../common/Button";
import { useFormState } from "react-use-form-state";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Checkbox from '@material-ui/core/Checkbox';
import "./RegistrationForm.scss";
import FormLabel from '@material-ui/core/FormLabel';
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import {
    LeaderRegistrationParameters,
    MemberRegistrationParameters,
    registerLeader,
    registerMember,
    testIfEmailRegistered
} from "../../services/UserService";
import { UserContext } from "../../contexts/UserContext";
import { SelectedPayerContext } from "../../contexts/SelectedPayerContext";
import Loading from "../common/Loading";
import ContactPhoneNumber from "../common/ContactPhoneNumber";
import { EventType } from "../../util/EventType";
import {
    getPhoneUnmask,
    isValidEmail, PHONE_REGEX, readRegistrationParametersFromSession,
    readUtmRegistrationParametersFromSession
} from "../../util/Util";
import { UserRegistrationFlow } from "../../services/Models";
import InputMask from "react-input-mask";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// Used to indicate current registration flow - determined by URL not determined by if the user is a member or leader.
export enum RegistrationFormType {
    LEADER,
    MEMBER,
    PAYER_DETAILS
}

interface RegistrationFormProps extends RouteComponentProps {
    type: RegistrationFormType,
    eventType: string | null,
    postSubmit: Function,
    setShowSelfGuidedWaiver: Function | null,
    deputyLeaderInviteUrlFragment?: string,
    id?: string 
}

function RegistrationForm(props: RegistrationFormProps) {
    const { t } = useTranslation('registrationModal');
    const { memberCode } = readRegistrationParametersFromSession();
    const initialState = {
        firstName: "",
        lastName: "",
        renewId: memberCode ?? "",
        zipCode: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        termsOfUse: false
    }

    let id = props.id === undefined ? 'buttonId': props.id;

    const [formState, {text, password, email, checkbox, tel}] = useFormState(initialState);
    const [registrationError, setRegistrationError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    const {login} = useContext(UserContext);
    const {payerDetails} = useContext(SelectedPayerContext);

    const submitEnabled = () => {
        const allFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        });
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);
        const termsOfUseAccepted = formState.values.termsOfUse;
        const waiverAccepted = formState.values.selfGuidedEventsWaiver;

        if (isSelfGuidedEvent) {
            return allFieldsValid && allValuesValid && termsOfUseAccepted && waiverAccepted;
        } else {
            return allFieldsValid && allValuesValid && termsOfUseAccepted;
        }
    }

    const isSelfGuidedEvent = props.eventType === EventType.SELF_GUIDED;

    async function testEmailRegistered(email: string) {
        if (email) {
            try {
                const emailIsRegisteredResponse = await testIfEmailRegistered(email);
                if (emailIsRegisteredResponse.isRegistered) {
                    formState.setFieldError("email", t('registrationForm.emailUsed'))
                }
            } catch (e) {
                formState.setFieldError("email", t('registrationForm.unknownError'))
            }
        }
    }

    const elementConfig: Record<string, any> = {
        firstName: {
            name: "firstName",
            validate: (value: string) => {
                if (!value.trim()) {
                    //return 'First Name is required';
                    //return false;
                    return " ";
                }
            },
            validateOnBlur: true
        },
        lastName: {
            name: "lastName",
            validate: (value: string) => {
                if (!value.trim()) {
                    //return 'Last Name is required';
                    return " ";
                }
            },
            validateOnBlur: true
        },
        renewId: {
            name: 'renewId',
            validate: (value: string) => {
                if (props.type !== RegistrationFormType.MEMBER || payerDetails !== null) {
                    return;
                }
                const validRenewIdRegex = /^[SAsa][0-9]{9}$/;
                if (!value.trim()) {
                    //return 'Member Confirmation Code is required';
                    return " ";
                }
                if (!value.match(validRenewIdRegex)) {
                    return t('registrationForm.invalidConfCode');
                }
            },
            validateOnBlur: true
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value.trim()) {
                    //return 'Zip Code is required';
                    return " ";
                }
                if (!value.match(zipCodeRegex)) {
                    //return "Invalid zip code";
                    return " ";
                }
            },
            validateOnBlur: true
        },
        phoneNumber: {
            name: 'phoneNumber',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return ' ';
                }
            },
            validateOnBlur: true
        },
        email: {
            name: 'email',
            validate: (value: string) => {
                if (value.trim() === "") {
                    //return "Email is required.";
                    return " ";
                }
                if (!isValidEmail(value)) {
                    return "Email address is not an email address.";
                }
                /*if (value !== formState.values.confirmEmail && formState.touched.confirmEmail) {
                    formState.setFieldError('confirmEmail', 'Email addresses do not match. Please Re-type confirm email.');
                }*/
            },
            onBlur: ((e: SyntheticEvent) => {
                testEmailRegistered((e.target as HTMLInputElement).value).then(() => {
                })
                return undefined;
            }),
            validateOnBlur: true
        },
        confirmEmail: {
            name: 'confirmEmail',
            validate: (value: string) => {
                if (value.trim() === "") {
                    //return "Please confirm email address.";
                    return " ";
                }
                if (value?.toLowerCase() !== formState.values.email?.toLowerCase()) {
                    return t('registrationForm.emailMatchFail');
                }

            },
            validateOnBlur: true
        },
        password: {
            name: 'password',
            validate: (value: string) => {
                const trimmedInput = value.trim();
                if (!trimmedInput) {
                    //return "Password required."
                    return " ";
                }
                if (trimmedInput.length < 8) {
                    return t('registrationForm.invalidPasswordLength');
                }
                if (trimmedInput.search(/[A-Z]/) === -1 || trimmedInput.search(/[0-9]/) === -1) {
                    return t('registrationForm.invalidPasswordFormat');
                }
            },
            validateOnBlur: true
        },
        confirmPassword: {
            name: 'confirmPassword',
            validate: (value: string) => {
                if (!value.trim()) {
                    //return "Please confirm password."
                    return " ";
                }
                if (value !== formState.values.password) {
                    return t('registrationForm.passwordMatchFail');
                }
            },
            validateOnBlur: true
        },
        termsOfUse: {
            name: 'termsOfUse'
        },
        selfGuidedEventsWaiver: {
            name: 'selfGuidedEventsWaiver'
        }
    }

    async function handleSubmit() {
        try {
            console.log('Handling Submit :) ')
            setLoading(true);
            if (props.type === RegistrationFormType.LEADER) {
                let deputyLeaderInviteUrlFragment = props.deputyLeaderInviteUrlFragment === undefined ? null : props.deputyLeaderInviteUrlFragment;
                const parameters : LeaderRegistrationParameters = {
                    email: formState.values.email,
                    phone: getPhoneUnmask(formState.values.phoneNumber),
                    password: formState.values.password,
                    firstName: formState.values.firstName,
                    lastName: formState.values.lastName,
                    zipCode: formState.values.zipCode,
                    deputyLeaderInviteUrlFragment: deputyLeaderInviteUrlFragment
                };
                await registerLeader(parameters).then(
                    // good
                    () => {
                        login(formState.values.email, formState.values.password).then(
                            // good
                            () => {
                                setLoading(false);
                                props.postSubmit(parameters);
                            },
                            // nope
                            () => {
                                setLoading(false);
                                setRegistrationError(t('registrationForm.registrationError'));
                            });
                    },
                    // nope
                    () => {
                        setLoading(false);
                        setRegistrationError(t('registrationForm.registrationError'));
                    });
            } else if (props.type === RegistrationFormType.MEMBER) {
                setLoading(true);
                const parameters : MemberRegistrationParameters = {
                    email: formState.values.email,
                    phone: getPhoneUnmask(formState.values.phoneNumber),
                    password: formState.values.password,
                    firstName: formState.values.firstName,
                    lastName: formState.values.lastName,
                    zipCode: formState.values.zipCode,
                    renewId: (payerDetails === null) ? formState.values.renewId : payerDetails.insuranceId,
                    payerId: (payerDetails === null) ? undefined : payerDetails.payerId,
                    ...readUtmRegistrationParametersFromSession(),
                    registrationFlow: UserRegistrationFlow.LEGACY_MEMBER_FLOW
                };
                await registerMember(parameters).then(
                    // good
                    () => {
                        login(formState.values.email, formState.values.password).then(
                            // good
                            () => {
                                setLoading(false);
                                props.postSubmit(parameters);
                            },
                            // nope
                            () => {
                                setLoading(false);
                                setRegistrationError(t('registrationForm.registrationError'));
                            });
                    },
                    // nope
                    () => {
                        setLoading(false);
                        setRegistrationError(t('registrationForm.registrationError'));
                    });
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
            setRegistrationError(t('registrationForm.registrationError'));
        }
    }

    function setShowSelfGuidedWaiver(b: boolean) {
        if (props.setShowSelfGuidedWaiver !== null) {
            props.setShowSelfGuidedWaiver(b);
        }
    }

    const renewIdValid = !elementConfig.renewId.validate(formState.values.renewId);

    return (
        <div className={"RegistrationForm_Wrapper"}>
            {loading && <><Loading loading={loading}/></>}
            {!loading && <>
                <form>
                    <div className={"RegistrationForm_FieldWrapper"}>
                        <div className={"RegistrationForm_Field"}>
                            <TextField {...text(elementConfig.firstName)}
                                       className="RegistrationForm_Input"
                                       label={t('registrationForm.firstNamePlaceholder')}
                                       required
                                       variant={"outlined"}
                                       error={formState.errors.firstName !== undefined}
                                       size="small"/>
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.firstName && (formState.errors.firstName ? "error" : "validated")}`}/>
                            </div>
                        </div>
                        <div className={"RegistrationForm_Field"}>
                            <TextField
                                {...text(elementConfig.lastName)}
                                className="RegistrationForm_Input"
                                label={t('registrationForm.lastNamePlaceholder')}
                                variant="outlined"
                                error={formState.errors[elementConfig.lastName.name] !== undefined}
                                required
                                size="small"/>
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.lastName && (formState.errors.lastName ? "error" : "validated")}`}/>
                            </div>
                        </div>
                    </div>
                    {(props.type === RegistrationFormType.MEMBER && payerDetails === null &&
                        <React.Fragment>
                            <div className={"RegistrationForm_FieldWrapper RegistrationForm_ConfirmationCodeFieldWrapper"}>
                                <div className={"RegistrationForm_Field RegistrationForm_ConfirmationCodeField"}>
                                    <TextField {...text(elementConfig.renewId)}
                                               className="RegistrationForm_Input"
                                               label={t('registrationForm.memberConfCodePlaceholder')}
                                               variant={"outlined"}
                                               error={formState.errors.renewId !== undefined}
                                               inputProps={{
                                                   maxLength: 10
                                               }}
                                               required
                                               size="small"/>
                                    <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                        <CheckCircleIcon
                                            className={`RegistrationForm_FieldIndicator ${
                                                renewIdValid 
                                                    ? "validated"
                                                    : (formState.touched.renewId || formState.values.renewId) && !renewIdValid
                                                    ? "error" : ""}`}/>
                                    </div>
                                    <div className={"RegistrationForm_ElementHelpText"}>
                                        {t('registrationForm.memberConfCodeHelperText')}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div className={"RegistrationForm_FieldWrapper"}>
                        <div className={"RegistrationForm_Field"}>
                            <TextField
                                {...text(elementConfig.zipCode)}
                                label={t('registrationForm.zipCodePlaceholder')}
                                variant="outlined"
                                className="RegistrationForm_Input"
                                error={formState.errors.zipCode !== undefined}
                                required
                                size="small"
                                inputProps={{
                                    length: 5,
                                    minLength: 5,
                                    maxLength: 5
                                }}
                            />
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.zipCode && (formState.errors.zipCode ? "error" : "validated")}`}/>
                            </div>
                        </div>
                        <div className={"RegistrationForm_Field"}>
                            <InputMask
                                mask='(999) 999-9999'
                                maskChar='*'
                                {...tel(elementConfig.phoneNumber)}
                            >
                                {(inputProps: any) =>
                                    <TextField {...inputProps}
                                    label={t('registrationForm.phoneNumberPlaceholder')}
                                    variant="outlined"
                                    className="RegistrationForm_Input"
                                    error={formState.errors.phoneNumber !== undefined}
                                    required
                                    size="small"/>
                                }
                            </InputMask>
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.phoneNumber && (formState.errors.phoneNumber ? "error" : "validated")}`}/>
                            </div>
                            <div className={"RegistrationForm_ElementHelpText"}>
                                {t('registrationForm.phoneNumberHelperText')}
                            </div>
                        </div>
                    </div>
                    <div className={"RegistrationForm_FieldWrapper"}>
                        <div className={"RegistrationForm_Field"}>
                            <TextField
                                {...email(elementConfig.email)}
                                className="RegistrationForm_Input"
                                type={"email"}
                                label={t('registrationForm.emailPlaceholder')}
                                error={formState.errors.email !== undefined}
                                variant="outlined"
                                size="small"/>
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.email && (formState.errors.email ? "error" : "validated")}`}/>
                            </div>
                        </div>
                        <div className={"RegistrationForm_Field"}>
                            <TextField
                                {...email(elementConfig.confirmEmail)}
                                className="RegistrationForm_Input"
                                label={t('registrationForm.confirmEmailPlaceholder')}
                                error={formState.errors.confirmEmail !== undefined}
                                variant="outlined"
                                size="small"/>
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.confirmEmail && (formState.errors.confirmEmail ? "error" : "validated")}`}/>
                            </div>
                        </div>
                    </div>
                    <div className={"RegistrationForm_FieldWrapper"}>
                        <div className={"RegistrationForm_Field"}>
                            <TextField
                                {...password(elementConfig.password)}
                                className="RegistrationForm_Input"
                                label={t('registrationForm.passwordPlaceholder')}
                                variant="outlined"
                                error={formState.errors.password !== undefined}
                                size="small"
                                InputProps={{
                                    type: values.showPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setValues({
                                                        ...values,
                                                        showPassword: !values.showPassword,
                                                    })
                                                }}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.password && (formState.errors.password ? "error" : "validated")}`}/>
                            </div>
                            <div className={"RegistrationForm_ElementHelpText"}>
                                {t('registrationForm.passwordHelperText')}
                            </div>
                        </div>
                        <div className={"RegistrationForm_Field"}>
                            <TextField
                                {...password(elementConfig.confirmPassword)}
                                className="RegistrationForm_Input"
                                label={t('registrationForm.confirmPasswordPlaceholder')}
                                variant="outlined"
                                error={formState.errors.confirmPassword !== undefined}
                                size="small"
                                InputProps={{
                                    type: values.showConfirmPassword ? 'text' : 'password',
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setValues({
                                                        ...values,
                                                        showConfirmPassword: !values.showConfirmPassword,
                                                    })
                                                }}
                                                edge="end"
                                            >
                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            <div className={"RegistrationForm_FieldIndicatorWrapper"}>
                                <CheckCircleIcon
                                    className={`RegistrationForm_FieldIndicator ${formState.touched.confirmPassword && (formState.errors.confirmPassword ? "error" : "validated")}`}/>
                            </div>
                        </div>
                    </div>
                    <div className={"RegistrationFormField_TermsOfUseField"}>
                        {props.type === RegistrationFormType.LEADER && <>
                            <div className={"RegistrationFormField_TermsOfUseWrapper"}>
                            <FormLabel>{t('registrationForm.acceptTOS')} <Link target="_blank"
                                                              className={"RegistrationForm_TermsOfUseLink"}
                                                              to={"/club-leader-terms-of-use"}>{t('registrationForm.TOS')}</Link></FormLabel>
                                <Checkbox {...checkbox(elementConfig.termsOfUse)}/>
                            </div>
                        </>}
                        {props.type === RegistrationFormType.MEMBER && <>
                            <div className={"RegistrationFormField_TermsOfUseWrapper"}>
                                <Checkbox {...checkbox(elementConfig.termsOfUse)}/>
                                <FormLabel>{t('registrationForm.acceptTOS')} <Link target="_blank"
                                                              className={"RegistrationForm_TermsOfUseLink"}
                                                              to={"/terms-of-use"}>{t('registrationForm.TOS')}</Link></FormLabel>
                            </div>
                        </>}
                    </div>
                    <div className={"RegistrationFormField_SelfGuidedEventWaiverField"}>
                        {props.type === RegistrationFormType.MEMBER && isSelfGuidedEvent && <>
                            <div className={"RegistrationFormField_SelfGuidedEventWaiverWrapper"}>
                                <Checkbox {...checkbox(elementConfig.selfGuidedEventsWaiver)}/>
                                <FormLabel>{t('registrationForm.memberLiabilityWaiver1')} <Link to={"#"}
                                                 className={"RegistrationForm_TermsOfUseLink"}
                                                 onClick={() => {
                                                     setShowSelfGuidedWaiver(true)
                                                 }}>{t('registrationForm.memberLiabilityWaiver2')}</Link> {t('registrationForm.memberLiabilityWaiver3')}</FormLabel>
                            </div>
                        </>}
                    </div>
                    {(registrationError !== null || Object.entries(formState.errors).length > 0) &&
                    <div className="RegistrationModal_FormErrors">
                        <div className="RegistrationForm_FormError">{registrationError}</div>
                        {Object.entries(formState.errors).map(([key, value]) =>
                            <div key={key} className="RegistrationForm_FormError">{value}</div>)}
                    </div>}
                    <div className="RegistrationForm_Bottom">
                        <Button clickHandler={handleSubmit}
                                id={id}
                                className="RegistrationForm_SubmitButton"
                                buttonStyle={ButtonStyles.FilledPrimary}
                                disabled={!submitEnabled()}>{t('registrationForm.signMeUpButtonText')}</Button>
                        <div className="RegistrationForm_FormHelpText">{t('registrationForm.needHelpText')}
                            <span className="RegistrationForm_FormHelpText_ContactPhoneNumber">
                                {props.type === RegistrationFormType.MEMBER
                                && <ContactPhoneNumber formType={RegistrationFormType.MEMBER}/>}
                                {props.type === RegistrationFormType.LEADER
                                &&
                                <ContactPhoneNumber formType={RegistrationFormType.LEADER}/>}
                            </span>
                        </div>
                    </div>
                </form>
            </>}
        </div>
    )
}

export default withRouter(RegistrationForm);
