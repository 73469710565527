import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IconButton, Modal } from "@material-ui/core";
import { getMonths } from "../../../util/ignite/Dashboard.utils";
import { IActivityItem } from '../../../types/dashboard'
import TrackingActivityItem from './TrackingActivityItem'
import { useParams } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import TrophyBadge from './TrophyBadge'
import FirstBadge from './FirstBadge'
import useBadge from "../../../hooks/useBadge"

import "./TrackingActivityModal.scss";
import { memberDashboardConfig } from "../../../configs/memberDashboard";
import { ACTIVITY_MONTH_STATUS } from "../../../constants/dashboard";

interface ITrackingActivityModalProps {
    isOpen: boolean
    myActivity?: IActivityItem
    setIsOpenTrackingActivity: Function
    getMyActivity: Function
    fetchLeaderboard?: Function
    fetchMembersActivity?: Function
    additionalActionOnFirstBadgeClick?: Function
}

export default function TrackingActivityModal({
    myActivity,
    isOpen,
    setIsOpenTrackingActivity,
    getMyActivity,
    fetchLeaderboard,
    fetchMembersActivity,
    additionalActionOnFirstBadgeClick
}: ITrackingActivityModalProps): JSX.Element {
    const { t } = useTranslation('ignite/dashboard');
    const { clubId } = useParams<{ clubId?: string }>()
    const [activeMonth, setActiveMonth] = useState('');

    const records = myActivity?.activityRecords || []
    const userId = myActivity?.userRecord?.userId
    const maxReportingPeriod = myActivity?.maxReportingPeriod || memberDashboardConfig.DEFAULT_MAX_REPORTING_PERIOD

    const {
        shouldShowFirstBadge,
        setShouldShowFirstBadge,
        shouldShowTrackingCompletedBadge,
        setShouldShowTrackingCompletedBadge
    } = useBadge(myActivity?.badgesEarned)

    useEffect(() => {
        if (isOpen) {
            getMyActivity();
        } else {
            fetchLeaderboard && fetchLeaderboard();
            fetchMembersActivity && fetchMembersActivity()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const totalAmount = records.reduce((prev, next) => prev + next.activityTotal, 0)
    const isCompleted = records.length === myActivity?.maxReportingPeriod && records.every(record => record.activityTotal)
    const minAmountOfMonthForShowing = maxReportingPeriod + 1
    const months = getMonths({ records, includeCurrentMonth: true, maxReportingPeriod: minAmountOfMonthForShowing, reversedOrder: false})

    const onClose = () => {
        setIsOpenTrackingActivity(false)
        if (shouldShowFirstBadge) setShouldShowFirstBadge(false)
        if (shouldShowTrackingCompletedBadge) setShouldShowTrackingCompletedBadge(false)
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <div className="tracking-activity-modal-wrapper">
                <IconButton
                    className='close-button'
                    onClick={onClose}
                >
                    <span hidden aria-hidden="true">Close</span>
                    <CancelIcon className="icon" />
                </IconButton>
                <div className="tracking-activity-modal">
                    {shouldShowFirstBadge &&
                        <FirstBadge onClick={() => {
                            setShouldShowFirstBadge(false)
                            additionalActionOnFirstBadgeClick && additionalActionOnFirstBadgeClick()
                        }} />
                    }
                    {shouldShowTrackingCompletedBadge &&
                        <TrophyBadge onClick={() => {
                            setShouldShowTrackingCompletedBadge(false)
                            onClose()
                        }} />}
                    {!shouldShowFirstBadge && !shouldShowTrackingCompletedBadge && (
                        <div>
                            <div className="top-content">
                                <div className="top-content__text">
                                    {t('activities')}
                                </div>
                                <div className="top-content__amount">
                                    {totalAmount}
                                </div>
                            </div>
                            <div className="middle-content">
                                {isCompleted ?
                                    <div className="completed">
                                        {t('youAreCaughtUp')}
                                    </div> :
                                    <div className="enter-activity">
                                        {t('enterMonthlyActivity')}
                                    </div>
                                }
                            </div>
                            <div className="bottom-content">
                                {months.map((item, index) => {
                                    return (
                                        <TrackingActivityItem
                                            activeMonth={activeMonth}
                                            setActiveMonth={setActiveMonth}
                                            userId={userId}
                                            clubId={clubId}
                                            getMyActivity={getMyActivity}
                                            slug={item.key}
                                            readOnly={index > Number(myActivity?.maxReportingPeriod) || item.status === ACTIVITY_MONTH_STATUS.LOCKED}
                                            {...item}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal >
    );
}
