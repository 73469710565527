import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./VirtualClubsCategoryPage.scss";

import Carousel from "react-elastic-carousel";

import getClubAssociationImageViaSanity from "../../sanity/getClubAssociationImageViaSanity";
import { IClubWithDesc } from "../../types/clubFinder";
import useGetAllCategories from "../../hooks/useGetAllCategories";
import getClubsForTerm from "../../api/getClubsForTerm";

import ClubCard from "../../components/virtualClubs/ClubCard";
import Loading from "../../components/common/Loading";
import LoadingMessage from "../../components/common/LoadingMessage";
import { getClubPageRouteLink } from "../../util/ignite/routes.utils";

/**
 * VirtualClubsCategoryPage
 * route: /virtual-clubs/category/:termId
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&mode=design
 * 
 * Displays all clubs associated with a given category.
 */

interface IClubDetails extends IClubWithDesc {
    termName?: string;
    associationImage?: string;
}

export default function VirtualClubsCategoryPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);

    const { termId } = useRouteMatch<{ termId: string }>().params;
    const [terms] = useGetAllCategories();

    // raw data from api db, dictionary- all clubs organized by term key
    const [clubDataForTerms, setClubDataForTerms] = useState<{ [key: number]: [IClubDetails] | [] }>({});

    // just the clubs that should be displayed in the carousel
    const [clubsForTerm, setClubsForTerm] = useState<IClubDetails[] | []>([]);

    const carouselRef = useRef<any>();
    const carouselBreakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 600, itemsToShow: 2 }
    ];

    useEffect(() => {
        setLoading(false);
    }, [clubsForTerm])


    useEffect(() => {
        let controller: any = new AbortController();
        async function loadData() {
            const theClubs: { [key: string]: [IClubDetails] | [] } = {};
            if (terms) {
                // 1. for every term in terms, get club data
                for (const term of terms) {
                    let termIdStr = (term.id).toString();
                    let clubsForTerm = await getClubsForTerm(term.id, controller);

                    if (!clubsForTerm || !clubsForTerm?.allClubs) {
                        setLoadingErrorMessage(t('virtualClubsCategoriesPage.loadingError'));
                        setLoading(false);
                    } else {
                        theClubs[termIdStr] = clubsForTerm.allClubs;

                        // 2. when an individual term's club data comes back, 
                        // for each individual club, send out the call for its sanity data
                        for (const club of theClubs[termIdStr]) {
                            club.termName = term.termName;
                            club.associationImage = undefined;
                            const clubSanityData = await getClubAssociationImageViaSanity(club.shortCode);
                            if (!!clubSanityData?.result?.length) {
                                if (clubSanityData.result[0].associationImage != undefined && clubSanityData.result[0].associationImage != "") {
                                    club.associationImage = clubSanityData.result[0].associationImage;
                                }

                            }
                    
                         
                        }
                    }

                }
            }

            controller = null;
            // 3. after ALL data has come back from both the API and Sanity, put it in the state 
            setClubDataForTerms(theClubs);
        }

        setLoadingErrorMessage(null);
        setLoading(true);

        if (!!terms?.length) {
            loadData().finally(() => {
                setLoading(false);
            });
        }
        else {
            setLoadingErrorMessage(t('virtualClubsCategoriesPage.loadingError'));
            setLoading(false);
        }


        return () => controller?.abort();
    }, [terms])


    useEffect(() => {
        // when all data is loaded (OR, when you click onto a different category) -
        // pull out current term's club data and put it in state clubsforTerm (singular)
        let termIdNum: number = parseInt(termId);
        if (clubDataForTerms.hasOwnProperty(termIdNum)) {
            let newClubsForTerms = clubDataForTerms[termIdNum];
            setClubsForTerm(newClubsForTerms);
        }

        // reset carousel
        const carousel = carouselRef.current
        carousel?.goTo(0);
    }, [clubDataForTerms, termId])

    return (
        <div className="VirtualClubsCategoryPage">
            <div>

                <div className="VirtualClubs_TermsContainer">
                    <div className="VirtualClubs_Terms equal-columns is-square has-5">
                        {terms && Object.values(terms).map(term => (
                            <div key={term.id} className={`VirtualClubs_Term ${(Number(term.id) === Number(termId)) ? "active" : ""}`} onClick={() => history.push('/virtual-clubs/categories/' + term.id)}>
                                <img src={require("../../assets/" + term.imageUrl)} className="term_image" />
                                <h5>{term.termName}</h5>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="VirtualClubs_ClubList">

                    {loading &&
                        <div>
                            <Loading className="pt-48" loading={loading} />
                        </div>
                    }
                    {!loading && loadingErrorMessage !== null && (
                        <LoadingMessage
                            className={"pt-48"}
                            showLoginLink={false}
                            message={loadingErrorMessage} />
                    )}

                    {!loading &&
                        (<Carousel
                            isRTL={false}
                            breakPoints={carouselBreakPoints}
                            showEmptySlots
                            ref={carouselRef}
                            className="recCarousel"
                        >
                            {Object.values(clubsForTerm).map((club) => (
                                <ClubCard
                                    key={club.id}
                                    termName={club.termName}
                                    clubName={club.clubName}
                                    clubDescription={club.description}
                                    clubImage={club.associationImage}
                                    CTAurl={getClubPageRouteLink(true, club.urlFragment)} 
                                    CTAtext={t('virtualClubsCategoriesPage.CTAtext')}
                                    className="VirtualClubs_ClubCard"
                                >
                                </ClubCard>

                            ))}

                        </Carousel>
                        )}
                </div>
            </div>
        </div >
    );
}