import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

const REQUEST_REFERRAL_URL = '/ignite/request-referral'

const postRequestReferral = async (clubShortCode: string, userToken?: string | null): Promise<{ referralId: number, clubId: number } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${REQUEST_REFERRAL_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            clubShortCode: clubShortCode,
            ...(Boolean(userToken) && { userToken })
        })
    })

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))

}

export default postRequestReferral
