import {ButtonStyles} from "../../common/Button";
import PlayButton from "../../common/PlayButton";
import React from "react";
import {useTranslation} from "react-i18next";

export interface ContinueButtonProps {
    onClick: () => void;
    enabled: boolean;
    className?: string;
    text?: string;
}

export function ContinueButton(props: ContinueButtonProps): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <PlayButton type="button"
                    clickHandler={props.onClick}
                    text={props.text ?? t('registration.continueButton')}
                    className={`ContinueButton ${props.className || ''}`}
                    buttonStyle={ButtonStyles.FilledPrimary}
                    disabled={!props.enabled}
        />
    )
}
