import React, { useState, useEffect, memo } from "react";
import { TextField, Tooltip } from '@material-ui/core'
import { applyMaxMinChecking } from '../../../util/ignite/Dashboard.utils'
import { memberDashboardConfig } from "../../../configs/memberDashboard";
import { useTranslation } from "react-i18next";
import usePrevious from "../../../hooks/usePrevious";

interface IMonthItemProps {
    name: string
    onSave: (event: React.FocusEvent<HTMLInputElement>, year: number, month: number) => Promise<any> | undefined
    activityTotalInPerson?: number
    readOnly?: boolean
    count?: number
    income?: number
    year?: number
    month?: number
}

const MonthItem = ({
    name,
    readOnly,
    count,
    onSave,
    activityTotalInPerson,
    income,
    year,
    month
}: IMonthItemProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard')
    const [amount, setAmount] = useState<number | undefined | string>(activityTotalInPerson)
    const [shouldShowTooltip, setShouldShowTooltip] = useState(false)

    useEffect(() => {
        setAmount(activityTotalInPerson ?? "")
    }, [activityTotalInPerson])

    const showTooltip = () => {
        setShouldShowTooltip(true)
        setTimeout(() => setShouldShowTooltip(false), memberDashboardConfig.TOOLTIP_HIDE_TIME)
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const formattedValue = applyMaxMinChecking(value)

        if (Number(e.target.value) > memberDashboardConfig.MAX_ACTIVITY_PER_MONTH) showTooltip()

        setAmount(formattedValue)
    }


    const onMonthClick = () => {
        if (readOnly) showTooltip()
    }

    const isAbsentActivity = !amount && amount !== 0
    const withIncome = income !== undefined
    const prevAmount = usePrevious(amount)
    const tooltipTitle = readOnly ? t('changeReadOnlyActivityText') : t('activityMaxLimit', { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH })

    const onBlur = (e: React.FocusEvent) => {
        const target = e.target as HTMLInputElement
        if (!readOnly && amount !== prevAmount && year && month && target.value !== '') {
            onSave(e as React.FocusEvent<HTMLInputElement>, year, month)?.catch((e) => {
                setAmount(activityTotalInPerson ?? "")
            })
        }
        if (amount === '' && !readOnly) {
            setAmount(activityTotalInPerson)
        }
    }

    return (
        <div className="month" onClick={onMonthClick} >
            {withIncome && <label htmlFor={`month-${name}-${count}`}>{name}</label>}
            <Tooltip
                placement="top"
                open={shouldShowTooltip}
                arrow
                title={tooltipTitle}
                classes={{ tooltip: 'dashboard-tooltip', arrow: 'arrow' }} >
                <TextField
                    variant="outlined"
                    fullWidth
                    id={`month-${name}-${count}`}
                    className='amount'
                    placeholder='?'
                    value={amount}
                    onChange={onChange}
                    error={isAbsentActivity}
                    onBlur={onBlur}
                    hiddenLabel
                    size="small"
                    InputProps={{
                        readOnly,
                        classes: {
                            input: `${readOnly ? 'read-only' : ''} amount-input ${isAbsentActivity ? 'absent' : ''}`,
                            focused: 'label-focused',
                        }
                    }}
                />
            </Tooltip>
            {withIncome ?
                <div className="income">
                    {income ? income : '--'}
                </div> :
                <label htmlFor={`month-${name}-${count}`}>
                    {name}
                </label>
            }
        </div>
    )
}

export default memo(MonthItem)
