import React, { FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from 'react-router-dom';

import { Button, Container, Grid, Hidden, Typography } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PhoneIcon from '@material-ui/icons/Phone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import EmailIcon from '@material-ui/icons/Email';
import WebAssetIcon from '@material-ui/icons/WebAsset';

import Loading from '../../components/common/Loading';
import NotFound from '../../pages/notFound/NotFoundPage';

import { UserContext } from "../../contexts/UserContext";
import getMemberClubByUrlFragment from '../../api/getMemberClubByUrlFragment';
import postPendingJoinClub from '../../api/postPendingJoinClub';
import useGetClubV2ById from '../../hooks/useGetClubV2ById';

import { IClubV2 } from '../../types/clubFinder';
import { GetClubResponseBody } from '../../services/MemberService';
import { GetContactPhoneNumber, PhoneNumberOverride } from '../../components/common/ContactPhoneNumber';


const PHONE_NUMBER = GetContactPhoneNumber(PhoneNumberOverride.ANONYMOUS).display;

const ClubContainer: FC = (props: PropsWithChildren<unknown>) => {
  return (
    <Container className="ClubDirectory-Single" maxWidth="lg">
      {props.children ?? ''}
    </Container>
  );
};


/* 
 * Club page
 *  Jira: https://hellogrouperhealth.atlassian.net/browse/EDP-268
 *  Figma: https://www.figma.com/file/0ZCdv7tDtSxIdiakBRVx3e/Club-directory-(redesign)?type=design&node-id=494-2461&mode=design&t=cydnt88p4icMIuEh-0
 *
 *  Individual club page linked to from the ClubDirectory - URL is /clubs/:id
 *  "I'm Interested" button:
 *    1. registers user, if they are not authenticated
 *    2. sets the club to a "pending" status
 *    3. redirects user to their profile "My Clubs" page
*/

const Club: FC = () => {
  const { t } = useTranslation('club');
  const history = useHistory();
  const { user, authenticatedFetch } = useContext(UserContext);

  const { clubId } = useParams<{ clubId: string }>();

  const [data, isLoading]: [IClubV2 | undefined, boolean] = useGetClubV2ById(Number(clubId));
  const [clubV1, setClubV1] = useState<GetClubResponseBody | null | undefined>();
  const [pendingJoinSuccess, setPendingJoinSuccess] = useState(false);

  useEffect(() => {
    if (pendingJoinSuccess) {
      history.push({
        pathname: `/ignite/profile/clubs/`,
        search: "?showClubJoinedMessage=true"
      })
    }
  }, [pendingJoinSuccess])

  // TODO currently used to fetch primaryClubLeaders/secondaryClubLeaders
  // Switch with V2 endpoint in the future
  useEffect(() => {
    if (data?.urlFragment) {
      getMemberClubByUrlFragment(data?.urlFragment)
        .then((response) => {
          setClubV1(response);
        }).catch(() => {
          // getMemberClubByUrlFragment always fails when user is not logged in
          setClubV1(null);
        })
    }
  }, [data?.urlFragment]);

  // need to know if current use is a club leader (so we can hide the "I'm Interested" button from club leaders)
  // TODO currently used to fetch primaryClubLeaders/secondaryClubLeaders
  // Switch with V2 endpoint in the future
  const isClubLeader = useMemo(() => {
    if (clubV1 && user) {
      const isPrimaryClubLeader = clubV1.primaryClubLeaders?.some(x => x.email === user.email);
      const isDeputyClubLeader = clubV1.deputyClubLeaders?.some(x => x.email === user.email);
      return isPrimaryClubLeader || isDeputyClubLeader;
    }
    return false;
  }, [clubV1, user]);

  const setInterestedInClub = async () => {
    if (data?.id) {
      if (user) {
        postPendingJoinClub(Number(data.id))
          .then(() => setPendingJoinSuccess(true))
      } else {
        history.push({
          pathname: `/register-member/club-interest/${data.urlFragment}`,
          search: "?showClubJoinedMessage=true"
        })
      }
    }
  }

  if (isLoading) {
    return <Loading loading />;
  }

  if (!data) {
    return (
      <ClubContainer>
        <NotFound />
      </ClubContainer>
    );
  }

  const getLocation = () => {
    let location = "";
    if (data.locations && data.locations.length > 0) {
      if ((data.locations[0].city && data.locations[0].city.length > 0) || 
          (data.locations[0].state && data.locations[0].state.length > 0)) {
        location += (data.locations[0].city) ? (data.locations[0].city) : "";
        if (data.locations[0].state) {
          location += (location.length > 0) ? ", " + data.locations[0].state : data.locations[0].state;
        }
      }

      if (data.locations[0].postalCode && data.locations[0].postalCode.length > 0) {
        location += " " + data.locations[0].postalCode
      }
    }
    return location;
  }

  // React adds root url to all urls that do not start with http or https
  const addHttpToInvalidUrl = (url: string | undefined) => {
    if(url && !url.startsWith("http")) {
      return `http://${url}`;
    }
    return url;
  }

  return (
    <ClubContainer>
      <Grid container direction="row">
        <Grid item xs={12}>
          <Link to="/clubs" className="ClubDirectory-Single-BackToClubFinder">
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="body2"
            >
              <KeyboardArrowLeftIcon fontSize="large" />
              <span>{t('club.backButtonText')}</span>
            </Typography>
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          container
          direction="column"
          wrap="nowrap"
          spacing={4}
        >
          <Grid item>
            {data.terms && (
              <h5 className="ClubDirectory-Single-Surtitle">
                {data.terms.join(", ")}
              </h5>
            )}
            <h1 className="ClubDirectory-Single-Title">{data.name}</h1>
            {data.organizationName && (
              <div className="ClubDirectory-Single-Subtitle">
                {`Hosted by ${data.organizationName}`}
              </div>
            )}



            {(getLocation()).length > 0 && (
              <div className="ClubDirectory-Single-Subtitle">
                <PinDropIcon/>
                <span>{getLocation()}</span>
              </div>

            )}


            {data.phone && data.phone.length > 0 && (
              
              <div className="ClubDirectory-Single-Subtitle">
                <PhoneIcon/>
                <span>{data.phone}</span>
              </div>

            )}

            {data.email && data.email.length > 0 && (
              
              <div className="ClubDirectory-Single-Subtitle">
                <EmailIcon/>
                <span><a href="mailto:{data.eamil}">{data.email}</a></span>
              </div>

            )}


            {data.clubWebsiteUrl && data.clubWebsiteUrl.length > 0 && (
              
              <div className="ClubDirectory-Single-Subtitle">
                <WebAssetIcon/>
                <span><a target="_blank" href={addHttpToInvalidUrl(data.clubWebsiteUrl)} rel="noopener">{data.clubWebsiteUrl}</a></span>
              </div>

            )}

          </Grid>
          <Grid item>
            {
              !isClubLeader &&
              <Button
                variant="text"
                color="primary"
                className="ClubDirectory-Single-Button"
                onClick={() => {
                  setInterestedInClub();
                }}
              >
                {t('club.mainButtonText')}
              </Button>
            }
          </Grid>
          <Hidden mdUp>
            <Grid item xs={true} md={false}>
              <div
                className="ClubDirectory-Single-CoverImage"
                style={{
                  backgroundImage: `url("/images/passions/${((data.clubImage)? data.clubImage : 'other.jpg')}")`,
                }}
              />
            </Grid>
          </Hidden>
          <Grid item>{data.description}</Grid>
        </Grid>
        <Grid item xs={false} md={6}>
          <div
            className="ClubDirectory-Single-CoverImage"
            style={{
              backgroundImage: `url("/images/passions/${((data.clubImage)? data.clubImage : 'other.jpg')}")`,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <hr className="ClubDirectory-Single-Spacer" />
        </Grid>
        <Grid item xs={12}>
          <div className="ClubDirectory-Single-CallUs">
            <Grid container direction="row" alignItems="center" spacing={4}>
              <Grid item xs={12}>
                <h3>{t('helpCard.title')}</h3>
                <p>
                  <Trans
                    t={t}
                    values={{ phoneNumber: PHONE_NUMBER, email: 'info@hellogrouper.com' }}
                    i18nKey="helpCard.body"
                    components={{
                      callLink: <a href={`tel:${PHONE_NUMBER}`} />,
                      emailLink: <a href={`mailto:info@hellogrouper.com`}/>,
                    }}
                  />
                </p>
              </Grid>
              {/* TODO implement call scheduling
              Commenting button out for MVP since it's not doing anything - 
              <Grid item xs={12} md={3}>
                <Button
                  fullWidth
                  variant="text"
                  color="primary"
                  className="ClubDirectory-Single-Button"
                >
                  {t('helpCard.buttonText')}
                </Button>
              </Grid> */}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </ClubContainer>
  );
};

export default Club;
