import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import "../NewMemberRegistrationWizard.scss";
import {useFormState} from "react-use-form-state";
import {FormControl, MenuItem, Select, TextField} from "@material-ui/core";
import Button, {ButtonStyles} from "../../../components/common/Button";
import Grid from '@material-ui/core/Grid';
import {Locale} from "@js-joda/locale_en-us";
import {convert, DateTimeParseException, LocalDate} from "@js-joda/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {LocalDateUtils} from "../../../util/FixedJsJodaUtils";
import InputMask from "react-input-mask";
import {getPhoneUnmask, PHONE_REGEX} from "../../../util/Util";
import Loading from "../../../components/common/Loading";
import LoadingMessage from "../../../components/common/LoadingMessage";
import {
    getAllPayersByPayerListSlug, getChildPayersForParentList,
    GetPayerResponseBody
} from "../../../services/MemberService";
import {NewMemberPayerInformationParameters} from "../NewMemberRegistrationWizard";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {payerConfig} from "../../../configs/payers";
import FieldStatusIcon from "../../../components/ignite/registration/FieldStatusIcon";

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void,
    registrationPath: string,
    renewId: string | null,
    personalInformation: NewMemberPayerInformationParameters | undefined
}

interface Option {
    name: string;
    value: number;
    slug: string;
}

export function NewMemberPersonalInformationStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const initialState = {
        payerId: undefined,
        insuranceId: "",
        healthPlanName: null,
        dateOfBirth: null,
        phone: "",
        zipCode: ""
    }

    const [formState, {text, select, tel}] = useFormState(initialState);
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(null);
    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [dateOfBirthTouched, setDateOfBirthTouched] = useState(false);
    const {payerId, healthPlanName} = formState.values;
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState<boolean>(false);

    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [displayedPayers, setDisplayedPayers] = useState<GetPayerResponseBody[]>([]);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const selectedPayer: GetPayerResponseBody | null =
        displayedPayers.find(p => p.id === payerId) || null;
    const [otherPayerId, setOtherPayerId] = useState<number>(0);
    const [logoSrc, setLogoSrc] = useState<string>('');
    const [cardSrc, setCardSrc] = useState<string>('');

    // Autocomplete health plan
    const [options, setOptions] = useState(new Array<Option>());
    const [autoCompleteValue, setAutoCompleteValue] = useState<Option | null>(null);
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const elementConfig: Record<string, any> = {
        payerId: {
            name: "payerId",
            validate: (value: string | number): string | undefined => {
                if (typeof value === "number" && displayedPayers.find(p => p.id === value)) {
                    return;
                }
                return " ";
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (props.registrationPath === 'optum' && props.renewId) {
                    const validRenewIdRegex = /^[SAsa][0-9]{9}$/;
                    if (value.trim() && !value.match(validRenewIdRegex)) {
                        return t('newMemberRegistration.invalidConfCode');
                    }
                    if (value === "") {
                        return t('newMemberRegistration.renewIdError');
                    }
                }
                else {
                    if (value === "") {
                        return t('newMemberRegistration.memberIdError');
                    }
                }
            },
            validateOnBlur: true
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string | null) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value?.trim()) {
                    return t('newMemberRegistration.zipCodeError');
                }
                if (!value.match(zipCodeRegex)) {
                    return t('newMemberRegistration.zipCodeError');
                }
            },
            validateOnBlur: true
        },
        phone: {
            name: 'phone',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return t('newMemberRegistration.phoneError');
                }
            },
            validateOnBlur: true
        }
    }

    const continueEnabled = () => {
        // Validity is not evaluated on form load for pre-populated values, so
        // this is good enough for that initial state.
        const allFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        });

        return allFieldsValid &&
            validateHealthPlanName() &&
            !Boolean(dateOfBirthError) &&
            Object.entries(formState.validity).every(([key, value]) => value);
    }

    const validateHealthPlanName = () => {
        if (selectedPayer !== null && selectedPayer.unlistedPayerPlaceholder) {
            return autoCompleteValue?.value !== undefined && autoCompleteValue.value > 0;
        }
        return true;
    }

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const validateDateOfBirth = (date: LocalDate) => {
        if (date instanceof LocalDate) {
            const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
            const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

            if (!isEqualOrAfterMinDate || !isEqualOrBeforeMaxDate) {
                setDateOfBirthError(t('newMemberRegistration.dobError'));
            }
            else {
                setDateOfBirthError('');
            }
            return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
        } else {
            setDateOfBirthError(t('newMemberRegistration.dobError'));
            return false;
        }
    }

    const onContinueSelected = () => {
        setSaving(true);
        props.onContinue({
            payerId: selectedPayer?.id,
            otherPayerId: otherPayerId,
            insuranceId: formState.values.insuranceId,
            zipCode: formState.values.zipCode,
            phone: getPhoneUnmask(formState.values.phone),
            dateOfBirth: formState.values.dateOfBirth
        });
    }

    useEffect(() => {

        const loadAllPayers = async () => {
            try {
                // Displayed payers
                const displayedPayersResponseBody = await getAllPayersByPayerListSlug(props.registrationPath);
                setDisplayedPayers(displayedPayersResponseBody.allPayers.sort((a,b) => {
                    if (!a.unlistedPayerPlaceholder && !b.unlistedPayerPlaceholder)
                        return a.payerName.localeCompare(b.payerName);
                    else if (a.unlistedPayerPlaceholder)
                        return 1;
                    else
                        return -1;
                }));

                // All payers for autocomplete - varying by reg flow path
                const otherId = displayedPayersResponseBody.allPayers.find(p => p.unlistedPayerPlaceholder)?.id;
                if (otherId !== undefined) {
                    const allPayersResponseBody = await getChildPayersForParentList(otherId);
                    setAllPayers(allPayersResponseBody.allPayers.sort((a,b) => a.payerName.localeCompare(b.payerName)));

                    // Prepopulate autocomplete field if applicable
                    if (props.personalInformation) {
                        const otherPayer = allPayersResponseBody.allPayers.find(p => p.id === props.personalInformation?.otherPayerId);
                        if (otherPayer !== undefined) {
                            const selectedOption : Option = {
                                name: otherPayer.payerName,
                                value: otherPayer.id,
                                slug: otherPayer.payerSlug
                            };

                            setAutoCompleteValue(selectedOption);
                        }
                    }
                }

                // If we go back to personal information, we can prepopulate all of the data that was just entered
                if (props.personalInformation) {
                    formState.setField('payerId', props.personalInformation.payerId);
                    formState.setField('insuranceId', props.personalInformation.insuranceId);
                    formState.setField('phone', props.personalInformation.phoneNumber);
                    formState.setField('zipCode', props.personalInformation.zipCode);
                    setOtherPayerId(props.personalInformation.otherPayerId);
                    setDateOfBirth(props.personalInformation.dateOfBirth);
                }
                // On the optum flow, we have the renewId already entered
                else if (props.renewId) {
                    formState.setField('insuranceId', props.renewId);
                }

                setLoading(false);
                setLoadingErrorMessage(null);
            } catch (e) {
                setLoading(false);
                setLoadingErrorMessage(t('memberSelectPayerStep.unexpectedError'));
            }
        };

        loadAllPayers();

    }, [t]);

    // Compute the options for the autocomplete dropdown.
    useEffect(() => {
        const results: GetPayerResponseBody[] = allPayers.filter(p => p.payerName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

        const newOptions: Option[] =
            results.map(payer => {
                return {
                    name: payer.payerName,
                    value: payer.id,
                    slug: payer.payerSlug
                };
            });

        setOptions(newOptions);

    }, [inputValue, allPayers]);

    useEffect(() => {
        // The widget validation was inconsistent during development. Perform explicit validation on the parsed LocalDate before passing off the value to the form.
        if (dateOfBirth instanceof LocalDate && validateDateOfBirth(dateOfBirth)) {
            formState.setField('dateOfBirth', dateOfBirth);
        } else {
            // Otherwise invalidate the date on the form
            formState.setField('dateOfBirth', null);
        }
    }, [dateOfBirth]);

    useEffect(() => {
        let payerSlug;
        if (selectedPayer?.unlistedPayerPlaceholder && autoCompleteValue?.slug) {
            payerSlug = autoCompleteValue?.slug;
            setOtherPayerId(Number(autoCompleteValue.value));
        }
        else if (selectedPayer?.payerSlug) {
            payerSlug = selectedPayer.payerSlug;
            setOtherPayerId(0);
        }

        // Gather the correct images for selected payer
        let p: keyof typeof payerConfig;
        for (p in payerConfig) {
            if (p === payerSlug) {
                setLogoSrc(payerConfig[p].logoSrc);
                setCardSrc(payerConfig[p].cardSrc);
                return;
            }
        }
        setLogoSrc('');
        setCardSrc('');
    }, [selectedPayer, autoCompleteValue]);

    
    return (
        <div className="NewMemberRegistrationWizard_Wrapper">
            <h2>{t('newMemberRegistration.personalInformationHeader')}</h2>
            <div className={"NewMemberRegistrationWizard_Wrapper_InnerContainer"}>
                {loading && loadingErrorMessage === null && (
                    <Loading loading={loading}/>
                )}
                {!loading && loadingErrorMessage !== null && (
                    <LoadingMessage message={loadingErrorMessage}/>
                )}
                {!loading &&
                    <>
                    <Grid container spacing={1} direction="row" alignItems="center">
                        <Grid item xs={12}>
                            <div className={"NewMemberRegistrationWizard_PersonalInformationInnerHeader"}>
                                {t('newMemberRegistration.personalInformationFormHeaderText')}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} direction="row" alignItems="flex-start"
                          className="NewMemberRegistrationWizard_FieldsContainer">
                        <Grid item xs={12} md={7}>
                            <FormControl fullWidth>
                                <Select
                                    style={{width: '100%'}} {...select(elementConfig.payerId)}
                                    variant="outlined"
                                    margin={"dense"}
                                    disabled={loading}
                                    displayEmpty
                                    required>
                                    <MenuItem value="" disabled>
                                        {t('newMemberRegistration.selectPayer')}
                                    </MenuItem>
                                    {displayedPayers.map(payer => (
                                        <MenuItem key={payer.id} value={payer.id}>
                                            {props.registrationPath === 'optum' ? payer.payerName.replace('United Healthcare ', '') : payer.payerName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={5}></Grid>
                        {selectedPayer !== null && selectedPayer.unlistedPayerPlaceholder &&
                            <>
                                <Grid item xs={12} md={8}>
                                    <div className={"NewMemberRegistrationWizard_PersonalInformationInnerHeader"}>
                                        {t('newMemberRegistration.manualEnter')}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}></Grid>
                                <Grid item xs={12} md={7}>
                                    <div className={"NewMemberPersonalInformationStep_HealthPlanNameField_Autocomplete"}>
                                        <FormControl fullWidth
                                            className="NewMemberPersonalInformationStep_HealthPlanNameField">
                                            <Autocomplete
                                                open={open}
                                                style={{width: "100%"}}
                                                onOpen={() => {
                                                    setOpen(true);
                                                }}
                                                onClose={() => {
                                                    setOpen(false);
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                onChange={(event, value) => {
                                                    setAutoCompleteValue(value);
                                                }}
                                                value={autoCompleteValue}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                getOptionLabel={(option) => option.name}
                                                options={options}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        margin={"dense"}
                                                        required
                                                        error={!validateHealthPlanName()}
                                                        label={t('newMemberRegistration.selectPayer')}
                                                        style={{marginBottom: '10px', width: '100%'}}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={5}></Grid>
                            </>
                        }
                        {selectedPayer !== null && (!selectedPayer.unlistedPayerPlaceholder || healthPlanName !== '') &&
                            <>
                                <Grid item xs={11} md={7}>
                                    <FormControl fullWidth>
                                        <TextField style={{width: '100%'}} {...text(elementConfig.insuranceId)}
                                                   error={formState.errors.insuranceId !== undefined}
                                                   helperText={formState.errors.insuranceId}
                                                   className="NewMemberPersonalInformationStep_InsuranceIdField"
                                                   margin={"dense"}
                                                   required
                                                   inputProps={{maxLength: 50}}
                                                   label={props.registrationPath === 'optum' && props.renewId ? t('newMemberRegistration.enterRenewId') : t('newMemberRegistration.enterPayerId')}
                                                   variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon shouldShow={formState.touched.insuranceId}
                                                         isError={formState.errors.insuranceId}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={4}></Grid>
                                {(props.registrationPath !== 'optum' || !props.renewId) &&
                                    <>
                                    <Grid item xs={12} md={8}>
                                        <span className={"PersonalInformation_MemberIDNote"}>
                                            {Boolean(cardSrc) && <>{t('newMemberRegistration.exampleCardNote')} </>}
                                            {!Boolean(cardSrc) && <>{t('newMemberRegistration.memberIdNote')} </>}
                                        </span>
                                    </Grid>
                                    <Grid item xs={12} md={4}></Grid>
                                </>}
                            </>
                        }
                        <div className="NewMemberPersonalInformationStep_CardWrapper">
                            {Boolean(cardSrc) && <img src={cardSrc} alt={`${selectedPayer?.payerName}`} className="card-img" />}
                        </div>
                        {(selectedPayer === null || !selectedPayer.unlistedPayerPlaceholder) &&
                            <>
                                <Grid item xs={undefined} md={12}>&nbsp;</Grid>
                                <Grid item xs={undefined} md={12}>&nbsp;</Grid>
                            </>
                        }
                        {selectedPayer !== null && (!selectedPayer.unlistedPayerPlaceholder || healthPlanName !== '') &&
                            <>
                                <Grid item xs={11} md={3} className={"PersonalInformation_DatePicker"}>
                                    <FormControl fullWidth>
                                        <MuiPickersUtilsProvider utils={LocalDateUtils}
                                                                 locale={Locale.US}>
                                            <KeyboardDatePicker
                                                className="MemberRegistrationWizard_Input"
                                                value={dateOfBirth}
                                                label={t('memberPersonalInformationStep.dob')}
                                                required
                                                error={Boolean(dateOfBirthError)}
                                                helperText={dateOfBirthError}
                                                inputVariant="outlined"
                                                margin={"dense"}
                                                format="MM/dd/yyyy"
                                                views={["year", "month", "date"]}
                                                openTo="year"
                                                disableFuture
                                                minDate="1900-01-02" // Treated as 1/1/1900
                                                minDateMessage={t('memberPersonalInformationStep.dobMin')}
                                                maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                                                maxDateMessage={t('memberPersonalInformationStep.dobMax')}
                                                initialFocusedDate="1900-01-02"
                                                onChange={date => {
                                                    setDateOfBirthTouched(true);
                                                    setDateOfBirth(date);
                                                    validateDateOfBirth(date);
                                                }}
                                                onBlur={(event: any) => {
                                                    setDateOfBirthTouched(true);
                                                    if (!event.target.value) {
                                                        dateOfBirth instanceof LocalDate ?
                                                            validateDateOfBirth(event.target.value) : setDateOfBirthError(t('newMemberRegistration.dobError'));
                                                    }
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon
                                            shouldShow={dateOfBirthTouched || Boolean(dateOfBirthError)}
                                            isError={Boolean(dateOfBirthError)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={11} md={3}>
                                    <FormControl fullWidth>
                                        <InputMask
                                            mask='(999) 999-9999'
                                            maskChar='*'
                                            {...tel(elementConfig.phone)}
                                        >
                                            {(inputProps: any) =>
                                                <TextField {...inputProps}
                                                           className="MemberRegistrationWizard_Input"
                                                           label={t('newMemberRegistration.phoneLabel')}
                                                           margin={"dense"}
                                                           required
                                                           variant={"outlined"}
                                                           error={formState.errors.phone !== undefined}
                                                           helperText={formState.errors.phone}
                                                           size="small"
                                                           FormHelperTextProps={{error: formState.errors.phone !== undefined}}
                                                />
                                            }
                                        </InputMask>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon shouldShow={formState.touched.phone} isError={formState.errors.phone} />
                                    </div>
                                </Grid>
                                <Grid item xs={11} md={3}>
                                    <FormControl fullWidth>
                                        <TextField {...text(elementConfig.zipCode)}
                                                   className="MemberRegistrationWizard_Input"
                                                   margin={"dense"}
                                                   label={t('newMemberRegistration.zipCodeLabel')}
                                                   required
                                                   error={formState.errors.zipCode !== undefined}
                                                   helperText={formState.errors.zipCode}
                                                   variant={"outlined"}
                                                   size="small"/>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon shouldShow={formState.touched.zipCode} isError={formState.errors.zipCode} />
                                    </div>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <div className={"NewMemberPersonalInformationStep_NextContainer"}>
                        {!saving && <Button type="button"
                                id="PersonalInformationSubmit"
                                clickHandler={onContinueSelected}
                                text={t('newMemberRegistration.nextButton')}
                                className="NewMemberRegistrationWizard_SubmitButton"
                                buttonStyle={ButtonStyles.UnfilledWithBorder}
                                disabled={!continueEnabled()}
                        />}
                        {saving && <Loading className={"NewMemberRegistrationWizard_SubmitButtonLoading"} loading={saving}/>}
                        <Button type="button"
                                clickHandler={props.onPrevious}
                                text={t('newMemberRegistration.backButton')}
                                className="NewMemberRegistrationWizard_SubmitButton"
                                buttonStyle={ButtonStyles.UnfilledWithBorder}
                                disabled={true}
                        />
                    </div>
                </>}
            </div>
        </div>
    );
}