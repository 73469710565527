import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';

import "./NavigationDesktop.scss";
import { UserContext } from "../../contexts/UserContext";
import NavigationElement from "./NavigationElement";
import Separator from "./Separator";

interface LoggedOutNavigationDesktopProps {
    useClubDirectoryNav: boolean;
}

function LoggedOutNavigationDesktop(props: LoggedOutNavigationDesktopProps): JSX.Element {
    const { t } = useTranslation('layout');
    const { user } = useContext(UserContext);
                            
    return (
        <div className={'NavigationDesktop'}>
            {user === null && <>
                <div className="LoggedOut NavigationDesktop_Container">
                        <>
                           {/* register */}
                           <NavigationElement clickHandler={() =>
                                    window.location.href = "https://hellogrouper.com/join-a-group/?utm_source=product-link&utm_campaign=get-started-v2"
                                }>
                                {t('loggedOutNavigation.getStarted')}
                            </NavigationElement>
                            <Separator />
                            
                            {/* Login */}
                            <NavigationElement to={"/login"}>
                                {t('loggedOutNavigation.login')}
                            </NavigationElement>

                        </>

                </div>
            </>}
        </div>
    );
}

export default LoggedOutNavigationDesktop;
