import React from "react";
import MedalIcon from "../icons/MedalIcon";
import { BADGE_KEYS } from "../../../constants/dashboard";
import CupIcon from "../icons/CupIcon";

const BADGE_MAP: Record<string, JSX.Element> = {
    [BADGE_KEYS.FIRST_ACTIVITY]: <MedalIcon className="icon" />,
    [BADGE_KEYS.TRACKING_COMPLETED]: <CupIcon className="icon" />,

}

const Badge = ({ slug }: { slug: BADGE_KEYS }) => BADGE_MAP[slug]

export default Badge
