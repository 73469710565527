import React, { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IconButton } from "@material-ui/core";
import SendToListIcon from "../../../components/ignite/icons/SendToListIcon";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { IClub } from "../../../types/clubFinder";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { UserContext } from "../../../contexts/UserContext";
import { copyToClipboard } from "../../../util/ignite/Dashboard.utils";

interface ICopyEmailContent {
    onBackClick: (e: React.MouseEvent) => void
    club: IClub
}

const CopyEmailContent = ({ onBackClick, club }: ICopyEmailContent) => {
    const { t } = useTranslation('ignite/dashboard');
    const [isCopied, setIsCopied] = useState(false)
    const { user } = useContext(UserContext);

    const { clubName } = club
    const leaderName = `${user?.firstName} ${user?.lastName}`

    const signupIgniteRoute = getIgniteRoute(IGNITE_ROUTE_KEY.MEMBER_ONBOARDING, { ':clubShortCode': String(club.shortCode) })

    const invitationMessage = t('copyEmailContent.invitationMessageText', { leaderName, clubName, link: window.location.origin + signupIgniteRoute })

    const copyEmail = () => {
        copyToClipboard(invitationMessage)
        setIsCopied(true)
    }

    return (
        <div className="copy-email-content">
            <IconButton
                className="icon-button"
                onClick={onBackClick}
            >
                <ArrowBackIcon className="icon" />
                {t('backButtonText')}
            </IconButton>

            <div className="action-text">
                {t('copyEmailContent.title')}
            </div>

            <div className="content-inner">
                <div className="subtitle">
                    {t('copyEmailContent.subtitle')}
                </div>
                <div className="main-part">
                    <div className="text" dangerouslySetInnerHTML={{__html: invitationMessage }} />
                    <div className="logo-wrapper" onClick={copyEmail}>
                        <SendToListIcon className="logo" />
                        {isCopied ? t('copyEmailContent.messageCopied') : t('copyEmailContent.clickToCopyInvitationMessage')}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CopyEmailContent;
