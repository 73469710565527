import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import MailEnvelope from "../icon/MailEnvelope";
import CrossBold from "../icon/CrossBold";

import "./DirectoryWorkInProgress.scss";

interface DirectoryWorkInProgressProps {
    className?: string;
}

const DIRECTORY_WORK_IN_PROGRESS_DATE = 'DIRECTORY_WORK_IN_PROGRESS_DATE'
const SECONDS_IN_DAY = 86400;

export default function DirectoryWorkInProgress(props: DirectoryWorkInProgressProps) {
    const { t } = useTranslation('layout');
    const [isVisible, setIsVisible] = useState<boolean>(false)
    useEffect(() => {
        const directoryWorkInProgressHiddenDateJson = sessionStorage.getItem(DIRECTORY_WORK_IN_PROGRESS_DATE);
        const now = new Date();
        if (directoryWorkInProgressHiddenDateJson) {
            const lastHidden =  new Date(JSON.parse(directoryWorkInProgressHiddenDateJson))
            // compare date to today -if 24 hrs passed, show again
            const seconds = Math.abs(lastHidden.getTime() - now.getTime()) / 1000;
            const shouldShow = seconds > SECONDS_IN_DAY
            setIsVisible(shouldShow);
        } else {
            setIsVisible(true);
        }
    }, [])


    function onCrossClick() {
        setIsVisible(false);
        sessionStorage.setItem(DIRECTORY_WORK_IN_PROGRESS_DATE, JSON.stringify(new Date()));

    }

    if (!isVisible) {
        return null;
    }

    return (
        <div className={`DirectoryWorkInProgress ${props?.className ?? ''}`}>
            <div className="DirectoryWorkInProgress_Content">
                <span className="CrossBoldIcon" onClick={onCrossClick}>
                    <CrossBold />
                </span>
                <div>
                    <p className="DirectoryWorkInProgress_Message_Body">
                        {t('directoryWorkInProgress.message')}
                    </p>
                </div>
            </div>
        </div>
    );
}


