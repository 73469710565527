import React from "react";

import "./Separator.scss";

export interface SeparatorProps {
    className?: string,
}

export default function Separator(props: SeparatorProps): JSX.Element {

    let classes = 'Separator';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (
        <div className={classes}/>
    );
}