import React, { useContext, useMemo, useState } from 'react'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import cn from "classnames";
import { useTranslation } from 'react-i18next';
import {CLUB_ROLES_STRINGS} from "../../../constants/dashboard";
import {getClubPageRouteLink, getIgniteRoute} from "../../../util/ignite/routes.utils";
import {IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS} from "../../../constants/routes";
import {useHistory, useLocation} from "react-router-dom";
import { UserContext } from '../../../contexts/UserContext';
import { IClub } from '../../../types/clubFinder';

const ONE_CLUB = 1

interface ClubInfoPanelProps {
    clubName?: string;
    clubImage?: string;
    myClubs: IClub[];
    location: any;
}

export const ClubInfoPanel = ({
    clubName,
    clubImage,
    location,
    myClubs,
}: ClubInfoPanelProps) => {
    const { t } = useTranslation('ignite/dashboard')
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const history = useHistory()

    const { viewState } = useContext(UserContext);

    const isCurrentClubIgniteEnabled = useMemo(() => {
        const currentClub = myClubs.find(club => club.id === viewState?.currentClubId);
        const isClubIgniteEnabled = !!currentClub?.isIgniteEnabled;

        return isClubIgniteEnabled;
    }, [myClubs, viewState]);

    const onToggleDropdownMenu = () => shouldRenderDropdown && setMenuIsOpen(prevValue => !prevValue)
    const shouldRenderDropdown = myClubs !== undefined && myClubs.length > ONE_CLUB

    const { city, countrySubdivision } = location || {}
    const locationRow = [city, countrySubdivision].filter(Boolean).join(', ')

    const url = useLocation();

    function onClick(dashboardRoute:string) {
        setMenuIsOpen(false);
        history.push(dashboardRoute);
    }

    return (
        <div className="club-info">
            <div className="left-content">
                <div className="name">
                    {clubName}
                </div>
                {locationRow && <div className="description-text">{locationRow}</div>}
            </div>
            <div className="right-content" onClick={onToggleDropdownMenu}>
                <img src={"/images/passions/" + clubImage} alt="" className='img' />
                {shouldRenderDropdown ? menuIsOpen ? <ArrowUpIcon className="icon" /> :
                    <ArrowDownIcon className="icon" /> : null}
            </div>
            {<div className={cn('dropdown', { 'is-open': menuIsOpen })}>
                {myClubs?.map((club) => {
                    const isLeader = club.role !== CLUB_ROLES_STRINGS.MEMBER
                    let route = '';
                    if (isLeader) {
                        if (isCurrentClubIgniteEnabled && club.isIgniteEnabled) {
                            if (url.pathname.toLowerCase().startsWith(IGNITE_ROUTE_PATTERNS.LEADER_MANAGE_MEMBERS.replace(':clubId', ''))) {
                                route = getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_MANAGE_MEMBERS, { ':clubId': String(club.id) });
                            } else {
                                route = getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': String(club.id) });
                            }
                        } else {
                            route = `/my-club/${club.urlFragment}`;
                        }
                    } else {
                        let urlFragment = ''
                        if (club.urlFragment){
                            urlFragment = club.urlFragment;
                        }
                        if (club.isVirtual === true){
                            route = getClubPageRouteLink(true,urlFragment);
                        } else {
                            if (isCurrentClubIgniteEnabled && club.isIgniteEnabled) {
                                route = getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': String(club.id) });
                            } else {
                                route = getClubPageRouteLink(false,urlFragment);
                            }
                        }
                       
                    }

                    return (
                        <div
                            key={club.id}
                            className={`club-item${club.id === viewState?.currentClubId ?  ' selected' : ''}`}
                            onClick={() => onClick(route)}
                        >
                            <div className="club-item__name">
                                {club.clubName}
                            </div>
                            <div className={`club-item__role ${club.role}`}>
                                {t(`${club.role}`)}
                            </div>
                        </div>)
                })}
            </div>}
        </div>
    )
}
