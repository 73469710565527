import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { loadConfig } from '../../services/ConfigService';
import { UserContext } from '../../contexts/UserContext';
import { authenticatedFetch } from '../../services/UserService';
import {
  GetEventAndClubResponseBody,
  GetUserEventRelationshipStatusResponseBody,
  UserEventRelationshipStatus,
} from '../../services/MemberService';
import Loading from '../common/Loading';
import LoadingMessage from '../common/LoadingMessage';
import EventView from './EventView';

import './EventViewWithDetails.scss';
import PlayButton from '../common/PlayButton';
import { ButtonStyles } from '../common/Button';
import RegistrationModal from '../registrationModal/RegistrationModal';
import { RegistrationFormType } from '../registrationModal/RegistrationForm';
import OkModal from '../common/OkModal';
import { Link, useHistory } from 'react-router-dom';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  isClubInAvaOrganization,
  isEventSelfGuided,
  storeRegistrationParametersToSession,
} from '../../util/Util';
import SelfGuidedEventText from './SelfGuidedEventText';
import LiabilityWaiverModal from '../liabilityWaiverModal/LiabilityWaiverModal';
import VirtualEventMedium from '../../util/VirtualEventMedium';
import postCancelRsvpEvent from '../../api/postCancelRsvpEvent';
import postRsvpEvent from '../../api/postRsvpEvent';
import { getClubPageRouteLink } from '../../util/ignite/routes.utils';
import {
  getEventAndClub,
  getRsvpStatusOnEvent,
} from '../../services/EventSearchService';

interface EventViewWithDetailsProps {
  eventUrlFragment: string;
  className?: string;
  source?: string;
  postalCode?: string;
}

enum LiabilityWaiverState {
  IS_CLOSED,
  IS_OPEN_DID_NOT_JUST_REGISTER,
  IS_OPEN_AFTER_REGISTRATION,
}

export default function EventViewWithDetails(
  props: EventViewWithDetailsProps,
): JSX.Element {
  const { t } = useTranslation('event');

  const { user } = useContext(UserContext);

  const { source, postalCode } = props;

  let eventUrlFragment = props.eventUrlFragment;

  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
  const [eventAndClub, setEventAndClub] =
    useState<GetEventAndClubResponseBody | null>(null);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showPayerDetailsDetailsModal, setShowPayerDetailsDetailsModal] =
    useState(false);
  const [showRsvpErrorModal, setShowRsvpErrorModal] = useState(false);
  const [showZoomRsvpModal, setShowZoomRsvpModal] = useState(false);
  const [showCancelErrorModal, setShowCancelErrorModal] = useState(false);
  const [rsvpSuccess, setRsvpSuccess] = useState(true);
  const [isGoing, setIsGoing] = useState(false);
  const [isJustCancelled, setIsJustCancelled] = useState(false);
  const [liabilityWaiverState, setLiabilityWaiverState] = useState(
    LiabilityWaiverState.IS_CLOSED,
  );

  const history = useHistory();

  useEffect(() => {
    storeRegistrationParametersToSession(history.location);
  }, []);

  function isLoggedIn() {
    return user !== null;
  }

  let isSelfGuided = false;
  if (eventAndClub !== null) {
    isSelfGuided = isEventSelfGuided(eventAndClub.event);
  }

  const isAvaClub =
    eventAndClub !== null && isClubInAvaOrganization(eventAndClub.club);

  function rsvp() {
    if (eventAndClub === null) {
      return;
    }
    if (user === null) {
      setShowRegistrationModal(true);
    } else if (user.renewId === null) {
      setShowPayerDetailsDetailsModal(true);
    } else {
      justRsvp();
    }
  }

  function rsvpAfterRegistration() {
    if (eventAndClub === null) {
      return;
    }

    let url = history.location.pathname;
    const params = new URLSearchParams(history.location.search);
    params.delete('memberCode');
    const paramsString = params.toString();
    if (paramsString) {
      url += `?${paramsString}`;
    }
    history.replace(url);

    postRsvpEvent(eventAndClub.event.urlFragment)
      .then((response) => {
        if (response === null || !response?.ok) {
          setRsvpSuccess(false);
          setShowRegistrationModal(false);
          setShowPayerDetailsDetailsModal(false);
          setShowRsvpErrorModal(true);
          return;
        } else {
          setRsvpSuccess(true);
          setShowRegistrationModal(false);
          setShowPayerDetailsDetailsModal(false);
          // Setting eventAndClub to null triggers a useEffect block and reloads the eventAndClub with updated data
          setEventAndClub(null);
          if (
            !!eventAndClub.event.virtualEventDetails?.url &&
            (eventAndClub.event.virtualEventDetails?.medium ===
              VirtualEventMedium.ZoomExternalHosted ||
              eventAndClub.event.virtualEventDetails?.medium ===
                VirtualEventMedium.ZoomInternalHosted)
          ) {
            setShowZoomRsvpModal(true);
          }
        }
        window.scrollTo(0, 0);
      })
      .catch(() => {
        setRsvpSuccess(false);
        setShowRegistrationModal(false);
        setShowPayerDetailsDetailsModal(false);
        setShowRsvpErrorModal(true);
      });
  }

  function justRsvp(skipLiabilityWaiver = !(isSelfGuided && isAvaClub)) {
    if (eventAndClub === null) {
      return;
    }

    if (!skipLiabilityWaiver) {
      setLiabilityWaiverState(
        LiabilityWaiverState.IS_OPEN_DID_NOT_JUST_REGISTER,
      );
      return;
    }

    postRsvpEvent(eventAndClub.event.urlFragment)
      .then((response) => {
        if (response === null || !response?.ok) {
          setRsvpSuccess(false);
          setShowRsvpErrorModal(true);
          return;
        }
        setRsvpSuccess(true);
        setShowRegistrationModal(false);
        setShowPayerDetailsDetailsModal(false);
        setIsGoing(true);
        if (
          !!eventAndClub.event.virtualEventDetails?.url &&
          (eventAndClub.event.virtualEventDetails?.medium ===
            VirtualEventMedium.ZoomExternalHosted ||
            eventAndClub.event.virtualEventDetails?.medium ===
              VirtualEventMedium.ZoomInternalHosted)
        ) {
          setShowZoomRsvpModal(true);
        }
        window.scrollTo(0, 0);
      })
      .catch(() => {
        setRsvpSuccess(false);
        setShowRegistrationModal(false);
        setShowPayerDetailsDetailsModal(false);
        setShowRsvpErrorModal(true);
      });
  }

  function onClickContinueZoomModal() {
    window.open(eventAndClub?.event.virtualEventDetails?.url, '_blank');
    setShowZoomRsvpModal(false);
  }

  const isLiabilityWaiverOpen =
    liabilityWaiverState !== LiabilityWaiverState.IS_CLOSED;

  function onSubmitLiabilityWaiverForm() {
    switch (liabilityWaiverState) {
      case LiabilityWaiverState.IS_OPEN_DID_NOT_JUST_REGISTER:
        justRsvp(/* skipLiabilityWaiver= */ true);
        break;
      case LiabilityWaiverState.IS_OPEN_AFTER_REGISTRATION:
        rsvpAfterRegistration();
        break;
    }

    setLiabilityWaiverState(LiabilityWaiverState.IS_CLOSED);
  }

  function onCancelLiabilityWaiverForm() {
    setLiabilityWaiverState(LiabilityWaiverState.IS_CLOSED);
  }

  async function cancel() {
    const config = await loadConfig();

    if (user === null) {
      return;
    }
    const response = await postCancelRsvpEvent(eventUrlFragment);

    if (!response) {
      setShowCancelErrorModal(true);
      return;
    }

    setIsJustCancelled(true);
    setShowCancelErrorModal(false);
    window.scrollTo(0, 0);
  }

  const isCorrectEventAndClub = useCallback(() => {
    return (
      eventAndClub !== null &&
      eventAndClub.event.urlFragment === eventUrlFragment
    );
  }, [eventAndClub, eventUrlFragment]);
  useEffect(() => {
    if (!isCorrectEventAndClub()) return;
    getRsvpStatusOnEvent(eventUrlFragment).then((isGoing) => {
      setIsGoing(isGoing);
      setLoading(false);
    });
  }, [isCorrectEventAndClub]);

  useEffect(() => {
    if (isCorrectEventAndClub()) return;
    setLoading(true);
    setIsGoing(false);
    getEventAndClub(eventUrlFragment).then(({ error, eventAndClub }) => {
      if (error) {
        setLoadingMessage(t(error));
        setLoading(false);
        return;
      }
      setEventAndClub(eventAndClub);
    });
  }, [eventUrlFragment, user, eventAndClub, t]);

  return (
    <>
      {loading && <Loading loading={loading} />}
      {!loading && loadingMessage !== null && (
        <LoadingMessage showLoginLink={false} message={loadingMessage} />
      )}
      {!loading &&
        loadingMessage === null &&
        isJustCancelled &&
        eventAndClub !== null && <RsvpCancelled eventAndClub={eventAndClub} />}
      {!loading &&
        loadingMessage === null &&
        !isJustCancelled &&
        eventAndClub !== null && (
          <>
            <div className={`EventViewWithDetails ${props.className || ''}`}>
              <EventView
                eventAndClub={eventAndClub}
                rsvp={rsvp}
                source={source}
                postalCode={postalCode}
                isGoing={isGoing}
                cancelReservation={cancel}
              />
              {isSelfGuided && isAvaClub && (
                <>
                  <div className="SelfGuidedMessage">
                    <SelfGuidedEventText
                      eventClubUrl={eventAndClub.event.externalUrl}
                    />
                  </div>
                </>
              )}
              {!isGoing && (
                <>
                  <RegistrationModal
                    type={
                      showPayerDetailsDetailsModal
                        ? RegistrationFormType.PAYER_DETAILS
                        : RegistrationFormType.MEMBER
                    }
                    eventType={eventAndClub.event.typeOne}
                    open={showRegistrationModal || showPayerDetailsDetailsModal}
                    onClose={() => {
                      setShowRegistrationModal(false);
                      setShowPayerDetailsDetailsModal(false);
                    }}
                    onSuccessfulSubmit={rsvpAfterRegistration}
                  />
                  {!rsvpSuccess && !isLoggedIn() && (
                    <OkModal
                      shown={showRsvpErrorModal}
                      okClickHandler={() => {
                        setShowRsvpErrorModal(false);
                      }}
                    >
                      {t('eventViewWithDetails.accountCreatedRsvpFailed')}
                    </OkModal>
                  )}
                  {!rsvpSuccess && isLoggedIn() && (
                    <OkModal
                      shown={showRsvpErrorModal}
                      okClickHandler={() => {
                        setShowRsvpErrorModal(false);
                      }}
                    >
                      {t('eventViewWithDetails.rsvpFailed')}
                    </OkModal>
                  )}
                </>
              )}
              {!rsvpSuccess && !isLoggedIn() && (
                <OkModal
                  shown={showRsvpErrorModal}
                  okClickHandler={() => {
                    setShowRsvpErrorModal(false);
                  }}
                >
                  {t('eventViewWithDetails.accountCreatedRsvpFailed')}
                </OkModal>
              )}
              {!rsvpSuccess && isLoggedIn() && (
                <OkModal
                  shown={showRsvpErrorModal}
                  okClickHandler={() => {
                    setShowRsvpErrorModal(false);
                  }}
                >
                  {t('eventViewWithDetails.rsvpFailed')}
                </OkModal>
              )}
              {isGoing && (
                <OkModal
                  shown={showCancelErrorModal}
                  okClickHandler={() => {
                    setShowCancelErrorModal(false);
                  }}
                >
                  {t('eventViewWithDetails.cancelFailed')}
                </OkModal>
              )}
              {showZoomRsvpModal && (
                <OkModal
                  shown={true}
                  okClickHandler={onClickContinueZoomModal}
                  okButtonText={t('eventViewWithDetails.continue')}
                >
                  <div>
                    <p>{t('eventViewWithDetails.continueZoomRegistration')}</p>
                  </div>
                </OkModal>
              )}
              <LiabilityWaiverModal
                isOpen={isLiabilityWaiverOpen}
                onSubmit={onSubmitLiabilityWaiverForm}
                onCancel={onCancelLiabilityWaiverForm}
              />
            </div>
          </>
        )}
    </>
  );
}

interface RsvpCancelledProps {
  eventAndClub: GetEventAndClubResponseBody;
}

function RsvpCancelled(props: RsvpCancelledProps): JSX.Element {
  const { t } = useTranslation('event');
  let eventAndClub = props.eventAndClub;
  const { user } = useContext(UserContext);

  return (
    <>
      <div className={'EventViewWithDetails_RsvpCancelled'}>
        <div className={'EventViewWithDetails_RsvpCancelled_Title'}>
          {t('eventViewWithDetails.rsvpCancelledTitle')}
        </div>
        <div className={'EventViewWithDetails_RsvpCancelled_Body'}>
          <div
            className={'EventViewWithDetails_RsvpCancelled_Messsage'}
            dangerouslySetInnerHTML={{
              __html: t('eventViewWithDetails.rsvpCancelledMessage'),
            }}
          />
          <div
            className={'EventViewWithDetails_RsvpCancelled_GoToMyEventsButton'}
          >
            <PlayButton
              buttonStyle={ButtonStyles.FilledPrimary}
              to={'/my-events'}
              text={t('eventViewWithDetails.myEventsButtonText')}
            />
          </div>
        </div>
        <div className={'EventViewWithDetails_RsvpCancelled_LinksContainer'}>
          <div className={'EventViewWithDetails_RsvpCancelled_MoreClubEvents'}>
            <Link
              to={getClubPageRouteLink(
                eventAndClub.club.isVirtual,
                eventAndClub.club.urlFragment,
              )}
            >
              <AddCircle />{' '}
              {t('eventViewWithDetails.moreClubEvents', {
                clubName: eventAndClub.club.clubName,
              })}
            </Link>
          </div>
          {eventAndClub.club.passion !== undefined && (
            <div
              className={'EventViewWithDetails_RsvpCancelled_MorePassionEvents'}
            >
              {user !== null &&
                user.postalCode !== undefined &&
                user.postalCode !== null && (
                  <>
                    <Link
                      to={`/find-an-event?postalCode=${user.postalCode}&passionIds=${eventAndClub.club.passion?.id}&passionsSelected`}
                    >
                      <AddCircle />{' '}
                      {t('eventViewWithDetails.morePassionEvents', {
                        passionName: eventAndClub.club.passion?.passionName,
                      })}
                    </Link>
                  </>
                )}
              {(user === null ||
                user.postalCode === undefined ||
                user.postalCode === null) && (
                <>
                  <Link to={`/find-a-club`}>
                    <AddCircle />{' '}
                    {t('eventViewWithDetails.morePassionEvents', {
                      passionName: eventAndClub.club.passion?.passionName,
                    })}
                  </Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
