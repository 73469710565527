import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';

import "./PrivacyPolicyPage.scss";

export default function PrivacyPolicyPage(): JSX.Element {
    const { t } = useTranslation('pages');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className={"PrivacyPolicyPage"} dangerouslySetInnerHTML={{__html: t('privacyPolicyPage.content')}}></div>
        </>
    )
}