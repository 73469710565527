import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./PasswordResetConfirm.scss";
import PasswordResetConfirmForm from "../../components/passwordResetForm/PasswordResetConfirmForm";
import {logout} from "../../services/UserService";
import Loading from "../../components/common/Loading";

export default function PasswordResetConfirmPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const [loaded, setLoaded] = useState(false);

    useEffect(
        () => {
            const doTheLogout = async () => {
                await logout();
            };
            doTheLogout().then(()=> {setLoaded(true)});
        },
        []);


    return (
        <>
            {!loaded && <Loading loading={true}/>}
            {loaded &&
            <div className="PasswordResetConfirmPage">
                <h1>{t('passwordResetConfirmPage.header')}</h1>
                <div className="PasswordResetConfirmPage_FormContainer">
                    <PasswordResetConfirmForm/>
                </div>
            </div>
            }
        </>
    );
}
