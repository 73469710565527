import React from "react";

const twoCircleFilled = require("../../assets/2-circle-filled.svg");

interface TwoCircleFilledProps {
    className?: string
}

export default function TwoCircleFilled(props: TwoCircleFilledProps) {
    return <img className={props.className} src={twoCircleFilled} alt="circle two icon"/>
}
