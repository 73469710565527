import { loadConfig } from '../services/ConfigService'
import { IClub } from '../types/clubFinder'

export const GET_CLUBS_URL = '/clubs/active'

const getClubs = async (): Promise<[IClub] | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_CLUBS_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await fetch(request);

    if (response.ok) {
        return response.json()
    }
}

export default getClubs
