import React, {useState} from "react";
import "./CollapsibleFAQ.scss";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface CollapsibleFAQProps {
    question: string,
    children: any
}

export default function CollapsibleFAQ(props: CollapsibleFAQProps): JSX.Element {
    let [open, setOpen]  = useState(false);

    function getChevron() {
        if (open) {
            return <KeyboardArrowUpIcon fontSize="large" className="Collapsible_FAQ_Toggle"/>
        } else {
            return <KeyboardArrowDownIcon fontSize="large" className="Collapsible_FAQ_Toggle"/>
        }
    }

    return (
        <div className={"Collapsible_FAQ_Wrapper"}>
            <div className={"Collapsible_FAQ_Question"} onClick={() => setOpen(!open)}>
                <div className={"Collapsible_FAQ_Question_Text"}>
                    {props.question}
                </div>
                <div className={"Collapsible_FAQ_Toggle"}>
                    {getChevron()}
                </div>
            </div>
            <div className={`Collapsible_FAQ_Answer ${open ? "open" : ""}`}>
                {props.children}
            </div>
        </div>
    );
}