import { useState } from "react";
import postCheckEligibility from "../api/postCheckEligibility";

const useCheckEligibility = (): [boolean, (params: any) => Promise<{ id: number } | undefined >] => {
    const [isLoading, setIsLoading] = useState(false)

    const checkEligibility = (parameters: any) => {
        setIsLoading(true)

        return postCheckEligibility(parameters).finally(() => setIsLoading(false))

    }

    return [isLoading, checkEligibility]
}

export default useCheckEligibility
