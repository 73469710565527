import {InputConfig, RegistrationInputProps, useRootForm, useSubFormInput} from "../../../util/Forms";
import {LocalDateUtils} from "../../../util/FixedJsJodaUtils";
import {Locale} from "@js-joda/locale_en-us";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {convert, DateTimeParseException, LocalDate} from "@js-joda/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export function DateOfBirthInput(props: RegistrationInputProps): JSX.Element {
    const { t } = useTranslation('pages');
    const { inputValues } = useRootForm();
    // This *can* just work with the form state directly, but it doesn't play well with Material UI,
    // and using useState instead doesn't have that problem.
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(null);
    const inputConfig: InputConfig<LocalDate | DateTimeParseException | null> = {
        name: "dateOfBirth",
        initialValue: null,
        validate: (dateOfBirth: LocalDate | DateTimeParseException | null | string): string | undefined => {
            // If the user is Renew Active/One Pass, they don't need to pass along a date of birth,
            // so ignore the field in that case.
            if (inputValues.planType === "renew") {
                return;
            }
            if (!dateOfBirth) {
                return " ";
            }
            // The widget validation was inconsistent during development. Perform explicit validation on the parsed
            // LocalDate before passing off the value to the form.
            if (!(dateOfBirth instanceof LocalDate) || !validateDateOfBirth(dateOfBirth)) {
                // Invalidate the date on the form
                return " ";
            }
        }
    }
    const [formState] = useSubFormInput(inputConfig);

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const validateDateOfBirth = (date: LocalDate) => {
        const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
        const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);
        return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
    }

    useEffect(() => {
        formState.setField("dateOfBirth", dateOfBirth);
    }, [dateOfBirth]);

    return (
        <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
            <KeyboardDatePicker
                className={`RegistrationWizard_Input DateOfBirthInput ${props.className || ''}`}
                value={dateOfBirth}
                label={t('registration.dob.label')}
                inputVariant="outlined"
                format="MM/dd/yyyy"
                views={["year", "month", "date"]}
                openTo="year"
                disableFuture
                required
                minDate="1900-01-02" // Treated as 1/1/1900
                minDateMessage={t('registration.dob.dobMin')}
                maxDate={convert(dateOfBirthMaxDate).toDate()}
                maxDateMessage={t('registration.dob.dobMax')}
                initialFocusedDate="1900-01-02"
                onChange={date => setDateOfBirth(date)}/>
        </MuiPickersUtilsProvider>
    )
}