import { useState, useEffect } from "react";
import { FILTER_KEYS, REFERRAL_STATUS } from "../constants/dashboard";
import { IActivityItem, IMember } from "../types/dashboard";
import { getMonths } from "../util/ignite/Dashboard.utils";

const useTabFilter = (allMembers: (IActivityItem | IMember)[], setFilteredMembers: Function): { tabFilter: FILTER_KEYS, setTabFilter: Function, applyTabFilter: Function } => {
    const [tabFilter, setTabFilter] = useState(FILTER_KEYS.ALL_MEMBERS)
    const applyTabFilter = (members = allMembers) => {

        let newData = members
        if (tabFilter === FILTER_KEYS.INVITED_NOT_SIGNED_UP) {
            newData = members.filter(item => item.referralStatus === REFERRAL_STATUS.INVITED)
        }
        if (tabFilter === FILTER_KEYS.NOT_INVITED) {
            newData = members.filter(item => item.referralStatus === REFERRAL_STATUS.NOT_INVITED)
        }
        if (tabFilter === FILTER_KEYS.MISSING_ACTIVITY) {
            newData = members.filter(member => {
                if ("activityRecords" in member) {
                    const monthsForMember = getMonths({fixedAmount: member.maxReportingPeriod, records: member.activityRecords || [], includeCurrentMonth: true})
                    return monthsForMember.some(month => month.activityTotal === undefined)
                }
            })
        }
        if (tabFilter === FILTER_KEYS.ALL_MEMBERS) {
            newData = members
        }
        setFilteredMembers(newData)
    }

    useEffect(applyTabFilter, [tabFilter, allMembers.length])

    return { tabFilter, setTabFilter, applyTabFilter }
}

export default useTabFilter
