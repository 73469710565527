import React from 'react';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio,
} from '@material-ui/core';
import "./ClubDuesPlanOptions.scss";
import { useTranslation } from 'react-i18next';
import { PlanOptions } from '../../../types/club';

interface ClubDuesPlanOptionsProps {
    onChange: (selected: PlanOptions) => void;
    value: PlanOptions;
    label?: string;
}

const ClubDuesPlanOptions = ({value, onChange, label} : ClubDuesPlanOptionsProps) => {
    const { t } = useTranslation('directToConsumer/addClub');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseInt(event.target.value));
    };

    return(
        <FormControl component="fieldset">
            <div className='club-dues-plan-options'>
                <FormLabel component="p" className='group-label'>{label ?? t('fields.clubPlanMenu.label')}</FormLabel>
                <RadioGroup
                    row defaultValue="monthly" aria-label="club-dues-plan-cadence" name="customized-radios"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value={PlanOptions.Annual} control={<Radio />} label={t('fields.clubPlanMenu.plans.annual')} />
                    <FormControlLabel value={PlanOptions.Monthly} control={<Radio />} label={t('fields.clubPlanMenu.plans.monthly')} />
                </RadioGroup>
            </div>
        </FormControl>
    );
}

export default ClubDuesPlanOptions;