import React from "react";
import { useTranslation } from 'react-i18next';
import "./ConfirmationModal.scss";
import { Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IConfirmationModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    onConfirm: (event: React.MouseEvent) => void
    subtitle: string
    content?: JSX.Element | null
    disabled?: boolean
    buttonText?: string
    title: string
}

export default function ConfirmationModal({
    isOpen,
    content,
    onClose,
    onConfirm,
    subtitle,
    disabled,
    buttonText,
    title
}: IConfirmationModalProps) {
    const { t } = useTranslation('ignite/dashboard');

    return (
        <Modal
            open={isOpen}
            className='confirmation-modal'
        >
            <div className="confirmation-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className='content'>
                    <div className="title">
                        {title}
                    </div>
                    <div className="main-text">
                        <div className="subtitle">
                            {subtitle}
                        </div>
                        {content}
                    </div>
                    <div className="button-wrapper">
                        <Button
                            onClick={onClose}
                            className='button cancel'
                            variant='outlined'
                        >
                            {t('removeUsersModal.cancelButton')}
                        </Button>
                        <Button
                            className='button confirm'
                            onClick={onConfirm}
                            disabled={disabled}
                            classes={{ disabled: 'disabled' }}
                        >
                            {buttonText ? buttonText : t('removeUsersModal.confirmButton')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
