import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import "../MemberRegistrationWizardStep.scss";
import "./MemberPersonalInformationStep.scss";
import {useFormState} from "react-use-form-state";
import {FormControl, TextField} from "@material-ui/core";
import PlayButton from "../../../../components/common/PlayButton";
import {ButtonStyles} from "../../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {Locale} from "@js-joda/locale_en-us";
import {convert, DateTimeParseException, LocalDate} from "@js-joda/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MemberSelectPayer, { MemberSelectPayerFormState } from '../../../../components/payer/MemberSelectPayer';
import {LocalDateUtils} from "../../../../util/FixedJsJodaUtils";
import InputMask from "react-input-mask";
import {getPhoneUnmask, PHONE_REGEX} from "../../../../util/Util";
import {UserContext} from "../../../../contexts/UserContext";
import {PhoneNumberOverride} from "../../../../components/common/ContactPhoneNumber";

export interface MemberPersonalInformation {
    payerId: string,
    insuranceId: string,
    healthPlanName: string | null,
    zipCode: string,
    phone: string,
    dateOfBirth: string
}

type StepProp = {
    firstName: string,
    onContinue: (data: any) => void,
    onPrevious: () => void
}

type MemberPersonalInformationFormState = {
    payerId: number | undefined,
    insuranceId: string,
    healthPlanName: string | null,
    zipCode: string,
    phone: string,
    dateOfBirth: LocalDate | null
}


export function MemberPersonalInformationStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const {getContactPhoneNumber} = useContext(UserContext);
    const [formState, {text, tel}] = useFormState<MemberPersonalInformationFormState>({
        payerId: undefined,
        insuranceId: "",
        healthPlanName: null,
        zipCode: "",
        phone: "",
        dateOfBirth: null
    });
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(null);
    const [isPayerStateValid, setIsPayerStateValid] = useState<boolean>(false);
    const [payerState, setPayerState] = useState<MemberSelectPayerFormState>();

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const elementConfig: Record<keyof MemberPersonalInformationFormState, {
        name: keyof MemberPersonalInformationFormState,
        validate?: (value: any) => string | undefined,
        validateOnBlur?: boolean
    }>  = {
        payerId: {
            name: 'payerId',
            validate: () => {
               if (isPayerStateValid) {
                   return;
               }
                return " ";
            },
        },
        insuranceId: {
            name: 'insuranceId',
            validate: () => {
                if (isPayerStateValid) {
                    return;
                }
                return " ";
            },
        },
        healthPlanName: {
            name: 'healthPlanName',
            validate: () => {
                if (isPayerStateValid) {
                    return;
                }
                return " ";
            },
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value.trim()) {
                    //return 'Zip Code is required';
                    return " ";
                }
                if (!value.match(zipCodeRegex)) {
                    //return "Invalid zip code";
                    return " ";
                }
            },
            validateOnBlur: true
        },
        phone: {
            name: 'phone',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return ' ';
                }
            },
            validateOnBlur: true
        },
        // While this isn't rendered directly, it provides validation for the
        // more custom approach for the submit button enabled logic.
        dateOfBirth: {
            name: 'dateOfBirth',
            validate: (value: LocalDate | null) => {
                if (!value || !validateDateOfBirth(value)) {
                    return " ";
                }
            }
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",formState.values);
        // Not all fields are required, so not all fields need to be touched.
        const allFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        });
        return allFieldsValid && Object.entries(formState.validity).every(([key, value]) => value);
    }

    const onContinueSelected = () => {
        props.onContinue({
            payerId: formState.values.payerId,
            insuranceId: formState.values.insuranceId,
            healthPlanName: formState.values.healthPlanName,
            zip: formState.values.zipCode,
            phone: getPhoneUnmask(formState.values.phone),
            dateOfBirth: formState.values.dateOfBirth
        });
    }

    useEffect(() => {
        if (payerState) {
            formState.setField('payerId', payerState.payerId);
            formState.setField('insuranceId', payerState.insuranceId);
            formState.setField('healthPlanName', payerState.healthPlanName);
        }
    }, [payerState]);

    const validateDateOfBirth = (date: LocalDate) => {
        const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
        const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

        return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
    }

    useEffect(() => {
        // The widget validation was inconsistent during development. Perform explicit validation on the parsed LocalDate before passing off the value to the form.
        if (dateOfBirth instanceof LocalDate && validateDateOfBirth(dateOfBirth)) {
            formState.setField('dateOfBirth', dateOfBirth);
        }
        else {
            // Otherwise invalidate the date on the form
            formState.setField('dateOfBirth', null);
        }
    }, [dateOfBirth]);
    
    return (
        <div className="ClubMemberRegistrationWizard_Wrapper MemberPersonalInformationStep">
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={2}>
                <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} direction="column" alignItems="stretch">
                    <Grid item>
                        <h2>{t('memberPersonalInformationStep.thankYouMessage', { name: props.firstName})}</h2>
                        <p className="MemberRegistrationWizard_StepSubcontent">{t('memberPersonalInformationStep.stepSubcontent')}</p>
                        <p dangerouslySetInnerHTML={{__html: t('memberPersonalInformationStep.preferPhone', { phoneNumber: getContactPhoneNumber({override: PhoneNumberOverride.MEMBER})})}}></p>
                        </Grid>
                    <Grid item>
                        <MemberSelectPayer onChange={(state, isPayerInfoValid) => {
                            setPayerState(state)
                            setIsPayerStateValid(isPayerInfoValid)
                        }}></MemberSelectPayer>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth margin="normal">
                        <TextField {...text(elementConfig.zipCode)}
                                            className="MemberRegistrationWizard_Input"
                                            label={t('memberPersonalInformationStep.zipCodeLabel')}
                                            required
                                            variant={"outlined"}
                                            error={formState.errors.zipCode !== undefined}
                                            size="small"/>
                    </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth margin="normal">
                            <InputMask
                                mask='(999) 999-9999'
                                maskChar='*'
                                {...tel(elementConfig.phone)}
                            >
                                {(inputProps: any) =>
                                    <TextField {...inputProps}
                                            className="MemberRegistrationWizard_Input"
                                            label={t('memberPersonalInformationStep.phoneLabel')}
                                            required
                                            variant={"outlined"}
                                            error={formState.errors.phone !== undefined}
                                            size="small"
                                            helperText={t('memberPersonalInformationStep.phoneHelperText')}
                                            FormHelperTextProps={{error: false}}
                                            />
                                }
                            </InputMask>
                    </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth margin="normal">
                        <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
                            <KeyboardDatePicker
                                className="MemberRegistrationWizard_Input"
                                value={dateOfBirth}
                                label={t('memberPersonalInformationStep.dob')}
                                required
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                views={[ "year", "month", "date"]}
                                openTo="year"
                                disableFuture
                                minDate="1900-01-02" // Treated as 1/1/1900
                                minDateMessage={t('memberPersonalInformationStep.dobMin')}
                                maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                                maxDateMessage={t('memberPersonalInformationStep.dobMax')}
                                initialFocusedDate="1900-01-02"
                                onChange={date => setDateOfBirth(date)} />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    </Grid>
                    <Grid item container justify="flex-end">
                        <FormControl>
                        <PlayButton id="PersonalInformationSubmit"
                                    type="button"
                                    clickHandler={onContinueSelected}
                                    text={t('memberPersonalInformationStep.continueButtonText')}
                                    className="MemberRegistrationWizard_SubmitButton"
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={!continueEnabled()}
                        />
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    </div>
    );
}
