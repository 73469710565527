import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Loading from "../common/Loading";
import { UserContext } from '../../contexts/UserContext';
import { PayerEligibilityVerificationStatus } from '../../api/getEligibilityStatus';
import useLatestEligibilityStatus from '../../hooks/useLatestEligibilityStatus';
import { getUsersEligibilityModalShownState, setUsersEligibilityModalShownState } from '../../services/UserService';

import './EligibilityStatusModal.scss';

interface IEligibilityStatusModalProps {
    isOpen?: boolean;
    onClose?: () => void;
    retryRequest?: boolean;
}

enum EligibilityStatus {
    LOADING,
    ELIGIBLE,
    PENDING,
}

const MAX_REFETCH_TRIES = 7;

export default function EligibilityStatusModal({
    isOpen: isOpenProp,
    onClose,
    retryRequest,
}: IEligibilityStatusModalProps) {
    const { t } = useTranslation('common');

    const [isOpen, setOpenStatus] = useState(isOpenProp || false);

    const { user } = useContext(UserContext);

    const [latestEligibility, isLoading, getLatestEligibility] = useLatestEligibilityStatus(isOpen);

    const status = isLoading
        ? EligibilityStatus.LOADING
        : ((latestEligibility?.verificationStatus === PayerEligibilityVerificationStatus.ACTIVE && EligibilityStatus.ELIGIBLE)
            || (latestEligibility?.verificationStatus === PayerEligibilityVerificationStatus.LEGACY_ACTIVE && EligibilityStatus.ELIGIBLE)
            || (latestEligibility?.verificationStatus === PayerEligibilityVerificationStatus.IN_PROGRESS && EligibilityStatus.PENDING)
            || EligibilityStatus.PENDING)

    const getClassName = () => {
        const baseClass = 'eligibility-status-modal-header';

        return (status === EligibilityStatus.LOADING && `${baseClass} ${baseClass}__loading`)
            || (status === EligibilityStatus.ELIGIBLE && `${baseClass} ${baseClass}__eligible`)
            || (status === EligibilityStatus.PENDING && `${baseClass} ${baseClass}__pending`)
            || baseClass;
    }

    const onCloseInner = () => {
        setOpenStatus(false);

        if (user) {
            setUsersEligibilityModalShownState(user.id, true);
        }

        if (onClose) {
            onClose();
        }
    }

    useEffect(() => {
        if (isOpenProp !== undefined) {
            setOpenStatus(isOpenProp);
        }
    }, [isOpenProp]);

    const currentRefetchTry = useRef(MAX_REFETCH_TRIES);
    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        if (user) {
            const isShown = getUsersEligibilityModalShownState(user.id);

            if (isShown === false) {
                setOpenStatus(true);

                if (retryRequest && status !== EligibilityStatus.ELIGIBLE) {
                    intervalId = setInterval(() => {
                        getLatestEligibility();

                        if (currentRefetchTry.current === 0 && intervalId) {
                            clearInterval(intervalId);
                        } else {
                            currentRefetchTry.current -= 1;
                        }
                    }, 2000);
                }
            }
        }

        return () => intervalId ? clearInterval(intervalId) : undefined;
        // isOpen is added to clear the interval when we close the modal
    }, [user, isOpen, status]);

    return (
        <Modal
            disableAutoFocus
            disableEnforceFocus
            open={isOpen}
            className="eligibility-status-modal-wrapper"
            BackdropProps={{ onClick: onCloseInner }}
        >
            <div className="eligibility-status-modal">
                {status === EligibilityStatus.LOADING && (
                    <>
                        <div className={getClassName()}>
                            <Loading loading size={40}/>
                            <p>{t('eligibilityStatusModal.loadingHeader')}</p>
                        </div>
                        <p className="eligibility-status-modal-text">
                            {t('eligibilityStatusModal.loadingText')}
                        </p>
                    </>
                )}
                {status === EligibilityStatus.ELIGIBLE && (
                    <>
                        <div className={getClassName()}>
                            <CheckCircleIcon />
                            <p>{t('eligibilityStatusModal.eligibleHeader')}</p>
                        </div>
                        <p className="eligibility-status-modal-text">
                            {t('eligibilityStatusModal.eligibleText')}
                        </p>
                    </>
                )}
                {status === EligibilityStatus.PENDING && (
                    <>
                        <div className={getClassName()}>
                            <div>
                                <MoreHorizIcon />
                            </div>
                            <p>
                                {latestEligibility?.isUHCPayer
                                    ? t('eligibilityStatusModal.UHCPendingHeader')
                                    : t('eligibilityStatusModal.pendingHeader')
                                }
                            </p>
                        </div>
                        <p className="eligibility-status-modal-text">
                            {latestEligibility?.isUHCPayer
                                ? t('eligibilityStatusModal.UHCPendingText')
                                : t('eligibilityStatusModal.pendingText')
                            }
                        </p>
                    </>
                )}

                {status !== EligibilityStatus.LOADING && (
                    <Button
                        className='eligibility-status-modal-button'
                        onClick={onCloseInner}
                    >
                        {t('okModal.okButtonText')}
                    </Button>
                )}
            </div>
        </Modal>
    );
}
