import React from "react";
import { useTranslation } from 'react-i18next';

import "./EventViewFullMobile.scss";
import StandardDateView from "../common/StandardDateView";
import {isEventTypeSelfGuided} from "../../util/Util";
import SelfGuidedEventTextShort from "../event/SelfGuidedEventTextShort";

interface EventViewFullMobileProps {
    eventDescription: string;
    eventName: string;
    eventDate: string | Date;
    clubName: string;
    className?: string;
    eventType: string | null;
}


export default function EventViewFullMobile(props: EventViewFullMobileProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let eventName = props.eventName;
    let eventDate = props.eventDate;
    let clubName = props.clubName;
    let className = props.className;
    let eventType = props.eventType;

    let classes = 'CCEventViewFullMobile';
    if (className !== undefined) {
        classes += ' ' + className;
    }

    const isSelfGuided = isEventTypeSelfGuided(eventType);

    return (
        <div className={`CCEventViewFullMobile ${classes}`}>
            <div className={`CCEventViewFullMobile_Presents`}>
                {t('eventViewFull.onDate')} <StandardDateView date={eventDate}/>,<br/>
                {t('eventViewFull.presents', {clubName: clubName})}
            </div>
            <div className={`CCEventViewFullMobile_EventName`}>
                {eventName}
            </div>
            <div className={`CCEventViewFullMobile_EventDescription`}>
                {props.eventDescription}
            </div>
            {isSelfGuided && <>
                <div className={`CCEventViewFullDesktop_SelfGuided`}>
                    <SelfGuidedEventTextShort/>
                </div>
            </>}
        </div>
    );
}