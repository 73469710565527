import React from 'react';
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {useFormState} from "react-use-form-state";
import {TextField} from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import Grid from '@material-ui/core/Grid';

export type MemberName = {
  firstName: string,
  lastName: string,
}

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void
}

export function MemberNameStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const initialState = {
        firstName: "",
        lastName: ""
    }

    const [formState, {text}] = useFormState(initialState);

    const elementConfig = {
        firstName: {
            name: "firstName",
            validate: (value: string) => {
                if (!value.trim()) {
                    //return 'First Name is required';
                    return " ";
                }
            },
            validateOnBlur: true
        },
        lastName: {
            name: "lastName",
            validate: (value: string) => {
                if (!value.trim()) {
                    //return 'Last Name is required';
                    return " ";
                }
            },
            validateOnBlur: true
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",formState.values);
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = () => {
        props.onContinue({firstName: formState.values.firstName, lastName: formState.values.lastName});
    }

    return (
        <div className="ClubMemberRegistrationWizard_Wrapper MemberNameStep">
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} direction="column" alignItems="stretch">
                        <Grid item>
                            <h2>{t('memberNameStep.namePrompt')}</h2>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField {...text(elementConfig.firstName)}
                                                    className="MemberRegistrationWizard_Input"
                                                    label={t('memberNameStep.firstName')}
                                                    required
                                                    variant={"outlined"}
                                                    error={formState.errors.firstName !== undefined}
                                                    size="small"/>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                            {...text(elementConfig.lastName)}
                                            className="MemberRegistrationWizard_Input"
                                            label={t('memberNameStep.lastName')}
                                            variant="outlined"
                                            error={formState.errors[elementConfig.lastName.name] !== undefined}
                                            required
                                            size="small"/>
                            </FormControl>
                        </Grid>
                        <Grid item container justify="flex-end">  
                            <FormControl>
                                <PlayButton type="button"
                                            clickHandler={onContinueSelected}
                                            text={t('memberNameStep.continueButtonText')}
                                            className="MemberRegistrationWizard_SubmitButton"
                                            buttonStyle={ButtonStyles.FilledPrimary}
                                            disabled={!continueEnabled()}
                                            id="NameSubmit"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </div>
    );
}