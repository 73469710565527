import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IConfirmationModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    onConfirm: (event: React.MouseEvent) => void
    clubName?: string
    isLeaderOrAdmin: boolean
    isAssociation: boolean
}

export const LeaveClubModal = ({
    isOpen,
    clubName,
    onClose,
    onConfirm,
    isLeaderOrAdmin,
    isAssociation
}: IConfirmationModalProps) => {
    const { t } = useTranslation('ignite/myInfo');

    return (
        <Modal open={isOpen} className='leave-club-modal'>
            <div className="leave-club-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className='content'>
                    <div className="title">
                        {isAssociation ? t('leaveAssociation') : t('leaveClub')}
                    </div>
                    <div className="description">
                        {t('leaveClubConfirmText', { clubName })}
                    </div>
                    {isLeaderOrAdmin && (
                        <div className="warning-block">
                            <div className="subtitle">{t('leaveClubWarningText')}</div>
                            <div className="help-text">
                                {t('leaveClubWarningHelpText')}
                            </div>
                        </div>
                    )}
                    <div className="button-wrapper">
                        <Button
                            onClick={onClose}
                            className='button cancel'
                            variant='outlined'
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            className='button confirm'
                            onClick={onConfirm}
                            disabled={isLeaderOrAdmin}
                            classes={{ disabled: 'disabled' }}
                        >
                            {isAssociation ? t('leaveAssociation') : t('leaveClub')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    );
}
