import React, {useEffect, useState} from "react";
import "./CustomEnrollmentAgreements.scss";
import {useRouteMatch} from "react-router-dom";
import Loading from "../../components/ignite/layout/Loading";
import NotFoundPage from "../notFound/NotFoundPage";
import getClubAssociationAgreementViaSanity, {
    ClubAssociationAgreementSanityData
} from "../../sanity/getClubAssociationAgreementViaSanity";
import PortableText from "react-portable-text";

export function CustomEnrollmentAgreements(): JSX.Element {
    const { agreementSlug } = useRouteMatch<{agreementSlug: string}>().params;

    const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);
    const [agreement, setAgreement] = useState<ClubAssociationAgreementSanityData | undefined>(undefined);

    useEffect(() => {
        getClubAssociationAgreementViaSanity(agreementSlug).then(data => {
            setAgreement(data?.result[0]);
            setQueryResultsLoading(false);
        });

    }, []);

    if (queryResultsLoading) return <Loading loading={queryResultsLoading} />

    if (!queryResultsLoading && (!agreement || !agreement!.content)) {
        return (
            <NotFoundPage></NotFoundPage>
        );
    }

    return (
        <div className={"CustomEnrollmentAgreements"}>
            <PortableText content={agreement!.content}></PortableText>
        </div>
    )
}
