import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom'
import Loading from "../../../components/ignite/layout/Loading";
import useClubById from "../../../hooks/useClubById";
import AddCircleIcon from '@material-ui/icons/AddCircle'

import useGetMembers from "../../../hooks/useGetMembersActivity";
import useGetInvitedMembers from "../../../hooks/useGetInvitedMembers";

import './NewLeaderDashboard.scss';
import useGetNotInvitedMembers from "../../../hooks/useGetNotInvitedMembers";

import MembersTabNew from "../../../components/ignite/LeaderDashboard/MembersTabNew";
import FileUploadIcon from "../../../components/ignite/icons/FileUploadIcon";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { CONTENT_TYPE } from "../../../constants/dashboard";
import SubHeader from "../../../components/ignite/SubHeader/SubHeader";
import useGetMyClubs from "../../../hooks/useGetMyClubs";
import useGetMissingActivityMembers from "../../../hooks/useGetMissingActivity";
import getClubAssociationDashboardContentViaSanity, {
    ClubAssociationManageMemberSanityData
} from "../../../sanity/getClubAssociationDashboardContentViaSanity";

export default function NewLeaderDashboard(): JSX.Element {
    const { clubId } = useParams()
    const history = useHistory()
    const { t } = useTranslation('ignite/dashboard');
    const [club, isClubLoading] = useClubById(clubId)
    const [myClubs] = useGetMyClubs()
    const [dashboardManageMembersModalConfiguration, setDashboardManageMembersModalConfiguration] = useState<ClubAssociationManageMemberSanityData | undefined>(undefined)
    const [modalConfigurationLoading, setModalConfigurationLoading] = useState(true)

    const {
        members,
        isLoading: isMembersActivityLoading,
        fetchMembers,
        totalMembersResultCount
    } = useGetMembers(clubId)

    const {
        invitedMembers = [],
        isLoading: isMembersInvitedLoading,
        fetchInvitedMembers,
        totalInvitedResultCount
    } = useGetInvitedMembers(clubId)

    const {
        notInvitedMembers = [],
        isLoading: isMembersNotInvitedLoading,
        fetchNotInvitedMembers,
        totalNotInvitedResultCount
    } = useGetNotInvitedMembers(clubId)

    const {
        missingActivityMembers = [],
        isLoading: isMissingActivityMembersLoading,
        fetchMissingActivityMembers,
        totalMissingActivityMembersResultCount
    } = useGetMissingActivityMembers(clubId)

    useEffect(() => {
        setModalConfigurationLoading(true);
        if (club?.shortCode) {
            getClubAssociationDashboardContentViaSanity(club.shortCode).then(data => {
                if (data?.result && data?.result.length > 0 && data?.result[0].associationEnrollmentConfiguration?.length > 0) {
                    setDashboardManageMembersModalConfiguration(data.result[0].associationEnrollmentConfiguration[0]);
                }
                else {
                    setDashboardManageMembersModalConfiguration(undefined);
                }
                setModalConfigurationLoading(false);
            });
        } else {
            setDashboardManageMembersModalConfiguration(undefined);
            setModalConfigurationLoading(false);
        }
    }, [club]);

    const isLoading = isClubLoading || isMembersActivityLoading ||
        isMembersInvitedLoading || isMembersNotInvitedLoading || isMissingActivityMembersLoading ||
        modalConfigurationLoading

    if (isLoading) return <Loading loading={isLoading} />

    if (!club) return <div>{t('noSuchClub')}</div>

    return (
        <div className='new-leader-dashboard-page'>
            <div className='top-accent' />
            <div className='leader-dashboard-content'>
                <div className="top-content">
                    <SubHeader
                        clubName={club.clubName}
                        myClubs={myClubs}
                        clubImage={club.clubImage}
                        location={club.location}
                    />
                    <div className="subtitle">
                        {t('memberList')}
                    </div>
                </div>
                <div className="middle-content">
                    <div
                        className="action-button"
                        onClick={() => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': clubId }) + `?content-type=${CONTENT_TYPE.INVITE}`)}
                    >
                        <AddCircleIcon className="add-icon" />
                        <div className="text">
                            {t('addNewMember')}
                        </div>
                    </div>
                    <div
                        className="action-button"
                        onClick={() => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': clubId }) + `?content-type=${CONTENT_TYPE.UPLOAD}`)}
                    >
                        <FileUploadIcon className="file-upload-icon" />
                        <div className="text">
                            {t('bulkUploadMemberList')}
                        </div>
                    </div>
                    {/* temporarily hide according AT-550
                    <div className="action-button">
                        <FileIcon className="file-icon" />
                        <div className="text">
                            {t('bulkUploadMemberLog')}
                        </div>
                    </div> */}
                </div>
                <div className="bottom-content">
                    <MembersTabNew
                        invitedMembers={invitedMembers}
                        notInvitedMembers={notInvitedMembers}
                        missingActivityMembers={missingActivityMembers}
                        members={members}
                        clubId={clubId}
                        club={club}
                        fetchMembers={fetchMembers}
                        fetchMissingActivityMembers={fetchMissingActivityMembers}
                        fetchNotInvitedMembers={fetchNotInvitedMembers}
                        fetchInvitedMembers={fetchInvitedMembers}
                        totalInvitedResultCount={totalInvitedResultCount}
                        totalNotInvitedResultCount={totalNotInvitedResultCount}
                        totalMembersResultCount={totalMembersResultCount}
                        totalMissingActivityMembersResultCount={totalMissingActivityMembersResultCount}
                        dashboardManageMembersModalConfiguration={dashboardManageMembersModalConfiguration}
                    />
                </div>
            </div>
        </div>
    );
}
