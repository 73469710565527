import React from "react";

import {
    LocalDate,
} from "@js-joda/core";

import {
    Checkbox
} from "@material-ui/core";
import "./VirtualClubEventCheckList.scss";

import VirtualClubEventForSignup from "./VirtualClubEventForSignup";

export interface VirtualClubEventCheckListProps {
    className?: string
    children?: any
    events: any
    updateIntendingToSignUpForEvents: Function
    afterEventRSVPReset: any
    nextOccurrenceOnOrAfter: LocalDate
    isClubMember: boolean
}

/**
 * VirtualClubEventCheckList
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=296-3917&mode=design&t=Gs9IdcDyGMIOnhOI-0
 * current uses: see src/pages/VirtualClubsClubDetailPage 
 * 
 * List of a given club's events, ordered chronologically starting on Monday, showing
 * user's current RSVP status, and allowing for sign up / cancelation
 */


export default function VirtualClubEventCheckList(props: VirtualClubEventCheckListProps): JSX.Element {
    let classes = 'VirtualClub_events_container_checklist flex-columns';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let ongoingAndFutureEvents = props.events;

    const handleClick = (e:any) => {

        const eventUrlFragment:string = e.target.value;
        const wantsToJoin:boolean = e.target.checked
        props.updateIntendingToSignUpForEvents(eventUrlFragment, wantsToJoin);
    }
    
    return (
        <div className={classes}>

            {!!ongoingAndFutureEvents && ongoingAndFutureEvents.map((event: any, index: number) => (
                <div className="eventInstance flex-columns" key={index}>
                    <div className="col-left">
                        <VirtualClubEventForSignup
                            event={event}
                            nextOccurrenceOnOrAfter={props.nextOccurrenceOnOrAfter}
                            isClubMember={props.isClubMember}
                            isRegistered={false}
                        />
                    </div>
                    <div className="col-right">
                        <Checkbox
                            value={event.urlFragment}
                            onClick={handleClick}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />

                    </div>
                </div>
            ))}

        </div>
    )
};