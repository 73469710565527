import React from "react";
import { useLocation} from "react-router-dom";
import IgniteRouter from "./IgniteRouter";
import AuthenticatedRouter from "../AuthenticatedRouter";

const Router = () => {
    const location = useLocation()

    return location.pathname.startsWith('/ignite') ? <IgniteRouter /> : <AuthenticatedRouter />
}

export default Router
