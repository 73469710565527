import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

const postRsvpEvent = async (eventUrlFragment: string) => {
    const config = await loadConfig();
    let url = `${config.apiOrigin}/events/rsvp/${encodeURIComponent(eventUrlFragment)}`;

    const request = new Request(
        url,
        { method: "POST", headers: { "Accept": "application/json" } });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response
    } else {
        return undefined
    }
}

export default postRsvpEvent
