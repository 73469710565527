import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./RsvpAttendanceListSelfGuided.scss";
import {
    GetUserEventRelationshipResponseBody,
    GetUserEventRelationshipsResponseBody,
    UserEventRelationshipStatus
} from "../../services/ClubLeaderService";
import BorderedRow, {BorderedRowItem, BorderedRowLastItem} from "../common/BorderedRow";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/UserService";
import Loading from "../common/Loading";
import {userNameSort} from "../../util/Util";

export interface RsvpAttendanceListProps {
    className?: string;
    eventUrlFragment: string;
}

function filterAttendees(responseBody: GetUserEventRelationshipsResponseBody) {
    let tempAttendees = new Array<GetUserEventRelationshipResponseBody>();
    responseBody.userEventRelationships.map((attendanceRecord) => {
        if (attendanceRecord.status === UserEventRelationshipStatus.Attended
            || attendanceRecord.status === UserEventRelationshipStatus.AttendedWithoutRsvp) {
            tempAttendees.push(attendanceRecord);
        }
        return true;
    });

    return {attended: tempAttendees.sort(userNameSort)};
}

function count(allUsers: Array<GetUserEventRelationshipResponseBody>) {
    let newAttendeeCount = 0;
    allUsers.map((attendeeRecord) => {
        if (attendeeRecord.status === UserEventRelationshipStatus.Attended
            || attendeeRecord.status === UserEventRelationshipStatus.AttendedWithoutRsvp) {
            newAttendeeCount++;
        }
        return true;
    })

    return {attendeeCount: newAttendeeCount};
}

function getGetRequest(apiOrigin: string, eventUrlFragment: string) {
    const getUrl = `${apiOrigin}/club-leader/events/get-user-event-relationships/${eventUrlFragment}/false`;

    return new Request(getUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default function RsvpAttendanceListSelfGuided(props: RsvpAttendanceListProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let initialClasses = 'RsvpAttendanceListSelfGuided';
    if (props.className !== undefined) {
        initialClasses += ' ' + props.className;
    }

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [numberOfAttendees, setNumberOfAttendees] = useState(0);
    const [attendees, setAttendees] = useState(new Array<GetUserEventRelationshipResponseBody>());

    useEffect(
        () => {
            async function load() {
                setLoading(true);
                const config = await loadConfig();
                const getRequest = getGetRequest(config.apiOrigin, props.eventUrlFragment);
                const getResponse = await authenticatedFetch(getRequest);

                if (getResponse === null || !getResponse?.ok) {
                    setLoading(false);
                    setLoadingMessage(t('rsvpAttendanceListSelfGuided.loadingError'));
                    console.log(getResponse);
                    return;
                }

                const responseBody: GetUserEventRelationshipsResponseBody = await getResponse.json();

                setAttendees(filterAttendees(responseBody).attended);
                setNumberOfAttendees(count(filterAttendees(responseBody).attended).attendeeCount);
                setLoading(false);
            }

            load().then();
        },
        [props.eventUrlFragment, t]);


    return (
        <div className={`${initialClasses}`}>
            <div
                className={`'RsvpAttendanceListSelfGuided_Content'`}>
                {(loading) && <Loading loading={(loading)}/>}
                {loadingMessage !== null &&
                <div
                    className={"RsvpAttendanceListSelfGuided_LoadingMessage"}>{loadingMessage}</div>}
                {!(loading) && loadingMessage === null &&
                <>
                    {attendees.length <= 0 && <>
                        <div className="RsvpAttendanceListSelfGuided_Title">
                            {t('rsvpAttendanceListSelfGuided.noAttendanceNotice')}
                        </div>
                    </>}
                    {attendees.length > 0 && <>
                        <div
                            className="RsvpAttendanceListSelfGuided_Title">{numberOfAttendees}&nbsp;
                            {numberOfAttendees === 1 && " " + t('rsvpAttendanceListSelfGuided.singularAttendee')}
                            {numberOfAttendees !== 1 && " " + t('rsvpAttendanceListSelfGuided.multipleAttendees')}
                        </div>
                        {attendees.map((rsvp, index) =>
                            <RsvpAttendanceRow
                                key={rsvp.userEmail}
                                rsvp={rsvp}
                                index={index}
                            />
                        )}
                    </>}
                </>
                }
            </div>
        </div>
    );
}

export interface RsvpAttendanceRowProps {
    className?: string;
    index: number;
    rsvp: GetUserEventRelationshipResponseBody
}

export function RsvpAttendanceRow(props: RsvpAttendanceRowProps) {
    const { t } = useTranslation('clubLeaderEvent');
    let classes = 'RsvpAttendanceRow';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let message = t('rsvpAttendanceListSelfGuided.attendedText');

    return (
        <BorderedRow className={`${classes}`} showTopBorder={props.index === 0}>
            <BorderedRowItem className="RsvpAttendanceRow_Name">
                {props.rsvp.userFirstName} {props.rsvp.userLastName}
            </BorderedRowItem>
            <BorderedRowItem className="RsvpAttendanceRow_Contact">
                <div><a href={`mailto:${props.rsvp.userEmail}`}>{props.rsvp.userEmail}</a></div>
                <div><a href={`tel:${props.rsvp.userPhone}`}>{props.rsvp.userPhone}</a></div>
            </BorderedRowItem>
            <BorderedRowLastItem className="RsvpAttendanceRow_AttendedOrNot">
                {message}
            </BorderedRowLastItem>
        </BorderedRow>
    );
}
