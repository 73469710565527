import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import useCheckDevice from '../../../hooks/useCheckDevice'
import { IActivityItem, IMember, IUser } from '../../../types/dashboard'
import { CLUB_ROLES, CLUB_ROLES_FE_STRINGS, CONTENT_TYPE, MEMBER_TABS } from '../../../constants/dashboard'
import Tabs from './Tabs'
import SearchIcon from '@material-ui/icons/Search';
import './MembersTab.scss';
import { MembersTable } from "./MembersTable/MembersTable";
import { MembersTableMobile } from "./MembersTable/MembersTableMobile";
import MemberInviteModal from "./MemberInviteModal";
import { useSelectLogic } from "./MembersTable/useSelectLogic";
import { getFormattedUsers } from "../../../util/ignite/Dashboard.utils";
import postRemoveUsersFromClub from "../../../api/postRemoveUsersFromClub";
import { Snackbar, TextField, MenuItem, FormControl, OutlinedInput, InputAdornment } from "@material-ui/core";
import ConfirmationModal from "./ConfirmationModal";
import { UserContext } from "../../../contexts/UserContext";
import { memberDashboardConfig } from "../../../configs/memberDashboard";
import postUpdateClubRoles from "../../../api/postUpdateClubRoles";
import { IClub } from "../../../types/clubFinder";
import postInviteNotInvitedMembers from "../../../api/postInviteNotInvitedMembers";
import EditMemberModal from "./EditMemberModal/EditMemberModal";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { useHistory, useLocation } from "react-router-dom";
import CalendarModal from "./CalendarModal";
import {ClubAssociationManageMemberSanityData} from "../../../sanity/getClubAssociationDashboardContentViaSanity";

interface IMembersTabProps {
    members: IActivityItem[]
    invitedMembers?: IMember[]
    notInvitedMembers?: IMember[]
    club: IClub,
    clubId: string
    missingActivityMembers: IActivityItem[]
    fetchMissingActivityMembers: Function
    fetchNotInvitedMembers: Function
    fetchInvitedMembers: Function
    totalInvitedResultCount: number
    totalNotInvitedResultCount: number
    fetchMembers: Function
    totalMembersResultCount: number
    totalMissingActivityMembersResultCount: number
    dashboardManageMembersModalConfiguration?: ClubAssociationManageMemberSanityData|undefined
}

interface INotification {
    message: string
    isError?: boolean
}

const MembersTabNew = ({
    invitedMembers = [],
    notInvitedMembers = [],
    members: allMembers,
    clubId,
    fetchMembers,
    missingActivityMembers,
    fetchMissingActivityMembers,
    fetchNotInvitedMembers,
    fetchInvitedMembers,
    club,
    totalInvitedResultCount,
    totalNotInvitedResultCount,
    totalMembersResultCount,
    totalMissingActivityMembersResultCount,
    dashboardManageMembersModalConfiguration
}: IMembersTabProps) => {
    const { t } = useTranslation('ignite/dashboard');
    const [shouldShowRemoveUsersModal, setShouldShowRemoveUsersModal] = useState(false)
    const [shouldShowChangeRoleModal, setShouldShowChangeRoleModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const [selectedRole, setSelectedRole] = useState<CLUB_ROLES>(CLUB_ROLES.MEMBER)
    const [memberInviteModalIsOpen, setMemberInviteModalIsOpen] = useState(false)
    const { user } = useContext(UserContext)
    const [notification, setNotification] = useState<INotification | null>()
    const [memberTabValue, setMemberTabValue] = useState(MEMBER_TABS.ALL_MEMBERS_TAB);
    const [editingId, setEditingId] = useState<string>();
    const history = useHistory()
    const location = useLocation();
    const [logActivityId, setLogActivityId] = useState<string>();

    const getMembers = () => {
        if (memberTabValue === MEMBER_TABS.NOT_INVITED_TAB) return notInvitedMembers
        if (memberTabValue === MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB) return invitedMembers
        if (memberTabValue === MEMBER_TABS.ALL_MEMBERS_TAB) return allMembers
        if (memberTabValue === MEMBER_TABS.MISSING_ACTIVITY_TAB) return missingActivityMembers
    }

    const members = getMembers() as IActivityItem[]

    const { isMobile } = useCheckDevice()
    const rows = getFormattedUsers(members as unknown as IUser[])
    const selectionData = useSelectLogic(rows)
    const { selected, isSelected, reset } = selectionData
    const currentUserId = Number(rows.find(clubUser => clubUser.email === user?.email)?.userId)
    const currentUserRole = rows.find(clubUser => clubUser.email === user?.email)?.role
    const getIdsWithoutCurrentUser = (clubUserIds: number[]) => clubUserIds.filter(userId => userId !== currentUserId)
    const onEditMember = (row: IUser) => {
        if (memberTabValue === MEMBER_TABS.ALL_MEMBERS_TAB || memberTabValue === MEMBER_TABS.MISSING_ACTIVITY_TAB) {
            setEditingId(row.userId)
        }
    }

    const selectedId = rows.filter(member => isSelected(member.email)).map(member => Number(member.userId))
    const selectedOnlyLeadersAndMembersId = rows.filter(member => isSelected(member.email) && (member.role === CLUB_ROLES[0] || member.role === CLUB_ROLES[2])).map(member => Number(member.userId))
    const selectedOnlyMembersId = rows.filter(member => isSelected(member.email) && member.role === CLUB_ROLES[0]).map(member => Number(member.userId))

    const isSelectedOnlyMembers = selectedId.length === selectedOnlyMembersId.length && selectedId.length > 0
    const isSelectedOnlyMembersAndLeader = selectedId.length === selectedOnlyLeadersAndMembersId.length && selectedId.length > 0

    const isInvitedMembersTab = memberTabValue === MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB
    const isNotInvitedMembersTab = memberTabValue === MEMBER_TABS.NOT_INVITED_TAB
    const isAllMembersTab = memberTabValue === MEMBER_TABS.ALL_MEMBERS_TAB
    const isMissingActivityMembersTab = memberTabValue === MEMBER_TABS.MISSING_ACTIVITY_TAB

    useEffect(() => setSelectedRole(CLUB_ROLES.MEMBER), [shouldShowChangeRoleModal])
    useEffect(() => setSearchValue(''), [memberTabValue])

    const getIsDisabledEditIcon = (row: IUser) =>
        memberTabValue === MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB
        || memberTabValue === MEMBER_TABS.NOT_INVITED_TAB
        || (currentUserRole === CLUB_ROLES_FE_STRINGS.LEADER
            && row.role != CLUB_ROLES_FE_STRINGS.MEMBER
            && currentUserId !== Number(row.userId)
        )

    const tryToRemoveUsers = () => {
        setShouldShowRemoveUsersModal(true)
    }

    const tryToUpdateRoles = () => {
        setShouldShowChangeRoleModal(true)
    }

    const onCloseInviteModal = () => {
        fetchNotInvitedMembers()
        fetchInvitedMembers()
        setMemberInviteModalIsOpen(false)
    }

    const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const getTotalCount = () => {
        if (isInvitedMembersTab) return totalInvitedResultCount
        if (isNotInvitedMembersTab) return totalNotInvitedResultCount
        if (isAllMembersTab) return totalMembersResultCount
        if (isMissingActivityMembersTab) return totalMissingActivityMembersResultCount
    }

    const updateRoles = () => {
        const formattedData = getIdsWithoutCurrentUser(selectedOnlyLeadersAndMembersId)
            .map(data => ({ UserId: data, UserClubRelationshipTypeId: selectedRole }))

        postUpdateClubRoles({
            ClubId: Number(clubId),
            UserClubRoles: formattedData,
            ConfirmChange: true
        }).then((data) => {
            if (data?.success) {
                fetchMembers()
                fetchMissingActivityMembers()
                reset()
                setNotification({ message: t('changeRoleModal.successNotification', { count: selectedOnlyLeadersAndMembersId.length }) })
                setTimeout(() => {
                    if (!isSelectedOnlyMembersAndLeader) {
                        setNotification({ message: t('changeRoleModal.errorNotification', { count: selectedId.length - selectedOnlyLeadersAndMembersId.length }), isError: true })
                        setTimeout(() => setNotification(null), memberDashboardConfig.NOTIFICATION_HIDE_TIME)
                    } else { setNotification(null) }
                }, memberDashboardConfig.NOTIFICATION_HIDE_TIME)
            }
        }).finally(() => {
            setShouldShowChangeRoleModal(false)
        })
    }

    const removeUsers = () => {
        postRemoveUsersFromClub({
            ClubId: Number(clubId),
            UserIds: selectedOnlyMembersId,
            ConfirmChange: true
        }).then((data) => {
            if (data?.success) {
                fetchMembers()
                fetchMissingActivityMembers()
                reset()
                setNotification({ message: t('removeUsersModal.successNotification', { count: selectedOnlyMembersId.length }) })
                setTimeout(() => {
                    if (!isSelectedOnlyMembers) {
                        setNotification({ message: t('removeUsersModal.errorNotification', { count: selectedId.length - selectedOnlyMembersId.length }), isError: true })
                        setTimeout(() => setNotification(null), memberDashboardConfig.NOTIFICATION_HIDE_TIME)
                    } else { setNotification(null) }
                }, memberDashboardConfig.NOTIFICATION_HIDE_TIME)
            }
        }).finally(() => {
            setShouldShowRemoveUsersModal(false)
        })
    }

    const inviteUsers = (selectedMembers: IMember[]) => {
        const notInvitedReferralIds = selectedMembers?.map((member: IMember) => member.referralId) as number[]
        return postInviteNotInvitedMembers({
            ClubId: Number(clubId),
            ReferralIds: notInvitedReferralIds,
        }).then(() => {
            setNotification({ message: t('inviteUsersModal.successNotification', { count: notInvitedReferralIds.length }) })
            setTimeout(() => {
                setNotification(null)
            }, memberDashboardConfig.NOTIFICATION_HIDE_TIME)
            onCloseInviteModal()
            selectionData.reset()
        })
    }

    const remindMembers = () => {
        history.push(getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': clubId }) + `?content-type=${CONTENT_TYPE.REMIND_MISSING_ACTIVITY_MEMBERS}`,
            { missingActivityMembers: rows.filter(member => isSelected(member.email)), initialPath: location.pathname }
        )
    }

    const onMembersTabChange = (event: React.ChangeEvent<{}>, newValue: MEMBER_TABS) => {
        setMemberTabValue(newValue);
        reset()
    };

    const roles = Object.keys(CLUB_ROLES_FE_STRINGS) as unknown as CLUB_ROLES[]

    const currentRecord = rows.find(row => row.userId == logActivityId) as unknown as IUser
    return (
        <div className="members-tab-content">
            <CalendarModal
                isOpen={Boolean(logActivityId)}
                onClose={() => setLogActivityId('')}
                club={club}
                refetchActivity={() => {
                    fetchMembers()
                    fetchMissingActivityMembers()
                }}
                activityItem={currentRecord}
                logActivityId={logActivityId}
            />
            <MemberInviteModal
                isOpen={memberInviteModalIsOpen}
                onClose={onCloseInviteModal}
                notInvitedMembers={notInvitedMembers}
                clubId={clubId}
                selected={selected}
                inviteUsers={inviteUsers}
                club={club}
            />
            <EditMemberModal
                isOpen={Boolean(editingId)}
                onClose={() => setEditingId(undefined)}
                editingId={Number(editingId)}
                clubId={Number(clubId)}
                fetchMembers={() => {
                    fetchMembers()
                    fetchMissingActivityMembers()
                }}
                dashboardManageMembersModalConfiguration={dashboardManageMembersModalConfiguration}
            />
            <Snackbar
                open={Boolean(notification && !notification.isError)}
                onClose={() => setNotification(null)}
                message={notification?.message}
                ContentProps={{ className: 'notification-success' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
            <Snackbar
                open={Boolean(notification?.isError)}
                onClose={() => setNotification(null)}
                message={notification?.message}
                ContentProps={{ className: 'notification-error' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />

            <ConfirmationModal
                onConfirm={removeUsers}
                isOpen={shouldShowRemoveUsersModal}
                title={t('removeUsersModal.title')}
                subtitle={t('removeUsersModal.subtitle', { count: selectedId.length })}
                disabled={selectedOnlyMembersId.length === 0}
                onClose={() => { setShouldShowRemoveUsersModal(false) }}
                content={!isSelectedOnlyMembers ? (
                    <div className="warning-block">
                        <div className="title">{t('removeUsersModal.warningTitle')}</div>
                        <div className="description">
                            {t('removeUsersModal.description', { count: selectedId.length - selectedOnlyMembersId.length })}
                        </div>
                    </div>
                ) : null}
            />
            <ConfirmationModal
                onConfirm={updateRoles}
                isOpen={shouldShowChangeRoleModal}
                onClose={() => setShouldShowChangeRoleModal(false)}
                title={t('changeRoleModal.title', { count: selectedId.length })}
                subtitle={t('changeRoleModal.subtitle', { count: selectedId.length })}
                buttonText={t('changeRoleModal.confirmButton')}
                disabled={selectedOnlyLeadersAndMembersId.length === 0}
                content={
                    <div className="change-role">
                        <TextField
                            className='change-role__select'
                            variant="outlined"
                            select={true}
                            value={CLUB_ROLES[selectedRole]}
                            size='small'
                        >
                            {roles.map((action) => (
                                <MenuItem
                                    key={action}
                                    value={action}
                                    className="change-role__item"
                                    onClick={() => setSelectedRole(CLUB_ROLES[action] as unknown as CLUB_ROLES)}
                                >
                                    {t(`changeRoleModal.roles.${action}`)}
                                </MenuItem>
                            ))}
                        </TextField>
                        {!isSelectedOnlyMembersAndLeader && (
                            <div className="warning-block">
                                <div className="title">{t('changeRoleModal.warningTitle')}</div>
                                <div className="description">
                                    {t('changeRoleModal.description', { count: selectedId.length - selectedOnlyLeadersAndMembersId.length })}
                                </div>
                            </div>
                        )}
                    </div>
                }
            />
            <FormControl variant="standard" className="search-input">
                <label htmlFor="search-id" hidden aria-hidden="true">{t('search')}</label>
                <OutlinedInput
                    className='search-input'
                    fullWidth
                    id="search-id"
                    value={searchValue}
                    placeholder={t('searchPlaceholder')}
                    onChange={onChangeSearchValue}
                    startAdornment={(
                        <InputAdornment position="start">
                            <SearchIcon className='search-icon' />
                        </InputAdornment>
                    )}
                />
            </FormControl>
            <Tabs
                value={memberTabValue}
                onChange={onMembersTabChange}
                className='members-tabs'
                items={[
                    {
                        label: t(`memberTabs.${MEMBER_TABS.ALL_MEMBERS_TAB}`) + ` (${totalMembersResultCount})`,
                        value: MEMBER_TABS.ALL_MEMBERS_TAB,
                    },
                    {
                        label: t(`memberTabs.${MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB}`) + ` (${totalInvitedResultCount})`,
                        value: MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB
                    },
                    {
                        label: t(`memberTabs.${MEMBER_TABS.NOT_INVITED_TAB}`) + ` (${totalNotInvitedResultCount})`,
                        value: MEMBER_TABS.NOT_INVITED_TAB,
                    },
                    {
                        label: t(`memberTabs.${MEMBER_TABS.MISSING_ACTIVITY_TAB}`) + ` (${totalMissingActivityMembersResultCount})`,
                        value: MEMBER_TABS.MISSING_ACTIVITY_TAB
                    }
                ]}
            />
            {isMobile ?
                <MembersTableMobile
                    setLogActivityId={setLogActivityId}
                    rows={rows}
                    selectionData={selectionData}
                    onRemoveMembers={tryToRemoveUsers}
                    onUpdateRoles={tryToUpdateRoles}
                    memberTabValue={memberTabValue}
                    onEditMember={onEditMember}
                    club={club}
                    openMemberInviteModal={() => setMemberInviteModalIsOpen(true)}
                    isModalOpen={shouldShowRemoveUsersModal || shouldShowChangeRoleModal}
                    getIsDisabledEditIcon={getIsDisabledEditIcon}
                    isInvitedMembersTab={isInvitedMembersTab}
                    isNotInvitedMembersTab={isNotInvitedMembersTab}
                    isAllMembersTab={isAllMembersTab}
                    isMissingActivityMembersTab={isMissingActivityMembersTab}
                    fetchInvitedMembers={fetchInvitedMembers}
                    fetchNotInvitedMembers={fetchNotInvitedMembers}
                    fetchMembers={fetchMembers}
                    fetchMissingActivityMembers={fetchMissingActivityMembers}
                    getTotalCount={getTotalCount}
                    remindMembers={remindMembers}
                    isDisabledViewIcon={isInvitedMembersTab || isNotInvitedMembersTab}
                    searchValue={searchValue}
                /> :
                <MembersTable
                    setLogActivityId={setLogActivityId}
                    selectionData={selectionData}
                    memberTabValue={memberTabValue}
                    onRemoveMembers={tryToRemoveUsers}
                    onUpdateRoles={tryToUpdateRoles}
                    rows={rows}
                    onEditMember={onEditMember}
                    club={club}
                    openMemberInviteModal={() => setMemberInviteModalIsOpen(true)}
                    isModalOpen={shouldShowRemoveUsersModal || shouldShowChangeRoleModal}
                    getIsDisabledEditIcon={getIsDisabledEditIcon}
                    isDisabledViewIcon={isInvitedMembersTab || isNotInvitedMembersTab}
                    getTotalCount={getTotalCount}
                    isInvitedMembersTab={isInvitedMembersTab}
                    isNotInvitedMembersTab={isNotInvitedMembersTab}
                    isAllMembersTab={isAllMembersTab}
                    isMissingActivityMembersTab={isMissingActivityMembersTab}
                    fetchInvitedMembers={fetchInvitedMembers}
                    fetchNotInvitedMembers={fetchNotInvitedMembers}
                    fetchMembers={fetchMembers}
                    fetchMissingActivityMembers={fetchMissingActivityMembers}
                    remindMembers={remindMembers}
                    searchValue={searchValue}
                />}
        </div>
    )
}

export default MembersTabNew
