import i18next from "i18next";
import {SanityTextObject, getSanityResponse} from "./SanityUtils";

export interface SignUpFormConfigSanityResponse {
    ms: number;
    query: string;
    result: SignUpFormConfigSanityData[];
}
export interface SignUpFormConfigSanityData {
    title: SanityTextObject[];
    body: SanityTextObject[];
    firstNameLabel: string;
    lastNameLabel: string;
    emailLabel: string;
    confirmEmailLabel: string;
    passwordLabel: string;
    confirmPasswordLabel: string;
}

const getRegistrationFormViaSanity = async (formSlug: string): Promise<SignUpFormConfigSanityResponse | undefined> => {
    const LANGUAGE = i18next.language?.split('-')[0] ?? 'en';
    let query = encodeURIComponent(`
        *[_type == "sign_up_form" && slug == "${formSlug}"]
        {
            "title": pageTitle[_key == "${LANGUAGE}"][0].value,
            "body": body[_key == "${LANGUAGE}"][0].value,
            "firstNameLabel": firstNameLabel.${LANGUAGE},
            "lastNameLabel": lastNameLabel.${LANGUAGE},
            "emailLabel": emailLabel.${LANGUAGE},
            "confirmEmailLabel": confirmEmailLabel.${LANGUAGE},
            "passwordLabel": passwordLabel.${LANGUAGE},
            "confirmPasswordLabel": confirmPasswordLabel.${LANGUAGE},
        }
    `);

    return getSanityResponse(query);
}

export default getRegistrationFormViaSanity;
