import React from 'react';
import { IClub } from '../../../types/clubFinder';
import { useTranslation } from 'react-i18next';
import "./ClubCard.scss";

interface ClubCardProps {
    club: IClub
}

export const DEFAULT_PASSION_IMAGE_NAME = "other.jpg";

const ClubCard = ({club} : ClubCardProps) => {
    const { t } = useTranslation('directToConsumer/clubFinder');
    const renderClubLeader = () => {
        const leaders = club?.primaryClubLeaders ?? [];
        if (leaders?.length > 0) {
            const {firstName, lastName} = leaders[0];
            return (
                <div className='leaders'>
                    <span>{t("section.clubLeader")}:</span>
                    <span>&nbsp;{firstName}&nbsp;{lastName}</span>
                </div>
            );
        }

        return <></>;
    }

    const renderClubLocation = () => {
        if(!club?.location) return <></>;
        const { location } = club;
        const { city, countrySubdivision } = location;
        if(!(city?.length > 0 && countrySubdivision?.length > 0)) return <></>;
        return(
            <div className="location divider">
                <div><b>{t("section.location")}</b>&nbsp;</div>
                <div>
                    {city},&nbsp;{countrySubdivision}
                </div>
            </div>
        );
    }

    return (
        <div className='clubcard'>
            <div>
                <img  
                    src={"/images/passions/" + (club?.clubImage?.length > 0 ? club.clubImage : DEFAULT_PASSION_IMAGE_NAME)}
                    alt=""
                    className="club-image"
                />
            </div>
            <div className="club-info">
                <div className="club-name divider">
                    {club?.clubName}
                </div>
                {renderClubLocation()}
                {renderClubLeader()}
            </div>
        </div>
    );
}

export default ClubCard;