import React from "react";
import "./FieldWithBottomText.scss";

interface FieldWithBottomTextProps {
    children: any;
    instructionText: string;
    className: string;
}

export default function FieldWithBottomText(props: FieldWithBottomTextProps): JSX.Element {
    let classes = 'FieldWithBottomText_Wrapper';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (

        <div className={classes}>
            {props.children}
            <div className="FieldWithBottomText_Instructions">{props.instructionText}</div>
        </div>

    );
}