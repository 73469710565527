export enum FILTER_KEYS {
    LAST_NAME_ASC = 'LAST_NAME_ASC',
    LAST_NAME_DESC = 'LAST_NAME_DESC',
    ACTIVITY_ASC = 'ACTIVITY_ASC',
    ACTIVITY_DESC = 'ACTIVITY_DESC',
    EARNING_ASC = 'EARNING_ASC',
    EARNING_DESC = 'EARNING_DESC',
    ELIGIBLE = 'ELIGIBLE',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    INVITED_NOT_SIGNED_UP = 'INVITED_NOT_SIGNED_UP',
    NOT_INVITED = 'NOT_INVITED',
    ALL_MEMBERS = 'ALL_MEMBERS',
    MISSING_ACTIVITY = 'MISSING_ACTIVITY'
}

export enum PROFILE_MENU_KEYS {
    DASHBOARD = 'DASHBOARD',
    MY_EVENTS = 'MY_EVENTS',
    EDIT_YOUR_INFO = 'EDIT_YOUR_INFO',
    SWITCH_CLUBS = 'SWITCH_CLUBS',
    HELP = 'HELP',
    LOG_OUT = 'LOG_OUT',
}

export enum FILTER_FIELDS {
    LAST_NAME = 'lastName',
    SCORE = 'score',
    INCOME = 'income',
    ELIGIBLE = 'eligible',
    NOT_ELIGIBLE = 'notEligible',
    INVITED_NOT_SIGNED_UP = 'invitedNotSignedUp',
    NOT_INVITED = 'notInvited',
    ALL_MEMBERS = 'allMembers',
}

export const filterOptions = [
    { key: FILTER_KEYS.LAST_NAME_ASC, field: FILTER_FIELDS.LAST_NAME, isAsc: true },
    { key: FILTER_KEYS.LAST_NAME_DESC, field: FILTER_FIELDS.LAST_NAME, isAsc: false },
    { key: FILTER_KEYS.ACTIVITY_ASC, field: FILTER_FIELDS.SCORE, isAsc: true },
    { key: FILTER_KEYS.ACTIVITY_DESC, field: FILTER_FIELDS.SCORE, isAsc: false },
    { key: FILTER_KEYS.EARNING_ASC, field: FILTER_FIELDS.INCOME, isAsc: true },
    { key: FILTER_KEYS.EARNING_DESC, field: FILTER_FIELDS.INCOME, isAsc: false },
    { key: FILTER_KEYS.ELIGIBLE, field: FILTER_FIELDS.ELIGIBLE, isAsc: false },
    { key: FILTER_KEYS.NOT_ELIGIBLE, field: FILTER_FIELDS.NOT_ELIGIBLE, isAsc: false },
]

export const profileOptions = [
    { key: PROFILE_MENU_KEYS.DASHBOARD },
    { key: PROFILE_MENU_KEYS.EDIT_YOUR_INFO },
    { key: PROFILE_MENU_KEYS.MY_EVENTS },
    { key: PROFILE_MENU_KEYS.SWITCH_CLUBS },
    { key: PROFILE_MENU_KEYS.HELP },
    { key: PROFILE_MENU_KEYS.LOG_OUT }
]

export enum MONTH_KEYS {
    JAN,
    FEB,
    MAR,
    APR,
    MAY,
    JUN,
    JUL,
    AUG,
    SEP,
    OCT,
    NOV,
    DEC
}

export enum BADGE_KEYS {
    FIRST_ACTIVITY = 'first-activity',
    TRACKING_COMPLETED = 'tracking-completed'
}

export enum CONTENT_TYPE {
    INITIAL = 'INITIAL',
    COPY_EMAIL = 'COPY_EMAIL',
    INVITE = 'INVITE',
    UPLOAD = 'UPLOAD',
    REMIND_INVITED_MEMBERS = 'REMIND_INVITED_MEMBERS',
    REMIND_MISSING_ACTIVITY_MEMBERS = 'REMIND_MISSING_ACTIVITY_MEMBERS'
}

export enum MEMBER_TABS {
    ALL_MEMBERS_TAB = 'ALL_MEMBERS_TAB',
    INVITED_NOT_SIGNED_UP_TAB = 'INVITED_NOT_SIGNED_UP_TAB',
    NOT_INVITED_TAB = 'NOT_INVITED_TAB',
    MISSING_ACTIVITY_TAB = 'MISSING_ACTIVITY_TAB'
}

export enum BODY_TABS {
    LEADER_TO_DO_TAB = 'LEADER_TO_DO_TAB',
    LEADERBOARD_TAB = 'LEADERBOARD_TAB',
    MEMBERS_TAB = 'MEMBERS_TAB'
}

export enum CLUB_ROLES {
    MEMBER,
    ADMIN,
    LEADER
}

export enum CLUB_ROLES_FE_STRINGS {
    MEMBER = 'MEMBER',
    ADMIN = 'ADMIN',
    LEADER = 'LEADER'
}

export enum CLUB_ROLES_STRINGS {
    MEMBER = 'Member',
    ADMIN = 'PrimaryLeader',
    LEADER = 'SecondaryLeader',
    UNKNOWN = 'Unknown'
}

export enum REFERRAL_STATUS {
    NOT_INVITED,
    INVITED,
    ACCEPTED,
}

export enum CONTACT_METHOD {
    EMAIL,
    PHONE,
    BOTH,
}

export enum ACTIVITY_MONTH_STATUS {
    REPORTED,
    LOCKED,
}

export enum ACTIVITY_DATE_RECORD_TYPE {
    MONTHLY_RECORD = 0,
    DAILY_RECORD = 1,
}

export enum VIEW {
    MEMBER_DASHBOARD,
    LEADER_DASHBOARD,
}

export enum ACTION_OPTIONS {
    CHANGE_ROLE = 'CHANGE_ROLE',
    CHANGE_STATUS = 'CHANGE_STATUS',
    REMOVE = 'REMOVE',
    SEND_EMAIL = 'SEND_EMAIL',
    INVITE_MEMBERS = 'INVITE_MEMBERS',
    SEND_MISSING_ACTIVITY_REMINDER = 'SEND_MISSING_ACTIVITY_REMINDER'
}

export enum SOURCE_TYPES_BY_ACTIVITY_TYPE {
    IN_PERSON = 'IN_PERSON',
    VIRTUAL = 'VIRTUAL'
}

export enum USER_ACTIVITY_SOURCE_TYPE {
    MEMBER,
    LEADER,
    AUTOMATED,
}
