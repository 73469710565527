import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./ClubLeaderViewEvent.scss";
import BackLink from "../../components/common/BackLink";
import EventViewWithDetails from "../../components/clubLeaderEvent/EventViewWithDetails";
import {loadConfig} from "../../services/ConfigService";
import {
    EventStatus,
    GetClubLeaderEventAndClubResponseBody,
    getEventTimeInfo
} from "../../services/ClubLeaderService";
import {UserContext} from "../../contexts/UserContext";
import {RouteComponentProps, useHistory} from "react-router-dom";
import RsvpAttendanceList from "../../components/clubLeaderEvent/RsvpAttendanceList";
import ManualRsvp from "../../components/clubLeaderEvent/ManualRsvp";
import {Instant, ZonedDateTime} from "@js-joda/core";
import Loading from "../../components/common/Loading";
import LoadingMessage from "../../components/common/LoadingMessage";
import {getClubImageUrl, isEventTypeSelfGuided, useIsMountedRef} from "../../util/Util";
import {getUsersClub} from "../../services/UserService";
import RsvpAttendanceListSelfGuided
    from "../../components/clubLeaderEvent/RsvpAttendanceListSelfGuided";

interface ViewEventPageMatchParams {
    eventUrlFragment: string;
}

export default function ClubLeaderViewEvent({match, history}: RouteComponentProps<ViewEventPageMatchParams>): JSX.Element {
    const { t } = useTranslation('pages');
    const {user, authenticatedFetch} = useContext(UserContext);
    const eventUrlFragment = match.params.eventUrlFragment;

    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [loggedOut, setLoggedOut] = useState(false);
    const [eventAndClub, setEventAndClub] = useState<GetClubLeaderEventAndClubResponseBody | null>(null);
    const [isPast, setIsPast] = useState(false);

    const event = (eventAndClub === null) ? null : eventAndClub.event;
    const club = (eventAndClub === null) ? null : eventAndClub.club;

    const eventTimeInfo = (event === null) ? null : getEventTimeInfo(event);

    // ClickHandler to pass to the components.
    function onClick(e: MouseEvent) {
    }

    const isMounted = useIsMountedRef();

    useEffect(
        () => {
            const loadEventAndClubFromApi = async () => {
                if (user === null) {
                    setLoadingMessage(t('clubLeaderViewEvent.signInError'));
                    setLoggedOut(true);
                    return;
                }

                window.scrollTo(0, 0);

                const config = await loadConfig();

                const eventAndClubRequest =
                    new Request(
                        `${config.apiOrigin}/club-leader/events/get-with-club/${encodeURIComponent(eventUrlFragment)}`,
                        {method: "GET", headers: {"Accept": "application/json"}});

                const eventAndClubResponse = await authenticatedFetch(eventAndClubRequest);

                if (eventAndClubResponse === null) {
                    setLoadingMessage(t('clubLeaderViewEvent.signInError'));
                    setLoggedOut(true);
                    return;
                }

                if (!eventAndClubResponse.ok) {
                    const responseBodyText = await eventAndClubResponse.text();
                    console.error(
                        `Non-successful response from API: `
                        + `${eventAndClubResponse.status} ${eventAndClubResponse.statusText} `
                        + `from ${eventAndClubResponse.url}\n\n${responseBodyText}`);
                    if (eventAndClubResponse.status === 403) {
                        history.replace('/');
                    }
                    setLoadingMessage(t('clubLeaderViewEvent.signInError'));
                    return;
                }

                const eventAndClub: GetClubLeaderEventAndClubResponseBody = await eventAndClubResponse.json();

                const myClub = await getUsersClub(eventAndClub.club.urlFragment);

                let isLeader = myClub !== undefined;

                if (!isLeader) {
                    setLoadingMessage(null);
                    window.location.pathname = "/my-club";
                    return;
                }

                setEventAndClub(eventAndClub);
                setLoadingMessage(null);

                if (eventAndClub.event !== null) {
                    let now = ZonedDateTime.now().toInstant();
                    let startsAt: Instant = Instant.parse(eventAndClub.event.startsAtInstant);
                    if (startsAt.isAfter(now)) {
                        setIsPast(false);
                    } else {
                        setIsPast(true);
                    }
                }

            };

            setLoadingMessage(null);
            setLoading(true);
            loadEventAndClubFromApi().then(() => {
                setLoading(false)
            });
        },
        [user, authenticatedFetch, eventUrlFragment, isMounted, t]);

    return (
        <div className="ClubLeaderViewEventPage">
            {loading && <Loading className="PageLoading" loading={loading}/>}
            {loadingMessage !== null && (
                <LoadingMessage className={"PageLoadingMessage"} showLoginLink={loggedOut}
                                message={loadingMessage}/>
            )}
            {!loading
            && loadingMessage === null
            && event !== null
            && club !== null
            && eventTimeInfo !== null && (
                <>
                    <BackLink to={`/my-club/${club.urlFragment}`}>{t('clubLeaderViewEvent.backTo')} {club.clubName}</BackLink>
                    <EventViewWithDetails eventName={event.eventName}
                                          eventDate={eventTimeInfo.startsAtNativeJsDate}
                                          buttonText={t('clubLeaderViewEvent.eventButtonText')}
                                          organizationName={club.organization?.organizationName ?? undefined}
                                          className={`EventView`}
                                          isClubVirtual={club.isVirtual}
                                          attendeeCount={event.attendeeCount}
                                          rsvpCount={event.rsvpCount}
                                          cancelled={event.status === EventStatus.CANCELLED}
                                          eventUrlFragment={event.urlFragment}
                                          clubImage={getClubImageUrl(club.passion).standard}
                                          eventStartDate={eventTimeInfo.startsAtNativeJsDate}
                                          eventEndDate={eventTimeInfo.endsAtNativeJsDate}
                                          clubDescription={club.description}
                                          eventDescription={event.description}
                                          importantDetails={event.importantDetails}
                                          eventLeaderFirstName={event.leaderFirstName}
                                          eventLeaderLastName={event.leaderLastName}
                                          eventLeaderEmail={event.leaderEmail}
                                          locationName={event.location?.locationName ?? ""}
                                          locationAddress1={event.location?.streetAddressOne ?? ""}
                                          locationAddress2={event.location?.streetAddressTwo ?? ""}
                                          locationCity={event.location?.city ?? ""}
                                          locationState={event.location?.countrySubdivision ?? ""}
                                          locationZip={event.location?.postalCode ?? ""}
                                          isVirtual={event.isVirtual}
                                          virtualEventDetails={event.virtualEventDetails === null ? undefined : event.virtualEventDetails}
                                          timeZone={event.timeZone}
                                          isRecurring={event.isRecurring}
                                          recurrence={event.recurrence}
                                          occurrences={event.occurrences}
                                          clubName={club.clubName}
                                          clubUrlFragment={club.urlFragment}
                                          eventTypeOne={event.typeOne}
                                          eventTypeTwo={event.typeTwo}
                                          buttonClickHandler={onClick}
                                          clubUrl={event.externalUrl}/>
                    {!(event.status === EventStatus.CANCELLED)
                    && isEventTypeSelfGuided(event.typeOne)
                    &&
                    <>
                        <RsvpAttendanceListSelfGuided className={"RsvpAttendanceList"}
                                    eventUrlFragment={event.urlFragment}/>
                    </>}
                    {!(event.status === EventStatus.CANCELLED)
                    && !isEventTypeSelfGuided(event.typeOne)
                    && !isPast
                    &&
                    <>
                        <ManualRsvp className={"ClubLeaderViewEvent_ManualRsvp"}
                                    eventUrlFragment={event.urlFragment}/>
                    </>}
                    {!(event.status === EventStatus.CANCELLED)
                    && !isEventTypeSelfGuided(event.typeOne)
                    && isPast
                    &&
                    <RsvpAttendanceList
                        eventUrlFragment={event.urlFragment}
                        className="RsvpAttendanceList"/>}
                </>
            )}
        </div>
    );
}

