import { useState } from "react";
import postCreateClub from "../api/postCreateClub";

interface ISetupClubOptionsArg {
    onSuccessCallback: (value: { clubId: number; } | undefined) => { clubId: number; } | PromiseLike<{ clubId: number; } | undefined> | undefined,
    onFailCallback: (reason: any) => PromiseLike<never>
}

const useClubs = (): [Function, boolean] => {
    const [isLoading, setIsLoading] = useState(false)

    const createClub = async (body: any, { onSuccessCallback, onFailCallback }: ISetupClubOptionsArg) => {
        setIsLoading(true)

        return await postCreateClub(body)
            .then(onSuccessCallback)
            .catch(onFailCallback)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return [createClub, isLoading]
}

export default useClubs;
