import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import {UserState} from "../../contexts/UserContext";
import {IImportedEvent, IImportResult, IValidation} from "./EventImportWizard";
import ImportedEventPreview from "./ImportedEventPreview";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch, logoutAll} from "../../services/UserService";
import OkModal from "../common/OkModal";
import Loading from "../common/Loading";
import {useHistory} from "react-router-dom";

export default function EventImportReview(props: { clubUrlFragment: string, UserState: UserState, ImportResult: IImportResult, StepSetter: Function, ImportResultSetter: Function }): JSX.Element {
    const { t } = useTranslation('eventImportWizard');
    const history = useHistory();
    let clubUrlFragment = props.clubUrlFragment;
    let importResult: IImportResult = props.ImportResult;
    let setStep: Function = props.StepSetter;
    let setImportResult = props.ImportResultSetter;

    const [errors, setErrors] = useState<number>(0);
    const [eventIndex, setEventIndex] = useState<number>(-1);
    const [eventToShow, setEventToShow] = useState<IImportedEvent | null>(null);
    const [isPreviousDisabled, setPreviousDisabled] = useState<boolean>(false);
    const [isNextDisabled, setNextDisabled] = useState<boolean>(false);
    const [isAcceptAllDisabled, setAcceptAllDisabled] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showLoggedOutModal, setShowLoggedOutModal] = useState(false);
    const [isNextWithErrorsDisabled, setNextWithErrorsDisabled] = useState(true);
    const [saving, setSaving] = useState(false);

    function next() {
        let newIndex = eventIndex + 1;
        let newEventToShow = importResult.events[newIndex];
        setEventIndex(newIndex);
        setEventToShow(newEventToShow);
    }

    function nextWithError() {
        let newIndex = eventIndex + 1;
        let errorFound = false;
        let i = 0;
        for (i = newIndex; i < importResult.events.length; i++) {
            let event = importResult.events[i];
            for (let z = 0; z < event.validationResults.length; z++) {
                let validation = event.validationResults[z];
                if (!errorFound && !validation.success) {
                    newIndex = i;
                    errorFound = true;
                }
            }
        }
        if (!errorFound) {
            setNextWithErrorsDisabled(true);
        } else {
            let newEventToShow = importResult.events[newIndex];
            setEventIndex(newIndex);
            setEventToShow(newEventToShow);
        }
    }

    function previous() {
        let newIndex = eventIndex - 1;
        let newEventToShow = importResult.events[newIndex];
        setEventIndex(newIndex);
        setEventToShow(newEventToShow);
    }

    async function accept() {
        if (importResult === null) {
            return;
        }

        const config = await loadConfig();
        const url = `${config.apiOrigin}/eventimport/steptwo/${clubUrlFragment}`;
        const request = new Request(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                'events': importResult.events,
                'attachmentsZipFileS3Key': importResult.attachmentsZipFileS3Key
            })
        });

        setSaving(true);

        const response = await authenticatedFetch(request);

        setSaving(false);

        if (response === null) {
            await logoutAll();
            setShowLoggedOutModal(true);
            return;
        }

        if (!response.ok) {
            const responseBodyText = await response.text();
            setShowErrorModal(true);
            console.error(
                `Non-successful response from API: `
                + `${response.status} ${response.statusText} `
                + `from ${response.url}\n\n${responseBodyText}`);
            return;
        } else {
            let confirmResult: IImportResult | null = null;
            try {
                const responseBodyText = await response.text();
                confirmResult = JSON.parse(responseBodyText);
            } catch (e) {
                console.log(e);
            }
            if (confirmResult === null) {
                // dang
            } else {
                setStep(2);
            }
        }
    }

    function goBack() {
        setImportResult(null);
        setStep(0);
    }

    useEffect(() => {
        if (importResult === null || importResult.events === null) {
            return;
        }
        setPreviousDisabled(eventIndex <= 0);
        setNextDisabled((eventIndex + 1) >= importResult.events.length);
        let errors = 0;
        importResult.events.forEach(function (event) {
            let invalidFields: any = {};
            event.validationResults.forEach(function (validation: IValidation) {
                if (!validation.success) {
                    if (!invalidFields.hasOwnProperty(validation.displayName)) {
                        invalidFields[validation.displayName] = true;
                        errors += 1;
                    }
                }
            });
        });
        if (errors > 0) {
            setNextWithErrorsDisabled(false);
        } else {
            setNextWithErrorsDisabled(true);
        }
        setErrors(errors);
        setAcceptAllDisabled(errors > 0);
    }, [eventIndex, importResult]);

    if (eventIndex === -1) {
        let newIndex = eventIndex + 1;
        let newEventToShow = importResult.events[newIndex];
        setEventIndex(newIndex);
        setEventToShow(newEventToShow);
    }

    function onModalClick() {
        setShowErrorModal(false);
    }

    async function onLoggedOutModalClick() {
        setShowLoggedOutModal(false);
        history.push("/login", {'referrer': null});
    }

    const previousButtonText = t('eventImportReview.previousButtonText');
    const nextButtonText = t('eventImportReview.nextButtonText');
    const nextEventWithErrorsButtonText = t('eventImportReview.nextEventWithErrorsButtonText');
    const acceptAllButtonText = t('eventImportReview.acceptAllButtonText');

    return (
        <>
            {saving && <Loading loading={saving}/>}
            {!saving &&
            <>
                <div className={`PreviewEventsWrapper PositionRelative`}>
                    {showLoggedOutModal && <>
                        <OkModal shown={showLoggedOutModal} okClickHandler={onLoggedOutModalClick}>
                            {t('eventImportReview.sessionEnded')}
                        </OkModal>
                    </>}
                    {!showLoggedOutModal && showErrorModal && <>
                        <OkModal shown={showErrorModal} okClickHandler={onModalClick}>
                            {t('eventImportReview.unexpectedError')}
                        </OkModal>
                    </>}
                    {!showLoggedOutModal && !showErrorModal && <>
                        <h1>{t('eventImportReview.previewHeader')}</h1>
                        <hr className="ThinDivider"/>
                        <div className="PreviewInstructions" dangerouslySetInnerHTML={{__html: t('eventImportReview.previewInstructions')}}></div>
                        <div className="PreviewEvents">
                            <input type="Button"
                                   readOnly
                                   className={`SmallButton PreviousButton BlueBackground WhiteForeground ${isPreviousDisabled && 'Disabled'}`}
                                   onClick={previous} value={previousButtonText}
                                   disabled={isPreviousDisabled}/>
                            <b>{t('eventImportReview.indexCounter', {index: eventIndex + 1, total: importResult != null && importResult.events.length})}</b>
                            <input readOnly type="Button"
                                   className={`SmallButton NextButton BlueBackground WhiteForeground ${isNextDisabled && 'Disabled'}`}
                                   onClick={next} value={nextButtonText} disabled={isNextDisabled}/>
                            <input readOnly type="Button"
                                   className={`SmallButton NextButton BlueBackground WhiteForeground ${isNextWithErrorsDisabled && 'Disabled'}`}
                                   onClick={nextWithError} value={nextEventWithErrorsButtonText}
                                   disabled={isNextWithErrorsDisabled}/>
                            <div className="PositionRelative" style={{top: '.4em'}}>
                                <b>{importResult != null && importResult.events.length}</b> {t('eventImportReview.eventCount')}
                                <span
                                    className={`PositionRelative Bold ${errors === 0 && 'DarkGreenForeground'} ${errors > 0 && 'DarkRedForeground'}`}
                                    style={{left: '10px'}}>{errors} {t('eventImportReview.errorCount')}</span>
                            </div>
                            <hr className="ThinDivider"
                                style={{marginTop: '10px', marginBottom: '10px'}}/>
                            <div id="uploadEvents">
                                <div className="eventUploadPreview">
                                    {eventToShow != null &&
                                    <ImportedEventPreview EventIndex={eventIndex}
                                                          ImportedEvent={eventToShow}/>}
                                    <div className={`StillNeedHelp`} dangerouslySetInnerHTML={{__html: t('eventImportReview.stillNeedHelp')}}></div>
                                </div>
                            </div>
                        </div>
                        <hr className="ThinDivider"/>
                        <input readOnly type="Button" disabled={isAcceptAllDisabled}
                               className={`Button BlueBackground WhiteForeground DisplayBlock MarginAuto ${isAcceptAllDisabled && 'Disabled'}`}
                               onClick={accept} value={acceptAllButtonText}/>
                        <span className="DisplayBlock MarginAuto Centered PositionRelative"
                              style={{
                                  fontWeight: 'bold',
                                  top: '1em',
                                  cursor: 'pointer',
                                  color: '#0091ea'
                              }}
                              onClick={goBack}>{t('eventImportReview.reviseUploadText')}</span>
                    </>}
                </div>
            </>
            }
        </>
    )
}
