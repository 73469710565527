import React from "react";
import {ContinueButton, ContinueButtonProps} from "./ContinueButton";
import {useTranslation} from "react-i18next";

interface RegisterButtonProps extends ContinueButtonProps {
}

export function RegisterButton(props: RegisterButtonProps): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <ContinueButton {...props}
                        text={t('registration.registerButton')} />
    )
}