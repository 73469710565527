import { useState } from "react";
import postResetPassword from "../api/postResetPassword";

const useResetPassword = (): [boolean, (email: string) => void] => {
    const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] = useState(false)

    const resetPassword = (email: string) => {
        postResetPassword(email).then(
            isSuccess => {
                setIsPasswordResetSuccessful(isSuccess)
            }
        ).catch(error => console.log(error))
    }

    return [isPasswordResetSuccessful, resetPassword]
}

export default useResetPassword
