import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const CHECK_ELIGIBILITY_URL = '/ignite/check-eligibility'

const postCheckEligibility = async (body: any): Promise<{ id: number } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${CHECK_ELIGIBILITY_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })

    return authenticatedFetch(request)
        .then(response => response?.json())
}

export default postCheckEligibility
