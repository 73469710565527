import React from "react";
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import heartSrc from '../../../assets/ignite/heart.jpg'
import { useHistory, useParams, Link } from "react-router-dom";
import { IGNITE_ROUTE_KEY } from '../../../constants/routes'

import './MedicareAdvantagePayerQualification.scss'
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { CLUB_ROLES_STRINGS } from "../../../constants/dashboard";
import useMyClubStatus from "../../../hooks/useMyClubStatus";

const MedicareAdvantagePayerQualification = (): JSX.Element => {
    const { t } = useTranslation('ignite/payerSelection');
    const history = useHistory()
    const { clubId } = useParams()
    const myClubStatus = useMyClubStatus({ id: Number(clubId) })
    const shouldRedirectToLeaderDashboard = myClubStatus === CLUB_ROLES_STRINGS.LEADER || myClubStatus === CLUB_ROLES_STRINGS.ADMIN
    const dashboardRoute = shouldRedirectToLeaderDashboard ? getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': clubId }) :
        getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': clubId })

    return (
                <div className="ignite-page-content payer-qualification">
                    <div className="title-wrapper">
                        <div className="title-wrapper__image_wrapper">
                            <img src={heartSrc} alt="" className="title-wrapper__image" />
                        </div>
                        <div className="title-wrapper__text">
                            {t('startEarningMoney')}
                        </div>
                    </div>
                    <div className="button-block">
                        <Button
                            className='select-payer'
                            variant="outlined"
                            onClick={() => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_SELECTION, { ':clubId': clubId }))}
                        >
                            {t('selectPayer')}
                            <PlayIcon className='select-payer__icon' />
                        </Button>
                    </div>
                    <div className="bottom-text" dangerouslySetInnerHTML={{ __html: t('youCanDoThisLate') }} />
                    <Link
                        className='skip-for-now'
                        to={dashboardRoute}
                    >
                        {t('skipForNow')}
                    </Link>
                </div>
    )
}

export default MedicareAdvantagePayerQualification
