import { useState, useEffect } from "react";
import { getClubV2ById } from '../api/getClubV2ById'
import { IClubV2 } from '../types/clubFinder';

const useGetClubV2ById = (id: number): [IClubV2 | undefined, boolean] => {
    const [club, setClub] = useState<IClubV2 | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getClubV2ById(id)
            .then(data => setClub(data))
            .finally(() => {
                setIsLoading(false)
            })
    }, [id])

    return [club, isLoading]
}

export default useGetClubV2ById
