import React, {useContext, useEffect, useState} from "react";
import "./ClubMemberRegistrationWizard.scss";
import {RouteComponentProps} from "react-router-dom";
import {useFormState} from "react-use-form-state";
import {SelectedPayerContext} from "../../../contexts/SelectedPayerContext";
import {MemberName, MemberNameStep} from "../steps/MemberNameStep";
import {MemberPersonalInformation, MemberPersonalInformationStep} from "../steps/memberPersonalInformationStep/MemberPersonalInformationStep";
import {MemberAccount, MemberAccountStep} from "../steps/MemberAccountStep";

//Adding for Registration Submission - Refactor later
import {MemberRegistrationParameters, registerMember} from "../../../services/UserService";
import {UserContext} from "../../../contexts/UserContext";

import WizardProgress from "../wizardProgress/WizardProgress";
import { sendPageView } from "../../../withTracker";
import { UserRegistrationFlow } from "../../../services/Models";
import { readUtmRegistrationParametersFromSession } from "../../../util/Util";

/**
 * The first step in the club member registration process in which the user selects
 * their payer and enters their insurance id.
 *
 * The user arrives here from the `ClubMemberLandingPage`.
 */
export default function ClubMemberRegistrationWizard(props: RouteComponentProps): JSX.Element {

    //////////////////////////////////////////////////////
    const {login} = useContext(UserContext);
    //////////////////////////////////////////////////////
    const {history} = props;

    const initialState = {
        payerId: 0,
        insuranceId: "",
        firstName: "",
        lastName: "",
        phone: "",
        zip: "",
        dateOfBirth: ""
    }

    async function postSubmit(parameters: MemberRegistrationParameters) {
        setPayerDetails(null);

        sendPageView(
            `${history.location.pathname}?step=complete`,
            ` - Club Member Registration Complete`);

        const searchParams = new URLSearchParams();
        searchParams.set("justRegistered", "true");
        searchParams.set("postalCode", parameters.zipCode);
        history.push(`/find-an-event?${searchParams.toString()}`);
    }

    // for capturing state of each wizard step...
    const [memberName, setMemberName] = useState<MemberName>({firstName:"", lastName:""});
    const [memberPersonalInformation, setMemberPersonalInformation] = useState<MemberPersonalInformation>({
        payerId: "",
        insuranceId: "",
        zipCode: "",
        phone:"",
        dateOfBirth: "",
        healthPlanName: null
    });
    const [memberAccount, setMemberAccount] = useState<MemberAccount>({email:"", password:"", termsOfUse: false});

    const [formState] = useFormState(initialState);

    // steps in the registration flow provided by Erick
    enum WizardSteps {
        FullName,
        PersonalInformation,
        Account
    };

    const steps = [
        WizardSteps.FullName,
        WizardSteps.PersonalInformation,
        WizardSteps.Account
    ];

    // current wizard step
    let [currentStepPosition, setCurrentStepPosition] = useState(0);
    const [wizardStep, setWizardStep] = useState(steps[currentStepPosition]);

    useEffect(() => {
        sendPageView(
            `${history.location.pathname}?step=${wizardStep.valueOf() + 1}`,
            ` - Club Member Registration Step ${wizardStep.valueOf() + 1}`);
    }, [wizardStep]);

    const {setPayerDetails} = useContext(SelectedPayerContext);

    // "continue" selected from Wizard sections; just changes the current
    async function onContinueSelected(data: any) {
        function moveToStep (targetStepPosition: number) {
            setCurrentStepPosition(targetStepPosition);
            setWizardStep(steps[targetStepPosition]);
        }

        switch(wizardStep) {
            case WizardSteps.FullName:
                setMemberName({...data});
                formState.setField('firstName', data.firstName);
                formState.setField('lastName', data.lastName);

                moveToStep(currentStepPosition + 1);

                break;
            case WizardSteps.PersonalInformation:
                setMemberPersonalInformation({...data});
                formState.setField('payerId', data.payerId);
                formState.setField('insuranceId', data.insuranceId);
                formState.setField('healthPlanName', data.healthPlanName);
                formState.setField('phone', data.phone);
                formState.setField('zip', data.zip);
                formState.setField('dateOfBirth', data.dateOfBirth);

                moveToStep(currentStepPosition + 1);

                break;
            case WizardSteps.Account: // this would be the final "submit" button; handle this a bit differently
                await onRegister({...data});
                break;
            default:
                moveToStep(0);
          }
    }

    // "previous" selected from Wizard sections; just changes the current step to the previous step
    function onPreviousSelected() {
        function moveToStep (targetStepPosition: number) {
            setCurrentStepPosition(targetStepPosition);
            setWizardStep(steps[targetStepPosition]);
        }

        switch(wizardStep) {
            case WizardSteps.PersonalInformation:
                moveToStep(steps.indexOf(WizardSteps.PersonalInformation) - 1);
                break;
            case WizardSteps.Account:
                moveToStep(steps.indexOf(WizardSteps.Account) - 1);
                break;
            default:
                moveToStep(0);
            }
    }

    // TODO - here we gather all the data and register like the current RegistrationForm.tsx does it
    async function onRegister(data: any) {
        const parameters : MemberRegistrationParameters = {
            email: data.email,
            phone: formState.values.phone,
            password: data.password,
            firstName: formState.values.firstName,
            lastName: formState.values.lastName,
            zipCode: formState.values.zip,
            renewId: formState.values.insuranceId,
            healthPlanName: formState.values.healthPlanName,
            dateOfBirth: formState.values.dateOfBirth,
            payerId: formState.values.payerId,
            ...readUtmRegistrationParametersFromSession(),
            registrationFlow: UserRegistrationFlow.PROJECT_LIGHTNING_BOLT
        };
        await registerMember(parameters)

        await login(data.email, data.password)

        postSubmit(parameters)
    }

    function renderFullName() {
        return (
            <div className="ClubMemberRegistrationWizard">
                <MemberNameStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberNameStep>
                <WizardProgress currentStep={currentStepPosition + 1} totalSteps={steps.length}></WizardProgress>
            </div>
        );
    }

    function renderPersonalInformation() {
        return (
            <div className="ClubMemberRegistrationWizard">
                <MemberPersonalInformationStep firstName={memberName.firstName} onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberPersonalInformationStep>
                <WizardProgress currentStep={currentStepPosition + 1} totalSteps={steps.length}></WizardProgress>
            </div>
        );
    }

    function renderAccount() {
        return (
            <div className="ClubMemberRegistrationWizard">
                <MemberAccountStep onContinue={onContinueSelected} onPrevious={onPreviousSelected}></MemberAccountStep>
                <WizardProgress currentStep={currentStepPosition + 1} totalSteps={steps.length}></WizardProgress>
            </div>
        );
    }

    function renderWizardStep() {
        switch(wizardStep) {
          case WizardSteps.FullName:
            return renderFullName();
          case WizardSteps.PersonalInformation:
            return renderPersonalInformation();
          case WizardSteps.Account:
            return renderAccount();
          default:
            return renderFullName();
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return renderWizardStep();
}
