import React, { useState, useEffect } from 'react'; // importing FunctionComponent
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {useFormState} from "react-use-form-state";
import {loadConfig} from "../../../services/ConfigService";
import Loading from "../../../components/common/Loading";
import LoadingMessage from "../../../components/common/LoadingMessage";
import { FormControl } from '@material-ui/core';
import {
    FormHelperText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {
    GetAllPayersResponseBody,
    GetPayerResponseBody
} from "../../../services/MemberService";

export interface MemberPayer {
    payerId: number
    insuranceId: string
  }
  
  type StepProp = {
      onContinue: (data: any) => void,
      onPrevious: () => void
  }

export function MemberSelectPayerStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const initialState = {
        payerId: 4,
        insuranceId: ""
    }

    const [formState, {text, select}] = useFormState(initialState);
    const {payerId, insuranceId} = formState.values;

    const elementConfig = {
        payerId: {
            name: "payerId",
            onChange: () => {
                formState.setField("insuranceId", "");
            },
            validate: (value: string | number): string | undefined => {
                if (typeof value === "number" && allPayers.find(p => p.id === value)) {
                    return;
                }
                return t('centenePayerStep.selectPayer');
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (value === "") {
                    return t('centenePayerStep.enterWellCareId');
                }
            },
            validateOnBlur: true
        }
    }

    const submitEnabled =
        Object.entries(formState.pristine).every(([key, value]) => !value)
        && Object.entries(formState.validity).every(([key, value]) => value);

    const [loading, setLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);

    const selectedPayer = {id: 4, payerName: "Centene" };

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",formState.values);
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = () => {
        props.onContinue({payer: selectedPayer, insuranceId: formState.values.insuranceId});
    }

    useEffect(() => {

        const loadAllPayers = async () => {

            const config = await loadConfig();

            //const request = new Request('https://api.grouper.com/all-payers');
            const request = new Request(`${config.apiOrigin}/all-payers`);

            // This is one of the rare, non-authenticated API endpoints.
            const response = await fetch(request);

            if (!response.ok) {
                const responseBodyText = await response.text();

                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);

                setLoading(false);
                setLoadingErrorMessage(t('centenePayerStep.unexpectedError'));

                return;
            }

            const responseBody: GetAllPayersResponseBody = await response.json();

            setAllPayers(responseBody.allPayers);
            setLoading(false);
            setLoadingErrorMessage(null);

        };

        loadAllPayers();

    }, [t]);

    return (
        <div className={"MemberRegistrationWizard_Wrapper"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                    <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <h2 dangerouslySetInnerHTML={{__html: t('centenePayerStep.wellCareIdPrompt')}}></h2>
                </Grid>
            </Grid>
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <LoadingMessage message={loadingErrorMessage}/>
            )}
            <form className="MemberSelectPayerPage_Form">
                    <Grid container spacing={2} direction="row" alignItems="center" className="MemberSelectPayerPage_Fields">
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={10}>
                            {selectedPayer !== null &&
                            <>
                            <FormControl fullWidth margin="normal">
                                <TextField {...text(elementConfig.insuranceId)}
                                        error={formState.errors.insuranceId !== undefined}
                                        className="MemberSelectPayerPage_InsuranceIdField"
                                        required
                                        label={t('centenePayerStep.wellCardId')}
                                        inputProps={{maxLength: 50}}
                                        variant="outlined"
                                        size="small"
                                />
                                </FormControl>
                            </>
                            }
                        </Grid>
                        <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
                            <Grid item xs={7}></Grid>
                            <Grid item xs={5}>
                                <FormControl>
                                <PlayButton type="button"
                                            clickHandler={onContinueSelected}
                                            disabled={!submitEnabled}
                                            text={t('centenePayerStep.continueButtonText')}
                                            className="MemberSelectPayerPage_SubmitButton"
                                            buttonStyle={ButtonStyles.FilledPrimary}
                                            id="HealthPlanSubmit"
                                />
                                </FormControl>
                            </Grid>
                        </Grid>
                        
                    </Grid>
    
                    {Object.entries(formState.errors).length > 0 &&
                    <div className="MemberSelectPayerPage_FormErrors">
                        {Object.entries(formState.errors).map(([key, value]) =>
                            <div key={key}
                                 className="MemberSelectPayerPage_FormError">
                                {value}
                            </div>)
                        }
                    </div>
                    }
    
                </form>
        </div>
    );
    }
