import React, {ChangeEvent, useState} from "react";
import { useTranslation } from 'react-i18next';

import {Checkbox, FormControlLabel, Modal} from "@material-ui/core";
import AmericasWalkingClubWaiver from "../common/AmericasWalkingClubWaiver";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import "./LiabilityWaiverModal.scss";

/**
 * Properties of the {@link LiabilityWaiverModal} component.
 */
interface LiabilityWaiverModalProps {
    /**
     * `true` if the modal dialog is currently open and visible, `false` if the
     * modal dialog is currently closed and hidden.
     *
     * The {@link onSubmit} and {@link onCancel} functions should be defined
     * such that they set {@link isOpen} to `false`.
     */
    isOpen: boolean

    /**
     * A function invoked when the user agrees to the Liability Waiver by
     * checking the "I agree" checkbox and then clicking the "Count Me In!"
     * submit button.
     */
    onSubmit: () => void

    /**
     * A function invoked when the user attempts to dismiss the modal dialog by
     * clicking the "Cancel" button, clicking outside of the modal dialog, or
     * pressing the escape key.
     */
    onCancel: () => void
}

/**
 * A modal dialog displaying a Liability Waiver for self-guided events.  The
 * dialog contains a form with a mandatory "I agree" checkbox.
 */
export default function LiabilityWaiverModal(props: LiabilityWaiverModalProps): JSX.Element {
    const { t } = useTranslation('liability');
    const [agreed, setAgreed] = useState(false);

    function onChangeAgreed(event: ChangeEvent<HTMLInputElement>) {
        setAgreed(event.target.checked);
    }

    function onSubmitForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        props.onSubmit();
    }

    return (
        <Modal className="LiabilityWaiverModal" open={props.isOpen} onClose={props.onCancel}>
            <form className="LiabilityWaiverModal_Form" onSubmit={onSubmitForm}>
                <div className="LiabilityWaiverModal_PleaseNote">
                    {t('liabilityWaiverModal.note')}
                </div>
                <div className="LiabilityWaiverModal_IAgree">
                    <FormControlLabel
                        label={t('liabilityWaiverModal.agree')}
                        control={
                            <Checkbox required checked={agreed} onChange={onChangeAgreed}/>
                        }
                    />
                </div>
                <div className="LiabilityWaiverModal_WaiverContent">
                    <AmericasWalkingClubWaiver />
                </div>
                <div className="LiabilityWaiverModal_Buttons">
                    <PlayButton
                        text={t('liabilityWaiverModal.countMeInButtonText')}
                        type="submit"
                        className="LiabilityWaiverModal_CountMeInButton"
                        buttonStyle={ButtonStyles.FilledPrimary}/>
                    <PlayButton
                        text={t('liabilityWaiverModal.cancelButtonText')}
                        clickHandler={props.onCancel}
                        className="LiabilityWaiverModal_CancelButton"
                        buttonStyle={ButtonStyles.FilledSecondary}/>
                </div>
            </form>
        </Modal>
    );
}
