import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./FindAnEventPage.scss";
import { RouteComponentProps } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import FindAnEventForm, {
    EventView,
    ParentPage
} from "../../components/findAnEvent/FindAnEventForm";
import { GetPassionsResponseBody } from "../../services/MemberService";
import {
    getAllPassions,
    GetAllPassionsResponseBody
} from "../../services/ClubLeaderService";
import SelectPassions from "../../components/findAnEvent/SelectPassions";
import { getFavoritePassions } from "../../services/MyProfileService";
import { GetPassionResponseBody } from "../../services/Models";
import HaveQuestions from "../../components/haveQuestions/HaveQuestions";
import EventSearchResults from "../../components/event/EventSearchResults";
import FeaturedEvents from "../../components/event/FeaturedEvents";
import SectionHeader from "../../components/common/SectionHeader";
import Button, { ButtonStyles } from "../../components/common/Button";
import EligibilityStatusModal from "../../components/eligibilityStatusModal/EligibilityStatusModal";
import {
    EventSearchResult,
    searchEvents,
    searchEventsAndSeparateOccurrences,
    startOfCurrentOrSoonMonthDateTime
} from "../../services/EventSearchService";
import { User } from "../../services/UserService";
import Loading from "../../components/common/Loading";
import {FastForward, FastRewind, PlayArrow} from "@material-ui/icons";
import { useFeatureFlag } from "../../services/FeatureFlagService";
import { USE_EDP_44_VIRTUAL_CLUBS } from "../../constants/flags";
import { DateTimeParseException, LocalDate, LocalTime, ZoneId, ZonedDateTime, use } from "@js-joda/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocalDateUtils } from "../../util/FixedJsJodaUtils";
import { Locale } from "@js-joda/locale_en-us";

export enum EventSearchSort {
    EventStartsAt = 0,
    Distance = 1,
    Default = 2
}

export interface EventSearchParams {
    postalCode: string | null;
    virtualOnly: boolean;
    distance: number;
    view: EventView;
    passionIds: number[];
    skip: number | null;
    limit: number | null;
    sort: EventSearchSort | null;
    reverse: boolean | null;
}

export interface EventSearchResponseBody {
    postalCode: string;
    skip: number;
    limit: number;
    sort: EventSearchSort;
    reverse: boolean;
    totalResultCount: number;
    results: EventSearchResult[];
    anyEventIsVirtual: boolean;
}

function defaultMonth(searchParams: URLSearchParams): number {
    const monthParam = searchParams.get("month");
    return monthParam ? parseInt(monthParam, 10) : new Date().getMonth()
}

function defaultStartDate(searchParams: URLSearchParams): string | null {
    const startDateParam = searchParams.get("date");
    return startDateParam ?? null;
}

function defaultPostalCode(searchParams: URLSearchParams, user: User | null): string | null {
    const postalCodeFromParams = searchParams.get("postalCode");
    return postalCodeFromParams ?? user?.postalCode ?? null;
}

function readDistance(searchParams: URLSearchParams): number | null {
    const distanceStr = searchParams.get("distance");
    if (distanceStr !== null) {
        return parseInt(distanceStr, 10);
    }
    return null;
}

function defaultVirtualOnly(searchParams: URLSearchParams): boolean {
    return searchParams.get("virtualOnly") === "true";
}

function defaultPassionIds(searchParams: URLSearchParams): number[] {
    const passionIdsStr = searchParams.get("passionIds")
    if (!passionIdsStr) {
        return [];
    } else {
        return passionIdsStr.split("+").map(str => parseInt(str));
    }
}

export const DISTANCE_OPTIONS = [
    5,
    10,
    25,
    50,
]
export const DEFAULT_DISTANCE = DISTANCE_OPTIONS[1];

export default function FindAnEventPage({location, history}: RouteComponentProps): JSX.Element {
    const searchParams = new URLSearchParams(location.search);
    const { t } = useTranslation('pages');
    const {user, authenticatedFetch} = useContext(UserContext);

    const useEdp44VirtualClubs = useFeatureFlag(USE_EDP_44_VIRTUAL_CLUBS, false);

    const [loading, setLoading] = useState<boolean>(true);
    const [resultsLoading, setResultsLoading] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [searchingErrorMessage, setSearchingErrorMessage] = useState<string | null>(null);
    const [allPassions, setAllPassions] = useState<GetPassionResponseBody[]>([]);
    const [defaultLimit, setDefaultLimit] = useState<number | null>(10);
    const [ignoreLocationUpdate, setIgnoreLocationUpdate] = useState<boolean>(false);
    const [searchParamsLoaded, setSearchParamsLoaded] = useState<boolean>(false);
    const [passionsSelected, setPassionsSelected] = useState<boolean>(false);

    const [savedPassionIds, setSavedPassionIds] = useState<number[]>([]);
    const [selectedPassionIds, setSelectedPassionIds] = useState<number[]>(defaultPassionIds(searchParams));
    const [month, setMonth] = useState<number>(defaultMonth(searchParams));
    const [startDate, setStartDate] = useState<string | null>(defaultStartDate(searchParams));
    const [endDate, setEndDate] = useState<string | null>(null);
    const [postalCode, setPostalCode] = useState<string | null>(defaultPostalCode(searchParams, user));
    const [distance, setDistance] = useState<number>(readDistance(searchParams) || DEFAULT_DISTANCE);
    const [virtualOnly, setVirtualOnly] = useState<boolean>(defaultVirtualOnly(searchParams));
    const [sort, setSort] = useState<EventSearchSort>(EventSearchSort.Default);
    const [reverse, setReverse] = useState<boolean>(false);
    const [view, setView] = useState<EventView>(EventView.LIST);
    const [skip, setSkip] = useState<number | null>(null);
    const [limit, setLimit] = useState<number | null>(null);

    const [searchStartDate, setSearchStartDate] = useState<LocalDate | DateTimeParseException | null>(LocalDate.now());
    const [searchEndDate, setSearchEndDate] = useState<LocalDate | DateTimeParseException | null>(LocalDate.now().plusMonths(1));

    const [searchResponseBody, setSearchResponseBody] = useState<EventSearchResponseBody | null>(null);

    const DAY_IN_MILLIS = 86400000;
    const currentDate = Date.now();
    const isPreviousDayButtonEnabled =
        view !== EventView.MONTH
        && !resultsLoading
        // Check to make sure startDate > than tomorrow's date
        && startDate !== null && (parseInt(startDate, 10) > currentDate);

    const isPreviousButtonEnabled =
        searchResponseBody !== null
        && searchResponseBody.skip > 0
        && view !== EventView.MONTH
        && !resultsLoading;

    const isTodayButtonEnabled =
        searchResponseBody !== null
        && view !== EventView.MONTH
        && !resultsLoading
        // Check to make sure startDate > than tomorrow's date
        && startDate !== null && (parseInt(startDate, 10) > currentDate);

    const isNextButtonEnabled =
        searchResponseBody !== null
        && searchResponseBody.skip + searchResponseBody.limit < searchResponseBody.totalResultCount
        && view !== EventView.MONTH
        && !resultsLoading;

    const isNextDayButtonEnabled =
        view !== EventView.MONTH
        && !resultsLoading
        && startDate !== null;

    const usingFilters = virtualOnly || !!selectedPassionIds.length || startDate !== null;

    window.scrollTo(0,0);

    const sortByEventStartsAt = () => sortBy(EventSearchSort.EventStartsAt);
    const sortByDistance = () => sortBy(EventSearchSort.Distance);
    const sortBy = (newSort: EventSearchSort) => search({
        sort: newSort,
        reverse: newSort === sort ? !reverse : false,
        skip: null
    });
    const updateMonth = (newMonth: number) => {
        // For some reason react kicks off a state change even if the month
        // didn't change, which leads to a loop.
        if (newMonth === month) {
            return
        }
        search({ month: newMonth })

    }
    const onClickPreviousDayButton = () => {
        const currentStartDate = startDate !== null ? parseInt(startDate, 10) : null;
        const newStartDate = currentStartDate !== null ? currentStartDate - DAY_IN_MILLIS : null;
        search({
            skip: 0,
            startDate: newStartDate !== null ? newStartDate.toString() : null,
            endDate: newStartDate !== null ? (newStartDate + DAY_IN_MILLIS).toString() : null,
        });
    };
    const onClickPreviousButton = () => search({
        skip: Math.max((skip ?? 0) - (limit ?? 0), 0)
    });
    const onClickTodayButton = () => search({
        skip: 0,
        startDate: null
    });
    const onClickNextButton = () => search({
        skip: (skip ?? 0) + (limit ?? 0)
    });
    const onClickNextDayButton = () => search({
        skip: 0,
        startDate: startDate !== null ? (parseInt(startDate, 10) + DAY_IN_MILLIS).toString() : null,
        endDate: startDate !== null ? (parseInt(startDate, 10) + DAY_IN_MILLIS + DAY_IN_MILLIS).toString() : null,
    });
    const resetFilters = () => search({
        passionIds: [],
        virtualOnly: false,
        startDate: null,
        endDate: null
    });
    
    
    const viewVirtualOnly = () => search({
        passionIds: [],
        virtualOnly: true,
        startDate: null,
        endDate: null
    });
    const updateView = (view: EventView) => search({
        skip: null,
        limit: null,
        startDate: null,
        endDate: null,
        view
    });
    const updateVirtualOnly = (virtualOnly: boolean) => search({
        virtualOnly,
        skip: view === EventView.LIST ? 0 : undefined
    });
    const updatePostalCodeAndDistance = (postalCode: string | null, distance: number) =>
        search({
            postalCode,
            distance
        });
    const submitSearch = () => search({ skip: null });

    function isSearchStartDateError() {
        return !searchStartDate || !(searchStartDate instanceof LocalDate) || searchStartDate < LocalDate.now();
    }

    function searchStartDateErrorText() {
        if (!searchStartDate) {
            return t("virtualClubsClubDetailPage.requiredLabel");
        }
        else if (searchStartDate.toString() < LocalDate.now().toString()) {
            return t("virtualClubsClubDetailPage.pastDateError");
        }
        if (searchStartDate instanceof DateTimeParseException) {
            return "MM/DD/YYYY";
        }
        return "";
    }

    function updateSearchParams({
                                    passionsSelected,
                                    postalCode,
                                    view,
                                    passionIds,
                                    virtualOnly,
                                    distance,
                                    month,
                                    startDate,
                                    sort,
                                    reverse,
                                    skip,
                                    limit,
                                }: {
        passionsSelected: boolean;
        passionIds: number[];
        postalCode: string | null;
        distance: number;
        month: number;
        startDate: string | null;
        virtualOnly: boolean;
        sort: EventSearchSort;
        reverse: boolean;
        view: EventView;
        skip: number | null;
        limit: number | null;
    }) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete("justRegistered");
        searchParams.delete("getActive");

        if (!passionsSelected) {
            searchParams.delete("postalCode");
        } else {
            searchParams.set("passionsSelected", "");
        }

        if (postalCode === null) {
            searchParams.delete("postalCode");
        } else {
            searchParams.set("postalCode", postalCode);
        }

        if (!passionIds.length) {
            searchParams.delete("passionIds");
        } else {
            searchParams.set("passionIds", passionIds.join("+"));
        }

        if (view !== EventView.MONTH || month === new Date().getMonth()) {
            searchParams.delete("month");
        } else {
            searchParams.set("month", month.toString());
        }

        if (startDate === null) {
            searchParams.delete("date");
        } else {
            searchParams.set("date", startDate.toString());
        }

        if (distance === DEFAULT_DISTANCE) {
            searchParams.delete("distance");
        } else {
            searchParams.set("distance", distance.toString());
        }

        searchParams.set("view", view);

        if (!virtualOnly) {
            searchParams.delete("virtualOnly");
        } else {
            searchParams.set("virtualOnly", virtualOnly.toString());
        }

        if (skip === null || skip === 0) {
            searchParams.delete("skip");
        } else {
            searchParams.set("skip", skip.toString());
        }

        if (limit === null && defaultLimit !== null) {
            searchParams.set("limit", defaultLimit.toString());
        } else if (limit === null) {
            searchParams.delete("limit");
        } else {
            searchParams.set("limit", limit.toString());
        }

        if (sort === null) {
            searchParams.delete("sort");
        } else {
            searchParams.set("sort", sort.toString());
        }

        if (reverse === null) {
            searchParams.delete("reverse");
        } else {
            searchParams.set("reverse", reverse.toString());
        }

        const newUrl = `/find-an-event?${searchParams.toString()}`;
        history.push(newUrl);
        window.scrollTo(0,0);
    }

    // Necessary because directly referencing the variables confuses JS below
    // into thinking it's trying to reference the parameter itself (which isn't
    // yet initialized). It's a confusing explanation but try changing it
    // below to `allPassions = allPassions`
    const defaults = {
        passionsSelected,
        postalCode,
        view,
        passionIds: selectedPassionIds,
        savedPassionIds,
        virtualOnly,
        distance,
        month,
        startDate,
        endDate,
        sort,
        reverse,
        skip,
        limit: limit ?? defaultLimit,
    }
    async function search({
                              passionsSelected = defaults.passionsSelected,
                              postalCode = defaults.postalCode,
                              view = defaults.view,
                              passionIds = defaults.passionIds,
                              savedPassionIds = defaults.savedPassionIds,
                              virtualOnly = defaults.virtualOnly,
                              distance = defaults.distance,
                              month = defaults.month,
                              startDate = defaults.startDate,
                              endDate = defaults.endDate,
                              sort = defaults.sort,
                              reverse = defaults.reverse,
                              skip = defaults.skip,
                              limit = defaults.limit,
                              noHistory = false,
                          }: {
        passionsSelected?: boolean;
        passionIds?: number[];
        savedPassionIds?: number[];
        postalCode?: string | null;
        distance?: number;
        month?: number;
        startDate?: string | null;
        endDate?: string | null;
        virtualOnly?: boolean;
        sort?: EventSearchSort;
        reverse?: boolean;
        view?: EventView;
        skip?: number | null;
        limit?: number | null;
        noHistory?: boolean;
    }) {
        if (view === EventView.MONTH) {
            sort = EventSearchSort.EventStartsAt;
        }
        if (!passionsSelected && !!savedPassionIds.length) {
            passionIds = savedPassionIds;
        }
        // Set this right away so that it can switch away from the passion
        // selector immediately
        setPassionsSelected(passionsSelected);
        setPostalCode(postalCode);
        setSelectedPassionIds(passionIds);
        setSavedPassionIds(savedPassionIds);
        setVirtualOnly(virtualOnly);
        setDistance(distance);
        setStartDate(startDate);
        setEndDate(endDate);
        setSort(sort);
        setReverse(reverse);
        setSkip(skip);
        setLimit(limit);

        if ((!!savedPassionIds.length || passionsSelected)) {
            setSearching(true);
            setResultsLoading(true);
            setSearchingErrorMessage(null);
            try {
                if (view === EventView.MONTH) {
                    let searchResponseBody: EventSearchResponseBody | null = null;
                    const currentMonth = new Date().getMonth();
                    const year = currentMonth > month ? new Date().getFullYear() + 1: new Date().getFullYear();
                    const getDaysInMonth = (year: number, month: number) => {
                        // Can reduce the number of requests to just days after today for the current month
                        const currentDay = currentMonth === month ? new Date().getDate() - 1 : 0;
                        const daysInMonth = new Date(year, month + 1, 0).getDate();
                        return Array.from({ length: daysInMonth-currentDay }, (_, index) => index + currentDay);
                    };
                    const daysArray = getDaysInMonth(year, month);
                    const searchResults: EventSearchResponseBody[] = [];

                    await Promise.all(daysArray.map(async (day) => {
                        const startDay = startOfCurrentOrSoonMonthDateTime(month).plusDays(day);
                        const startDayRequest = startDay.toInstant().toEpochMilli();
                        const endDayRequest = startDay.plusDays(1).minusNanos(1).toInstant().toEpochMilli();

                        // For each day of the month, request that day's events.
                        let latestSearchResponseBody; 

                        if (useEdp44VirtualClubs) {
                            latestSearchResponseBody = await searchEventsAndSeparateOccurrences({
                                authenticatedFetch,
                                postalCode,
                                // We want to use LIST mode for these daily requests to compile the monthly list of events
                                view: EventView.LIST,
                                passionIds,
                                virtualOnly,
                                distance,
                                startDate: startDayRequest.toString(),
                                endDate: endDayRequest.toString(),
                                sort,
                                reverse,
                                skip,
                                limit
                            });
                            searchResults.push(latestSearchResponseBody);
                        } else {
                            latestSearchResponseBody = await searchEvents({
                                authenticatedFetch,
                                postalCode,
                                // We want to use LIST mode for these daily requests to compile the monthly list of events
                                view: EventView.LIST,
                                passionIds,
                                virtualOnly,
                                distance,
                                startDate: startDayRequest.toString(),
                                endDate: endDayRequest.toString(),
                                sort,
                                reverse,
                                skip,
                                limit
                            });
                            searchResults.push(latestSearchResponseBody);
                        }
                    }));

                    for (const searchResult of searchResults) {
                        if (searchResponseBody === null) {
                            searchResponseBody = searchResult;
                        } else {
                            searchResponseBody.results = searchResponseBody.results.concat(searchResult.results);
                        }
                        setDefaultLimit(searchResult.limit);
                        setSearchResponseBody(searchResponseBody);
                    }
                } else {
                    let searchResponseBody;
                    if (useEdp44VirtualClubs && !isSearchStartDateError() && !(searchEndDate instanceof DateTimeParseException)) {

                        searchResponseBody = await searchEventsAndSeparateOccurrences({
                            authenticatedFetch,
                            postalCode,
                            view,
                            passionIds,
                            virtualOnly,
                            distance,
                            startDate: searchStartDate instanceof LocalDate ? ZonedDateTime.of(searchStartDate, LocalTime.MIN, ZoneId.SYSTEM).toInstant().toEpochMilli().toString() : null,
                            endDate: searchEndDate instanceof LocalDate ? ZonedDateTime.of(searchEndDate, LocalTime.MAX, ZoneId.SYSTEM).toInstant().toEpochMilli().toString() : null,
                            sort,
                            reverse,
                            skip,
                            limit
                        });
                        setSearchResponseBody(searchResponseBody);
                    } else if (!useEdp44VirtualClubs) {
                        searchResponseBody = await searchEvents({
                            authenticatedFetch,
                            postalCode,
                            view,
                            passionIds,
                            virtualOnly,
                            distance,
                            startDate,
                            endDate,
                            sort,
                            reverse,
                            skip,
                            limit
                        });
                        setDefaultLimit(searchResponseBody.limit);
                        setSearchResponseBody(searchResponseBody);
                    }
                }
                setSearchingErrorMessage(null);
            } catch (e) {
                setSearchingErrorMessage(t('findAnEvent.unexpectedError'));
            } finally {
                setSearching(false);
                setResultsLoading(false);
            }
        }

        // Set view and month after the search is complete to avoid majorly
        // updating the UI before the data to populate that is ready.
        setView(view);
        setMonth(month);
        setSearchParamsLoaded(true);

        if (!noHistory) {
            setIgnoreLocationUpdate(true);
            updateSearchParams({
                passionsSelected,
                passionIds,
                postalCode,
                distance,
                month,
                startDate,
                virtualOnly,
                sort,
                reverse,
                view,
                skip,
                limit,
            });
        }
    }

    useEffect(() => {
        if (!useEdp44VirtualClubs || (!isSearchStartDateError() && !(searchEndDate instanceof DateTimeParseException))) {
            if (ignoreLocationUpdate) {
                // The location is being manually updated by the search function.
                setIgnoreLocationUpdate(false);
                return;
            }
            const searchParams = new URLSearchParams(location.search);
    
            const passionsSelected = searchParams.has("passionsSelected");
    
            const passionIdsStr = searchParams.get("passionIds")
            let passionIds: number[];
            if (!passionIdsStr) {
                passionIds = [];
            } else {
                passionIds = passionIdsStr.split("+")
                    .map(str => parseInt(str));
            }
    
            const postalCode = searchParams.get("postalCode")
                ?? user?.postalCode ?? null;
    
            const distanceStr = searchParams.get("distance");
            const distance = !!distanceStr ? parseInt(distanceStr) : DEFAULT_DISTANCE;
    
            const monthStr = searchParams.get("month");
            const month = !!monthStr ? parseInt(monthStr) : new Date().getMonth();
    
            let startDate;
            let endDate;
    
            if (useEdp44VirtualClubs) {
                startDate = searchStartDate instanceof LocalDate ? ZonedDateTime.of(searchStartDate, LocalTime.MIN, ZoneId.SYSTEM).toInstant().toEpochMilli().toString() : null;
                endDate = searchEndDate instanceof LocalDate ? ZonedDateTime.of(searchEndDate, LocalTime.MAX, ZoneId.SYSTEM).toInstant().toEpochMilli().toString() : null;
            } else {
                startDate = searchParams.get("date") ?? null;
                endDate = startDate !== null ? (parseInt(startDate, 10) + DAY_IN_MILLIS).toString() : null;
            }
    
            const virtualOnly = searchParams.get("virtualOnly") === "true";
    
            const sort = (searchParams.get("sort") ?? EventSearchSort.Default) as EventSearchSort;
    
            const reverse = searchParams.get("reverse") === "true";
    
            const view = (searchParams.get("view") ?? EventView.LIST) as EventView;
    
            const skipStr = searchParams.get("skip");
            const skip = !!skipStr ? parseInt(skipStr) : null;
    
            const limitStr = searchParams.get("limit");
            const limit = !!limitStr ? parseInt(limitStr) : null;
    
            search({
                passionsSelected,
                postalCode,
                view,
                passionIds,
                virtualOnly,
                distance,
                month,
                startDate,
                endDate,
                sort,
                reverse,
                skip,
                limit,
                noHistory: true
            });
        }
    }, [location, searchStartDate, searchEndDate]);


    useEffect(
        () => {
            if (searchParamsLoaded) {
                (async () => {
                    let allPassions: GetAllPassionsResponseBody;
                    let favoritePassions: GetPassionsResponseBody;
                    try {
                        allPassions = await getAllPassions();
                        favoritePassions = await getFavoritePassions({authenticatedFetch});
                    } catch (e) {
                        setSearching(false);
                        setSearchingErrorMessage(t('findAnEvent.unexpectedError'));
                        return;
                    }

                    allPassions.allPassions.sort((a, b) => a.passionName.localeCompare(b.passionName));
                    setAllPassions(allPassions.allPassions);

                    if (!!favoritePassions.passions.length && !selectedPassionIds.length) {
                        const savedPassionIds = favoritePassions.passions
                            .map(passion => passion.id);
                        search({
                            savedPassionIds,
                            passionIds: passionsSelected ? selectedPassionIds : savedPassionIds,
                            passionsSelected: true,
                            noHistory: true
                        });
                    } else {
                        search({
                            noHistory: true
                        });
                    }
                })()
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [authenticatedFetch, t, searchParamsLoaded]);

    function onChangeSelectedPassions(passionIds: number[], saved: boolean | undefined) {
        search({
            passionIds,
            savedPassionIds: saved ? passionIds : savedPassionIds,
            passionsSelected: true
        });
    }

    function scrollToEvent(eventUrlFragment: string, startOfDate: string) {
        if (!searchResponseBody) {
            return;
        }
        const view = EventView.LIST;

        search({
            view,
            startDate: startOfDate,
            endDate: startOfDate !== null ? (parseInt(startOfDate, 10) + DAY_IN_MILLIS).toString() : null,
        });
    }

    const needToSelectPassions = !passionsSelected && !savedPassionIds.length;

    return (
        <>
            <EligibilityStatusModal retryRequest/>
            {!loading && <div className="FindAnEventPage">
                {needToSelectPassions && <SelectPassions
                    onChangeSelectedPassions={onChangeSelectedPassions}
                    selectedPassionIds={selectedPassionIds}
                    allPassions={allPassions}
                />}
                {!needToSelectPassions && <FindAnEventForm
                    isTodayButtonEnabled={isTodayButtonEnabled}
                    onClickTodayButton={onClickTodayButton}
                    allPassions={allPassions}
                    selectedPassionIds={selectedPassionIds}
                    sort={sort}
                    reverse={reverse}
                    parentPage={ParentPage.FindEventPage}
                    defaultLimit={defaultLimit}
                    searching={searching}
                    onChangeSelectedPassions={onChangeSelectedPassions}
                    view={view}
                    setView={updateView}
                    submitSearch={submitSearch}
                    virtualOnly={virtualOnly}
                    setVirtualOnly={updateVirtualOnly}
                    distance={distance}
                    postalCode={postalCode}
                    setPostalCodeAndDistance={updatePostalCodeAndDistance}>
                    <>
                        {searchingErrorMessage !== null && (
                            <div className="SearchingErrorMessage">
                                {searchingErrorMessage}
                            </div>
                        )}
                        {resultsLoading && <Loading loading={resultsLoading}/>}

                        {
                            useEdp44VirtualClubs && view === EventView.LIST && 
                            <>
                                <div className="EventList_dateRange">
                                    <div>
                                        <MuiPickersUtilsProvider
                                            utils={LocalDateUtils}
                                            locale={Locale.US}>
                                            <KeyboardDatePicker
                                                value={searchStartDate}
                                                label={t("virtualClubsClubDetailPage.fromLabel")}
                                                minDate={new Date()}
                                                error={isSearchStartDateError()}
                                                helperText={searchStartDateErrorText()}
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                required
                                                onChange={(x) => { setSearchStartDate(x) }} 
                                                />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div>
                                        <MuiPickersUtilsProvider
                                            utils={LocalDateUtils}
                                            locale={Locale.US}>
                                            <KeyboardDatePicker
                                                value={searchEndDate}
                                                label={t("virtualClubsClubDetailPage.toLabel")}
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                onChange={(x) => { setSearchEndDate(x) }} />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </>
                        }

                        <EventSearchResults
                            resultsLoading={resultsLoading}
                            searchResponseBody={searchResponseBody}
                            sortByEventStartsAt={sortByEventStartsAt}
                            sortByDistance={sortByDistance}
                            onSortSelected={sortBy}
                            view={view}
                            usingFilters={usingFilters}
                            resetFilters={resetFilters}
                            viewVirtualOnly={viewVirtualOnly}
                            month={month}
                            setMonth={updateMonth}
                            maxEventsPerDay={5}
                            scrollToEvent={scrollToEvent}
                        />
                        { !useEdp44VirtualClubs && (isPreviousDayButtonEnabled || isPreviousButtonEnabled || isNextButtonEnabled || isNextDayButtonEnabled) && (
                            <>
                                <div className="PreviousAndNextButtons">
                                    {isPreviousDayButtonEnabled &&
                                        <Button clickHandler={onClickPreviousDayButton}
                                                buttonStyle={ButtonStyles.Unfilled}
                                                saving={searching}
                                                disabled={!isPreviousDayButtonEnabled}
                                                className="PreviousDayButton">
                                            <FastRewind/>
                                            {t('findAnEvent.previousDayButtonText')}
                                        </Button>
                                    }
                                    <Button clickHandler={onClickPreviousButton}
                                            buttonStyle={ButtonStyles.Unfilled}
                                            saving={searching}
                                            disabled={!isPreviousButtonEnabled}
                                            className="PreviousButton">
                                        <PlayArrow style={{
                                            transform: 'rotate(-180deg)',
                                        }}/>
                                        {t('findAnEvent.previousButtonText')}
                                    </Button>
                                    <Button clickHandler={onClickNextButton}
                                            buttonStyle={ButtonStyles.Unfilled}
                                            saving={searching}
                                            disabled={!isNextButtonEnabled}
                                            className="NextButton">
                                        {t('findAnEvent.nextButtonText')}
                                        <PlayArrow/>
                                    </Button>
                                    {isNextDayButtonEnabled &&
                                        <Button clickHandler={onClickNextDayButton}
                                                buttonStyle={ButtonStyles.Unfilled}
                                                saving={searching}
                                                disabled={!isNextDayButtonEnabled}
                                                className="NextDayButton">
                                            {t('findAnEvent.nextDayButtonText')}
                                            <FastForward/>
                                        </Button>
                                    }
                                </div>
                            </>
                        )}
                        <br/>
                        <SectionHeader title={t('findAnEvent.featuredEvents')}
                                       subtitle={t('findAnEvent.featuredEventsSubtitle')}
                        />
                        <FeaturedEvents />
                    </>
                </FindAnEventForm>}
            </div>}
        </>
    );
}

export interface EventSearchResultExtended extends EventSearchResult {
    ext: {
        url: string;
        today: boolean;
        dayOfWeek: string;
        date: string;
        localDate: string;
        dayNumber: string;
        monthRawNumber: number;
        dateWithDayOfWeek: string;
        timeStart: string;
        timeStartShort: string;
        timeEnd: string;
        passionImageUrl: string;
        passionImageWideUrl: string;
        passionImageThumbnailUrl: string;
        passionImageThumbnailWideUrl: string;
        isSelfGuided: boolean;
    };
}
