import { useEffect, useRef, useState } from "react";
import {
    GetClubLeaderEventOrOccurrenceResponseBody,
    GetUserEventRelationshipResponseBody, VirtualEventDetailsModel
} from "../services/ClubLeaderService";
import {User} from "../services/UserService";
import {EventType} from "./EventType";
import {
    GetClubResponseBody,
    GetEventResponseBody
} from "../services/MemberService";
import {GetPassionResponseBody} from "../services/Models";
import {TFunction} from "i18next";

export function useIsMountedRef() {
    const isMountedRef = useRef(true);
    useEffect(() => {
        return () => {
            isMountedRef.current = false;
        };
    });
    return isMountedRef;
}

export interface WindowSize {
    width: number;
    height: number;
    isMobile: boolean;
    isAltMobile: boolean;
}

const MOBILE_WIDTH = 768;
const ALT_MOBILE_WIDTH = 600;

export function useWindowSize(): WindowSize {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= MOBILE_WIDTH,
        isAltMobile: window.innerWidth <= ALT_MOBILE_WIDTH
    });

    useEffect((() => {
        function onResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                isMobile: window.innerWidth <= MOBILE_WIDTH,
                isAltMobile: window.innerWidth <= ALT_MOBILE_WIDTH
            });
        }
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }), []);

    return windowSize;
}

///
/// The default club/event image
///
export const defaultClubImageUrl = "https://images.squarespace-cdn.com/content/v1/559f08d7e4b07a1d6a29f0de/1541783289182-T4H1C2E69REMC6Z9460F/ke17ZwdGBToddI8pDm48kBdaEgjlJHgZ4GdB_hjsRNJ7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UX2ZxVsGsuPfsKE5TZjcz60FTWZukRYVpV3dcdoOtxIaJ1-7fBEtYAcEyBOMpwO3KA/square+cat.jpg";


export interface ClubImageUrl {
    standard: string;
    wide: string;
    thumbnail: string;
    thumbnailWide: string;
}

///
/// Returns the URL for the club/event image
///
export function  getClubImageUrl(passion: GetPassionResponseBody | null | undefined): ClubImageUrl {
    // TODO: What should the default club image actually be?

    if (passion === undefined || passion === null) {
        return {
            standard: defaultClubImageUrl,
            wide: defaultClubImageUrl,
            thumbnail: defaultClubImageUrl,
            thumbnailWide: defaultClubImageUrl,
        };
    }

    return {
        standard: process.env.PUBLIC_URL + "/images/passions/" + passion.imageFile,
        wide: process.env.PUBLIC_URL + "/images/passions-wide/" + passion.imageFile,
        thumbnail: process.env.PUBLIC_URL + "/images/passions-thumbnail/" + passion.imageFile,
        thumbnailWide: process.env.PUBLIC_URL + "/images/passions-thumbnail-wide/" + passion.imageFile,
    };
}

///
/// Does the current user have leadership roles?
///
export function hasLeadershipRoles(user:User | null): boolean | undefined {
    return user?.hasLeadershipRoles;
}

export function userNameSort(a: GetUserEventRelationshipResponseBody, b: GetUserEventRelationshipResponseBody) {
    let output = a.userLastName.localeCompare(b.userLastName);

    if (output === 0) {
        output = a.userFirstName.localeCompare(b.userFirstName);
    }

    if (output === 0) {
        output = a.userEmail.localeCompare(b.userEmail);
    }

    return output;
}


///
/// To compensate for bad import data, replace ? in the given string with ", if the number of
/// ? characters is even
///
export function fixQuestionMarkCharacters(input: string): string {
    let output = input;
    let qMarksCount = (output.match(/\?/g) || []).length;
    if (qMarksCount % 2 === 0) {
        output = output.replace(/\?/g, "\"");
    }

    return output;
}

///
/// Is the given event type self-guided?
///
export function isEventTypeSelfGuided(eventType: string | null) {
    return eventType !== null && eventType === EventType.SELF_GUIDED;
}

///
/// Is the given event self-guided?
///
export function isEventSelfGuided(event: GetEventResponseBody | GetClubLeaderEventOrOccurrenceResponseBody) {
    return event.typeOne === EventType.SELF_GUIDED;
}

export function isClubInAvaOrganization(club: GetClubResponseBody): boolean {
    if (!club.organization) return false;
    return isAvaOrganizationName(club.organization.organizationName);
}

export function isAvaOrganizationName(organizationName: string): boolean {
    return organizationName === "AVA";
}

// Email validation from https://stackoverflow.com/a/7786283
const validEmailRegex = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+/;
export const validEmailRegexText = validEmailRegex.source;
export function isValidEmail(email: string): boolean {
    return validEmailRegex.test(email)
}

const REGISTRATION_PARAMETERS = "REGISTRATION_PARAMETERS";

export interface RegistrationParameters {
    utmReferringClub?: string;
    utmCampaign?: string;
    memberCode?: string;
}

export interface UtmRegistrationParameters {
    utmReferringClub?: string;
    utmCampaign?: string;
}

export function storeRegistrationParametersToSession({ search }: { search: string }) {
    const searchParams = new URLSearchParams(search);
    const utmRegistrationParameters: RegistrationParameters = {
        utmReferringClub: searchParams.get("utm_source") || undefined,
        utmCampaign: searchParams.get("utm_campaign") || undefined,
        memberCode: searchParams.get("memberCode") || undefined,
    }
    sessionStorage.setItem(REGISTRATION_PARAMETERS,
        JSON.stringify(utmRegistrationParameters));
}


export function storeRegistrationParamsToSession(utmSource : string | undefined, utmCampaign: string | undefined , memberCode: string | undefined ) {
    const utmRegistrationParameters: RegistrationParameters = {
        utmReferringClub: utmSource || undefined,
        utmCampaign: utmCampaign || undefined,
        memberCode: memberCode || undefined,
    }
    sessionStorage.setItem(REGISTRATION_PARAMETERS,
        JSON.stringify(utmRegistrationParameters));
}
export function readRegistrationParametersFromSession(): RegistrationParameters {
    const registrationParametersJson = sessionStorage.getItem(REGISTRATION_PARAMETERS);
    return registrationParametersJson
        && JSON.parse(registrationParametersJson)
        || {};
}

export function readUtmRegistrationParametersFromSession(): UtmRegistrationParameters {
    const registrationParameters = readRegistrationParametersFromSession();
    return {
        utmCampaign: registrationParameters.utmCampaign,
        utmReferringClub: registrationParameters.utmReferringClub,
    };
}

// Slightly modified from MyInfo.utils.ts from ignite work
export const getPhoneUnmask = (value: string) => value.replace(/\D/g, '');

// Copied from Ignite work
export const PHONE_REGEX = /^[0-9]{10}$/

export function isEmptyVirtualEventDetails(virtualEventDetails: VirtualEventDetailsModel | null): boolean {
    if (virtualEventDetails === null || virtualEventDetails === undefined) {
        return true;
    }
    // `medium` may be defined but still be considered empty.
    return !virtualEventDetails.url
        && !virtualEventDetails.urlPasscode
        && !virtualEventDetails.dialInNumber
        && !virtualEventDetails.dialInPasscode;
}

export type TArrayFunction = (path: string, params?: object) => any;

// Doesn't actually involve any hooks but w/e.
export function useTArray(t: TFunction): TArrayFunction {
    return function tArray(path: string, params?: object): any[] {
        return t(path, { returnObjects: true, ...(params ?? {}) })
    }
}

export const WEB_REGEX = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;