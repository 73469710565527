import { useState, useEffect } from "react";
import getPayers from '../api/getPayers'
import { IPayer } from '../types/verification'

const useGetPayers = (): [[IPayer] | undefined, boolean] => {
    const [payers, setPayers] = useState<[IPayer] | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        getPayers()
            .then(data => setPayers(data?.allPayers))
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [payers, isLoading]
}

export default useGetPayers
