import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { IconButton, Button, Switch } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import MultiEmailWrapper from "./MultiEmailWrapper";
import { IClub } from "../../../types/clubFinder";
import { UserContext } from "../../../contexts/UserContext";
import { IMember, IActivityItem } from "../../../types/dashboard";
import cn from "classnames";
import postRemindInvitedMembers from "../../../api/postRemindInvitedMembers";
import postRemindMissingActivity from "../../../api/postRemindMissingActivity";
import { useHistory, useLocation } from "react-router-dom";
import useQuery from "../../../hooks/useQuery";

interface IRemindContent {
    onBackClick: (e: React.MouseEvent) => void
    club: IClub,
    invitedMembers?: IMember[],
    missingActivity?: IActivityItem[]
}

const RemindContent = ({ onBackClick: onBackClickDefault, club, invitedMembers, missingActivity: initialMissingActivity }: IRemindContent) => {
    const { t } = useTranslation('ignite/dashboard');
    const { user } = useContext(UserContext);
    const history = useHistory()
    const query = useQuery()
    const { state }: { state: { missingActivityMembers: IActivityItem[], initialPath: string } } = useLocation()
    const [isRemindEveryMonth, setIsRemindEveryMonth] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const missingActivityFromLocationState = state?.missingActivityMembers
    const missingActivity = missingActivityFromLocationState || initialMissingActivity

    const { clubName } = club
    const leaderName = `${user?.firstName} ${user?.lastName}`
    const initialEmails = invitedMembers?.map(member => member.email) || missingActivity?.map(member => member.email) || []
    const [emails, setEmails] = React.useState(initialEmails as string[]);

    const onClick = () => {
        if (invitedMembers) {
            const ReferralIds = invitedMembers?.filter((member: IMember) => emails.some(email => email === member.email))
                .map((member: IMember) => member.referralId)

            postRemindInvitedMembers({
                ClubId: club.id,
                ReferralIds,
            }).then(() => setIsDisabled(true))
        } else {
            const UserIds = missingActivity?.filter((member: IActivityItem) => emails.some(email => email === member?.email))
                .map(activity => activity?.userId) as number[]
            postRemindMissingActivity({
                ClubId: club.id,
                UserIds,
                Recurring: isRemindEveryMonth
            }).then(() => setIsDisabled(true))
        }
    }

    const count = invitedMembers?.length || missingActivity?.length;

    const onBackClick = (e: React.MouseEvent) => {
        if (state?.initialPath) {
            if (query.has('content-type')) {
                query.delete('content-type')
                history.push({search: query.toString()})
            }
            history.push(state.initialPath)
        } else {
            onBackClickDefault(e)
        }
    }

    const signupIgniteRoute = getIgniteRoute(IGNITE_ROUTE_KEY.MEMBER_ONBOARDING, { ':clubShortCode': String(club.shortCode) });
    const dashboardIgniteRoute = getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': String(club.id) });

    const reminderMessage = Boolean(invitedMembers)
        ? t('copyEmailContent.invitationMessageText', { leaderName, clubName, link: window.location.origin + signupIgniteRoute })
        : t('copyEmailContent.missingActivityMessageText', { leaderName, clubName, link: window.location.origin + dashboardIgniteRoute });

    return (
        <div className="remind-content">
            <IconButton
                className="icon-button"
                onClick={onBackClick}
            >
                <ArrowBackIcon className="icon" />
                {t('backButtonText')}
            </IconButton>

            <div className="action-text">
                {t('remindClubMembers', { count })}
            </div>
            {Boolean(invitedMembers) && (
                <div className="action-description">
                    {t('remindClubMembersDescription', { count })}
                </div>
            )}

            {Boolean(missingActivity) && (
                <div className="switcher">
                    <div className={cn("switch-option", { active: !isRemindEveryMonth })}>
                        <label htmlFor={!isRemindEveryMonth ? 'switch-id' : 'label'}>{t('remindOneTime')}</label>
                    </div>
                    <Switch
                        onChange={() => setIsRemindEveryMonth(prevValue => !prevValue)}
                        className="switch"
                        id="switch-id"
                        checked={isRemindEveryMonth}
                    />
                    <div className={cn("switch-option", { active: isRemindEveryMonth })}>
                        <label htmlFor={isRemindEveryMonth ? 'switch-id' : 'label'}

                        >{t('remindEveryMonth')}</label>
                    </div>
                </div>
            )}

            <MultiEmailWrapper
                emails={emails}
                setEmails={setEmails}
                disabledInput
            />
            <div className="button-wrapper">
                <Button
                    className={cn("button", { disabled: isDisabled })}
                    onClick={onClick}
                    disabled={isDisabled}
                >
                    {isDisabled ? t('reminded') : t('sendReminders')}
                </Button>
            </div>

            <div className="content-inner">
                <div className="subtitle">
                    {t('inviteContent.subtitle')}
                </div>
                <div className="main-part">
                    <div className="text" dangerouslySetInnerHTML={{ __html: reminderMessage }} />
                </div>
            </div>
        </div>
    )
}

export default RemindContent;
