import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0097ea',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

type StepProgressBarProperties = {
  /**
   * A value between 0 and 100 representing the percentage of completion
   */
  progressPercentage: number
}

/**
 * Heavily inspired by the [customized progress bar demo provided by MUI](https://v4.mui.com/components/progress/#customized-progress)
 */
export default function StepProgressBar(props: StepProgressBarProperties) {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={props.progressPercentage} />
    </div>
  );
}