import React from 'react';
import SyncIcon from '@material-ui/icons/SyncAlt';
import Visibility from "@material-ui/icons/Visibility";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IGNITE_ROUTE_KEY } from '../../../constants/routes';
import { getIgniteRoute } from '../../../util/ignite/routes.utils';

interface ViewSwitcherProps {
    isLeaderView: boolean;
    isClubIgniteEnabled: boolean;
    currentClubId: number;
    currentClubUrlFragment: string;
    onSwitchViewState: (isLeaderView: boolean) => void;
}

export const ViewSwitcher = (props: ViewSwitcherProps) => {
    const history = useHistory()
    const { t } = useTranslation('layout');

    let route = '';
    if (props.isLeaderView) {
        if (props.isClubIgniteEnabled) {
            route = getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': String(props.currentClubId) });
        } else {
            route = `/club/${props.currentClubUrlFragment}`;
        }
    } else {
        if (props.isClubIgniteEnabled) {
            route = getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': String(props.currentClubId) });
        } else {
            route = `/my-club/${props.currentClubUrlFragment}`;
        }
    }

    const onClick = () => {
        props.onSwitchViewState(!props.isLeaderView);

        history.push(route)
    };

    return (
        <div className={'ViewSwitcher'}>
            <div className={'ViewSwitcherMode'}>
                <Visibility className='icon' />
                <span>{props.isLeaderView ? t('header.clubViewMode') : t('header.memberViewMode')}</span>
            </div>
            <div className={'ViewSwitcherLink'} onClick={onClick}>
                <SyncIcon className='icon' />
                <span>{props.isLeaderView ? t('header.switchToMemberView') : t('header.switchToClubView')}</span>
            </div>
            <div className={'ViewSwitcherMode_Mobile'} onClick={onClick}>
                <SyncIcon className='icon' />
                <span>{props.isLeaderView ? t('header.clubViewMode') : t('header.memberViewMode')}</span>
            </div>
        </div>
    )
}
