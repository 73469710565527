import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IClubV2SearchParams, IClubV2SearchResponse } from '../types/clubFinder';
import { getClubsV2 } from "../api/getClubsV2";

const useGetClubsV2 = (params: IClubV2SearchParams): [IClubV2SearchResponse | undefined, string, boolean] => {
    const { t } = useTranslation('club');
    const [clubSearchResponse, setClubSearchResponse] = useState<IClubV2SearchResponse>();
    const [clubSearchErrorText, setClubSearchErrorText] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const defaultResponse: IClubV2SearchResponse = {
        items: null,
        limit: 0,
        skip: 0,
        total: 0
    }

 

    useEffect(() => {
        setIsLoading(true)
        getClubsV2(params)
            .then(data => {
                setClubSearchResponse(data);
                setClubSearchErrorText("");
            })
            .catch((e) => {
                if (e.data && e.data.includes("Postal code not found")) {
                    setClubSearchResponse(defaultResponse);
                    setClubSearchErrorText(`${params["location.postalCode"]} ${t('locationPicker.invalidZipcodeApiError')}`);
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [params])

    return [clubSearchResponse, clubSearchErrorText, isLoading]
}

export default useGetClubsV2;
