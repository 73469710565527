import React from "react";
import { EventView } from "../findAnEvent/FindAnEventForm";
import SelectButtonGroup from "../common/SelectButtonGroup";
import { useTranslation } from "react-i18next";
import "./EventViewTypeSelect.scss";

interface ViewTypeSelectProps {
    view: EventView,
    setView: (view: EventView) => void;
}

export default function EventViewTypeSelect(props: ViewTypeSelectProps): JSX.Element {
    const { t } = useTranslation('findAnEvent');
    function onChangeView(view: string) {
        props.setView(view as EventView);
    }

    return (
        <div className={"ViewTypeSelect"}>
            <span className={"ViewTypeSelect_Label"}>{t('findAnEventForm.view')}</span>
            <SelectButtonGroup
                value={props.view}
                onChangeValue={onChangeView}
                options={Object.keys(EventView)
                    .map(key => ({
                        value: key,
                        label: t(`findAnEventForm.viewType.${key}`)
                    }))} />
        </div>
    )
}
