import React from "react";
import { useTranslation } from 'react-i18next';
import "./Copyright.scss";

export default function Copyright(): JSX.Element {
    const { t } = useTranslation('layout');

    return (
        <div className={`Copyright`}>&copy;{t('footer.copyright')}</div>
    );
}
