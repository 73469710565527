import { useState, useEffect } from "react";
import getCustomEnrollmentInfo, {CustomEnrollmentInfo} from "../api/getCustomEnrollmentInfo";

const useGetCustomEnrollmentInfo = (shortCode: string, skipRequest: boolean = false): [CustomEnrollmentInfo | undefined, boolean] => {
    const [customEnrollmentInfo, setCustomEnrollmentInfo] = useState<any | undefined>({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!skipRequest) {
            setIsLoading(true)

            getCustomEnrollmentInfo(shortCode)
                .then(data => {
                    setCustomEnrollmentInfo(data)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [])

    return [customEnrollmentInfo, isLoading]
}

export default useGetCustomEnrollmentInfo
