import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MemberOnboarding from '../pages/ignite/memberOnbording/MemberOnboarding';
import SuccessfulSignup from '../pages/ignite/successfulSignup/SuccessfulSignup';
import ClubFinder from '../pages/ignite/clubFinder/ClubFinder';
import ClubRequesting from '../pages/ignite/clubRequesting/ClubRequesting';
import ClubRequested from '../pages/ignite/clubRequested/ClubRequested';
import MedicareAdvantagePayerSelection from '../pages/ignite/medicareAdvantagePayerSelection/MedicareAdvantagePayerSelection';
import CheckEligibility from '../pages/ignite/checkEligibility/CheckEligibility';
import VerificationStatus from '../pages/ignite/verificationStatus/VerificationStatus';
import MedicareAdvantagePayerQualification from '../pages/ignite/medicareAdvantagePayerQualification/MedicareAdvantagePayerQualification';
import ContactMethods from '../pages/ignite/contactMethods/ContactMethods';
import Dashboard from '../pages/ignite/dashboard/Dashboard';
import LeaderOnboardingWelcome from '../pages/ignite/leaderOnboardingWelcome/LeaderOnboardingWelcome';
import PrivateRoute from './PrivateRoute';
import { Profile } from '../pages/ignite/profile/Profile';
import LeaderDashboard from '../pages/ignite/leaderDashboard/LeaderDashboard';
import LeaderDashboardNew from '../pages/ignite/newLeaderDashboard/NewLeaderDashboard';
import { IGNITE_ROUTE_PATTERNS } from '../constants/routes';
import ClubSetup from '../pages/ignite/clubSetup/ClubSetup';
import Error from '../pages/ignite/error/Error';
import MemberLanding from '../pages/ignite/memberLanding/MemberLanding';
import { withLayout } from '../components/layout/Layout';

import '../index.scss';
import '../styles/ignite/fonts.scss';
import '../styles/ignite/common.scss';
import NotFoundPage from '../pages/notFound/NotFoundPage';

const MemberLandingWithLayout = withLayout(MemberLanding);
const MemberOnboardingWithLayout = withLayout(MemberOnboarding);
const SuccessfulSignupWithLayout = withLayout(SuccessfulSignup);
const ClubFinderWithLayout = withLayout(ClubFinder);
const ClubRequestingWithLayout = withLayout(ClubRequesting);
const ClubRequestedWithLayout = withLayout(ClubRequested);
const MedicareAdvantagePayerSelectionWithLayout = withLayout(
  MedicareAdvantagePayerSelection,
);
const CheckEligibilityWithLayout = withLayout(CheckEligibility);
const VerificationStatusWithLayout = withLayout(VerificationStatus);
const MedicareAdvantagePayerQualificationWithLayout = withLayout(
  MedicareAdvantagePayerQualification,
);
const ContactMethodsWithLayout = withLayout(ContactMethods);
const DashboardWithLayout = withLayout(Dashboard);
const LeaderOnboardingWelcomeWithLayout = withLayout(LeaderOnboardingWelcome);
const ClubSetupWithLayout = withLayout(ClubSetup);
const ErrorWithLayout = withLayout(Error);
const ProfileWithLayout = withLayout(Profile);
const LeaderDashboardWithLayout = withLayout(LeaderDashboard);
const LeaderDashboardNewWithLayout = withLayout(LeaderDashboardNew);
const NotFoundPageWithLayout = withLayout(NotFoundPage);

export default function IgniteRouter() {
  return (
    <Switch>
      <Route
        exact
        path={IGNITE_ROUTE_PATTERNS.MEMBER_ONBOARDING}
        component={MemberLandingWithLayout}
      />
      <Route
        exact
        path={IGNITE_ROUTE_PATTERNS.MEMBER_REGISTRATION}
        component={MemberOnboardingWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.SUCCESSFUL_SIGNUP}
        component={SuccessfulSignupWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.CLUB_FINDER}
        component={ClubFinderWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.CLUB_REQUESTING}
        component={ClubRequestingWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.CLUB_REQUESTED}
        component={ClubRequestedWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.MEDICARE_ADVANTAGE_PAYER_SELECTION}
        component={MedicareAdvantagePayerSelectionWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.CHECK_ELIGIBILITY}
        component={CheckEligibilityWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.VERIFICATION_STATUS}
        component={VerificationStatusWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.MEDICARE_ADVANTAGE_PAYER_QUALIFICATION}
        component={MedicareAdvantagePayerQualificationWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.CONTACT_METHODS}
        component={ContactMethodsWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.DASHBOARD}
        component={DashboardWithLayout}
      />
      <Route
        exact
        path={IGNITE_ROUTE_PATTERNS.LEADER_ONBOARDING_WELCOME}
        component={LeaderOnboardingWelcomeWithLayout}
      />
      <PrivateRoute
        exact
        path={IGNITE_ROUTE_PATTERNS.CLUB_SETUP}
        component={ClubSetupWithLayout}
      />
      <Route
        exact
        path={IGNITE_ROUTE_PATTERNS.MEMBER_TERMS_OF_USE}
        //TODO: clean up unsued code for TOS components (EDP-637)
        // component={MemberTermsOfUsePageWithLayout}
        render={() => {
          window.location.href =
            'https://hellogrouper.com/terms-of-use-for-group-members/';
          return null;
        }}
      />
      <Route
        exact
        path={IGNITE_ROUTE_PATTERNS.LEADER_TERMS_OF_USE}
        //TODO: clean up unsued code for TOS components (EDP-637)
        // component={LeaderTermsOfUsePageWithLayout}
        render={() => {
          window.location.href =
            'https://hellogrouper.com/club-leader-terms-of-use/';
          return null;
        }}
      />
      <Route
        exact
        path={IGNITE_ROUTE_PATTERNS.ERROR}
        component={ErrorWithLayout}
      />
      <PrivateRoute
        path={IGNITE_ROUTE_PATTERNS.PROFILE}
        component={ProfileWithLayout}
      />
      <PrivateRoute
        path={IGNITE_ROUTE_PATTERNS.LEADER_DASHBOARD}
        component={LeaderDashboardWithLayout}
      />
      <PrivateRoute
        path={IGNITE_ROUTE_PATTERNS.LEADER_MANAGE_MEMBERS}
        component={LeaderDashboardNewWithLayout}
      />
      <PrivateRoute component={NotFoundPageWithLayout} />
    </Switch>
  );
}
