import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import "../NewMemberRegistrationWizard.scss";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import Button, {ButtonStyles} from "../../../components/common/Button";
import Grid from '@material-ui/core/Grid';
import {
    GetActivityTypeResponseBody,
    GetAllActivityTypeResponseBody
} from "../../../services/Models";
import LoadingMessage from "../../../components/common/LoadingMessage";
import {loadConfig} from "../../../services/ConfigService";
import {getJson} from "../../../services/RequestService";
import Loading from "../../../components/common/Loading";

type MemberActivityTypes = {
    onContinue: (data: any) => void,
    onPrevious: () => void,
    selectedActivityTypes: number[]
}

export default function NewMemberActivityTypesStep(props: MemberActivityTypes): JSX.Element {
    const { t } = useTranslation('pages');

    const [allActivityTypes, setAllActivityTypes] = useState<GetActivityTypeResponseBody[]>([]);
    const [activityTypes, setActivityTypes] = useState<number[]>(props.selectedActivityTypes);
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const continueEnabled = () => {
        return true;
    }

    const onContinueSelected = () => {
        setSaving(true);
        props.onContinue({activityTypeIds: activityTypes});
    }

    const onChangeActivityType = (activityTypeId: number) => {
        if (activityTypes.includes(activityTypeId)) {
            setActivityTypes(
                activityTypes.filter(id => id !== activityTypeId));
        } else {
            setActivityTypes([...activityTypes, activityTypeId]);
        }
    }

    async function getAllActivityTypes(): Promise<GetAllActivityTypeResponseBody> {
        const config = await loadConfig();
        return await getJson({
            url: `${config.apiOrigin}/all-activity-types`
        });
    }

    useEffect(() => {
        (async () => {
            try {
                setAllActivityTypes(
                    (await getAllActivityTypes()).allActivityTypes.sort((a, b) => a.id - b.id));
            } catch (e) {
                setErrorMessage(t('newMemberRegistration.unexpectedErrorActivityTypes'))
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <div className={"NewMemberRegistrationWizard_Wrapper"}>
            <h2>{t('newMemberRegistration.activityTypesHeader')}</h2>
            {!loading && errorMessage !== null && (
                <LoadingMessage message={errorMessage}/>
            )}
            <div className={"NewMemberRegistrationWizard_Wrapper_InnerContainer"}>
                <div className="NewMemberActivityTypesStep_SubHeader">{t('newMemberRegistration.selectActivityTypes')}</div>
                <div>
                    <FormGroup>
                        <Grid container spacing={2} direction="row" alignItems="flex-start">
                            <Grid item xs={12} sm={9}>
                                <Grid container spacing={2} direction="row" alignItems="flex-start">
                                    {allActivityTypes.map(activityType => (
                                        <Grid item xs={12} sm={6} md={4}
                                            key={activityType.id}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    checked={activityTypes.includes(activityType.id)}
                                                    onChange={() => onChangeActivityType(activityType.id)}
                                                    name={activityType.name}/>}
                                                label={activityType.name}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormGroup>
                </div>
                <div className={"NewMemberActivityTypeStep_NextContainer"}>
                    {!saving && <Button type="button"
                            id="ActivityTypeSubmit"
                            clickHandler={onContinueSelected}
                            text={t('newMemberRegistration.finishButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                            disabled={!continueEnabled()}
                    />}
                    {saving && <Loading className={"NewMemberRegistrationWizard_SubmitButtonLoading"} loading={saving}/>}
                    <Button type="button"
                            clickHandler={props.onPrevious}
                            text={t('newMemberRegistration.backButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                    />
                </div>
            </div>
        </div>
    );
}
