import { IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS } from "../../constants/routes"

export const getIgniteRoute = (routeKey: IGNITE_ROUTE_KEY, params?: Record<string, string>) => {
    const pattern = IGNITE_ROUTE_PATTERNS[routeKey]

    if (!params) return pattern

    let url = pattern

    Object.entries(params).forEach(([params, value]: [string, string]) => {
        url = url.replace(params, value)
    })

    return url
}

export const getClubPageRouteLink = (isVirtual: boolean, urlFragment: string) => {
    const clubLink = isVirtual === true ? `/virtual-clubs/club/${urlFragment}` : `/club/${urlFragment}`
    return clubLink;
}
