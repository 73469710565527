import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import BorderedRow from "../common/BorderedRow";
import EventListNameItem from "./EventListNameItem";
import EventListDateTimeItem from "./EventListDateTimeItem";
import {
    GetEventAndUserEventRelationshipStatusResponseBody,
    GetUserEventRelationshipStatusResponseBody,
    UserEventRelationshipStatus
} from "../../services/MemberService";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/UserService";
import EventListButtonItem from "./EventListButtonItem";
import EventList from "./EventList";

import "./ClubEvents.scss";
import {isEventSelfGuided} from "../../util/Util";
import {UserContext} from "../../contexts/UserContext";
import Button, {ButtonStyles} from "../common/Button";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";

export interface ClubEventsProps {
    clubUrlFragment: string;
    className?: string
    postalCode?: string
}

interface PagedClubEvents {
    totalResultCount: number;
    results: GetEventAndUserEventRelationshipStatusResponseBody[]
}

export default function ClubEvents(props: ClubEventsProps): JSX.Element {
    const { t } = useTranslation('event');
    let classes = 'ClubEvents';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let clubUrlFragment = props.clubUrlFragment;

    const {postalCode} = props;

    const {user, hasLeadershipRole} = useContext(UserContext);

    const [loading, setLoading] = useState(true);
    const [previousLoading, setPreviousLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
    const [events, setEvents]
            = useState<GetEventAndUserEventRelationshipStatusResponseBody[] | null>(null);
    const [skip, setSkip] = useState(0);
    const [totalResultCount, setTotalResultCount] = useState(0);
    const [userHasLeadershipRole, setUserHasLeadershipRole] = useState(false);

    const limit = 10;
    const isPreviousButtonEnabled = skip > 0;
    const isNextButtonEnabled = skip + limit < totalResultCount;

    function onClickPreviousButton() {
        setPreviousLoading(true);
        setSkip(Math.max(0, skip - limit));
    }

    function onClickNextButton() {
        setNextLoading(true);
        setSkip(skip + limit);
    }

    useEffect(() => {
            loadConfig().then((config) => {
                const params = new URLSearchParams();
                params.set("skip", skip.toString());
                params.set("limit", limit.toString());

                if (postalCode !== undefined) {
                    params.set("postalCode", postalCode);
                }

                const getEventAndClubRequest =
                    new Request(
                        `${config.apiOrigin}/clubs/get-events/${clubUrlFragment}/paged?${params.toString()}`,
                        {method: "GET", headers: {"Accept": "application/json"}});

                let responsePromise = authenticatedFetch(getEventAndClubRequest);

                responsePromise.then((response) => {
                    if (response === null || !response.ok) {
                        console.log("Null response on ", getEventAndClubRequest);
                        setLoadingMessage(t('clubEvents.error'));
                        setLoading(false);
                        setPreviousLoading(false);
                        setNextLoading(false);

                        return;
                    }
                    response.json().then((paged: PagedClubEvents) => {
                        setTotalResultCount(paged.totalResultCount);
                        setEvents(paged.results);
                        setLoading(false);
                        setPreviousLoading(false);
                        setNextLoading(false);
                        setLoadingMessage(null);
                    });
                });
            });
            hasLeadershipRole(clubUrlFragment).then(setUserHasLeadershipRole);
        },
        [clubUrlFragment, postalCode, skip, limit, t]);

    function isGoing(relationship?: GetUserEventRelationshipStatusResponseBody | null) {
        if (relationship === null || relationship === undefined) {
            return false;
        }

        return (relationship.status === UserEventRelationshipStatus.Rsvp
            || relationship.status === UserEventRelationshipStatus.Attended
            || relationship.status === UserEventRelationshipStatus.AttendedWithoutRsvp);
    }

    return (
        <>
            <EventList
                className={classes}
                loading={loading}
                loadingMessage={loadingMessage}>
                <>
                    {events !== null &&
                    events.map((eAndR, i) => (
                        <BorderedRow className="EventList_Row"
                                     link={ `/event/${eAndR.event.urlFragment}/search` }
                                     key={eAndR.event.urlFragment}
                                     showTopBorder={i === 0}>
                            <EventListNameItem
                                eventName={eAndR.event.eventName}
                                isPaid={eAndR.event.isPaid}
                                isSelfGuided={isEventSelfGuided(eAndR.event)}
                                isGoing={isGoing(eAndR.status)}
                                status={eAndR.event.status}/>
                            <EventListDateTimeItem endsAtInstant={eAndR.event.endsAtInstant}
                                                   startsAtInstant={eAndR.event.startsAtInstant}/>
                            {isGoing(eAndR.status) &&
                            <EventListButtonItem buttonText={t('clubEvents.viewButton')}
                                                 link={`/event/${eAndR.event.urlFragment}/search`}/>
                            }
                            {!isGoing(eAndR.status) &&
                            <EventListButtonItem buttonText={t('clubEvents.detailsButton')}
                                                 link={`/event/${eAndR.event.urlFragment}/search`}/>
                            }
                        </BorderedRow>
                    ))}
                </>
            </EventList>
            {(isPreviousButtonEnabled || isNextButtonEnabled) && (
                <div className="ClubEvents_PreviousAndNextButtons">
                    <Button clickHandler={onClickPreviousButton}
                            buttonStyle={ButtonStyles.Unfilled}
                            disabled={!isPreviousButtonEnabled}
                            saving={previousLoading}
                            className="PreviousButton">
                        <SkipPreviousIcon/>
                        {t('clubEvents.previousButtonText')}
                    </Button>
                    <Button clickHandler={onClickNextButton}
                            buttonStyle={ButtonStyles.Unfilled}
                            disabled={!isNextButtonEnabled}
                            saving={nextLoading}
                            className="NextButton">
                        {t('clubEvents.nextButtonText')}
                        <SkipNextIcon/>
                    </Button>
                </div>
            )}
        </>
    );
}