import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ClubCard.scss';
import { IClubV2 } from '../../../../../../types/clubFinder';

export interface ClubCardProps {
  club: IClubV2;
  onClubSelect: (club: IClubV2) => void;
}

export const DEFAULT_PASSION_IMAGE_NAME = 'other.jpg';

const ClubCard = ({ club, onClubSelect }: ClubCardProps) => {
  const [highlight, setHighlight] = useState<boolean>(false);

  const getLocation = () => {
    const { locations } = club;
    let location = '';
    if (locations && locations.length > 0) {
      if (
        (locations[0].city && locations[0].city.length > 0) ||
        (locations[0].state && locations[0].state.length > 0)
      ) {
        location += locations[0].city ? locations[0].city : '';
        if (locations[0].state) {
          location +=
            location.length > 0
              ? ', ' + locations[0].state
              : locations[0].state;
        }
      }

      if (locations[0].postalCode && locations[0].postalCode.length > 0) {
        location += ' ' + locations[0].postalCode;
      }
    }
    return location;
  };

  return (
    <div
      className="clubcard"
      onMouseEnter={() => setHighlight(true)}
      onMouseLeave={() => setHighlight(false)}
      style={{
        backgroundColor: highlight ? 'rgba(0,0,0,0.1)' : '',
        cursor: highlight ? 'pointer' : 'pointer',
      }}
      onClick={() => onClubSelect(club)}
    >
      <div>
        <img
          src={
            '/images/passions/' +
            (club?.clubImage?.length > 0
              ? club.clubImage
              : DEFAULT_PASSION_IMAGE_NAME)
          }
          alt=""
          className="club-image"
        />
      </div>
      <div className="club-info">
        {club?.passion && <div>{club.passion}</div>}
        <div className="club-name divider">{club?.name}</div>
        {getLocation()}
      </div>
    </div>
  );
};

export default ClubCard;
