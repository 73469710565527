import { POSTAL_CODE_REGEX, GROUP_NUMBER_REGEX, MEMBER_ID_REGEX } from '../../../constants/regex'

const getElementConfig = (t: Function) => ({
    firstName: {
        name: "firstName",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('eligibility.errors.firstName')
            }
        },
        validateOnBlur: true
    },
    lastName: {
        name: "lastName",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('eligibility.errors.lastName')
            }
        },
        validateOnBlur: true
    },
    userSuppliedPayerName: {
        name: "userSuppliedPayerName",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('eligibility.errors.healthPlan')
            }
        },
        validateOnBlur: true
    },
    zipCode: {
        name: 'zipCode',
        validate: (value: string) => {
            if (!value.match(POSTAL_CODE_REGEX)) {
                return t('eligibility.errors.zipCode')
            }
        },
        validateOnBlur: true
    },
    dateOfBirth: {
        name: 'dateOfBirth',
    },
    groupNumber: {
        name: 'groupNumber',
        validate: (value: string) => {
            if (value !== '' && !value.match(GROUP_NUMBER_REGEX)) {
                return t('eligibility.errors.groupNumber')
            }
        },
        validateOnBlur: true
    },
    memberId: {
        name: 'memberId',
        validate: (value: string) => {
            if (!value.match(MEMBER_ID_REGEX)) {
                return t('eligibility.errors.memberId')
            }
        },
        validateOnBlur: true
    },
})

export { getElementConfig }
