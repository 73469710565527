import React from "react";
import "./Modal.scss";
import {Dialog} from "@material-ui/core";
import {CCStyles} from "../form/Theme";

interface ModalProps {
    className?: string;
    shown: boolean;
    children: any;
}

export default function CCModal(props: ModalProps): JSX.Element {

    let classes = "CCModal "
        + (props.className === undefined ? "" : props.className);

    const ccStyles = CCStyles();

    return (
        <Dialog  fullWidth={true} className={`${ccStyles.root} ${classes}`} open={props.shown}>
            <div className="CCModal_Content">
                <div className="CCModal_Content_Inner">
                    {props.children}
                </div>
            </div>
        </Dialog>
    );
}