
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const GET_PAYERS_URL = '/ignite/all-payers'

const getPayers = async (): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_PAYERS_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export default getPayers
