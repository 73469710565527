import React from "react";
import "./FullSizeThumbnail.scss";

interface FullSizeThumbnailProps {
    className?: string;
    children: any;
}

function FullSizeThumbnail(props: FullSizeThumbnailProps): JSX.Element {
    return (<>
        <div className={"FullSizeThumbnail"}>{props.children}</div>
    </>);
}

export default FullSizeThumbnail;