import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {TextField} from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { GetPassionResponseBody } from "../../../services/Models";
import LoadingMessage from "../../../components/common/LoadingMessage";
import { getAllPassions } from '../../../services/ClubLeaderService';

type MemberPassions = {
  onContinue: (data: any) => void, // when the user selects "continue", including whatever data might be entered
  onPrevious: () => void
}

export default function MemberPassionsStep(props: MemberPassions): JSX.Element {
    const { t } = useTranslation('pages');

    const [allPassions, setAllPassions] = useState<GetPassionResponseBody[]>([]);
    const [passions, setPassions] = useState<GetPassionResponseBody[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",passions);
        // TODO - should there even be a passion selected as a requirement?
        return true;
    }

    const onContinueSelected = () => {
        props.onContinue({passions: passions});
    }

    const onPassionsChanged = (newValue:any) => {
        console.log("onPassionsChanged", newValue);
        setPassions(newValue);
    }

    useEffect(() => {
        (async () => {
            try {
                setAllPassions((await getAllPassions()).allPassions
                    .sort((a, b) => a.passionName.localeCompare(b.passionName)));
            } catch (e) {
                setErrorMessage(t('memberPassionsStep.unexpectedError'))
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <div className={"MemberRegistrationWizard_Wrapper MemberPassionsStep"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={10}>
                    <h2>{t('memberPassionsStep.selectPassions')}</h2>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth margin="normal">
                        <Autocomplete
                            multiple
                            options={allPassions}
                            getOptionLabel={(option) => option.passionName}
                            defaultValue={[]}
                            onChange={(event, newValue) => {
                                onPassionsChanged(newValue);
                              }}
                            disabled={loading || !!errorMessage}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={t('memberPassionsStep.choosePassions')}
                                placeholder=""
                            />
                            )}
                        />
                    </FormControl>
                    {!loading && errorMessage !== null && (
                        <LoadingMessage message={errorMessage}/>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                    <FormControl>
                        <PlayButton type="button"
                                    id="PassonSubmit"
                                    clickHandler={onContinueSelected}
                                    text={t('memberPassionsStep.continueButtonText')}
                                    className="MemberRegistrationWizard_SubmitButton"
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={!continueEnabled()}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}
