import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const PASSWORD_RESET_REQUEST_URL = '/ignite/password-reset-request'

const postResetPassword = async (email: string): Promise<boolean> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${PASSWORD_RESET_REQUEST_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
    });

    return authenticatedFetch(request)
        .then(response => response?.ok ?? false)
}

export default postResetPassword
