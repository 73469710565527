
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const LEAVE_CLUB_URL = '/my-profile/leave-club'

const postLeaveClub = async (clubId: number) : Promise<Response | undefined>  => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${LEAVE_CLUB_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ClubId: clubId })
    });

    let response = await authenticatedFetch(request);

    if (response?.ok) {
        return response
    }
    const error = await response?.text()

    throw new Error(error)
}

export default postLeaveClub
