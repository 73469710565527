import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import React from "react";
import {getPhoneUnmask, PHONE_REGEX} from "../../../util/Util";
import InputMask from "react-input-mask";

export function PhoneInput(props: RegistrationInputProps) {
    const {t} = useTranslation('pages');
    const inputConfig: InputConfig<string> = {
        name: 'phone',
        initialValue: "",
        validate: (value: string) => {
            const formattedValue = getPhoneUnmask(value)
            if (!formattedValue.match(PHONE_REGEX)) {
                return ' ';
            }
        },
        fromInputValue: value => getPhoneUnmask(value),
        validateOnBlur: true
    };

    const [formState, {tel}] = useSubFormInput(inputConfig);

    return (
        <InputMask
            mask='(999) 999-9999'
            maskChar='*'
            {...tel(inputConfig)}
        >
            {(inputProps: any) =>
                <TextField {...inputProps}
                           className={`RegistrationWizard_Input PhoneInput ${props.className || ''}`}
                           label={t('registration.phone.label')}
                           required
                           variant={"outlined"}
                           error={formState.errors.phone !== undefined}
                           size="small"
                           helperText={t('registration.phone.helperText')}
                           FormHelperTextProps={{error: false}}
                />
            }
        </InputMask>
    )
}
