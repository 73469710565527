
import useCheckWindowWidth from './useCheckWindowSize'

const MOBILE_MAX_WIDTH = 500
const TABLET_MAX_WIDTH = 855

const useCheckDevice = (): { isMobile: boolean, isDesktop: boolean, isTablet: boolean } => {
    const windowWidth = useCheckWindowWidth()

    const isMobile = windowWidth < MOBILE_MAX_WIDTH
    const isTablet = windowWidth < TABLET_MAX_WIDTH && windowWidth > MOBILE_MAX_WIDTH
    const isDesktop = windowWidth > TABLET_MAX_WIDTH

    return { isMobile, isDesktop, isTablet }
}

export default useCheckDevice
