import { useEffect, useState } from 'react';
import { loadConfig } from './ConfigService';

const ENV_FLAG_PREFIX = 'REACT_APP_FLAG_';

const noop = () => ({} as Record<string, boolean>);
const validateFlagName = (flagName: string): void => {
  if (!flagName) throw Error('flag name cannot be empty');
};

const toBoolean = (value: string | undefined): boolean | undefined =>
  typeof value !== 'undefined' ? String(value).toLowerCase() === 'true' : value;

const flagsOnly = ([key]: [string, string | undefined], _i: number, _a: [string, string | undefined][]) => key.startsWith(ENV_FLAG_PREFIX)

const normalizeFlags = (
  acc: Record<string, boolean | undefined>,
  cur: [string, string | undefined],
  _i: number,
  _a: [string, string | undefined][],
): Record<string, boolean | undefined> => {
    const [key, value] = cur;
    const flagName = key.replace(ENV_FLAG_PREFIX, '');

    return {
      ...acc,
      [flagName]: toBoolean(value),
    };
};

const readFlags = async (): Promise<Record<string, boolean | undefined>> => {
  const { flags } = await loadConfig();
  const envFlags = Object.entries(process?.env || {})
    .filter(flagsOnly)
      .reduce(normalizeFlags, {});

  return { ...flags, ...envFlags};
};

export const useFeatureFlag = (flagName: string, flagDefaultValue: boolean): boolean => {
  validateFlagName(flagName);

  const [value, setValue] = useState<boolean>(flagDefaultValue);

  useEffect(() => {
    const readFlag = async () => {
      const flags = await readFlags();
      setValue(flags[flagName] ?? flagDefaultValue);
    };

    readFlag().catch(noop);
  }, [])

  return value;
};

export const checkFeatureFlag = async (flagName: string, flagDefaultValue: boolean): Promise<boolean> => {
  validateFlagName(flagName);
  const flags = await readFlags().catch(noop);
  return flags[flagName] ?? flagDefaultValue;
};
