import {
    convert,
    DateTimeFormatter,
    LocalDate,
    ZonedDateTime
} from "@js-joda/core";
import i18next from "i18next";

const monthShiftInJs = 1
const defaultHours = 12

interface IFormat {
    weekday?: "long" | "short" | "narrow",
    year?: "numeric" | "2-digit",
    month: "long" | "short" | "narrow" | "numeric" | "2-digit",
    day: "numeric" | "2-digit",
    hour?: "numeric" | "2-digit",
    minute?: "numeric" | "2-digit"
}

export const getFormattedDate = (date?: string, format?: IFormat) => date ?
    convert(LocalDate.parse(date, DateTimeFormatter.ISO_LOCAL_DATE))
        .toDate()
        .toLocaleDateString(i18next.language || 'en', format)
    : ''

export const getFormattedZonedDate = (format: IFormat, date?: string,) => date ?
    convert(ZonedDateTime.parse(date))
        .toDate()
        .toLocaleDateString(i18next.language || 'en', format)
    : ''

export const getDateObjFromString = (dateStr: string): Date => {
    const [year, month, date] = dateStr.split('-').map(Number)
    return new Date(year, month - monthShiftInJs, date, defaultHours)
}
