import React, {useContext, useState} from "react";
import {useTranslation} from 'react-i18next';

import EventViewWithDetails from "../../components/event/EventViewWithDetails";
import {RouteComponentProps} from "react-router-dom";

import "./EventLanding.scss";
import {UserContext} from "../../contexts/UserContext";
import BackLink from "../../components/common/BackLink";
import Button, {ButtonStyles} from "../../components/common/Button";
import {ArrowBack} from "@material-ui/icons";

interface EventLandingPageParams {
    eventUrlFragment: string;
    source?: string;
}

export default function EventLandingPage({match, location, history}: RouteComponentProps<EventLandingPageParams>): JSX.Element {
    const { t } = useTranslation('pages');
    const {getClubUrlFragmentIfLeader} = useContext(UserContext);
    const [clubUrlFragmentIfLeader, setClubUrlFragmentIfLeader] = useState<string | undefined>(undefined);

    const eventUrlFragment = match.params.eventUrlFragment;

    let source = match.params.source;

    getClubUrlFragmentIfLeader(eventUrlFragment).then(clubUrlFragment => setClubUrlFragmentIfLeader(clubUrlFragment));

    const params = new URLSearchParams(location.search);

    const postalCode = params.get("postalCode") || undefined;

    function navigateBack() {
        history.go(-1);
    }

    window.scrollTo(0,0);

    return (
        <div className="EventLandingPage">
            {clubUrlFragmentIfLeader !== undefined &&
                <BackLink to={`/my-club/${clubUrlFragmentIfLeader}`}>{t('eventLandingPage.backToClub')}</BackLink>}
            {clubUrlFragmentIfLeader === undefined && source !== undefined && source === "search" &&
                <Button type={"button"}
                        clickHandler={navigateBack}
                        className={"EventLandingPage_Back"}
                        buttonStyle={ButtonStyles.UnfilledWithBorder}>
                    <ArrowBack className={"EventLandingPage_Back_Icon"} />
                    {t('eventLandingPage.back')}
                </Button>}
            <EventViewWithDetails
                eventUrlFragment={eventUrlFragment}
                source={source}
                postalCode={postalCode}
            />
        </div>
    );
}

