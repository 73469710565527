import React, {useContext, useEffect} from "react";
import {useTranslation} from 'react-i18next';

import "./ClubLeaderFaqPage.scss";
import CollapsibleFAQ from "../../components/home/CollapsibleFAQ";
import {UserContext} from "../../contexts/UserContext";
import {PhoneNumberOverride} from "../../components/common/ContactPhoneNumber";

export default function ClubLeaderFaqPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const {getContactPhoneNumber} = useContext(UserContext);
    const phoneNumber = getContactPhoneNumber({override: PhoneNumberOverride.CLUB_LEADER});
    // TODO: Consider implementing "scroll to top on page load" behavior
    //       globally at the router level.
    //       https://stackoverflow.com/a/54343182/359008
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="ClubLeaderFaqPage">

            <h1 className="ClubLeaderFaqPage_PageHeader">{t('clubLeaderFaqPage.pageHeader')}</h1>

            <div className="ClubLeaderFaqPage_Section">
                <h2 className="ClubLeaderFaqPage_SectionHeader">{t('clubLeaderFaqPage.salSectionHeader')}</h2>
                <div className="ClubLeaderFaqPage_ItemList">
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.salQ1')}>
                        <div dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.salA1')}}></div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.salQ2')}>
                        <div>
                            {t('clubLeaderFaqPage.salA2')}
                        </div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.salQ3')}>
                        <div>
                            {t('clubLeaderFaqPage.salA3')}
                        </div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.salQ4')}>
                        <div>
                            {t('clubLeaderFaqPage.salA4')}
                        </div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.salQ5')}>
                        <div dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.salA5')}}></div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.salQ6')}>
                        <div dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.salA6', { phoneNumber: phoneNumber })}}></div>
                    </CollapsibleFAQ>
                </div>
            </div>

            <div className="ClubLeaderFaqPage_Section">
                <h2 className="ClubLeaderFaqPage_SectionHeader">{t('clubLeaderFaqPage.calendarSectionHeader')}</h2>
                <div className="ClubLeaderFaqPage_ItemList">
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.calendarQ1')}>
                        <div>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.calendarA1a')}}></li>
                                <li dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.calendarA1b')}}></li>
                                <li dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.calendarA1c')}}></li>
                            </ul>
                        </div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.calendarQ2')}>
                        <div>
                            {t('clubLeaderFaqPage.calendarA2')}
                        </div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.calendarQ3')}>
                        <div  dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.calendarA3')}}></div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.calendarQ4')}>
                        <div  dangerouslySetInnerHTML={{__html: t('clubLeaderFaqPage.calendarA4')}}></div>
                    </CollapsibleFAQ>
                </div>
            </div>

            <div className="ClubLeaderFaqPage_Section">
                <h2 className="ClubLeaderFaqPage_SectionHeader">{t('clubLeaderFaqPage.attendanceSectionHeader')}</h2>
                <div className="ClubLeaderFaqPage_ItemList">
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.attendanceQ1')}>
                        <div>
                            {t('clubLeaderFaqPage.attendanceA1a')}
                        </div>
                        <div>
                            {t('clubLeaderFaqPage.attendanceA1b')}
                        </div>
                    </CollapsibleFAQ>
                    <CollapsibleFAQ question={t('clubLeaderFaqPage.attendanceQ2')}>
                        <div>
                            {t('clubLeaderFaqPage.attendanceA2')}
                        </div>
                    </CollapsibleFAQ>
                </div>
            </div>

        </div>
    )
}
