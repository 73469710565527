import React from "react";

import {
    Button
} from "@material-ui/core";

import "./VirtualClubEventButtonList.scss";

import {
    LocalDate,
} from "@js-joda/core";

import VirtualClubEventForSignup from "./VirtualClubEventForSignup";
import postCancelRsvpEvent from "../../api/postCancelRsvpEvent";
import postRsvpEvent from "../../api/postRsvpEvent";
import { GetClubLeaderEventResponseBody } from "../../services/ClubLeaderService";
import { GetEventAndClubAndUserEventRelationshipResponseBody } from "../../services/MemberService";

export interface VirtualClubEventButtonListProps {
    className?: string
    events: GetClubLeaderEventResponseBody[]
    userEventsInClub: GetEventAndClubAndUserEventRelationshipResponseBody[]
    afterEventRSVPReset: Function
    nextOccurrenceOnOrAfter: LocalDate
    isClubMember: boolean
}

/**
 * VirtualClubEventButtonList
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=296-3917&mode=design&t=Gs9IdcDyGMIOnhOI-0
 * current uses: see src/pages/VirtualClubsClubDetailPage 
 * 
 * List of a given club's events, ordered chronologically starting on Monday, showing
 * user's current RSVP status, and allowing for sign up / cancelation
 */


export default function VirtualClubEventButtonList(props: VirtualClubEventButtonListProps): JSX.Element {
    let classes = 'VirtualClub_events_container_button_list flex-columns';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let ongoingAndFutureEvents = props.events;
    let userEventsInClub = props.userEventsInClub

    // when iterating through ongoingAndFutureEvents to display the club's events to find out if user is registered or not to the event
    const isMemberRegisteredForEvent = (event: GetClubLeaderEventResponseBody) => {
        return userEventsInClub.some(
            (obj: GetEventAndClubAndUserEventRelationshipResponseBody) =>
                (obj.eventAndClub.event.eventId === event.eventId && obj.status === 1)
        );
    }

    function signUpForEvent(eventUrlFragment: string) {
        let controller: any = new AbortController
        async function load() {

            try {
                await postRsvpEvent(eventUrlFragment);
            }
            catch {
                
            }
           
            props.afterEventRSVPReset();
        }
        load().then();
        return () => controller?.abort();
    }

    function cancelForEvent(eventUrlFragment: string) {
        let controller: any = new AbortController
        async function load() {
            console.log("cancel for " + eventUrlFragment);
            await postCancelRsvpEvent(eventUrlFragment);
            props.afterEventRSVPReset();
        }
        load().then();
        return () => controller?.abort();
    }

    return (
        <div className={classes}>
            {ongoingAndFutureEvents.map((event: GetClubLeaderEventResponseBody, index: number) => (
                <div className="eventInstance flex-columns" key={index}>
                    <div className="col-left">
                        <VirtualClubEventForSignup
                            event={event}
                            nextOccurrenceOnOrAfter={props.nextOccurrenceOnOrAfter}
                            isClubMember={props.isClubMember}
                            isRegistered={isMemberRegisteredForEvent(event)}
                        />
                    </div>
                    <div className="col-right">
                        {
                            isMemberRegisteredForEvent(event) ?
                                <Button
                                    className="VirtualClub_MUIButton_outlined medium event-cancel"
                                    variant="outlined"
                                    size="medium"
                                    onClick={() => cancelForEvent(event.urlFragment)}
                                >
                                    Cancel
                                </Button> :
                                props.isClubMember &&
                                <Button
                                    className="VirtualClub_MUIButton medium event-signup"
                                    disabled={!props.isClubMember}
                                    onClick={() => signUpForEvent(event.urlFragment)}
                                >
                                    Sign Up
                                </Button>
                        }
                    </div>
                </div>
            ))}

        </div>
    )
};