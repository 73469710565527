import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import "../MemberRegistrationWizardStep.scss";
import "./MemberPersonalInformationStep.scss";
import {useFormState} from "react-use-form-state";
import {FormControl, TextField} from "@material-ui/core";
import PlayButton from "../../../../components/common/PlayButton";
import {ButtonStyles} from "../../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {Locale} from "@js-joda/locale_en-us";
import {convert, DateTimeParseException, LocalDate} from "@js-joda/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import OptumSelectPlan from '../../../../components/payer/OptumSelectPlan';
import {LocalDateUtils} from "../../../../util/FixedJsJodaUtils";
import InputMask from "react-input-mask";
import {getPhoneUnmask, PHONE_REGEX, readRegistrationParametersFromSession} from "../../../../util/Util";
import {UserContext} from "../../../../contexts/UserContext";
import {PhoneNumberOverride} from "../../../../components/common/ContactPhoneNumber";

export interface MemberPersonalInformation {
    payerId: string,
    planType: string,
    insuranceId: string,
    zipCode: string,
    phone: string,
    dateOfBirth: string
}

type StepProp = {
    firstName: string,
    onContinue: (data: any) => void,
    onPrevious: () => void
}

export function MemberPersonalInformationStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const { memberCode } = readRegistrationParametersFromSession();

    const initialState = {
        payerId: "",
        planType: !!memberCode ? "renew" : "",
        insuranceId: memberCode ?? "",
        zipCode: "",
        phone: "",
        dateOfBirth: null
    }

    const {getContactPhoneNumber} = useContext(UserContext);
    const [formState, {raw, text, tel}] = useFormState(initialState);
    const [dateOfBirth, setDateOfBirth] = useState<LocalDate | DateTimeParseException | null>(null);
    const [payerState, setPayerState] = useState({
        payerId: initialState.payerId,
        planType: initialState.planType,
        insuranceId: initialState.insuranceId,
    });

    // Use a well-rounded date for the date picker widget
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    // Users must be at least 18 years of age
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);

    const elementConfig = {
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                const zipCodeRegex = /^[0-9]{5}$/;
                if (!value.trim()) {
                    //return 'Zip Code is required';
                    return " ";
                }
                if (!value.match(zipCodeRegex)) {
                    //return "Invalid zip code";
                    return " ";
                }
            },
            validateOnBlur: true
        },
        phone: {
            name: 'phone',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return ' ';
                }
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (formState.values.planType === "renew") {
                    if (value === "") {
                        return t('optumSelectPlan.enterCode');
                    }
                    const validRenewIdRegex = /^[SAsa][0-9]{9}$/;
                    if (!value.match(validRenewIdRegex)) {
                        return t('optumSelectPlan.invalidCode');
                    }
                    return;
                }

                if (value === "") {
                    return t('optumSelectPlan.enterHealthPlanId');
                }
            }
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",formState.values);

        if (!payerState) {
            return false;
        }

        const allCommonFieldsValid = Object.values(elementConfig).every(field => {
            if (field.validate) {
                return !field.validate(formState.values[field.name]);
            }
            return true;
        }) && Object.entries(formState.validity).every(([key, value]) => value);

        // If the user is Renew Active/One Pass, they don't need to pass along a date of birth,
        // so ignore the field in that case.
        return allCommonFieldsValid && (payerState.planType === "renew" || dateOfBirth);
    }

    const onContinueSelected = () => {
        props.onContinue({
            payerId: formState.values.payerId,
            insuranceId: formState.values.insuranceId,
            zip: formState.values.zipCode,
            phone: getPhoneUnmask(formState.values.phone),
            dateOfBirth: formState.values.dateOfBirth
        });
    }

    useEffect(() => {
        if (payerState) {
            formState.setField('planType', payerState.planType);
            formState.setField('payerId', payerState.payerId);
            formState.setField('insuranceId', payerState.insuranceId);
            if (payerState.planType === "renew") {
                formState.setField('dateOfBirth', null);
            }
            else if (payerState.planType === "other") {
                formState.setField('dateOfBirth', null);
            }
        }
    }, [payerState]);

    const validateDateOfBirth = (date: LocalDate) => {
        const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
        const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

        return isEqualOrAfterMinDate && isEqualOrBeforeMaxDate;
    }

    useEffect(() => {
        // The widget validation was inconsistent during development. Perform explicit validation on the parsed LocalDate before passing off the value to the form.
        if (dateOfBirth instanceof LocalDate && validateDateOfBirth(dateOfBirth)) {
            formState.setField('dateOfBirth', dateOfBirth);
        }
        else {
            // Otherwise invalidate the date on the form
            formState.setField('dateOfBirth', null);
        }
    }, [dateOfBirth]);
    
    return (
        <div className="OptumMemberRegistrationWizard_Wrapper MemberPersonalInformationStep">
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={2}>
                <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} direction="column" alignItems="stretch">
                    <Grid item>
                        <h2>{t('optumMemberPersonalInformationStep.thankYouMessage', { name: props.firstName})}</h2>
                        <p className="MemberRegistrationWizard_StepSubcontent">{t('optumMemberPersonalInformationStep.stepSubcontent')}</p>
                        <p dangerouslySetInnerHTML={{__html: t('optumMemberPersonalInformationStep.preferPhone', { phoneNumber: getContactPhoneNumber({override: PhoneNumberOverride.MEMBER}) })}}></p>
                    </Grid>
                    <Grid item>
                        <OptumSelectPlan onChange={(state: any) => setPayerState(state)}></OptumSelectPlan>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth margin="normal">
                        <TextField {...text(elementConfig.zipCode)}
                                            className="MemberRegistrationWizard_Input"
                                            label={t('optumMemberPersonalInformationStep.zipCodeLabel')}
                                            required
                                            variant={"outlined"}
                                            error={formState.errors.zipCode !== undefined}
                                            size="small"/>
                    </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth margin="normal">
                            <InputMask
                                mask='(999) 999-9999'
                                maskChar='*'
                                {...tel(elementConfig.phone)}
                            >
                                {(inputProps: any) =>
                                    <TextField {...inputProps}
                                            className="MemberRegistrationWizard_Input"
                                            label={t('optumMemberPersonalInformationStep.phoneLabel')}
                                            required
                                            variant={"outlined"}
                                            error={formState.errors.phone !== undefined}
                                            size="small"
                                            helperText={t('optumMemberPersonalInformationStep.phoneHelperText')}
                                            FormHelperTextProps={{error: false}}
                                            />
                                }
                            </InputMask>
                        </FormControl>
                    </Grid>
                    {payerState && payerState.planType === "other" &&
                    <Grid item>
                        <FormControl fullWidth margin="normal">
                        <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
                            <KeyboardDatePicker
                                className="MemberRegistrationWizard_Input"
                                value={dateOfBirth}
                                label={t('optumMemberPersonalInformationStep.dob')}
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                views={[ "year", "month", "date"]}
                                openTo="year"
                                disableFuture
                                required
                                minDate="1900-01-02" // Treated as 1/1/1900
                                minDateMessage={t('optumMemberPersonalInformationStep.dobMin')}
                                maxDate={convert(LocalDate.now().minusYears(18)).toDate()}
                                maxDateMessage={t('optumMemberPersonalInformationStep.dobMax')}
                                initialFocusedDate="1900-01-02"
                                onChange={date => setDateOfBirth(date)} />
                        </MuiPickersUtilsProvider>
                    </FormControl>
                    </Grid>
                    }
                    <Grid item container justify="flex-end">
                        <FormControl>
                        <PlayButton id="PersonalInformationSubmit"
                                    type="button"
                                    clickHandler={onContinueSelected}
                                    text={t('optumMemberPersonalInformationStep.continueButtonText')}
                                    className="MemberRegistrationWizard_SubmitButton"
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    disabled={!continueEnabled()}
                        />
                    </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    </div>
    );
}
