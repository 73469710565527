import React from "react";
import { IClub } from "../../../types/clubFinder";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ClubType } from "../../../types/club";
import ClubCard from "./ClubCard";

interface ConfirmClubProps {
    confirmClub: IClub;
    onClubConfirm: () => void;
    onBack: () => void;
    open: boolean;
}

export default function ConfirmClub(props: ConfirmClubProps) {
    const { t } = useTranslation('directToConsumer/clubFinder');
    const { confirmClub, onClubConfirm, onBack } = props;

    return(
    <Dialog
        open={props.open}
        onClose={props.onBack}
      >
        <DialogTitle>
            {t("section.confirmClub.title", {
                clubType: confirmClub?.type === ClubType.ASSOCIATION ?
                    t("section.confirmClub.association")
                    : t("section.confirmClub.club")
                }
            )}
        </DialogTitle>
        <DialogContent>
        <ClubCard club={confirmClub} />  
        </DialogContent>
        <DialogActions>
          <Button color="primary" style={{fontWeight: 700}} onClick={onBack}>{t("buttons.notMyClub.label")}</Button>
          <Button color="primary" style={{fontWeight: 700}} onClick={onClubConfirm}>{t("buttons.thatsMyClub.label")}</Button>
        </DialogActions>
      </Dialog>
        
    );
}



    