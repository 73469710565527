import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import {useFormState} from "react-use-form-state";
import {TextField} from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputMask from "react-input-mask";
import {getPhoneUnmask, PHONE_REGEX} from "../../../util/Util";

export interface MemberPhone {
    phone: string
}

type StepProp = {
    firstName: string,
    onContinue: (data: any) => void,
    onPrevious: () => void
}

export function MemberPhoneStep(props: StepProp): JSX.Element {
    const { t } = useTranslation('pages');
    const initialState = {
        phone: "",
    }

    const [formState, {tel}] = useFormState(initialState);

    const elementConfig = {
        phone: {
            name: 'phone',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)
                if (!formattedValue.match(PHONE_REGEX)) {
                    return ' ';
                }
            },
            validateOnBlur: true
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        console.log("continueEnabled: values=",formState.values);
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = () => {
        props.onContinue({phone: getPhoneUnmask(formState.values.phone)});
    }
    
    return (
        <div className={"MemberRegistrationWizard_Wrapper"}>
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={2}>
                <IconButton className={"MemberRegistrationWizard_Previous"} aria-label="Go Back" onClick={props.onPrevious}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>
            <Grid item xs={10}>
                <h2>{t('memberPhoneStep.header', {"name":props.firstName})}</h2>
            </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
                <FormControl fullWidth margin="normal">
                    <InputMask
                        mask='(999) 999-9999'
                        maskChar='*'
                        {...tel(elementConfig.phone)}
                    >
                        {(inputProps: any) =>
                            <TextField {...inputProps}
                                        className="MemberRegistrationWizard_Input"
                                        label={t('memberPhoneStep.phone')}
                                        required
                                        variant={"outlined"}
                                        error={formState.errors.phone !== undefined}
                                        size="small"/>
                        }
                    </InputMask>
                </FormControl>
            </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" alignItems="center" justify="flex-end">
            <Grid item xs={7}></Grid>
            <Grid item xs={5}>
                <FormControl>
                    <PlayButton id="PhoneSubmit"
                                type="button"
                                clickHandler={onContinueSelected}
                                text={t('memberPhoneStep.continueButtonText')}
                                className="MemberRegistrationWizard_SubmitButton"
                                buttonStyle={ButtonStyles.FilledPrimary}
                                disabled={!continueEnabled()}
                    />
                </FormControl>
            </Grid>
        </Grid>
    </div>
    );
    }
