import React from "react";

const crossBold = require("../../assets/cross-bold.svg");

interface CrossBoldProps {
  className?: string
}

export default function CrossBold(props: CrossBoldProps) {
  return <img className={props.className} src={crossBold} alt="cross bold icon"/>
}
