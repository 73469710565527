import i18next from "i18next";
import {SanityTextObject, getSanityResponse} from "./SanityUtils";

export interface ActivityRecordingFormSanityResponse {
    ms: number;
    query: string;
    result: ActivityRecordingFormSanityData[];
}

export interface ActivityRecordingFormSanityData {
    title: SanityTextObject [];
    description: SanityTextObject [];
    headline: string;
    inPersonColumnLabel: string;
    virtualColumnLabel: string;
}

const getActivityRecordingFormViaSanity = async (formSlug: string): Promise<ActivityRecordingFormSanityResponse | undefined> => {
    const LANGUAGE = i18next.language.split('-')[0];
    let query = encodeURIComponent(`
        *[_type == "activity_recording_form" && slug == "${formSlug}"]
        {
            "title": coalesce(pageTitle.${LANGUAGE}, pageTitleV2[_key == "${LANGUAGE}"][0].value),
            "description": description[_key == "${LANGUAGE}"][0].value,
            "headline": headline.${LANGUAGE},
            "inPersonColumnLabel": inPersonColumnLabel.${LANGUAGE},
            "virtualColumnLabel": virtualColumnLabel.${LANGUAGE},
        }
    `);

    return getSanityResponse(query);
}

export default getActivityRecordingFormViaSanity;