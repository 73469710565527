import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";

import "./VerifyAcblEmail.scss";
import { useTranslation } from "react-i18next";
import {storeRegistrationParametersToSession} from "../../util/Util";

export default function VerifyAcblEmail(): JSX.Element {
    const {t} = useTranslation('pages');
    const { tokenLogin } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const pathParts = location.pathname.split("/");
    const [expired, setExpired] = useState<boolean>(false);
    let token: string | undefined = undefined;
    if (pathParts.length > 2)
    {
        token = pathParts[2];
    }

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    useEffect(() => {
        if (!token) {
            history.replace("/login");
        } else {
            (async () => {
                try {
                    await tokenLogin(token);
                    history.replace("/acbl/new-registration");
                } catch (e) {
                    if (e.message === "expired") {
                        setExpired(true);
                    } else {
                        // Unknown error, for example a token that never
                        // existed. Just return them to login.
                        history.replace("/login");
                    }
                }
            })();
        }
    }, [token]);

    if (expired) {
        return (
            <div className={"VerifyAcblEmail_Error"}>
                {t("verifyAcblEmail.expired")}
            </div>
        )
    }

    return <></>
}
