import {getSanityResponse, SanityTextObject} from "./SanityUtils";

export interface ClubAssociationAgreementSanityResponse {
    ms: number;
    query: string;
    result: ClubAssociationAgreementSanityData[];
}

export interface ClubAssociationAgreementSanityData {
    slug: string;
    title: string;
    checkboxText: string;
    name: string;
    content: SanityTextObject[];
    requiredForEnrollmentFlow: boolean;
}

const getClubAssociationAgreementViaSanity = async (slug: string): Promise<ClubAssociationAgreementSanityResponse | undefined> => {
    let query = encodeURIComponent(`
        *[_type == "user_agreement" && lower(slug) == "${slug}"]
        {
            title, slug, checkboxText, name, content, requiredForEnrollmentFlow
        }
    `);

    return getSanityResponse(query);
}

export default getClubAssociationAgreementViaSanity
