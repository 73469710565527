import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from "@material-ui/core";

interface ISwitcherProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    isChecked: boolean
    positiveText: string
    negativeText: string
}

const Switcher = ({ isChecked, onChange, positiveText, negativeText }: ISwitcherProps): JSX.Element => {
    return (
        <div className={`switcher ${isChecked ? 'positive' : 'negative'}`}>
            <span
                className='switcher__negative-text'
            >
                {negativeText}
            </span>
            <Switch
                className={`MyProfilePage_Switch_InputElement`}
                checked={isChecked}
                onChange={onChange}
                classes={{
                    switchBase: 'base',
                    track: 'track',
                    checked: 'checked'
                }}
            />
            <span className='switcher__positive-text'>
                {positiveText}
            </span>
        </div>
    )
}

export default Switcher
