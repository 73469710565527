import React, {useEffect, useState} from "react";
import "./CustomEnrollmentFaq.scss";
import { useRouteMatch} from "react-router-dom";
import getClubAssociationContentViaSanity, {
    ClubAssociationFaqPageSanityData
} from "../../sanity/getClubAssociationContentViaSanity";
import Loading from "../../components/ignite/layout/Loading";
import NotFoundPage from "../notFound/NotFoundPage";
import PortableText from "react-portable-text";

export function CustomEnrollmentFaq(): JSX.Element {
    const { customEnrollmentShortCode } = useRouteMatch<{customEnrollmentShortCode: string}>().params;

    const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);
    const [customEnrollmentFaqPageContent, setCustomEnrollmentFaqPageContent] = useState<ClubAssociationFaqPageSanityData | undefined>(undefined);

    useEffect(() => {
        getClubAssociationContentViaSanity(customEnrollmentShortCode).then(data => {
            setCustomEnrollmentFaqPageContent(data?.result[0].associationFaqPage[0]);
            setQueryResultsLoading(false);
        });
    }, []);

    const isLoading = queryResultsLoading;
    if (isLoading) return <Loading loading={isLoading} />

    if (!isLoading && !customEnrollmentFaqPageContent) {
        return (
            <NotFoundPage></NotFoundPage>
        );
    }

    return (
        <div className={"CustomEnrollmentFaq"}>
            {customEnrollmentFaqPageContent && <>
                {customEnrollmentFaqPageContent.heroBannerImageUrl &&
                    <div className={"CustomEnrollmentFaq_BannerContainer"}>
                        <div className={"CustomEnrollmentFaq_Banner"} style={{backgroundImage: `url(${customEnrollmentFaqPageContent.heroBannerImageUrl}?w=850)`}}></div>
                    </div>
                }
                <div className={"CustomEnrollmentFaq_BodyText"}>
                    {customEnrollmentFaqPageContent.content && <PortableText content={customEnrollmentFaqPageContent.content}></PortableText>}
                </div>
                </>
            }
        </div>
    )
}

