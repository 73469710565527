import React from "react";
import { useTranslation } from 'react-i18next';

import "./OkModal.scss";
import CCModal from "./Modal";
import PlayButton from "./PlayButton";
import {ButtonStyles} from "./Button";

interface OkModalProps {
    className?: string;
    shown: boolean;
    children?: any;
    okClickHandler: Function;
    cancelClickHandler?: Function;
    okButtonText?: string;
}

export default function OkModal(props: OkModalProps): JSX.Element {
    const { t } = useTranslation('common');
    let children = props.children === undefined ? t('okModal.confirmText') : props.children;

    let classes = "OkModal "
        + (props.className === undefined ? "" : props.className);

    function ok(e:any) {
        props.okClickHandler(e);
    }

    return (
        <CCModal className={classes} shown={props.shown}>
                <div className="OkModal_Message">
                    {children}
                </div>
                <div className="OkModal_Buttons">
                    <PlayButton clickHandler={ok}
                                text={props.okButtonText ?? t('okModal.okButtonText')}
                                buttonStyle={ButtonStyles.FilledPrimary}/>
                </div>
        </CCModal>
    );
}