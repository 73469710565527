import {TFunction} from "i18next";

export enum SupportedTimeZone {
    EASTERN = "America/New_York",
    CENTRAL = "America/Chicago",
    MOUNTAIN = "America/Denver",
    ARIZONA = "America/Phoenix",
    PACIFIC = "America/Los_Angeles",
    ALASKA = "America/Juneau",
    HAWAII = "Pacific/Honolulu"
}

export function labelForTimeZone(t: TFunction, timeZone: SupportedTimeZone): string {
    switch (timeZone) {
        case SupportedTimeZone.EASTERN:
            return t('timeZones.eastern');
        case SupportedTimeZone.CENTRAL:
            return t('timeZones.central');
        case SupportedTimeZone.MOUNTAIN:
            return t('timeZones.mountain');
        case SupportedTimeZone.ARIZONA:
            return t('timeZones.arizona');
        case SupportedTimeZone.PACIFIC:
            return t('timeZones.pacific');
        case SupportedTimeZone.ALASKA:
            return t('timeZones.alaska');
        case SupportedTimeZone.HAWAII:
            return t('timeZones.hawaii');
    }
}
