import React, {ChangeEvent, useContext, useState} from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import "./NeedHelpCallout.scss";
import {SupportedLanguages} from "../../util/SupportedLanguages"
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import {MenuItem, Select} from "@material-ui/core";
import MuiSelectElement from "../form/MuiSelectElement";
import {clearUsersClubs} from "../../services/UserService";
import {UserContext} from "../../contexts/UserContext";
import {useLocation} from "react-router-dom";

interface NeedHelpCalloutProps {
    hideLanguageSelection?: boolean
}

export default function NeedHelpCallout(props: NeedHelpCalloutProps): JSX.Element {
    const { t } = useTranslation('layout');
    const {getContactPhoneNumber} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);

    function onChange(event: ChangeEvent<MuiSelectElement>) {
        Object.entries(SupportedLanguages).forEach((entry,index) => {
            if (event.target.value === entry[0]) {
                setSelectedLanguage(entry[0]);
                i18n.changeLanguage(entry[0]);
                // Force a reset of clubs to get translated club names
                clearUsersClubs();
                window.location.reload();
            }
        });
    }

    return (
        <div className={`TopCallout`}>
            {(props.hideLanguageSelection === undefined || !props.hideLanguageSelection) && <div className={`LanguageSelection`}>
                <div className={`LanguageSelectionContent`}>
                    <LanguageIcon className={`LanguageIcon`}/>
                    <div className="TopCallout_LanguageSelectionField">
                        <Select required
                                onChange={onChange}
                                disableUnderline={true}
                                value={selectedLanguage}>

                            {Object.entries(SupportedLanguages).map(supportedLanguage => (
                                <MenuItem key={supportedLanguage[0]}
                                          value={supportedLanguage[0]}>
                                    {supportedLanguage[1]}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>}
            <div className={`NeedHelp`}>
                <div className={`NeedHelpContent`}>
                    {t('header.needHelpContent')} <a
                    href={`tel:${phoneNumber.tel}`}>{phoneNumber.display}</a>
                </div>
                <div className={`NeedHelpContentMobile`}>
                    <a href={`tel:${phoneNumber.tel}`}>{t('header.needHelpContentMobile')} <PhoneIcon className={`PhoneIcon`}/></a>
                </div>
            </div>
        </div>
    );
}
