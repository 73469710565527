import React, { ChangeEvent, useState } from "react";
import "./SelectPassionsModal.scss"
import { Checkbox, Modal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { updateFavoritePassions } from "../../services/MyProfileService";
import { authenticatedFetch } from "../../services/UserService";
import SectionHeader from "../common/SectionHeader";
import Button, { ButtonStyles } from "../common/Button";
import Close from "@material-ui/icons/Close";
import {GetPassionResponseBody} from "../../services/Models";

interface SelectPassionsModalProps {
    open: boolean,
    onClose: Function,
    onChangeSelectedPassions: Function;
    selectedPassionIds: number[];
    allPassions: GetPassionResponseBody[];
}

export default function SelectPassionsModal(props: SelectPassionsModalProps): JSX.Element {
    const {t} = useTranslation('pages');
    const [currentSelectedPassionIds, setCurrentSelectedPassionIds] = useState<number[]>(props.selectedPassionIds);
    const [saveAsMyPassions, setSaveAsMyPassions] = useState<boolean>(false);
    const sortedPassions = props.allPassions
        .sort((a, b) => a.passionName.localeCompare(b.passionName));
    const numberOfColumns = 3;
    const basePassionsPerColumn = Math.floor(sortedPassions.length / numberOfColumns);
    const remainingPassions = sortedPassions.length % numberOfColumns;
    const passionColumns: GetPassionResponseBody[][] = Array.from({length: numberOfColumns})
        .map((_, index) => {
            const baseStartIndex = Math.min(index * basePassionsPerColumn, sortedPassions.length);
            const baseEndIndex = Math.min((index + 1) * basePassionsPerColumn, sortedPassions.length);
            const startIndex = baseStartIndex + Math.min(index, remainingPassions);
            const endIndex = baseEndIndex + Math.min(index + 1, remainingPassions);
            return sortedPassions.slice(startIndex, endIndex);
        });

    function isSelected(passion: GetPassionResponseBody) {
        return currentSelectedPassionIds.includes(passion.id);
    }

    function onClickPassion(passionId: number) {
        if (currentSelectedPassionIds.includes(passionId)) {
            setCurrentSelectedPassionIds(
                currentSelectedPassionIds.filter(id => id !== passionId));
        } else {
            setCurrentSelectedPassionIds([...currentSelectedPassionIds, passionId]);
        }
    }

    function onChangeSaveAsMyPassions(event: ChangeEvent<HTMLInputElement>) {
        setSaveAsMyPassions(event.target.checked)
    }

    async function submit() {
        if (saveAsMyPassions) {
            await updateFavoritePassions(authenticatedFetch, currentSelectedPassionIds);
        }
        props.onChangeSelectedPassions(currentSelectedPassionIds, saveAsMyPassions);
        props.onClose();
    }

    function onClose() {
        props.onClose();
    }

    return (
        <Modal open={props.open} onClose={onClose}>
            <div className="SelectPassionsModal">
                <Close className={"SelectPassionsModal_Close"} onClick={onClose} />
                <SectionHeader title={t('findAnEvent.addPassions')}
                               subtitle={t('findAnEvent.selectPassions')}/>
                <div className="SelectPassionsModal_Passions">
                    {passionColumns.map((passions, index) => (
                        <div key={index}
                             className={"SelectPassionsModal_Passions_Column"}>
                            {passions.map(passion => {
                                const selected = isSelected(passion);
                                return (
                                    <label key={passion.id}
                                           className={"SelectPassionsModal_Passion"}>
                                        <Checkbox checked={selected}
                                                  onClick={() => onClickPassion(passion.id)}/>
                                        <span
                                            className="SelectPassionsModal_Passion_PassionName">
                                            {passion.passionName}
                                        </span>
                                    </label>
                                )
                            })}
                        </div>
                    ))}
                </div>
                <div className="SelectPassionsModal_Panel">
                    <div className="SelectPassionsModal_CheckboxContainer">
                        <Checkbox checked={saveAsMyPassions}
                                  onChange={onChangeSaveAsMyPassions}/>
                        {t('findAnEvent.saveAsMyPassions')}
                    </div>
                    <div className="SelectPassionsModal_Panel_Right">
                        <Button type="button"
                                buttonStyle={ButtonStyles.FilledPrimary}
                                className="SelectPassionsModal_Submit"
                                clickHandler={submit}>{t('findAnEvent.apply')}</Button>
                        <Button type="button"
                                buttonStyle={ButtonStyles.Link}
                                className="SelectPassionsModal_Cancel"
                                clickHandler={onClose}>{t('findAnEvent.cancel')}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
