import React, { useCallback, useState } from "react";
import { IActivityItem } from '../../../types/dashboard'
import getMemberContactInfo from "../../../api/getMemberContactInfo";
import MemberActivityItemWithDetails from "../LeaderDashboard/MemberActivityItemWithDetails";

interface IMembersListProps {
    members: IActivityItem[]
    clubId: number
    monthsAmount: number
    withDetails?: boolean
    buttonsDisabled?: boolean
    fetchMembersActivity?: Function
    fetchLeaderboard?: Function
    changeInvitedReferralIds?: Function
    changeNotInvitedReferralIds?: Function
    changeMissingActivityMemberIds?: Function
    invitedReferralIds?: number[],
    notInvitedReferralIds?: number[],
    missingActivityMemberIds?: number[],
    fetchMyActivity?: Function
}

const MembersList = ({
    members,
    clubId,
    monthsAmount,
    buttonsDisabled,
    fetchMembersActivity,
    fetchLeaderboard,
    fetchMyActivity,
    changeInvitedReferralIds,
    changeNotInvitedReferralIds,
    changeMissingActivityMemberIds,
    invitedReferralIds,
    notInvitedReferralIds,
    missingActivityMemberIds,
}: IMembersListProps) => {
    const [expanded, setExpanded] = useState(-1)
    const [memberDetails, setMemberDetails] = useState()

    const onExpand = useCallback((event: React.MouseEvent, id?: number) => {
        const isExpanded = expanded === id

        if (!isExpanded) {
            return getMemberContactInfo(clubId, Number(id)).then(data => {
                setMemberDetails(data)
            }).then(() => {
                setExpanded(Number(id))
            })
        } else {
            setExpanded(-1)
        }
    }, [clubId, expanded])

    return (
        <div className='members-list'>
            {members.map((memberData: IActivityItem): JSX.Element => (
                <MemberActivityItemWithDetails
                    key={memberData.email}
                    activityRecords={memberData.activityRecords}
                    clubId={clubId}
                    firstName={memberData.firstName}
                    score={memberData.score}
                    lastName={memberData.lastName}
                    income={memberData.income}
                    eligibility={memberData.eligibility}
                    monthsAmount={monthsAmount}
                    onExpand={onExpand}
                    expanded={expanded === memberData.userId}
                    memberDetails={memberDetails}
                    userId={memberData.userId}
                    referralStatus={memberData.referralStatus}
                    referralId={memberData.referralId}
                    invitedAt={memberData.invitedAt}
                    buttonsDisabled={buttonsDisabled}
                    fetchMembersActivity={fetchMembersActivity}
                    fetchLeaderboard={fetchLeaderboard}
                    fetchMyActivity={fetchMyActivity}
                    maxReportingPeriod={memberData.maxReportingPeriod}
                    email={memberData.email}
                    changeInvitedReferralIds={changeInvitedReferralIds}
                    changeNotInvitedReferralIds={changeNotInvitedReferralIds}
                    changeMissingActivityMemberIds={changeMissingActivityMemberIds}
                    invitedReferralIds={invitedReferralIds}
                    notInvitedReferralIds={notInvitedReferralIds}
                    missingActivityMemberIds={missingActivityMemberIds}
                />
            ))}
        </div>
    )
}

export default React.memo(MembersList)
