import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import "./VirtualClubsLandingPage.scss";
import { IClubWithDesc } from "../../types/clubFinder";
import useGetAllCategories from "../../hooks/useGetAllCategories";
const landingPageImage = require("../../assets/virtualclubslanding_weights.jpg");

const imagePrefix = "/images/terms/";
/**
 * VirtualClubsCategoryPage
 * route: /virtual-clubs/category/:termId
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&mode=design
 * 
 * Landing page for Virtual Clubs. Grabs category buttons from useGetAllCategories hook. 
 * 
 */
interface IClubDetails extends IClubWithDesc {
    termName?: string;
    associationImage?: string;
}

export default function VirtualClubsLandingPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const history = useHistory()
    const [terms] = useGetAllCategories();

    return (
        <div className="VirtualClubsLandingPage">
            <h1 className="VirtualClubsPage_Header">{t('virtualClubsLandingPage.header')}</h1>

            <div className="VirtualClubsPage_Info equal-columns">
                <div >
                    <img src={landingPageImage} alt={t('virtualClubsLandingPage.headerImgAltText')} />
                </div>
                <div dangerouslySetInnerHTML={{ __html: t('virtualClubsLandingPage.bodyText') }} ></div>
            </div>
          
            <div className="VirtualClubs_TermsContainer">
                <div className="VirtualClubs_Terms equal-columns is-square has-5">
                    {terms && Object.values(terms).map(term => (
                        <div key={term.id} className="VirtualClubs_Term" onClick={() => history.push('/virtual-clubs/categories/' + term.id)}>
                            <img src={imagePrefix + term.imageFile} className="term_image" />
                            <h5>{term.termName}</h5>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
