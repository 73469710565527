// TODO: This is not really a "service" as the file name implies.  What is it?
import {convert, Duration, Instant, LocalDate, LocalDateTime, LocalTime, ZonedDateTime, ZoneId} from "@js-joda/core";

import "@js-joda/timezone";
import {SupportedTimeZone} from "../util/SupportedTimeZone";
import VirtualEventMedium from "../util/VirtualEventMedium";
import {loadConfig} from "./ConfigService";
import {
    AuthParams,
    getJson, getJsonAuth, getNullableJsonAuth, postFormDataAuth, postJsonAuth
} from "./RequestService";
import {GetLocationResponseBody, GetPassionResponseBody, GetTermResponseBody} from "./Models";
import {ClubType} from "../types/club";

export enum ClubStatus {
    ACTIVE = 1,
    INACTIVE = 2
}

export enum EventStatus {
    ACTIVE = 1,
    CANCELLED = 2
}

export enum UserEventRelationshipStatus {
    Rsvp = 1,
    RsvpCancelled = 2,
    NoRsvp = 3,
    Attended = 4,
    AttendedWithoutRsvp = 5
}

export interface GetUserEventRelationshipResponseBody {
    userId: number;
    eventId: number;
    userFirstName: string;
    userLastName: string;
    userEmail: string;
    userPhone: string;
    status: UserEventRelationshipStatus;
}

export interface GetUserEventRelationshipsResponseBody {
    userEventRelationships: GetUserEventRelationshipResponseBody[];
}

export interface GetAllPassionsResponseBody {
    allPassions: GetPassionResponseBody[];
}
export interface GetAllTermsResponseBody {
    allTerms: GetTermResponseBody[];
}
export interface GetMyClubsResponseBody {
    myClubs: GetClubLeaderClubResponseBody[];
}

export interface GetClubLeaderClubResponseBody {
    id: number;
    type: ClubType;
    urlFragment: string;
    clubName: string;
    description: string;
    customerServiceEmail: string;
    customerServicePhone: string;
    organization: GetOrganizationResponseBody | null;
    passion: GetPassionResponseBody | null;
    timeZone: SupportedTimeZone;
    location: GetLocationResponseBody | null;
    status: ClubStatus;
    events: GetClubLeaderEventResponseBody[];
    isPrimaryClubLeader: boolean;
    primaryClubLeaders: GetClubLeaderResponseBody[];
    deputyClubLeaders: GetClubLeaderResponseBody[];
    openDeputyInvitations: GetDeputyInvitationResponseBody[];
    trackingInPersonActivitiesEnabled: boolean;
    trackingOnlineActivitiesEnabled: boolean;
    isVirtual : boolean;
}

export interface GetOrganizationResponseBody {
    organizationName: string;
}

export interface GetClubLeaderResponseBody {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export interface GetDeputyInvitationResponseBody {
    toEmailAddress: string;
}

export interface VirtualEventDetailsModel {
    medium: VirtualEventMedium;
    url: string;
    urlPasscode: string;
    dialInNumber: string;
    dialInPasscode: string;
    meetingId: number | undefined;
}

export enum WeeklyDay {
    SUNDAY = 1,
    MONDAY = 2,
    TUESDAY = 3,
    WEDNESDAY = 4,
    THURSDAY = 5,
    FRIDAY = 6,
    SATURDAY = 7
}

export function labelForWeeklyDay(weeklyDay: WeeklyDay | string): string {
    switch (weeklyDay) {
        case WeeklyDay.SUNDAY:
            return 'S';
        case WeeklyDay.MONDAY:
            return 'M';
        case WeeklyDay.TUESDAY:
            return 'T';
        case WeeklyDay.WEDNESDAY:
            return 'W';
        case WeeklyDay.THURSDAY:
            return 'T';
        case WeeklyDay.FRIDAY:
            return 'F';
        case WeeklyDay.SATURDAY:
            return 'S';
    }
    return '';
}

export function nameForWeeklyDay(weeklyDay: WeeklyDay | string): string {
    switch (weeklyDay) {
        case WeeklyDay.SUNDAY:
            return 'Sunday';
        case WeeklyDay.MONDAY:
            return 'Monday';
        case WeeklyDay.TUESDAY:
            return 'Tuesday';
        case WeeklyDay.WEDNESDAY:
            return 'Wednesday';
        case WeeklyDay.THURSDAY:
            return 'Thursday';
        case WeeklyDay.FRIDAY:
            return 'Friday';
        case WeeklyDay.SATURDAY:
            return 'Saturday';
    }
    return '';
}

export interface EventRecurrence {
    endDateTime?: LocalDate,
    endTimes?: number,
    weeklyDays?: WeeklyDay,
    repeatInterval: number,
    recurrenceType: number,
}

export interface EventOccurrence {
    duration: number,
    occurrenceId: string,
    startTime: LocalDateTime, // 2020-12-25T14:00:00Z
    startsAtInstant: string,
    endsAtInstant: string,
    status: string
}

export interface GetClubLeaderEventResponseBody {
    eventId: number;
    eventName: string;
    description: string;
    importantDetails: string;
    urlFragment: string;
    leaderFirstName: string;
    leaderLastName: string;
    leaderEmail: string;
    leaderPhone: string;
    startsAtDate: string; // yyyy-mm-dd
    startsAtTime: string; // HH:mm
    startsAtInstant: string, // 2020-12-25T14:00:00Z
    endsAtInstant: string, // 2020-12-25T14:00:00Z
    timeZone: SupportedTimeZone;
    howLongInHours: number;
    location: GetLocationResponseBody | null;
    status: EventStatus;
    typeOne: string | null;
    typeTwo: string | null;
    isPaid: boolean;
    isVirtual: boolean;
    virtualEventDetails: VirtualEventDetailsModel | null;
    isRecurring: boolean;
    recurrence: EventRecurrence | null;
    occurrences: Array<EventOccurrence>;
    rsvpCount: number;
    attendeeCount: number;
    endsAtTime: string;  // HH:mm
    externalUrl: string;
    clubUrlFragment: string;
}

export interface GetClubLeaderEventOrOccurrenceResponseBody {
    eventId: number;
    occurrenceId: string | null;
    eventName: string;
    description: string;
    importantDetails: string;
    urlFragment: string;
    leaderFirstName: string;
    leaderLastName: string;
    leaderEmail: string;
    leaderPhone: string;
    startsAtDate: string; // yyyy-mm-dd
    startsAtTime: string; // HH:mm
    startsAtInstant: string, // 2020-12-25T14:00:00Z
    endsAtInstant: string, // 2020-12-25T14:00:00Z
    timeZone: SupportedTimeZone;
    howLongInHours: number;
    location: GetLocationResponseBody | null;
    status: EventStatus;
    typeOne: string | null;
    typeTwo: string | null;
    isPaid: boolean;
    isVirtual: boolean;
    virtualEventDetails: VirtualEventDetailsModel | null;
    isRecurring: boolean;
    recurrence: EventRecurrence | null;
    rsvpCount: number;
    attendeeCount: number;
    endsAtTime: string;  // HH:mm
    externalUrl: string;
    clubUrlFragment: string;
}

export interface GetClubLeaderEventAndClubResponseBody {
    event: GetClubLeaderEventResponseBody;
    club: GetClubLeaderClubResponseBody;
}

export interface CreateEventResponseBody {
    eventUrlFragment: string
}

export interface GetPreviousAttendeesResponseBody {
    previousAttendees: Array<GetPreviousAttendeeResponseBody>;
}

export interface GetPreviousAttendeeResponseBody {
    firstName: string;
    lastName: string;
    email: string;
}

/**
 * The data structure returned by {@link getEventTimeInfo}.
 */
export interface EventTimeInfo {
    howLong: Duration;
    timeZone: ZoneId;
    startsAtZonedDateTime: ZonedDateTime;
    startsAtInstant: Instant;
    startsAtNativeJsDate: Date;
    endsAtZonedDateTime: ZonedDateTime;
    endsAtInstant: Instant;
    endsAtNativeJsDate: Date;
}

/**
 * Returns various representations of the time-related properties of the event for convenience.
 */
export function getEventTimeInfo(event: GetClubLeaderEventResponseBody): EventTimeInfo {
    // nb: can't use ofHours here as that fails on fractional values
    const howLong = Duration.ofMinutes(event.howLongInHours * 60);
    const timeZone = ZoneId.of(event.timeZone);
    const startsAtDate = LocalDate.parse(event.startsAtDate);
    const startsAtTime = LocalTime.parse(event.startsAtTime);
    const startsAtDateTime = LocalDateTime.of(startsAtDate, startsAtTime)
    const startsAtZonedDateTime = startsAtDateTime.atZone(timeZone);
    const startsAtInstant = startsAtZonedDateTime.toInstant();
    const startsAtNativeJsDate = convert(startsAtZonedDateTime).toDate();
    const endsAtZonedDateTime = startsAtZonedDateTime.plus(howLong);
    const endsAtInstant = endsAtZonedDateTime.toInstant();
    const endsAtNativeJsDate = convert(endsAtZonedDateTime).toDate();

    return {
        howLong,
        timeZone,
        startsAtZonedDateTime,
        startsAtInstant,
        startsAtNativeJsDate,
        endsAtZonedDateTime,
        endsAtInstant,
        endsAtNativeJsDate
    };
}

export async function getAllCategories(): Promise<GetAllTermsResponseBody> {
    const config = await loadConfig();
    return await getJson({
        url: `${config.apiOrigin}/all-terms/1`
    });
}

export async function getAllPassions(): Promise<GetAllPassionsResponseBody> {
    const config = await loadConfig();
    return await getJson({
        url: `${config.apiOrigin}/all-passions`
    });
}

interface GetMyClubParams extends AuthParams {
    clubUrlFragment: string;
    omitLeaders: boolean;
}

export async function getMyClub({
                                    authenticatedFetch,
                                    clubUrlFragment,
                                    omitLeaders
                                }: GetMyClubParams): Promise<GetClubLeaderClubResponseBody | null> {
    const config = await loadConfig();
    return getNullableJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/my-club/${encodeURIComponent(clubUrlFragment)}?omitEvents=true${omitLeaders ? '&omitClubLeaders=true' : ''}&ignoreLanguage=true`,
    });
}

interface GetMyEventParams extends AuthParams {
    eventUrlFragment: string;
}

export async function getMyEvent({
                                    authenticatedFetch,
                                    eventUrlFragment
                                }: GetMyEventParams): Promise<GetClubLeaderEventResponseBody> {
    const config = await loadConfig();
    return getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/events/get/${encodeURIComponent(eventUrlFragment)}`,
    });
}

export interface CreateOrUpdateEventRequestBody {
    eventName: string,
    description: string,
    importantDetails: string,
    leaderFirstName: string,
    leaderLastName: string,
    leaderEmail: string,
    leaderPhone: string,
    startsAtDate: LocalDate,
    startsAtTime: string,
    timeZone: string,
    howLongInHours: number,
    typeOne: string | null,
    typeTwo: string | null,
    isPaid: boolean,
    locationName: string,
    streetAddressOne: string,
    streetAddressTwo: string,
    city: string,
    countrySubdivision: string,
    postalCode: string,
    isVirtual?: boolean,
    virtualEventDetails?: VirtualEventDetailsModel | null,
    isRecurring?: boolean,
    recurrence?: EventRecurrence | null,
    occurrenceIdsToCancel?: string[]
}

interface CreateEventParams extends AuthParams {
    clubUrlFragment: string;
    data: CreateOrUpdateEventRequestBody;
}

export async function createEvent({
                                      authenticatedFetch,
                                      clubUrlFragment,
                                      data
                                  }: CreateEventParams): Promise<CreateEventResponseBody> {
    const config = await loadConfig();
    return (await postJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/events/create/${encodeURIComponent(clubUrlFragment)}`,
        data
    })).json();
}

interface UpdateEventParams extends AuthParams {
    eventUrlFragment: string;
    data: CreateOrUpdateEventRequestBody;
}

export async function updateEvent({
                                      authenticatedFetch,
                                      eventUrlFragment,
                                      data
                                  }: UpdateEventParams): Promise<Response> {
    const config = await loadConfig();
    return await postJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/events/update/${encodeURIComponent(eventUrlFragment)}`,
        data
    });
}

interface UploadEventFilesParams extends AuthParams {
    eventUrlFragment: string;
    files: FileList;
}

export async function uploadEventFiles({
                                      authenticatedFetch,
                                      eventUrlFragment,
                                      files
                                  }: UploadEventFilesParams): Promise<Response> {
    const config = await loadConfig();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file !== null) {
            formData.append("newFiles", file);
        }
    }
    return await postFormDataAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/club-leader/events/upload-files/${encodeURIComponent(eventUrlFragment)}`,
        formData
    });
}
