import React, {useContext} from "react";

import "./Hamburger.scss";
import "./NavigationDesktop";
import {UserContext} from "../../contexts/UserContext";

interface HamburgerProps {
    navigationShown: boolean;
    setNavigationShown: Function;
}

export default function Hamburger(props:HamburgerProps): JSX.Element {

    const {user} = useContext(UserContext);

    function onClick() {
        props.setNavigationShown(!props.navigationShown);
    }

    return (
        <div className={`HamburgerOuterContainer`}>
            <div onClick={onClick} className={`HamburgerContainer`}>
                <span className="Hamburger"/>
            </div> 
        </div>
    );
}