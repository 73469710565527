import React from "react";

const oneCircleFilled = require("../../assets/1-circle-filled.svg");

interface OneCircleFilledProps {
    className?: string
}

export default function OneCircleFilled(props: OneCircleFilledProps) {
    return <img className={props.className} src={oneCircleFilled} alt="circle one icon"/>
}
