import React from "react";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";

import "./EventList.scss";

export interface EventListProps {
    loading: boolean;
    loadingMessage: string | null;
    className?: string
    children?: any
}

export default function EventList(props: EventListProps): JSX.Element {

    let classes = 'EventList';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let loading = props.loading;
    let loadingMessage = props.loadingMessage;
    let children = props.children;

    return (
        <>
            {loading && <Loading loading={loading}/>}
            {!loading && loadingMessage !== null && <LoadingMessage message={loadingMessage}/>}
            {!loading && loadingMessage === null &&
            <div className={classes}>
                {children}
            </div>
            }
        </>
    );
}