import React, {useState, useEffect, useContext} from 'react';
import { useHistory, useParams } from 'react-router-dom'

import "./ClubDirectoryRegistrationPage.scss";

import { UserContext } from '../../../contexts/UserContext';

import HealthplaInfoForm from '../form/HealthplaInfoForm';
import RegistrationForm from '../form/RegistrationForm';

import { sendPageView } from '../../../withTracker';
import getClubIdByUrlFragment from '../../../api/getClubIdByUrlFragment';
import postPendingJoinClub from '../../../api/postPendingJoinClub';


export enum ClubDirectoryRegistrationSteps {
    Register = 1,
    EligibilityCheck = 2,
    InterestedInClub = 3
}

const PageTitle = [
    "Register",
    "Check Your Eligibility",
]

export default function ClubDirectoryRegistrationPage(){
    const [renderRegistration, setRenderRegistration] = useState<boolean>(false);
    const [step, setStep] = useState<ClubDirectoryRegistrationSteps>(ClubDirectoryRegistrationSteps.Register);
    const {syncUser, isLoggedIn, user} = useContext(UserContext);
    const history = useHistory();

    const { clubUrlFragment } = useParams();
    
    const [clubId, setClubId] = useState(0);

    useEffect(() => {
        if(isLoggedIn()){
            setStep(ClubDirectoryRegistrationSteps.InterestedInClub);
        } else{
            setStep(ClubDirectoryRegistrationSteps.Register);
        }
    }, []);

    // If urlFragment is not a valid club, redirect to the Club Directory page
    useEffect(() => {
        if (clubUrlFragment) {
            getClubIdByUrlFragment(clubUrlFragment)
            .then((response) => {
                if (response === 0) {
                    history.push("/clubs");
                } else {
                    setClubId(response);
                }
            });
        }
    }, [clubUrlFragment]);

    useEffect(() => {
        if(step) {
            // Google Analytics tracking
            sendPageView(
                `${history.location.pathname}?step=${step}`,
                `Club Directory Registration Step ${PageTitle[step - 1]}`
            );
        }
        window.scrollTo(0, 0);
    }, [step]);

    const addClubToPendingAndRedirectToMemberScreen = () => (
        postPendingJoinClub(clubId).then(() => {
            history.push({
                pathname: `/ignite/profile/clubs/`,
                search: "?showClubJoinedMessage=true"
            });
        })
    )

    const renderStep = () => {
        switch(step){
            case ClubDirectoryRegistrationSteps.Register: 
                return <RegistrationForm
                    handleSubmit={() => {
                        syncUser();
                        setStep(ClubDirectoryRegistrationSteps.EligibilityCheck);
                    }}
                    onBack={() => setRenderRegistration(!renderRegistration)}
                />;
            case ClubDirectoryRegistrationSteps.EligibilityCheck:
                return (
                    <HealthplaInfoForm
                        onSubmit={addClubToPendingAndRedirectToMemberScreen}
                    />
                );
            case ClubDirectoryRegistrationSteps.InterestedInClub:
                addClubToPendingAndRedirectToMemberScreen();
        }
    }

    return(
        <>
        {renderStep()}
        </>
    );
}