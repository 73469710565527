import React from "react";

const plus = require("../../assets/plus.svg");

interface PlusProps {
  className?: string;
}

export default function Plus(props: PlusProps): JSX.Element {
  return <img className={`Plus ${props.className || ''}`} src={plus} />
}
