import React, { useContext } from "react";
import {Redirect, Route, RouteProps, useLocation} from "react-router-dom";
import { E3_ROUTES } from "../constants/routes";
import { UserContext } from "../contexts/UserContext";
import { Logout } from "../components/logout/Logout";

const PrivateRoute = ({
                          component: Component,
                          render,
                          ...rest
                      }: any) => {

    const { isLoggedIn, user } = useContext(UserContext);
    const {pathname, hash, search} = useLocation();

    function onRender(props: RouteProps) {
        if (isLoggedIn()) {
            if (user?.isIncompleteRegistration) {
                return <Redirect to={"/acbl/prompt-continue"} />;
            } else if (!user?.isPasswordSet) {
                return <Logout />;
            } else {
                return Component ? <Component {...props} /> : render(props);
            }
        } else {
            return <Redirect
                        to={{
                            pathname: E3_ROUTES.LOGIN,
                            state: { referrer: pathname+search+hash }
                        }}
                    />;
        }
    }

    return (
        <Route {...rest} render={onRender} />
    );
};

export default PrivateRoute
