import { useState, useContext } from "react";
import postRegisterInvitedMember from '../api/postRegisterInvitedMember'
import postRegisterInvitedLeader from '../api/postRegisterInvitedLeader'
import { useHistory } from 'react-router-dom'
import { IGNITE_ROUTE_PATTERNS } from "../constants/routes";
import { UserContext } from "../contexts/UserContext";

const SUCCESSFUL_SIGNUP_URL = '/ignite/successful-signup/'

const useRegisterInvitedUser = ({ onSuccess, onFailure }: { onSuccess: Function, onFailure: Function }): { registerMember: Function, registerLeader: Function, isLoading: boolean, error: string } => {
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const { login, refreshUser } = useContext(UserContext)
    const [error, setError] = useState('')

    const registerMember = (body: any, clubId: number) => {
        setIsLoading(true)
        setError('')
        onSuccess()
        const url = body.userToken ? `${SUCCESSFUL_SIGNUP_URL}${clubId}?userToken=${body.userToken}` :
            `${SUCCESSFUL_SIGNUP_URL}${clubId}`

        postRegisterInvitedMember(body)
            .then(data => {
                if (data) {
                    login(body.Email, body.Password)
                        .then(() => refreshUser())
                        .then(() => history.push(url))
                }
            })
            .catch(error => {
                setError(error.message)
                setIsLoading(false)
                onFailure()
            })
    }

    const registerLeader = (body: any) => {
        setIsLoading(true)
        setError('')
        onSuccess()
        postRegisterInvitedLeader(body)
            .then(data => {
                if (data) {
                    login(body.Email, body.Password)
                        .then(() => refreshUser())
                        .then(() => history.push(IGNITE_ROUTE_PATTERNS.CLUB_SETUP))
                }
            })
            .catch(error => {
                setError(error.message)
                setIsLoading(false)
                onFailure()
            })
    }

    return { registerMember, registerLeader, isLoading, error }
}

export default useRegisterInvitedUser
