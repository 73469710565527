import React from "react";
import { useHistory } from 'react-router-dom'

import "./VirtualClubEventButtonList.scss";
import { GetClubLeaderEventResponseBody } from "../../services/ClubLeaderService";

import {
    LocalDate
} from "@js-joda/core";

import { 
    formatEventDate, 
    formatEventOccurrenceDateOnOrAfter, 
    getEventOccurrenceDateOnOrAfter,
    formatEventRecurrence, 
    formatEventTimeRange 
} from "../../util/Event.utils";

export interface VirtualClubEventForSignupProps {
    className?: string
    event: GetClubLeaderEventResponseBody
    nextOccurrenceOnOrAfter: LocalDate
    isClubMember: boolean
    isRegistered: boolean
}

export default function VirtualClubEventForSignup(props: VirtualClubEventForSignupProps): JSX.Element {
    const history = useHistory();

    let classes = 'VirtualClub_events_container flex-columns';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    const event = props.event;
    const nextOccurrenceOnOrAfter = props.nextOccurrenceOnOrAfter;
    const isRegistered = props.isRegistered;

 
    let eventStartAtInstant  = event.startsAtInstant
    let eventEndAtInstant = event.endsAtInstant
    let firstOccurence

    if (event.isRecurring){
        firstOccurence = getEventOccurrenceDateOnOrAfter(event.occurrences, nextOccurrenceOnOrAfter);
        if (firstOccurence){
            eventStartAtInstant = firstOccurence.startsAtInstant;
            eventEndAtInstant = firstOccurence.endsAtInstant;
        }
    }

    return (
        <>
            <div className="title" onClick={() => history.push('/event/' + event.urlFragment)}>{event.eventName}</div>
            {
                event.isRecurring ?
                    <>
                        <div className="time">
                            {formatEventRecurrence(event.recurrence)}
                        </div>
                        <div className="time">
                            {formatEventTimeRange(eventStartAtInstant, eventEndAtInstant)}
                        </div>
                        <div className="time">
                            Next Meeting:
                        </div>
                        <div className="time">
                            {formatEventOccurrenceDateOnOrAfter(event.occurrences, nextOccurrenceOnOrAfter)}
                        </div>
                    </> :
                    <>
                        <div className="time">
                            {formatEventDate(event.startsAtInstant)}
                        </div>
                        <div className="time">
                            {formatEventTimeRange(eventStartAtInstant, eventEndAtInstant)}
                        </div>
                    </>

            }
            {isRegistered && 
                <div className="attending">You're signed up!</div>
            }
        </>
    )
};