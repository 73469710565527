import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Button } from "@material-ui/core";
import { E3_ROUTES } from "../../../constants/routes";
import {useHistory, useLocation} from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { refreshUsersClubs } from "../../../services/UserService";

import "./Error.scss";

export default function Error() {
    const { t } = useTranslation('ignite/error');
    const history = useHistory()

    const { getContactPhoneNumber, refreshUser } = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    const onButtonClick = async () => {
        await refreshUser();
        await refreshUsersClubs();

        history.push(E3_ROUTES.ROOT)
    }

    return (
            <div className="error-page">
                <ErrorIcon className="icon" />
                <div className="title">
                    {t('title')}
                </div>
                <div className="description">
                    {t('defaultDescription')}
                    {' '}
                    {phoneNumber.display}
                </div>
                <Button className="button" onClick={onButtonClick}>
                    {t('buttonText')}
                </Button>
            </div>
    )
}
