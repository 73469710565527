import React from "react";
import { useTranslation } from 'react-i18next';
import CloseIcon from "@material-ui/icons/Close";

import './WelcomeToDashboardPanel.scss'
import { ClubType } from "../../../types/club";

interface IWelcomeToDashboardPanelProps {
    onClose: (e: React.MouseEvent) => void
    type: ClubType
}

const WelcomeToDashboardPanel = ({
    onClose,
    type,
}: IWelcomeToDashboardPanelProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');

    return <div className='welcome-dashboard'>
        <div className='welcome-dashboard__title'>
            {t('welcomeToDashboardTitle')}
        </div>
        <div className='welcome-dashboard__description'>
            {t('welcomeToDashboardDescription', {entity: type === ClubType.CLUB ? "club" : "association"})}
        </div>
        <CloseIcon className='welcome-dashboard__icon' onClick={onClose} />
    </div>
}

export default WelcomeToDashboardPanel
