import React from "react";
import { useTranslation } from 'react-i18next';

import "./AmericasWalkingClubWaiver.scss";
import logo from "../../assets/americas-walking-club-logo.png";

export default function AmericasWalkingClubWaiver(): JSX.Element {
    const { t } = useTranslation('common');
    return (<>
        <div className={"AmericasWalkingClubWaiver"}>
            <div className={"AmericasWalkingClubWaiver_Header"}>
                <div><img className="Logo" src={logo} alt="logo"/></div>
                <div className={"AmericasWalkingClubWaiver_HeaderText"}
                     dangerouslySetInnerHTML={{__html: t('americasWalkingClubWaiver.header')}}>
                </div>
            </div>
            <div className={"AmericasWalkingClubWaiver_Body"}
                dangerouslySetInnerHTML={{__html: t('americasWalkingClubWaiver.body')}}></div>
        </div>
    </>);
}