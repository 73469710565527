import { useState } from "react";
import postSetupClub from "../api/postSetupClub";

interface ISetupClubOptionsArg {
    onSuccessCallback: (value: { clubId: number; } | undefined) => { clubId: number; } | PromiseLike<{ clubId: number; } | undefined> | undefined,
    onFailCallback: (reason: any) => PromiseLike<never>
}

const useSetupClub = (): [Function, boolean] => {
    const [isLoading, setIsLoading] = useState(false)

    const setupClub = (body: any, { onSuccessCallback, onFailCallback }: ISetupClubOptionsArg) => {
        setIsLoading(true)

        return postSetupClub(body)
            .then(onSuccessCallback)
            .catch(onFailCallback)
            .finally(() => {
                setIsLoading(false)
            })
    }

    return [setupClub, isLoading]
}

export default useSetupClub
