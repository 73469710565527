import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loadConfig } from '../../services/ConfigService';
import './CustomEnrollmentLandingPage.scss';
import '../clubLeaderLanding/ClubLeaderLanding.scss';
import Button, { ButtonStyles } from '../../components/common/Button';
import { storeRegistrationParametersToSession } from '../../util/Util';
import ArrowButton from '../../components/icon/ArrowButton';
import Plus from '../../components/icon/Plus';
import logo from '../../assets/logo.svg';
import getClubAssociationContentViaSanity, {
  ClubAssociationLandingPageSanityData,
} from '../../sanity/getClubAssociationContentViaSanity';
import Loading from '../../components/ignite/layout/Loading';
import { ArrowRight } from '@material-ui/icons';
import NotFoundPage from '../notFound/NotFoundPage';
import PortableText from 'react-portable-text';

interface CustomEnrollmentLandingPageProps {}

/**
 * A landing page for Clubs/Associations with content delivered via Sanity that contains a link to register as a member.
 */
export default function CustomEnrollmentLandingPage(
  props: CustomEnrollmentLandingPageProps,
): JSX.Element {
  const { t } = useTranslation('pages');
  const history = useHistory();
  const { customEnrollmentShortCode } = useRouteMatch<{
    customEnrollmentShortCode: string;
  }>().params;

  const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);
  const [clubAssociationLogo, setClubAssociationLogo] = useState<
    string | undefined
  >(undefined);
  const [clubAssociationLogoLight, setClubAssociationLogoLight] = useState<
    string | undefined
  >(undefined);
  const [clubAssociationLandingPage, setClubAssociationLandingPage] = useState<
    ClubAssociationLandingPageSanityData | undefined
  >(undefined);

  useEffect(() => {
    getClubAssociationContentViaSanity(customEnrollmentShortCode).then(
      (data) => {
        setClubAssociationLogo(data?.result[0]?.associationLogoUrl);
        setClubAssociationLogoLight(data?.result[0]?.associationLogoLightUrl);
        setClubAssociationLandingPage(
          data?.result[0]?.associationLandingPage[0],
        );
        setQueryResultsLoading(false);
      },
    );

    storeRegistrationParametersToSession(history.location);
    window.scrollTo(0, 0);
  }, []);

  const onClickQualifyButton = async () => {
    const config = await loadConfig();
    if (clubAssociationLandingPage?.moonshotEnabled) {
      const hasParams = history.location.search?.length;
      window.location.href = `${config.grouperUrl}${hasParams > 0 ? history.location.search + '&' : '?'}utm_shortcode=${customEnrollmentShortCode}`;
    } else {
      history.push(`/p/${customEnrollmentShortCode}/register${history.location.search}`);
    }
  };

  const onClickFaq = () => {
    window.open(`/p/${customEnrollmentShortCode}/faq`, '_blank');
  };

  if (queryResultsLoading) return <Loading loading={queryResultsLoading} />;

  if (!queryResultsLoading && !clubAssociationLandingPage) {
    return <NotFoundPage></NotFoundPage>;
  }

  return (
    <div className="CustomEnrollmentLandingPage">
      <div className={'CustomEnrollmentLandingPage_Banner'}>
        <div
          className={'CustomEnrollmentLandingPage_Banner_Top'}
          style={
            clubAssociationLandingPage!.backgroundColor
              ? {
                  backgroundColor:
                    clubAssociationLandingPage!.backgroundColor.hex,
                  opacity: clubAssociationLandingPage!.backgroundColor.alpha,
                }
              : undefined
          }
        >
          {clubAssociationLogoLight && (
            <img
              className={'CustomEnrollmentLandingPage_Banner_Top_Logo'}
              src={`${clubAssociationLogoLight}?w=80`}
            />
          )}
          {clubAssociationLogo && !clubAssociationLogoLight && (
            <img
              className={'CustomEnrollmentLandingPage_Banner_Top_Logo'}
              src={`${clubAssociationLogo}?w=80`}
            />
          )}
        </div>
      </div>
      <div className={'CustomEnrollmentLandingPage_TopSection'}>
        <div className={'CustomEnrollmentLandingPage_TopSection_Left'}>
          <div className={'CustomEnrollmentLandingPage_TopSection_LeftText'}>
            {clubAssociationLandingPage!.heroText && (
              <PortableText
                content={clubAssociationLandingPage!.heroText}
              ></PortableText>
            )}
          </div>
          <Button
            className={'CustomEnrollmentLandingPage_TopSection_LeftButton'}
            clickHandler={onClickQualifyButton}
            type={'button'}
            buttonStyle={ButtonStyles.FilledPrimary}
          >
            {t('customEnrollmentLandingPage.qualifyButtonLabel')}
            <ArrowButton
              className={
                'CustomEnrollmentLandingPage_TopSection_LeftButtonIcon'
              }
            />
          </Button>
          <div
            className={
              'CustomEnrollmentLandingPage_TopSection_LeftLogoContainer'
            }
          >
            {clubAssociationLogo && (
              <img
                className={
                  'CustomEnrollmentLandingPage_TopSection_LeftLogo CustomEnrollmentLandingPage_TopSection_LeftLogo_ClubAssociationLogo'
                }
                src={`${clubAssociationLogo}?w=80`}
              />
            )}
            <Plus
              className={'CustomEnrollmentLandingPage_TopSection_LeftLogoPlus'}
            />
            <img
              className={
                'CustomEnrollmentLandingPage_TopSection_LeftLogo CustomEnrollmentLandingPage_TopSection_LeftLogo_E3HLogo'
              }
              src={logo}
              alt="Grouper"
            />
          </div>
        </div>
        <div className={'CustomEnrollmentLandingPage_TopSection_Right'}>
          {!clubAssociationLandingPage?.landingVideo ? (
            <div
              className={
                'CustomEnrollmentLandingPage_TopSection_Right_ImageContainer'
              }
            >
              <div
                className={
                  'CustomEnrollmentLandingPage_TopSection_Right_BackgroundColor'
                }
                style={
                  clubAssociationLandingPage!.backgroundColor
                    ? {
                        backgroundColor:
                          clubAssociationLandingPage!.backgroundColor.hex,
                        opacity:
                          clubAssociationLandingPage!.backgroundColor.alpha,
                      }
                    : undefined
                }
              ></div>
              {clubAssociationLandingPage!.heroImageUrl && (
                <div
                  className={
                    'CustomEnrollmentLandingPage_TopSection_Right_Image_Desktop'
                  }
                  style={{
                    backgroundImage: `url(${
                      clubAssociationLandingPage!.heroImageUrl
                    }?w=500)`,
                  }}
                ></div>
              )}
            </div>
          ) : (
            <div className="landingVideoContainer">
              <video width="100%" height="100%" controls>
                <source
                  src={clubAssociationLandingPage?.landingVideo}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {clubAssociationLandingPage!.heroBannerImageUrl &&
            !clubAssociationLandingPage?.landingVideo && (
              <img
                className={
                  'CustomEnrollmentLandingPage_TopSection_Right_Image_Mobile'
                }
                src={`${clubAssociationLandingPage!.heroBannerImageUrl}?w=650`}
              />
            )}
        </div>
      </div>
      <div className={'CustomEnrollmentLandingPage_MiddleSection'}>
        {clubAssociationLandingPage!.whyYouShouldEnroll && (
          <>
            <div className={'CustomEnrollmentLandingPage_MiddleSection_Header'}>
              {t('customEnrollmentLandingPage.whyJoinHeader')}
            </div>
            <div
              className={'CustomEnrollmentLandingPage_MiddleSection_Subheader'}
            >
              {t('customEnrollmentLandingPage.whyJoinSubtitle')}
            </div>
            <div
              className={
                'CustomEnrollmentLandingPage_MiddleSection_InfoContainer'
              }
            >
              {clubAssociationLandingPage!.whyYouShouldEnroll.map(
                (item, index) => (
                  <div
                    key={index}
                    className={'CustomEnrollmentLandingPage_MiddleSection_Info'}
                  >
                    {clubAssociationLandingPage!
                      .whyYouShouldEnrollHeaderImageUrls &&
                      clubAssociationLandingPage!
                        .whyYouShouldEnrollHeaderImageUrls.length > 0 && (
                        <div
                          className={
                            'CustomEnrollmentLandingPage_MiddleSection_InfoIconContainer'
                          }
                        >
                          <img
                            className={
                              'CustomEnrollmentLandingPage_MiddleSection_InfoIcon'
                            }
                            src={`${
                              clubAssociationLandingPage!
                                .whyYouShouldEnrollHeaderImageUrls[
                                index %
                                  clubAssociationLandingPage!
                                    .whyYouShouldEnrollHeaderImageUrls.length
                              ]
                            }?w=50`}
                          />
                        </div>
                      )}
                    <p>{item}</p>
                  </div>
                ),
              )}
            </div>
          </>
        )}
        {clubAssociationLandingPage!.faqRef && (
          <div
            className={'CustomEnrollmentLandingPage_MiddleSection_FaqLink'}
            onClick={onClickFaq}
          >
            <span
              className={
                'CustomEnrollmentLandingPage_MiddleSection_FaqLink_Text'
              }
            >
              {t('customEnrollmentLandingPage.faqLinkText')}
            </span>
            <ArrowRight
              className={
                'CustomEnrollmentLandingPage_MiddleSection_FaqLink_Icon'
              }
            />
          </div>
        )}
      </div>
      {clubAssociationLandingPage!.additionalContent?.length > 0 &&
        clubAssociationLandingPage!.additionalContent[0]!.children[0]!.text
          ?.length > 0 && (
          <div className={'CustomEnrollmentLandingPage_BottomSection'}>
            <PortableText
              content={clubAssociationLandingPage!.additionalContent}
            ></PortableText>
          </div>
        )}
    </div>
  );
}
