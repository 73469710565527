import {
    AuthenticatedFetch, AuthParams,
    getJsonAuth, postJsonAuth
} from "./RequestService";
import { loadConfig } from "./ConfigService";
import { GetPassionsResponseBody } from "./MemberService";

export async function getFavoritePassions({ authenticatedFetch }: AuthParams): Promise<GetPassionsResponseBody> {
    const config = await loadConfig();
    return await getJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/my-profile/favorite-passions`
    });
}

export async function updateFavoritePassions(authenticatedFetch: AuthenticatedFetch, passionIds: number[]): Promise<void> {
    const config = await loadConfig();
    await postJsonAuth({
        authenticatedFetch,
        url: `${config.apiOrigin}/my-profile/favorite-passions/all`,
        data: {passionIds}
    });
}
