import React, {useContext, useEffect, useRef, useState} from "react";
import { useTranslation } from 'react-i18next';
import {useHistory, useLocation} from "react-router-dom";

import {UserContext, UserState} from "../../contexts/UserContext";
import {IImportResult} from "./EventImportWizard";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch, getUser} from "../../services/UserService";
import ContactPhoneNumber from "../common/ContactPhoneNumber";


export default function EventImportUpload(
        props: { clubUrlFragment: string, UserState: UserState, ImportResultSetter: Function, StepSetter: Function }): JSX.Element {
    const { t } = useTranslation('eventImportWizard');
    const {getContactPhoneNumber} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    let clubUrlFragment = props.clubUrlFragment;
    let setStep = props.StepSetter;
    let setImportResult = props.ImportResultSetter;
    let userState = props.UserState;
    let uploadFileRef = useRef<HTMLInputElement>(null);
    let uploadFileLabelRef = useRef<HTMLInputElement>(null);
    let uploadModalRef = useRef<HTMLDivElement>(null);
    let uploadProgressBarRef = useRef<HTMLDivElement>(null);
    let uploadProgressLabelRef = useRef<HTMLDivElement>(null);
    let uploadAttachmentsFileRef = useRef<HTMLInputElement>(null);
    let uploadAttachmentsFileLabelRef = useRef<HTMLInputElement>(null);


    const [uploadFileName, setUploadFileName] = useState<string>(t('eventImportUpload.noFileSelected'));
    const [statusMessage, setStatusMessage] = useState<string>('');
    const [uploadModalShown, setUploadModalShown] = useState<boolean>(false);
    const [uploadFileSet, setUploadFileSet] = useState<boolean>(false);
    const [uploadAttachmentsFileName, setUploadAttachmentsFileName] = useState<string>(t('eventImportUpload.noFileSelected'));

    const history = useHistory();

    function uploadFile_OnChange() {
        if (uploadFileRef === null
            || uploadFileRef.current === null
            || uploadFileRef.current.files === null
            || uploadFileRef.current.files[0] === null
            || uploadFileRef.current.files[0] === undefined) {
            return;
        }

        setUploadFileName(uploadFileRef.current.files[0].name);
        setUploadFileSet(true);
    }

    function chooseFile() {
        if (uploadFileRef === null || uploadFileRef.current === null) {
            return;
        }

        uploadFileRef.current.click();
    }

    function uploadAttachmentsFile_OnChange() {
        if (uploadAttachmentsFileRef === null
            || uploadAttachmentsFileRef.current === null
            || uploadAttachmentsFileRef.current.files === null
            || uploadAttachmentsFileRef.current.files[0] === null
            || uploadAttachmentsFileRef.current.files[0] === undefined) {
            return;
        }

        setUploadAttachmentsFileName(uploadAttachmentsFileRef.current.files[0].name);
    }

    function chooseAttachmentsFile() {
        if (uploadAttachmentsFileRef === null || uploadAttachmentsFileRef.current === null) {
            return;
        }

        uploadAttachmentsFileRef.current.click();
    }

    function getTemplate() {
        window.location.href = "/event-import-template.xlsx";
    }

    async function doUpload() {
        if (uploadFileRef === null
            || uploadFileRef.current === null
            || uploadFileRef.current.files === null
            || uploadFileRef.current.files[0] === null
            || uploadFileRef.current.files[0] === undefined) {
            return;
        }
        let file: File = uploadFileRef.current.files[0];

        let attachmentsFile: File|null = null;
        if (uploadAttachmentsFileRef !== null
            && uploadAttachmentsFileRef.current !== null
            && uploadAttachmentsFileRef.current.files !== null
            && uploadAttachmentsFileRef.current.files[0] !== null
            && uploadAttachmentsFileRef.current.files[0] !== undefined) {
            attachmentsFile = uploadAttachmentsFileRef.current.files[0];
        }

        const config = await loadConfig();
        const url = `${config.apiOrigin}/eventimport/stepone/${clubUrlFragment}`;
        let xhr = new XMLHttpRequest();

        // refresh the auth token
        const request = new Request(`${config.apiOrigin}/eventimport/refresh`);
        const response = await authenticatedFetch(request);
        if (response === null) {
            return;
        }

        let user = getUser();

        setUploadModalShown(true);
        // upload.onprogress must be attached before open
        xhr.upload.onprogress = function (e) {
            let percent = Math.ceil((e.loaded / e.total) * 100);
            if (uploadProgressBarRef !== null && uploadProgressBarRef.current !== null
                && uploadProgressLabelRef !== null && uploadProgressLabelRef.current !== null) {
                uploadProgressBarRef.current.style.width = percent + "%";
                uploadProgressLabelRef.current.innerHTML = percent + "%";
            }
        }
        xhr.open('POST', url);
        if (userState.user !== null) {
            //xhr.setRequestHeader('Authorization', 'Bearer ' + userState.user?.accessToken);
            xhr.setRequestHeader('Authorization', 'Bearer ' + user?.accessToken);
        }
        let formData = new FormData();
        formData.append('files', file);
        if (attachmentsFile !== null)
        {
            formData.append('files', attachmentsFile);
        }
        xhr.send(formData);
        xhr.onerror = function () {
            setUploadModalShown(false);
        }
        xhr.onload = function () {
            setUploadModalShown(false);

            if (xhr.status === 403) {
                history.replace('/');
            }

            let uploadResult: IImportResult | null = null;
            try {
                uploadResult = JSON.parse(xhr.responseText);
            } catch (e) {
            }
            if (uploadResult === null) {
                // dang
            } else {
                setImportResult(uploadResult);
                if (uploadResult.status === "NoFiles") {
                    setStatusMessage(t('eventImportUpload.noFiles'));
                } else if (uploadResult.status === "TooManyFiles") {
                    setStatusMessage(t('eventImportUpload.tooManyFiles'));
                } else if (uploadResult.status === "FailureInvalidColumnHeaders") {
                    setStatusMessage(t('eventImportUpload.failureInvalidColumnHeaders'));
                } else if (uploadResult.status === "FailureNotAnExcelFile") {
                    setStatusMessage(t('eventImportUpload.failureNotAnExcelFile'));
                } else if (uploadResult.status === "FailureException") {
                    setStatusMessage(t('eventImportUpload.failureException'));
                } else if (uploadResult.status === "FailureNoEvents" || uploadResult.events === undefined || uploadResult.events === null || uploadResult.events.length === 0) {
                    setStatusMessage(t('eventImportUpload.failureNoEvents'));
                } else {
                    setStep(1);
                }
            }
        };
    }

    useEffect(() => {
    });

    // These translation strings are used in input values which error if placed directly.
    const getTemplateButtonText = t('eventImportUpload.getTemplateButtonText');
    const chooseSpreadsheetButtonText = t('eventImportUpload.chooseSpreadsheetButtonText');
    const chooseAttachmentsButtonText = t('eventImportUpload.chooseAttachmentsButtonText');
    const uploadButtonText = t('eventImportUpload.uploadButtonText');

    return (
        <div id="upload">
            <div className={`DarkModal DisplayNone ${uploadModalShown && 'DisplayBlock'}`}
                 ref={uploadModalRef}>
                <div className="UploadProgressContainer">
                    <div className="Centered" style={{marginBottom: '.2em'}}>{t('eventImportUpload.uploadProgress')}</div>
                    <div className="UploadProgressBarBackground">
                        <div className="UploadProgressBar" ref={uploadProgressBarRef}>&nbsp;</div>
                    </div>
                </div>
            </div>
            <div className={`ClearModal DisplayNone ${uploadModalShown && 'DisplayBlock'}`}
                 ref={uploadModalRef}>
                <div className="UploadProgressContainer">
                    <div className="UploadProgressLabel" ref={uploadProgressLabelRef}/>
                </div>
            </div>
            <h1>{t('eventImportUpload.header')}</h1>
            <hr className="ThinDivider"/>
            <div className="LeftColumn">
                <div>{t('eventImportUpload.introInstructions1')}</div>
                <div><span dangerouslySetInnerHTML={{__html: t('eventImportUpload.introInstructions2')}}></span> {phoneNumber.display}.</div>
                <div>
                    <h2>{t('eventImportUpload.step1Header')}</h2>
                    <input type="button" value={getTemplateButtonText} onClick={getTemplate}
                           className="Button BlueBackground WhiteForeground DisplayBlock"/>
                    <span dangerouslySetInnerHTML={{__html: t('eventImportUpload.step1Details')}}></span>
                </div>
                <div dangerouslySetInnerHTML={{__html: t('eventImportUpload.step2')}}></div>
                <div dangerouslySetInnerHTML={{__html: t('eventImportUpload.step3')}}></div>
            </div>
            <div className="MiddleColumn"/>
            <div className="RightColumn">
                <div className="BorderBox">
                    <div>
                        <b>{t('eventImportUpload.uploadExcelHeader')}</b>
                        <input className="DisplayNone" onChange={uploadFile_OnChange}
                               type="file" name="uploadFile"
                               ref={uploadFileRef}/>
                        <input className="DisplayNone" onChange={uploadAttachmentsFile_OnChange}
                               type="file" name="uploadAttachmentsFile"
                               ref={uploadAttachmentsFileRef}/>
                        <input className="Button BlueBackground WhiteForeground"
                               onClick={chooseFile}
                               type="button" value={chooseSpreadsheetButtonText}/>
                        <div className="Truncate UploadFileLabel"
                              ref={uploadFileLabelRef}>{uploadFileName}</div>
                        <input className="Button BlueBackground WhiteForeground"
                               onClick={chooseAttachmentsFile}
                               type="button" value={chooseAttachmentsButtonText}/>
                        <div className="Truncate UploadFileLabel"
                              ref={uploadAttachmentsFileLabelRef}>{uploadAttachmentsFileName}</div>
                        <input
                            className={`Button BlueBackground WhiteForeground ${!uploadFileSet && 'Disabled'}`}
                            onClick={doUpload}
                            type="button"
                            value={uploadButtonText}/>
                        <div className="DarkRedForeground">{statusMessage}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

