import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import SelectOrder from '../../../components/ignite/Dashboard/SelectOrder'
import SearchPanel from "../../../components/ignite/Dashboard/SearchPanel";
import MembersList from "../../../components/ignite/Dashboard/MemberList";
import useCheckDevice from '../../../hooks/useCheckDevice'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import useSortMembersActivity from '../../../hooks/useSortMembersActivity'
import { IActivityItem, IMember } from '../../../types/dashboard'
import { BODY_TABS, filterOptions, FILTER_KEYS, MEMBER_TABS } from '../../../constants/dashboard'
import { memberDashboardConfig } from '../../../configs/memberDashboard'
import Tabs from './Tabs'
import { goThroughArr } from "../../../util/ignite/Dashboard.utils";
import cn from "classnames";
import InviteIcon from "../icons/InviteIcon";
import BellIcon from "../icons/BellIcon";
import postRemindInvitedMembers from "../../../api/postRemindInvitedMembers";
import postRemindMissingActivity from "../../../api/postRemindMissingActivity";
import postInviteNotInvitedMembers from "../../../api/postInviteNotInvitedMembers";
import { Leaderboard } from "../Dashboard/Leaderboard";
import { IClub } from "../../../types/clubFinder";

import './MembersTab.scss';
import useTabFilter from "../../../hooks/useTabFilter";

interface IMembersTabProps {
    onBodyTabChange: (event: React.ChangeEvent<{}>, value: BODY_TABS) => void
    bodyTabValue: string
    leaderboardList: IActivityItem[]
    leaderboardTotalCount: number
    members: IActivityItem[]
    invitedMembers?: IMember[]
    notInvitedMembers?: IMember[]
    missingActivity?: IActivityItem[]
    club: IClub
    fetchMembersActivity: Function
    fetchLeaderboard: Function
    getMyActivity: Function
    setIsOpenTrackingActivity?: Function
}

const MembersTab = ({
    invitedMembers = [],
    notInvitedMembers = [],
    missingActivity = [],
    bodyTabValue,
    onBodyTabChange,
    leaderboardList,
    leaderboardTotalCount,
    members,
    club,
    fetchMembersActivity,
    fetchLeaderboard,
    getMyActivity,
}: IMembersTabProps) => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchValue, setSearchValue] = useState('')

    const [disabledButtons, setDisabledButtons] = useState<FILTER_KEYS[]>([])

    const initialInvitedReferralIds = useMemo(() => invitedMembers?.map((member: IMember) => member.referralId), [invitedMembers.length])
    const initialNotInvitedMemberIds = useMemo(() => notInvitedMembers?.map((member: IMember) => member.referralId), [notInvitedMembers.length])
    const initialMissingActivityIds = useMemo(() => missingActivity?.map((activity: IActivityItem) => activity?.userRecord?.userId) as number[], [missingActivity.length])

    const [invitedReferralIds, setInvitedReferralIds] = useState(initialInvitedReferralIds)
    const [notInvitedReferralIds, setNotInvitedReferralIds] = useState(initialNotInvitedMemberIds)
    const [missingActivityMemberIds, setMissingActivityMemberIds] = useState(initialMissingActivityIds)

    const { t } = useTranslation('ignite/dashboard');
    const allMembers = [...invitedMembers, ...notInvitedMembers, ...members]

    const [filteredMembers, setFilteredMembers] = useState(allMembers)
    const { tabFilter, setTabFilter, applyTabFilter } = useTabFilter(allMembers, setFilteredMembers)
    const [filter, filteredMembersActivity, setFilteredMembersActivity, setFilter, applySort] = useSortMembersActivity(filteredMembers)
    const { isDesktop, isMobile } = useCheckDevice()

    useEffect(() => {
        applySort()
    }, [filteredMembers])

    useEffect(() => {
        setFilteredMembers(allMembers)
        applyTabFilter(allMembers)
    }, [members])

    const onChangeOrder = (value: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(value);
    };
    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const onClickSearch = () => {
        const formattedSearchValue = searchValue.toLocaleLowerCase().trim()
        const newMemberActivity: IActivityItem[] = filteredMembersActivity.filter((user: IActivityItem) => {
            const formattedFirstName = user?.firstName?.toLocaleLowerCase() || user?.userRecord?.firstName || ''
            const formattedLastName = user?.lastName?.toLocaleLowerCase() || user?.userRecord?.lastName || ''
            const formattedEmail = user?.email || ''

            return formattedFirstName.includes(formattedSearchValue) ||
                formattedLastName.includes(formattedSearchValue) || formattedEmail.includes(formattedSearchValue)
        })

        setFilteredMembersActivity(newMemberActivity)
    }

    const onCleanSearch = () => {
        setSearchValue('')
        setFilteredMembersActivity(filteredMembers)
    }

    const onCloseSearching = () => {
        setIsSearching(false)
        setSearchValue('')
        setFilteredMembersActivity(filteredMembers)
    }

    const [memberTabValue, setMemberTabValue] = useState(MEMBER_TABS.ALL_MEMBERS_TAB);

    const onMembersTabChange = (event: React.ChangeEvent<{}>, newValue: MEMBER_TABS) => {
        setMemberTabValue(newValue);
        if (newValue === MEMBER_TABS.NOT_INVITED_TAB) {
            setTabFilter(FILTER_KEYS.NOT_INVITED)
        }
        if (newValue === MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB) {
            setTabFilter(FILTER_KEYS.INVITED_NOT_SIGNED_UP)
        }
        if (newValue === MEMBER_TABS.ALL_MEMBERS_TAB) {
            setTabFilter(FILTER_KEYS.ALL_MEMBERS)
        }
        if (newValue === MEMBER_TABS.MISSING_ACTIVITY_TAB) {
            setTabFilter(FILTER_KEYS.MISSING_ACTIVITY)
        }
    };

    const isNotInvitedTab = tabFilter === FILTER_KEYS.NOT_INVITED
    const isInvitedTab = tabFilter === FILTER_KEYS.INVITED_NOT_SIGNED_UP

    const shouldShowActionButton = tabFilter !== FILTER_KEYS.ALL_MEMBERS && Boolean(filteredMembersActivity.length)

    const actionButtonCount = isNotInvitedTab ? notInvitedReferralIds.length : (isInvitedTab ? invitedReferralIds.length : missingActivityMemberIds.length)

    const actionButtonText = isNotInvitedTab ? t('inviteAll') : t('remindAll')

    const onActionButtonClick = () => {
        if (tabFilter === FILTER_KEYS.INVITED_NOT_SIGNED_UP) {
            postRemindInvitedMembers({
                ClubId: club?.id,
                ReferralIds: invitedReferralIds,
            }).then(() => setDisabledButtons([...disabledButtons, FILTER_KEYS.INVITED_NOT_SIGNED_UP])).
                then(() => setInvitedReferralIds([]))
        } else if (isNotInvitedTab) {
            postInviteNotInvitedMembers({
                ClubId: club?.id,
                ReferralIds: notInvitedReferralIds,
            }).then(() => setDisabledButtons([...disabledButtons, FILTER_KEYS.NOT_INVITED])).
                then(() => setNotInvitedReferralIds([]))
        } else {
            postRemindMissingActivity({
                ClubId: club?.id,
                UserIds: missingActivityMemberIds,
            }).then(() => setDisabledButtons([...disabledButtons, FILTER_KEYS.MISSING_ACTIVITY])).
                then(() => setMissingActivityMemberIds([]))
        }
    }

    const changeInvitedReferralIds = (id: number) => {
        if (invitedReferralIds.includes(id)) {
            setInvitedReferralIds(invitedReferralIds.filter(oldRefId => id !== oldRefId))
        }
    }

    const changeNotInvitedReferralIds = (id: number) => {
        if (notInvitedReferralIds.includes(id)) {
            setNotInvitedReferralIds(notInvitedReferralIds.filter(oldRefId => id !== oldRefId))
        }
    }

    const changeMissingActivityMemberIds = (id: number) => {
        if (missingActivityMemberIds.includes(id)) {
            setMissingActivityMemberIds(missingActivityMemberIds.filter(oldId => id !== oldId))
        }
    }

    const getTabSubtitleText = () => {
        if (tabFilter === FILTER_KEYS.INVITED_NOT_SIGNED_UP) return `${t('invitedNotSignedUp')} (${invitedMembers.length})`
        if (tabFilter === FILTER_KEYS.MISSING_ACTIVITY) return `${t('missingActivity')}  (${filteredMembersActivity.length})`
        if (tabFilter === FILTER_KEYS.NOT_INVITED) return `${t('notInvited')} (${notInvitedMembers.length})`
        return `${t('allMembers')} (${allMembers.length})`
    }

    const routeMembersTab = (isForward: boolean) => (e: any) => {
        const keys = Object.keys(MEMBER_TABS)
        const nextIndex = goThroughArr(keys, memberTabValue, isForward)
        const tabValue = MEMBER_TABS[keys[nextIndex] as MEMBER_TABS]

        onMembersTabChange(e, tabValue)
    }

    return (
        <div className="members-tab-content">
            {isDesktop ? (
                <Tabs
                    value={memberTabValue}
                    onChange={onMembersTabChange}
                    className='members-tabs'
                    items={[
                        { label: t(`memberTabs.${MEMBER_TABS.ALL_MEMBERS_TAB}`), value: MEMBER_TABS.ALL_MEMBERS_TAB },
                        { label: t(`memberTabs.${MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB}`), value: MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB },
                        { label: t(`memberTabs.${MEMBER_TABS.NOT_INVITED_TAB}`), value: MEMBER_TABS.NOT_INVITED_TAB },
                        { label: t(`memberTabs.${MEMBER_TABS.MISSING_ACTIVITY_TAB}`), value: MEMBER_TABS.MISSING_ACTIVITY_TAB }
                    ]}
                />
            ) : (
                (
                    <Tabs
                        value={bodyTabValue}
                        onChange={onBodyTabChange}
                        items={[
                            { label: t(`tabs.${BODY_TABS.LEADER_TO_DO_TAB}`), value: BODY_TABS.LEADER_TO_DO_TAB },
                            { label: t(`tabs.${BODY_TABS.MEMBERS_TAB}`), value: BODY_TABS.MEMBERS_TAB },
                            { label: t(`tabs.${BODY_TABS.LEADERBOARD_TAB}`), value: BODY_TABS.LEADERBOARD_TAB }
                        ]}
                    />
                )
            )}

            {bodyTabValue === BODY_TABS.MEMBERS_TAB || isDesktop ? (
                <>
                    <div className="sorting">
                        <div className="sorting_info">
                            <div className="tab-subtitle">
                                {!isDesktop ? (
                                    <div className="tab-navigation">
                                        <ArrowBackIcon
                                            className="arrow-icon"
                                            onClick={routeMembersTab(false)}
                                        />
                                        {getTabSubtitleText()}
                                        <ArrowForwardIcon
                                            className="arrow-icon"
                                            onClick={routeMembersTab(true)}
                                        />
                                    </div>
                                ) : getTabSubtitleText()}
                            </div>

                            {isDesktop && <SelectOrder
                                filter={filter}
                                filterOptions={filterOptions}
                                onChange={onChangeOrder}
                            />}
                        </div>


                        {!isSearching && (
                            <>
                                {shouldShowActionButton && !isMobile &&
                                    <div className="button-wrapper">
                                        <div className="description">
                                            {isNotInvitedTab ? t('oneClickInvite') : t('oneClickRemind')}
                                        </div>
                                        <Button
                                            className={cn("action-button red", { disabled: disabledButtons?.includes(tabFilter) || actionButtonCount === 0 })}
                                            onClick={onActionButtonClick}
                                            disabled={disabledButtons?.includes(tabFilter) || actionButtonCount === 0}
                                        >
                                            {isNotInvitedTab ? <InviteIcon className="icon" isWhite /> : <BellIcon className="icon" isWhite />}
                                            {actionButtonText}
                                            {' '}
                                            {actionButtonCount}
                                        </Button>
                                    </div>}

                                <div className="search-icon-wrapper" onClick={() => setIsSearching(true)}>
                                    <SearchIcon className='icon' />
                                    {t('search')}
                                </div>
                            </>
                        )}
                    </div>
                    {shouldShowActionButton && isMobile &&
                        <div className="button-wrapper">
                            <div className="description">
                                {isNotInvitedTab ? t('oneClickInvite') : t('oneClickRemind')}
                            </div>
                            <Button
                                className={cn("action-button red", { disabled: disabledButtons?.includes(tabFilter) })}
                                onClick={onActionButtonClick}
                                disabled={disabledButtons?.includes(tabFilter)}
                            >
                                {isNotInvitedTab ? <InviteIcon className="icon" isWhite /> : <BellIcon className="icon" isWhite />}
                                {actionButtonText}
                            </Button>
                        </div>}
                    {!isDesktop && <SelectOrder
                        filter={filter}
                        filterOptions={filterOptions}
                        onChange={onChangeOrder}
                    />}
                    <div>
                        {isSearching && (
                            <SearchPanel
                                onChangeSearch={onChangeSearch}
                                onClickSearch={onClickSearch}
                                onCleanSearch={onCleanSearch}
                                onCloseSearching={onCloseSearching}
                                searchValue={searchValue}
                            />
                        )}
                    </div>
                    <MembersList
                        members={filteredMembersActivity}
                        clubId={club?.id}
                        monthsAmount={isMobile ?
                            memberDashboardConfig.MONTHS_AMOUNT_FOR_MOBILE_FOR_LEADER :
                            memberDashboardConfig.MONTHS_AMOUNT_FOR_DESKTOP
                        }
                        withDetails
                        buttonsDisabled={disabledButtons?.includes(tabFilter)}
                        fetchMembersActivity={fetchMembersActivity}
                        fetchLeaderboard={fetchLeaderboard}
                        fetchMyActivity={getMyActivity}
                        changeInvitedReferralIds={changeInvitedReferralIds}
                        changeNotInvitedReferralIds={changeNotInvitedReferralIds}
                        changeMissingActivityMemberIds={changeMissingActivityMemberIds}
                        invitedReferralIds={invitedReferralIds}
                        notInvitedReferralIds={notInvitedReferralIds}
                        missingActivityMemberIds={missingActivityMemberIds}
                    />
                </>
            ) : <Leaderboard
                    list={leaderboardList}
                    totalCount={leaderboardTotalCount}
                    fetchLeaderboard={fetchLeaderboard}
                />
            }
        </div>
    )
}

export default MembersTab
