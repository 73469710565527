import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import "./MemberRegistrationWizardStep.scss";
import { useFormState } from "react-use-form-state";
import Checkbox from '@material-ui/core/Checkbox';
import { FormHelperText, TextField } from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import PlayButton from "../../../components/common/PlayButton";
import { ButtonStyles } from "../../../components/common/Button";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import { isValidEmail } from "../../../util/Util";

export type MemberAccount = {
    email: string,
    termsOfUse: boolean
}

type StepProp = {
    onContinue: (data: any) => void,
    onPrevious: () => void
}

export function MemberAccountStep(props: StepProp): JSX.Element {
    const [isPosting, setIsPosting] = useState<boolean>(false);
    const {t} = useTranslation('pages');
    const initialState = {
        email: "",
        termsOfUse: false
    }

    const [formState, {text, email, checkbox}] = useFormState(initialState);

    const elementConfig = {
        email: {
            name: 'email',
            validate: (value: string) => {
                if (value.trim() === "") {
                    //return "Email is required.";
                    return " ";
                }
                // Basic validation to prevent simple misentered values
                if (!isValidEmail(value)) {
                    return t('acblAccountStep.invalidEmail');
                }
            },
            validateOnBlur: true
        },
        termsOfUse: {
            name: 'termsOfUse'
        }
    }

    // borrowed from the original form
    const continueEnabled = () => {
        const allInputsTouched = Object.entries(formState.pristine).every(([key, value]) => !value);
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allInputsTouched && allValuesValid;
    }

    const onContinueSelected = async () => {
        setIsPosting(true);
        await props.onContinue({
            email: formState.values.email,
            password: formState.values.password
        });
        setIsPosting(false);
    }

    return (
        <div className={"AcblRegistrationWizard_Wrapper MemberAccountStep"}>
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={8}>
                    <Grid container spacing={1} direction="column"
                          alignItems="stretch">
                        <Grid item>
                            <h2 dangerouslySetInnerHTML={{__html: t('acblAccountStep.title')}}></h2>
                            <FormHelperText>
                                {t('acblAccountStep.formHelperText')}
                            </FormHelperText>
                        </Grid>
                        <Grid item>
                            <FormControl fullWidth margin="normal">
                                <TextField {...email(elementConfig.email)}
                                           className="MemberRegistrationWizard_Input"
                                           label={t('acblAccountStep.emailLabel')}
                                           required
                                           variant={"outlined"}
                                           helperText={formState.errors.email}
                                           error={formState.errors.email !== undefined}
                                           size="small"/>
                            </FormControl>
                            <FormControl>
                                <div
                                    className={"RegistrationFormField_SelfGuidedEventWaiverWrapper"}>
                                    <Checkbox {...checkbox(elementConfig.termsOfUse)}
                                              style={{paddingLeft: 0}}/>
                                    <FormLabel>
                                        {t('acblAccountStep.acceptTOS')}&nbsp;
                                        <Link
                                            to={{pathname: "/terms-of-use"}}
                                            target="_blank"
                                            className={"RegistrationForm_TermsOfUseLink"}
                                        >{t('acblAccountStep.tosLink')}</Link>
                                    </FormLabel>
                                </div>
                            </FormControl>
                        </Grid>
                        <Grid item container justify="flex-end">
                            <FormControl>
                                <PlayButton type="button"
                                            clickHandler={onContinueSelected}
                                            text={t('acblAccountStep.registerButtonText')}
                                            className="MemberRegistrationWizard_SubmitButton"
                                            buttonStyle={ButtonStyles.FilledPrimary}
                                            disabled={!continueEnabled() || isPosting}
                                            id="EmailPassSubmit"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
