import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface ISuccessfulJoiningModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    clubName?: string
}

export const SuccessfulJoiningModal = ({
    isOpen,
    onClose,
    clubName
}: ISuccessfulJoiningModalProps) => {
    const { t } = useTranslation('ignite/myInfo');

    return (
        <Modal open={isOpen} className='leave-club-modal'>
            <div className="leave-club-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className='content'>
                    <div className="title">
                        {t('success')}
                    </div>
                    <div className="description">
                        {t('successfulClubJoiningText', { clubName })}
                    </div>
                    <div className="button-wrapper">
                        <Button
                            className='button confirm'
                            onClick={onClose}
                            classes={{ disabled: 'disabled' }}
                        >
                            {t('close')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    );
}