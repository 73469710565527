import React, { useEffect, useState } from "react";

import "./NavigationElement.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface NavigationElementProps extends RouteComponentProps {
    to?: string,
    setNavigationShown?: Function;
    className?: string;
    children?: any;
    clickHandler?: Function;
}

function NavigationElement(props: NavigationElementProps): JSX.Element {

    const [classes, setClasses] = useState("");
    const [over, setOver] = useState(false);

    function onOver() {
        setOver(true);
    }

    function onOut() {
        setOver(false);
    }

    function onClick() {
        if (props.setNavigationShown !== undefined) {
            props.setNavigationShown(false);
        }
        if (props.clickHandler !== undefined) {
            props.clickHandler();
            return;
        }
        else if (props.to !== undefined) {
            props.history.push(props.to);
        }
    }

    useEffect(
        () => {
            var classes = "NavigationElement";
            if ( props.to === props.location.pathname || props.location.pathname.startsWith(String(props.to))) {
                classes += " NavigationElement_Current";
            }
            if (props.className !== undefined) {
                classes += " " + props.className;
            }
            if (over) {
                classes += " NavigationElement_Over";
            }

            setClasses(classes);

        }, [props, over])

    return (
        <div className={classes} onClick={onClick} onMouseOver={onOver} onMouseOut={onOut}>
            {props.children}
        </div>
    )
}

export default withRouter(NavigationElement);