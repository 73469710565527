import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';

import "./StaticLinks.scss";
import {Link} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";


export default function StaticLinks(): JSX.Element {
    const { t } = useTranslation('layout');
    const {hasLeadershipRoles} = useContext(UserContext);

    return (
        <div className={`StaticLinks`}>
            {hasLeadershipRoles() &&
            <Link
                className={"FooterLink"}
                to={"/club-agreement"}
                target={"_blank"}>{t('footer.clubAgreement')}</Link>
            }
            <Link className={`FooterLink`}
                  to="/about"
                  target={"_blank"}>{t('footer.about')}</Link>
            {hasLeadershipRoles() &&
            <Link
                className={`FooterLink`}
                to="/club-leader-terms-of-use"
                target={"_blank"}>{t('footer.leaderTermsOfUse')}</Link>
            }
            <Link
                className={`FooterLink`}
                to="/terms-of-use"
                target={"_blank"}>{t('footer.memberTermsOfUse')}</Link>
            <a
                className={`FooterLink`}
                href="https://hellogrouper.com/privacy-policy/"
                target={"_blank"}>{t('footer.privacyPolicy')}</a>
            {hasLeadershipRoles() &&
            <Link
                className={`FooterLink`}
                to="/club-leader-faq"
                target={"_blank"}>{t('footer.faq')}</Link>
            }
        </div>
    );
}
