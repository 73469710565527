
import { loadConfig } from '../services/ConfigService'
import { getJson } from '../services/RequestService';
import { GET_CLUBS_V2_URL, IClubV2SearchParams, IClubV2SearchResponse } from '../types/clubFinder';
import { authenticatedFetch } from '../services/UserService';
import { getJsonAuth } from '../services/RequestService';

export const getClubsV2 = async (params: IClubV2SearchParams): Promise<IClubV2SearchResponse| undefined> => {
    const config = await loadConfig();
    return await getJsonAuth({
    authenticatedFetch,
    url: `${config.apiOrigin}${GET_CLUBS_V2_URL}`,
    searchParams: params
});
    
}


