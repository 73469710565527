import React, { useEffect, useRef, useState } from "react";
import {
    Checkbox, TableFooter, Table, TableBody,
    TableContainer, TableRow, TablePagination
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Create';
import EyeIcon from '@material-ui/icons/Visibility';
import MonitorIcon from '@material-ui/icons/Tv';
import PersonIcon from '@material-ui/icons/Person';
import PaginationActions from "./PaginationActions";
import { useTranslation } from "react-i18next";
import { memberDashboardConfig } from "../../../../configs/memberDashboard";
import SelectionPanel from "../SelectionPanel";
import { IClub } from "../../../../types/clubFinder";
import { MEMBER_TABS } from "../../../../constants/dashboard";
import { IUseSelectLogicReturn, IUser } from "../../../../types/dashboard";
import cn from "classnames";

// Create a new type for the TablePagination component with 'any' type
// to address a TypeScript problem related to passing the onPageChange prop,
// inexplicably, a TypeScript error is encountered in this scenario.
const TablePaginationAny = TablePagination as any;

interface IMembersTableMobileProps {
    rows: IUser[]
    openMemberInviteModal: Function
    selectionData: IUseSelectLogicReturn
    memberTabValue: MEMBER_TABS
    onRemoveMembers: Function
    onEditMember: Function
    onUpdateRoles: Function
    club: IClub
    isModalOpen: boolean
    getIsDisabledEditIcon: Function
    fetchInvitedMembers: Function
    fetchNotInvitedMembers: Function
    fetchMissingActivityMembers: Function
    fetchMembers: Function
    isInvitedMembersTab: boolean
    isNotInvitedMembersTab: boolean
    isAllMembersTab: boolean
    isMissingActivityMembersTab: boolean
    getTotalCount: Function
    remindMembers: Function
    isDisabledViewIcon: boolean
    setLogActivityId: Function
    searchValue: string
}

export function MembersTableMobile({
    rows,
    onRemoveMembers,
    onUpdateRoles,
    club,
    memberTabValue,
    onEditMember,
    openMemberInviteModal,
    selectionData,
    getIsDisabledEditIcon,
    fetchInvitedMembers,
    fetchNotInvitedMembers,
    fetchMissingActivityMembers,
    fetchMembers,
    isInvitedMembersTab,
    isNotInvitedMembersTab,
    isAllMembersTab,
    isMissingActivityMembersTab,
    getTotalCount,
    remindMembers,
    isDisabledViewIcon,
    setLogActivityId,
    searchValue
}: IMembersTableMobileProps) {
    const [page, setPage] = useState(0);
    const { selectedCount, handleSelectAllClick, isSelected, handleSelectClick } = selectionData
    const { t } = useTranslation('ignite/dashboard')
    const rowsPerPage = memberDashboardConfig.MEMBERS_AMOUNT_PER_PAGE
    const timerRef = useRef<null | number>(null)

    const fetchMembersCurrentMembers = (skip = 0) => {
        if (isInvitedMembersTab) fetchInvitedMembers({ skip, searchValue })
        if (isNotInvitedMembersTab) fetchNotInvitedMembers({ skip, searchValue })
        if (isAllMembersTab) fetchMembers({ skip, searchValue })
        if (isMissingActivityMembersTab) fetchMissingActivityMembers({ skip, searchValue })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        const skip = newPage * rowsPerPage

        setPage(newPage);

        fetchMembersCurrentMembers(skip)
    };

    useEffect(() => setPage(0), [memberTabValue])

    useEffect(() => {
        setPage(0)
        if (timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = Number(setTimeout(() =>
            fetchMembersCurrentMembers(), memberDashboardConfig.SEARCH_MEMBERS_INPUT_DEBOUNCE_DELAY))
    }, [searchValue])

    return (
        <TableContainer>
            <Table aria-labelledby="tableTitle">
                {selectedCount > 0 &&
                    <SelectionPanel
                        onSelectAllClick={handleSelectAllClick}
                        amount={selectedCount}
                        openMemberInviteModal={openMemberInviteModal}
                        onRemoveMembers={onRemoveMembers}
                        memberTabValue={memberTabValue}
                        onUpdateRoles={onUpdateRoles}
                        club={club}
                        remindMembers={remindMembers}
                    />}
                <TableBody className="members-table-mobile">
                    {rows.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const isItemSelected = isSelected(row.email);
                        const isDisabledEditIcon = getIsDisabledEditIcon(row)

                        return (
                            <>
                                <div className="member-item">
                                    <div className="top-content">
                                        <div className={cn("left-content", { 'with-margin': memberTabValue === MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB })}>
                                            {memberTabValue !== MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB && (
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={() => handleSelectClick(row.email)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />)}

                                            {row.fullName}
                                        </div>
                                        <div className="right-content">
                                            <EyeIcon
                                                className={cn("cell-icon view", { disabled: isDisabledViewIcon })}
                                                onClick={() => setLogActivityId(row.userId)}
                                            />
                                            <EditIcon
                                                className={cn("cell-icon edit", { disabled: isDisabledEditIcon })}
                                                onClick={() => onEditMember(row)}
                                            />
                                        </div>
                                    </div>
                                    <div className="middle-content">
                                        {memberTabValue !== MEMBER_TABS.NOT_INVITED_TAB && memberTabValue !== MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB ?
                                            <div className="row">
                                                <div className="label-value-block">
                                                    <div className="label">
                                                        {t('eligibility')}
                                                        :
                                                    </div>
                                                    <div className="value">
                                                        {row.eligibility}
                                                    </div>
                                                </div>
                                            </div> : null
                                        }
                                        <div className="row">
                                            {/* temporarily hide according AT-548 */}
                                            {/* <div className="label-value-block">
                                            <div className="label">
                                                {t('status')}
                                                :
                                            </div>
                                            <div className="value">
                                                {row.status}
                                            </div>
                                        </div> */}
                                            {memberTabValue !== MEMBER_TABS.NOT_INVITED_TAB && (
                                                <div className="label-value-block">
                                                    <div className="label">
                                                        {t('role')}
                                                        :
                                                    </div>
                                                    <div className="value">
                                                        {row.role}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="label-value-block">
                                            <div className="label">
                                                {t('lastActivity')}
                                                :
                                            </div>
                                            <div className="value">
                                                {row.lastActivity}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-content">
                                        <div className="label-value-block">
                                            <div className="label">
                                                {t('online')}
                                                :
                                            </div>
                                            <div className="value">
                                                {row.activityTotalVirtual}
                                                <MonitorIcon className="cell-icon" />
                                            </div>
                                        </div>
                                        <div className="label-value-block">
                                            <div className="label">
                                                {t('inPerson')}
                                                :
                                            </div>
                                            <div className="value">
                                                {row.activityTotalInPerson}
                                                <PersonIcon className="cell-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePaginationAny
                            count={getTotalCount()}
                            onPageChange={handleChangePage}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            ActionsComponent={PaginationActions}
                            classes={{
                                caption: 'display-none',
                                input: 'display-none',
                                spacer: 'display-none',
                                toolbar: 'toolbar',
                                actions: 'actions'
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer >
    );
}
