import React from "react";
import "./MapLink.scss";
import {GetLocationResponseBody} from "../../services/Models";

interface MapLinkProps {
    locationAddress1?: string | null,
    locationAddress2?: string | null,
    locationCity?: string | null,
    locationState?: string | null,
    locationZip?: string,
    children: any,
    className?: string,
    location?: GetLocationResponseBody|null|undefined;
}

export default function MapLink(props: MapLinkProps): JSX.Element {

    let classes = 'CCMapLink';
    let className = props.className;
    let children = props.children;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    let url = 'https://www.google.com/maps/search/?api=1&query=';

    url = checkAndAppend(props.locationAddress1, url);
    url = checkAndAppend(props.locationCity, url);
    url = checkAndAppend(props.locationState, url);
    url = checkAndAppend(props.locationZip, url);

    if (props.location !== undefined && props.location !== null) {
        url = 'https://www.google.com/maps/search/?api=1&query=';
        url = checkAndAppend(props.location.streetAddressOne, url);
        url = checkAndAppend(props.location.city, url);
        url = checkAndAppend(props.location.countrySubdivision, url);
        url = checkAndAppend(props.location.postalCode, url);
    }

    function checkAndAppend(toCheck: string | null | undefined, url: string) {
        if (toCheck === undefined || toCheck === null) {
        } else {
            url += toCheck.split(' ').join('+') + '+';
        }
        return url;
    }

    return (
        <div className={classes}>
            <a target="_new" href={url}>{children}</a>
        </div>
    );
}