import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import "../NewMemberRegistrationWizard.scss";
import Button, {ButtonStyles} from "../../../components/common/Button";
import { getAllPassions } from "../../../services/ClubLeaderService";
import { GetPassionResponseBody } from "../../../services/Models";
import LoadingMessage from "../../../components/common/LoadingMessage";
import {getClubImageUrl} from "../../../util/Util";
import Checkmark from "../../../components/icon/Checkmark";
import {DoubleArrow} from "@material-ui/icons";
import Loading from "../../../components/common/Loading";

type MemberPassions = {
    onContinue: (data: any) => void,
    onPrevious: () => void,
    selectedPassions: number[],
}

export default function NewMemberPassionsStep(props: MemberPassions): JSX.Element {
    const { t } = useTranslation('pages');

    const [allPassions, setAllPassions] = useState<GetPassionResponseBody[]>([]);
    const [currentSelectedPassionIds, setCurrentSelectedPassionIds] = useState<number[]>(props.selectedPassions);
    const [loading, setLoading] = useState<boolean>(true);
    const [saving, setSaving] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const continueEnabled = () => {
        return true;
    }

    const onSkip = () => {
        props.onContinue({passions: []});
    }

    const onContinueSelected = () => {
        setSaving(true);
        props.onContinue({passions: currentSelectedPassionIds});
    }

    function isSelected(passion: GetPassionResponseBody) {
        return currentSelectedPassionIds.includes(passion.id);
    }

    function onClickPassion(passionId: number) {
        if (currentSelectedPassionIds.includes(passionId)) {
            setCurrentSelectedPassionIds(
                currentSelectedPassionIds.filter(id => id !== passionId));
        } else {
            setCurrentSelectedPassionIds([...currentSelectedPassionIds, passionId]);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setAllPassions((await getAllPassions()).allPassions
                    .sort((a, b) => a.passionName.localeCompare(b.passionName)));
            } catch (e) {
                setErrorMessage(t('newMemberRegistration.unexpectedError'))
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <div className={"NewMemberRegistrationWizard_Wrapper NewMemberPassionsStep"}>
            <h2>{t('newMemberRegistration.passionsHeader')}</h2>
            <div className="NewMemberPassionsStep_SubHeader">{t('newMemberRegistration.passionsSubHeader')}</div>
            <div>
                <div className="SelectPassions_Passions">
                    {allPassions.map(passion => {
                        const selected = isSelected(passion);
                        return (
                            <div key={passion.id}
                                 className={`${selected ? "selected" : ""} SelectPassions_Passion`}
                                 onClick={() => onClickPassion(passion.id)}
                                 style={{backgroundImage: `url(${getClubImageUrl(passion).thumbnail})`}}>
                                <div className="SelectPassions_Passion_Top">
                                    <div
                                        className="SelectPassions_Passion_Overlay"></div>
                                    <Checkmark/>
                                </div>
                                <div className="SelectPassions_Passion_Bottom">
                                    <div
                                        className="SelectPassions_Passion_PassionName">{passion.passionName.replace(new RegExp("(\\S)/(\\S)"), '$1 / $2')}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {!loading && errorMessage !== null && (
                    <LoadingMessage message={errorMessage}/>
                )}
            </div>
            <div className={"NewMemberPassionsStep_NextContainer"}>
                {!saving && <Button type="button"
                            id="PassionSubmit"
                            clickHandler={onContinueSelected}
                            text={t('newMemberRegistration.nextButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                            disabled={!continueEnabled()}
                />}
                {saving && <Loading className={"NewMemberRegistrationWizard_SubmitButtonLoading"} loading={saving}/>}
            </div>
            <div className={"NewMemberPassionsStep_SkipLinkContainer"}>
                <div className={"NewMemberPassionsStep_SkipLink"} onClick={onSkip}>
                    <span>{t('newMemberRegistration.skipText')}</span>&nbsp;<DoubleArrow/>
                </div>
            </div>
        </div>
    );
}
