
import React from "react";
import { useTranslation } from 'react-i18next';
import cn from 'classnames'
import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@material-ui/core";

export const ProfilePanel = ({ isEditing, children, title, onEdit, className }:
    { isEditing?: boolean, children: JSX.Element, title: string, onEdit?: Function, className?: string }) => {
    const { t } = useTranslation('ignite/myInfo');

    return (
        <div className={cn("profile-panel", { editing: isEditing, [String(className)]: className })}>
            <div className="top-content">
                <div className="title">
                    {title}
                </div>
                {!isEditing && isEditing !== undefined && <div className='button-wrapper'>
                    <div
                        className="edit-button"
                        onClick={() => onEdit && onEdit()}
                    >
                        <IconButton className='icon-wrapper'>
                            <EditIcon />
                            <span hidden aria-label={t('edit')}>{t('edit')}</span>
                        </IconButton>
                        {t('edit')}
                    </div>
                </div>}
            </div>
            <div className="bottom-content">
                {children}
            </div>
        </div>
    );
}
