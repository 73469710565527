import React from "react";
import { Box, Chip } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';

interface IChipsListProps {
    removeSelectedToken: Function
    selectedTokens: Array<string>
}

const ChipsList = ({ selectedTokens, removeSelectedToken }: IChipsListProps): JSX.Element => {
    return (
        <Box className="chip-list">
            {selectedTokens.map(token =>
                <Chip
                    variant="outlined"
                    className="chip-list__item"
                    label={token}
                    deleteIcon={<ClearIcon />}
                    onDelete={() => { removeSelectedToken(token) }}
                    key={token}
                />)}
        </Box>
    )
}

export default ChipsList
