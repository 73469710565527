import React from "react";
import "./EventSearchResults.scss";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {
    EventSearchSort
} from "../../pages/findAnEvent/FindAnEventPage";

interface SortableHeaderProps {
    sortByFunction: () => void,
    sortByEnum: EventSearchSort,
    sort: EventSearchSort,
    reverse: boolean,
    label: string
}

export default function SortableHeader(props: SortableHeaderProps): JSX.Element {
    return (
        <>
            <div className="SortButton" onClick={props.sortByFunction}>
                <div className="SortText">{props.label}</div>
                {props.sort === props.sortByEnum &&
                    !props.reverse &&
                    <ArrowDropUpIcon className="SortIcon"/>
                }
                {props.sort === props.sortByEnum &&
                    props.reverse &&
                    <ArrowDropDownIcon className="SortIcon"/>
                }
                {props.sort !== props.sortByEnum &&
                    <ArrowDropUpIcon className="SortIcon" color="disabled" />
                }
            </div>
        </>
    );
}
