import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./OptumSelectPlan.scss";
import {loadConfig} from "../../services/ConfigService";
import Loading from "../../components/common/Loading";
import LoadingMessage from "../../components/common/LoadingMessage";
import {
    FormControlLabel,
    FormHelperText,
    MenuItem, Radio, RadioGroup,
    Select,
    TextField
} from "@material-ui/core";
import {useFormState} from "react-use-form-state";
import {
    GetAllPayersResponseBody,
    GetPayerResponseBody
} from "../../services/MemberService";
import {readRegistrationParametersFromSession} from "../../util/Util";

interface OptumSelectPlanProperties {
    //function that exposes the payer form state. See the react-use-form-state docs for the structure of this object.
    onChange: Function
}

export default function OptumSelectPlan(props: OptumSelectPlanProperties): JSX.Element {
    const { t } = useTranslation('pages');
    const { memberCode } = readRegistrationParametersFromSession();
    const initialState = {
        planType: !!memberCode ? "renew" : "",
        payerId: "",
        insuranceId: memberCode ?? "",
    }

    const [formState, {text, radio}] = useFormState(initialState, {
        onChange(e, stateValues, nextStateValues) {
            props.onChange(nextStateValues);
        }
    });
    const onChange = props.onChange;

    useEffect(() => {
        //propagate the initial form state up to any subscribing components
        onChange(formState.values);
    }, [onChange, formState.values])

    const {planType, insuranceId} = formState.values;

    const optumRadioOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formState.setField("insuranceId", "");
        formState.setField("planType", (event.target as HTMLInputElement).value);
        formState.setField("payerId", optumPayer?.id);
    };

    const elementConfig = {
        planType: {
            name: "planType",
            defaultValue: "renew",
            onChange: (value: string) => {
                formState.setField("insuranceId", "");
                formState.setField("planType", value);
            },
            validate: (value: string): string | undefined => {
                if (value === "renew" || value === "other") {
                    return;
                }
                return t('optumSelectPlan.validateText');
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (formState.values.planType === "renew") {
                    if (value === "") {
                        return t('optumSelectPlan.enterCode');
                    }
                    const validRenewIdRegex = /^[SAsa][0-9]{9}$/;
                    if (!value.match(validRenewIdRegex)) {
                        return t('optumSelectPlan.invalidCode');
                    }
                    return;
                }

                if (value === "") {
                    return t('optumSelectPlan.enterHealthPlanId');
                }
            },
            validateOnBlur: true
        }
    }

    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const optumPayer : GetPayerResponseBody | null =
        allPayers.find(p => p.payerSlug === "united-healthcare") || null;
    const [insuranceIdLabel, setInsuranceIdLabel] = useState<string>('');

    useEffect(() => {
        formState.setField("payerId", optumPayer?.id);
    }, [optumPayer]);

    useEffect(() => {
        if (formState.values.planType) {
            if (formState.values.planType === "renew") {
                setInsuranceIdLabel(t('optumSelectPlan.insuranceIdLabelCode'));
            }
            else if (formState.values.planType === "other"){
                setInsuranceIdLabel(t('optumSelectPlan.insuranceIdLabelId'));
            }
            else {
                setInsuranceIdLabel("");
            }
        }
    }, [formState.values.planType])

    useEffect(() => {

        const loadAllPayers = async () => {

            const config = await loadConfig();

            const request = new Request(`${config.apiOrigin}/all-payers`);

            // This is one of the rare, non-authenticated API endpoints.
            const response = await fetch(request);

            if (!response.ok) {
                const responseBodyText = await response.text();

                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);

                setLoading(false);
                setLoadingErrorMessage(t('optumSelectPlan.loadingError'));

                return;
            }

            const responseBody: GetAllPayersResponseBody = await response.json();

            setAllPayers(responseBody.allPayers);
            setLoading(false);
            setLoadingErrorMessage(null);

        };

        loadAllPayers();

    }, []);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="OptumSelectPlan">
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <LoadingMessage message={loadingErrorMessage}/>
            )}
            <form className="OptumSelectPlan_Form">
                <div className="OptumSelectPlan_Fields">
                    <RadioGroup name="OptumSelectPlan_group"
                                value={formState.values.planType}
                                onChange={optumRadioOnChange}
                    >
                            <FormControlLabel id="OptumSelectPlan_renew"
                                              value="renew"
                                              control={<Radio />}
                                              label={t('optumSelectPlan.renewLabel')}/>
                            <FormControlLabel id="OptumSelectPlan_other"
                                              value="other"
                                              control={<Radio />}
                                              label={t('optumSelectPlan.healthPlanIdLabel')}/>
                    </RadioGroup>

                    {formState.values.planType !== null && formState.values.planType !== "" &&
                    <>
                        <TextField {...text(elementConfig.insuranceId)}
                                   error={formState.errors.insuranceId !== undefined}
                                   className="OptumSelectPlan_InsuranceIdField"
                                   required
                                   inputProps={{maxLength: 50}}
                                   label={insuranceIdLabel}
                                   variant="outlined"
                        />
                        {formState.values.planType === "renew" &&
                        <FormHelperText>
                            {t('optumSelectPlan.codeHelperText')}
                        </FormHelperText>
                        }
                    </>
                    }
                </div>

                {Object.entries(formState.errors).length > 0 &&
                <div className="OptumSelectPlan_FormErrors">
                    {Object.entries(formState.errors).map(([key, value]) =>
                        <div key={key}
                             className="OptumSelectPlan_FormError">
                            {value}
                        </div>)
                    }
                </div>
                }

            </form>
        </div>
    )
}
