import { getSanityResponse } from "./SanityUtils";

export interface ClubAssociationSanityResponse {
    ms: number;
    query: string;
    result: ClubAssociationImageData[];
}

export interface ClubAssociationImageData {
    associationImage: string;
}
 
const getClubAssociationImageViaSanity = async (shortCode: string): Promise<ClubAssociationSanityResponse | undefined> => {
    const query = encodeURIComponent(`
        *[_type == "association" && lower(slug) == "${shortCode}"]
        {

            "associationImage": associationImage.asset->url,
        }
    `);

    return getSanityResponse(query);
}

export default getClubAssociationImageViaSanity