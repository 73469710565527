import { useState, useEffect } from "react";
import { GetClubResponseBody } from "../services/MemberService";
import getClubByUrlFragment from "../api/getClubByUrlFragment";

const useGetClubByUrlFragment = (clubUrlFragment: string): [GetClubResponseBody | undefined, boolean] => {
    const [club, setClub] = useState<GetClubResponseBody | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getClubByUrlFragment(clubUrlFragment)
            .then(data => setClub(data))
            .finally(() => {
                setIsLoading(false)
            })
    }, [clubUrlFragment])

    return [club, isLoading]
}

export default useGetClubByUrlFragment
