import React from "react";

const iconCircleCheck = require("../../assets/icon-circle-check.svg");

interface IconCircleCheckProps {
    className?: string
}

export default function IconCircleCheck(props: IconCircleCheckProps) {
    return <img className={props.className} src={iconCircleCheck} alt="circle check icon"/>
}
