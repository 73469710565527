import {getSanityResponse, SanityTextObject} from "./SanityUtils";

export interface UserSanityResponse {
    ms: number;
    query: string;
    result: UserSanityData[];
}

export interface UserSanityData {
    email: string;
    funFact: string;
    bio: SanityTextObject;
    personalQuote : string;
    showEmailInBioPopup: boolean;
    avatar: string;
} 
const getUserViaSanity = async (email: string): Promise<UserSanityResponse | undefined> => {
    let query = encodeURIComponent(`
    *[_type == "user" && lower(email) == "${email}"]
    {
        email, _id, bio, funFact, personalQuote, showEmailInBioPopup, avatar, 
        "avatar": avatar.asset->url,
    }
`);

    return getSanityResponse(query);
}

export default getUserViaSanity
