import React from "react";
import { Checkbox, TableSortLabel, TableCell, TableHead, TableRow, Box } from '@material-ui/core'
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { MEMBER_TABS } from "../../../../constants/dashboard";
interface HeadCell {
    id: any;
    label: string;
    numeric: boolean;
}

interface IMembersTableHead {
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    order: Order,
    orderBy: string,
    numSelected: number,
    rowCount: number,
    onRequestSort: Function,
    memberTabValue: MEMBER_TABS
}

enum Order {
    ASC = 'asc',
    DESC = 'desc'
}

const getHeadCells = (t: Function, memberTabValue: MEMBER_TABS) => {
    return [
        // temporarily hide according AT-548
        // {
        //     id: 'status',
        //     numeric: false,
        //     label: t('status'),
        // },
        memberTabValue !== MEMBER_TABS.NOT_INVITED_TAB && memberTabValue !== MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB ? {
            id: 'eligibility',
            numeric: false,
            label: t('eligibility'),
        } : null,
        {
            id: 'fullName',
            numeric: false,
            label: t('name'),
        },
        memberTabValue !== MEMBER_TABS.NOT_INVITED_TAB ? {
            id: 'role',
            numeric: false,
            label: t('role'),
        } : null,
        {
            id: 'lastActivity',
            numeric: true,
            label: t('lastActivity'),
        },
        {
            id: 'activityTotalVirtual',
            numeric: true,
            label: t('online'),
        },
        {
            id: 'activityTotalInPerson',
            numeric: true,
            label: t('inPerson'),
        },
    ].filter((headerItem) => headerItem)
}

export default function MembersTableHead(props: IMembersTableHead) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        memberTabValue
    } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => onRequestSort(event, property)
    const { t } = useTranslation('ignite/dashboard')
    const headCells = getHeadCells(t, memberTabValue) as HeadCell[]

    return (
        <TableHead>
            <TableRow>
                {memberTabValue !== MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB && (
                    <TableCell padding="checkbox" className="header-cell checkbox-cell">
                        <Checkbox
                            className="checkbox"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                )}
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        className="header-cell"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : Order.ASC}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowDropUpIcon}
                            classes={{
                                icon: cn('sort-icon', { 'is-desc': order === Order.DESC }),
                                active: 'active'
                            }}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
