import { useState, useEffect } from "react";
import getClubs from '../api/getClubs'
import { IClub } from '../types/clubFinder'

const useGetClubs = (): [[IClub] | undefined, boolean] => {
    const [clubs, setClub] = useState<[IClub] | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getClubs()
            .then(data => {
                if(data){
                    data.sort((a, b) => a.clubName.toLowerCase().localeCompare(b.clubName.toLowerCase()));
                }
                setClub(data)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [clubs, isLoading]
}

export default useGetClubs
