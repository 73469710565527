import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';

import "./ClubAgreementPage.scss"

export default function ClubAgreementPage(): JSX.Element {
    const { t } = useTranslation('pages');
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className={"ClubAgreementPage"}>
            <header>
                <h1 className="title">{t('clubAgreementPage.header')}<br/></h1>
            </header>
            <p>{t('clubAgreementPage.date')}</p>
            <p dangerouslySetInnerHTML={{__html: t('clubAgreementPage.p1')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('clubAgreementPage.p2')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('clubAgreementPage.p3')}}></p>
            <p>{t('clubAgreementPage.p4')}</p>
            <h1 id="general">{t('clubAgreementPage.generalH')}</h1>
            <p dangerouslySetInnerHTML={{__html: t('clubAgreementPage.generalP')}}></p>
            <h1 id="confidential-pii">{t('clubAgreementPage.piiH')}</h1>
            <p>{t('clubAgreementPage.piiP')}</p>
            <h1 id="reporting-of-group-member-activity"> {t('clubAgreementPage.reportingH')}</h1>
            <p dangerouslySetInnerHTML={{__html: t('clubAgreementPage.reportingP')}}></p>
            <h1 id="payment-of-group-member-fees">{t('clubAgreementPage.paymentH')}</h1>
            <p dangerouslySetInnerHTML={{__html: t('clubAgreementPage.paymentP')}}></p>
            <h1 id="access-and-audit-rights">{t('clubAgreementPage.rightsH')}</h1>
            <p>{t('clubAgreementPage.rightsP')}</p>
            <h1 id="warranty">{t('clubAgreementPage.warrantyH')}</h1>
            <p>{t('clubAgreementPage.warrantyP')}</p>
            <h1 id="non-solicitation">{t('clubAgreementPage.nonsolicitationH')}</h1>
            <p>{t('clubAgreementPage.nonsolicitationP')}</p>
            <h1 id="limitation-of-liability">{t('clubAgreementPage.liabilityH')}</h1>
            <p>{t('clubAgreementPage.liabilityP')}</p>
            <h1 id="waiver">{t('clubAgreementPage.waiverH')}</h1>
            <p>{t('clubAgreementPage.waiverP')}</p>
            <h1 id="general-1">{t('clubAgreementPage.general1')}</h1>
            <p>.</p>
            <h2 id="relationship-of-the-parties"><strong>{t('clubAgreementPage.relationshipH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.relationshipP')}</p>
            </blockquote>
            <h2 id="assignment"><strong>{t('clubAgreementPage.assignmentH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.assignmentP')}</p>
            </blockquote>
            <h2 id="notices"><strong>{t('clubAgreementPage.noticesH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.noticesP')}</p>
            </blockquote>
            <h2 id="governing-law"><strong>{t('clubAgreementPage.governingH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.governingP')}</p>
            </blockquote>
            <h2 id="compliance-with-laws"><strong>{t('clubAgreementPage.complianceH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.complianceP')}</p>
            </blockquote>
            <h2 id="severability"><strong>{t('clubAgreementPage.severabilityH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.severabilityP')}</p>
            </blockquote>
            <h2 id="construction"><strong>{t('clubAgreementPage.constructionH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.constructionP')}</p>
            </blockquote>
            <h2 id="modification"><strong>{t('clubAgreementPage.modificationH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.modificationP')}</p>
            </blockquote>
            <h2 id="counterparts"><strong>{t('clubAgreementPage.counterpartsH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.counterpartsP')}</p>
            </blockquote>
            <h2 id="entire-agreement-no-waiver"><strong>{t('clubAgreementPage.entireH')}</strong></h2>
            <blockquote>
                <p>{t('clubAgreementPage.entireP')}</p>
            </blockquote>
            <p className={"SignatureBlockInstructions"}>{t('clubAgreementPage.instructions')}</p>
            <div className={"SignatureBlockWrapper"}>
                <div className={"SignatureBlock"}>
                    <table>
                    <tr>
                        <td colSpan={2}><strong><span
                            className="smallcaps">{t('clubAgreementPage.signGrouper')}</span></strong></td>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signBy')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signName')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signTitle')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signDate')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signAddress')}:</td>
                        <td className="underline"/>
                    </tr>
                    </table>
                </div>

                <div className={"SignatureBlock"}>
                    <table>
                    <tr>
                        <td colSpan={2}><strong><span
                            className="smallcaps">[{t('clubAgreementPage.signGroup')}]</span></strong></td>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signBy')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signName')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signTitle')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signDate')}:</td>
                        <td className="underline"/>
                    </tr>
                    <tr>
                        <td>{t('clubAgreementPage.signAddress')}:</td>
                        <td className="underline"/>
                    </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}