import React from "react";
import "./SaveIndicator.scss";
import {CheckCircle} from "@material-ui/icons";


interface SaveIndicatorProps {
    message?: string | null
    success: boolean;
    className?: string
    children?: any
}

export default function SaveIndicator(props: SaveIndicatorProps): JSX.Element {

    return (
        <div
            className={`${props.className} CCSaveIndicator ${!props.success && 'CCSaveIndicator_Failure'} ${props.success && 'CCSaveIndicator_Success'}`}>
            <CheckCircle/>
            <div className="CCSaveIndicator_Message">
                {props.message != null && props.message}
            </div>
            </div>
    );
}