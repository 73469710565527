import React, {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./MemberSelectPayer.scss";
import {loadConfig} from "../../services/ConfigService";
import Loading from "../../components/common/Loading";
import LoadingMessage from "../../components/common/LoadingMessage";
import {
    FormHelperText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {useFormState} from "react-use-form-state";
import {
    GetAllPayersResponseBody,
    GetPayerResponseBody
} from "../../services/MemberService";

interface MemberSelectPayerProperties {
    //function that exposes the payer form state. See the react-use-form-state docs for the structure of this object.
    onChange: (formState: MemberSelectPayerFormState, isPayerInfoValid: boolean) => void;
}

export type MemberSelectPayerFormState = {
    payerId: number | undefined;
    insuranceId: string;
    healthPlanName: string | null;
}

export default function MemberSelectPayer(props: MemberSelectPayerProperties): JSX.Element {
    const { onChange } = props;
    const { t } = useTranslation('payer');
    const [formState, {text, select}] = useFormState<MemberSelectPayerFormState>({
        payerId: undefined,
        insuranceId: "",
        healthPlanName: null
    });
    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const selectedPayer =
      allPayers.find(p => p.id === formState.values.payerId) || null;
    const [insuranceIdLabel, setInsuranceIdLabel] = useState<string>('');

    
    const elementConfig: Record<keyof MemberSelectPayerFormState, any> = {
        payerId: {
            name: "payerId",
            onChange: () => {
                formState.setField("insuranceId", "");
            },
            validate: (value: string | number): string | undefined => {
                if (typeof value === "number" && allPayers.find(p => p.id === value)) {
                    return;
                }
                return t('memberSelectPayer.selectPayer');
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (!value?.trim()) {
                    return t('memberSelectPayer.enterPayerId', {payerName: selectedPayer.payerName});
                }
            },
            validateOnBlur: true
        },
        healthPlanName: {
            name: "healthPlanName",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (selectedPayer.unlistedPayerPlaceholder) {
                    if (!value?.trim()) {
                        return t('memberSelectPayer.enterHealthPlanName');
                    }
                    return;
                }
            },
            validateOnBlur: true
        }
    };

    useEffect(() => {
        //propagate the initial form state up to any subscribing components
        const isPayerInfoValid = Object.entries(elementConfig).every(([key, { validate }]) => {
            if (!validate) return true;
            return validate(formState.values[key as keyof MemberSelectPayerFormState]) === undefined;
        })
        onChange(formState.values, isPayerInfoValid);
    }, [onChange, formState.values]);

    useEffect(() => {
        if (selectedPayer) {
            if (selectedPayer.unlistedPayerPlaceholder){
                setInsuranceIdLabel(t('memberSelectPayer.healthPlanId'));
            }
            else {
                setInsuranceIdLabel(t('memberSelectPayer.payerIdPlaceholder', {payerName: selectedPayer.payerName}));
            }
        }
    }, [selectedPayer, t])

    useEffect(() => {

        const loadAllPayers = async () => {

            const config = await loadConfig();

            const request = new Request(`${config.apiOrigin}/all-payers`);

            // This is one of the rare, non-authenticated API endpoints.
            const response = await fetch(request);

            if (!response.ok) {
                const responseBodyText = await response.text();

                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);

                setLoading(false);
                setLoadingErrorMessage(t('memberSelectPayer.unexpectedError'));

                return;
            }

            const responseBody: GetAllPayersResponseBody = await response.json();
            const sortedPayers = responseBody.allPayers.sort((left, right) => left.payerName.localeCompare(right.payerName))
            sortedPayers.push(sortedPayers.splice(sortedPayers.findIndex(payer => payer.unlistedPayerPlaceholder), 1)[0])
            setAllPayers(sortedPayers);
            setLoading(false);
            setLoadingErrorMessage(null);

        };

        loadAllPayers();

    }, [t]);

    useEffect(() => {
        if (!loading && !selectedPayer?.unlistedPayerPlaceholder) {
            formState.setField("healthPlanName", null);
        }
    }, [loading, selectedPayer]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="MemberSelectPayer">
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <LoadingMessage message={loadingErrorMessage}/>
            )}
            <form className="MemberSelectPayer_Form">
                <div className="MemberSelectPayer_Fields">
                    <Select {...select(elementConfig.payerId)}
                            error={formState.errors.payerId !== undefined}
                            className="MemberSelectPayer_PayerSelect"
                            variant="outlined"
                            disabled={loading}
                            displayEmpty
                            required>
                        <MenuItem value="" disabled>
                            {t('memberSelectPayer.selectProvider')}
                        </MenuItem>
                        {allPayers.map(payer => (
                            <MenuItem key={payer.id} value={payer.id}>
                                {payer.payerName}
                            </MenuItem>
                        ))}
                    </Select>
                    {selectedPayer !== null && selectedPayer.unlistedPayerPlaceholder &&
                        <>
                            <TextField {...text(elementConfig.healthPlanName)}
                                       error={formState.errors.healthPlanName !== undefined}
                                       className="MemberSelectPayer_HealthPlanNameField"
                                       required
                                       helperText={formState.errors.healthPlanName}
                                       label={t("memberSelectPayer.healthPlanName")}
                                       variant="outlined"
                                       style={{marginBottom: '10px', width: '100%'}}
                            />
                        </>
                    }
                    {selectedPayer !== null &&
                    <>
                        <TextField {...text(elementConfig.insuranceId)}
                                   error={formState.errors.insuranceId !== undefined}
                                   className="MemberSelectPayer_InsuranceIdField"
                                   required
                                   inputProps={{maxLength: 50}}
                                   label={insuranceIdLabel}
                                   variant="outlined"
                        />
                    </>
                    }
                </div>

                {Object.entries(formState.errors).length > 0 &&
                <div className="MemberSelectPayer_FormErrors">
                    {Object.entries(formState.errors).map(([key, value]) =>
                        <div key={key}
                             className="MemberSelectPayer_FormError">
                            {value}
                        </div>)
                    }
                </div>
                }

            </form>
        </div>
    )
}
