import { POSTAL_CODE_REGEX, SHORT_CODE_REGEX } from "../../../constants/regex";
import { LocationState } from "../../../constants/contact";

const getElementConfig = (t: Function, formState: any) => ({
    clubName: {
        name: "clubName",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('clubSetup.clubSetupForm.errors.clubName');
            }
        },
        validateOnBlur: true
    },
    description: {
        name: "description",
        validate: (value: string) => {
            if (!value.trim()) {
                return t('clubSetup.clubSetupForm.errors.description');
            }
        },
        validateOnBlur: true
    },
    zipCode: {
        name: 'zipCode',
        validate: (value: string) => {
            if (!value.match(POSTAL_CODE_REGEX) && formState.values.location === LocationState.Offline) {
                return t('clubSetup.clubSetupForm.errors.zipCode');
            }
        },
        validateOnBlur: true,
        onBlur: (e: React.FocusEvent) => {
            const target = e.target as HTMLInputElement;
            if (!target.value) {
                formState.setFieldError('zipCode', t('clubSetup.clubSetupForm.errors.zipCode'))
            }
        }
    },
    passion: {
        name: 'passion',
        validate: (value: string) => {
            if (!value) {
                return t('clubSetup.clubSetupForm.errors.passion');
            }
        },
        validateOnBlur: true
    },
    timeZone: {
        name: 'timeZone',
        validate: (value: string) => {
            if (!value.trim()) {
                return t('clubSetup.clubSetupForm.errors.timeZone');
            }
        },
        validateOnBlur: true
    },
    shortCode: {
        name: 'shortCode',
        validate: (value: string) => {
            if (!value.match(SHORT_CODE_REGEX)) {
                return t('clubSetup.clubSetupForm.errors.shortCodeShouldBeValid');
            }
        },
        validateOnBlur: true
    },
    city: {
        name: 'city',
    },
    state: {
        name: 'state',
    },
    termsOfUse: {
        name: 'termsOfUse',
        validate: () => {
            if (!formState.values.termsOfUse) {
                return t('clubSetup.clubSetupForm.errors.termsOfUse');
            }
        },
        validateOnBlur: true
    },
    location: {
        name: 'location',
    },
    clubStatus: {
        name: 'clubStatus',
    },

})

export { getElementConfig }
