import { GetClubLeaderEventResponseBody } from "./ClubLeaderService";
export interface GetLocationResponseBody {
    locationName: string | null;
    streetAddressOne: string | null;
    streetAddressTwo: string | null;
    city: string | null;
    countrySubdivision: string | null;
    postalCode: string;
}

export interface GetPassionResponseBody {
    id: number;
    passionName: string;
    imageFile: string;
    imageUrl: string;
}

export interface GetTermResponseBody {
    id: number;
    termName: string;
    imageFile: string;
    imageUrl: string;
}

export interface GetAllActivityTypeResponseBody {
    allActivityTypes: GetActivityTypeResponseBody[];
}

export interface GetActivityTypeResponseBody {
    id: number;
    name: string;
}

export enum UserRegistrationFlow {
    LEGACY_MEMBER_FLOW = 0,
    PROJECT_LIGHTNING_BOLT = 1,
    OPTUM_GET_ACTIVE = 2,
    WELLCARE = 3,
    GENERIC_FLOW = 4,
    EXPERIMENT_FLOW = 5,
    CUSTOM_ENROLLMENT_FLOW = 6,
    BYOC_ENROLLMENT = 7,
    DIRECTORY_ENROLLMENT = 8,
    GROUPWORKS_ENROLLMENT = 9,
    AETNA_ENROLLMENT = 10,
    GROUP_LEADER_ENROLLMENT = 11
}

export interface UserAgreementModel {
    id: number;
    slug: string;
    category: string;
    required: boolean;
    startsAt: string | null;
    endsAt: string | null;
    expired: boolean;
    isValid: boolean;
}

export interface GetPagedEventsResponseBody {
    totalEventCount: number
    event: GetClubLeaderEventResponseBody[]
}

