import React from "react"

const ArrowDoubleLeft = ({ className }: { className?: string }) => (
    <svg width="22" height="16" viewBox="0 0 22 16" style={{transform: "rotate(180deg)"}} className={className} transform="rotate(180deg)" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_522_6663)">
            <path d="M21.9998 7.65955C21.997 8.00208 21.8509 8.3288 21.5951 8.57385L14.8498 14.8975C14.3018 15.414 13.4108 15.414 12.86 14.9028C12.5958 14.6551 12.4468 14.3205 12.444 13.97C12.444 13.6196 12.5902 13.285 12.8543 13.0373L18.6103 7.64374L12.8572 2.25018C12.3091 1.73374 12.3091 0.898491 12.8628 0.384693C13.4136 -0.129106 14.3018 -0.129106 14.8526 0.389962L21.5951 6.71363C21.8593 6.96395 22.0055 7.30648 21.9998 7.65955Z" fill="#3A3A3A" />
            <path d="M10.4437 7.65955C10.4409 8.00208 10.2947 8.3288 10.039 8.57385L3.29368 14.8975C2.74562 15.414 1.85468 15.414 1.30381 14.9028C1.03962 14.6551 0.890666 14.3205 0.887856 13.97C0.887856 13.6196 1.034 13.285 1.29819 13.0373L7.05418 7.64374L1.30101 2.25018C0.75295 1.73374 0.75295 0.898491 1.30663 0.384693C1.85749 -0.129106 2.74562 -0.129106 3.29649 0.389962L10.039 6.71363C10.3032 6.96395 10.4493 7.30648 10.4437 7.65955Z" fill="#3A3A3A" />
        </g>
        <defs>
            <clipPath id="clip0_522_6663">
                <rect width="21.112" height="15.2855" fill="white" transform="matrix(-1 0 0 1 22 0)" />
            </clipPath>
        </defs>
    </svg>

)

export default ArrowDoubleLeft
