
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { IClub } from '../types/clubFinder'

export const GET_MY_CLUBS_URL = '/ignite/get-my-pending-clubs'

// This now returns all clubs mapped to this user - where the status = PENDING
const getMyPendingClubs = async (): Promise<{ allClubs: [IClub] } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_MY_CLUBS_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export default getMyPendingClubs
