import React from "react";
import { useTranslation } from 'react-i18next';
import SendToListIcon from "../../../components/ignite/icons/SendToListIcon";
import UploadIcon from "../../../components/ignite/icons/UploadIcon";
import InviteIcon from "../../../components/ignite/icons/InviteIcon";
import CardItem from './CardItem'
import BellIcon from "../../../components/ignite/icons/BellIcon";
import ActivityIcon from "../../../components/ignite/icons/ActivityIcon";
import { CONTENT_TYPE } from "../../../constants/dashboard";
import { IActivityItem, IMember } from '../../../types/dashboard'

import './ToDoTab.scss';

interface IInitialContentProps {
    onContentChange: Function;
    members: IActivityItem[],
    invitedMembers?: IMember[],
    missingActivity?: IActivityItem[]
}

const InitialContent = ({
    onContentChange,
    members,
    invitedMembers,
    missingActivity
}: IInitialContentProps) => {
    const { t } = useTranslation('ignite/dashboard');

    const shouldShowRemindBlock = members.length > 1 || invitedMembers && invitedMembers.length > 0;

    return (
        <div className="initial-content">
            {shouldShowRemindBlock ?
                <>
                    <div className="action-text">
                        {t('initialContent.remindButtonsActionText')}
                    </div>
                    <div className="remind-block">
                        <CardItem
                            Icon={BellIcon}
                            text={t('invited')}
                            buttonText={t('initialContent.remind')}
                            className='light-blue'
                            amount={invitedMembers?.length}
                            helpText={t('butNotSignedUp')}
                            disabled={Boolean(!invitedMembers?.length)}
                            onClick={() => onContentChange(CONTENT_TYPE.REMIND_INVITED_MEMBERS)}
                        />
                        <CardItem
                            Icon={ActivityIcon}
                            text={t('missing')}
                            buttonText={t('initialContent.remind')}
                            className='red'
                            helpText={t('activity')}
                            amount={missingActivity?.length}
                            disabled={Boolean(!missingActivity?.length)}
                            onClick={() => onContentChange(CONTENT_TYPE.REMIND_MISSING_ACTIVITY_MEMBERS)}
                        />
                    </div>
                </>
                : <>
                    <div className="subtitle">
                        {t('initialContent.subtitle')}
                    </div>
                    <div className="description">
                        {t('initialContent.description')}
                    </div>
                </>
            }

            <div className="action-text inviteText">
                {t('initialContent.actionText')}
            </div>
            <div className="action-description">
                {t('initialContent.actionDescriptions')}
            </div>

            <div className="list">
                <CardItem
                    Icon={SendToListIcon}
                    text={t('initialContent.card1Text')}
                    buttonText={t('initialContent.card1ButtonText')}
                    description={t('initialContent.card1Description')}
                    onClick={() => onContentChange(CONTENT_TYPE.COPY_EMAIL)}
                />

                <CardItem
                    Icon={InviteIcon}
                    text={t('initialContent.card2Text')}
                    buttonText={t('initialContent.card2ButtonText')}
                    description={t('initialContent.card2Description')}
                    className='gray'
                    onClick={() => onContentChange(CONTENT_TYPE.INVITE)}

                />

                <CardItem
                    Icon={UploadIcon}
                    text={t('initialContent.card3Text')}
                    buttonText={t('initialContent.card3ButtonText')}
                    description={t('initialContent.card3Description')}
                    className='blue'
                    onClick={() => onContentChange(CONTENT_TYPE.UPLOAD)}
                />
            </div>
        </div >
    )
}

export default InitialContent;
