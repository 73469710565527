import { IClub } from '../../types/clubFinder'

const EXCEPTIONS = ['and', '&', 'club']

const filterByExceptions = (word: string, index: number, arr: Array<string>) =>
    !EXCEPTIONS.includes(word) && arr.indexOf(word) === index && word

export const getTokensFromClub = (data: Array<IClub> | IClub): Array<string> => {
    if (Array.isArray(data)) {
        if (!data.length) return []
    }

    const preparedClubs: Array<IClub> = Array.isArray(data) ? data : [data]

    return (
        preparedClubs
            .flatMap(({ clubName }) =>
                clubName.toLocaleLowerCase().split(" "))
            .filter(filterByExceptions)
    )
}

export const getTokensFromStr = (data: string): Array<string> => {
    return (
        data.toLocaleLowerCase().split(" ")
            .filter(filterByExceptions)
    )
}
