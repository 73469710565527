import React from "react"

const BellIcon = ({ className, isWhite }: { className: string, isWhite?: boolean }) => (
    <svg className={className} width="59" height="67" viewBox="0 0 59 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_666_56477)">
            <path fillRule="evenodd" clipRule="evenodd" d="M53.353 8.96237C56.6337 13.6455 58.5525 19.3287 58.5525 25.4673C58.5525 28.3287 58.1349 31.1109 57.3495 33.7247H52.9951C53.9097 31.1406 54.4068 28.3683 54.4068 25.4673C54.4068 19.1208 52.0108 13.3386 48.0739 8.96237H53.343H53.353ZM11.0311 8.96237C7.09414 13.3485 4.69819 19.1307 4.69819 25.4673C4.69819 28.3584 5.19527 31.1406 6.10991 33.7148H1.75544C0.970043 31.101 0.55249 28.3287 0.55249 25.4673C0.55249 19.3287 2.47124 13.6356 5.75201 8.96237H11.0211H11.0311ZM29.5525 0.714844C31.8391 0.714844 33.6982 2.55643 33.6982 4.83366C33.6982 4.97227 33.6982 5.12078 33.6783 5.2594C43.0335 7.19999 50.102 15.6257 50.271 25.4673V37.2693C50.271 37.6356 50.4202 37.9822 50.6786 38.2396L57.7472 45.2792C58.2642 45.794 58.5624 46.497 58.5624 47.2198V52.9525C58.5624 53.7148 57.946 54.3287 57.1805 54.3287H1.93439C1.17882 54.3386 0.55249 53.7247 0.55249 52.9723V47.2396C0.55249 46.5069 0.8408 45.8039 1.36771 45.299L8.43628 38.2594C8.69476 38.002 8.84389 37.6554 8.84389 37.2891V25.4871C8.84389 15.497 15.9721 7.17029 25.4366 5.2693C25.4167 5.13068 25.4167 5.00197 25.4167 4.86336C25.4167 2.58613 27.2758 0.744547 29.5624 0.744547L29.5525 0.714844ZM37.8339 58.4673C37.8339 63.0218 34.1257 66.7148 29.5525 66.7148C24.9793 66.7148 21.3804 63.1307 21.271 58.6752V58.4673H37.8339Z" fill={isWhite ? "#fff" : "#0280CD"} />
        </g>
        <defs>
            <clipPath id="clip0_666_56477">
                <rect width="58" height="66" fill="white" transform="translate(0.55249 0.714844)" />
            </clipPath>
        </defs>
    </svg>
)

export default BellIcon
