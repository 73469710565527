import "./HaveQuestions.scss";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../contexts/UserContext";
import {useLocation} from "react-router-dom";

interface HaveQuestionsProps {
    altMobileSizing?: boolean;
    alwaysMobile?: boolean;
    searchPage?: boolean;
    className?: string
}

export default function HaveQuestions(props: HaveQuestionsProps): JSX.Element {
    const {t} = useTranslation('pages');
    const {getContactPhoneNumber} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    return (
        <div className={`HaveQuestionsWrapper ${props.className || ''}`}>
            <a className={`HaveQuestions ${props.altMobileSizing ? "AltMobileSizing" : ""} ${props.alwaysMobile ? "AlwaysMobile" : ""}`}
               href={`tel:${phoneNumber.tel}`}>
                <div className={"HaveQuestions_TextContent"}>
                    <div className={"HaveQuestions_HaveQuestions"}>{props.searchPage ? t('haveQuestions.lookingForClub') : t('haveQuestions.haveQuestions')}</div>
                    <div className={"HaveQuestions_AskSpecialist"}>{t('haveQuestions.weAreStandingBy')}</div>
                    <div className={"HaveQuestions_ContactNumber"}>{phoneNumber.display}</div>
                </div>
                <div className={"HaveQuestions_ImageWrapperBackground"}>
                    <div className={"HaveQuestions_ImageWrapper"}>
                        <div className={"HaveQuestions_Image"}
                             style={{backgroundImage: `url(${require("../../assets/have-questions.png")})`}}/>
                    </div>
                </div>
            </a>
        </div>
    )
}
