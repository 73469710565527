import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import "./ChangeRoleModal.scss";
import { Modal } from "@material-ui/core";
import { Calendar } from "../Dashboard/Calendar/Calendar";
import { IClub } from "../../../types/clubFinder";
import { IActivityItem, IActivityRecord, IUser } from "../../../types/dashboard";
import CloseIcon from "@material-ui/icons/Close";
import getMemberActivity from "../../../api/getMemberActivity";
import Loading from "../layout/Loading";

interface ICalendarModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    club: IClub
    activityItem: IUser
    refetchActivity: Function
    logActivityId?: string
}

export default function CalendarModal({ isOpen, onClose, activityItem, refetchActivity, club, logActivityId }: ICalendarModalProps) {
    const { t } = useTranslation('ignite/dashboard');
    const [memberData, setMemberDate] = useState<IActivityItem>()

    const fetchMemberActivity = () => {
        getMemberActivity(club.id, String(logActivityId))
            .then(data => {
                setMemberDate(data)
            })
    }

    useEffect(() => {
        if (isOpen && logActivityId) {
            fetchMemberActivity()
        } else {
            refetchActivity()
        }
    }, [isOpen])

    return (
        <Modal
            open={isOpen}
            className='calendar-modal'
        >
            <div className="calendar-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className="title">
                    {t('viewActivity')}
                </div>
                {!memberData ? <Loading loading={!memberData} /> : (
                    <div className='content'>
                        <div className="name">
                            {`${activityItem?.fullName}`}
                        </div>
                        <Calendar
                            club={club}
                            userId={Number(logActivityId)}
                            activityRecords={memberData?.activityRecords as IActivityRecord[]}
                            refetchActivity={fetchMemberActivity}
                            isLeaderDashboard={true}
                            activityMonths={memberData?.activityMonths}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
}
