import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const UPDATE_PROFILE_MEMBER_INFO_URL = '/my-profile/member-info'

interface IUpdateProfileBody {
    FirstName: string
    LastName: string
    DateOfBirth: string
    PostalCode: string
    Street: string
    City: string
    State: string
    EmailLocale: string
}

const postUpdateProfileMemberInfo = async (body: IUpdateProfileBody) => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${UPDATE_PROFILE_MEMBER_INFO_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postUpdateProfileMemberInfo
