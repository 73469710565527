import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import {UserContext} from "../../contexts/UserContext";
import {loadConfig} from "../../services/ConfigService";
import Button, {ButtonStyles} from "../../components/common/Button";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import Loading from "../../components/common/Loading";
import BorderedRow, {
    BorderedRowItem,
    BorderedRowLastItem
} from "../../components/common/BorderedRow";
import LocationViewCityState
    from "../../components/common/LocationViewCityState";
import PlayButton from "../../components/common/PlayButton";
import "./LegacyVirtualClubsPage.scss";

interface GetFeaturedClubsResponseBody {
    totalResultCount: number
    results: GetFeaturedClubResponseBody[]
    skip: number
    limit: number
}

interface GetFeaturedClubResponseBody {
    clubUrlFragment: string
    clubName: string
    passionId: number
    passionName: string
    city: string | null
    countrySubdivision: string | null
    eventCount: number
}

export default function LegacyVirtualClubsPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const {authenticatedFetch} = useContext(UserContext);

    const [initialLoading, setInitialLoading] = useState(true);
    const [previousLoading, setPreviousLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const limit = 10;

    const [featuredClubsResponseBody, setFeaturedClubsResponseBody] = useState<GetFeaturedClubsResponseBody | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const isPreviousButtonEnabled =
        featuredClubsResponseBody !== null
        && featuredClubsResponseBody.skip > 0;

    const isNextButtonEnabled =
        featuredClubsResponseBody !== null
        && featuredClubsResponseBody.skip + featuredClubsResponseBody.limit < featuredClubsResponseBody.totalResultCount;

    function onClickPreviousButton() {
        setPreviousLoading(true);
        setSkip(Math.max(0, skip - limit));
    }

    function onClickNextButton() {
        setNextLoading(true);
        setSkip(skip + limit);
    }

    useEffect(() => {

        async function loadFeaturedClubs() {

            const config = await loadConfig();

            const searchParams = new URLSearchParams();
            searchParams.set("skip", skip.toString());
            searchParams.set("limit", limit.toString());

            const request = new Request(`${config.apiOrigin}/featured-clubs?${searchParams.toString()}`);

            const response = await authenticatedFetch(request);

            if (response === null) {
                // The user became logged out.  Wait for re-render.
                return;
            }

            if (!response.ok) {
                const responseBodyText = await response.text();
                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);
                setErrorMessage(t('virtualClubsPage.loadingError'));
                return;
            }

            const responseBody: GetFeaturedClubsResponseBody = await response.json();

            setFeaturedClubsResponseBody(responseBody);
            setInitialLoading(false);
            setPreviousLoading(false);
            setNextLoading(false);
            setErrorMessage(null);
        }

        loadFeaturedClubs();

    }, [ authenticatedFetch, skip, limit, t ]);

    return (
        <div className="LegacyVirtualClubsPage">
            <h1 className="VirtualClubsPage_Header">{t('legacyVirtualClubsPage.header')}</h1>
            <Loading className="VirtualClubsPage_Loading" loading={initialLoading}/>
            {errorMessage !== null && (
                <div className="ErrorMessage">
                    {errorMessage}
                </div>
            )}
            {featuredClubsResponseBody &&
            <div className="Results">
                {/* TODO: Should we display some message if there are zero featured clubs? */}
                {featuredClubsResponseBody.results.map((result, i) => (
                    <BorderedRow key={result.clubUrlFragment}
                                 className="Result"
                                 showTopBorder={i === 0}
                                 link={`/club/${result.clubUrlFragment}`}>
                        <BorderedRowItem className="ClubName">
                            {result.clubName}
                        </BorderedRowItem>
                        <BorderedRowItem className="Passion">
                            {result.passionName}
                        </BorderedRowItem>
                        <BorderedRowItem className="Location">
                            <LocationViewCityState
                                city={result.city}
                                state={result.countrySubdivision}/>
                        </BorderedRowItem>
                        <BorderedRowLastItem className="Events">
                            <PlayButton
                                to={`/club/${result.clubUrlFragment}`}
                                buttonStyle={ButtonStyles.UnfilledWithBorder}
                                text={t('virtualClubsPage.viewEventsButtonText')}/>
                        </BorderedRowLastItem>
                    </BorderedRow>
                ))}
            </div>
            }
            {(isPreviousButtonEnabled || isNextButtonEnabled) && (
                <>
                    <div className="PreviousAndNextButtons">
                        <Button clickHandler={onClickPreviousButton}
                                buttonStyle={ButtonStyles.Unfilled}
                                saving={previousLoading}
                                disabled={!isPreviousButtonEnabled}
                                className="PreviousButton">
                            <SkipPreviousIcon/>
                            {t('virtualClubsPage.previousButtonText')}
                        </Button>
                        <Button clickHandler={onClickNextButton}
                                buttonStyle={ButtonStyles.Unfilled}
                                saving={nextLoading}
                                disabled={!isNextButtonEnabled}
                                className="NextButton">
                            {t('virtualClubsPage.nextButtonText')}
                            <SkipNextIcon/>
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}
