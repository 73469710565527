import React, { useContext, useEffect, useState } from "react";
import "./FeaturedEvents.scss";
import {
    EventSearchResponseBody
} from "../../pages/findAnEvent/FindAnEventPage";
import { UserContext } from "../../contexts/UserContext";
import PassionImage from "../passionImage/PassionImage";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
    EventSearchResult,
    findFeatured
} from "../../services/EventSearchService";
import SelfGuidedEventIndicator from "../common/SelfGuidedEventIndicator";
import { EventType } from "../../util/EventType";

interface FeaturedEventsProps {
    className?: string;
    mini?: boolean
    onLoad?: Function
    excludeIds?: number[];
}

export default function FeaturedEvents(props: FeaturedEventsProps): JSX.Element {
    const [searchResponseBody, setSearchResponseBody] = useState<EventSearchResponseBody | null>(null);
    const {authenticatedFetch} = useContext(UserContext);
    const {t} = useTranslation('event');

    useEffect(() => {
        (async () => {
            try {
                const results = await findFeatured({
                    authenticatedFetch,
                    skip: 0,
                    limit: 3,
                    excludeIds: props.excludeIds
                });
                setSearchResponseBody(results);
                if (props.onLoad) {
                    props.onLoad(results);
                }
            } catch (e) {
            }
        })();
    }, [authenticatedFetch])

    return (
        <div className={`FeaturedEvents ${props.className || ''} ${props.mini ? "Mini" : "Full"}`}>
            {!props.mini && <>
                {searchResponseBody?.results?.map(result => (
                    <FullEvent key={result.eventUrlFragment} result={result} />
                ))}
            </>}
            {props.mini && <>
                <div className={"FeaturedEvents_Title"}>{t('featuredEvents.eventsYouMightLike')}</div>
                {searchResponseBody?.results?.map(result => (
                    <MiniEvent key={result.eventUrlFragment} result={result} />
                ))}
            </>}
        </div>
    )
}

interface FullEventProps extends RouteComponentProps {
    result: EventSearchResult
}

const FullEvent = withRouter(function(props: FullEventProps): JSX.Element {
    const { result } = props;
    const { t: tCommon } = useTranslation('common');
    const date = new Date(result.eventStartsAt)
        .toLocaleDateString(i18n.language || 'en', { day: 'numeric', month: 'short' });
    const startTime = new Date(result.eventStartsAt)
        .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric'});
    const endTime = new Date(result.eventEndsAt)
        .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric', timeZoneName: "short"});

    function linkToEvent() {
        props.history.push(`/event/${result.eventUrlFragment}`)
    }

    return (
        <div className={"EventResult"} key={result.eventUrlFragment} onClick={linkToEvent}>
            <PassionImage passion={result.passion}/>
            <div className={"EventResult_PassionName"}>{result.passion.passionName}</div>
            <div className={"EventResult_EventName"}>
                {result.eventTypeOne === EventType.SELF_GUIDED && <span title={tCommon('listSelfGuidedIndicator.selfGuidedLabel')}>
                    <SelfGuidedEventIndicator className={"SelfGuidedIndicator"} />
                </span>}
                {result.eventName}
            </div>
            <div className={"EventResult_EventTime"}>{date} / {startTime} - {endTime}</div>
        </div>
    )
})

interface MiniEventProps extends RouteComponentProps {
    result: EventSearchResult
}

const MiniEvent = withRouter(function(props: MiniEventProps): JSX.Element {
    const { result } = props;
    const { t: tCommon } = useTranslation('common');
    const date = new Date(result.eventStartsAt)
        .toLocaleDateString(i18n.language || 'en', { day: 'numeric', month: 'short' });
    const startTime = new Date(result.eventStartsAt)
        .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric', minute: 'numeric'});
    const endTime = new Date(result.eventEndsAt)
        .toLocaleTimeString(i18n.language || 'en', {hour: 'numeric', minute: 'numeric', timeZoneName: "short"});

    function linkToEvent() {
        props.history.push(`/event/${result.eventUrlFragment}`)
    }

    return (
        <div className={"EventResult"} key={result.eventUrlFragment} onClick={linkToEvent}>
            <div className={"EventResult_EventName"}>
                {result.eventTypeOne === EventType.SELF_GUIDED && <span title={tCommon('listSelfGuidedIndicator.selfGuidedLabel')}>
                    <SelfGuidedEventIndicator className={"SelfGuidedIndicator"} />
                </span>}
                {result.eventName}
            </div>
            <div className={"EventResult_EventTime"}>{date} / {startTime} - {endTime}</div>
        </div>
    )
});
