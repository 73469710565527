import React, {useContext, useEffect, useState} from "react";
import { Trans, useTranslation } from 'react-i18next';

import "./ClubLeaderLanding.scss";
import {ButtonStyles} from "../../components/common/Button";
import PlayButton from "../../components/common/PlayButton";
import RegistrationModal from "../../components/registrationModal/RegistrationModal";
import {RegistrationFormType} from "../../components/registrationModal/RegistrationForm";
import {UserContext} from "../../contexts/UserContext";
import {Redirect, RouteComponentProps} from "react-router-dom";
import Loading from "../../components/common/Loading";
import clubLeaderLanding from "../../assets/club-leader-landing.jpg";

interface ClubLeaderLandingPageProps {
    deputyLeaderInviteUrlFragment: string;
}

export default function ClubLeaderLandingPage({match}: RouteComponentProps<ClubLeaderLandingPageProps>) {
    const { t } = useTranslation('pages');
    const {getContactPhoneNumber} = useContext(UserContext);

    const [showRegistrationModal, setShowRegistrationForm] = useState(false);
    const [loading, setLoading] = useState(true);

    const deputyLeaderInviteUrlFragment = match.params.deputyLeaderInviteUrlFragment;

    let postRegistrationRedirect = "/edit-club";

    if (deputyLeaderInviteUrlFragment !== undefined) {
        postRegistrationRedirect = `/club-invitation/${deputyLeaderInviteUrlFragment}`;
    }

    const {user} = useContext(UserContext);

    useEffect(
        () => {
            setLoading(false);
        },
        []);

    return (
        <>
            {loading && <Loading className="PageLoading" loading={loading}/>}
            {!loading && user !== null && deputyLeaderInviteUrlFragment !== undefined && <Redirect to={`/club-invitation/${deputyLeaderInviteUrlFragment}`}/>}
            {/* If we go to /club-leader or /club-leader/ with no token, we want them to be redirected to */}
            {/* edit-club to be able to create a new club */}
            {!loading && user !== null && deputyLeaderInviteUrlFragment === undefined && <Redirect to={"/edit-club/"}/>}
            {!loading && user === null && <div>
                <div id="CCClubLeaderLanding_Banner">
                    <div>
                        <img id="CCClubLeaderLanding_Image_Wrapper" src={clubLeaderLanding}
                             alt={t('clubLeaderLandingPage.altImage')}/>
                    </div>
                    <div id="text">
                        <h1 id="CCClubLeaderLanding_Title">{t('clubLeaderLandingPage.title')}</h1>
                        <p id="CCClubLeaderLanding_Subtext">{t('clubLeaderLandingPage.subtext')}</p>
                        <div id="CCClubLeaderLanding_SigninLogin">
                            <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                                <p id="CCClubLeaderLanding_SubSubtext">{t('clubLeaderLandingPage.subsubtext')}</p>
                                <PlayButton text={t('clubLeaderLandingPage.getStartedButtonText')}
                                            buttonStyle={ButtonStyles.FilledPrimary}
                                            clickHandler={() => {
                                                setShowRegistrationForm(true);
                                            }}/>
                            </div>
                            <div id={"CCClubLeaderLanding_SignInWrapper"}>
                                <p id={"CCClubLeaderLanding_SignInText"}>{t('clubLeaderLandingPage.alreadyRegistered')}</p>
                                <PlayButton buttonStyle={ButtonStyles.UnfilledWithBorder}
                                            text={t('clubLeaderLandingPage.signInButtonText')}
                                            to={"/login"}
                                            toState={{ referrer: postRegistrationRedirect }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id={"CCClubLeaderLanding_FAQ_Wrapper"}>
                    <div id={"CCClubLeaderLanding_FAQ"}>
                        <Trans
                            t={t}
                            i18nKey="clubLeaderLandingPage.info"
                            components={{
                                headerH2: <h2/>,
                                textParagraph: <p/>
                            }}
                        />
                    </div>
                </div>
                <div id={"CCClubLeaderLanding_Signup_Wrapper"}>
                    <PlayButton text={t('clubLeaderLandingPage.signUpButtonText')} buttonStyle={ButtonStyles.FilledPrimary}
                                id="LeaderGetStarted"
                                clickHandler={() => {
                                    setShowRegistrationForm(true);
                                }}/>
                </div>
                <RegistrationModal type={RegistrationFormType.LEADER}
                                   deputyLeaderInviteUrlFragment={deputyLeaderInviteUrlFragment}
                                   eventType={null}
                                   id="LeaderSignUp"
                                   open={showRegistrationModal}
                                   onClose={() => setShowRegistrationForm(false)}
                                   postRegisterRedirect={postRegistrationRedirect}
                                   onSuccessfulSubmit={(userId: number) => {
                                       setShowRegistrationForm(false);
                                   }}/>
            </div>}
        </>
    );
}
