import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IGNITE_ROUTE_PATTERNS } from "../constants/routes";
import { IMember } from '../types/dashboard'
import getMemberReferrals from "../api/getMemberReferrals";

interface IUseGetInvitedMembersReturn {
    invitedMembers: IMember[] | undefined
    isLoading: boolean
    fetchInvitedMembers: Function
    totalInvitedResultCount: number
}

const useGetInvitedMembers = (clubId: number): IUseGetInvitedMembersReturn => {
    const [invitedMembers, setInvitedMembers] = useState<IMember[] | undefined>()
    const [totalInvitedResultCount, setTotalInvitedResultCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const fetchInvitedMembers = ({ skip = 0, sortBy = 'fullName', sortOrder = 'asc', searchValue = '' } = {}) => {
        return getMemberReferrals({ clubId, skip, referralStatusType: 1, sortBy, sortOrder, searchValue })
            .then(data => {
                setInvitedMembers(data?.results)
                setTotalInvitedResultCount(Number(data?.totalResultCount))
            })
            .catch(() => history.replace(IGNITE_ROUTE_PATTERNS.ERROR))
    }

    useEffect(() => {
        setIsLoading(true)
        fetchInvitedMembers({}).finally(() => {
            setIsLoading(false)
        })
    }, [clubId])

    return { invitedMembers, isLoading, fetchInvitedMembers, totalInvitedResultCount }
}

export default useGetInvitedMembers
