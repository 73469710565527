import { Button, Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import './BYOCWelcomeModal.scss';
import CrossBold from '../../../../components/icon/CrossBold';
import { useTranslation } from 'react-i18next';

const BYOCWelcomeModal = () => {
    const { t } = useTranslation('directToConsumer/dashboard');
    const [open, setOpen] = useState(false);
    const {state} = useLocation<{ShowDTCWelcomeMessage: boolean | undefined}>();
    useEffect(() => {
        if(state?.ShowDTCWelcomeMessage){
            setOpen(true);
        }
    }, [state]);

    const handleClose = () => setOpen(false);

    return(
        <Dialog open={open}>
            <div className='BYOCWelcomeModal'>
                <div>
                    <h1 className='welcome'>{t("welcomeModal.title")}</h1>
                    <p className='message'>{t("welcomeModal.description")}</p>
                    <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={handleClose}
                        className='cta'
                    >
                        {t("welcomeModal.button.label")}
                    </Button>
                </div>
                <div className="close">
                    <IconButton size='small' onClick={handleClose}>
                        <CrossBold/>
                    </IconButton>
                </div>
            </div>
        </Dialog>
    );
}

export default BYOCWelcomeModal;