import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { IEligibility } from './getEligibilityStatus';

const URL = '/ignite/get-latest-eligibility-status/';

const getLatestEligibilityStatus = async (): Promise<IEligibility | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}

export default getLatestEligibilityStatus
