import React from "react";
import { useTranslation } from 'react-i18next';

import "./GoingIndicator.scss";
import Done from "@material-ui/icons/Done";

export interface GoingIndicatorProps {
    className?: string;
}

export default function GoingIndicator(props: GoingIndicatorProps): JSX.Element {
    const { t } = useTranslation('common');
    let classes = "GoingIndicator";
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (
        <div className={classes}><Done/>{t('goingIndicator.goingLabel')}</div>
    );
}