import React, {useState} from "react";

export interface PayerDetails {
    payerId: number
    insuranceId: string
}

export interface SelectedPayerState {
    payerDetails: PayerDetails | null
    setPayerDetails: (payerDetails: PayerDetails | null) => void
}

const initialSelectedPayerState: SelectedPayerState = {
    payerDetails: null,
    setPayerDetails: () => {}
}

export const SelectedPayerContext: React.Context<SelectedPayerState> =
    React.createContext(initialSelectedPayerState);

const PAYER_DETAILS_STORAGE_KEY = "ClubCalendarPayerDetails";

// Advantage of using session storage instead of local storage:
// the selected payer goes away (and stops consuming storage space) when the
// user closes this browser tab.
//
// Advantage of using local storage instead of session storage:
// the selected payer would persist in other browser tabs.
const storage = sessionStorage;

function loadPayerDetailsFromStorage(): PayerDetails {
    const serialized = storage.getItem(PAYER_DETAILS_STORAGE_KEY);
    return (serialized === null) ? null : JSON.parse(serialized);
}

function savePayerDetailsToStorage(payerDetails: PayerDetails) {
    const serialized = JSON.stringify(payerDetails);
    storage.setItem(PAYER_DETAILS_STORAGE_KEY, serialized);
}

function clearPayerDetailsFromStorage() {
    storage.removeItem(PAYER_DETAILS_STORAGE_KEY);
}

export function SelectedPayerContextProvider({ children }: React.PropsWithChildren<React.ReactNode>) {

    const [payerDetails, setPayerDetails] =
        useState<PayerDetails | null>(loadPayerDetailsFromStorage());

    const currentSelectedPayerState: SelectedPayerState = {
        payerDetails,
        setPayerDetails: newPayerDetails => {
            setPayerDetails(newPayerDetails);
            try {
                if (newPayerDetails === null) {
                    clearPayerDetailsFromStorage();
                } else {
                    savePayerDetailsToStorage(newPayerDetails);
                }
            } catch (storageError) {
                // Not a complete disaster, but the selected payer will vanish
                // if the user hits refresh or navigates to some other URL
                // manually.
                console.warn(
                    "Unable to persist payer details to storage; "
                    + "they will be persistent in memory only",
                    storageError)
            }
        }
    };

    return (
        <SelectedPayerContext.Provider
            value={currentSelectedPayerState}
            children={children} />
    );
}
