import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import React from "react";

export function ZipCodeInput(props: RegistrationInputProps): JSX.Element {
    const {t} = useTranslation('pages');
    const inputConfig: InputConfig<string> = {
        name: 'zipCode',
        initialValue: "",
        validate: (value: string) => {
            const zipCodeRegex = /^[0-9]{5}$/;
            if (!value.trim()) {
                //return 'Zip Code is required';
                return " ";
            }
            if (!value.match(zipCodeRegex)) {
                //return "Invalid zip code";
                return " ";
            }
        },
        validateOnBlur: true
    };

    const [formState, {text}] = useSubFormInput(inputConfig);

    return (
        <TextField {...text(inputConfig)}
                   className={`RegistrationWizard_Input ZipCodeInput ${props.className || ''}`}
                   label={t('registration.zipCode.label')}
                   required
                   variant={"outlined"}
                   error={formState.errors.zipCode !== undefined}
                   helperText={formState.errors.zipCode?.trim()}
                   size="small"/>
    )
}
