import React from "react";
import { useTranslation } from 'react-i18next';
import RegistrationForm from "../../../components/ignite/registration/RegistrationForm";

import "./MemberOnboarding.scss";

export default function MemberOnboarding() {
    const { t } = useTranslation('ignite/registration');

    return (
            <div className="onboarding-page">
                <div>
                    <div className='onboarding-page__title'>
                        {t('registrationForm.signupToStartEarning')}
                    </ div>
                    <RegistrationForm />
                </div>
            </div>
    );
}
