import React from "react";

const threeCircleUnfilled = require("../../assets/3-circle-unfilled.svg");

interface ThreeCircleUnfilledProps {
    className?: string
}

export default function ThreeCircleUnfilled(props: ThreeCircleUnfilledProps) {
    return <img className={props.className} src={threeCircleUnfilled} alt="circle three icon"/>
}
