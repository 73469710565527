import React, {useContext, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import { useFormState } from "react-use-form-state";
import Checkbox from '@material-ui/core/Checkbox';
import { Link, useHistory } from "react-router-dom";
import { getElementConfig } from './clubSetupFormConfig'
import PlayIcon from '@material-ui/icons/PlayArrow';
import {
    FormLabel,
    MenuItem,
    Switch,
    TextField,
    Button,
    Radio,
} from "@material-ui/core";
import { labelForTimeZone, SupportedTimeZone, states } from "../../../util/ignite/ClubSetup.utils";
import "./ClubSetupForm.scss";
import useGetAllPassions from "../../../hooks/useGetAllPassions";
import useSetupClub from "../../../hooks/useSetupClub";
import Loading from "../layout/Loading";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS } from "../../../constants/routes";
import {UserContext} from "../../../contexts/UserContext";
import {refreshUsersClubs} from "../../../services/UserService";
import { LocationState } from "../../../constants/contact";

enum ClubStatus {
    Active = 1,
    Inactive = 2,
}

const initialState = {
    clubName: "",
    city: "",
    zipCode: "",
    description: "",
    passion: "",
    termsOfUse: false,
    clubStatus: true,
    location: LocationState.Offline,
    shortCode: ''
}

const requiredLocationKeys = ['zipCode']

export default function ClubSetupForm() {
    const { t } = useTranslation('ignite/leaderOnboarding');
    const [formState, { text, select, checkbox, radio }] = useFormState(initialState);
    const elementConfig = getElementConfig(t, formState)
    const [allPassions, isPassionsLoading] = useGetAllPassions()
    const [setupClub, isSetupLoading] = useSetupClub()
    const history = useHistory();
    const { refreshUser } = useContext(UserContext);

    useEffect(() => {
        formState.resetField('state')
        formState.resetField('city')
        formState.resetField('zipCode')
    }, [formState.values.location])

    const submitEnabled = () => {
        const allValuesValid = Object.entries(formState.validity).every(([, value]) => value)
        const allInputsTouched = Object.entries(formState.pristine)
            .every(([key, value]) => !value
                || key === 'location'
                || key === 'clubStatus'
                || key === 'state'
                || key === 'city'
                || (formState.values.location === LocationState.Online && requiredLocationKeys.includes(key))
            )

        return allValuesValid && allInputsTouched
    }

    const isLoading = isSetupLoading || isPassionsLoading

    const onSuccessCallback = async (data: { clubId: number }) => {
        await refreshUser();
        await refreshUsersClubs();
        history.push(getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': String(data.clubId) }))
    }

    const onFailCallback = () => formState.setFieldError('shortCode', t('clubSetup.clubSetupForm.errors.duplicateClubShortCode'))
    const onlineAndInPersonActivitiesEnabled = formState.values.location === LocationState.Both

    const onSubmit = () => {
        const body = {
            ClubName: formState.values.clubName,
            Description: formState.values.description,
            PassionId: formState.values.passion,
            TimeZone: formState.values.timeZone,
            ClubStatus: formState.values.clubStatus ? ClubStatus.Active : ClubStatus.Inactive,
            City: formState.values.city,
            CountrySubdivision: formState.values.state,
            PostalCode: formState.values.zipCode,
            ShortCode: formState.values.shortCode,
            TrackingOnlineActivitiesEnabled: formState.values.location === LocationState.Online || onlineAndInPersonActivitiesEnabled,
            TrackingInPersonActivitiesEnabled: formState.values.location === LocationState.Offline || onlineAndInPersonActivitiesEnabled
        }
        setupClub(body, { onSuccessCallback, onFailCallback })
    }

    const shouldShowLocationBlock = Boolean(formState.values.location === LocationState.Offline || onlineAndInPersonActivitiesEnabled)

    if (isLoading) return <Loading loading={isLoading} />

    return (
        <div className="club-setup-form">
            <TextField
                {...text(elementConfig.clubName)}
                classes={{
                    root: 'club-setup-form__field',
                }}
                InputLabelProps={{
                    htmlFor: 'club-name-id',
                    required: false,
                    classes: {
                        root: 'label-root',
                        error: 'label-error',
                        focused: 'label-focused'
                    }
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-setup-form__field_error'
                    }
                }}
                InputProps={{
                    classes: {
                        input: 'club-setup-form__field_bold'
                    }
                }}
                id='club-name-id'
                label={t('clubSetup.clubSetupForm.clubNameLabel')}
                error={formState.errors.clubName !== undefined}
                helperText={formState.errors.clubName}
                variant='outlined'
                size='small'
            />
            <div className="club-setup-form__description">
                {t('clubSetup.clubSetupForm.clubNameSubtitle')}
            </div>

            <TextField
                {...text(elementConfig.description)}
                classes={{
                    root: 'club-setup-form__field',
                }}
                InputLabelProps={{
                    htmlFor: 'club-desc-id',
                    required: false
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-setup-form__field_error'
                    }
                }}
                rows={4}
                inputProps={{ maxLength: 200 }}
                id='club-desc-id'
                required
                multiline
                variant="outlined"
                label={t('clubSetup.clubSetupForm.descriptionLabel')}
                error={formState.errors.description !== undefined}
                helperText={formState.errors.description}
                size='small'
            />

            <div className="club-setup-form__description">
                {t('clubSetup.clubSetupForm.descriptionSubtitle')}
            </div>

            <TextField
                {...select(elementConfig.passion)}
                className='club-setup-form__select'
                variant="outlined"
                InputLabelProps={{
                    htmlFor: 'passion-id',
                }}
                label={t('clubSetup.clubSetupForm.passionLabel')}
                select={true}
                classes={{ root: 'club-setup-form__select' }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-setup-form__field_error'
                    }
                }}
                InputProps={{
                    id: "passion-id"
                }}
                error={formState.errors.passion !== undefined}
                helperText={formState.errors.passion}
                size='small'
            >
                {Object.values(allPassions).map(passion => (
                    <MenuItem
                        key={passion.id}
                        value={passion.id}
                    >
                        {passion.passionName}
                    </MenuItem>
                ))}
            </TextField>
            <div className="club-setup-form__description">
                {t('clubSetup.clubSetupForm.clubPassionSubtitle')}
            </div>

            <TextField
                {...select(elementConfig.timeZone)}
                className='club-setup-form__select'
                variant="outlined"
                label={t('clubSetup.clubSetupForm.timeZoneLabel')}
                InputLabelProps={{
                    htmlFor: 'timezone-id',
                }}
                InputProps={{
                    id: "timezone-id"
                }}
                classes={{ root: 'club-setup-form__select' }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-setup-form__field_error'
                    }
                }}
                error={formState.errors.timeZone !== undefined}
                helperText={formState.errors.timeZone}
                size='small'
                select={true}
            >
                {Object.values(SupportedTimeZone).map(supportedTimeZone => (
                    <MenuItem
                        key={supportedTimeZone}
                        value={supportedTimeZone}
                    >
                        {labelForTimeZone(t, supportedTimeZone, 'clubSetup.clubSetupForm.timeZones')}
                    </MenuItem>
                ))}
            </TextField>
            <div className="club-setup-form__description">
                {t('clubSetup.clubSetupForm.timeZoneSubtitle')}
            </div>
            <TextField
                {...text(elementConfig.shortCode)}
                classes={{
                    root: 'club-setup-form__field',
                }}
                InputLabelProps={{
                    htmlFor: 'short-code-id',
                    required: false,
                    classes: {
                        root: 'label-root',
                        error: 'label-error',
                        focused: 'label-focused'
                    }
                }}
                FormHelperTextProps={{
                    classes: {
                        root: 'club-setup-form__field_error'
                    }
                }}
                InputProps={{
                    classes: {
                        input: 'club-setup-form__field_bold'
                    }
                }}
                id='short-code-id'
                label={t('clubSetup.clubSetupForm.shortCodeLabel')}
                error={formState.errors.shortCode !== undefined}
                helperText={formState.errors.shortCode}
                variant='outlined'
                size='small'
            />
            <div className="club-setup-form__description">
                {t('clubSetup.clubSetupForm.shortCodeSubtitle')}
            </div>
            <div className="subtitle">
                {t('clubSetup.clubSetupForm.clubStatusSubtitle')}
            </div>
            <div className="switch-wrapper">
                <Switch
                    {...checkbox(elementConfig.clubStatus)}
                    className="switch"
                    id="switch-status"
                />
                <label htmlFor="switch-status">{t('clubSetup.clubSetupForm.clubStatusLabel')}</label>
            </div>
            <div className="club-setup-form__description">
                {t('clubSetup.clubSetupForm.clubStatusHelpText')}
            </div>
            <div className="subtitle">
                {t('clubSetup.clubSetupForm.isVirtualSubtitle')}
            </div>

            <div className="radio-wrapper">
                <div className="radio-item">
                    <Radio
                        {...radio(elementConfig.location.name, LocationState.Online)}
                        id="location-online"
                    />
                    <label htmlFor="location-online">
                        {t('clubSetup.clubSetupForm.onlineLocationLabel')}
                    </label>
                </div>
                <div className="radio-item">
                    <Radio
                        {...radio(elementConfig.location.name, LocationState.Offline)}
                        id="location-offline"
                    />
                    <label htmlFor="location-offline">
                        {t('clubSetup.clubSetupForm.offlineLocationLabel')}
                    </label>
                </div>
                <div className="radio-item">
                    <Radio
                        {...radio(elementConfig.location.name, LocationState.Both)}
                        id="location-both"
                    />
                    <label htmlFor="location-both">
                        {t('clubSetup.clubSetupForm.bothLocationLabel')}
                    </label>
                </div>
            </div>
            {shouldShowLocationBlock && (
                <>
                    <div className="subtitle">
                        {t('clubSetup.clubSetupForm.locationSubtitle')}
                    </div>
                    <TextField
                        {...text(elementConfig.city)}
                        classes={{
                            root: 'club-setup-form__field',
                        }}
                        InputLabelProps={{
                            htmlFor: 'city-id',
                            required: false,
                            classes: {
                                root: 'label-root',
                                error: 'label-error',
                                focused: 'label-focused'
                            }
                        }}
                        FormHelperTextProps={{
                            classes: {
                                root: 'club-setup-form__field_error'
                            }
                        }}
                        InputProps={{
                            classes: {
                                input: 'club-setup-form__field_bold'
                            }
                        }}
                        id='city-id'
                        label={t('clubSetup.clubSetupForm.cityLabel')}
                        error={formState.errors.city !== undefined}
                        helperText={formState.errors.city}
                        variant='outlined'
                        size='small'
                    />
                    <div className="club-setup-form__field-wrapper">
                        <TextField
                            {...select(elementConfig.state)}
                            className='club-setup-form__select'
                            variant="outlined"
                            label={t('clubSetup.clubSetupForm.stateLabel')}
                            InputLabelProps={{
                                htmlFor: 'state-id',
                            }}
                            InputProps={{
                                id: "state-id"
                            }}
                            classes={{
                                root: 'club-setup-form__select',
                            }}
                            FormHelperTextProps={{
                                classes: {
                                    root: 'club-setup-form__field_error'
                                }
                            }}
                            error={formState.errors.state !== undefined}
                            helperText={formState.errors.state}
                            size='small'
                            select={true}
                        >
                            {states.map(state => (
                                <MenuItem
                                    key={state.id}
                                    value={state.id}
                                >
                                    {state.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            {...text(elementConfig.zipCode)}
                            classes={{
                                root: 'club-setup-form__field short',
                            }}
                            InputLabelProps={{
                                htmlFor: 'zip-code-id',
                                required: false,
                                classes: {
                                    root: 'label-root',
                                    error: 'label-error',
                                    focused: 'label-focused'
                                }
                            }}
                            FormHelperTextProps={{
                                classes: {
                                    root: 'club-setup-form__field_error'
                                }
                            }}
                            InputProps={{
                                classes: {
                                    input: 'club-setup-form__field_bold'
                                }
                            }}
                            id='zip-code-id'
                            label={t('clubSetup.clubSetupForm.zipCodeLabel')}
                            error={formState.errors.zipCode !== undefined}
                            helperText={formState.errors.zipCode}
                            variant='outlined'
                            size='small'
                        />
                    </div>
                </>
            )}

            <div className='club-setup-form__terms-of-use-wrapper'>
                <div className="club-setup-form-terms-of-use">
                    <Checkbox
                        {...checkbox(elementConfig.termsOfUse)}
                        id="terms-checkbox-id"
                        classes={{
                            root: "club-setup-form__checkbox"
                        }}
                    />
                    <FormLabel className="club-setup-form__label" htmlFor="terms-checkbox-id">
                        <span className="club-setup-form__checkbox-title">
                            {t('clubSetup.clubSetupForm.agreeWithE3')}
                            &nbsp;
                        </span>
                        <Link
                            target="_blank"
                            className='club-setup-form__terms-of-use-link'
                            to={IGNITE_ROUTE_PATTERNS.LEADER_TERMS_OF_USE}
                        >
                            {t('clubSetup.clubSetupForm.termsAndConditions')}
                        </Link>
                    </FormLabel>
                </div>

                {Boolean(formState.errors.termsOfUse) && <div className="terms-of-use-error">
                    {formState.errors.termsOfUse}
                </div>}
            </div>

            <div className='club-setup-form__button-wrapper'>
                <Button
                    onClick={onSubmit}
                    className="club-setup-form__button"
                    variant="contained"
                    classes={{
                        label: 'club-setup-form__button-label',
                        disabled: 'club-setup-form__button-disabled'
                    }}
                    disabled={!submitEnabled()}
                >
                    {t('clubSetup.clubSetupForm.buttonText')}
                    <PlayIcon className='club-setup-form__button-icon' />
                </Button>
            </div>
        </div >
    )
}
