import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { SortOrder } from "../../types/sortOrder";
import { IClubV2SearchParams, IClubV2SortByColumns } from '../../types/clubFinder';
import { ITerm } from '../../types/clubSetup';
import { UserContext } from "../../contexts/UserContext";
import useGetClubsV2 from "../../hooks/useGetClubsV2";
import { useGetAllInPersonActivityTypes } from "../../hooks/useGetTerms";
import Button, { ButtonStyles } from "../../components/common/Button";
import Loading from "../../components/common/Loading";
import MobileMenuToggle from "../../components/icon/MobileMenuToggle";
import ClubDetailCard from "../../components/clubDirectory/ClubDetailCard";
import LocationPicker from "../../components/clubDirectory/LocationPicker"
import ActivityTypePicker from "../../components/clubDirectory/ActivityTypePicker"
import CloseIcon from '@material-ui/icons/Close';
import Pagination from '@material-ui/lab/Pagination';
import "./ClubDirectory.scss";
import { storeRegistrationParametersToSession } from "../../util/Util";
import { useHistory } from "react-router-dom";
import DirectoryWorkInProgress from "../../components/layout/DirectoryWorkInProgress";
/* 
 * ClubDirectory page
 *  Jira: https://hellogrouperhealth.atlassian.net/browse/EDP-268
 *  Figma: https://www.figma.com/file/0ZCdv7tDtSxIdiakBRVx3e/Club-directory-(redesign)?type=design&node-id=494-2461&mode=design&t=cydnt88p4icMIuEh-0
 *
 *  This new search area will live at /clubs and be accessible to any user, logged in or not.
 *  (The presence of its route on the nav and in the system is toggled by the feature flag USE_CLUB_DIRECTORY_NAV)
 *  Current state of the code is for the MVP launch on 1/1.
*/

export interface zipcodeAndDistanceSearchType {
    zipcode: string | null;
    distance: number | null;
}

export default function ClubDirectory(props: any): JSX.Element {
    const { t } = useTranslation('club');
    const { user } = useContext(UserContext);

    // "Activity Types" on the frontend/platform are referred to as "terms" in database/backend
    const [terms, isLoadingTerms]: [ITerm[] | undefined, boolean] = useGetAllInPersonActivityTypes();

    const [allSelectedTerms, setAllSelectedTerms] = useState<string[]>([])
    const [zipcodeAndDistanceSearch, setZipcodeAndDistanceSearch] = useState<zipcodeAndDistanceSearchType>({ 'zipcode': null, 'distance': null })
    const [filterDisplayMessage, setFilterDisplayMessage] = useState<string>('');
    const [showVirtualClubsMessage, setShowVirtualClubsMessage] = useState<boolean>(false)
    // initialize zipcode from logged in user if we have it, otherwise ghost text
    const [zipcode, setZipcode] = useState<string | null>(user?.postalCode ?? null);
    const [distance, setDistance] = useState<number | null>(10);
    const history = useHistory();

    useEffect(() => {
        if (user?.postalCode) {
            setZipcodeAndDistanceSearch({ 'zipcode': zipcode, 'distance': distance })
        }

        storeRegistrationParametersToSession(history.location);

    }, []);

    const updateZipcodeAndDistance = (update_zipcode: string | undefined, update_distance: number | undefined) => {
        const searchterm_zipcode = (update_zipcode !== undefined) ? update_zipcode : zipcode;
        const searchterm_distance = (update_distance !== undefined) ? update_distance : distance;
        if (zipcode !== undefined && zipcode !== null && zipcode !== "") {
            const zipcodeAndDistance: zipcodeAndDistanceSearchType = { 'zipcode': searchterm_zipcode, 'distance': searchterm_distance }
            setZipcodeAndDistanceSearch(zipcodeAndDistance);
        }
    }

    // NOTE - this is here due to the previous and possible future requirement for a global "Clear filters" button 
    // for both Activity Types and Location that would need to exist in this component level
    // if this requirement goes away, state for zipcode and distance should be moved into the LocationPicker component
    const clearZipcodeFilter = () => {
        setZipcode(null);
        setDistance(10);
        setZipcodeAndDistanceSearch({ zipcode: null, distance: null })
    }

    const updateAllSelectedTerms = (termNames: string[]) => {
        const arr = [...termNames];
        setAllSelectedTerms(arr);
    }

    const PAGE_LIMIT = 6;
    const [currentPage, setCurrentPage] = useState<number>(1);

    const paginationChange = (event: any, page: number) => {
        // update search params with correct offset
        let newSearchParams = JSON.parse(JSON.stringify(searchParams));
        newSearchParams.offset = (page - 1) * PAGE_LIMIT;
        setSearchParams(newSearchParams);
        setCurrentPage(page);
        // pages are long, scroll user to the top
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const [searchParams, setSearchParams] = useState<IClubV2SearchParams>(
        {
            type: null,
            sortBy: IClubV2SortByColumns.NAME,
            sortOrder: SortOrder.ASC,
            terms: allSelectedTerms,
            offset: 0,
            isVirtual: false,
            limit: PAGE_LIMIT,
            "location.postalCode": zipcode,
            "location.radiusInMiles": distance,
            "location.onlyMatchedLocations": false
        }
    );
    const [clubSearchResponse, clubSearchErrorText, isLoadingClubs] = useGetClubsV2(searchParams);

    // filter display message
    const updateFilterDisplayMessage = (filterTerms: string[], filterLocation: zipcodeAndDistanceSearchType) => {
        let displayMessageBuilder = '';

        // message to be displayed:
        // if 1 result:
        // "There is <strong>1</strong> // Bowling and Chess activity // within 10 miles of 30909"
        // if > results:
        // "There are <strong>38</strong> //  Bowling and Chess activities // within 10 miles of 30909"
        // or if > 3 activity types 
        // "There are <strong>38</strong>//  activities for the chosen activity types // within 10 miles of 30909"
        // or if ALL activity types -
        // "There are <strong>38</strong> activities for all activity types // within 10 miles of 30909"

        if (filterLocation.zipcode?.length) {
            // location - distance is always set but zipcode is either null/empty or set
            displayMessageBuilder = t("clubDirectory.searchLocationMessage", {filterLocation});
        }

        let filterTermsMessage = "";
        let activityOrActivities = (clubSearchResponse?.total === 1) ? t("clubDirectory.searchActivityMessage") :  t("clubDirectory.searchActivitiesMessage");
        if (filterTerms.length > 0) {
            if (filterTerms.length < 4) {
                for (let i = 0; i < filterTerms.length; i++) {
                    filterTermsMessage += " " + filterTerms[i];
                    if (filterTerms.length > 1 && i < filterTerms.length - 1) {
                        filterTermsMessage += t("clubDirectory.searchAndMessage");
                    }
                }
                filterTermsMessage += activityOrActivities;
            } else if (filterTerms.length === terms?.length) {
                filterTermsMessage = activityOrActivities + t("clubDirectory.searchAllActivitiesMessage");
            } else {
                filterTermsMessage = activityOrActivities + t("clubDirectory.searchChosenActivitiesMessage");
            }
        }
        displayMessageBuilder = filterTermsMessage + displayMessageBuilder;

        if (displayMessageBuilder !== activityOrActivities) {
            // only set if there is already something in there (ie zipcode &/or terms are set)
            displayMessageBuilder = ((clubSearchResponse?.total === 1) 
                ? t("clubDirectory.searchResultsCountMessageSingular", {total: clubSearchResponse?.total}) 
                : t("clubDirectory.searchResultsCountMessage", {total: clubSearchResponse?.total})
            ) + displayMessageBuilder;
          
            setShowVirtualClubsMessage(clubSearchResponse?.total === 0);        
            setFilterDisplayMessage(displayMessageBuilder);
        } else {
            // otherwise you're not currently searching - set to empty string
            setFilterDisplayMessage("");
        }
    }

    useEffect(() => {
        updateFilterDisplayMessage(allSelectedTerms, zipcodeAndDistanceSearch);
    }, [clubSearchResponse]);

    useEffect(() => {
        let newSearchParams = JSON.parse(JSON.stringify(searchParams));
        newSearchParams.terms = allSelectedTerms;
        newSearchParams["location.postalCode"] = zipcodeAndDistanceSearch.zipcode;
        newSearchParams["location.radiusInMiles"] = zipcodeAndDistanceSearch.distance;
        newSearchParams.offset = 0;
        setSearchParams(newSearchParams);
    }, [allSelectedTerms, zipcodeAndDistanceSearch])


    // UI interactivity
    const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);

    const toggleMenuDrawer = function () {
        if (!openMobileMenu) {
            document.body.classList.add("ClubDirectory_OpenMobileMenu")
        } else {
            document.body.classList.remove("ClubDirectory_OpenMobileMenu")
        }
        setOpenMobileMenu(!openMobileMenu);
    }

    const goToVirtualClubs = function () {
        history.push('/virtual-clubs')
    }


    return (
        <div className={"ClubDirectory"}>
            <div className="ClubDirectory_FilterColumn_Container">
                <div className={"ClubDirectory_FilterColumn "}>
                    <div className="ClubDirectory_Section ClubDirectory_FilterColumn_MobileHeader"
                        onClick={toggleMenuDrawer}
                    >
                        <div className="ClubDirectory_FormControl_Row1 ClubDirectory_FilterColumn_MobileHeader--Form">
                            <div className="ClubDirectory_Title">{t('clubDirectory.pageTitle')}</div>
                            <div className="ClubDirectory_MobileToggle">
                                <MobileMenuToggle className={"ClubDirectory_MenuOpen"} />
                                <CloseIcon className={"ClubDirectory_MobileClose"} />
                            </div>
                        </div>
                        <div className="ClubDirectory_FormControl_Row1 ClubDirectory_FilterColumn_MobileHeader--Form">
                            <DirectoryWorkInProgress
                                className="ClubDirectory_DirectoryWorkInProgress"
                            />
                        </div>
                    </div>
                    <div className={" ClubDirectory_FilterColumn_MobileBody"}>
                        <div className="ClubDirectory_Section ClubDirectory_Section--WithSeparator">
                            <div className="ClubDirectory_FormControl_Row1">
                                <div className="ClubDirectory_SubTitle">{t('clubDirectory.locationPickerTitle')}</div>
                                <div className="ClubDirectory_Link" onClick={clearZipcodeFilter}>
                                    {t('clubDirectory.locationPickerClear')}
                                </div>
                            </div>

                            <LocationPicker
                                placeholderText={t('locationPicker.initialTextfieldValue')}
                                zipcode={zipcode} setZipcode={setZipcode}
                                distance={distance} setDistance={setDistance}
                                setFilterZipcodeAndDistance={updateZipcodeAndDistance}
                                apiErrors={clubSearchErrorText}
                            />
                        </div>

                        <div className="ClubDirectory_Section ClubDirectory_Section--FillToBottom">
                            <div className="ClubDirectory_FormControl_Row1">
                                <div className="ClubDirectory_SubTitle">{t('clubDirectory.activityPickerTitle')}</div>
                            </div>

                            <ActivityTypePicker
                                terms={terms}
                                selectedTerms={allSelectedTerms}
                                setSelectedTerms={updateAllSelectedTerms}
                            ></ActivityTypePicker>
                        </div>


                        <div className="ClubDirectory_Section--Bottom">
                            <Button
                                buttonStyle={ButtonStyles.FilledClubDirectoryPrimary}
                                className={"ClubDirectory_MobileFilter_SubmitButton"}
                                clickHandler={toggleMenuDrawer}
                            >
                                {t('clubDirectory.mobileFilterSubmitButton')}&nbsp;
                                {
                                    (!isLoadingClubs && clubSearchResponse?.total) ? (
                                        <>
                                            ({clubSearchResponse?.total})
                                        </>
                                    ) : null
                                }
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ClubDirectory_ClubList_Container">
                <div className="ClubDirectory_ClubList">
                    {props.emailVerificationNag}
                    {
                        isLoadingClubs ?
                            <Loading loading={isLoadingClubs} />
                            :
                            <>
                                <div className="ClubDirectory_FilterDisplayMessage" dangerouslySetInnerHTML={{ __html: filterDisplayMessage }} />
                                {
                                    (!isLoadingClubs && !!clubSearchResponse?.items?.length) ?
                                        <>
                                            {clubSearchResponse.items.map((club) => (
                                                <ClubDetailCard key={club.id} club={club}></ClubDetailCard>
                                            ))}

                                            < Pagination
                                                count={Math.ceil(clubSearchResponse.total / clubSearchResponse.limit)}
                                                page={currentPage}
                                                onChange={paginationChange}
                                                color="primary"
                                                className="ClubDirectory_Pagination" />
                                        </> : <></>
                                }

                                {
                                    (showVirtualClubsMessage) ?
                                        <>
                                            <div className="ClubDirectory_VirtualClubs"> {t('clubDirectory.noClubs')}
                                            &nbsp;
                                                <span className="ClubDirectoryVC_Link" onClick={goToVirtualClubs}>{t('clubDirectory.virtualClubs')}</span>
                                            </div >
                                        </> : <></>
                                }
                               
                            </>
                    }
                </div>
            </div>

        </div>
    )
}