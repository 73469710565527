import React from "react";
import "./Loading.scss";
import { CircularProgress } from "@material-ui/core";

export interface LoadingProps {
    loading: boolean;
    size?: number;
    className?: string;
}

export default function Loading(props: LoadingProps): JSX.Element {
    let size = props.size === undefined ? 100 : props.size;
    let classes = 'CCLoading';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (
        <div className={`${classes} ${!props.loading && 'CCLoading_DisplayNone'}`}>
            <CircularProgress size={size} />
        </div>
    );
}
