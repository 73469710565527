import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./MemberSelectPayerPage.scss";
import {loadConfig} from "../../../services/ConfigService";
import Loading from "../../../components/common/Loading";
import LoadingMessage from "../../../components/common/LoadingMessage";
import {RouteComponentProps} from "react-router-dom";
import {
    FormHelperText,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import PlayButton from "../../../components/common/PlayButton";
import {ButtonStyles} from "../../../components/common/Button";
import {useFormState} from "react-use-form-state";
import {
    GetAllPayersResponseBody,
    GetPayerResponseBody
} from "../../../services/MemberService";
import {SelectedPayerContext} from "../../../contexts/SelectedPayerContext";

/**
 * The first step in the member registration process in which the user selects
 * their payer and enters their insurance id.
 *
 * The user arrives here from the `MemberLandingPage`.
 */
export default function MemberSelectPayerPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {history} = props;

    const initialState = {
        payerId: "",
        insuranceId: ""
    }

    const [formState, {text, select}] = useFormState(initialState);

    const {payerId, insuranceId} = formState.values;

    const elementConfig = {
        payerId: {
            name: "payerId",
            onChange: () => {
                formState.setField("insuranceId", "");
            },
            validate: (value: string | number): string | undefined => {
                if (typeof value === "number" && allPayers.find(p => p.id === value)) {
                    return;
                }
                return t('memberSelectPayerPage.selectPayer');
            },
            validateOnBlur: true
        },
        insuranceId: {
            name: "insuranceId",
            validate: (value: string): string | undefined => {
                if (selectedPayer === null) {
                    return;
                }

                if (value === "") {
                    return t('memberSelectPayerPage.enterPayerId', {payerName: selectedPayer.payerName});
                }
            },
            validateOnBlur: true
        }
    }

    const submitEnabled =
        Object.entries(formState.pristine).every(([key, value]) => !value)
        && Object.entries(formState.validity).every(([key, value]) => value);

    const [loading, setLoading] = useState(true);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);
    const selectedPayer : GetPayerResponseBody | null =
        allPayers.find(p => p.id === payerId) || null;

    const {setPayerDetails} = useContext(SelectedPayerContext);
    const [insuranceIdLabel, setInsuranceIdLabel] = useState<string>('');

    useEffect(() => {
        if (selectedPayer) {
            if (selectedPayer.unlistedPayerPlaceholder){
                setInsuranceIdLabel(t('memberSelectPayerPage.healthPlanName'));
            }
            else {
                setInsuranceIdLabel(selectedPayer.payerName + " ID");
            }
        }
    }, [selectedPayer, t])

    function onSubmitChoosePayerForm() {
        if (!submitEnabled) {
            return;
        }

        if (payerId === null || insuranceId === null) {
            // TODO: How to add validation message?
            return;
        }

        setPayerDetails({ payerId, insuranceId });
        history.push("/member/create-account");
    }

    useEffect(() => {

        const loadAllPayers = async () => {

            const config = await loadConfig();

            const request = new Request(`${config.apiOrigin}/all-payers`);

            // This is one of the rare, non-authenticated API endpoints.
            const response = await fetch(request);

            if (!response.ok) {
                const responseBodyText = await response.text();

                console.error(
                    `Non-successful response from API: `
                    + `${response.status} ${response.statusText} `
                    + `from ${response.url}\n\n${responseBodyText}`);

                setLoading(false);
                setLoadingErrorMessage(t('memberSelectPayerPage.unexpectedError'));

                return;
            }

            const responseBody: GetAllPayersResponseBody = await response.json();

            setAllPayers(responseBody.allPayers);
            setLoading(false);
            setLoadingErrorMessage(null);

        };

        loadAllPayers();

    }, [t]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <div className="MemberSelectPayerPage">
            <h1>{t('memberSelectPayerPage.selectProvider')}</h1>
            {loading && loadingErrorMessage === null && (
                <Loading loading={loading}/>
            )}
            {!loading && loadingErrorMessage !== null && (
                <LoadingMessage message={loadingErrorMessage}/>
            )}
            <form className="MemberSelectPayerPage_Form">
                <div className="MemberSelectPayerPage_Fields">
                    <Select {...select(elementConfig.payerId)}
                            error={formState.errors.payerId !== undefined}
                            className="MemberSelectPayerPage_PayerSelect"
                            variant="outlined"
                            disabled={loading}
                            displayEmpty
                            required>
                        <MenuItem value="" disabled>
                            {t('memberSelectPayerPage.selectProvider')}
                        </MenuItem>
                        {allPayers.map(payer => (
                            <MenuItem key={payer.id} value={payer.id}>
                                {payer.payerName}
                            </MenuItem>
                        ))}
                    </Select>
                    {selectedPayer !== null &&
                    <>
                        <TextField {...text(elementConfig.insuranceId)}
                                   error={formState.errors.insuranceId !== undefined}
                                   className="MemberSelectPayerPage_InsuranceIdField"
                                   required
                                   inputProps={{maxLength: 50}}
                                   label={insuranceIdLabel}
                                   variant="outlined"
                        />
                        <PlayButton type="button"
                                    clickHandler={onSubmitChoosePayerForm}
                                    disabled={!submitEnabled}
                                    text={t('memberSelectPayerPage.continueButtonText')}
                                    className="MemberSelectPayerPage_SubmitButton"
                                    buttonStyle={ButtonStyles.FilledPrimary}
                        />
                    </>
                    }
                </div>

                {Object.entries(formState.errors).length > 0 &&
                <div className="MemberSelectPayerPage_FormErrors">
                    {Object.entries(formState.errors).map(([key, value]) =>
                        <div key={key}
                             className="MemberSelectPayerPage_FormError">
                            {value}
                        </div>)
                    }
                </div>
                }

            </form>
        </div>
    );
}
