import React from "react";
import {useTranslation} from 'react-i18next';
import { useHistory } from "react-router-dom";
import "./ClubView.scss";
import LocationView from "../common/LocationView";
import PlayButton from "../common/PlayButton";
import {ButtonStyles} from "../common/Button";
import {Link} from "react-router-dom";
import {ClubStatus} from "../../util/ClubStatus";
import Email from "@material-ui/icons/Email";
import {ClubType} from "../../types/club";
import { getClubPageRouteLink } from "../../util/ignite/routes.utils";
import Chip from "@material-ui/core/Chip";

interface ClubViewProps {
    urlFragment: any;
    clubType?: ClubType;
    clubImage: string;
    clubName: string;
    clubDescription: string;
    locationName?: string | null;
    locationAddress1?: string | null;
    locationAddress2?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip?: string;
    passion?: string;
    timeZone: string;
    clubLeaderFirstName: string;
    clubLeaderLastName: string;
    clubLeaderEmail: string;
    clubLeaderView: boolean;
    primaryClubLeaderView: boolean;
    clubStatus?: ClubStatus,
    className?: string,
    isVirtual?: boolean,
}


export default function ClubView(props: ClubViewProps): JSX.Element {
    const { t } = useTranslation('club');
    const isAssociation = props.clubType === ClubType.ASSOCIATION;
    const history = useHistory();

    let classes = 'CCClubView';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
 
    const isVirtual = props.isVirtual === true

    function goToVirtualClub() { 
        history.push(getClubPageRouteLink(true, props.urlFragment ));   
    }
    return (

        <div className={classes}>

            {props.clubLeaderView && props.clubStatus === ClubStatus.INACTIVE &&
            <div className="CCClubView_InactiveWarning">
                <div className="CCClubView_InactiveWarningTitle">
                    {isAssociation ? t('clubView.inactiveWarningTitleAssoc') : t('clubView.inactiveWarningTitle')}
                </div>
                <div className="CCClubView_InactiveWarningText">
                    {isAssociation ? t('clubView.inactiveWarningTextAssoc') : t('clubView.inactiveWarningText')}
                    {props.primaryClubLeaderView && <>{" "}
                        {isAssociation ? t('clubView.activateInstructionsAssoc') : t('clubView.activateInstructions')}
                    <div className="CCClubView_InactiveWarningButton">
                        <PlayButton text={isAssociation ? t('clubView.editAssociationButtonText') : t('clubView.editClubButtonText')}
                                    to={`/edit-club/${props.urlFragment}`}
                                    buttonStyle={ButtonStyles.Unfilled}/>
                    </div>
                    </>}
                </div>
            </div>}
            <div style={{backgroundImage: "url("+props.clubImage+")"}} className={`CCClubView_Image`}/>
            <div className={`CCClubView_Details ${classes}`}>
                <div className="CCClubView_ClubNameAndDescription">
                    {isVirtual &&  <div className="CCClubView_ClubNameAndVirtual"  onClick={goToVirtualClub}>
                    <div className="CCClubView_ClubName">
                        {props.clubName}
                    </div>
                  
                    <div className="CCClubView_VirtualClub">
                        <Chip className={"CCClubView_Chip Virtual_Full"} 
                         label={t('clubView.virtualClub')}/>
                         
                    </div>
                    </div>}
                    {!isVirtual &&  <div className="CCClubView_ClubName">
                        {' '}{props.clubName}
                    </div>}
                   
                    <div className="CCClubView_ClubDescription">
                        {props.clubDescription}
                    </div>
                </div>
                <div className="CCClubView_PassionAndLocation">
                    <div className="CCClubView_Passion">
                        {props.passion}
                    </div>
                    <div className="CCClubView_Location">
                        <LocationView locationCity={props.locationCity}
                                      locationState={props.locationState}
                                      locationZip={props.locationZip === undefined ? "" : props.locationZip}/>
                    </div>
                </div>
                {(props.clubLeaderFirstName || props.clubLeaderLastName) &&
                    <div className="CCClubView_ClubLeader">
                        {isAssociation ? t('clubView.associationLeaderLabel') :
                            t('clubView.clubLeaderLabel')}: {props.clubLeaderFirstName} {props.clubLeaderLastName}
                    </div>
                }
                {props.clubLeaderEmail &&
                    <div className="CCClubView_ClubLeaderEmail">
                        <Email/> <a href={`mailto:${props.clubLeaderEmail}`}>{isAssociation ?
                        t('clubView.emailAssociationLeaderText') : t('clubView.emailClubLeaderText')}</a>
                    </div>
                }
                {props.clubLeaderView && <div className="CCClubView_Links">
                    <div className="CCClubView_Links_Row">
    
                    {props.isVirtual && <>
                    <Link to={getClubPageRouteLink(true, props.urlFragment)}>{t('clubView.viewVirtualClubDetailPage')}</Link>
                    &nbsp;&#x7c;&nbsp;
                    </>}

                    
                    {props.primaryClubLeaderView && <>
                    <Link to={`/edit-club/${props.urlFragment}`}>{isAssociation ? t('clubView.editAssociationInfo') : t('clubView.editClubInfo')}</Link>
                    </>}

                    </div>
                </div>}
            </div>
        </div>
    );
}
