import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./MasqueradePage.scss";
import {Link, Redirect, RouteComponentProps} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";

export default function MasqueradePage({location}: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('pages');
    const {user, masquerade} = useContext(UserContext);

    const [masqueradeResult, setMasqueradeResult] = useState<string|null>(null);
    const [processedToken, setProcessedToken] = useState<string | null>();
    const [redirectToHome, setRedirectToHome] = useState(false);

    useEffect(
        () => {
            async function processToken() {
                const hash = location.hash;
                const hashParams = new URLSearchParams(hash.substring(1));
                const masqueradeToken = hashParams.get("token");

                if (masqueradeToken === processedToken) {
                    return;
                }

                setProcessedToken(masqueradeToken);

                if (masqueradeToken === null) {
                    setRedirectToHome(true);
                    setMasqueradeResult(t('masqueradePage.malformedError'));
                    return;
                }

                const isLoggedIn = await masquerade(masqueradeToken);
                if (!isLoggedIn) {
                    setMasqueradeResult(t('masqueradePage.tokenError'));
                    return;
                }
            }

            processToken();
        },
        [location, masquerade, processedToken, t]);

    return (
        <>
            {redirectToHome && <Redirect to={"/"}/>}
            {!redirectToHome && masqueradeResult !== null && <>
                <div className="MasqueradePage">
                    <div className="Result">
                        {masqueradeResult}
                    </div>
                </div>
            </>}
            {!redirectToHome && masqueradeResult === null && <>
                <div className="MasqueradePage">
                    <div className="Instructions">
                        {user !== null && (
                            <>
                                <div>{t('masqueradePage.loggedIn', { user: user.email })}</div>
                                <div>{t('masqueradePage.instructions', { user: user.email})}</div>
                                <div>{t('masqueradePage.logOutRequest')}</div>
                                <div>{t('masqueradePage.usersHomePage')} <Link to={"/"}>{t('masqueradePage.usersHomePageLink')}</Link>.</div>
                            </>
                        )}
                    </div>
                </div>
            </>
            }
        </>
    );
}
