import React, {
    ChangeEvent,
    FormEvent,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { useTranslation } from 'react-i18next';
import InputMask from "react-input-mask";
import { 
        Redirect, 
        RouteComponentProps, 
        useLocation 
    } from "react-router-dom";

import "./EditEventPage.scss";

import {
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Input,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {
    ChronoField,
    DateTimeFormatter,
    DateTimeParseException,
    LocalDate,
    LocalDateTime,
    LocalTime,
    ZonedDateTime,
    ZoneId
} from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";

import { USE_EDP_44_VIRTUAL_CLUBS } from "../../constants/flags";
import { UserContext } from "../../contexts/UserContext";

import BackLink from "../../components/common/BackLink";
import { ButtonStyles } from "../../components/common/Button";
import Loading from "../../components/common/Loading";
import PlayButton from "../../components/common/PlayButton";
import FieldWithBottomText from "../../components/form/FieldWithBottomText";
import MuiSelectElement from "../../components/form/MuiSelectElement";
import { CCStyles } from "../../components/form/Theme";

import {
    createEvent,
    CreateOrUpdateEventRequestBody,
    GetClubLeaderClubResponseBody,
    GetClubLeaderEventResponseBody,
    GetClubLeaderResponseBody,
    getMyClub,
    getMyEvent,
    updateEvent,
    uploadEventFiles,
    VirtualEventDetailsModel,
    EventRecurrence,
    WeeklyDay,
    labelForWeeklyDay,
    EventOccurrence
} from "../../services/ClubLeaderService";
import { useFeatureFlag } from "../../services/FeatureFlagService";
import { RequestError } from "../../services/RequestService";

import {
    labelForTimeZone,
    SupportedTimeZone
} from "../../util/SupportedTimeZone";
import { EventStatus } from "../../util/EventStatus";
import { EventType, isDeprecatedEventType, labelForEventType } from "../../util/EventType";
import states from "../../util/UnitedStates";
import SaveIndicator from "../../components/common/SaveIndicator";
import { LocalDateUtils } from "../../util/FixedJsJodaUtils";
import { validEmailRegexText } from "../../util/Util";
import VirtualEventMedium from "../../util/VirtualEventMedium";
import ContactPhoneNumber, { PhoneNumberOverride } from "../../components/common/ContactPhoneNumber";

interface CreateEventResponseBody {
    eventUrlFragment: string
}

interface EditEventPageMatchParams {
    eventOrClubUrlFragment: string;
}

export function GetDurations() {
    return ["0.25", "0.5", "0.75",
        "1", "1.25", "1.5", "1.75",
        "2", "2.25", "2.5", "2.75",
        "3", "3.25", "3.5", "3.75",
        "4"]
}

export function GetHours() {
    return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]
}

export function GetMinutes() {
    return ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
        "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
        "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
        "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
        "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
        "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"]
}

export function GetAmPm() {
    return ["AM", "PM"]
}

export function GetListOfStringNumbers(max : number) {
    const numbers = [];
    for (let i = 1; i <= max; i++) {
        numbers.push(i.toString());
    }
    return numbers;
}

const SUPPORTED_EVENT_FILE_CONTENT_TYPES = [
    /* .doc */ "application/msword",
    /* .docx */ "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    /* .jpg, .jpeg, .jfif, .pjp, .pjpeg */ "image/jpeg",
    "image/gif"
];

const MAX_FILES_PER_EVENT = 5;

const MAX_EVENT_FILE_SIZE_IN_BYTES = 50 * 1024 * 1024; // 50 MiB

export default function EditEventPage({match, history}: RouteComponentProps<EditEventPageMatchParams>): JSX.Element {
    const useEdp44VirtualClubs : boolean = useFeatureFlag(USE_EDP_44_VIRTUAL_CLUBS, false);

    const { t } = useTranslation('pages');
    const {user, authenticatedFetch, getContactPhoneNumber} = useContext(UserContext);
    const classes = CCStyles();
    const eventOrClubUrlFragment = match.params.eventOrClubUrlFragment;

    const [loadingMessage, setLoadingMessage] = useState<string | null>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [savingMessage, setSavingMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState<boolean>(false);
    const [savingAttempted, setSavingAttempted] = useState(false);
    const [savingSuccess, setSavingSuccess] = useState(false);
    const [myClubUrlFragment, setMyClubUrlFragment] = useState<string | null>(eventOrClubUrlFragment);

    const [eventName, setEventName] = useState("");
    const [description, setDescription] = useState("");
    const [importantDetails, setImportantDetails] = useState("");
    const [leaderFirstName, setLeaderFirstName] = useState("");
    const [leaderLastName, setLeaderLastName] = useState("");
    const [leaderEmail, setLeaderEmail] = useState("");
    const [leaderPhone, setLeaderPhone] = useState("");
    const [initialTimeZone, setInitialTimeZone] = useState("America/New_York");
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [initialHowLongInHours, setInitialHowLongInHours] = useState(1);
    const [howLongInHours, setHowLongInHours] = useState(1);
    const [locationName, setLocationName] = useState("");
    const [streetAddressOne, setStreetAddressOne] = useState("");
    const [streetAddressTwo, setStreetAddressTwo] = useState("");
    const [city, setCity] = useState("");
    const [countrySubdivision, setCountrySubdivision] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [initialEventType, setInitialEventType] = useState("");
    const [eventType, setEventType] = useState("");
    const [isPaid, setIsPaid] = useState(false);
    const [isVirtual, setIsVirtual] = useState(false);
    const [virtualEventDetails, setVirtualEventDetails] = useState<VirtualEventDetailsModel | null>(null);
    const [isCancelled, setIsCancelled] = useState(false);
    const [initialStartsAtDate, setInitialStartsAtDate] = useState<LocalDate | DateTimeParseException | null>(null);
    const [startsAtDate, setStartsAtDate] = useState<LocalDate | DateTimeParseException | null>(null);
    const [initialStartsAtTime, setInitialStartsAtTime] = useState("");
    const [startsAtTime, setStartsAtTime] = useState("");
    const [cancelled, setCancelled] = useState(false);
    const [hours, setHours] = useState("12");
    const [minutes, setMinutes] = useState("00");
    const [ampm, setAmPm] = useState<string>("AM");
    const [initialRecurrence, setInitialRecurrence] = useState<EventRecurrence | null>(null);
    const [recurrence, setRecurrence] = useState<EventRecurrence | null>(null);
    const [isRecurring, setIsRecurring] = useState(false);
    const [recurrenceEndsType, setRecurrenceEndsType] = useState();
    const [recurrenceEndsAtDate, setRecurrenceEndsAtDate] = useState<LocalDate | DateTimeParseException | null>(null);
    const [recurrenceEndsAfterCount, setRecurrenceEndsAfterCount] = useState(1);
    const [occurrences, setOccurrences] = useState<EventOccurrence[]>([]);
    const [occurrenceIdsToCancel, setOccurrenceIdsToCancel] = useState<string[]>([]);
    const [disableCancellingOccurrences, setDisableCancellingOccurrences] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>();
    const [fileInputErrors, setFileInputErrors] = useState<string[]>([]);

    const location = useLocation();
    const isCreatingNewEvent = location.pathname.indexOf("/add-activity") === 0;

    const supportPhoneNumber = getContactPhoneNumber({override: PhoneNumberOverride.ANONYMOUS});

    const [clubLeaders, setClubLeaders] = useState<GetClubLeaderResponseBody[]>([{email: '', firstName: '', lastName: '', phone: ''}]);
    const [selectedClubLeaderIndex, setSelectedClubLeaderIndex] = useState(0);

    const useStyles = makeStyles((theme: Theme) => 
        createStyles({
            listRoot: {
                width: '100%',
                maxWidth: 360,
                backgroundColor: theme.palette.background.paper,
                position: 'relative',
                overflow: 'auto',
                maxHeight: 300,
            },
        }),
    );
    const muiClasses = useStyles();

    useEffect(
        () => {
            if (user === null) {
                history.push(`/login`);
                return;
            }

            const loadClubFromApi = async () => {
                setLoadingMessage(null);
                setLoading(true);

                try {
                    const myClub: GetClubLeaderClubResponseBody | null = await getMyClub({
                        authenticatedFetch,
                        clubUrlFragment: eventOrClubUrlFragment,
                        omitLeaders: false,
                    });

                    if (myClub === null) {
                        history.replace('/');
                        return;
                    }

                    setLoading(false);
                    
                    setMyClubUrlFragment(myClub.urlFragment);
                    setTimeZone(myClub.timeZone);
                    setPostalCode(myClub.location?.postalCode ?? "");
                    setCity(myClub.location?.city ?? "");
                    setCountrySubdivision(myClub.location?.countrySubdivision ?? "");

                    if (myClub) {
                        const clearEntry = {email: '', firstName: '', lastName: '', phone: ''};
                        const leaders = [clearEntry, ...myClub.primaryClubLeaders, ...myClub.deputyClubLeaders];
                        setClubLeaders(leaders);
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            if (isCreatingNewEvent) {
                loadClubFromApi();
                return;
            }

            const loadEventFromApi = async () => {
                setLoading(true);

                let thisEvent: GetClubLeaderEventResponseBody;
                let thisClub: GetClubLeaderClubResponseBody | null;
                try {
                    thisEvent = await getMyEvent({
                        authenticatedFetch,
                        eventUrlFragment: eventOrClubUrlFragment
                    });

                    thisClub = await getMyClub({
                        authenticatedFetch,
                        clubUrlFragment: thisEvent.clubUrlFragment,
                        omitLeaders: false,
                    });
                } catch (e) {
                    if ((e as RequestError).notLoggedIn) {
                        setLoadingMessage(t('editEventPage.loginMessage'));
                        // todo: go to login page
                        return;
                    } else {
                        setLoadingMessage(t('editEventPage.loadingError'));
                        setLoading(false);
                        return;
                    }
                }
                setMyClubUrlFragment(thisEvent.clubUrlFragment);
  
                if (thisClub) {
                    const clearEntry = {email: '', firstName: '', lastName: '', phone: ''};
                    const leaders = [clearEntry, ...thisClub.primaryClubLeaders, ...thisClub.deputyClubLeaders];
                    setClubLeaders(leaders);

                    const clubLeaderIndex = leaders.findIndex(x => x.email === thisEvent.leaderEmail);
                    if (clubLeaderIndex >= 0) {
                        setSelectedClubLeaderIndex(clubLeaderIndex);
                    }
                }
                
                setEventName(thisEvent.eventName);
                setDescription(thisEvent.description);
                setImportantDetails(thisEvent.importantDetails);
                setLeaderFirstName(thisEvent.leaderFirstName);
                setLeaderLastName(thisEvent.leaderLastName);
                setLeaderEmail(thisEvent.leaderEmail);
                setLeaderPhone(thisEvent.leaderPhone);
                setStartsAtDate(LocalDate.parse(thisEvent.startsAtDate, DateTimeFormatter.ISO_LOCAL_DATE));
                setInitialStartsAtDate(LocalDate.parse(thisEvent.startsAtDate, DateTimeFormatter.ISO_LOCAL_DATE));
                setStartsAtTime(thisEvent.startsAtTime);
                setInitialStartsAtTime(thisEvent.startsAtTime);
                let zone = ZoneId.of(thisEvent.timeZone);
                let startsAtInstant
                    = ZonedDateTime.parse(thisEvent.startsAtInstant).withZoneSameInstant(zone);
                setMinutes(zeroPad(startsAtInstant.get(ChronoField.MINUTE_OF_HOUR) + ""));
                if (startsAtInstant.get(ChronoField.AMPM_OF_DAY) === 0) {
                    setAmPm("AM");
                    let hours = startsAtInstant.get(ChronoField.HOUR_OF_DAY);
                    if (hours === 0) {
                        hours = 12;
                    }
                    setHours(hours + "");
                } else {
                    setAmPm("PM");
                    let hours = startsAtInstant.get(ChronoField.HOUR_OF_DAY);
                    hours = hours - 12;
                    if (hours === 0) {
                        hours = 12;
                    }
                    setHours(hours + "");
                }
                setTimeZone(thisEvent.timeZone);
                setInitialTimeZone(thisEvent.timeZone);

                // TODO: Round to the nearest 0.25?
                setHowLongInHours(thisEvent.howLongInHours);
                setInitialHowLongInHours(thisEvent.howLongInHours);

                setLocationName(thisEvent.location?.locationName ?? "");
                setStreetAddressOne(thisEvent.location?.streetAddressOne ?? "");
                setStreetAddressTwo(thisEvent.location?.streetAddressTwo ?? "");
                setCity(thisEvent.location?.city ?? "");
                setCountrySubdivision(thisEvent.location?.countrySubdivision ?? "");
                setPostalCode(thisEvent.location?.postalCode ?? "");
                setEventType(thisEvent.typeOne && thisEvent.typeOne in EventType ? thisEvent.typeOne : "");
                setInitialEventType(thisEvent.typeOne && thisEvent.typeOne in EventType ? thisEvent.typeOne : "");
                setIsPaid(thisEvent.isPaid);
                setIsVirtual(thisEvent.isVirtual);
                setVirtualEventDetails(thisEvent.virtualEventDetails);
                setIsRecurring(thisEvent.isRecurring);
                setRecurrence(thisEvent.recurrence);
                setInitialRecurrence(thisEvent.recurrence);
                if (thisEvent.recurrence !== null) {
                    if (!!thisEvent.recurrence?.endTimes) {
                        setRecurrenceEndsType("AFTER");
                        setRecurrenceEndsAfterCount(thisEvent.recurrence?.endTimes);
                    } else if (!!thisEvent.recurrence?.endDateTime) {
                        setRecurrenceEndsType("ON");
                        setRecurrenceEndsAtDate(thisEvent.recurrence?.endDateTime);
                    }
                }
                setOccurrences(thisEvent.occurrences ?? []);
                
                setIsCancelled(thisEvent.status === EventStatus.CANCELLED);
                setLoadingMessage(null);
                setLoading(false);
            };

            loadEventFromApi();
        },
        [user, authenticatedFetch, eventOrClubUrlFragment, myClubUrlFragment, isCreatingNewEvent, history, t]);

    useEffect(() => {

        let minutesAsNumber: number = Number(minutes);
        if (isNaN(minutesAsNumber)) {
            return;
        }
        let hoursAsNumber: number = Number(hours);
        if (isNaN(hoursAsNumber)) {
            return;
        }
        const inFormatter = DateTimeFormatter
            .ofPattern("h:mm a").withLocale(Locale.US);
        const outFormatter = DateTimeFormatter
            .ofPattern("HH:mm:ss").withLocale(Locale.US);
        let startsAtTime: LocalTime = LocalTime.parse(hours + ":" + minutes + " " + ampm, inFormatter)
        setStartsAtTime(outFormatter.format(startsAtTime));
    }, [hours, minutes, ampm, startsAtTime, setStartsAtTime]);

    function zeroPad(s: string) {
        if (s.length === 1) {
            return "0" + s;
        }

        return s;
    }

    function formatOccurrenceTime(occurenceStartsAt : LocalDateTime) : string {
        var dateTime = LocalDateTime.parse(occurenceStartsAt.toString());
        const formatter = DateTimeFormatter.ofPattern("eee, MMM d, yyyy, h:mm a").withLocale(Locale.US);
        return dateTime.format(formatter);
    }

    // Occcurrences cannot be canceled if startDate, startTime, duration or recurrence fields have been changed
    useEffect(() => {
        let isRecurringRelatedFieldChanged = false;
        if (isCreatingNewEvent) {
            isRecurringRelatedFieldChanged = true;
        }
        if(!!initialRecurrence && !!recurrence) {
            if (initialRecurrence.endDateTime instanceof LocalDate && recurrence.endDateTime instanceof LocalDate) {
                if (!initialRecurrence.endDateTime.equals(recurrence.endDateTime)) {
                    isRecurringRelatedFieldChanged = true;
                } 
            } else if (initialRecurrence.endDateTime !== recurrence.endDateTime) {
                isRecurringRelatedFieldChanged = true;
            }

            if(initialRecurrence.endTimes !== recurrence.endTimes 
                || initialRecurrence.weeklyDays !== recurrence.weeklyDays 
                || initialRecurrence.repeatInterval !== recurrence.repeatInterval 
                || initialRecurrence.recurrenceType !== recurrence.recurrenceType) {
                isRecurringRelatedFieldChanged = true;
            }
        }
        if (initialStartsAtDate instanceof LocalDate && startsAtDate instanceof LocalDate) {
            if (!initialStartsAtDate.equals(startsAtDate)) {
                isRecurringRelatedFieldChanged = true;
            }
        } else if (initialStartsAtDate !== startsAtDate){
            isRecurringRelatedFieldChanged = true;
        }
        if (initialStartsAtTime !== startsAtTime || initialHowLongInHours !== howLongInHours || initialTimeZone !== timeZone) {
            isRecurringRelatedFieldChanged = true;
        }
        if(isRecurringRelatedFieldChanged) {
            setOccurrenceIdsToCancel([]);
        }
        setDisableCancellingOccurrences(isRecurringRelatedFieldChanged);
        
    }, [initialStartsAtDate, startsAtDate, initialStartsAtTime, startsAtTime, initialHowLongInHours, howLongInHours, initialTimeZone, timeZone, initialRecurrence, recurrence]);

    function onChangeEventName(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setEventName(event.target.value);
    }

    function onChangeDescription(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setDescription(event.target.value);
    }

    function onChangeImportantDetails(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setImportantDetails(event.target.value);
    }

    function onChangeFilesToUpload(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;
        if (files === null) return; // Impossible.

        const errors = [];

        // FIXME: When editing an event, populate `oldFileNames` from the
        //        existing EventFile.FileName values.  Fetch these values from
        //        the API.
        const oldFileNames = new Set<string>();
        const newFileNames = new Set<string>();

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            if (file === null) continue; // Impossible.

            const fileName = file.name;
            const contentType = file.type;
            const sizeInBytes = file.size;

            // TODO: Repeat file name validation logic from the API?

            if (!SUPPORTED_EVENT_FILE_CONTENT_TYPES.includes(contentType)) {
                console.warn("Unsupported content type", { fileName, contentType });
                errors.push(t('editEventPage.fileTypeError', { fileName: fileName}));
            }

            if (sizeInBytes > MAX_EVENT_FILE_SIZE_IN_BYTES) {
                console.warn("File too large", { fileName, sizeInBytes });
                errors.push(t('editEventPage.largeFileError', { fileName: fileName}));
            }

            if (newFileNames.has(fileName)) {
                console.warn("Duplicate file name", { fileName });
                errors.push(t('editEventPage.fileNameError', { fileName: fileName}));
            }

            newFileNames.add(fileName);
        }

        const fileNamesAfter =
            new Set<string>([
                ...Array.from(oldFileNames),
                ...Array.from(newFileNames)
            ]);

        if (fileNamesAfter.size > MAX_FILES_PER_EVENT) {
            errors.push(t('editEventPage.fileCountError', { maxCount: MAX_FILES_PER_EVENT}));
        }

        setFileInputErrors(errors);
    }

    function onChangeLeaderFirstName(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setLeaderFirstName(event.target.value);
    }

    function onChangeLeaderLastName(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setLeaderLastName(event.target.value);
    }

    function onChangeLeaderEmail(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setLeaderEmail(event.target.value);
    }

    function onChangeLeaderPhone(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setLeaderPhone(event.target.value);
    }

    function onChangeStartsAtDate(startsAtDate: LocalDate | DateTimeParseException | null) {
        setSavingAttempted(false);
        setStartsAtDate(startsAtDate);
        if (isRecurring && startsAtDate instanceof LocalDate) {
            // Jodatime dayOfWeek() MONDAY = 1, 
            let dayOfWeek = (startsAtDate as LocalDate).dayOfWeek().value() + 1;
            if (dayOfWeek === 8) {
                dayOfWeek = 1;
            }
            setRecurrence({
                ...(recurrence!),
                weeklyDays: dayOfWeek
            });
        }
    }

    function onChangeHours(event: ChangeEvent<MuiSelectElement>) {
        if (event.target.value === null || event.target.value === "") {
            return;
        }
        setSavingAttempted(false);
        setHours(event.target.value + "");
    }

    function onChangeMinutes(event: ChangeEvent<MuiSelectElement>) {
        if (event.target.value === null || event.target.value === "") {
            return;
        }
        setSavingAttempted(false);
        setMinutes(event.target.value + "");
    }

    function onChangeAmPm(event: ChangeEvent<MuiSelectElement>) {
        if (event.target.value === null || event.target.value === "") {
            return;
        }
        setSavingAttempted(false);
        setAmPm(event.target.value + "");
    }

    function onChangeTimeZone(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string' && Object.values(SupportedTimeZone).map(tz => tz.toString()).includes(event.target.value)) {
            setTimeZone(event.target.value);
        } else {
            setTimeZone("");
        }
    }

    function onChangeHowLongInHours(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string') {
            setHowLongInHours(event.target.value ? Number.parseFloat(event.target.value) : 0);
        }
    }

    function onChangeLocationName(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setLocationName(event.target.value);
    }

    function onChangeStreetAddressOne(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setStreetAddressOne(event.target.value);
    }

    function onChangeStreetAddressTwo(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setStreetAddressTwo(event.target.value);
    }

    function onChangeCity(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setCity(event.target.value);
    }

    function onChangeCountrySubdivision(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string' && states.some(state => state.id === event.target.value)) {
            setCountrySubdivision(event.target.value);
        } else {
            setCountrySubdivision("");
        }
    }

    function onChangePostalCode(event: ChangeEvent<HTMLInputElement>) {
        setPostalCode(event.target.value);
    }

    function onChangeEventType(event: ChangeEvent<HTMLInputElement>) {
        setEventType(event.target.value.toString());
    }

    function onChangeIsPaid(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setIsPaid(event.target.checked);
    }

    function onChangeIsVirtual(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        const newIsVirtual = event.target.checked;
        setIsVirtual(newIsVirtual);
        if (!newIsVirtual) {
            setVirtualEventDetails(null);
        } else if (newIsVirtual && !isVirtual) {
            setVirtualEventDetails({
                dialInNumber: "",
                url: "",
                medium: VirtualEventMedium.NotSpecified,
                dialInPasscode: "",
                urlPasscode: "",
                meetingId: undefined,
            });
        }
    }

    function onChangeVirtualEventUrl(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setVirtualEventDetails({
            ...(virtualEventDetails!),
            url: event.target.value
        })
    }

    function onChangeVirtualEventUrlPasscode(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setVirtualEventDetails({
            ...(virtualEventDetails!),
            urlPasscode: event.target.value
        })
    }

    function onChangeVirtualEventMedium(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        // dialInNumber, url and dialInPasscode are all ignored for Internal Zoom Meetings
        if (isCreatingNewEvent && event.target.value === VirtualEventMedium.ZoomInternalHosted) {
            setVirtualEventDetails({
                ...(virtualEventDetails!),
                dialInNumber: "",
                url: "",
                medium: event.target.value as VirtualEventMedium,
                dialInPasscode: "",
                meetingId: undefined,
            })
        } else {
            setVirtualEventDetails({
                ...(virtualEventDetails!),
                medium: event.target.value as VirtualEventMedium
            })
        }
    }

    function onChangeVirtualEventDialInPasscode(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setVirtualEventDetails({
            ...(virtualEventDetails!),
            dialInPasscode: event.target.value
        })
    }

    function onChangeVirtualEventDialInNumber(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        setVirtualEventDetails({
            ...(virtualEventDetails!),
            dialInNumber: event.target.value
        })
    }

    function onChangeIsRecurring(event: ChangeEvent<HTMLInputElement>) {
        const newIsRecurring = event.target.checked;
        setIsRecurring(newIsRecurring);
        if (!newIsRecurring) {
           setRecurrence(null);
        } else if (newIsRecurring && !isRecurring) {
            let weeklyDayValue = undefined;
            if (startsAtDate instanceof LocalDate) {
                // Jodatime dayOfWeek() MONDAY = 1, 
                let dayOfWeek = (startsAtDate as LocalDate).dayOfWeek().value() + 1;
                if (dayOfWeek === 8) {
                    dayOfWeek = 1;
                }
                weeklyDayValue = dayOfWeek;
            }
            setRecurrence({
                endDateTime: undefined,
                endTimes: undefined,
                weeklyDays: weeklyDayValue,
                repeatInterval: 1,
                recurrenceType: 2
            });
        }
    }

    function onChangeRepeatInterval(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string') {
            setRecurrence({
                ...(recurrence!),
                repeatInterval: Number.parseInt(event.target.value)
            });
        }
    }

    function onChangeWeeklyDay(weeklyDay : string | WeeklyDay) {
        if (typeof weeklyDay === 'number') {
            setRecurrence({
                ...(recurrence!),
                weeklyDays: weeklyDay
            })
        }
    }

    function onChangeRecurrenceEndsRadio(event: ChangeEvent<HTMLInputElement>) {
        setRecurrenceEndsType(event.target.value);
        console.log(typeof recurrenceEndsAtDate);
        if (event.target.value === "ON" && recurrenceEndsAtDate && recurrenceEndsAtDate instanceof LocalDate) {
            setRecurrence({
                ...(recurrence!),
                endDateTime: recurrenceEndsAtDate as LocalDate,
                endTimes: undefined
            });
        } 
        else if (event.target.value === "AFTER") {
            setRecurrence({
                ...(recurrence!),
                endDateTime: undefined,
                endTimes: recurrenceEndsAfterCount
            });
        }
    }

    function onChangeRecurrenceEndsAtDate(endsAtDate: LocalDate | DateTimeParseException | null) {
        setSavingAttempted(false);
        setRecurrenceEndsAtDate(endsAtDate);
        if(recurrenceEndsType === "ON" && endsAtDate && endsAtDate instanceof LocalDate) {
            setRecurrence({
                ...(recurrence!),
                endDateTime: endsAtDate as LocalDate,
                endTimes: undefined,
            })
        }
    }

    function onChangeRecurrenceEndsAfterCount(event: ChangeEvent<MuiSelectElement>) {
        setSavingAttempted(false);
        if (typeof event.target.value === 'string') {
            setRecurrenceEndsAfterCount(Number.parseInt(event.target.value));
            if(recurrenceEndsType === "AFTER") {
                setRecurrence({
                    ...(recurrence!),
                    endDateTime: undefined,
                    endTimes: Number.parseInt(event.target.value)
                });
            }
        }
    }

    function  onChangeOccurrenceIdsToCancel(event: ChangeEvent<HTMLInputElement>) {
        setSavingAttempted(false);
        if(event.target.checked) {
            setOccurrenceIdsToCancel([...occurrenceIdsToCancel, event.target.value]);
        } else {
            setOccurrenceIdsToCancel(occurrenceIdsToCancel.filter((x : string) => x !== event.target.value));
        }
    }

    function onChangeSelectedClubLeader(event: ChangeEvent<MuiSelectElement>) {
        const newIndex = Number(event.target.value);
        setSelectedClubLeaderIndex(newIndex);

        setLeaderEmail(clubLeaders[newIndex].email);
        setLeaderFirstName(clubLeaders[newIndex].firstName);
        setLeaderLastName(clubLeaders[newIndex].lastName);
        setLeaderPhone(clubLeaders[newIndex].phone);
    }

    function onCancelClick() {
        setSavingAttempted(false);
        setCancelled(true);
    }

    function initialEventTypeIsDeprecated() {
        return initialEventType === EventType.EDUCATIONAL.toString() 
            || initialEventType === EventType.GROUP_ACTIVITY.toString() 
            || initialEventType === EventType.SELF_GUIDED.toString();
    }

    function shouldShowEventType(eventType : EventType) : boolean {
        if(isCreatingNewEvent && isDeprecatedEventType(eventType)) {
            return false;
        }
        if(!isCreatingNewEvent && isDeprecatedEventType(eventType) && !initialEventTypeIsDeprecated()) {
            return false;
        }
        return true;
    }

    async function onSubmitEditEventForm(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setSaving(true);

        // Clear Address info if virtual event
        if (isVirtual) {
            setLocationName("");
            setStreetAddressOne("");
            setStreetAddressTwo("");
            setCity("");
            setCountrySubdivision("");
            setPostalCode("");
        }

        if (startsAtDate instanceof DateTimeParseException) {
            setSaving(false);
            setSavingAttempted(true);
            setSavingMessage(t('editEventPage.invalidDateError'));
            setSavingSuccess(false);
            return;
        }

        if (fileInputErrors.length > 0) {
            setSaving(false);
            setSavingAttempted(true);
            setSavingMessage(t('editEventPage.fileUploadError', { files: fileInputErrors.join(" ")}));
            setSavingSuccess(false);
            return;
        }

        const data: CreateOrUpdateEventRequestBody = {
            eventName,
            description,
            importantDetails,
            leaderFirstName,
            leaderLastName,
            leaderEmail,
            leaderPhone,
            startsAtDate: startsAtDate!,
            startsAtTime,
            timeZone,
            howLongInHours,
            locationName,
            streetAddressOne,
            streetAddressTwo,
            city,
            countrySubdivision,
            postalCode,
            typeOne: eventType,
            typeTwo: null,
            isPaid,
            isVirtual,
            virtualEventDetails,
            isRecurring,
            recurrence,
            occurrenceIdsToCancel,
        }

        let urlFragmentForRedirect: string | null;
        let responseBody: CreateEventResponseBody;
        try {
            if (isCreatingNewEvent) {
                responseBody = await createEvent({
                    authenticatedFetch,
                    clubUrlFragment: eventOrClubUrlFragment,
                    data
                });
                urlFragmentForRedirect = responseBody.eventUrlFragment;
                setSavingMessage(null);

                // Clear the values so they don't show up if the user creates another new event.
                // TODO: Is there a good way to reuse the default values between here and the `useState(...)` calls?
                setEventName("");
                setDescription("");
                setImportantDetails("");
                setLeaderFirstName("");
                setLeaderLastName("");
                setLeaderEmail("");
                setLeaderPhone("");
                setStartsAtDate(null);
                setStartsAtTime("");
                setTimeZone("");
                setHowLongInHours(0);
                setLocationName("");
                setStreetAddressOne("");
                setStreetAddressTwo("");
                setCity("");
                setCountrySubdivision("");
                setPostalCode("");
                setEventType("");
                setIsPaid(false);
                setIsVirtual(false);
                setIsRecurring(false);
                setRecurrence(null);
                setIsCancelled(false);
            } else {
                await updateEvent({
                    authenticatedFetch,
                    eventUrlFragment: eventOrClubUrlFragment,
                    data
                });
                setSavingMessage(t('editEventPage.saveSuccess'));
                urlFragmentForRedirect = eventOrClubUrlFragment;
            }
            setSavingSuccess(true);
        } catch (e) {
            // TODO: Look for server-side validation errors?
            // In general, there should be none, because client-side validation should catch everything.
            if ((e as RequestError).notLoggedIn) {
                setSavingMessage(t('editEventPage.loginRequired'));
                setSavingSuccess(false);
                return;
            } else if (e instanceof RequestError && e.data?.includes("System.Net.Http.HttpRequestException") && e.data?.includes("User does not exist")) {
                setSavingMessage(t('editEventPage.zoomAccountError', {leaderEmail, phoneNumber: supportPhoneNumber.display}));
                setSavingSuccess(false);
                return;
            } else if (e instanceof RequestError && e.data?.includes("System.Net.Http.HttpRequestException") && e.data?.includes("not a licensed user")) {
                setSavingMessage(t('editEventPage.zoomLicensedAccountError', {leaderEmail, phoneNumber: supportPhoneNumber.display}));
                setSavingSuccess(false);
                return;
            } else {
                setSavingMessage(t('editEventPage.saveError'));
                setSavingSuccess(false);
                return;
            }
        } finally {
            setSaving(false);
            setSavingAttempted(true);
        }

        const fileInputElement = fileInputRef.current;
        if (fileInputElement !== undefined) {
            const fileList = fileInputElement.files;
            if (fileList !== null && fileList.length > 0) {
                setSaving(true);
                try {
                    await uploadEventFiles({
                        authenticatedFetch,
                        eventUrlFragment: urlFragmentForRedirect,
                        files: fileList
                    });
                    setSavingSuccess(true);
                    setSavingMessage(t('editEventPage.saveSuccess'));
                } catch (e) {
                    if ((e as RequestError).notLoggedIn) {
                        setSavingMessage("Login required");
                        setSavingSuccess(false);
                        return;
                    } else {
                        setSavingMessage(t('editEventPage.uploadErrorWithSave'));
                        setSavingSuccess(false);
                        return;
                    }
                } finally {
                    setSaving(false);
                    setSavingAttempted(true);
                }
                fileInputElement.value = "";
            }
        }

        if (urlFragmentForRedirect !== null) {
            history.push(`/event-details/${urlFragmentForRedirect}`);
        } else {
            history.push(`/my-club/${eventOrClubUrlFragment}`);
        }
    }


    const virtualEventMediums = Object.entries(VirtualEventMedium)
        // TypeScript is meh sometimes. It will include value mappings with
        // keys like "1", "2", etc. by default, whereas we only want the enum
        // names.
        .filter(([key]) => isNaN(parseInt(key)) && (useEdp44VirtualClubs || key !== VirtualEventMedium[VirtualEventMedium.ZoomInternalHosted].toString()) );

    return (
        <div className="EditEventPage">
            {cancelled && <Redirect to={`/my-club/${myClubUrlFragment}`} push/>}
            <BackLink to={`/my-club/${myClubUrlFragment}`}>{t('editEventPage.backToClub')}</BackLink>

            {isCreatingNewEvent && <h1>{t('editEventPage.addEvent')}</h1>}
            {!isCreatingNewEvent && <h1>{t('editEventPage.editEvent')}</h1>}
            {loading && (
                <Loading className="EditEventPage_Loading" loading={loading}/>
            )}
            {!loading && loadingMessage !== null && (
                <div className="EditEventPage_LoadingMessage">
                    {loadingMessage}
                </div>
            )}
            {!loading && loadingMessage === null && (
                <form className={classes.root} onSubmit={onSubmitEditEventForm}>
                    {isCancelled && (
                        <div className="EditEventPage_Cancelled">
                            {t('editEventPage.eventCancelled')}
                        </div>
                    )}
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}
                        component="div">
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.eventName')}</h3></FormLabel>
                        </div>
                        <FieldWithBottomText
                            instructionText={`${eventName.length}/60 ` + t('editEventPage.characterCount')}
                            className={'EditEventPage_Field'}>
                            <TextField value={eventName}
                                       variant="outlined"
                                       autoFocus={true}
                                       className={`${classes.root}`}
                                       onChange={onChangeEventName} required
                                       inputProps={{maxLength: 60, minLength: 4}}/>
                        </FieldWithBottomText>
                        <div className=" EditEventPage_FormhelperText">
                            <FormHelperText
                                className={`${classes.root} ${classes.formHelperText} EditEventPage_FormhelperText`}>
                                {t('editEventPage.eventNameHelperText')}</FormHelperText>
                        </div>
                    </FormControl>
                    <hr />
                    <FormControl
                        className={`${classes.root} ${classes.formControl}  EditEventPage_FormControl`}
                        component="div"
                    >
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.eventType')}</h3>
                            </FormLabel>
                        </div>
                        <div className="EditEventPage_Field">
                            <RadioGroup name="EventType_RadioGroup" value={eventType} onChange={onChangeEventType}> 
                                {
                                    Object.values(EventType).map(e => (
                                        shouldShowEventType(e) &&
                                        <FormControlLabel
                                            key={e}
                                            value={e}
                                            checked={e.toString() === eventType}
                                            control={<Radio required/>}
                                            disabled={isDeprecatedEventType(e)}
                                            label={labelForEventType(t, e)}
                                        />
                                        
                                    ))
                                }

                            </RadioGroup>
                        </div>
                        <div className=" EditEventPage_FormhelperText">
                            <FormHelperText
                                className={`${classes.root} ${classes.formHelperText}`}>
                                {t('editEventPage.eventTypeHelperText')}
                            </FormHelperText>
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.paidEventToggle')}</h3></FormLabel>
                        </div>
                        <div className="EditEventPage_Field">
                            <Switch checked={isPaid} onChange={onChangeIsPaid}/>
                        </div>
                        <div className="EditEventPage_FormhelperText">
                            <FormHelperText className={classes.formHelperText}>
                                {t('editEventPage.paidEventHelperText')}
                            </FormHelperText>
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.virtualEventToggle')}</h3>
                            </FormLabel>
                        </div>
                        <div className="EditEventPage_Field">
                            <Switch checked={isVirtual} onChange={onChangeIsVirtual}/>
                        </div>
                        <div className="EditEventPage_FormhelperText">
                            <FormHelperText className={classes.formHelperText}>
                                {t('editEventPage.virtualEventHelperText')}
                            </FormHelperText>
                        </div>
                    </FormControl>
                    {virtualEventDetails && <>
                        <FormControl
                            className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                            <div className="EditEventPage_Label">
                                <FormLabel component="legend"
                                           className={`${classes.formLabel}`}>
                                    <h3>{t('editEventPage.virtualEventDetails')}</h3>
                                </FormLabel>
                            </div>
                            <div className="EditEventPage_Field EditEventPage_VirtualEventDetails">
                                <FormControl
                                    className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                    <TextField type="text" value={virtualEventDetails.medium}
                                               label={t('editEventPage.virtualEventMediumLabel')}
                                               variant="outlined"
                                               className={`${classes.root}`}
                                               select={true}
                                               onChange={onChangeVirtualEventMedium}>
                                        {virtualEventMediums.map(([key, value]) => (
                                            <MenuItem key={key}
                                                      value={value}>
                                                {t(`editEventPage.virtualEventMedium.${key}`)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <FormHelperText>{virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted ? t('editEventPage.virtualEventMediumZoomInternalHostedInstructions') : ''}</FormHelperText>
                                </FormControl>
                                {
                                   virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted && !isCreatingNewEvent &&
                                   <FormControl
                                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                        <TextField type="text" 
                                                value={virtualEventDetails.meetingId}
                                                label={t('editEventPage.virtualEventMeetingId')}
                                                variant="outlined"
                                                className={`${classes.root}`}
                                                disabled={true}
                                                />
                                    </FormControl>
                                }
                                {
                                    (virtualEventDetails.medium !== VirtualEventMedium.ZoomInternalHosted || !isCreatingNewEvent) &&
                                    <FormControl
                                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                        <TextField type="text" value={virtualEventDetails.url}
                                                label={t('editEventPage.virtualEventUrl')}
                                                variant="outlined"
                                                className={`${classes.root}`}
                                                disabled={virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                                onChange={onChangeVirtualEventUrl}/>
                                    </FormControl>
                                    
                                }
                                <FormControl
                                    className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                    <FieldWithBottomText
                                        instructionText={`${virtualEventDetails.urlPasscode.length}/10 ` + t('editEventPage.characterCount')}
                                        className={'EditEventPage_FormControl EditEventPage_Field'}>
                                        <TextField value={virtualEventDetails.urlPasscode}
                                                label={t('editEventPage.virtualEventUrlPasscode')}
                                                variant="outlined"
                                                className={`${classes.root}`}
                                                onChange={onChangeVirtualEventUrlPasscode}
                                                inputProps={{maxLength: 10}}/>
                                    </FieldWithBottomText>
                                </FormControl>
                                {
                                    (virtualEventDetails.medium !== VirtualEventMedium.ZoomInternalHosted || !isCreatingNewEvent) &&
                                    <FormControl
                                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                        <TextField type="text" value={virtualEventDetails.dialInNumber}
                                                label={t('editEventPage.virtualEventDialInNumber')}
                                                variant="outlined"
                                                className={`${classes.root}`}
                                                disabled={virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                                onChange={onChangeVirtualEventDialInNumber}/>
                                    </FormControl>
                                    
                                }
                                {
                                    (virtualEventDetails.medium !== VirtualEventMedium.ZoomInternalHosted || !isCreatingNewEvent) &&
                                    <FormControl
                                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                        <TextField type="text" value={virtualEventDetails.dialInPasscode}
                                                label={t('editEventPage.virtualEventDialInPasscode')}
                                                variant="outlined"
                                                className={`${classes.root}`}
                                                disabled={virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                                onChange={onChangeVirtualEventDialInPasscode}/>
                                    </FormControl>
                                }
                            </div>
                        </FormControl>
                    </>}
                    <hr/>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.dateTime')}</h3>
                            </FormLabel>
                        </div>
                        <div className="EditEventPage_Field EditEventPage_DateAndTime">
                            <div className="EditEventPage_FlexRow">
                                <div className="EditEventPage_DateField">
                                    <MuiPickersUtilsProvider utils={LocalDateUtils}
                                                             locale={Locale.US}>
                                        <KeyboardDatePicker
                                            value={startsAtDate}
                                            required
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            onChange={onChangeStartsAtDate}/>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="EditEventPage_TimeField">
                                    <FormControl>
                                        <div className="EditEventPage_TimeField_Hours">
                                            <Select required
                                                    value={hours}
                                                    fullWidth={true}
                                                    onChange={onChangeHours}
                                                    variant="outlined">
                                                {GetHours().map(hour => (
                                                    <MenuItem key={hour}
                                                              value={hour}>
                                                        {hour}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </FormControl>
                                    <FormControl>
                                        <div className="EditEventPage_TimeField_Minutes">
                                            <Select required
                                                    value={minutes}
                                                    fullWidth={true}
                                                    onChange={onChangeMinutes}
                                                    variant="outlined">
                                                {GetMinutes().map(minute => (
                                                    <MenuItem key={minute}
                                                              value={minute}>
                                                        {minute}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </FormControl>
                                    <FormControl>
                                        <div className="EditEventPage_TimeField_AmPm">
                                            <Select required
                                                    fullWidth={true}
                                                    value={ampm}
                                                    onChange={onChangeAmPm}
                                                    variant="outlined">
                                                {GetAmPm().map(ampmOption => (
                                                    <MenuItem key={ampmOption}
                                                              value={ampmOption}>
                                                        {ampmOption}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="EditEventPage_FlexRow">
                                <div className="EditEventPage_TimeZoneLabel">
                                    <FormLabel component="legend"
                                               className={`${classes.formLabel}`}>
                                        {t('editEventPage.timeZone')}
                                    </FormLabel>
                                </div>
                                <div className="EditEventPage_TimeZoneField">
                                    <Select required
                                            value={timeZone}
                                            onChange={onChangeTimeZone}
                                            variant="outlined">
                                        {Object.values(SupportedTimeZone).map(supportedTimeZone => (
                                            <MenuItem key={supportedTimeZone}
                                                      value={supportedTimeZone}>
                                                {labelForTimeZone(t, supportedTimeZone)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div> 
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.duration')}</h3>
                            </FormLabel>
                        </div>
                        <div className="EditEventPage_Field">
                            <div className="EditEventPage_FlexRow">
                                <div className="EditEventPage_FlexData">

                                    <Select required
                                            value={howLongInHours}
                                            onChange={onChangeHowLongInHours}
                                            variant="outlined">
                                        {GetDurations().map(duration => (
                                            <MenuItem key={duration}
                                                      value={duration}>
                                                {duration}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="EditEventPage_FormhelperText">
                            <FormHelperText className={classes.formHelperText}>
                                {t('editEventPage.durationHelperText')}
                            </FormHelperText>
                        </div>
                    </FormControl>
                    <hr/>
                    {
                        !!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted &&
                        <>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                <div className="EditEventPage_Label">
                                    <FormLabel component="legend"
                                            className={`${classes.formLabel}`}>
                                        <h3>{t('editEventPage.recurrence.recurrenceLabel')}</h3>
                                    </FormLabel>
                                </div>
                                <div className="EditEventPage_Field">
                                    <div className="EditEventPage_FlexRow">
                                        <Switch checked={isRecurring} onChange={onChangeIsRecurring}/>
                                    </div>
                                </div>
                                <div className="EditEventPage_FormhelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        {t('editEventPage.recurrence.recurrenceHelperText')}
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <FormControl className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                <div className="EditEventPage_Label">
                                    <FormLabel component="legend"
                                            className={`${classes.formLabel}`}>
                                    </FormLabel>
                                </div>
                                <div className="EditEventPage_Field">
                                {
                                    isRecurring && recurrence &&
                                    <>
                                        <div className="EditEventPage_FlexRow">
                                            <div className="EditEventPage_RightMarginLabel">
                                                <FormLabel component="legend"
                                                        className={`${classes.formLabel}`}>
                                                    {t('editEventPage.recurrence.repeatEvery')}
                                                </FormLabel>
                                            </div>
                                            <Select required
                                                    value={recurrence.repeatInterval}
                                                    onChange={onChangeRepeatInterval}
                                                    variant="outlined">
                                                {
                                                GetListOfStringNumbers(10).map(x => (
                                                    <MenuItem key={x}
                                                            value={x}>
                                                        {x}
                                                    </MenuItem>
                                                ))
                                            }
                                            </Select>
                                            <div className="EditEventPage_LeftMarginLabel">
                                                <FormLabel component="legend"
                                                        className={`${classes.formLabel}`}>
                                                    {recurrence.repeatInterval === 1 ? t('editEventPage.recurrence.week') : t('editEventPage.recurrence.weeks')}
                                                </FormLabel>
                                            </div>
                                        </div>

                                        <div className="EditEventPage_FlexRow">
                                            {t('editEventPage.recurrence.repeatOn')}
                                        </div>

                                        <div className="EditEventPage_FlexRow">
                                            {Object.values(WeeklyDay).filter(w => !isNaN(Number(w))).map(w => (
                                                <Chip className="EditEventPage_WeekDay"
                                                        clickable
                                                        color={w === recurrence.weeklyDays ? "primary" : "default"}
                                                        label={labelForWeeklyDay(w)}
                                                        onClick={() => onChangeWeeklyDay(w)}
                                                />
                                            ))}
                                        </div>

                                        <div>
                                            {t('editEventPage.recurrence.ends')}
                                        </div>
                                        <RadioGroup name="Recurrence_RadioGroup" value={recurrenceEndsType} onChange={onChangeRecurrenceEndsRadio}> 
                                            <FormControlLabel
                                                value="ON"
                                                control={<Radio required/>}
                                                label={
                                                    <div className="EditEventPage_RadioNoWrapFlexRow">
                                                        <div className="EditEventPage_RightMarginLabel">
                                                            <FormLabel component="legend"
                                                                    className={`${classes.formLabel}`}>
                                                                {t('editEventPage.recurrence.on')}
                                                            </FormLabel>
                                                        </div>
                                                        <div className="EditEventPage_DateField">
                                                            <MuiPickersUtilsProvider utils={LocalDateUtils}
                                                                                    locale={Locale.US}>
                                                                <KeyboardDatePicker
                                                                    value={recurrenceEndsAtDate}
                                                                    required={recurrenceEndsType === "ON"}
                                                                    inputVariant="outlined"
                                                                    format="MM/dd/yyyy"
                                                                    onChange={onChangeRecurrenceEndsAtDate}/>
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                            <FormControlLabel
                                                value="AFTER"
                                                control={<Radio required/>}
                                                label={
                                                    <div className="EditEventPage_RadioFlexRow">
                                                        <div className="EditEventPage_RightMarginLabel">
                                                            <FormLabel component="legend"
                                                                    className={`${classes.formLabel}`}>
                                                                {t('editEventPage.recurrence.after')}
                                                            </FormLabel>
                                                        </div>

                                                        <Select required
                                                                value={recurrenceEndsAfterCount}
                                                                onChange={onChangeRecurrenceEndsAfterCount}
                                                                variant="outlined">
                                                            {
                                                            GetListOfStringNumbers(60).map(x => (
                                                                <MenuItem key={x}
                                                                        value={x}>
                                                                    {x}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                        </Select>

                                                        <div className="EditEventPage_LeftMarginLabel">
                                                            <FormLabel component="legend"
                                                                    className={`${classes.formLabel}`}>
                                                                { recurrenceEndsAfterCount === 1 ? t('editEventPage.recurrence.occurrence') : t('editEventPage.recurrence.occurrences')}
                                                            </FormLabel>
                                                        </div>
                                                        
                                                    </div>
                                                }
                                            />
                                        </RadioGroup>
                                    </>
                                }
                                </div>            
                            </FormControl>
                            <hr/>
                        </>
                    }
                    {
                        !isCreatingNewEvent && !!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted && !!recurrence &&
                        <>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                                <div className="EditEventPage_Label">
                                    <FormLabel component="legend"
                                            className={`${classes.formLabel}`}>
                                        <h3>{t('editEventPage.occurrence.occurrenceLabel')}</h3>
                                    </FormLabel>
                                </div>
                                <div className="EditEventPage_Field">
                                    <div className="EditEventPage_FlexRow">
                                        <List className={muiClasses.listRoot}>
                                            {
                                                occurrences.filter((x: EventOccurrence) => x.status !== 'deleted').map((occurrence : EventOccurrence) => {
                                                    return (
                                                        <ListItem key={occurrence.occurrenceId}>
                                                            <Checkbox value={occurrence.occurrenceId} 
                                                                      checked={occurrenceIdsToCancel.includes(occurrence.occurrenceId)}
                                                                      disabled={disableCancellingOccurrences}
                                                                      onChange={onChangeOccurrenceIdsToCancel} />
                                                            <ListItemText id={occurrence.occurrenceId} 
                                                                          primary={formatOccurrenceTime(occurrence.startTime)}/>
                                                        </ListItem>
                                                    );
                                                })
                                            }
                                        </List>
                                        
                                    </div>
                                </div>
                                <div className="EditEventPage_FormhelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        {t('editEventPage.occurrence.occurrenceHelperText')}
                                        <div>
                                            {disableCancellingOccurrences ? t('editEventPage.occurrence.occurrenceConflict') : ''}
                                        </div>
                                        
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <hr/>
                        </>
                    }
                    {
                        !isVirtual && 
                        <>
                            <FormControl
                                className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}
                                component="div">
                                <div className="EditEventPage_Label">
                                    <FormLabel component="legend"
                                            className={`${classes.formLabel}`}>
                                        <h3>{t('editEventPage.address')}</h3>
                                    </FormLabel>
                                </div>
                                <div className="EditEventPage_Field EditEventPage_Address">
                                    <div className="EditEventPage_FlexColumn">
                                        <FieldWithBottomText
                                            instructionText={`${locationName.length}/50 ` + t('editEventPage.characterCount')}
                                            className={'EditEventPage_FlexData EditEventPage_LocationName'}>
                                            <TextField type="text" value={locationName}
                                                    label={t('editEventPage.areaLabel')}
                                                    variant="outlined"
                                                    className={`${classes.root}`}
                                                    fullWidth={true}
                                                    required={!isVirtual}
                                                    onChange={onChangeLocationName}
                                                    inputProps={{maxLength: 50}}/>
                                        </FieldWithBottomText>
                                        <FieldWithBottomText
                                            instructionText={`${streetAddressOne.length}/50 ` + t('editEventPage.characterCount')}
                                            className="EditEventPage_FlexData">
                                            <TextField type="text" value={streetAddressOne}
                                                    label={t('editEventPage.streetAddress1Label')}
                                                    variant="outlined"
                                                    className={`${classes.root}`}
                                                    fullWidth={true}
                                                    required={!isVirtual}
                                                    onChange={onChangeStreetAddressOne}
                                                    inputProps={{maxLength: 50}}/>
                                        </FieldWithBottomText>
                                        <FieldWithBottomText
                                            instructionText={`${streetAddressTwo.length}/50 ` + t('editEventPage.characterCount')}
                                            className="EditEventPage_FlexData">
                                            <TextField type="text" value={streetAddressTwo}
                                                    label={t('editEventPage.streetAddress2Label')}
                                                    variant="outlined"
                                                    className={`${classes.root}`}
                                                    fullWidth={true}
                                                    onChange={onChangeStreetAddressTwo}
                                                    inputProps={{maxLength: 50}}/>
                                        </FieldWithBottomText>
                                        <FieldWithBottomText
                                            instructionText={`${city.length}/50 ` + t('editEventPage.characterCount')}
                                            className="EditEventPage_FlexData">
                                            <TextField type="text" value={city}
                                                    label={t('editEventPage.cityLabel')}
                                                    variant="outlined"
                                                    required={!isVirtual}
                                                    className={`${classes.root}`}
                                                    fullWidth={true}
                                                    onChange={onChangeCity}
                                                    inputProps={{maxLength: 50}}/>
                                        </FieldWithBottomText>
                                    </div>
                                    <div className="EditEventPage_FlexRow">
                                        <div className="EditEventPage_FlexData">
                                            <Select value={countrySubdivision}
                                                    onChange={onChangeCountrySubdivision}
                                                    variant="outlined"
                                                    required={!isVirtual}>
                                                {states.map(state => (
                                                    <MenuItem key={state.id}
                                                            value={state.id}>
                                                        {state.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <FieldWithBottomText
                                            instructionText={`${postalCode.length}/5 ` + t('editEventPage.characterCount')}
                                            className="EditEventPage_FlexData EditEventPostalCode">
                                            <TextField type="text" value={postalCode}
                                                    label={t('editEventPage.zipcodeLabel')}
                                                    variant="outlined"
                                                    className={`${classes.root}`}
                                                    fullWidth={true}
                                                    required={!isVirtual}
                                                    inputProps={{maxLength: 5, minLength: 5, pattern: "[0-9]*"}}
                                                    onChange={onChangePostalCode}/>
                                        </FieldWithBottomText>
                                    </div>
                                </div>
                                <div className="EditEventPage_FormhelperText">
                                    <FormHelperText className={classes.formHelperText}>
                                        {t('editEventPage.addressHelperText')}
                                    </FormHelperText>
                                </div>
                            </FormControl>
                            <hr/>
                        </>
                    }
                    
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}
                        component="div">
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.description')}</h3></FormLabel>
                        </div>
                        <FieldWithBottomText
                            instructionText={`${description.length}/1000 ` + t('editEventPage.characterCount')}
                            className={'EditEventPage_Field'}>
                            <TextField multiline rows={4}
                                       variant="outlined"
                                       className={`${classes.root}`}
                                       required
                                       value={description}
                                       placeholder={t('editEventPage.descriptionPlaceholder')}
                                       onChange={onChangeDescription}
                                       inputProps={{maxLength: 1000}}/>
                        </FieldWithBottomText>
                        <div className=" EditEventPage_FormhelperText">
                            <FormHelperText
                                className={`${classes.root} ${classes.formHelperText} EditEventPage_FormhelperText`}>
                            </FormHelperText>
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}
                        component="div">
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.additionalInfo')}</h3>
                            </FormLabel>
                        </div>
                        <FieldWithBottomText
                            instructionText={`${importantDetails.length}/1000 ` + t('editEventPage.characterCount')}
                            className={'EditEventPage_Field'}>
                            <TextField multiline rows={4} value={importantDetails}
                                       variant="outlined"
                                       inputProps={{maxLength: 1000}}
                                       className={`${classes.root}`}
                                       onChange={onChangeImportantDetails}/>
                        </FieldWithBottomText>
                        <div className=" EditEventPage_FormhelperText">
                            <FormHelperText
                                className={`${classes.root} ${classes.formHelperText} EditEventPage_FormhelperText`}>
                                {t('editEventPage.additionalInfoHelperText')}
                            </FormHelperText>
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl
                        className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}
                        component="div">
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.uploadFiles')}</h3>
                            </FormLabel>
                        </div>
                        {/* TODO: When editing an event, display the existing files. */}
                        <FieldWithBottomText
                            instructionText={t('editEventPage.uploadFilesInstructionText')}
                            className={'EditEventPage_Field EditEventPage_FileInputField'}>
                            <div className="EditEventPage_FileInputField_TopText">
                                {t('editEventPage.uploadFilesTopText')}
                            </div>
                            <Input
                                type="file"
                                error={fileInputErrors.length > 0}
                                className="EditEventPage_FileInputField_InputElement"
                                disableUnderline={fileInputErrors.length === 0}
                                inputProps={{
                                    ref: (element: HTMLInputElement) => fileInputRef.current = element,
                                    multiple: true,
                                    accept: SUPPORTED_EVENT_FILE_CONTENT_TYPES.join(",")
                                }}
                                onChange={onChangeFilesToUpload}
                            />
                            {fileInputErrors.length > 0 &&
                            <div className="EditEventPage_FileInputField_Errors">
                                {fileInputErrors.map((error: any) =>
                                    <div className="EditEventPage_FileInputField_Error" key={error}>
                                        {error}
                                    </div>
                                )}
                            </div>
                            }
                        </FieldWithBottomText>
                        <div className=" EditEventPage_FormhelperText">
                            <FormHelperText
                                className={`${classes.root} ${classes.formHelperText} EditEventPage_FormhelperText`}>
                                {t('editEventPage.uploadFilesHelperText')}
                            </FormHelperText>
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl component="div"
                                 className={`${classes.root} ${classes.formControl} EditEventPage_FormControl`}>
                        <div className="EditEventPage_Label">
                            <FormLabel component="legend"
                                       className={`${classes.formLabel}`}>
                                <h3>{t('editEventPage.eventLeader')}</h3>
                            </FormLabel>
                        </div>
                        <div className="EditEventPage_Field EditEventPage_EventLeader">
                            <div>
                                <Select value={selectedClubLeaderIndex} 
                                        fullWidth={true} 
                                        variant="outlined"
                                        onChange={onChangeSelectedClubLeader}
                                        >
                                            {
                                                clubLeaders.map((clubLeader: GetClubLeaderResponseBody, i: number) => (
                                                    i === 0 ? 
                                                        <MenuItem key={i} value={0}><em>{t('editEventPage.otherEventLeaderLabel')}</em></MenuItem> :
                                                        <MenuItem key={i} value={i}>{clubLeader.firstName + ' ' + clubLeader.lastName}</MenuItem>
                                                ))
                                            }
                                </Select>
                                <FormHelperText>{t('editEventPage.eventLeaderInstructionText')}</FormHelperText>
                            </div>
                            <div
                                className="EditEventPage_FlexRow EditEventPage_EventLeaderName">
                                <div
                                    className="EditEventPage_FlexData EditEventPage_EventLeaderFirstName">
                                    <TextField value={leaderFirstName}
                                               variant="outlined"
                                               className={`${classes.root}`}
                                               label={t('editEventPage.firstNameLabel')}
                                               required
                                               disabled={!!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                               onChange={onChangeLeaderFirstName}/>
                                </div>
                                <div className="EditEventPage_FlexData">
                                    <TextField value={leaderLastName} label={t('editEventPage.lastNameLabel')}
                                               variant="outlined"
                                               className={`${classes.root}`}
                                               required
                                               disabled={!!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                               onChange={onChangeLeaderLastName}/>
                                </div>
                            </div>
                            <div className="EditEventPage_FlexColumn">
                                <div
                                    className="EditEventPage_FlexData EditEventPage_EventLeaderEmail">
                                    <TextField type="email" value={leaderEmail}
                                               label={t('editEventPage.emailAddressLabel')}
                                               variant="outlined"
                                               className={`${classes.root}`}
                                               fullWidth={true}
                                               required
                                               inputProps={{
                                                   pattern: validEmailRegexText
                                               }}
                                               disabled={!!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                               onChange={onChangeLeaderEmail}/>
                                </div>
                                <div
                                    className="EditEventPage_FlexData EditEventPage_EventLeaderPhone">
                                    {/* TODO: InputMask is causing findDOMNode console error */}
                                    <InputMask
                                        value={leaderPhone}
                                        mask='(999) 999-9999'
                                        maskChar='*'
                                        onChange={onChangeLeaderPhone}
                                        disabled={!!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                    >
                                        {() => <TextField
                                            type="tel"
                                            fullWidth={true}
                                            variant="outlined"
                                            className={`${classes.root}`}
                                            label={t('editEventPage.phoneNumberLabel')}
                                            required
                                            disabled={!!virtualEventDetails && virtualEventDetails.medium === VirtualEventMedium.ZoomInternalHosted}
                                            inputProps={{
                                                pattern: (/^\(\d{3}\) \d{3}-\d{4}$/).source
                                            }}
                                        />}
                                    </InputMask>
                                </div>
                            </div>
                        </div>
                    </FormControl>
                    <hr/>
                    <FormControl
                        className={`${classes.formControl} EditEventPage_ButtonContainerFormControl`}
                        component="div">
                        <div className="EditEventPage_ButtonContainer">
                            <PlayButton
                                text={t('editEventPage.cancelButtonText')}
                                clickHandler={onCancelClick}
                                buttonStyle={ButtonStyles.FilledPrimary}
                                className="EditEventPage_Button"/>
                            <PlayButton type="submit"
                                        text={t('editEventPage.submitButtonText')}
                                        saving={saving}
                                        buttonStyle={ButtonStyles.FilledPrimary}
                                        className="EditEventPage_Button"/>
                        </div>
                        {savingAttempted &&
                        <SaveIndicator className="EditEventPage_SaveIndicator"
                                       message={savingMessage} success={savingSuccess}/>}
                    </FormControl>
                </form>
            )}
        </div>
    );
}
