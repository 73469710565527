import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const IMPORT_MEMBERS_STEP_ONE_URL = '/ignite/leader/import-members/step-one/'

const postImportMembersStepOne = async (body: any, clubId: number): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${IMPORT_MEMBERS_STEP_ONE_URL}${clubId}`, {
        method: "POST",
        body: body
    });

    return authenticatedFetch(request);
}

export default postImportMembersStepOne
