import React from "react";
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import { useHistory, useParams } from 'react-router-dom'
import Loading from "../../../components/ignite/layout/Loading";
import useGetPayers from "../../../hooks/useGetPayers";
import { IPayer } from '../../../types/verification'
import { getPayerAdditionalData } from '../../../util/ignite/Verification.utils'

import './MedicareAdvantagePayerSelection.scss';
import {CHECK_ELIGIBILITY_URL} from "../../../api/postCheckEligibility";

export default function MedicareAdvantagePayerSelection() {
    const { t } = useTranslation('ignite/verification');
    const { clubId } = useParams<{ clubId: string }>()

    const [payers, isLoading] = useGetPayers()
    const history = useHistory()

    const listedPayer = payers?.filter(payer => !payer.unlistedPayerPlaceholder && !payer.deprecated)
    const otherPayer = payers?.find(payer => payer.unlistedPayerPlaceholder)

    if (isLoading) return <Loading loading={isLoading} />

    return (
            <div className='payer-selection'>
                <div className="payer-selection__title">
                    {t('payerSelection.chooseYourPayer')}
                </div>
                <div
                    className="payer-selection__subtitle"
                    dangerouslySetInnerHTML={{
                        __html: t('payerSelection.payerIsNotInList', {
                            url: `${CHECK_ELIGIBILITY_URL}/${clubId}/${otherPayer?.payerSlug}/${otherPayer?.id}`,
                        }
                        )
                    }}
                />

                <div className="payer-selection-list">
                    {listedPayer?.map((payer: IPayer) => {
                        const { logoSrc } = getPayerAdditionalData(payer.payerSlug)
                        const selectionUrl = `${CHECK_ELIGIBILITY_URL}/${clubId}/${payer.payerSlug}/${payer.id}`

                        return (
                            <div key={payer.id}>
                                <div className="payer-selection-item">
                                    <div className="logo-wrapper">
                                        <div>
                                            {Boolean(logoSrc) && <img src={logoSrc} alt={`${payer.payerName} Logo`} className="logo" />}
                                        </div>
                                    </div>
                                    <div className="main-info">
                                        <div className="main-info__name">
                                            {payer.payerName}
                                        </div>
                                        <div className="main-info__description">
                                            {t('payerSelection.offers', { userSuppliedPayerName: payer.payerName })}
                                        </div>
                                    </div>
                                    <div className="button-wrapper">
                                        <Button
                                            className='button'
                                            variant="outlined"
                                            onClick={() => history.push(selectionUrl)}
                                            size='large'
                                            color='primary'
                                        >
                                            {t('payerSelection.select')}
                                            <PlayIcon className='button__icon' />
                                        </Button>
                                    </div>

                                </div>
                                <hr className="payer-selection__separator" />
                            </div>
                        )
                    })}
                </div>
            </div>
    );
}
