import React from "react";
import { Box } from '@material-ui/core'
import ClubFinderItem from "./ClubFinderItem";
import { IClub } from '../../../types/clubFinder'

import './ClubFinderList.scss'

interface IClubFinderListProps {
    clubs: Array<IClub> | undefined
}

const ClubFinderList = ({ clubs = [] }: IClubFinderListProps): JSX.Element => {
    return (
        <Box>
            {clubs.map(club => <ClubFinderItem key={club.id} club={club} />)}
        </Box>
    )
}

export default ClubFinderList
