import React from "react";
import { useTranslation } from 'react-i18next';
import {TFunction} from "i18next";

import "./VirtualEventDetails.scss";
import VirtualEventMedium from "../../util/VirtualEventMedium";

export interface VirtualEventDetailsProps {
    medium: VirtualEventMedium;
    url: string;
    urlPasscode: string;
    dialInNumber: string;
    dialInPasscode: string;
    meetingId: number | undefined;
}

export function NoVirtualEventDetails(): JSX.Element {
    const { t } = useTranslation('common');
    return (
        <div className="VirtualEventDetails">
            {t('virtualEventDetails.importantDetails')}
        </div>
    );
}

function getLinkText(t: TFunction, medium: VirtualEventMedium): string {
    switch (medium) {
        case VirtualEventMedium.NotSpecified: return t('virtualEventDetails.mediumNotSpecified');
        case VirtualEventMedium.ZoomInternalHosted: return t('virtualEventDetails.mediumZoom');
        case VirtualEventMedium.GoogleHangouts: return t('virtualEventDetails.mediumGoogleHangouts');
        case VirtualEventMedium.ZoomExternalHosted: return t('virtualEventDetails.mediumZoom');
    }
}

export default function VirtualEventDetails(props: VirtualEventDetailsProps): JSX.Element {
    const { t } = useTranslation('common');
    const { medium, url, urlPasscode, dialInNumber, dialInPasscode } = props;

    const linkText = getLinkText(t, medium);

    return (
        <div className="VirtualEventDetails">
            {url &&
            <div className="VirtualEventDetails_Line">
              <a className="VirtualEventDetails_UrlLink"
                 href={url}
                 target="_blank"
                 rel="noopener noreferrer"
              >{linkText}</a>
            </div>
            }
            {urlPasscode &&
            <div className="VirtualEventDetails_Line">
                {t('virtualEventDetails.passcode')}: {urlPasscode}
            </div>
            }
            {dialInNumber &&
            <div className="VirtualEventDetails_Line">
                {t('virtualEventDetails.dialIn')}: <a href={`tel:${dialInNumber}`}>{dialInNumber}</a>
            </div>
            }
            {dialInPasscode &&
            <div className="VirtualEventDetails_Line">
                {t('virtualEventDetails.passcode')}: {dialInPasscode}
            </div>
            }
        </div>
    );
}
