import React, { useEffect } from 'react';

interface ExternalRedirectProps {
  redirectUrl: string,
  forwardSearchParameters: boolean
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({redirectUrl, forwardSearchParameters}) => {
  useEffect(() => {
    const url = new URL(redirectUrl);
    // If the redirectUrl already has search params, start with them for building
    // up a final set of params.
    const paramsToForward = new URLSearchParams(url.search);

    if (forwardSearchParameters) {
      // Grab any params from the current page
      const currentPageParams = new URLSearchParams(new URL(window.location.href).search)
      // Combine the sets of parameters
      currentPageParams.forEach((value, key) => {
        paramsToForward.set(key, value);
      });
    }

    const searchParams = Array.from(paramsToForward).length === 0
      ? "" : `?${paramsToForward.toString()}`

    window.location.href = `${url.protocol}//${url.host}${url.pathname}${searchParams}${url.hash}`;
  }, []);

  return null;
};

export default ExternalRedirect;
