import React from "react";
import { Box } from '@material-ui/core'
import { IClub } from '../../../types/clubFinder'
import ClubItem from "./ClubItem";
import { useTranslation } from "react-i18next";
import "./ClubList.scss";
import { SanityTextObject } from "../../../sanity/SanityUtils";

interface IClubFinderListProps {
    clubs: Array<IClub> | undefined,
    onClubSelected: (selectedClub: IClub) => void,
    onAddClub: () => void,
    searchValue: string,
    ctaLabel: string | undefined,
    resultsCtaMessage: SanityTextObject[] | undefined,
    noResultsCtaMessage: SanityTextObject[] | undefined,
}

const ClubList = ({
        clubs = [], onClubSelected,  searchValue
    }: IClubFinderListProps): JSX.Element => {

    const { t } = useTranslation('directToConsumer/clubFinder');
    const isClubsFound = searchValue?.length > 0;

    return (
        <Box className="club-list">
            {
                isClubsFound && (
                    <div className="count-showing">{t("section.clubFinder.showingClubCount", {count: clubs?.length ?? 0})}</div>
                )}
                {isClubsFound && clubs.map(club => <ClubItem key={club.id} club={club} onClubSelected={onClubSelected}/>)}
        </Box>
    )
}

export default ClubList
