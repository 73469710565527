import React from "react";
import Button, {ButtonStyles} from "./Button";
import "./PlayButton.scss";
import PlayIcon from '@material-ui/icons/PlayArrow';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import "./PlayButton.scss";

interface ButtonProps {
    text: string,
    buttonStyle: ButtonStyles,
    clickHandler?: Function,
    useConfirmationModal?: boolean,
    showConfirmationModal?: boolean,
    confirmationModalBody?: any,
    confirmationModalConfirmText?: string,
    confirmationModalDenyText?: string,
    saving?: boolean,
    className?: string
    disabled?: boolean;
    to?: string,
    toState?: unknown,
    type?: 'submit' | 'reset' | 'button',
    id?: string
}

export default function PlayButton(props: ButtonProps): JSX.Element {
    return (
        <Button buttonStyle={props.buttonStyle}
                clickHandler={props.clickHandler}
                saving={props.saving}
                to={props.to}
                toState={props.toState}
                disabled={props.disabled}
                className={props.className}
                useConfirmationModal={props.useConfirmationModal}
                showConfirmationModal={props.showConfirmationModal}
                confirmationModalBody={props.confirmationModalBody}
                confirmationModalConfirmText={props.confirmationModalConfirmText}
                confirmationModalDenyText={props.confirmationModalDenyText}
                id={props.id}
        type={props.type}>
            <div className="CCPlayButton_ChildContainer">
                { props.buttonStyle === ButtonStyles.Link && <KeyboardArrowRight className={"CCPlayButton_RightKeyboardArrow"}/>}
                { props.buttonStyle !== ButtonStyles.Link && <PlayIcon className={"CCPlayButton_PlayIcon"}/> }
                <div className="CCPlayButton_TextContainer">{props.text.toLowerCase()}
                </div>
            </div>
        </Button>
    );
}
