import React from "react";

const arrowText = require("../../assets/arrow-text.svg");

interface ArrowTextProps {
  className?: string;
}

export default function ArrowText(props: ArrowTextProps): JSX.Element {
  return <img className={`ArrowText ${props.className || ''}`} src={arrowText} />
}
