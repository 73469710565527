import './NewMemberReportActivityStepV2.scss';
import React, { useEffect, useState } from 'react';
import {
    ActivityRecordParameters,
    DataGatheringAnswer,
    DataGatheringQuestion,
    InputType,
    MemberReportActivity,
    MonthObject,
    MonthObjectRecord
} from '../types';
import { useTranslation } from 'react-i18next';
import useGetCustomEnrollmentInfo from '../../../hooks/useGetCustomEnrollmentInfo';
import Loading from '../../../components/common/Loading';
import Button, { ButtonStyles } from '../../../components/common/Button';
import { memberDashboardConfig } from '../../../configs/memberDashboard';
import { applyMaxMinChecking } from '../../../util/ignite/Dashboard.utils';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { CustomEnrollmentUserActivityRecord } from '../../../api/getCustomEnrollmentInfo';
import getClubByShortCode from '../../../api/getClubByShortCode';
import { IClub } from '../../../types/clubFinder';
import { SOURCE_TYPES_BY_ACTIVITY_TYPE as ActivityType } from '../../../constants/dashboard';
import { SanityTextObject } from '../../../sanity/SanityUtils';

class ActivitiesRecordMap extends Map {
    constructor (activityRecords: CustomEnrollmentUserActivityRecord[]) {
        super();
        for (const record of activityRecords) {
            this.set(`${record.year}-${record.month}-${record.day}`, record);
        }
    }

    valueOf (year: number, month: number, day: number): CustomEnrollmentUserActivityRecord | undefined {
        return this.get(`${year}-${month}-${day}`);
    }

    static fromRecords (activityRecords: CustomEnrollmentUserActivityRecord[]): ActivitiesRecordMap {
        return new ActivitiesRecordMap(activityRecords);
    }
}

const activitiesHistoryFormLoader = (
    setMonths: (months: MonthObject[]) => void,
    setRecords: (records: Record<number, ActivityRecordParameters>) => void,
    ) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return (activityRecords: CustomEnrollmentUserActivityRecord[]): void => {
        const months: MonthObject[] = [];
        const records: Record<number, ActivityRecordParameters> = {};
        const activityRecordsMap = ActivitiesRecordMap.fromRecords(activityRecords);

        for (let i = 1; i <= 6; i++) {
            const date = new Date(currentYear, currentMonth - i, 1);
            const month = date.getMonth() + 1;

            const foundMonthlyActivityRecord = activityRecordsMap.valueOf(currentYear, month, 1);

            records[month] = {
                month,
                year: date.getFullYear(),
                activityTotalInPerson: foundMonthlyActivityRecord?.activityTotalInPerson,
                activityTotalVirtual: foundMonthlyActivityRecord?.activityTotalVirtual,
                isValid: true,
            };

            const monthObject: MonthObject = {
                index: month,
                record: {
                    activityTotalInPerson: foundMonthlyActivityRecord?.activityTotalInPerson ?? 0,
                    activityTotalVirtual: foundMonthlyActivityRecord?.activityTotalVirtual ?? 0,
                },
            };
            months.push(monthObject);
        }
        setMonths(months.reverse());
        setRecords(records);
    };
};

const toAllowedActivityTypes = ({
    trackingOnlineActivitiesEnabled,
}: IClub): ActivityType[] => trackingOnlineActivitiesEnabled
    ? [ActivityType.IN_PERSON, ActivityType.VIRTUAL]
    : [ActivityType.IN_PERSON];

const updateActivityTrackingLayoutType = async (
    setAllowedActivityTypes: (allowedActivities: ActivityType[]) => void,
    clubShortCode: string,
): Promise<void> => {
    if (!clubShortCode) return;

    try {
        const clubInfo = await getClubByShortCode(clubShortCode, false);
        if (clubInfo) {
            setAllowedActivityTypes(toAllowedActivityTypes(clubInfo));
        }
    } catch (e) {
        console.error(`Error getting club info for ${clubShortCode}`);
    }
};

export default function NewMemberReportActivityStep (props: MemberReportActivity): JSX.Element {
    const { t } = useTranslation('pages');

    const [saving, setSaving] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [records, setRecords] = useState<{ [id: number]: ActivityRecordParameters; }>({});
    const [months, setMonths] = useState<MonthObject[]>([]);
    const [customEnrollmentInfo, isCustomEnrollmentInfoLoading] = useGetCustomEnrollmentInfo(props.clubShortCode, !props.existingUser);
    const [dataGatheringAnswers, setDataGatheringAnswers] = useState<DataGatheringAnswer[] | undefined>();
    const [error, setError] = useState<string>('');
    const [allowedActivityTypes, setAllowedActivityTypes] = useState<ActivityType[]>([ActivityType.IN_PERSON]);

    useEffect(() => {
        if (customEnrollmentInfo?.userActivities?.activityRecords || !props.existingUser) {
            const loadActivityRecords = activitiesHistoryFormLoader(setMonths, setRecords);
            loadActivityRecords(customEnrollmentInfo?.userActivities?.activityRecords || []);
        }
        setDataGatheringAnswers(customEnrollmentInfo?.additionalEnrollmentQuestionsAnswers);
    }, [isCustomEnrollmentInfoLoading]);

    useEffect(() => {
        updateActivityTrackingLayoutType(setAllowedActivityTypes, props.clubShortCode);
    }, [props.clubShortCode]);

    const onValidation = (data: ActivityRecordParameters) => {
        setRecords({
            ...records,
            [data.month]: {
                ...records[data.month],
                isValid: data.isValid,
                activityTotalInPerson: data.activityTotalInPerson,
                activityTotalVirtual: data.activityTotalVirtual,
            }
        });

        const isValid = !Object.entries(records).some(([, value]) => !value.isValid);

        setIsValid(isValid);

        if (isValid) {
            setError('');
        }
    };

    const onContinueSelected = () => {
        setSaving(true);
        props.onContinue({
            activityRecords: Object.values(records),
            ...(dataGatheringAnswers && { additionalEnrollmentQuestionsAnswers: dataGatheringAnswers }),
        });
    };

    if (isCustomEnrollmentInfoLoading && props.existingUser) {
        return <Loading loading={isCustomEnrollmentInfoLoading} className={'NewMemberRegistrationWizard_Loading'}/>;
    }

    const renderAdditionalDataGatheringQuestion = ({
        questionText,
        inputType,
    }: DataGatheringQuestion, key: number): JSX.Element | undefined => {
        if (InputType.CHECKBOX === inputType) {
            const alreadyAnswered = dataGatheringAnswers?.find((a) => a.questionText === questionText);
            return <BinaryAnswerInput
                key={key}
                label={questionText}
                onChange={(v: boolean) => {
                    const answer = String(v);
                    if (alreadyAnswered) {
                        return setDataGatheringAnswers(
                            dataGatheringAnswers?.filter((a) => a.questionText !== questionText)
                                .concat({ questionText, answer })
                        );
                    }

                    setDataGatheringAnswers([
                        ...dataGatheringAnswers || [],
                        { questionText, answer },
                    ]);
                }}
                checked={alreadyAnswered?.answer === 'true'}
            />
        }
    }

    return (
        <div className="NewMemberReportActivityStepV2_Wrapper">
            <h2>{t('customEnrollment.NewMemberReportActivityStep.v2.header')}</h2>
            <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer">
                <h4>{t('customEnrollment.NewMemberReportActivityStep.v2.subHeader')}</h4>
                <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock">
                    <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Help">
                        {props.reportActivityPageContent && props.reportActivityPageContent
                            .map((c, i) => TextBlock(c, i))}
                        {props.additionalDataGatheringQuestions && props.additionalDataGatheringQuestions
                            .map((q, i) => renderAdditionalDataGatheringQuestion(q, i))}
                    </div>
                    <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input">
                        <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input_Headers">
                            <p></p>
                            {allowedActivityTypes.includes(ActivityType.IN_PERSON)
                                && <p>{t('customEnrollment.NewMemberReportActivityStep.v2.inPersonHeader')}</p>
                            }
                            {allowedActivityTypes.includes(ActivityType.VIRTUAL)
                                && <p>{t('customEnrollment.NewMemberReportActivityStep.v2.virtualHeader')}</p>}
                        </div>
                        <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input_Values">
                            {months && months.map(month => (
                                <ReportActivityMonthInput
                                    month={month.index}
                                    key={month.index}
                                    value={month.record}
                                    onValidation={onValidation}
                                    onError={setError}
                                    allowedActivityTypes={allowedActivityTypes}
                                />
                            ))}
                        </div>
                        {error && <div className="NewMemberReportActivityStepV2_Wrapper_InnerContainer_InputBlock_Input_Error">
                            {error}
                        </div>}
                    </div>
                </div>
                <div className="ButtonsContainer">
                    {!saving && <Button type="button"
                                        id="ReportActivitySubmit"
                                        clickHandler={onContinueSelected}
                                        text={t('newMemberRegistration.submitButton')}
                                        buttonStyle={ButtonStyles.UnfilledWithBorder}
                                        disabled={!isValid}
                    />}
                    {saving
                        && <Loading className={'NewMemberRegistrationWizard_SubmitButtonLoading'} loading={saving}/>}
                    <Button type="button"
                            clickHandler={props.onPrevious}
                            text={t('newMemberRegistration.backButton')}
                            className="NewMemberRegistrationWizard_SubmitButton"
                            buttonStyle={ButtonStyles.UnfilledWithBorder}
                    />
                </div>
            </div>
        </div>
    );
}

function ReportActivityMonthInput ({
    month,
    value,
    onValidation,
    onError,
    allowedActivityTypes,
}: {
    month: number,
    value?: MonthObjectRecord,
    onValidation: Function,
    onError: Function,
    allowedActivityTypes: ActivityType[],
}): JSX.Element {
    const { t } = useTranslation('pages');
    const [
        totalInPerson,
        setTotalInPerson,
    ] = useState<number | undefined>(value?.activityTotalInPerson);
    const [
        totalVirtual,
        setTotalVirtual,
    ] = useState<number | undefined>(value?.activityTotalVirtual);

    useEffect(() => {
        const error = validateActivityRecord(value);

        if (error) {
            onError(error);
        }

        onValidation({
            month: month,
            isValid: !error,
            activityTotalInPerson: value?.activityTotalInPerson,
            activityTotalVirtual: value?.activityTotalVirtual
        });
    }, []);

    const validateActivityRecord = (value?: MonthObjectRecord): string | undefined => {
        if (!value) {
            return t(
                'customEnrollment.NewMemberReportActivityStep.v2.invalidMonthlyActivity',
                { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH },
            );
        }

        if (
            Number.isNaN(Number(value?.activityTotalInPerson)) || Number.isNaN(Number(value?.activityTotalVirtual))
        ) {
            return t(
                'customEnrollment.NewMemberReportActivityStep.v2.invalidMonthlyActivity',
                { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH },
            );
        }

        const monthlyTotal = Number(value?.activityTotalInPerson) + Number(value?.activityTotalVirtual);

        if (monthlyTotal > memberDashboardConfig.MAX_ACTIVITY_PER_MONTH) {
            return t(
                'customEnrollment.NewMemberReportActivityStep.v2.invalidMonthlyActivity',
                { maxLimit: memberDashboardConfig.MAX_ACTIVITY_PER_MONTH },
            );
        }
    }

    const toNumber = (value: string): number => Number(applyMaxMinChecking(value));

    const onInPersonValueChange = (data: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = toNumber(data.target.value);
        const error = validateActivityRecord({
            activityTotalInPerson: formattedValue,
            activityTotalVirtual: totalVirtual
        });

        if (error) {
            onError(error);
        }

        onValidation({
            month: month,
            isValid: !error,
            activityTotalInPerson: formattedValue,
            activityTotalVirtual: totalVirtual,
        });

        return setTotalInPerson(formattedValue);
    };

    const onVirtualValueChange = (data: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = toNumber(data.target.value);

        const error = validateActivityRecord({
            activityTotalInPerson: totalInPerson,
            activityTotalVirtual: formattedValue
        });

        onValidation({
            month: month,
            isValid: !error,
            activityTotalInPerson: totalInPerson,
            activityTotalVirtual: formattedValue,
        });

        if (error) {
            onError(error);
        }

        return setTotalVirtual(formattedValue);
    };

    const onBlur = () => onValidation({
        month: month,
        isValid: !validateActivityRecord({
            activityTotalInPerson: totalInPerson,
            activityTotalVirtual: totalVirtual,
        }),
        activityTotalInPerson: totalInPerson,
        activityTotalVirtual: totalVirtual,
    })

    return (
            <div className="MonthInputElementContainer">
                <div className="MonthInput">
                    <span className="MonthInputLabel">
                        {t(`newMemberRegistration.months.${month}`)}
                    </span>
                    {allowedActivityTypes.includes(ActivityType.IN_PERSON) && <TextField
                        name={`month-${month}-inPerson`}
                        className="Input"
                        variant={'outlined'}
                        inputProps={{ style: { textAlign: 'center' } }}
                        required
                        value={totalInPerson}
                        onChange={onInPersonValueChange}
                        onBlur={onBlur}
                        size="small"/>}
                    {allowedActivityTypes.includes(ActivityType.VIRTUAL) && <TextField
                        name={`month-${month}-virtual`}
                        className="Input"
                        inputProps={{ style: { textAlign: 'center' }}}
                        variant={'outlined'}
                        required
                        value={totalVirtual}
                        onChange={onVirtualValueChange}
                        onBlur={onBlur}
                        size="small"/>}
                </div>
            </div>
    );
}

const BinaryAnswerInput = ({ checked, onChange, label }: { checked: boolean, onChange: Function, label: string }): JSX.Element => {
    return <FormControlLabel
        control={<Checkbox
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
            size="small"
        />}
        label={label}
    />;
};

const TextBlock = (sanityTextObject: SanityTextObject, key?: number): JSX.Element => {
    const text = sanityTextObject.children.map(({ text }) => text).join(' ');
    return typeof key !== 'undefined' ? <p key={key}>{text}</p> : <p>{text}</p>
};
