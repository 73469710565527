import React, { useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { IconButton } from "@material-ui/core";
import CardItem from './CardItem'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import { useDropzone } from 'react-dropzone';
import cn from "classnames";
import useUploadFile from "../../../hooks/useUploadFile";
import DownloadIcon from "../icons/DownloadIcon";
import UploadFileIcon from "../icons/UploadFileIcon";

interface IUploadContentProps {
    onBackClick: (e: React.MouseEvent) => void
    fetchNotInvitedMembers: Function,
}

const UploadContent = ({ onBackClick, fetchNotInvitedMembers }: IUploadContentProps) => {
    const { t } = useTranslation('ignite/dashboard');
    const { setFiles, error, isSuccess } = useUploadFile(t)

    useEffect(() => {
        if (isSuccess) fetchNotInvitedMembers()
    }, [isSuccess])

    const onGetTemplate = () => {
        window.location.href = "/ignite-upload-members-template.xlsx";
    }

    const onDrop = useCallback(acceptedFile => {
        setFiles(acceptedFile)
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    const getUploadDescription = () => {
        if (error) return error;
        if (isSuccess) return t('uploadContent.Success')

        return t('uploadContent.card2Description')
    }

    return (
        <div className="upload-content">
            <IconButton
                className="icon-button"
                onClick={onBackClick}
            >
                <ArrowBackIcon className="icon" />
                {t('backButtonText')}
            </IconButton>
            <div className="action-text">
                {t('uploadContent.title')}
            </div>

            <div className="list">
                <CardItem
                    Icon={DownloadIcon}
                    text={t('uploadContent.card1Text')}
                    buttonText={t('uploadContent.card1ButtonText')}
                    description={t('uploadContent.card1Description')}
                    onClick={onGetTemplate}
                />
                <div {...getRootProps({ className: "dropzone" })}>
                    <CardItem
                        Icon={UploadFileIcon}
                        text={t('uploadContent.card2Text')}
                        buttonText={t('uploadContent.card2ButtonText')}
                        className='gray'
                        description={getUploadDescription()}
                        descriptionClassName={cn({ error })}
                    />
                    <label hidden htmlFor="upload-zone-id">{t('uploadContent.card2Text')}</label>
                    <input {...getInputProps()} id="upload-zone-id" />
                </div>
            </div>
        </div>
    )
}

export default UploadContent;
