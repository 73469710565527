import React, { useContext, useEffect, useState } from 'react';

import './NewMemberRegistrationWizard.scss';
import { Redirect, RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { useFormState } from 'react-use-form-state';
import { SelectedPayerContext } from '../../contexts/SelectedPayerContext';

import NewMemberPassionsStep from './steps/NewMemberPassionsStep';
import { NewMemberAccountStep } from './steps/NewMemberAccountStep';
import { NewMemberPersonalInformationStep } from './steps/NewMemberPersonalInformationStep';
import NewMemberActivityTypesStep from './steps/NewMemberActivityTypesStep';
import NewMemberReportActivityStep from './steps/NewMemberReportActivityStep';
import NewMemberReportActivityStepV2 from './steps/NewMemberReportActivityStepV2';
import {
  CustomEnrollmentPersonalInformationParameters,
  CustomEnrollmentRegistrationParameters,
  NewMemberRegistrationParameters,
  PayerInformationParameters,
  registerMemberViaCustomEnrollment,
  registerNewMember,
  setUsersEligibilityModalShownState,
} from '../../services/UserService';
import { UserContext } from '../../contexts/UserContext';
import { sendPageView } from '../../withTracker';
import { UserRegistrationFlow } from '../../services/Models';
import {
  readUtmRegistrationParametersFromSession,
  storeRegistrationParametersToSession,
} from '../../util/Util';
import { loadConfig } from '../../services/ConfigService';
import { postJsonAuth } from '../../services/RequestService';
import { LocalDate } from '@js-joda/core';
import { getIgniteRoute } from '../../util/ignite/routes.utils';
import { IGNITE_ROUTE_KEY } from '../../constants/routes';
import useClubByShortCode from '../../hooks/useClubByShortCode';
import NotFoundPage from '../notFound/NotFoundPage';
import Loading from '../../components/common/Loading';
import { CustomEnrollmentContactDetailsStep } from './steps/CustomEnrollmentContactDetailsStep';
import { CustomEnrollmentPersonalInformationStep } from './steps/CustomEnrollmentPersonalInformationStep';
import { CustomEnrollmentHealthcareCoverageStep } from './steps/CustomEnrollmentHealthcareCoverageStep';
import getClubAssociationContentViaSanity, {
  ClubAssociationEnrollmentConfigurationSanityData,
  ClubAssociationSanityData,
} from '../../sanity/getClubAssociationContentViaSanity';
import {
  InputType,
  MemberReportActivity,
  ReportActivityParameters,
} from './types';
import { useFeatureFlag } from '../../services/FeatureFlagService';
import { USE_REPORT_ACTIVITY_STEP_V2 } from '../../constants/flags';
import { IClubV2 } from '../../types/clubFinder';

export class RegistrationError extends Error {
  constructor(message: string, readonly validationKey?: string) {
    super(message);
  }
}

interface UpdateActivityTypesParameters {
  activityTypeIds: number[];
}

export interface NewMemberPayerInformationParameters {
  payerId: number;
  otherPayerId: number;
  userSuppliedPayerName: string;
  insuranceId: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: LocalDate;
}

export enum NewMemberRegistrationWizardSteps {
  Passions = 1,
  Account = 2,
  PersonalInformation = 3,
  ActivityTypes = 4,
  ReportActivity = 5,
  HealthPlanCoverage = 6,
}

export default function NewMemberRegistrationWizard({
  location,
  history,
}: RouteComponentProps): JSX.Element {
  const useReportActivityV2 = useFeatureFlag(
    USE_REPORT_ACTIVITY_STEP_V2,
    false,
  );
  const { authenticatedFetch, login, isLoggedIn, refreshUser, user } =
    useContext(UserContext);
  const { payer } = useRouteMatch<{ payer: string }>().params;
  const { customEnrollmentShortCode } = useRouteMatch<{
    customEnrollmentShortCode: string;
  }>().params;
  const initialState = {
    passions: [],
    renewId: '',
  };

  const [memberZipCode, setMemberZipCode] = useState<string>('');
  const [personalInformation, setPersonalInformation] =
    useState<NewMemberPayerInformationParameters>();
  const [selectedActivityTypes, setSelectedActivityTypes] = useState<number[]>(
    [],
  );
  const [queryResults, setQueryResults] = useState<ClubAssociationSanityData>();
  const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);
  const [
    clubAssociationEnrollmentConfiguration,
    setClubAssociationEnrollmentConfiguration,
  ] = useState<ClubAssociationEnrollmentConfigurationSanityData | undefined>(
    undefined,
  );
  const [formState] = useFormState(initialState);

  const searchParams = new URLSearchParams(location.search);
  const experimentCode = searchParams.get('exp');
  const experimentClubShortCode = searchParams.get('csc');
  const existingUser = isLoggedIn();
  
  const isAetnaRegFlow = location.pathname.toLowerCase().startsWith("/register/aetna");

  // Is this the experimental flow?
  const isExperimentalFlow =
    experimentCode !== null && experimentClubShortCode !== null;
  const isCustomEnrollmentFlow = customEnrollmentShortCode !== undefined;
  // Assume the payer profile is "member" for the association flow.
  const registrationPath = isCustomEnrollmentFlow ? 'member' : payer;

  // current wizard step depending on if we are going through on the experimental or custom enrollment flow or not.
  const [wizardStep, setWizardStep] = useState(
    isExperimentalFlow || (isCustomEnrollmentFlow && !isLoggedIn())
      ? NewMemberRegistrationWizardSteps.Account
      : isCustomEnrollmentFlow && isLoggedIn()
      ? NewMemberRegistrationWizardSteps.PersonalInformation
      : NewMemberRegistrationWizardSteps.Passions,
  );
  const [club, isLoading] = useClubByShortCode(
    isExperimentalFlow ? experimentClubShortCode! : customEnrollmentShortCode,
    !isExperimentalFlow && !isCustomEnrollmentFlow,
    false,
  );

  const [groupworksSelectedClub, setGroupworksSelectedClub] = useState<
    IClubV2 | undefined
  >(undefined);

  useEffect(() => {
    storeRegistrationParametersToSession(history.location);

    if (isExperimentalFlow) {
      if (isLoggedIn()) {
        history.push(`/`);
      }
    }

    if (isCustomEnrollmentFlow) {
      getClubAssociationContentViaSanity(customEnrollmentShortCode).then(
        (data) => {
          setQueryResults(data?.result[0]);
          const associationEnrollmentConfiguration =
            data?.result[0].associationEnrollmentConfiguration[0];
          setClubAssociationEnrollmentConfiguration(
            associationEnrollmentConfiguration,
          );
          setQueryResultsLoading(false);
        },
      );
    } else {
      setQueryResultsLoading(false);
    }
  }, []);

  // No translations needed - these are for Google Analytics
  function getWizardStepTitle(wizardStep: NewMemberRegistrationWizardSteps) {
    switch (wizardStep) {
      case NewMemberRegistrationWizardSteps.Passions:
        return 'Passions';
      case NewMemberRegistrationWizardSteps.Account:
        return isCustomEnrollmentFlow ? 'Contact Details' : 'Account';
      case NewMemberRegistrationWizardSteps.PersonalInformation:
        return 'Personal Information';
      case NewMemberRegistrationWizardSteps.ActivityTypes:
        return 'Activity Types';
      case NewMemberRegistrationWizardSteps.ReportActivity:
        return 'Report Activity';
      case NewMemberRegistrationWizardSteps.HealthPlanCoverage:
        return 'Health Plan Coverage';
      default:
        return '';
    }
  }

  useEffect(() => {
    const flowTitleText = isExperimentalFlow
      ? ` - Experimental Flow ( ${experimentCode} )`
      : isCustomEnrollmentFlow
      ? ` - Custom Enrollment Flow ( ${customEnrollmentShortCode} )`
      : ` - ${payer} New Member`;

    sendPageView(
      `${history.location.pathname}?step=${wizardStep.valueOf()}`,
      `${flowTitleText} Registration Step ${getWizardStepTitle(
        wizardStep.valueOf(),
      )}`,
    );
  }, [wizardStep]);

  const { setPayerDetails } = useContext(SelectedPayerContext);

  function wizardComplete(zipCode: string) {
    setPayerDetails(null);

    if (user) {
      setUsersEligibilityModalShownState(user.id, false);
    }

    const flowTitleText = isExperimentalFlow
      ? ` - Experimental Flow ( ${experimentCode} )`
      : isCustomEnrollmentFlow
      ? ` - Custom Enrollment Flow ( ${customEnrollmentShortCode} )`
      : ` - ${payer} New Member`;

    sendPageView(
      `${history.location.pathname}?step=${wizardStep.valueOf()}`,
      `${flowTitleText} Registration Complete`,
    );

    if (club !== undefined && club.isIgniteEnabled && isExperimentalFlow) {
      history.push(
        getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, {
          ':clubId': String(club.id),
        }),
      );
    } else if (isCustomEnrollmentFlow) {
      history.push(`/p/${customEnrollmentShortCode}/enrollment-complete`);
    } else {
      const searchParams = new URLSearchParams();
      searchParams.set('justRegistered', 'true');
      searchParams.set('postalCode', zipCode);
      history.push(`/find-an-event?${searchParams.toString()}`);
    }
  }

  async function onContinueSelected(data: any) {
    switch (wizardStep) {
      case NewMemberRegistrationWizardSteps.Passions:
        formState.setField('passions', data.passions);
        setWizardStep(NewMemberRegistrationWizardSteps.Account);
        break;
      case NewMemberRegistrationWizardSteps.Account:
        formState.setField('renewId', data.renewId);
        // We want to create the user after this step before proceeding to payer information.
        if (isCustomEnrollmentFlow) {
          await onCustomEnrollmentRegister({ ...data });
        } else {
          await onRegister({ ...data });
        }
        break;
      case NewMemberRegistrationWizardSteps.PersonalInformation:
        if (isCustomEnrollmentFlow) {
          await updateCustomEnrollmentPersonalInformation({ ...data });

          setWizardStep(NewMemberRegistrationWizardSteps.HealthPlanCoverage);
        } else {
          if (checkIfPayerInformationIsTheSame(data)) {
            if (isExperimentalFlow) {
              setWizardStep(NewMemberRegistrationWizardSteps.ReportActivity);
            } else {
              setWizardStep(NewMemberRegistrationWizardSteps.ActivityTypes);
            }
            break;
          }
          await handlePayerInformation(data);
          if (isExperimentalFlow) {
            setWizardStep(NewMemberRegistrationWizardSteps.ReportActivity);
          } else {
            setWizardStep(NewMemberRegistrationWizardSteps.ActivityTypes);
          }
        }
        break;
      case NewMemberRegistrationWizardSteps.ActivityTypes:
        await updateActivityTypes({ ...data });
        setSelectedActivityTypes(data.activityTypeIds);
        wizardComplete(memberZipCode);
        break;
      case NewMemberRegistrationWizardSteps.HealthPlanCoverage:
        if (checkIfPayerInformationIsTheSame(data)) {
          setWizardStep(NewMemberRegistrationWizardSteps.ReportActivity);
          break;
        }
        await handlePayerInformation(data);
        // If we are collecting activity data, go to that step, otherwise, complete the wizard.
        if (clubAssociationEnrollmentConfiguration?.activityCollectionEnabled) {
          setWizardStep(NewMemberRegistrationWizardSteps.ReportActivity);
        } else {
          wizardComplete(memberZipCode);
        }
        break;
      case NewMemberRegistrationWizardSteps.ReportActivity:
        await reportActivity({ ...data });
        wizardComplete(memberZipCode);
        break;
      default:
        setWizardStep(NewMemberRegistrationWizardSteps.Account);
    }
  }

  function onPreviousSelected() {
    switch (wizardStep) {
      case NewMemberRegistrationWizardSteps.Passions:
        setWizardStep(NewMemberRegistrationWizardSteps.Passions);
        break;
      case NewMemberRegistrationWizardSteps.Account:
        if (isExperimentalFlow) {
          setWizardStep(NewMemberRegistrationWizardSteps.Account);
        } else if (isCustomEnrollmentFlow) {
          history.push(`/p/${customEnrollmentShortCode}`);
        } else {
          setWizardStep(NewMemberRegistrationWizardSteps.Passions);
        }
        break;
      case NewMemberRegistrationWizardSteps.PersonalInformation:
        // Do nothing
        break;
      case NewMemberRegistrationWizardSteps.ActivityTypes:
        setWizardStep(NewMemberRegistrationWizardSteps.PersonalInformation);
        break;
      case NewMemberRegistrationWizardSteps.HealthPlanCoverage:
        setWizardStep(NewMemberRegistrationWizardSteps.PersonalInformation);
        break;
      case NewMemberRegistrationWizardSteps.ReportActivity:
        if (isExperimentalFlow) {
          setWizardStep(NewMemberRegistrationWizardSteps.PersonalInformation);
        } else if (isCustomEnrollmentFlow) {
          setWizardStep(NewMemberRegistrationWizardSteps.HealthPlanCoverage);
        }
        break;
      default:
        if (isExperimentalFlow || isCustomEnrollmentFlow) {
          setWizardStep(NewMemberRegistrationWizardSteps.Account);
        } else {
          setWizardStep(NewMemberRegistrationWizardSteps.Passions);
        }
    }
  }

  async function onRegister(data: any) {
    let { utmCampaign, utmReferringClub } =
      readUtmRegistrationParametersFromSession() || {};

    if (isExperimentalFlow) {
      if (utmCampaign === undefined) {
        utmCampaign = experimentCode!;
      }
      if (utmReferringClub === undefined) {
        utmReferringClub = experimentClubShortCode!;
      }
    }

    try {
      const parameters: NewMemberRegistrationParameters = {
        passionIds: formState.values.passions,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        utmCampaign: utmCampaign,
        utmReferringClub: utmReferringClub,
        registrationFlow: isExperimentalFlow
          ? UserRegistrationFlow.EXPERIMENT_FLOW
          : isAetnaRegFlow ? UserRegistrationFlow.AETNA_ENROLLMENT
          : UserRegistrationFlow.GENERIC_FLOW,
      };

      await registerNewMember(parameters).then(
        // good
        () => {
          login(data.email, data.password).then(
            // good
            () => {
              setWizardStep(
                NewMemberRegistrationWizardSteps.PersonalInformation,
              );
            },
            // nope
            () => {
              console.error(
                'There was an error registering.' +
                  '  If this persists, please contact support.',
              );
            },
          );
        },
      );
    } catch (e) {
      console.error('RegistrationError: ', e);
    }
  }

  async function onCustomEnrollmentRegister(data: any): Promise<void> {
    let { utmCampaign, utmReferringClub } =
      readUtmRegistrationParametersFromSession() || {};

    const parameters: CustomEnrollmentRegistrationParameters = {
      email: data.email,
      password: data.password,
      hearAboutUs: data.hearAboutUs,
      referralCode: data.referralCode,
      hearAboutUsTextInput: data.hearAboutUsTextInput,
      shortCode: customEnrollmentShortCode!,
      utmCampaign: utmCampaign,
      utmReferringClub: utmReferringClub,
      registrationFlow:
        customEnrollmentShortCode.toLowerCase() === 'groupworks'
          ? UserRegistrationFlow.GROUPWORKS_ENROLLMENT
          : UserRegistrationFlow.CUSTOM_ENROLLMENT_FLOW,
      memberId: data.memberId,
      ...(clubAssociationEnrollmentConfiguration?.memberIdEnabled && {
        lastName: data.lastName,
      }),
    };

    return registerMemberViaCustomEnrollment(parameters)
      .then(() => login(data.email, data.password))
      .then(() =>
        setWizardStep(NewMemberRegistrationWizardSteps.PersonalInformation),
      )
      .catch((error) => {
        console.error('RegistrationError: ', error);

        let validationKey;
        let errorMessage =
          'There was an error registering. If this persists, please contact support.';
        try {
          const response = JSON.parse(error.data);
          validationKey = response.validationKey;
          if (clubAssociationEnrollmentConfiguration?.memberIdEnabled) {
            errorMessage = response.reason;
          }
        } finally {
          throw new RegistrationError(errorMessage, validationKey);
        }
      });
  }

  function checkIfPayerInformationIsTheSame(data: any) {
    return (
      personalInformation &&
      personalInformation.payerId === data.payerId &&
      personalInformation.otherPayerId === data.otherPayerId &&
      personalInformation.userSuppliedPayerName ===
        data.userSuppliedPayerName &&
      personalInformation.insuranceId === data.insuranceId &&
      personalInformation.dateOfBirth === data.dateOfBirth &&
      personalInformation.phoneNumber === data.phone &&
      personalInformation.zipCode === data.zipCode
    );
  }

  async function handlePayerInformation(data: any) {
    setMemberZipCode(data.zipCode);

    setPersonalInformation({
      payerId: data.payerId,
      otherPayerId: data.otherPayerId,
      userSuppliedPayerName: data.userSuppliedPayerName,
      insuranceId: data.insuranceId,
      dateOfBirth: data.dateOfBirth,
      phoneNumber: data.phone,
      zipCode: data.zipCode,
    });

    await updatePersonalInformation({ ...data });
    setPayerDetails({
      payerId: data.otherPayerId === 0 ? data.payerId : data.otherPayerId,
      insuranceId: data.insuranceId,
    });
  }

  async function updatePersonalInformation(data: any) {
    try {
      const parameters: PayerInformationParameters = {
        payerId: data.otherPayerId === 0 ? data.payerId : data.otherPayerId,
        healthPlanName: data.userSuppliedPayerName,
        insuranceId: data.insuranceId,
        dateOfBirth: data.dateOfBirth,
        phoneNumber: data.phone,
        zipCode: data.zipCode,
      };

      const config = await loadConfig();
      await postJsonAuth({
        authenticatedFetch: authenticatedFetch,
        url: `${config.apiOrigin}/submit-payer-details`,
        data: parameters,
      });
    } catch (e) {
      console.error('PersonalInformationUpdateError: ', e);
    }
  }

  async function updateCustomEnrollmentPersonalInformation(data: any) {
    try {
      let clubShortCode = isCustomEnrollmentFlow
        ? customEnrollmentShortCode!
        : experimentClubShortCode!;
      if (groupworksSelectedClub) {
        clubShortCode = groupworksSelectedClub.clubShortcode;
      }
      const parameters: CustomEnrollmentPersonalInformationParameters = {
        firstName: data.firstName,
        lastName: data.lastName,
        memberId: data.memberId,
        externalActivityProfileUrl: data.externalActivityProfileUrl,
        userReportedActiveMembership: data.userReportedActiveMembership,
        agreements: data.userAgreements,
        shortCode: clubShortCode,
      };

      const config = await loadConfig();
      await postJsonAuth({
        authenticatedFetch: authenticatedFetch,
        url: `${config.apiOrigin}/submit-personal-information-for-custom-enrollment`,
        data: parameters,
      });
    } catch (e) {
      console.error('CustomEnrollmentPersonalInformationUpdateError: ', e);
    }
  }

  async function updateActivityTypes(data: any) {
    try {
      const parameters: UpdateActivityTypesParameters = {
        activityTypeIds: data.activityTypeIds,
      };

      const config = await loadConfig();
      await postJsonAuth({
        authenticatedFetch: authenticatedFetch,
        url: `${config.apiOrigin}/my-profile/update-activity-types`,
        data: parameters,
      });
    } catch (e) {
      console.error('ActivityTypeUpdateError: ', e);
    }
  }

  async function reportActivity(data: any) {
    try {
      let clubShortCode = isCustomEnrollmentFlow
        ? customEnrollmentShortCode!
        : experimentClubShortCode!;
      if (groupworksSelectedClub) {
        clubShortCode = groupworksSelectedClub.clubShortcode;
      }
      const parameters: ReportActivityParameters = {
        additionalEnrollmentQuestionsAnswers:
          data.additionalEnrollmentQuestionsAnswers,
        activityRecords: data.activityRecords,
        clubShortCode: clubShortCode,
      };

      const config = await loadConfig();
      await postJsonAuth({
        authenticatedFetch: authenticatedFetch,
        url: `${config.apiOrigin}/ignite/submit-member-activity-with-enrollment`,
        data: parameters,
      });
      await refreshUser();
    } catch (e) {
      console.error('ReportActivityError: ', e);
    }
  }

  function renderPassions() {
    return (
      <div className="NewMemberRegistrationWizard">
        <NewMemberPassionsStep
          onContinue={onContinueSelected}
          onPrevious={onPreviousSelected}
          selectedPassions={formState.values.passions}
        ></NewMemberPassionsStep>
      </div>
    );
  }

  function renderAccount() {
    return (
      <div className="NewMemberRegistrationWizard">
        {!isCustomEnrollmentFlow && (
          <NewMemberAccountStep
            onContinue={onContinueSelected}
            onPrevious={onPreviousSelected}
            registrationPath={registrationPath}
            isEnrollmentFlow={isExperimentalFlow}
          ></NewMemberAccountStep>
        )}
        {isCustomEnrollmentFlow && (
          <CustomEnrollmentContactDetailsStep
            onContinue={onContinueSelected}
            onPrevious={onPreviousSelected}
            enrollmentConfiguration={clubAssociationEnrollmentConfiguration}
            clubShortCode={customEnrollmentShortCode!}
          ></CustomEnrollmentContactDetailsStep>
        )}
      </div>
    );
  }

  function renderPersonalInformation() {
    return (
      <div className="NewMemberRegistrationWizard">
        {!isCustomEnrollmentFlow && (
          <NewMemberPersonalInformationStep
            onContinue={onContinueSelected}
            onPrevious={onPreviousSelected}
            registrationPath={registrationPath}
            renewId={formState.values.renewId}
            personalInformation={personalInformation}
          ></NewMemberPersonalInformationStep>
        )}
        {isCustomEnrollmentFlow && (
          <CustomEnrollmentPersonalInformationStep
            onGroupWorksSelectedClub={setGroupworksSelectedClub}
            onContinue={onContinueSelected}
            onPrevious={onPreviousSelected}
            clubShortCode={customEnrollmentShortCode!}
            associationShortName={queryResults?.shortName}
            associationFullName={queryResults?.name}
            enrollmentConfiguration={clubAssociationEnrollmentConfiguration}
          ></CustomEnrollmentPersonalInformationStep>
        )}
      </div>
    );
  }

  function renderHealthPlanCoverage() {
    return (
      <div className="NewMemberRegistrationWizard">
        <CustomEnrollmentHealthcareCoverageStep
          onContinue={onContinueSelected}
          onPrevious={onPreviousSelected}
          personalInformation={personalInformation}
        ></CustomEnrollmentHealthcareCoverageStep>
      </div>
    );
  }

  function renderActivityTypes() {
    return (
      <div className="NewMemberRegistrationWizard">
        <NewMemberActivityTypesStep
          onContinue={onContinueSelected}
          onPrevious={onPreviousSelected}
          selectedActivityTypes={selectedActivityTypes}
        ></NewMemberActivityTypesStep>
      </div>
    );
  }

  function renderReportActivity() {
    const additionalProps: Partial<MemberReportActivity> = {};
    let clubShortCode = isCustomEnrollmentFlow
      ? customEnrollmentShortCode!
      : experimentClubShortCode!;
    if (
      customEnrollmentShortCode.toLowerCase() === 'groupworks' &&
      groupworksSelectedClub
    ) {
      clubShortCode = groupworksSelectedClub?.clubShortcode;
    }
    const {
      dataGatheringQuestionCheckboxEnabled,
      dataGatheringQuestionCheckboxLabel,
    } = clubAssociationEnrollmentConfiguration || {};

    if (
      useReportActivityV2 &&
      dataGatheringQuestionCheckboxEnabled &&
      dataGatheringQuestionCheckboxLabel
    ) {
      additionalProps.additionalDataGatheringQuestions = [
        {
          questionText: dataGatheringQuestionCheckboxLabel,
          inputType: InputType.CHECKBOX,
        },
      ];
    }

    return (
      <div
        className={`NewMemberRegistrationWizard${
          isCustomEnrollmentFlow ? ' CustomEnrollmentFlow' : ''
        }`}
      >
        {useReportActivityV2 ? (
          <NewMemberReportActivityStepV2
            onContinue={onContinueSelected}
            onPrevious={onPreviousSelected}
            experimentCode={experimentCode}
            clubShortCode={clubShortCode}
            reportActivityPageContent={
              clubAssociationEnrollmentConfiguration?.reportActivityPageContent
            }
            existingUser={existingUser}
            {...additionalProps}
          />
        ) : (
          <NewMemberReportActivityStep
            groupworksSelctedClub={groupworksSelectedClub}
            onContinue={onContinueSelected}
            onPrevious={onPreviousSelected}
            experimentCode={experimentCode}
            clubShortCode={
              isCustomEnrollmentFlow
                ? customEnrollmentShortCode!
                : experimentClubShortCode!
            }
            reportActivityPageContent={
              clubAssociationEnrollmentConfiguration?.reportActivityPageContent
            }
            existingUser={existingUser}
          />
        )}
      </div>
    );
  }

  function renderWizardStep() {
    if (isLoading || queryResultsLoading) {
      return (
        <Loading
          className="PageLoading"
          loading={isLoading || queryResultsLoading}
        />
      );
    } else {
      if (
        (isExperimentalFlow || isCustomEnrollmentFlow) &&
        club === undefined
      ) {
        return <NotFoundPage></NotFoundPage>;
      }
      if (isCustomEnrollmentFlow && !queryResults) {
        return <NotFoundPage></NotFoundPage>;
      }
    }
    switch (wizardStep) {
      case NewMemberRegistrationWizardSteps.Passions:
        if (isLoggedIn()) {
          return <Redirect to={'/'} />;
        }
        return renderPassions();
      case NewMemberRegistrationWizardSteps.Account:
        return renderAccount();
      case NewMemberRegistrationWizardSteps.PersonalInformation:
        return renderPersonalInformation();
      case NewMemberRegistrationWizardSteps.HealthPlanCoverage:
        return renderHealthPlanCoverage();
      case NewMemberRegistrationWizardSteps.ActivityTypes:
        return renderActivityTypes();
      case NewMemberRegistrationWizardSteps.ReportActivity:
        return renderReportActivity();
      default:
        if (isExperimentalFlow || isCustomEnrollmentFlow) {
          return renderAccount();
        } else {
          return renderPassions();
        }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return renderWizardStep();
}
