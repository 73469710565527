import React from "react";
import "./DateView.scss";
import {convert, LocalDate} from "@js-joda/core";
import i18n from "i18next";

export interface DateViewProps {
    date: string | Date | LocalDate;
    format: object;
    className?: string;
}

export default function DateView(props: DateViewProps): JSX.Element {

    let classes = 'CCDateView';
    let className = props.className;
    let date: Date;

    if (typeof props.date === 'string') {
        date = new Date(props.date.toString());
    } else if (props.date instanceof LocalDate) {
        // TODO: Does this work correctly?
        //       It is unclear how exactly converting a date without a time
        //       (LocalDate) to an absolute timestamp (native JS Date) works,
        //       especially since we don't tell it which time zone to use.
        date = convert(props.date).toDate();
    } else {
        date = props.date as Date;
    }

    let formattedDate = date.toLocaleDateString(i18n.language || 'en', props.format);

    if (className !== undefined) {
        classes += ' ' + className;
    }

    return (
        <span className={classes}>{formattedDate}</span>
    );
}
