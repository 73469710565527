
import { loadConfig } from '../services/ConfigService'
import { IPassion } from '../types/clubSetup';

export const GET_ALL_PASSIONS_URL = '/all-passions'

const getAllPassions = async (): Promise<{ allPassions: IPassion[] | [] }> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_ALL_PASSIONS_URL}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await fetch(request);
    if (response?.ok) {
        return response.json()
    } else {
        return { allPassions: [] }
    }
}

export default getAllPassions