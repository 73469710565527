import React, {useContext} from "react";
import { useTranslation } from 'react-i18next';

import "./ConfirmationModal.scss";
import PlayButton from "./PlayButton";
import {ButtonStyles} from "./Button";
import CloseIcon from "@material-ui/icons/Close";
import {Dialog} from "@material-ui/core";
import ContactPhoneNumber from "./ContactPhoneNumber";
import {UserContext} from "../../contexts/UserContext";
import {useLocation} from "react-router-dom";


interface ConfirmationModalProps {
    className?: string;
    shown: boolean;
    children?: any;
    okClickHandler?: Function;
    cancelClickHandler?: Function;
    confirmText?: string,
    denyText?: string
}

export default function ConfirmationModal(props: ConfirmationModalProps): JSX.Element {
    const { t } = useTranslation('common');
    const {getContactPhoneNumber} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    let children = props.children === undefined ? t('confirmationModal.areYouSure') : props.children;

    let classes = "ConfirmationModal "
        + (props.className === undefined ? "" : props.className);

    function cancel(e:any) {
        if (props.cancelClickHandler !== undefined) {
            props.cancelClickHandler(e);
        }
    }

    function ok(e:any) {
        if (props.okClickHandler !== undefined) {
            props.okClickHandler(e);
        }
    }

    return (
        <Dialog className={classes} open={props.shown}>
            <div className="ConfirmationModal_Content">
                <div className="ConfirmationModal_CloseWrapper">
                    <CloseIcon fontSize="large" className="RegistrationModal_CloseIcon"
                               onClick={cancel}/>
                </div>
                <div className="ConfirmationModal_Message">
                    {children}
                </div>
            </div>
            <div className="ConfirmationModal_Buttons">
                <PlayButton clickHandler={cancel} text={props.denyText || t('confirmationModal.no')} buttonStyle={ButtonStyles.UnfilledWithBorder}/>
                <PlayButton clickHandler={ok} text={props.confirmText || t('confirmationModal.yes')} buttonStyle={ButtonStyles.FilledPrimary}/>
            </div>
            <div className="ConfirmationModal_HelpText">{t('confirmationModal.helpPhone')} <a href={`tel:${phoneNumber.tel}`}>{phoneNumber.display}</a></div>
        </Dialog>
    );
}