import React from "react";
import { useTranslation } from 'react-i18next';

import "./PaidIndicator.scss";

export interface PaidIndicatorProps {
    className?: string;
    children?: any;
}

export default function PaidIndicator(props: PaidIndicatorProps): JSX.Element {
    const { t } = useTranslation('common');
    let classes = "PaidIndicator";
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    return (
        <><span className={classes}><span
            className={"PaidIndicator_Money"}>{t('paidIndicator.moneySymbol')}</span>{props.children}</span></>
    );
}