import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const REGISTER_INVITED_LEADER_URL = '/ignite/register-invited-leader'

const postRegisterInvitedLeader = async (body: any): Promise<{ userId: number } | { message: string } | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${REGISTER_INVITED_LEADER_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    const response = await fetch(request);

    if (response?.ok) {
        return response.json()
    }
    const error = await response.text()

    throw new Error(error)
}

export default postRegisterInvitedLeader
