import React from "react";
import { Tabs as MaterialTabs, Tab } from '@material-ui/core'

interface ITabsProps {
    value: string
    onChange: (event: React.ChangeEvent<{}>, value: any) => void
    items: { value: string, label: string }[]
    className?: string
}

const Tabs = ({
    value,
    onChange,
    items,
    className
}: ITabsProps) => {

    return (
        <MaterialTabs
            value={value}
            onChange={onChange}
            classes={{
                indicator: 'dashboard-tabs-indicator',
                root: className,
                scroller: 'scroller',
                flexContainer: 'container'
            }}
        >
            {items.map((item) =>
                <Tab
                    classes={{
                        root: 'dashboard-tab',
                        selected: 'selected'
                    }}
                    value={item.value}
                    label={item.label}
                />
            )}
        </MaterialTabs>
    )
}

export default Tabs
