import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import {Dialog, DialogContent} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import RegistrationForm, {RegistrationFormType} from "./RegistrationForm";
import {
    Link,
    RouteComponentProps,
    useHistory,
    withRouter
} from "react-router-dom";
import "./RegistrationModal.scss";
import {EventType} from "../../util/EventType";
import BackLink from "../common/BackLink";
import AmericasWalkingClubWaiver from "../common/AmericasWalkingClubWaiver";
import PayerDetailsForm from "./PayerDetailsForm";
import { sendPageView } from "../../withTracker";

interface RegistrationModalProps extends RouteComponentProps {
    open: boolean,
    type: RegistrationFormType,
    eventType: string | null,
    onClose: Function,
    onSuccessfulSubmit: Function,
    postRegisterRedirect?: string
    deputyLeaderInviteUrlFragment?: string;
    id?: string;
}

function RegistrationModal(props: RegistrationModalProps) {
    const { t } = useTranslation('registrationModal');
    const selfGuidedWaiverRef = useRef<HTMLDivElement | null>(null);
    const registrationModalRef = useRef<HTMLDivElement | null>(null);
    const history = useHistory();

    function postSubmit() {
        sendPageView(
            `${history.location.pathname}?step=complete`,
            ` - Optum Event-Specific Member Registration Complete`);
        props.onSuccessfulSubmit();
        if (props.postRegisterRedirect !== undefined) {
            props.history.push(props.postRegisterRedirect);
        }
    }
    
    let id = props.id === undefined ? 'buttonId': props.id;
    
    const isSelfGuidedEvent = props.eventType === EventType.SELF_GUIDED;

    const dialogContentRef = useRef<HTMLDivElement | null>(null);

    function setShowSelfGuidedWaiver(show: boolean) {
        if (selfGuidedWaiverRef !== null
            && selfGuidedWaiverRef.current !== null
            && registrationModalRef !== null
            && registrationModalRef.current !== null) {
            if (show) {
                selfGuidedWaiverRef.current.style.display = "block";
                registrationModalRef.current.style.display = "none";
                dialogContentRef?.current?.scrollTo(0, 0);
            }
            else {
                selfGuidedWaiverRef.current.style.display = "none";
                registrationModalRef.current.style.display = "block";
            }
        }
    }

    useEffect(() => {
        sendPageView(
            `${history.location.pathname}?step=1`,
            ` - Optum Event-Specific Member Registration Step 1`);
    }, []);

    return (
        <Dialog open={props.open}>
            <DialogContent ref={dialogContentRef}>
                <div className={"RegistrationModal_Wrapper"}>
                    <div ref={selfGuidedWaiverRef} style={{display:"none"}}>
                        <BackLink onClick={() => {
                            setShowSelfGuidedWaiver(false)
                        }}>{t('registrationModal.backToRegistration')}</BackLink>
                        <div className={"RegistrationModal_SelfGuidedWaiver_Wrapper"}>
                            <AmericasWalkingClubWaiver/>
                        </div>
                    </div>
                    <div ref={registrationModalRef}>
                        <div className={"RegistrationModal_Close"}>
                            <CloseIcon fontSize="large" className="RegistrationModal_CloseIcon"
                                       onClick={() => props.onClose()}/>
                        </div>
                        {props.type === RegistrationFormType.MEMBER &&
                        <div className={"RegistrationModal_MemberMessage"}>
                            <div>{t('registrationModal.forward')}</div>
                            <div dangerouslySetInnerHTML={{__html: t('registrationModal.pleaseRegisterEvent')}}></div>
                            <div className="RegistrationModal_SigninText">{t('registrationModal.signedUpBefore')}
                                <Link className="RegistrationModal_LoginLink"
                                      to={"/login"}>&gt; {t('registrationModal.signInLinkText')}
                                </Link>
                            </div>
                            {isSelfGuidedEvent && <>
                                <div className="RegistrationModal_SelfGuidedEventText">
                                    {t('registrationModal.selfGuidedGuidelines')}
                                </div>
                            </>}
                        </div>}
                        {props.type === RegistrationFormType.LEADER &&
                        <div className={"RegistrationModal_LeaderRegisterSignin"}>
                            <div dangerouslySetInnerHTML={{__html: t('registrationModal.pleaseRegisterLeader')}}></div>
                            <div className={"RegistrationModal_LeaderRegisteredText"}>{t('registrationModal.alreadyRegistered')}
                                <Link className="RegistrationModal_LoginLink"
                                      to={"/login"}>&gt; {t('registrationModal.signInLinkText')}</Link>
                            </div>
                        </div>
                        }
                        {props.type === RegistrationFormType.PAYER_DETAILS &&
                            <div className={"RegistrationModal_LeaderRegisterSignin"}>
                                <div>{t('registrationModal.forward')}</div>
                                <div dangerouslySetInnerHTML={{__html: t('registrationModal.payerDetailsDetails')}}></div>
                            </div>
                        }
                        {props.type !== RegistrationFormType.PAYER_DETAILS &&
                            <RegistrationForm postSubmit={postSubmit}
                                              id={id}
                                              type={props.type}
                                              eventType={props.eventType}
                                              setShowSelfGuidedWaiver={setShowSelfGuidedWaiver}
                                              deputyLeaderInviteUrlFragment={props.deputyLeaderInviteUrlFragment}/>
                        }
                        {props.type === RegistrationFormType.PAYER_DETAILS &&
                            <PayerDetailsForm postSubmit={postSubmit}
                                                    id={"PayerDetailsDetails"} />
                        }
                    </div>
                </div>
            </DialogContent>
        </Dialog>);
}


export default withRouter(RegistrationModal);
