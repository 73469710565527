import React from "react"

const InviteIcon = ({ className, isWhite }: { className: string, isWhite?: boolean }) => (
    <svg className={className} width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_664_55961)">
            <path d="M2.5293 23.3574V59.5061H58.8267V23.3574L32.0782 46.0036C31.2698 46.6892 30.0861 46.6892 29.2778 46.0036L2.5293 23.3574Z" fill={isWhite ? "#fff" : "#555555"} />
            <path d="M3.56307 57.6955V28.5182L9.21446 32.7472C9.37325 32.9565 9.58978 33.1225 9.84239 33.2163L29.8135 48.09C30.326 48.4725 31.0261 48.4725 31.5385 48.09L51.5169 33.1946C51.7623 33.1008 51.9788 32.9348 52.1448 32.7256L57.789 28.5182V57.6811L3.56307 57.6955ZM8.9041 21.9221V28.9151L4.45805 25.6026L8.9041 21.9221ZM49.5681 10.3753V31.0513L30.6796 45.1311L11.7911 31.0513V10.3753H49.5681ZM30.6796 3.88023L35.0102 7.4886H26.3491L30.6796 3.88023ZM56.9012 25.5882L52.4552 28.9007V21.9221L56.9012 25.5882ZM60.676 59.1244V25.6459C60.676 25.4439 60.6399 25.249 60.5533 25.0614L60.51 24.982C60.4812 24.917 60.4451 24.8521 60.4018 24.7944L60.3296 24.7078L60.1492 24.5273L52.4552 18.1622V8.93195C52.4552 8.54946 52.3036 8.18141 52.0293 7.91439C51.7551 7.64737 51.387 7.4886 51.0117 7.4886H39.5573L31.6179 0.91415C31.0838 0.473929 30.3115 0.473929 29.7774 0.91415L21.802 7.4886H10.3476C9.55369 7.4886 8.9041 8.13089 8.9041 8.93195V18.1622L1.20291 24.5346L1.02247 24.715L0.950295 24.8016C0.914207 24.8593 0.870901 24.9243 0.84203 24.9892L0.798725 25.0686C0.719331 25.249 0.676025 25.4511 0.676025 25.6531V59.1388C0.676025 59.5213 0.827595 59.8894 1.10186 60.1564C1.37613 60.4234 1.73701 60.5822 2.11955 60.5822H59.2325C59.6223 60.5822 59.9904 60.4306 60.2574 60.1564C60.5245 59.8822 60.6833 59.5141 60.676 59.1244Z" fill={isWhite ? "#fff" : "#555555"} />
            <path d="M29.2345 33.1088H32.1215V27.8766H37.3543V24.99H32.1215V19.7578H29.2345V24.99H24.0017V27.8766H29.2345V33.1088Z" fill={isWhite ? "#fff" : "#555555"} />
        </g>
        <defs>
            <clipPath id="clip0_664_55961">
                <rect width="60" height="60" fill="white" transform="translate(0.676758 0.582031)" />
            </clipPath>
        </defs>
    </svg>
)

export default InviteIcon
