import { IActivityItem, IActivityRecord, IUser } from '../../types/dashboard'
import { ACTIVITY_DATE_RECORD_TYPE, ACTIVITY_MONTH_STATUS, CLUB_ROLES, MONTH_KEYS } from '../../constants/dashboard'
import { memberDashboardConfig } from '../../configs/memberDashboard'
import { IClub } from '../../types/clubFinder'
import { getDateObjFromString, getFormattedZonedDate } from './date.utils'

const firstDateOfMonth = 1
const monthShiftInJs = 1

interface IEvent {
    start: Date
    end: Date
}

export const sortByTextField = <T>(data: T[], isAsc: boolean, field: keyof T): T[] => (
    [...data]
        .sort((prev: T, next: T) => {
            const prevFieldValue = prev[field] as unknown as string
            const nextFieldValue = next[field] as unknown as string

            if (!prevFieldValue) return 1
            return (prevFieldValue.localeCompare(nextFieldValue)) * Number(isAsc ? 1 : -1)
        }))

export const sortByNumberField = <T>(data: T[], isAsc: boolean, field: keyof T): T[] => (
    [...data]
        .sort((prev: T, next: T) => {
            const prevFieldValue = prev[field] as unknown as number
            const nextFieldValue = next[field] as unknown as number
            if (!prevFieldValue) return isAsc ? -1 : 1
            if (!nextFieldValue) return isAsc ? 1 : -1

            return (prevFieldValue - nextFieldValue) * Number(isAsc ? 1 : -1)
        }))


export const sortByDateField = <T>(data: T[], isAsc: boolean, field: keyof T): T[] => (
    [...data]
        .sort((prev: T, next: T) => {
            const prevFieldValue = new Date(prev[field] as unknown as string).getTime()
            const nextFieldValue = new Date(next[field] as unknown as string).getTime()
            if (isNaN(prevFieldValue)) return 1
            if (isNaN(nextFieldValue)) return -1

            return (prevFieldValue - nextFieldValue) * Number(isAsc ? 1 : -1)
        }))

export const formatActivityRecords = (arr: any): IActivityItem[] => arr.map(({ userRecord, ...rest }: any) => ({ ...userRecord, ...rest }))

export const differenceInMonths = (date1: Date, date2: Date) => {
    const monthDiff = date1.getMonth() - date2.getMonth();
    const yearDiff = date1.getFullYear() - date2.getFullYear();

    return monthDiff + yearDiff * 12 + monthShiftInJs
}

export const getOldestActivityDate = (records: IActivityRecord[]) => records.reduce((prevDate: Date, nextRecord: IActivityRecord) => {
    const nextDate = new Date(`${nextRecord.month}/${firstDateOfMonth}/${nextRecord.year}`)
    return prevDate.getTime() - nextDate.getTime() > 0 ? nextDate : prevDate
}, new Date)

export const getSubtractedDate = (monthsAnount: number) => {
    const date = new Date
    date.setDate(firstDateOfMonth)
    date.setMonth(date.getMonth() - monthsAnount)
    return date
}

export const getMonths = ({
    fixedAmount,
    records,
    reversedOrder = true,
    includeCurrentMonth = false,
    maxReportingPeriod = memberDashboardConfig.DEFAULT_MAX_REPORTING_PERIOD
}: {
    fixedAmount?: number,
    records: IActivityRecord[],
    includeCurrentMonth?: boolean,
    reversedOrder?: boolean,
    maxReportingPeriod?: number,
}) => {
    const date = new Date
    date.setDate(firstDateOfMonth)
    const result = []
    const dynamicAmount = Math.max(differenceInMonths(date, getOldestActivityDate(records)), maxReportingPeriod)
    const amount = fixedAmount || dynamicAmount
    let initialAmount = amount

    const thresholdDate = new Date
    thresholdDate.setDate(firstDateOfMonth)
    thresholdDate.setMonth(thresholdDate.getMonth() - maxReportingPeriod)

    while (initialAmount) {
        if (!includeCurrentMonth || initialAmount !== amount) {
            date.setMonth(date.getMonth() - monthShiftInJs)
        }

        let month = date.getMonth()
        const year = date.getFullYear()

        const foundRecord = records.find(record => record.year === year && record.month === month + monthShiftInJs)
        const activityTotal = foundRecord && foundRecord.activityTotal
        const activityTotalInPerson = foundRecord && foundRecord.activityTotalInPerson
        const activityTotalVirtual = foundRecord && foundRecord.activityTotalVirtual
        const income = foundRecord && foundRecord.income
        const status = foundRecord ? foundRecord.status : ACTIVITY_MONTH_STATUS.REPORTED

        const isOutOfMaxReportingPeriod = differenceInMonths(date, thresholdDate) < 0

        const monthData = {
            month: month + monthShiftInJs,
            year,
            name: MONTH_KEYS[month],
            activityTotal,
            activityTotalInPerson,
            activityTotalVirtual,
            key: `${month + monthShiftInJs}${year}`,
            income: income ?? 0,
            status,
            isOutOfMaxReportingPeriod,
            date: new Date(date)
        }

        result.push(monthData)

        --initialAmount
    }

    return reversedOrder ? result.reverse() : result
}

export const applyMaxMinChecking = (value: string) => {
    let currentVal: string | number = value
    currentVal = currentVal.replace(/\D/g, '')

    if (currentVal !== "") {
        currentVal = parseInt(currentVal)

        if (currentVal > memberDashboardConfig.MAX_ACTIVITY_PER_MONTH) {
            currentVal = memberDashboardConfig.MAX_ACTIVITY_PER_MONTH
        } else if (currentVal < memberDashboardConfig.MIN_ACTIVITY_PER_MONTH) {
            currentVal = memberDashboardConfig.MIN_ACTIVITY_PER_MONTH
        }
    }

    return currentVal
}

// function for converting server role for mi slider value and vice versa
export const convertRole = (role: CLUB_ROLES) => {
    if (role === CLUB_ROLES.MEMBER) return role

    return role === CLUB_ROLES.ADMIN ? CLUB_ROLES.LEADER : CLUB_ROLES.ADMIN
}


export const goThroughArr = <T>(arr: T[], item: T, isForward: boolean) => {
    const currentIndex = arr.indexOf(item)
    let nextIndex

    if (isForward) {
        nextIndex = (currentIndex + 1 >= arr.length) ? 0 : currentIndex + 1
    } else {
        nextIndex = (currentIndex - 1 >= 0) ? currentIndex - 1 : arr.length - 1
    }
    return nextIndex
}

//  cross browser support. Copied from https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript/33928558#33928558
export const copyToClipboard = (text: string) => {
    function listener(this: Document, e: ClipboardEvent) {
        e?.clipboardData?.setData("text/html", text);
        e?.clipboardData?.setData("text/plain", text);
        e.preventDefault();
    }
    const clipBoardElem = document.createElement("input");
    document.body.appendChild(clipBoardElem);
    clipBoardElem.value = text;
    clipBoardElem.select();
    document.addEventListener("copy", listener);
    document.execCommand('copy');
    document.removeEventListener("copy", listener);
    document.body.removeChild(clipBoardElem)
}

export const getClubLeaderData = (club: IClub) => {
    if (!club) return null

    const { primaryClubLeaders = [], secondaryClubLeaders = [] } = club
    const clubLeader = primaryClubLeaders[0] || secondaryClubLeaders[0]

    return clubLeader ? `${clubLeader.firstName} ${clubLeader.lastName}` : null
}

export const getFormattedUsers = (filteredMembersActivity: IUser[]): IUser[] => {
    const getFullName = (user: IUser) => {
        const { lastName, firstName } = user
        if (firstName && lastName) return `${firstName} ${lastName}`
        if (!firstName && !lastName) return user.email
        return String(firstName || lastName)
    }

    return filteredMembersActivity.map((user: IUser) => {
        const fullName = getFullName(user)
        return {
            ...user,
            activityTotalInPerson: user.activityTotalInPerson || 0,
            activityTotalVirtual: user.activityTotalVirtual || 0,
            role: user.type !== undefined ? CLUB_ROLES[Number(user.type)] : '------------',
            fullName: user.fullName || fullName,
            lastActivity: user.lastActivity ?
                getFormattedZonedDate({ month: 'numeric', day: 'numeric', year: 'numeric' }, user.lastActivity) : '------------',
        }
    })
}

export const getEvents = ({ currentMonthRecords }: { currentMonthRecords: IActivityRecord[] }) => {
    const events = [] as IEvent[]
    let event = {} as IEvent
    const dailyRecords = currentMonthRecords.filter(record => record.activityDateRecordType === ACTIVITY_DATE_RECORD_TYPE.DAILY_RECORD)

    dailyRecords.forEach((record, index) => {
        const prevIndex = index - 1
        const currentDay = record.day
        const prevDay = dailyRecords[prevIndex]?.day
        const isNear = currentDay - prevDay === 1

        if (index === 0) {
            event.start = getDateObjFromString(record.activityDate)
        } else if (!isNear) {
            event.end = getDateObjFromString(dailyRecords[prevIndex].activityDate)
            events.push(event)
            event = { start: getDateObjFromString(record.activityDate) } as IEvent
        }
        if (index === dailyRecords.length - 1) {
            if (event) {
                event.end = getDateObjFromString(record.activityDate)
                events.push(event)
            }
        }
    })

    return events
}

export const createDate = ({ date, month, year }:
    { date?: number | string, month?: number | string, year?: number | string } = {}): Date => {

    const newDate = new Date()

    if (date) newDate.setDate(Number(date))
    if (month !== undefined) newDate.setMonth(Number(month))
    if (year) newDate.setFullYear(Number(year))

    return newDate
}

export const isNumber = (value:string) => {
    return +value === +value;
}
