import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

export function TermsOfUseCheckbox(props: RegistrationInputProps): JSX.Element {
    const {t} = useTranslation('pages');
    const inputConfig: InputConfig<boolean> = {
        name: 'termsOfUse',
        initialValue: false,
        validate: function (value) {
            return !value ? " " : "";
        }
    };

    const [,{checkbox}] = useSubFormInput(inputConfig);

    return (
        <div className={`RegistrationWizard_TermsOfUseCheckbox ${props.className || ''}`}>
            <Checkbox {...checkbox(inputConfig)} style={{paddingLeft: 0}}/>
            <FormLabel>
                {t('registration.acceptTOS.label')}&nbsp;
                <Link to={{pathname: "https://hellogrouper.com/app-terms-of-use"}}
                      target="_blank"
                      className={"RegistrationWizard_TermsOfUseLink"}
                >{t('registration.acceptTOS.link')}</Link>
            </FormLabel>
        </div>
    )
}