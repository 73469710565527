import React from "react";
import { useTranslation } from 'react-i18next';

import "./EventImportWizard.tsx";
import "./ImportedEventPreview.scss";
import {IImportedEvent, IValidation} from "./EventImportWizard";
import ImportedFieldPreview from "./ImportedFieldPreview";

export default function ImportedEventPreview(props: { EventIndex: number, ImportedEvent: IImportedEvent }): JSX.Element {
    const { t } = useTranslation('eventImportWizard');
    let e: IImportedEvent = props.ImportedEvent;
    let eventIndex: number = props.EventIndex;

    let hasErrors = false;
    let errorMessages: string[] = [];
    e.validationResults.forEach(function (validation: IValidation) {
        if (!validation.success) {
            hasErrors = true;
            if (validation.message) {
                errorMessages.push(validation.message);
            }
        }
    });

    let indexClassName = " IndexColumn IndexColumnValid ";
    if (hasErrors) {
        indexClassName = " IndexColumn IndexColumnInvalid ";
    }

    return (
        <div>
            {!!errorMessages.length && (
              <div className="Bold DarkRedForeground">
                  <div>{t('eventImportReview.errors')}</div>
                  <ul>
                      {errorMessages.map((message, index) => {
                          return <li key={index} className="ErrorMessage">{message}</li>
                      })}
                  </ul>
              </div>
            )}
            <table>
                <tbody>
                <tr>
                    <td className={indexClassName}>{eventIndex + 1}.</td>
                    <td className="LabelColumn"><label htmlFor={e.id + "eventName"}>
                        {t('importedEventPreview.eventName')}</label></td>
                    <td><ImportedFieldPreview FieldName="eventName" DisplayName="Event Name"
                                              ImportedEvent={e} Post="" Pre=""
                                              Value={e.eventName}/></td>
                </tr>
                <tr>
                    <td/>
                    <td className="LabelColumn"><label htmlFor={e.id + "clubName"}>{t('importedEventPreview.clubName')}</label>
                    </td>
                    <td><ImportedFieldPreview FieldName="clubName" DisplayName="Club Name"
                                              ImportedEvent={e} Post="" Pre=""
                                              Value={e.clubName}/></td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.dateTime')}</label></td>
                    <td>
                        <div><ImportedFieldPreview FieldName="eventStartDate"
                                                   DisplayName="Event Start Date" ImportedEvent={e}
                                                   Post="" Pre=""
                                                   Value={e.eventStartDate}/>
                            {e.eventEndDate != null &&
                            <ImportedFieldPreview FieldName="eventEndDate"
                                                  DisplayName="Event End Date" ImportedEvent={e}
                                                  Pre=" - " Post=""
                                                  Value={e.eventEndDate}/>}
                        </div>
                        <div><ImportedFieldPreview FieldName="eventStartTime"
                                                   DisplayName="Event Start Time" ImportedEvent={e}
                                                   Post="" Pre=""
                                                   Value={e.eventStartTime}/>
                            <ImportedFieldPreview FieldName="eventEndTime"
                                                  DisplayName="Event End Time" ImportedEvent={e}
                                                  Post=""
                                                  Pre=" - "
                                                  Value={e.eventEndTime}/>
                            <ImportedFieldPreview FieldName="timeZone" ImportedEvent={e}
                                                  DisplayName="Time Zone"
                                                  Post="" Pre="&nbsp;"
                                                  Value={e.timeZone}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.address')}</label></td>
                    <td>
                        <ImportedFieldPreview FieldName="venueName" ImportedEvent={e} Post="" Pre=""
                                              DisplayName="Venue Name"
                                              Value={e.venueName}/>
                        <ImportedFieldPreview FieldName="eventStreetAddress" ImportedEvent={e}
                                              DisplayName="Event Street Address"
                                              Post="" Pre=""
                                              Value={e.eventStreetAddress}/>
                        <ImportedFieldPreview FieldName="eventStreetAddress2" ImportedEvent={e}
                                              DisplayName="Event Street Address 2"
                                              Post="" Pre=""
                                              Value={e.eventStreetAddress2}/>
                        <div><ImportedFieldPreview FieldName="eventCity" ImportedEvent={e}
                                                   DisplayName="Event City"
                                                   Post="&nbsp;" Pre=""
                                                   Value={e.eventCity}/>
                            <ImportedFieldPreview FieldName="eventState" ImportedEvent={e}
                                                  DisplayName="Event Stae"
                                                  Post="&nbsp;" Pre=""
                                                  Value={e.eventState}/>
                            <ImportedFieldPreview FieldName="eventZIP" ImportedEvent={e} Post=""
                                                  DisplayName="Event ZIP"
                                                  Pre=""
                                                  Value={e.eventZip}/>
                            <ImportedFieldPreview FieldName="venueCounty" ImportedEvent={e} Post=""
                                                  DisplayName="Venue County"
                                                  Pre=""
                                                  Value={e.venueCounty}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.eventLeader')}</label></td>
                    <td>
                        <ImportedFieldPreview FieldName="eventLeaderFirstName" ImportedEvent={e}
                                              DisplayName="Event Leader First Name"
                                              Post="&nbsp;" Pre=""
                                              Value={e.eventLeaderFirstName}/>
                        <ImportedFieldPreview FieldName="eventLeaderLastName" ImportedEvent={e}
                                              DisplayName="Event Leader Last Name"
                                              Post="&nbsp;" Pre=""
                                              Value={e.eventLeaderLastName}/>
                        <ImportedFieldPreview FieldName="eventLeaderEmail" ImportedEvent={e} Post=""
                                              DisplayName="Event Leader Email"
                                              Pre=""
                                              Value={e.eventLeaderEmail}/>
                        <ImportedFieldPreview FieldName="eventLeaderPhone" ImportedEvent={e} Post=""
                                              DisplayName="Event Leader Phone"
                                              Pre=""
                                              Value={e.eventLeaderPhone}/>
                    </td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.description')}</label></td>
                    <td><ImportedFieldPreview FieldName="classDescription" ImportedEvent={e} Pre=""
                                              DisplayName="Event Description"
                                              Post=""
                                              Value={e.classDescription}/></td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.additionalInfo')}</label></td>
                    <td><ImportedFieldPreview FieldName="importantDetails" ImportedEvent={e} Pre=""
                                              DisplayName="Important Details"
                                              Post=""
                                              Value={e.importantDetails}/></td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.eventType')}</label></td>
                    <td><ImportedFieldPreview FieldName="eventType1" ImportedEvent={e} Pre=""
                                              DisplayName="Event Type"
                                              Post=""
                                              Value={e.eventType1}/></td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.paidEvent')}</label></td>
                    <td><ImportedFieldPreview FieldName="isPaid" ImportedEvent={e} Pre=""
                                              DisplayName="Paid Event"
                                              Post=""
                                              Value={e.isPaid}/></td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.virtualEvent')}</label></td>
                    <td><ImportedFieldPreview FieldName="isVirtual" ImportedEvent={e} Pre=""
                                              DisplayName="Virtual Event"
                                              Post=""
                                              Value={e.isVirtual}/></td>
                </tr>
                <tr>
                    <td className="SpacerCell" colSpan={3}/>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.virtualMedium')}</label></td>
                    <td><ImportedFieldPreview FieldName="virtualMedium" ImportedEvent={e} Pre=""
                                              DisplayName="Virtual Medium"
                                              Post=""
                                              Value={e.virtualMedium}/></td>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.virtualUrl')}</label></td>
                    <td><ImportedFieldPreview longValue={true}
                                              FieldName="virtualUrl" ImportedEvent={e} Pre=""
                                              DisplayName="Virtual Url"
                                              Post=""
                                              Value={e.virtualUrl}/></td>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.virtualUrlPasscode')}</label></td>
                    <td><ImportedFieldPreview FieldName="virtualUrlPasscode" ImportedEvent={e}
                                              Pre="" DisplayName="Virtual Url Passcode"
                                              Post=""
                                              Value={e.virtualUrlPasscode}/></td>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.virtualDialInNumber')}</label></td>
                    <td><ImportedFieldPreview FieldName="virtualDialInNumber" ImportedEvent={e}
                                              Pre="" DisplayName="Virtual Dial-In Number"
                                              Post=""
                                              Value={e.virtualDialInNumber}/></td>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.virtualDialInPasscode')}</label></td>
                    <td><ImportedFieldPreview FieldName="virtualDialInPasscode" ImportedEvent={e}
                                              Pre="" DisplayName="Virtual Dial-In Passcode"
                                              Post=""
                                              Value={e.virtualDialInPasscode}/></td>
                </tr>
                <tr>
                    <td/>
                    <td><label>{t('importedEventPreview.attachments')}</label></td>
                    <td>
                        <ImportedFieldPreview FieldName="attachment1FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #1"
                                              Post=""
                                              Value={e.attachment1FileName}/>
                        <ImportedFieldPreview FieldName="attachment2FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #2"
                                              Post=""
                                              Value={e.attachment2FileName}/>
                        <ImportedFieldPreview FieldName="attachment3FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #3"
                                              Post=""
                                              Value={e.attachment3FileName}/>
                        <ImportedFieldPreview FieldName="attachment4FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #4"
                                              Post=""
                                              Value={e.attachment4FileName}/>
                        <ImportedFieldPreview FieldName="attachment5FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #5"
                                              Post=""
                                              Value={e.attachment5FileName}/>
                        <ImportedFieldPreview FieldName="attachment6FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #6"
                                              Post=""
                                              Value={e.attachment6FileName}/>
                        <ImportedFieldPreview FieldName="attachment7FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #7"
                                              Post=""
                                              Value={e.attachment7FileName}/>
                        <ImportedFieldPreview FieldName="attachment8FileName" ImportedEvent={e}
                                              Pre="" DisplayName="File #8"
                                              Post=""
                                              Value={e.attachment8FileName}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )

}
