import React from "react"

const SendToListIcon = ({ className }: { className: string }) => (
    <svg className={className} width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.9944 14.0126H26.5775V7.04243H36.9944V14.0126ZM45.6752 7.04243H43.9391C43.9391 5.19534 43.2099 3.41795 41.9078 2.11104C40.6056 0.804133 38.8348 0.0722656 36.9944 0.0722656H26.5775C22.7406 0.0722656 19.6328 3.19142 19.6328 7.04243H17.8967C12.1413 7.04243 7.47974 11.7212 7.47974 17.4977V27.9529C7.47974 29.8784 9.0336 31.438 10.9521 31.438C12.8705 31.438 14.4244 29.8784 14.4244 27.9529V17.4977C14.4244 15.5722 15.9782 14.0126 17.8967 14.0126H19.6328C19.6328 15.8597 20.362 17.6371 21.6642 18.944C22.9663 20.2509 24.7372 20.9828 26.5775 20.9828H36.9944C38.8348 20.9828 40.6056 20.2509 41.9078 18.944C43.2099 17.6371 43.9391 15.8597 43.9391 14.0126H45.6752C46.5954 14.0126 47.4808 14.3785 48.1319 15.032C48.783 15.6854 49.1476 16.5741 49.1476 17.4977V45.3784C49.1476 46.3019 48.783 47.1906 48.1319 47.8441C47.4808 48.4975 46.5954 48.8634 45.6752 48.8634H42.2029C40.2845 48.8634 38.7306 50.423 38.7306 52.3485C38.7306 54.274 40.2845 55.8336 42.2029 55.8336H45.6752C51.4306 55.8336 56.0922 51.1549 56.0922 45.3784V17.4977C56.0922 11.7212 51.4306 7.04243 45.6752 7.04243Z" fill="black" />
        <path d="M3.83279 46.1404H24.2725L20.682 49.7533C19.4735 50.9341 19.2633 52.8023 20.1566 54.2298C20.7346 55.1286 21.6803 55.7102 22.7312 55.816C23.7821 55.9217 24.833 55.5516 25.5774 54.7938L33.713 46.6075C34.7639 45.55 35.3594 44.1137 35.3594 42.6069C35.3594 41.1 34.7639 39.6725 33.713 38.6062L25.5774 30.4199C24.8418 29.6797 23.8259 29.3008 22.7838 29.3977C21.7504 29.4947 20.8134 30.041 20.2179 30.9046C19.2633 32.385 19.491 34.3324 20.7608 35.5485L24.2638 39.0733H3.83279C1.89741 39.0733 0.329834 40.6506 0.329834 42.598C0.329834 44.5455 1.89741 46.1228 3.83279 46.1228V46.1404Z" fill="black" />
    </svg>
)

export default SendToListIcon
