import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useFormState } from "react-use-form-state";
import { TextField, Button, Modal } from "@material-ui/core";
import FieldStatusIcon from "../../registration/FieldStatusIcon";
import { getElementConfig } from './editMemberFormConfig'
import InputMask from 'react-input-mask';
import CloseIcon from "@material-ui/icons/Close";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocalDateUtils } from "../../../../util/FixedJsJodaUtils";
import {LocalDate, convert, DateTimeFormatter} from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";

import './EditMemberModal.scss'
import getMemberDetails from "../../../../api/getMemberDetails";
import postUpdateMemberDetails from "../../../../api/postUpdateMemberDetails";
import {ClubAssociationManageMemberSanityData} from "../../../../sanity/getClubAssociationDashboardContentViaSanity";

interface IChangeRoleModalProps {
    isOpen: boolean
    onClose: (event: React.MouseEvent) => void
    onConfirm?: (event: React.MouseEvent) => void
    clubId: number,
    editingId?: number,
    fetchMembers: Function
    dashboardManageMembersModalConfiguration?: ClubAssociationManageMemberSanityData|undefined
}

export default function EditMemberModal({
    isOpen,
    onClose,
    editingId,
    clubId,
    fetchMembers,
    dashboardManageMembersModalConfiguration
}: IChangeRoleModalProps) {
    const { t } = useTranslation('ignite/dashboard');
    const [dateOfBirthError, setDateOfBirthError] = useState('')
    const [memberIdError, setMemberIdError] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const initialState = {
        firstName: "",
        lastName: "",
        zipCode: "",
        phoneNumber: "",
        email: "",
        memberId: "",
        dateOfBirth: null
    }

    const [formState, { text }] = useFormState(initialState);
    const elementConfig = getElementConfig(t, formState)
    const dateOfBirthMinDate = LocalDate.of(1900, 1, 1);
    const dateOfBirthMaxDate = LocalDate.now().minusYears(18);
    const memberIdFieldLabel = dashboardManageMembersModalConfiguration === undefined ? t('editMemberModal.memberIdOptional') :
        dashboardManageMembersModalConfiguration.memberIdFieldLabel ? dashboardManageMembersModalConfiguration.memberIdFieldLabel :
            dashboardManageMembersModalConfiguration.memberIdRequired ?
                t('editMemberModal.memberId') : t('editMemberModal.memberIdOptional');

    useEffect(() => {
        if (editingId) {
            getMemberDetails(clubId, editingId).then(data => {
                data.firstName && formState.setField('firstName', data.firstName)
                data.lastName && formState.setField('lastName', data.lastName)
                data.email && formState.setField('email', data.email)
                data.phone && formState.setField('phoneNumber', data.phone)
                data.postalCode && formState.setField('zipCode', data.postalCode)
                data.memberId && formState.setField('memberId', data.memberId)
                data.dateOfBirth && formState.setField('dateOfBirth', LocalDate.parse(data.dateOfBirth, DateTimeFormatter.ISO_LOCAL_DATE))
            })
        }
    }, [editingId])

    const submitEnabled = () => {
        const allValuesValid = Object.entries(formState.validity).every(([key, value]) => value);

        return allValuesValid && !memberIdError;
    }

    useEffect(() => {
        setIsDisabled(false)
        formState.reset()
    }, [isOpen])

    const validateMemberId = (memberId: string) => {
        if (dashboardManageMembersModalConfiguration?.memberIdEnabled && dashboardManageMembersModalConfiguration?.memberIdRequired && !memberId.trim()) {
            setMemberIdError(t('editMemberModal.errors.memberId', { label: memberIdFieldLabel }))
        } else {
            setMemberIdError( '')
        }
    }

    const validateDateOfBirth = (date: LocalDate) => {
        if (date instanceof LocalDate) {
            const isEqualOrAfterMinDate = dateOfBirthMinDate.isBefore(date) || dateOfBirthMinDate.isEqual(date);
            const isEqualOrBeforeMaxDate = dateOfBirthMaxDate.isAfter(date) || dateOfBirthMaxDate.isEqual(date);

            if (!isEqualOrAfterMinDate || !isEqualOrBeforeMaxDate) {
                setDateOfBirthError(t('editMemberModal.errors.dateOfBirth'))
            } else {
                setDateOfBirthError('')
            }
        } else {
            setDateOfBirthError(t('editMemberModal.errors.dateOfBirth'))
        }
    }

    async function handleSubmit(e: React.MouseEvent) {
        const body: object = {
            ...(formState.values.phoneNumber && { Phone: formState.values.phoneNumber.replace(/[^\d]/g, '') }),
            FirstName: formState.values.firstName,
            LastName: formState.values.lastName,
            PostalCode: formState.values.zipCode,
            ...(formState.values.memberId && { MemberId: formState.values.memberId }),
            UserId: editingId
        };
        setIsDisabled(true)
        postUpdateMemberDetails(body, clubId).then(() => {
            onClose(e)
            fetchMembers()
        })
    }

    return (
        <Modal
            open={isOpen}
            className='edit-member-modal'
        >
            <div className="edit-member-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className="title">
                    {t('editMemberModal.title')}
                </div>
                <div className="subtitle">
                    {t('editMemberModal.subtitle')}
                </div>
                <form className='edit-form'>
                    <div className='edit-form__field-wrapper'>
                        <div className='edit-form__field'>
                            <TextField {...text(elementConfig.firstName)}
                                classes={{
                                    root: 'edit-form__field_bold',
                                }}
                                InputLabelProps={{
                                    required: false,
                                    htmlFor: 'first-name-id',
                                    classes: {
                                        root: 'label-root',
                                        error: 'label-error',
                                        focused: 'label-focused'
                                    }
                                }}
                                FormHelperTextProps={{
                                    classes: {
                                        root: 'edit-form__field_error'
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        input: 'edit-form__field_bold',
                                    }
                                }}
                                id='first-name-id'
                                label={t('editMemberModal.firstName')}
                                required
                                variant='outlined'
                                error={formState.errors.firstName !== undefined}
                                helperText={formState.errors.firstName}
                                size='small'
                            />
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon
                                    shouldShow={formState.touched.firstName}
                                    isError={formState.errors.firstName}
                                />
                            </div>
                        </div>
                        <div className='edit-form__field'>
                            <TextField
                                {...text(elementConfig.lastName)}
                                classes={{
                                    root: 'edit-form__field_bold',
                                }}
                                InputLabelProps={{
                                    htmlFor: 'last-name-id',
                                    required: false,
                                    classes: {
                                        root: 'label-root',
                                        error: 'label-error',
                                        focused: 'label-focused'
                                    }
                                }}
                                FormHelperTextProps={{
                                    classes: {
                                        root: 'edit-form__field_error'
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        input: 'edit-form__field_bold'
                                    }
                                }}
                                id='last-name-id'
                                label={t('editMemberModal.lastName')}
                                variant='outlined'
                                error={formState.errors[elementConfig.lastName.name] !== undefined}
                                helperText={formState.errors.lastName}
                                required
                                size='small' />
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon
                                    shouldShow={formState.touched.lastName}
                                    isError={formState.errors.lastName}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='edit-form__field-wrapper'>
                        <div className='edit-form__field email'>
                            <TextField
                                {...text(elementConfig.email)}
                                classes={{
                                    root: 'edit-form__field_bold',
                                }}
                                InputLabelProps={{
                                    htmlFor: 'email-id',
                                    required: false,
                                    classes: {
                                        root: 'label-root',
                                        error: 'label-error',
                                        focused: 'label-focused'
                                    }
                                }}
                                FormHelperTextProps={{
                                    classes: {
                                        root: 'edit-form__field_error'
                                    }
                                }}
                                InputProps={{
                                    readOnly: true,
                                    classes: {
                                        input: 'edit-form__field_bold'
                                    }
                                }}
                                id='email-id'
                                label={t('editMemberModal.email')}
                                error={formState.errors.email !== undefined}
                                helperText={formState.errors.email}
                                variant='outlined'
                                size='small' />
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon shouldShow={formState.touched.email} isError={formState.errors.email} />
                            </div>
                        </div>
                        {(dashboardManageMembersModalConfiguration?.memberIdEnabled === undefined || dashboardManageMembersModalConfiguration?.memberIdEnabled) &&
                            <>
                                <div className="edit-form__field member-id">
                                    <TextField
                                        {...text(elementConfig.memberId)}
                                        classes={{
                                            root: 'edit-form__field_bold',
                                        }}
                                        InputLabelProps={{
                                            htmlFor: 'member-id',
                                            required: false,
                                            classes: {
                                                root: 'label-root',
                                                focused: 'label-focused',
                                                error: 'label-error'
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: 'edit-form__field_error'
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: 'edit-form__field_bold'
                                            }
                                        }}
                                        id='member-id'
                                        label={memberIdFieldLabel}
                                        variant='outlined'
                                        size='small'
                                        error={Boolean(memberIdError)}
                                        helperText={memberIdError}
                                        onChange={memberId => {
                                            formState.setField('memberId', memberId.target.value)
                                            validateMemberId(memberId.target.value)
                                        }}
                                        onBlur={() => {
                                            if (formState.values.memberId) {
                                                validateMemberId(formState.values.memberId)
                                            }
                                        }}
                                    />
                                    <div className='field-indicator-wrapper'>
                                        <FieldStatusIcon
                                            shouldShow={memberIdError || (formState.touched.memberId && formState.values.memberId)}
                                            isError={Boolean(memberIdError)}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='edit-form__field-wrapper dense-row'>
                        <div className="edit-form__field dob">
                            <MuiPickersUtilsProvider utils={LocalDateUtils} locale={Locale.US}>
                                <KeyboardDatePicker
                                    {...text(elementConfig.dateOfBirth)}
                                    value={formState.values.dateOfBirth}
                                    label={t('editMemberModal.dateOfBirth')}
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    views={["year", "month", "date"]}
                                    openTo="year"
                                    disableFuture
                                    required
                                    minDate="1900-01-02"
                                    maxDate={convert(dateOfBirthMaxDate).toDate()}
                                    size="small"
                                    fullWidth
                                    error={Boolean(dateOfBirthError)}
                                    helperText={dateOfBirthError}
                                    readOnly={true}
                                    InputLabelProps={{
                                        htmlFor: 'dob-id',
                                        required: false,
                                        classes: {
                                            root: 'label-root',
                                            focused: 'label-focused',
                                            error: 'label-error'
                                        }
                                    }}
                                    InputProps={{
                                        id: "dob-id",
                                        classes: {
                                            input: 'date-of-birth-input'
                                        }
                                    }}
                                    FormHelperTextProps={{
                                        classes: {
                                            error: 'label-error'
                                        }
                                    }}
                                    onChange={date => {
                                        formState.setField('dateOfBirth', date)
                                        validateDateOfBirth(date)
                                    }}
                                    onBlur={() => {
                                        if (formState.values.dateOfBirth) {
                                            validateDateOfBirth(formState.values.dateOfBirth)
                                        }
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon
                                    shouldShow={dateOfBirthError || (formState.touched.dateOfBirth && formState.values.dateOfBirth)}
                                    isError={Boolean(dateOfBirthError)}
                                />
                            </div>
                        </div>
                        <div className='edit-form__field'>
                            <InputMask
                                {...text(elementConfig.phoneNumber)}
                                mask='(999) 999-9999'
                                maskChar='*'
                            >
                                {(inputProps: object) => (
                                    <TextField
                                        classes={{ root: 'edit-form__field_bold' }}
                                        InputLabelProps={{
                                            htmlFor: 'phone-number-id',
                                            required: false,
                                            classes: {
                                                root: 'label-root',
                                                error: 'label-error',
                                                focused: 'label-focused'
                                            }
                                        }}
                                        FormHelperTextProps={{
                                            classes: {
                                                root: 'edit-form__field_error'
                                            }
                                        }}
                                        InputProps={{
                                            ...inputProps,
                                            classes: {
                                                input: 'edit-form__field_bold'
                                            }
                                        }}
                                        id='phone-number-id'
                                        label={t('editMemberModal.phoneNumber')}
                                        variant='outlined'
                                        error={formState.errors.phoneNumber !== undefined}
                                        helperText={formState.errors.phoneNumber}
                                        required
                                        size='small' />
                                )}
                            </InputMask>
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon shouldShow={formState.touched.phoneNumber} isError={formState.errors.phoneNumber} />
                            </div>
                        </div>
                        <div className='edit-form__field'>
                            <TextField
                                {...text(elementConfig.zipCode)}
                                classes={{ root: 'edit-form__field_bold' }}
                                InputLabelProps={{
                                    htmlFor: 'zip-code-id',
                                    required: false,
                                    classes: {
                                        root: 'label-root',
                                        focused: 'label-focused',
                                        error: 'label-error'
                                    }
                                }}
                                FormHelperTextProps={{
                                    classes: {
                                        root: 'edit-form__field_error'
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        input: 'edit-form__field_bold'
                                    }
                                }}
                                id='zip-code-id'
                                label={t('editMemberModal.zipCode')}
                                variant='outlined'
                                error={formState.errors.zipCode !== undefined}
                                helperText={formState.errors.zipCode}
                                required
                                size='small'
                                inputProps={{
                                    length: 5,
                                    minLength: 5,
                                    maxLength: 5
                                }}
                            />
                            <div className='field-indicator-wrapper'>
                                <FieldStatusIcon
                                    shouldShow={formState.touched.zipCode && formState.values.zipCode}
                                    isError={formState.errors.zipCode}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <div className='edit-form__button-wrapper'>
                    <Button
                        onClick={handleSubmit}
                        disabled={!submitEnabled() || isDisabled}
                        className="edit-form__button "
                        classes={{ disabled: 'disabled' }}
                    >
                        {t('editMemberModal.buttonText')}
                    </Button>
                </div>
            </div >
        </Modal>
    );
}
