import React from "react";
import "./BackLink.scss";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import {Link, RouteComponentProps, withRouter} from "react-router-dom";

export interface BackLinkProps extends RouteComponentProps {
    to?: string;
    onClick?: Function;
    children: any;
    className?: string;
}

function BackLink(props: BackLinkProps): JSX.Element {

    let classes = 'CCBackLink';
    let className = props.className;
    let to = props.to;
    let children = props.children;
    let onClick = props.onClick;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    let isNumber = false;
    if (to !== undefined) {
        isNumber = !isNaN(parseInt(to));
    }

    function clickhandler() {
        if (isNumber && to !== undefined) {
            props.history.go(parseInt(to))
        }
        else if (onClick !== undefined) {
            onClick();
        }
    }

    return (
        <div className={classes}>
            <KeyboardArrowLeft/>
            {!isNumber && to !== undefined && <Link to={to}>{children}</Link>}
            {(isNumber || onClick !== undefined) && <div className={"CCBackLink_Special"} onClick={clickhandler}>{children}</div>}
        </div>
    );
}

export default withRouter(BackLink);