import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../NewMemberRegistrationWizard.scss';
import { useFormState } from 'react-use-form-state';
import { FormControl, Switch, TextField } from '@material-ui/core';
import Button, { ButtonStyles } from '../../../components/common/Button';
import Grid from '@material-ui/core/Grid';
import FieldStatusIcon from '../../../components/ignite/registration/FieldStatusIcon';
import { NewMemberRegistrationWizardSteps } from '../NewMemberRegistrationWizard';
import CustomEnrollmentStepTracker from './CustomEnrollmentStepTracker';
import InfoIcon from '../../../components/icon/InfoIcon';
import InfoModal from '../InfoModal';
import { ClubAssociationEnrollmentConfigurationSanityData } from '../../../sanity/getClubAssociationContentViaSanity';
import Loading from '../../../components/common/Loading';
import { getUserAgreementNullable } from '../../../services/UserAgreementService';
import { UserAgreementModel } from '../../../services/Models';
import { ClubAssociationAgreementSanityData } from '../../../sanity/getClubAssociationAgreementViaSanity';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { Link } from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import useGetCustomEnrollmentInfo from '../../../hooks/useGetCustomEnrollmentInfo';
import GroupWorks from './components/groupworks/GroupWorks';
import { IClubV2 } from '../../../types/clubFinder';
import { SanityTextObject } from '../../../sanity/SanityUtils';
import { GHIN_CLUBS } from '../../../util/CustomEnrollment';

export type MemberAccount = {
  firstName: string;
  lastName: string;
  memberId: string;
  externalActivityProfileUrl: string;
  userReportedActiveMembership: boolean;
};

interface UserAgreementData {
  agreementModel: UserAgreementModel;
  sanityData: ClubAssociationAgreementSanityData;
}

type StepProp = {
  onContinue: (data: any) => void;
  onPrevious: () => void;
  clubShortCode: string;
  associationShortName?: string;
  associationFullName?: string;
  enrollmentConfiguration?: ClubAssociationEnrollmentConfigurationSanityData;
  onGroupWorksSelectedClub: (groupWorksClub: IClubV2 | undefined) => void;
};

export function CustomEnrollmentPersonalInformationStep(
  props: StepProp,
): JSX.Element {
  const { t } = useTranslation('pages');
  const initialState = {
    firstName: '',
    lastName: '',
    memberId: '',
    externalActivityProfileUrl: '',
    userReportedActiveMembership: false,
  };

  const [formState, { text, checkbox }] = useFormState(initialState);
  const [
    customEnrollmentInfo,
    isCustomEnrollmentInfoLoading,
  ] = useGetCustomEnrollmentInfo(props.clubShortCode);
  const [
    keyNamesToEnforceForValidation,
    setKeyNamesToEnforceForValidation,
  ] = useState<string[]>(['firstName', 'lastName']);
  const [
    isOpenExternalActivityInfoModal,
    setIsOpenExternalActivityInfoModal,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [userAgreements, setUserAgreements] = useState<UserAgreementData[]>();
  const [groupworksClub, setGroupWorksClub] = useState<IClubV2 | undefined>(undefined);
  const [infoModalData, setInfoModalData] = useState<SanityTextObject[]>([]);

  useEffect(() => {
    (async () => {
      if (
        props.enrollmentConfiguration?.userAgreementsToShow &&
        props.enrollmentConfiguration.userAgreementsToShow.length > 0
      ) {
        for (const agreement of props.enrollmentConfiguration
          .userAgreementsToShow) {
          const agreementModel = await getUserAgreementNullable({
            slug: agreement.slug,
          });
          if (agreementModel && agreementModel.isValid) {
            const combinedAgreementData: UserAgreementData = {
              agreementModel: agreementModel,
              sanityData: agreement,
            };
            setUserAgreements((prevState) => {
              if (prevState) {
                return [...prevState, combinedAgreementData];
              } else {
                return [combinedAgreementData];
              }
            });

            formState.setField(agreementModel.slug, false);
          }
        }
      }

      if (props.enrollmentConfiguration?.memberIdRequired) {
        setKeyNamesToEnforceForValidation((prevState) => [
          ...prevState,
          'memberId',
        ]);
      }

      setIsLoading(false);
    })();
  }, [formState, props.enrollmentConfiguration]);

  // Pre-populate form with data from custom enrollment info
  useEffect(() => {
    customEnrollmentInfo?.firstName &&
      formState.setField('firstName', customEnrollmentInfo?.firstName);
    customEnrollmentInfo?.lastName &&
      formState.setField('lastName', customEnrollmentInfo?.lastName);
    customEnrollmentInfo?.memberId &&
      formState.setField('memberId', customEnrollmentInfo?.memberId);
    customEnrollmentInfo?.externalActivityProfileUrl &&
      formState.setField(
        'externalActivityProfileUrl',
        customEnrollmentInfo?.externalActivityProfileUrl,
      );
    customEnrollmentInfo?.userReportedActiveMembership &&
      formState.setField(
        'userReportedActiveMembership',
        customEnrollmentInfo?.userReportedActiveMembership,
      );

    if (customEnrollmentInfo?.agreementsAgreedTo) {
      for (const agreement of customEnrollmentInfo?.agreementsAgreedTo) {
        formState.setField(agreement, true);
      }
    }
  }, [formState, customEnrollmentInfo]);

  const validateExternalActivityProfileUrl = (value: string) => {
    if (value.trim() === '') {
      return '';
    }

    try {
      new URL(value);
    } catch (error) {
      return t('customEnrollment.externalActivityProfileUrlError');
    }

    return '';
  };

  const elementConfig: Record<string, any> = {
    firstName: {
      name: 'firstName',
      validate: (value: string) => {
        if (!value.trim()) {
          return t('newMemberRegistration.firstNameError');
        }
      },
      validateOnBlur: true,
    },
    lastName: {
      name: 'lastName',
      validate: (value: string) => {
        if (!value.trim()) {
          return t('newMemberRegistration.lastNameError');
        }
      },
      validateOnBlur: true,
    },
    externalActivityProfileUrl: {
      name: 'externalActivityProfileUrl',
      validate: validateExternalActivityProfileUrl,
      validateOnBlur: true,
    },
    userReportedActiveMembership: {
      name: 'userReportedActiveMembership',
    },
    memberId: {
      name: 'memberId',
      validate: (value: string) => {
        if (props.enrollmentConfiguration?.memberIdRequired) {
          if (value.trim()) {
            return;
          }
          const {memberIdFieldLabel} = props.enrollmentConfiguration;
          return memberIdFieldLabel && memberIdFieldLabel.length > 0
            ? t('customEnrollment.memberIdCustomError', { memberIdFieldLabel })
            : t('newMemberRegistration.memberIdError');
        }
      },
      validateOnBlur: true,
    }
  };

  const continueEnabled = () => {
    const allFieldsValid = Object.values(elementConfig).every((field) => {
      if (
        keyNamesToEnforceForValidation.includes(field.name) &&
        field.validate
      ) {
        return !field.validate(formState.values[field.name]);
      }
      return true;
    });

    // Validate agreements based on requiredForFormSubmission
    let agreementsValidated = true;
    userAgreements?.map((agreement) => {
      if (
        !formState.values[agreement.agreementModel.slug] &&
        agreement.sanityData.requiredForEnrollmentFlow
      ) {
        agreementsValidated = false;
      }
    });

    const isGroupWorks = props.clubShortCode.toLowerCase() === 'groupworks';
    let isGroupWorksCheckValid = true;
    if(isGroupWorks) {
      if(!groupworksClub){
        isGroupWorksCheckValid = false;
      }
    }
    return (
      isGroupWorksCheckValid &&
      allFieldsValid &&
      agreementsValidated &&
      validateExternalActivityProfileUrl(
        formState.values.externalActivityProfileUrl,
      ) === ''
    );
  };

  const onContinueSelected = () => {
    setSaving(true);

    const agreementResponses = (userAgreements ?? []).map(
      ({ agreementModel }): { AgreementSlug: string; AgreedTo: boolean } => ({
        AgreementSlug: agreementModel.slug,
        AgreedTo: formState.values[agreementModel.slug],
      }),
    );

    props.onContinue({
      firstName: formState.values.firstName,
      lastName: formState.values.lastName,
      memberId: formState.values.memberId,
      externalActivityProfileUrl: formState.values.externalActivityProfileUrl,
      userReportedActiveMembership:
        formState.values.userReportedActiveMembership,
      userAgreements: agreementResponses,
    });
  };

  const onExternalActivityInfoButtonClick = () => {
    setIsOpenExternalActivityInfoModal(true);
  };

  const onMemberIdInfoButtonClick = () =>
    setInfoModalData(props.enrollmentConfiguration?.memberIdHelpOverlayContent ?? []);

  if (isLoading || isCustomEnrollmentInfoLoading) {
    return <Loading className="PageLoading" loading={isLoading} />;
  }

  return (
    <div className={'NewMemberRegistrationWizard_Wrapper NewMemberAccountStep'}>
      {props.enrollmentConfiguration
        ?.externalActivityTrackerProfileUrlHelpOverlay && (
        <InfoModal
          isOpen={isOpenExternalActivityInfoModal}
          setIsOpenInfoModal={setIsOpenExternalActivityInfoModal}
          modalContent={
            props.enrollmentConfiguration
              .externalActivityTrackerProfileUrlHelpOverlay
          }
        />
      )}
      <InfoModal
        isOpen={infoModalData.length > 0}
        setIsOpenInfoModal={() => setInfoModalData([])}
        modalContent={infoModalData}
      />
      <CustomEnrollmentStepTracker
        currentStep={NewMemberRegistrationWizardSteps.PersonalInformation}
      />
      <h2>{t('customEnrollment.personalInformationHeaderText')}</h2>
      <div className={'NewMemberRegistrationWizard_Wrapper_InnerContainer'}>
        <Grid container spacing={2} direction="row" justify="flex-start">
          <Grid item xs={12}>
            <span className={'NewMemberRegistrationWizard_AccountNote'}>
              {t('customEnrollment.fieldsRequiredText')}
            </span>
          </Grid>
          <Grid item xs={11} sm={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...text(elementConfig.firstName)}
                className="MemberRegistrationWizard_Input"
                label={t('newMemberRegistration.firstNamePlaceholder')}
                required
                variant={'outlined'}
                error={formState.errors.firstName !== undefined}
                helperText={formState.errors.firstName}
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.firstName}
                isError={formState.errors.firstName}
              />
            </div>
          </Grid>
          <Grid item xs={11} sm={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...text(elementConfig.lastName)}
                className="MemberRegistrationWizard_Input"
                label={t('newMemberRegistration.lastNamePlaceholder')}
                variant="outlined"
                error={formState.errors.lastName !== undefined}
                helperText={formState.errors.lastName}
                required
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.lastName}
                isError={formState.errors.lastName}
              />
            </div>
          </Grid>
          {props.enrollmentConfiguration?.memberIdEnabled && GHIN_CLUBS.includes(props.clubShortCode) && <>
          <Grid item xs={11} md={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...text(elementConfig.memberId)}
                className="MemberRegistrationWizard_Input"
                label={`${props.enrollmentConfiguration.memberIdFieldLabel}`}
                variant={'outlined'}
                required={props.enrollmentConfiguration.memberIdRequired}
                error={formState.errors.memberId !== undefined}
                helperText={formState.errors.memberId}
                size="small"
              />
              {props.enrollmentConfiguration.memberIdHelpOverlayContent && (
                <div
                  className={
                    'CustomEnrollmentPersonalInformationStep_MemberIdInfoContainer'
                  }
                >
                  <InfoIcon
                    className={
                      'CustomEnrollmentPersonalInformationStep_MemberId_InfoButton'
                    }
                    onClick={onMemberIdInfoButtonClick}
                  />
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.memberId}
                isError={formState.errors.memberId}
              />
            </div>
          </Grid>
          </>}
          {props.enrollmentConfiguration?.askIfCurrentMemberEnabled && (
            <Grid item xs={12}>
              <label htmlFor="switch-user-reported-active-membership">
                {t('customEnrollment.userReportedActiveMembershipLabel')}
              </label>
              <div className="switch-wrapper">
                {t('customEnrollment.userReportedActiveMembershipNoLabel')}
                <Switch
                  {...checkbox(elementConfig.userReportedActiveMembership)}
                  className="switch"
                  id="switch-user-reported-active-membership"
                />
                {t('customEnrollment.userReportedActiveMembershipYesLabel')}
              </div>
            </Grid>
          )}
          {props.enrollmentConfiguration
            ?.askForExternalActivityTrackerProfileUrl && (
            <>
              <Grid item xs={11} sm={5}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    {...text(elementConfig.externalActivityProfileUrl)}
                    className="MemberRegistrationWizard_Input"
                    label={t(
                      'customEnrollment.externalActivityProfileUrlPlaceholder',
                    )}
                    variant="outlined"
                    error={
                      formState.errors.externalActivityProfileUrl !== undefined
                    }
                    helperText={formState.errors.externalActivityProfileUrl}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <div className="field-indicator-wrapper">
                  <FieldStatusIcon
                    shouldShow={formState.touched.externalActivityProfileUrl}
                    isError={formState.errors.externalActivityProfileUrl}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <div
                  className={
                    'CustomEnrollmentPersonalInformationStep_ExternalActivityProfileUrlNotes'
                  }
                >
                  <div>
                    {t('customEnrollment.externalActivityProfileUrlNote')}
                  </div>
                  {props.enrollmentConfiguration!
                    .externalActivityTrackerProfileUrlHelpOverlay && (
                    <div>
                      {t('customEnrollment.findOutMore')}
                      <InfoIcon
                        className={
                          'CustomEnrollmentPersonalInformationStep_ExternalActivityProfileUrlNotes_InfoButton'
                        }
                        onClick={onExternalActivityInfoButtonClick}
                      />
                    </div>
                  )}
                </div>
              </Grid>
            </>
          )}
          {props.clubShortCode === 'groupworks' && <GroupWorks onGroupSelected={
            (selected: IClubV2 | undefined) => {
              props.onGroupWorksSelectedClub(selected);
              setGroupWorksClub(selected);
            }
          } />}
          {userAgreements && <br />}
          {userAgreements &&
            userAgreements.map(({ agreementModel, sanityData }, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <FormControl>
                    <div
                      className={
                        'NewMemberRegistrationWizard_Wrapper_TermsAndAgreements'
                      }
                    >
                      <Checkbox
                        name={agreementModel.slug}
                        required={sanityData.requiredForEnrollmentFlow}
                        checked={formState.values[agreementModel.slug]}
                        onChange={(e) => {
                          formState.setField(
                            agreementModel.slug,
                            e.target.checked,
                          );
                        }}
                        style={{ paddingLeft: 0 }}
                      />
                      <FormLabel>
                        {sanityData.checkboxText}{' '}
                        {sanityData.requiredForEnrollmentFlow && '*'}{' '}
                        {sanityData.content && (
                          <Link
                            to={`/p/${props.clubShortCode}/agreements/${agreementModel.slug}`}
                            target="_blank"
                          >
                            <LaunchIcon
                              className={
                                'NewMemberRegistrationWizard_ExternalLink_Icon'
                              }
                            />
                          </Link>
                        )}
                      </FormLabel>
                    </div>
                  </FormControl>
                </Grid>
              );
            })}
        </Grid>
        <div className={'NewMemberAccountStep_NextContainer'}>
          {!saving ? (
            <Button
              type="button"
              id="AccountSubmit"
              clickHandler={onContinueSelected}
              text={t('newMemberRegistration.nextButton')}
              className="NewMemberRegistrationWizard_SubmitButton"
              buttonStyle={ButtonStyles.UnfilledWithBorder}
              disabled={!continueEnabled()}
            />
          ) : (
            <Loading
              className={'NewMemberRegistrationWizard_SubmitButtonLoading'}
              loading={saving}
            />
          )}
        </div>
      </div>
    </div>
  );
}
