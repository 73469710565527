import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';

import "./ClubLeaderTermsOfUsePage.scss";

export default function ClubLeaderTermsOfUsePage(): JSX.Element {
    const { t } = useTranslation('pages');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: t('clubLeaderTermsOfUsePage.content')}}></div>
        </div>
    )
}