import React from 'react';
import { usePagination, DOTS } from '../../../hooks/usePagination';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import './Pagination.scss'

const Pagination = (props: any) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange: (string | number)[] | undefined = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (!paginationRange) return null

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <div className={className}>
            <ArrowBackIcon
                className={`pagination-icon ${1 === currentPage ? 'disabled' : ''}`}
                onClick={onPrevious}
            />
            <div className='pagination-list'>
                {paginationRange.map((pageNumber: any) => {
                    if (pageNumber === DOTS) {
                        return <span key={pageNumber} className="pagination-item dots">&#8230;</span>;
                    }

                    return (
                        <span
                            key={pageNumber}
                            className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
                            onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </span>
                    );
                })}
            </div>
            <ArrowForwardIcon
                className={`pagination-icon ${lastPage === currentPage ? 'disabled' : ''}`}
                onClick={onNext}
            />
        </div>
    );
};

export default Pagination;
