import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    RouteComponentProps,
    withRouter
} from "react-router-dom";

import {
    LocalDate,
} from "@js-joda/core";

import Button, {ButtonStyles} from "../common/Button";

import VirtualClubEventCheckList from './VirtualClubEventCheckList';
import { Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import "./JoinClubModal.scss";

import { GetClubResponseBody } from "../../services/MemberService";
import { GetClubLeaderEventOrOccurrenceResponseBody } from "../../services/ClubLeaderService";

interface JoinClubModalProps extends RouteComponentProps {
    open: boolean,
    onClose: Function,
    afterEventRSVPReset: Function,
    joinClubWithEvents: Function,
    eventsInRange: GetClubLeaderEventOrOccurrenceResponseBody[],
    searchStartDate: LocalDate,
    searchEndDate: LocalDate,
    isClubMember: boolean,

    club: GetClubResponseBody,
    memberEmailAddress: string
}


 {/* TODO -  
 we need a third state in here when the user in question is a club LEADER. 
 Leaders can't join or unjoin their groups. (will that happen in the admin?)
 so just no button in this case, some text that says "you're leading this group"
*/}


function JoinClubModal(props: JoinClubModalProps) {
    const { t } = useTranslation('pages');

    function navigateToMemberInfo() {
        window.open('/ignite/profile/member-info', "_blank", "noreferrer");
    }

    const dialogContentRef = useRef<HTMLDivElement | null>(null);

    const [eventUrlFragmentsToRsvp, setEventUrlFragmentsToRsvp] = useState<any>({})

    const updateIntendingToSignUpForEvents = (eventUrlFragment:string, wantsToJoin:boolean) => {
        let copy = eventUrlFragmentsToRsvp;
        if (wantsToJoin) {

            copy[eventUrlFragment] = eventUrlFragment;
        } else {
            delete copy[eventUrlFragment];
        }

        setEventUrlFragmentsToRsvp(copy);
    }


    const joinClubWithEvents = async (eventUrlFragmentsToSignUpFor: any) => {
        props.joinClubWithEvents(eventUrlFragmentsToSignUpFor)
    }

    return (
        <Dialog open={props.open}
                fullWidth={true}
                maxWidth="md">
            <DialogActions>
                <IconButton onClick={() => props.onClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogActions>
            
            <DialogContent ref={dialogContentRef}>
                <div className="JoinClubModal_Term">{props.club.term?.termName}</div>

                <h1>{props.club.clubName}</h1>

                <h2 className="eventInfo_title">
                    {t('virtualClubsClubDetailPage.clubActivitiesLabel')}
                    <div className="eventInfo_dateRange">
                        <div>
                            <TextField 
                                value={props.searchStartDate}
                                label="From"
                                variant="outlined"
                                disabled
                            />
                        </div>
                        <div>
                            <TextField 
                                value={props.searchEndDate}
                                label="To"
                                variant="outlined"
                                disabled
                            />
                        </div>
                    </div>
                </h2>
                <h3 className="eventInfo_subtitle">{t('virtualClubsClubDetailPage.clubActivitiesSignMeUp')}</h3>
                
                <VirtualClubEventCheckList
                        events={props.eventsInRange}
                        updateIntendingToSignUpForEvents={updateIntendingToSignUpForEvents}
                        afterEventRSVPReset={props.afterEventRSVPReset}
                        nextOccurrenceOnOrAfter={props.searchStartDate}
                        isClubMember={props.isClubMember}
                    >
                </VirtualClubEventCheckList>

                <br/>

                <div className="JoinClubModal_MemberMessage">
                    {t('virtualClubsClubDetailPage.emailConfirmationText')} &nbsp; <span className="JoinClubModal_MemberEmail">{props.memberEmailAddress}</span>
                    <Button buttonStyle={ButtonStyles.Link}
                            clickHandler={navigateToMemberInfo}
                            className="JoinClubModal_MemberProfileLink">
                        <EditIcon/> &nbsp;{t('virtualClubsClubDetailPage.memberProfileEditText')}
                    </Button>
                </div>
            </DialogContent>

            <DialogActions>
                <Button buttonStyle={ButtonStyles.FilledPrimary}
                        clickHandler={() => joinClubWithEvents(eventUrlFragmentsToRsvp)}>
                    {t('virtualClubsClubDetailPage.joinClubButton')}
                </Button>
            </DialogActions>
        </Dialog>);
}

export default withRouter(JoinClubModal);
