import { getSanityResponse,  SanityTextObject } from "./SanityUtils";
import i18next from 'i18next';

export interface ClubAssociationSanityResponse {
    ms: number;
    query: string;
    result: ClubAssociationSanityData[];
}

export interface ClubAssociationSanityData {
    associationImage: string;
    materialsNeededEnabled: boolean;
    materialsNeeded: string;
    memberGalleryEnabled: string;
    memberGallery: SanityTextObject[];
    memberGalleryImages: string[];
    whatOurMembersAreSayingEnabled:  boolean;
    whatOurMembersAreSaying: SanityTextObject[];
    clubDownloadableFile: string;

}
  

const getVirtualClubAssociationContentViaSanity = async (shortCode: string): Promise<ClubAssociationSanityResponse | undefined> => {
    const LANGUAGE = i18next.language?.split('-')[0] ?? 'en';
    const query = encodeURIComponent(`
        *[_type == "association" && lower(slug) == "${shortCode}"]
        {
            clubDownloadableFile, 
            materialsNeededEnabled, materialsNeeded,  
            whatOurMembersAreSayingEnabled, whatOurMembersAreSaying, 
            memberGalleryEnabled, memberGallery,
            "associationLogoUrl": associationLogo.asset->url,
            "associationLogoLightUrl": associationLogoLight.asset->url,
            "associationImage": associationImage.asset->url,
            "whatOurMembersAreSayingImages" : whatOurMembersAreSaying[].asset->url,
            "memberGalleryImages" : memberGallery[].asset->url,
            "clubDownloadableFileURL": clubDownloadableFile.asset->url,
        }
    `);

    return getSanityResponse(query);
}

export default getVirtualClubAssociationContentViaSanity