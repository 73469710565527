import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import {useTranslation} from "react-i18next";
import {TextField} from "@material-ui/core";
import React from "react";

export function LastNameInput(props: RegistrationInputProps) {
    const { t } = useTranslation('pages');
    const inputConfig: InputConfig<string> = {
        name: "lastName",
        initialValue: "",
        validate: (value: string) => {
            if (!value.trim()) {
                //return 'Last Name is required';
                return " ";
            }
        },
        validateOnBlur: true
    }

    const [formState, {text}] = useSubFormInput(inputConfig);

    return (
        <TextField
            {...text(inputConfig)}
            className={`RegistrationWizard_Input LastNameInput ${props.className || ''}`}
            label={t('registration.lastName.label')}
            variant="outlined"
            error={formState.errors.lastName !== undefined}
            helperText={formState.errors.lastName?.trim()}
            required
            size="small"/>
    )
}