import React from "react";
import { useTranslation } from 'react-i18next';
import { Tabs as MaterialTabs, Tab } from '@material-ui/core'
import { Link, useLocation, useParams } from "react-router-dom";
import useCheckDevice from "../../../hooks/useCheckDevice";

const Tabs = () => {
    const { pathname } = useLocation();
    const { clubId = '' } = useParams<{ clubId: string }>()
    const { t } = useTranslation('ignite/myInfo');
    const { isMobile } = useCheckDevice()
    return (
        <MaterialTabs
            value={pathname}
            orientation={isMobile ? "horizontal" : "vertical"}
            classes={{
                root: 'profile-tabs',
                indicator: 'profile-tabs-indicator',
                flexContainer: 'container'
            }}
        >
            <Tab
                label={t('tabs.memberInfo')}
                value={`/ignite/profile/member-info/${clubId}`}
                to={`/ignite/profile/member-info/${clubId}`}
                classes={{
                    root: 'profile-tab',
                    selected: 'selected',
                    wrapper: 'profile-tab-content'

                }}
                component={Link}
            />
            <Tab
                label={t('tabs.notifications')}
                value={`/ignite/profile/notifications/${clubId}`}
                to={`/ignite/profile/notifications/${clubId}`}
                classes={{
                    root: 'profile-tab',
                    selected: 'selected',
                    wrapper: 'profile-tab-content'
                }}
                component={Link}
            />
            <Tab
                label={t('tabs.healthPlan')}
                value={`/ignite/profile/health-plan/${clubId}`}
                to={`/ignite/profile/health-plan/${clubId}`}
                classes={{
                    root: 'profile-tab',
                    selected: 'selected',
                    wrapper: 'profile-tab-content'

                }}
                component={Link}
            />
            <Tab
                label={t('tabs.associations')}
                value={`/ignite/profile/associations/${clubId}`}
                to={`/ignite/profile/associations/${clubId}`}
                classes={{
                    root: 'profile-tab',
                    selected: 'selected',
                    wrapper: 'profile-tab-content'
                }}
                component={Link}
            />
            <Tab
                label={t('tabs.clubs')}
                value={`/ignite/profile/clubs/${clubId}`}
                to={`/ignite/profile/clubs/${clubId}`}
                classes={{
                    root: 'profile-tab',
                    selected: 'selected',
                    wrapper: 'profile-tab-content'
                }}
                component={Link}
            />
        </MaterialTabs>
    );
}

export default Tabs
