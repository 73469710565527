import React from "react";

const mailEnvelope = require("../../assets/mail-envelope.svg");

interface MailEnvelopeProps {
  className?: string
}

export default function MailEnvelope(props: MailEnvelopeProps) {
  return <img className={props.className} src={mailEnvelope} alt="mail envelope icon"/>
}
