import {
    DateTimeFormatter,
    Instant,
    LocalDate,
    LocalTime,
    ZonedDateTime,
    ZoneId
} from "@js-joda/core";

import { Locale } from "@js-joda/locale_en-us";
import { EventOccurrence, EventRecurrence, GetClubLeaderEventResponseBody, nameForWeeklyDay } from "../services/ClubLeaderService";
import { SupportedTimeZone } from "./SupportedTimeZone";
import i18n from "i18next";
export const endsAtTimeFormatter = DateTimeFormatter.ofPattern("h:mma z").withLocale(Locale.US);
export const startsAtTimeFormatter = DateTimeFormatter.ofPattern("h:mma").withLocale(Locale.US);
export const dateFormatter = DateTimeFormatter.ofPattern("eee MMM d, yyyy").withLocale(Locale.US);

 
export function formatEventDate(dateString : string) {
    // Thu, Mar 14, 2024
    const date = new Date(dateString)
    .toLocaleDateString(i18n.language || 'en', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' });
    return date;
}
 
export function formatEventOccurrenceDateOnOrAfter(occurrences: EventOccurrence[], onOrAfter: LocalDate) {
    let firstOccurrence = getEventOccurrenceDateOnOrAfter(occurrences, onOrAfter);
    return firstOccurrence ? formatEventDate(firstOccurrence.startsAtInstant) : ''
}

export function getEventOccurrenceDateOnOrAfter(occurrences: EventOccurrence[], onOrAfter: LocalDate) {
    const occurrenceAfter = onOrAfter instanceof LocalDate
        ? onOrAfter.toString()
        : LocalDate.now().toString();
    return occurrences.find(x => x.startsAtInstant > occurrenceAfter);
}
export function formatLastEventOccurrenceDate(occurrences: EventOccurrence[]) {
    if (occurrences.length > 0) {
        let lastOccurrence = occurrences[occurrences.length - 1];
        return formatEventDate(lastOccurrence.startsAtInstant);
    } else {
        return '';
    }
}
export function formatEventTime(timeString : string, withTimezone : boolean) {
    if (withTimezone === true){
        return new Date(timeString).toLocaleTimeString(i18n.language || 'en', { hour: 'numeric', minute: '2-digit', timeZoneName: "short" });
    }
    return new Date(timeString)
    .toLocaleTimeString(i18n.language || 'en', { hour: 'numeric', minute: '2-digit' });
 
}
export function formatEventTimeRange(startTime : string, endTime : string) {
 
    const start = formatEventTime(startTime, false);
    const end = formatEventTime(endTime, true)

    const startDisplay = start.replace(" AM", "am").replace(" PM", "pm");
    const endDisplay = end.replace(" AM", "am").replace(" PM", "pm");

    return `${startDisplay}-${endDisplay}`;
}

export function formatEventRecurrence(recurrence: EventRecurrence | null) {
    if (!!recurrence) {
        if (recurrence.recurrenceType === 2 && recurrence.weeklyDays) {
            if (recurrence.repeatInterval === 1) {
                return `Every ${nameForWeeklyDay(recurrence.weeklyDays)}`
            } else if (recurrence.repeatInterval === 2) {
                return `Every other ${nameForWeeklyDay(recurrence.weeklyDays)}`
            } else if (recurrence.repeatInterval === 3) {
                return `Every 3rd ${nameForWeeklyDay(recurrence.weeklyDays)}`
            } else if (recurrence.repeatInterval > 3) {
                return `Every ${recurrence.repeatInterval}th ${nameForWeeklyDay(recurrence.weeklyDays)}`
            }
        }
        console.warn(`Formatting not currently supported for RecurrenceType: ${recurrence.recurrenceType}`);
    }
    return "";
}

export function eventIsLiveOrStartsWithin15Minutes(event: GetClubLeaderEventResponseBody) {
    const nowToString = Instant.now().toString();
    const numSecondsIn15Min = 15 * 60;
    const fifteenMinutesFromNow = Instant.now().plusSeconds(numSecondsIn15Min).toString();

    if (event.isRecurring) {
        return event.occurrences?.some(
            (occurrence: EventOccurrence) => occurrence.startsAtInstant <= fifteenMinutesFromNow
                && occurrence.endsAtInstant >= nowToString
        );
    } else {
        return event.startsAtInstant <= fifteenMinutesFromNow && event.endsAtInstant >= nowToString;
    }
}

export function eventIsLive(event: GetClubLeaderEventResponseBody) {
    const nowToString = Instant.now().toString();

    if (event.isRecurring) {
        return event.occurrences?.some(
            (occurrence: EventOccurrence) => occurrence.startsAtInstant <= nowToString
                && occurrence.endsAtInstant >= nowToString
        );
    } else {
        return event.startsAtInstant <= nowToString && event.endsAtInstant >= nowToString;
    }
}