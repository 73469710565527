import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { IMember } from '../types/dashboard'

interface IGetMemberReferralsArgs {
    clubId: number
    skip: number
    referralStatusType?: number
    sortOrder: string
    sortBy: string
    searchValue: string
}

export const GET_REFERRALS_MEMBERS_URL = '/ignite/leader/get-member-referrals/'

const getMemberReferrals = async ({ clubId, skip = 0, referralStatusType = 0, sortBy, sortOrder, searchValue }:
    IGetMemberReferralsArgs): Promise<{ results: IMember[], totalResultCount: number } | undefined> => {
    const config = await loadConfig();
    const url = `${config.apiOrigin}${GET_REFERRALS_MEMBERS_URL}${clubId}?skip=${skip}&referralStatusType=${referralStatusType}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${searchValue}`

    const request = new Request(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    } else {
        throw new Error
    }
}

export default getMemberReferrals
