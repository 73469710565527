import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./AcblPromptContinue.scss";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../contexts/UserContext";
import { AcblBanner } from "../../components/acblBanner/AcblBanner";

export function AcblPromptContinue(): JSX.Element {
    const {t} = useTranslation('pages');
    const {logout} = useContext(UserContext);
    const history = useHistory();

    async function onClickPrompt(e: React.MouseEvent) {
        const action = (e.target as Element).getAttribute("data-action");
        if (action === "continue") {
            history.push("/acbl/new-registration")
        } else if (action === "login") {
            await logout();
            history.push("/login");
        }
    }

    return (
        <>
            <AcblBanner/>
            <div className={"AcblPromptContinue"}>
                <span className={"AcblPromptContinue_Prompt"} onClick={onClickPrompt} dangerouslySetInnerHTML={{__html: t("acblPromptContinue.prompt")}}/>
            </div>
        </>
    );
}
