import './MemberRegistrationWizardStep.scss';
import {
  GetAllPayersResponseBody,
  GetPayerResponseBody,
} from '../../../services/MemberService';
import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ButtonStyles } from '../../../components/common/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Loading from '../../../components/common/Loading';
import LoadingMessage from '../../../components/common/LoadingMessage';
import PlayButton from '../../../components/common/PlayButton';
import { loadConfig } from '../../../services/ConfigService';
import { useFormState } from 'react-use-form-state';
import { useTranslation } from 'react-i18next';

export interface MemberPayer {
  payerId: number;
  insuranceId: string;
}

type StepProp = {
  onContinue: (data: unknown) => void;
  onPrevious: () => void;
};

const useGetAllPayers = () => {
  const { t } = useTranslation('pages');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [allPayers, setAllPayers] = useState<GetPayerResponseBody[]>([]);

  useEffect(() => {
    setError(null);
    setLoading(true);

    loadConfig()
      .then(({ apiOrigin }) => new Request(`${apiOrigin}/all-payers`))
      .then(fetch)
      .then(async (response): Promise<GetAllPayersResponseBody> => {
        if (response.ok) {
          return response.json();
        }

        const body = await response.text();
        const { status, statusText, url } = response;
        const message = `Non-successful response from API: ${status} ${statusText} from ${url}`;

        console.error(message, body);
        throw new Error(message);
      })
      .then((data) =>
        data.allPayers.sort((left, right) =>
          left.payerName.localeCompare(right.payerName),
        ),
      )
      .then(setAllPayers)
      .finally(() => setLoading(false))
      .catch(() => setError(t('memberSelectPayerStep.unexpectedError')));
  }, [t]);

  return [allPayers, { loading, error }] as const;
};

export function MemberSelectPayerStep(props: StepProp): JSX.Element {
  const { t } = useTranslation('pages');
  const [allPayers, { loading, error }] = useGetAllPayers();

  const [formState, { text, select }] = useFormState({
    payerId: undefined,
    insuranceId: '',
    healthPlanName: null,
  });

  const elementConfig = {
    payerId: {
      name: 'payerId',
      onChange: () => {
        formState.setField('insuranceId', '');
      },
      validate: (value: string | number): string | undefined => {
        if (
          typeof value === 'number' &&
          allPayers.find(({ id }) => id === value)
        ) {
          return;
        }
        return t('memberSelectPayerStep.selectPayer');
      },
      validateOnBlur: true,
    },
    insuranceId: {
      name: 'insuranceId',
      validate: (value: string): string | undefined => {
        if (selectedPayer && value === '') {
          return t('memberSelectPayerStep.enterPayerId', {
            payerName: selectedPayer.payerName,
          });
        }
      },
      validateOnBlur: true,
    },
    healthPlanName: {
      name: 'healthPlanName',
      validate: (value: string): string | undefined => {
        if (selectedPayer?.unlistedPayerPlaceholder === true && value === '') {
          return t('memberSelectPayerStep.enterHealthPlanName');
        }
      },
      validateOnBlur: true,
    },
  } as const;

  const selectedPayer = useMemo(
    () => allPayers.find(({ id }) => id === formState.values.payerId) ?? null,
    [allPayers, formState.values.payerId],
  );

  const insuranceIdLabel = useMemo(() => {
    if (selectedPayer === null) {
      return '';
    }

    if (selectedPayer.unlistedPayerPlaceholder === true) {
      return t('memberSelectPayerStep.healthPlanId');
    }

    return `${selectedPayer.payerName} ID`;
  }, [selectedPayer, t]);

  const continueEnabled = useMemo(() => {
    // Not all fields are required, so not all fields need to be touched.
    const allFieldsValid = Object.values(elementConfig).every((field) => {
      // TODO: Find alternative for this form plugin.
      // It's terrible when coditional fields are required.
      return !field.validate(formState.values[field.name] as any);
    });

    if (allFieldsValid === false) {
      return false;
    }

    return Object.values(formState.validity).every((value) => value);
  }, [elementConfig, formState.values, formState.validity]);

  const onContinueSelected = () => {
    props.onContinue({
      payer: selectedPayer,
      insuranceId: formState.values.insuranceId,
      healthPlanName: formState.values.healthPlanName,
    });
  };

  useEffect(() => {
    if (!loading && !selectedPayer?.unlistedPayerPlaceholder) {
      formState.setField('healthPlanName', null);
    }
  }, [loading, selectedPayer, formState]);

  return (
    <div className={'MemberRegistrationWizard_Wrapper'}>
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={2}>
          <IconButton
            className={'MemberRegistrationWizard_Previous'}
            aria-label="Go Back"
            onClick={props.onPrevious}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <h2
            dangerouslySetInnerHTML={{
              __html: t('memberSelectPayerStep.header'),
            }}
          ></h2>
        </Grid>
      </Grid>
      {loading && <Loading loading={loading} />}
      {error && <LoadingMessage message={error} />}
      {!loading && !error && (
        <form className="MemberSelectPayerPage_Form">
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="center"
            className="MemberSelectPayerPage_Fields"
          >
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              <Select
                style={{ width: '300px' }}
                {...select(elementConfig.payerId)}
                error={formState.errors.payerId !== undefined}
                className="MemberSelectPayerPage_PayerSelect"
                variant="outlined"
                disabled={loading}
                displayEmpty
                required
              >
                <MenuItem value="" disabled>
                  {t('memberSelectPayerStep.selectProvider')}
                </MenuItem>
                {allPayers.map((payer) => (
                  <MenuItem key={payer.id} value={payer.id}>
                    {payer.payerName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              {selectedPayer?.unlistedPayerPlaceholder === true && (
                <TextField
                  {...text(elementConfig.healthPlanName)}
                  error={formState.errors.healthPlanName !== undefined}
                  className="MemberSelectPayerPage_HealthPlanNameField"
                  required
                  helperText={formState.errors.healthPlanName}
                  label={t('memberSelectPayerStep.healthPlanName')}
                  variant="outlined"
                  style={{ marginBottom: '10px' }}
                />
              )}
              {selectedPayer !== null && (
                <>
                  <TextField
                    {...text(elementConfig.insuranceId)}
                    error={formState.errors.insuranceId !== undefined}
                    className="MemberSelectPayerPage_InsuranceIdField"
                    helperText={formState.errors.insuranceId}
                    required
                    inputProps={{ maxLength: 50 }}
                    label={insuranceIdLabel}
                    variant="outlined"
                    style={{ marginBottom: '10px' }}
                  />
                  <PlayButton
                    type="button"
                    clickHandler={onContinueSelected}
                    disabled={!continueEnabled}
                    text={t('memberSelectPayerStep.continueButtonText')}
                    className="MemberSelectPayerPage_SubmitButton"
                    buttonStyle={ButtonStyles.FilledPrimary}
                    id="HealthPlanSubmit"
                  />
                </>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
}
