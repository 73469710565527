import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'
import { IClubMemberCount } from '../types/dashboard'

export const GET_MEMBER_COUNT_URL = '/clubs/get-member-count/'

const getClubMemberCount = async (clubId: number): Promise<IClubMemberCount> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_MEMBER_COUNT_URL}${clubId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    } else {
        return {
            count: 0
        }
    }
}

export default getClubMemberCount
 
