import {
    GetClubLeaderResponseBody, GetClubLeaderClubResponseBody,
    GetDeputyInvitationResponseBody
} from "../../services/ClubLeaderService";
import React, {ChangeEvent, useContext, useState} from "react";
import { useTranslation } from 'react-i18next';

import {UserContext} from "../../contexts/UserContext";
import {loadConfig} from "../../services/ConfigService";
import {CCStyles} from "../../components/form/Theme";
import {FormControl, TextField} from "@material-ui/core";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import "./MyClubLeaders.scss"
import ListSeparator from "../../components/common/ListSeparator";
import OkModal from "../../components/common/OkModal";
import BorderedRow, {BorderedRowItem} from "../../components/common/BorderedRow";
import WrappableEmailAddress from "../../components/common/WrappableEmailAddress";
import { validEmailRegexText } from "../../util/Util";
import { useHistory } from "react-router-dom";

/**
 * Parameters for the {@link ClubLeader} component.
 */
interface ClubLeaderParams {
    clubUrlFragment: string;
    /**
     * The club leader being displayed by this component.
     */
    clubLeader: GetClubLeaderResponseBody;

    /**
     * `true` if the club leader being displayed is a deputy club leader, and
     * the current user is the primary club leader.
     */
    isRemovable: boolean;

    /**
     * A function to be invoked after an existing club leader is removed.
     *
     * @param email the email address of the removed club leader
     */
    onRemoved: (email: string) => void;
}

/**
 * A component displaying one club leader on the My Club page.
 */
function ClubLeader({clubUrlFragment, clubLeader, isRemovable, onRemoved}: ClubLeaderParams): JSX.Element {
    const { t } = useTranslation('pages');
    const {user, authenticatedFetch} = useContext(UserContext);

    const [removingMessage, setRemovingMessage] = useState<string | null>(null);
    const [removed, setRemoved] = useState(false);

    async function onClickRemove() {
        // TODO: Ask for confirmation?

        if (user === null) {
            setRemovingMessage(t('myClubLeaders.sessionExpired'));
            return;
        }

        const config = await loadConfig();

        const removeDeputyUrl = `${config.apiOrigin}/club-leader/remove-deputy/${clubUrlFragment}`

        const removeDeputyRequest = new Request(removeDeputyUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                deputyEmailAddress: clubLeader.email
            })
        });

        const removeDeputyResponse = await authenticatedFetch(removeDeputyRequest);

        if (removeDeputyResponse === null) {
            setRemovingMessage(t('myClubLeaders.sessionExpired'));
            return;
        }

        if (!removeDeputyResponse.ok) {
            const responseBodyText = await removeDeputyResponse.text();
            console.error(
                `Non-successful response from API: `
                + `${removeDeputyResponse.status} ${removeDeputyResponse.statusText} `
                + `from ${removeDeputyResponse.url}\n\n${responseBodyText}`);
            setRemovingMessage(t('myClubLeaders.removeError'));
            return;
        }

        setRemoved(true);
        onRemoved(clubLeader.email);
    }


    let classes:string = "ClubLeader";
    if (!isRemovable) {
        classes = "ClubLeader NotRemovable"
    }

    return (
        <BorderedRow className={classes} showTopBorder={false}>
            <BorderedRowItem className="FullName">
                {clubLeader.firstName} {clubLeader.lastName}
            </BorderedRowItem>
            <BorderedRowItem className="ContactInfo">
                <div className="Email">
                    <a href={`mailto:${clubLeader.email}`}><WrappableEmailAddress emailAddress={clubLeader.email}/></a>
                </div>
                <div className="Phone">
                    <a href={`tel:${clubLeader.phone}`}>{clubLeader.phone}</a>
                </div>
            </BorderedRowItem>
            {isRemovable && (
                <BorderedRowItem className="Controls">
                    {!removed && (
                        <PlayButton className="RemoveButton" clickHandler={onClickRemove}
                                    useConfirmationModal={true}
                                    confirmationModalBody={<div>{t('myClubLeaders.confirmRemove')}</div>}
                                    buttonStyle={ButtonStyles.FilledPrimary}
                                    text={t('myClubLeaders.removeButtonText')}/>
                    )}
                    <OkModal className="RemovingMessage" okClickHandler={() => {
                        setRemovingMessage(null)
                    }} shown={removingMessage !== null}>
                        <div className={"ModalMessage"}>{removingMessage}</div>
                    </OkModal>
                </BorderedRowItem>
            )}
        </BorderedRow>
    );
}

/**
 * Parameters for the {@link AddClubLeaderForm} component.
 */
interface AddClubLeaderFormParams {
    /**
     * A function to be invoked after a new club leader is added.
     *
     * @param email the email address of the newly-invited club leader
     */
    onAdded: (email: string) => void;
    setIsAddingLeader: Function;
    clubUrlFragment: string;
}

/**
 * A component displaying the form to invite a new deputy club leader to My Club.
 */
function AddClubLeaderForm({onAdded, setIsAddingLeader, clubUrlFragment}: AddClubLeaderFormParams): JSX.Element {
    const { t } = useTranslation('pages');
    const history = useHistory();
    const {user, authenticatedFetch} = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [adding, setAdding] = useState(false);
    const [addingMessage, setAddingMessage] = useState<string | null>(null);

    const classes = CCStyles();

    function onChangeEmail(event: ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    async function onSubmitAddClubLeaderForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        setAdding(true);

        if (user === null) {
            setAddingMessage(t('myClubLeaders.sessionExpired'));
            setAdding(false);
            return;
        }

        const config = await loadConfig();

        const inviteDeputyUrl = `${config.apiOrigin}/club-leader/invite-deputy/${clubUrlFragment}`

        const inviteDeputyRequest = new Request(inviteDeputyUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                emailAddress: email
            })
        });

        const inviteDeputyResponse = await authenticatedFetch(inviteDeputyRequest);

        if (inviteDeputyResponse === null) {
            setAddingMessage(t('myClubLeaders.sessionExpired'));
            setAdding(false);
            return;
        }

        if (!inviteDeputyResponse.ok) {
            const responseBodyText = await inviteDeputyResponse.text();
            console.error(
                `Non-successful response from API: `
                + `${inviteDeputyResponse.status} ${inviteDeputyResponse.statusText} `
                + `from ${inviteDeputyResponse.url}\n\n${responseBodyText}`);
            if (inviteDeputyResponse.status === 403) {
                history.replace('/');
            }
            setAddingMessage(t('myClubLeaders.inviteError'));
            setAdding(false);
            return;
        }

        setAdding(false);
        onAdded(email);
    }

    function onCancelClick() {
        setAdding(false);
        setIsAddingLeader(false);
    }

    return (
        <form className={`${classes.root} AddClubLeaderForm`} onSubmit={onSubmitAddClubLeaderForm}>
            <FormControl
                className={`${classes.root} ${classes.formControl} AddClubLeaderForm_FormControl`}
                component="fieldset">
                <TextField value={email}
                           type="email"
                           label={t('myClubLeaders.emailPlaceholder')}
                           fullWidth={true}
                           variant="outlined"
                           className={`${classes.root}`}
                           onChange={onChangeEmail}
                           inputProps={{
                               pattern: validEmailRegexText
                           }}
                           autoFocus
                           required/>
            </FormControl>
            <FormControl
                className={`${classes.root} ${classes.formControl} AddClubLeaderForm_FormControl_Buttons`}
                component="div">
                <PlayButton type="submit"
                            text={t('myClubLeaders.addButtonText')}
                            saving={adding}
                            className="AddClubLeader_SubmitButton"
                            buttonStyle={ButtonStyles.FilledPrimary}/>
                <PlayButton text={t('myClubLeaders.cancelButtonText')}
                            clickHandler={onCancelClick}
                            className="AddClubLeader_SubmitButton"
                            buttonStyle={ButtonStyles.FilledPrimary}/>
            </FormControl>
            <div className="AddClubLeaderForm_Instructions">{t('myClubLeaders.addInstructions')}</div>
            <OkModal className="AddingMessage" okClickHandler={() => {
                setAddingMessage(null)
            }} shown={addingMessage !== null}>
                <div className={"ModalMessage"}>{addingMessage}</div>
            </OkModal>
        </form>
    );
}

/**
 * Parameters for the {@link DeputyInvitation} component.
 */
interface DeputyInvitationParams {
    clubUrlFragment: string;
    /**
     * The deputy invitation being displayed by this component.
     */
    deputyInvitation: GetDeputyInvitationResponseBody;

    /**
     * A function to be invoked after an existing deputy invitation is cancelled.
     *
     * @param email the email address associated with the cancelled deputy invitation
     */
    onCancelled: (email: string) => void;
}

/**
 * A component displaying one open deputy club leader invitation on the My Club page.
 */
function DeputyInvitation({clubUrlFragment, deputyInvitation, onCancelled}: DeputyInvitationParams): JSX.Element {
    const { t } = useTranslation('pages');
    const {user, authenticatedFetch} = useContext(UserContext);

    const [cancellingMessage, setCancellingMessage] = useState<string | null>(null);
    const [cancelled, setCancelled] = useState(false);

    async function onClickCancelInvitation() {
        // TODO: Ask for confirmation?

        if (user === null) {
            setCancellingMessage(t('myClubLeaders.sessionExpired'));
            return;
        }

        const config = await loadConfig();

        const cancelInvitationUrl = `${config.apiOrigin}/club-leader/cancel-deputy-invitation/${clubUrlFragment}`

        const cancelInvitationRequest = new Request(cancelInvitationUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                toEmailAddress: deputyInvitation.toEmailAddress
            })
        });

        const cancelInvitationResponse = await authenticatedFetch(cancelInvitationRequest);

        if (cancelInvitationResponse === null) {
            setCancellingMessage(t('myClubLeaders.sessionExpired'));
            return;
        }

        if (!cancelInvitationResponse.ok) {
            const responseBodyText = await cancelInvitationResponse.text();
            console.error(
                `Non-successful response from API: `
                + `${cancelInvitationResponse.status} ${cancelInvitationResponse.statusText} `
                + `from ${cancelInvitationResponse.url}\n\n${responseBodyText}`);
            setCancellingMessage(t('myClubLeaders.removeErrorDeputy'));
            return;
        }

        setCancellingMessage(t('myClubLeaders.inviteCancelled'));
        setCancelled(true);
        onCancelled(deputyInvitation.toEmailAddress);
    }

    return (
        <div className="BorderedRow DeputyInvitation">
            <div className="EmailAddress">
                <WrappableEmailAddress emailAddress={deputyInvitation.toEmailAddress}/>
            </div>
            <div className="Message">
                {cancellingMessage !== null && cancellingMessage}
                {cancellingMessage === null && t('myClubLeaders.inviteNotice')}
            </div>
            <div className="Controls">
                {!cancelled && (
                    <PlayButton className="CancelButton" clickHandler={onClickCancelInvitation}
                                buttonStyle={ButtonStyles.FilledPrimary}
                                useConfirmationModal={true}
                                confirmationModalBody={<div>{t('myClubLeaders.confirmCancel')}</div>}
                                text={t('myClubLeaders.cancelInviteButtonText')}/>
                )}
                {cancellingMessage !== null && (
                    <div className="CancellingMessage">
                        {cancellingMessage}
                    </div>
                )}
            </div>
        </div>
    );
}

/**
 * Parameters for the {@link MyClubLeaders} component.
 */
export interface MyClubLeadersParams {
    /**
     * The club being displayed by this component.
     */
    myClub: GetClubLeaderClubResponseBody;

    /**
     * A function to be invoked after a new club leader is added.
     *
     * @param email the email address of the newly-invited club leader
     */
    onAdded: (email: string) => void;

    /**
     * A function to be invoked after an existing club leader is removed.
     *
     * @param email the email address of the removed club leader
     */
    onRemoved: (email: string) => void;

    /**
     * A function to be invoked after an existing deputy invitation is cancelled.
     *
     * @param email the email address associated with the cancelled deputy invitation
     */
    onCancelled: (email: string) => void;
}

/**
 * A component displaying all of the club leaders on the My Club page.
 */
export function MyClubLeaders({myClub, onAdded, onRemoved, onCancelled}: MyClubLeadersParams): JSX.Element {
    const { t } = useTranslation('pages');
    const [isAddingLeader, setIsAddingLeader] = useState(false);

    async function onClickAddLeader() {
        setIsAddingLeader(!isAddingLeader);
    }

    async function onLeaderAdded(email: string) {
        setIsAddingLeader(false);
        onAdded(email);
    }

    return (
        <div className="MyClubLeaders">
            <div className="MyClubLeaders_TitleRow">
                <div className="MyClubLeaders_Title">{t('myClubLeaders.clubLeadersTitle')}</div>
            </div>
            <ListSeparator className="MyClubPage_MyClubLeadersSeparator"/>
            <div className="MyClubLeaders_Leaders">
                {myClub.deputyClubLeaders.map(clubLeader => (
                    <ClubLeader
                        key={clubLeader.email}
                        clubUrlFragment={myClub.urlFragment}
                        clubLeader={clubLeader}
                        isRemovable={myClub.isPrimaryClubLeader}
                        onRemoved={onRemoved}
                    />
                ))}
            </div>
            <div className="MyClubLeaders_Invitations">
                {myClub.openDeputyInvitations.map(deputyInvitation => (
                    <DeputyInvitation
                        key={deputyInvitation.toEmailAddress}
                        clubUrlFragment={myClub.urlFragment}
                        deputyInvitation={deputyInvitation}
                        onCancelled={onCancelled}/>
                ))}
            </div>
            <div className="MyClubLeaders_AddButton">
                {!isAddingLeader && (
                    <PlayButton clickHandler={onClickAddLeader}
                                buttonStyle={ButtonStyles.FilledPrimary} text={t('myClubLeaders.addLeaderButtonText')}/>
                )}
            </div>
            <div className="MyClubLeaders_AddForm">
                {isAddingLeader && (
                    <AddClubLeaderForm setIsAddingLeader={setIsAddingLeader}
                                       onAdded={onLeaderAdded}
                                       clubUrlFragment={myClub.urlFragment}/>
                )}
            </div>
        </div>
    );
}
