import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from "@material-ui/core";
import {SanityTextObject} from "../../sanity/SanityUtils";
import PortableText from "react-portable-text";

interface InfoModalProps {
    isOpen: boolean;
    setIsOpenInfoModal: Function;
    modalContent: SanityTextObject[];
}

export default function InfoModal(props : InfoModalProps): JSX.Element {
    const { t } = useTranslation('pages');

    return (
        <Modal open={props.isOpen} className="InfoModalWrapper">
            <div className="InfoModal">
                <div className="InfoModal_InnerContainer">
                    <div>
                        <PortableText content={props.modalContent}></PortableText>
                    </div>
                    <div className="InfoModal_ButtonContainer">
                        <Button
                            className='InfoModal_Button'
                            onClick={() => props.setIsOpenInfoModal(false)}
                            variant='outlined'
                            color='primary'
                        >
                            {t('infoModal.closeButtonText')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    );
}
