import React from "react";
import { useTranslation } from 'react-i18next';

import "./PasswordReset.scss";
import PasswordResetForm from "../../components/passwordResetForm/PasswordResetForm";

export default function PasswordResetPage(): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <div className="PasswordResetPage">
            <h1>{t('passwordResetPage.header')}</h1>
            <div className="PasswordResetPage_Instructions">{t('passwordResetPage.instructions1')}</div>
            <div className="PasswordResetPage_Instructions" dangerouslySetInnerHTML={{__html: t('passwordResetPage.instructions2')}}></div>
            <PasswordResetForm/>
        </div>
    );
}
