import React from "react";
import "./StartEndTime.scss";
import TimeView from "./TimeView";


export interface StartEndTimeProps {
    startsAt: string | Date;
    endsAt: string | Date;
    className?: string;
}

export default function StartEndTime(props: StartEndTimeProps): JSX.Element {

    let classes = 'CCStartEndTime';
    let className = props.className;

    let startFormat = {hour: 'numeric', minute: 'numeric'};
    let endFormat = {hour: 'numeric', minute: 'numeric', timeZoneName: "short" };

    if (className !== undefined) {
        classes += ' ' + className;
    }

    return (
        <div className={classes}><TimeView format={startFormat} time={props.startsAt}/>-<TimeView
            format={endFormat} time={props.endsAt}/></div>
    );
}