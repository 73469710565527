export const customEnrollmentRegex = /^\/p\/[\w-]*\/?/i;
export const customEnrollmentLandingRegex = /^\/p\/[\w-]*(\/?|\/faq)$/i;
export const GHIN_VALIDATION_KEY = 'ghin';
export const CUSTOM_MESSAGE_VALIDATION_KEYS = [GHIN_VALIDATION_KEY];
export const GHIN_VALIDATIONS = {
  MEMBER_NOT_FOUND:  "Member Id Not Found",
  LASTNAME_MISMATCH: "User last name does not match",
  NULL_MEMBER_ID: "Member ID is null",
  NULL_LAST_NAME: "Last Name is null"
}
export function ValidateGhinMessages(errorMessage: string) : string{
    const translationRoot = 'registrationForm.validationMessages.ghin';
    switch(errorMessage){
        case GHIN_VALIDATIONS.MEMBER_NOT_FOUND: return `${translationRoot}.memberNotFound`;
        case GHIN_VALIDATIONS.LASTNAME_MISMATCH: return `${translationRoot}.lastNameMismatch`;
        case GHIN_VALIDATIONS.NULL_MEMBER_ID: return `${translationRoot}.noMemberID`;
        case GHIN_VALIDATIONS.NULL_LAST_NAME: return `${translationRoot}.noLastName`;
        default:
            return 'registrationForm.registrationError';
      }
}
export const GHIN_CLUBS = ['usga'];