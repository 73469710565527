
import { loadConfig } from '../services/ConfigService'
import { GET_CLUBS_V2_URL, IClubV2 } from '../types/clubFinder';
import { authenticatedFetch } from '../services/UserService'; 
import i18next from "i18next";
   
export const getClubV2ById = async (id: number): Promise<IClubV2 | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_CLUBS_V2_URL}/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept-Language": i18next.language || 'en-US'
        }
    });

    const response = await authenticatedFetch(request);
    if (response?.ok) {
        return response.json()
    }
}
