import "./SectionHeader.scss";
import React from "react";

interface SectionProps {
  className?: string
  title?: string,
  subtitle?: string
}

export default function SectionHeader(props: SectionProps): JSX.Element {
  let classes = "SectionHeader " + (props.className === undefined ? "" : props.className);
  let title = props.title;
  let subtitle = props.subtitle;

  return (
    <div className={classes}>
      {title !== undefined &&
        <div className="SectionHeader_Title">
          {title}
        </div>
      }
      {subtitle !== undefined &&
        <div className="SectionHeader_Subtitle">
          {subtitle}
        </div>
      }
    </div>
  );
}

