import React, {useContext, useEffect} from "react";
import { useHistory, Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./ClubMemberLandingPage.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import clubLeaderLanding from "../../assets/club-leader-landing.jpg";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import {UserContext} from "../../contexts/UserContext";
 
export default function ClubMemberLandingPageComponent(): JSX.Element {
    const { t } = useTranslation('pages');
    const {user} = useContext(UserContext);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

 
    const onClickSignUpButton = () => {
        history.push("/club-member/new-registration");
    }

    return (
        <div className="ClubMemberLandingPage">
            <div id="MemberLanding_Banner">
                <div id="MemberLanding_Text">
                    <p id="CCClubLeaderLanding_Subtext" dangerouslySetInnerHTML={{__html: t('clubMemberLandingPage.subtext')}}></p>
                    <div id="CCMemberLanding_Register">
                        <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                            <PlayButton text={t('clubMemberLandingPage.playText')}
                                        id="MemberGetStarted"
                                        buttonStyle={ButtonStyles.FilledPrimary}
                                        disabled={user !== null}
                                        clickHandler={onClickSignUpButton}
                                        />
                        </div>
                    </div>
                    <div id="MemberLanding_Login">
                        <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                            <p id={"CCClubLeaderLanding_SignInText"}>
                                {t('clubMemberLandingPage.alreadyAccount')}&nbsp;
                                <Link to={"/login"}>{t('clubMemberLandingPage.logIn')}</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="MemberLanding_Image_Wrapper">
                    <img src={clubLeaderLanding}
                         alt={t('clubMemberLandingPage.imageAlt')}/>
                </div>
            </div>
            <div id={"CCMemberLanding_FAQ_Wrapper"}>
                <div id={"CCMemberLanding_FAQ"}>
                    <h2 id={"CCClubLeaderLanding_Learn_More_Header"}>{t('clubMemberLandingPage.aboutHeader')}</h2>
                    <div id={"CCMemberLanding_FAQ_Text"}>
                        <div className="Member_About_Text" dangerouslySetInnerHTML={{__html: t('clubMemberLandingPage.about1')}}>
                        </div>

                        <div className="Member_About_Text" dangerouslySetInnerHTML={{__html: t('clubMemberLandingPage.about2')}}>
                        </div>

                        <div className="Member_About_Text" dangerouslySetInnerHTML={{__html: t('clubMemberLandingPage.about3')}}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
