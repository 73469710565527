import React from "react";
import { useTranslation } from 'react-i18next';

import "./ListPaidIndicator.scss";
import PaidIndicator from "./PaidIndicator";


export interface ListPaidIndicatorProps {
    children?: any;
}

export default function ListPaidIndicator(props: ListPaidIndicatorProps): JSX.Element {
    const { t } = useTranslation('common');
    return (
        <><PaidIndicator className={"ListPaidIndicator"}>
            <div className={"PaidEvent"}>{t('listPaidIndicator.paidEvent')}</div>
            <div className={"Details"}>> {t('listPaidIndicator.viewDetails')}</div>
        </PaidIndicator></>
    );
}