import React, {useContext, useState} from "react";
import { useTranslation } from 'react-i18next';

import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/UserService";
import MailEnvelope from "../../components/icon/MailEnvelope";
import CrossBold from "../../components/icon/CrossBold";

import "./EmailVerificationNag.scss";
import {UserContext} from "../../contexts/UserContext";
import {useLocation} from "react-router-dom";

interface EmailVerificationNagProps {
    className?: string;
    showMemberJustRegisteredMessage: boolean;
    // Slightly customized message for those coming from the get-active flow.
    getActive: boolean;
    email?: string;
}

const IS_EMAIL_VERIFICATION_NAG_HIDDEN = 'IS_EMAIL_VERIFICATION_NAG_HIDDEN'

export default function EmailVerificationNag(props: EmailVerificationNagProps) {
    const { t } = useTranslation('layout');
    const {getContactPhoneNumber} = useContext(UserContext);
    const emailVerificationNagHiddenStatusJson = sessionStorage.getItem(IS_EMAIL_VERIFICATION_NAG_HIDDEN);
    const initialEmailVerificationNagHiddenStatus = emailVerificationNagHiddenStatusJson
        ? JSON.parse(emailVerificationNagHiddenStatusJson)
        : false;
    const [isVisible, setVisibleStatus] = useState(!initialEmailVerificationNagHiddenStatus);
    const [resendMessage, setResendMessage] = useState("");
    const [resendAttempted, setResendAttempted] = useState(false);

    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    async function onResendClick() {
        setResendAttempted(true);
        const config = await loadConfig();

        const request =
            new Request(
                `${config.apiOrigin}/resend-verification-email`,
                {method: "POST", headers: {"Accept": "application/json"}}
            );

        const response = await authenticatedFetch(request);
        if (response?.ok) {
            setResendMessage(t('emailVerificationNag.resentEmailConf'));
        } else {
            setResendMessage(t('emailVerificationNag.resentEmailFail'));
        }
    }

    function onCrossClick() {
        setVisibleStatus(false);
        sessionStorage.setItem(IS_EMAIL_VERIFICATION_NAG_HIDDEN, JSON.stringify(true));
    }

    let classes = 'EmailVerificationNag';
    if (props.className !== undefined) {
        classes = `${classes} ${props.className}`;
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div className={classes}>
            {props.showMemberJustRegisteredMessage &&
                <div className="EmailVerificationNag_MemberJustRegistered">
                    <h2>{t('emailVerificationNag.welcomeH2')}</h2>
                    {props.getActive
                        ? <>
                            <p>{t('emailVerificationNag.getActive2')}</p>
                            <p>{t('emailVerificationNag.getActive1')}</p>
                            <ul dangerouslySetInnerHTML={{__html: t('emailVerificationNag.getActive3', { phoneNumber })}} />
                        </>
                        : <p>{t('emailVerificationNag.notGetActive')}</p>
                    }
                </div>
            }
            <div className="EmailVerificationNag_Content">
                <span className="CrossBoldIcon" onClick={onCrossClick}>
                    <CrossBold />
                </span>
                <div>
                    <div className="EmailVerificationNag_Message_Title">
                        <MailEnvelope />
                        <p>{t('emailVerificationNag.clickTheLink')}</p>
                    </div>
                    <p className="EmailVerificationNag_Message_Body">
                        {t('emailVerificationNag.sentVerificationEmail')} <span>{props.email}</span>
                    </p>
                </div>
                {resendAttempted
                    ? <p onClick={onResendClick} className="EmailVerificationNag_Resent">{resendMessage}</p>
                    : <p onClick={onResendClick} className="EmailVerificationNag_Resend">{t('emailVerificationNag.resendEmail')}</p>
                }
            </div>
        </div>
    );
}
