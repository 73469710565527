import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { RouteComponentProps } from "react-router-dom";

// Adapted from https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
// and https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/
export function sendPageView(page: string, titleSuffix: string = "") {
    ReactGA.event("virtualPageview", {
        pageUrl: page,
        pageTitle: document.title + titleSuffix,
    });
}

const manuallyManagedPaths: string[] = [
    "/club-member/new-registration",
    "/acbl/new-registration",
    "/get-active/new-registration",
    "/member/wellcare-registration",
    "/member/new-registration",
    "/import-events",
    "/registration/",
    "/register/member",
    "/register/optum",
    "/register/aetna",
    "/p/"
]

export default <P extends RouteComponentProps>(
    WrappedComponent: React.ComponentType<P>
) => {
    return (props: P) => {
        const pathname = props.location.pathname;
        useEffect(() => {
            if (!manuallyManagedPaths.some(path => pathname.startsWith(path))) {
                sendPageView(pathname);
            }
        }, [pathname]);

        return <WrappedComponent {...props} />;
    };
}
