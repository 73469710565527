import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from "@material-ui/core";
import PortableText from "react-portable-text";
import {SanityTextObject} from "../../../sanity/SanityUtils";
import { ClubType } from "../../../types/club";

interface IsetIsOpenInfoTrackingActivity {
    isOpen: boolean
    setIsOpenInfoTrackingActivity: Function
    modalContent?: SanityTextObject[]|undefined
    clubType: ClubType
}

export default function InfoTrackingActivityModal({
    isOpen,
    setIsOpenInfoTrackingActivity,
    modalContent,
    clubType,
}: IsetIsOpenInfoTrackingActivity): JSX.Element {
    const { t } = useTranslation('ignite/dashboard');
    const entity = clubType === ClubType.CLUB ? t('club') : t('association');

    return (
        <Modal open={isOpen} className="info-tracking-activity-modal-wrapper">
            <div className="info-tracking-activity-modal">
                <div className="content">
                    {!modalContent && <>
                        <div className="title">
                            {t('infoTrackingActivityModal.title')}
                        </div>
                        <div className="action-text">
                            {t('infoTrackingActivityModal.actionText', {entity})}
                        </div>
                        <div className="subtitle">
                            {t('infoTrackingActivityModal.subtitleInPerson')}
                        </div>
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{__html: t('infoTrackingActivityModal.descriptionInPerson', {entity})}}
                        />
                        <div className="subtitle">
                            {t('infoTrackingActivityModal.subtitleOnline')}
                        </div>
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{__html: t('infoTrackingActivityModal.descriptionOnline', {entity})}}
                        />
                    </>}
                    {modalContent &&
                        <PortableText content={modalContent}></PortableText>
                    }
                    <div className="button-wrapper">
                        <Button
                            className='button'
                            onClick={() => setIsOpenInfoTrackingActivity(false)}
                            variant='outlined'
                            color='primary'
                        >
                            {t('infoTrackingActivityModal.buttonText')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >
    );
}
