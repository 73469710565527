import React, {useContext} from "react";
import "./EventImportWizardPage.scss";
import EventImportWizard from "../../components/eventImportWizard/EventImportWizard";
import {UserContext} from "../../contexts/UserContext";
import {RouteComponentProps} from "react-router-dom";

interface ClubEventImportParams{
    clubUrlFragment: string;
}

export default function EventImportWizardPage({match, history}: RouteComponentProps<ClubEventImportParams>): JSX.Element {
    const clubUrlFragment = match.params.clubUrlFragment;
    return (
        <div className="EventUploadPage PageContainer">
            <EventImportWizard importParams={clubUrlFragment} UserState={useContext(UserContext)}/>
        </div>
    );
}

