
import { memberDashboardConfig } from '../configs/memberDashboard';
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const GET_CLUB_LEADERBOARD_URL = '/ignite/get-club-leaderboard'

interface IGetClubLeaderboardArg {
    id: number
    skip?: number
}

const getClubLeaderboard = async ({ id, skip = 0 }: IGetClubLeaderboardArg): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_CLUB_LEADERBOARD_URL}/${id}?skip=${skip}&limit=${memberDashboardConfig.ITEMS_PER_PAGE_IN_LEADERBOARD}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }
}

export default getClubLeaderboard
