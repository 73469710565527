import React, {ChangeEvent} from "react";
import "./EventSearchResults.scss";
import {
    LocalDate,
    ZoneId
} from "@js-joda/core";
import i18n from "i18next";
import {
    MenuItem,
    TextField
} from "@material-ui/core";


interface MonthSelectProps {
    month: number,
    onChangeMonth: (month: number) => void
}

export default function MonthSelect(props: MonthSelectProps): JSX.Element {
    const currentMonth = new Date().getMonth();

    const months = Array.from({ length: 12 })
        .map((_, i) => {
            const month = (i + currentMonth) % 12;
            return {
                label: new Date(LocalDate.now()
                    .withMonth(month % 12 + 1)
                    .atStartOfDay()
                    .atZone(ZoneId.SYSTEM)
                    .toInstant()
                    .toEpochMilli())
                    .toLocaleDateString(i18n.language || 'en', { month: 'long' }),
                value: month
            }
        });

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        props.onChangeMonth(parseInt(e.target.value, 10));
    }

    return (
        <TextField
            value={props.month}
            onChange={onChange}
            type={"number"}
            className={"ResultsMonthList_MonthSelect"}
            select>
            {months.map(month => (
                <MenuItem key={month.value} value={month.value}>
                    {month.label}
                </MenuItem>
            ))}
        </TextField>
    )
}
