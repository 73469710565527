import { useState, useEffect, useRef } from "react";
import { BADGE_KEYS } from "../constants/dashboard";
import { IBadgeData } from '../types/dashboard'

const useBadge = (badgesEarned: IBadgeData[] | undefined, isMyActivityLoading?: boolean): {
    shouldShowFirstBadge: boolean,
    shouldShowTrackingCompletedBadge: boolean,
    setShouldShowFirstBadge: (shouldShow: boolean) => void,
    setShouldShowTrackingCompletedBadge: (shouldShow: boolean) => void
} => {
    const [shouldShowFirstBadge, setShouldShowFirstBadge] = useState(false)
    const [shouldShowTrackingCompletedBadge, setShouldShowTrackingCompletedBadge] = useState(false)

    const getBadgeByKey = (data: IBadgeData[] | undefined, key: BADGE_KEYS) =>
        data?.find((badge: IBadgeData) => badge.key === key)

    const firstActivityBadgeRef = useRef(getBadgeByKey(badgesEarned, BADGE_KEYS.FIRST_ACTIVITY))
    const trackingCompletedBadgeRef = useRef(getBadgeByKey(badgesEarned, BADGE_KEYS.TRACKING_COMPLETED))

    useEffect(() => {
        if (!isMyActivityLoading) {
            const firstActivityBadge = getBadgeByKey(badgesEarned, BADGE_KEYS.FIRST_ACTIVITY)
            const trackingCompletedBadge = getBadgeByKey(badgesEarned, BADGE_KEYS.TRACKING_COMPLETED)

            if (!firstActivityBadgeRef.current && firstActivityBadge) {
                setShouldShowFirstBadge(true)
                firstActivityBadgeRef.current = firstActivityBadge
            }
            if (!trackingCompletedBadgeRef.current && trackingCompletedBadge) {
                setShouldShowTrackingCompletedBadge(true)
                trackingCompletedBadgeRef.current = trackingCompletedBadge
            }
        }
    }, [badgesEarned, isMyActivityLoading])


    return {
        shouldShowFirstBadge,
        setShouldShowFirstBadge,
        shouldShowTrackingCompletedBadge,
        setShouldShowTrackingCompletedBadge
    }
}

export default useBadge
