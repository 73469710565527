import { useState, useEffect } from "react";
import { getAllInPersonActivityTypes, getAllVirtualActivityTypes } from '../api/getAllTerms'
import { ITerm } from '../types/clubSetup';

export const useGetAllInPersonActivityTypes = (): [ITerm[] | undefined, boolean] => {

    const [terms, setTerms] = useState<ITerm[] | []>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getAllInPersonActivityTypes()
            .then(data => {
                if (data) {
                    const sortedTerms = data.allTerms.sort(
                        (term1: ITerm, term2: ITerm) => {
                            return term1.termName.toLowerCase().localeCompare(term2.termName.toLowerCase());
                        })

                    setTerms(sortedTerms)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [terms, isLoading]
};

export const useGetAllVirtualActivityTypes = (): [ITerm[] | undefined, boolean] => {
    //   const [terms, setTerms] = useState<[ITerm] | undefined>()

    const [terms, setTerms] = useState<ITerm[] | []>([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getAllVirtualActivityTypes()
            .then(data => {
                if (data) {
                    const sortedTerms = data.allTerms.sort(
                        (term1: ITerm, term2: ITerm) => {
                            return term1.termName.toLowerCase().localeCompare(term2.termName.toLowerCase());
                        })

                    setTerms(sortedTerms)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [])

    return [terms, isLoading]
};