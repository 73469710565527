import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import "./WizardProgress.scss";
import StepProgressBar from "../../layout/StepProgressBar";

type WizardProgressProps = {
    currentStep: number,
    totalSteps: number
}

export default function WizardProgress(props: WizardProgressProps) {
    const { t } = useTranslation('pages');
    return (
        <div className="WizardProgress">
            <Grid container spacing={2} direction="row" alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <div className="WizardProgress_Label">
                        {t('registration.wizardProgress.registrationText')} <Hidden smUp><br/></Hidden>{t('registration.wizardProgress.stepCount', { current: props.currentStep, total: props.totalSteps})}
                    </div>
                    <StepProgressBar progressPercentage={((props.currentStep) / (props.totalSteps)) * 100}></StepProgressBar>
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </div>
    )
}