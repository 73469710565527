import React from "react";

const checkmark = require("../../assets/checkmark.svg");

interface CheckmarkProps {
}

export default function Checkmark(props: CheckmarkProps): JSX.Element {
  return <img className="Checkmark" src={checkmark} />
}
