import React from "react";
import { useTranslation } from 'react-i18next';

import "./MyEvents.scss";
import Button, { ButtonStyles } from "../common/Button";
import { RouteComponentProps, useHistory, useLocation, withRouter } from "react-router-dom";
import { ArrowRight } from "@material-ui/icons";
import { useWindowSize } from "../../util/Util";


interface CurrentlyNoEventsProps {
    favoritePassionsSelected: boolean
}

export default function CurrentlyNoEvents(props: CurrentlyNoEventsProps): JSX.Element {
    const {t} = useTranslation('event');
    const history = useHistory();
    const location = useLocation();
    
    const { isMobile } = useWindowSize();
    const { favoritePassionsSelected } = props;
    const tellUsYourPassionsDesktop = require("../../assets/tell-us-your-passions.png");
    const tellUsYourPassionsMobile = require("../../assets/tell-us-your-passions-mobile.png");
    const tellUsYourPassionsImage = isMobile ? tellUsYourPassionsMobile : tellUsYourPassionsDesktop;

    function goToFindEvents() {
        history.push("/find-an-event");
    }

    return (
        <div className={`MyEvents_CurrentlyNoEvents ${!favoritePassionsSelected ? "No_Passions" : ""}`}>
            <div className={"MyEvents_CurrentlyNoEvents_Text"}
                dangerouslySetInnerHTML={{ __html: t('myEvents.currentlyNoEvents') }}/>
            {favoritePassionsSelected &&
                <Button buttonStyle={ButtonStyles.FilledPrimary}
                        clickHandler={goToFindEvents}
                        className={"MyEvents_CurrentlyNoEvents_Button"}>
                    {t('myEvents.findAnEvent')}
                    <ArrowRight className={"MyEvents_CurrentlyNoEvents_Button_Icon"} />
                </Button>}
            {!favoritePassionsSelected &&
                <div className={"MyEvents_CurrentlyNoEvents_TellUsYourPassions"}>
                    <div className={"MyEvents_CurrentlyNoEvents_TellUsYourPassions_Text"}>
                        {t('myEvents.tellUsYourPassions')}
                    </div>
                    <Button
                        buttonStyle={ButtonStyles.FilledPrimary}
                        clickHandler={goToFindEvents}
                        className={"MyEvents_CurrentlyNoEvents_TellUsYourPassions_Button"}>
                        {t('myEvents.getStarted')}
                        <ArrowRight className={"MyEvents_CurrentlyNoEvents_TellUsYourPassions_Button_Icon"} />
                    </Button>
                    <div className={"MyEvents_CurrentlyNoEvents_TellUsYourPassions_Image"}
                         style={{backgroundImage: `url(${tellUsYourPassionsImage})`}}/>
                </div>}
        </div>
    )
};
