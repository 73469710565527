import { Tooltip } from "@material-ui/core";
import React from "react";
import { useTranslation } from 'react-i18next';
import { IActivityItem, IBadgeData } from "../../../types/dashboard";
import Badge from "./Badge";

interface IPersonalRankingPanelProps {
    myActivity?: IActivityItem
}

const PersonalRankingPanel = ({
    myActivity
}: IPersonalRankingPanelProps): JSX.Element => {
    const activityIsPresent = Boolean(myActivity?.activityRecords?.length)

    const { t } = useTranslation('ignite/dashboard');

    return (
        <div className="leaderboard-ranking">

            <div className="amount">
                <div className="number">
                    <span className="symbol">#</span>
                    {activityIsPresent ? myActivity?.ranking : '?'}
                </div>
                <div className="text">
                    {t('ranking')}
                </div>
            </div>

            {activityIsPresent ? (
                <div className="badges">
                    {myActivity?.badgesEarned?.map(
                        (badgeData: IBadgeData) =>
                            <Tooltip
                                title={t(`badgeTooltips.${badgeData.key}`) as string}
                                placement="top"
                                arrow
                                classes={{ tooltip: 'badge-tooltip', arrow: 'arrow' }}
                            >
                                <div>
                                    <Badge slug={badgeData.key} key={badgeData.key} />
                                </div>
                            </Tooltip>
                    )}
                </div>
            ) : (
                <div className="track-info">
                    <div className="track-info__button">
                        {t('track')}
                    </div>
                    <div className="track-info__text">
                        {t('getRating')}
                    </div>
                </div >
            )
            }
        </div >
    )
}

export default PersonalRankingPanel
