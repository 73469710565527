import React, {useContext, useEffect} from "react";
import {RouteComponentProps, Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./ClubMemberLandingPage.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import {UserContext} from "../../contexts/UserContext";
import { storeRegistrationParamsToSession } from "../../util/Util";
import ClubMemberLandingPageComponent from "./ClubMemberLandingPageComponent";
/**
 * A page with static content that contains a link to register as a member.
 */


interface ClubMemberLandingPageParams{
    utmSource: string;
    utmMedium: string;
    utmCampaign : string;
}
export default function ClubMemberLandingPageWithRouteParams({match, history}: RouteComponentProps<ClubMemberLandingPageParams>): JSX.Element {
    const { t } = useTranslation('pages');
    const {user} = useContext(UserContext);


    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        storeRegistrationParamsToSession(match.params.utmSource,match.params.utmCampaign, undefined);
    }, []);

    return (
        <ClubMemberLandingPageComponent />        
    );
}
