import React from "react";
import "./FullDateView.scss";
import DateView from "./DateView";

export interface FullDateViewProps {
    date: string | Date;
    className?: string;
}

export default function FullDateView(props: FullDateViewProps): JSX.Element {
    var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    return (
        <DateView date={props.date} format={options} className={props.className}/>
    );
}