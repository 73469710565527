import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { ProfilePanel } from "./ProfilePanel";
import Switcher from "../Switcher/Switcher";
import { IProfileInfo } from "../../../types/profile";
import { NotificationKind, NotificationStatus } from "../../../constants/profile";
import postUpdateNotificationPreference from "../../../api/postUpdateNotificationPreference";
import useGetMyProfileInfo from "../../../hooks/useGetMyProfileInfo";

export const Notifications = () => {
    const { t } = useTranslation('ignite/myInfo');
    const [shouldNotifyUpcomingEvent, setShouldNotifyUpcomingEvent] = useState(true)
    const [shouldNotifyUpdatedEvent, setShouldNotifyUpdatedEvent] = useState(true)
    const [shouldNotifyCancelledEvent, setShouldNotifyCancelledEvent] = useState(true)
    const [myProfileInfo, isMyProfileInfoLoading] = useGetMyProfileInfo()

    const updateNotification = (notificationKind: NotificationKind, preferenceType: NotificationStatus) => {
        postUpdateNotificationPreference({ notificationKind, preferenceType })
    }

    useEffect(() => {
        if (myProfileInfo?.notificationPreferences?.length) {
            myProfileInfo.notificationPreferences.forEach((notificationData: { preferenceType: any; notificationKind: NotificationKind; }) => {
                const isNegative = !notificationData?.preferenceType

                if (notificationData.notificationKind === NotificationKind.EVENT_UPCOMING && isNegative) {
                    setShouldNotifyUpcomingEvent(false)
                }
                if (notificationData.notificationKind === NotificationKind.EVENT_UPDATED && isNegative) {
                    setShouldNotifyUpdatedEvent(false)
                }
                if (notificationData.notificationKind === NotificationKind.EVENT_CANCELLED && isNegative) {
                    setShouldNotifyCancelledEvent(false)
                }
            })
        }
    }, [myProfileInfo, isMyProfileInfoLoading])

    return (
        <ProfilePanel title={t('tabs.notifications')} className="notifications">
            <>
                <div className="event-item">
                    <Switcher
                        isChecked={shouldNotifyUpcomingEvent}
                        onChange={() => {
                            setShouldNotifyUpcomingEvent(prev => !prev)
                            updateNotification(NotificationKind.EVENT_UPCOMING, Number(!shouldNotifyUpcomingEvent))
                        }}
                        positiveText={t('positiveNotificationText')}
                        negativeText={t('negativeNotificationText')}
                    />
                    <div className="label">
                        {t('eventUpcoming')}
                    </div>
                </div>
                <div className="event-item">
                    <Switcher
                        isChecked={shouldNotifyUpdatedEvent}
                        onChange={() => {
                            setShouldNotifyUpdatedEvent(prev => !prev)
                            updateNotification(NotificationKind.EVENT_UPDATED, Number(!shouldNotifyUpdatedEvent))
                        }}
                        positiveText={t('positiveNotificationText')}
                        negativeText={t('negativeNotificationText')}
                    />
                    <div className="label">
                        {t('eventUpdated')}
                    </div>
                </div>
                <div className="event-item">
                    <Switcher
                        isChecked={shouldNotifyCancelledEvent}
                        onChange={() => {
                            setShouldNotifyCancelledEvent(prev => !prev)
                            updateNotification(NotificationKind.EVENT_CANCELLED, Number(!shouldNotifyCancelledEvent))
                        }}
                        positiveText={t('positiveNotificationText')}
                        negativeText={t('negativeNotificationText')}
                    />
                    <div className="label">
                        {t('eventCanceled')}
                    </div>
                </div>
            </>
        </ProfilePanel>
    );
}
