import React, { useEffect, useRef } from "react";
import "./EventSearchResults.scss";
import {
    Instant,
    LocalTime,
    ZoneId
} from "@js-joda/core";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import {RouteComponentProps, useHistory} from "react-router-dom";
import FullCalendar, {
    DateRangeInput, DatesSetArg,
    EventClickArg,
    EventInput
} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
    EventSearchResponseBody, 
    EventSearchResultExtended
} from "../../pages/findAnEvent/FindAnEventPage";
import SelfGuidedEventIndicator from "../common/SelfGuidedEventIndicator";
import { CloudCircle } from "@material-ui/icons";
import NoResults from "./NoResults";

interface ResultsCalendarProps {
    resultsLoading: boolean;
    searchResponseBody: EventSearchResponseBody;
    results: EventSearchResultExtended[];
    month: number;
    setMonth: (month: number) => void;
    usingFilters: boolean;
    resetFilters: Function;
    viewVirtualOnly: Function;
    maxEventsPerDay: number;
    scrollToEvent: (eventUrlFragment: string, startOfDate: string) => void;
}

export default function ResultsCalendar (props: ResultsCalendarProps): JSX.Element {
    let events: EventInput[] = [];
    const { t } = useTranslation('pages');
    const { t: tCommon } = useTranslation('common');
    const ref = useRef<FullCalendar>(null);
    const currentMonth = new Date().getMonth();
    const viewingCurrentMonth = props.month === currentMonth;
    const viewingFinalMonth = props.month === ((currentMonth + 12 - 1) % 12);
    const history = useHistory();

    props.results.forEach(event => {
        const startInstant = Instant.parse(event.eventStartsAt);
        const startOfDate = startInstant
            .atZone(ZoneId.SYSTEM)
            .with(LocalTime.MIN)
            .toInstant()
            .toEpochMilli()
            .toString();
        events.push({
            start: new Date(startInstant.toEpochMilli()),
            title: `${event.eventName} - ${event.ext.timeStartShort}`,
            id: event.ext.url,
            color: event.eventIsVirtual ? '#0177C0' : '#BA68C8',
            extendedProps: {
                eventUrlFragment: event.eventUrlFragment,
                startOfDate: startOfDate
            }
        });
    });
    const eventsByDate: Record<string, EventInput[]> = {};
    events.forEach(event => {
        const dateTime = event.extendedProps?.startOfDate;
        if (eventsByDate[dateTime] === undefined) {
            eventsByDate[dateTime] = [];
        }
        eventsByDate[dateTime].push(event);
    });
    Object.keys(eventsByDate).forEach(dateTime => {
        const eventsOnDate = eventsByDate[dateTime];
        if (eventsOnDate.length > props.maxEventsPerDay) {
            const start = new Date(Instant.ofEpochMilli(parseInt(dateTime, 10))
                .atZone(ZoneId.SYSTEM)
                .with(LocalTime.MAX)
                // Subtract 30 seconds to prevent FullCalendar from letting it
                // bleed into the next day
                .minusSeconds(30)
                .toInstant()
                .toEpochMilli());
            let count = 0;
            events = events.filter(event => {
               if (event.extendedProps?.startOfDate !== dateTime)  {
                   return true;
               }
               const include = count < props.maxEventsPerDay;
               count++;
               return include
            });
            events.push({
                start,
                // If end is the same as start, FullCalendar seems to treat it
                // as if it rolls into the next day
                end: new Date(+start + 1),
                title: t('findAnEvent.seeMore'),
                id: dateTime,
                className: 'ResultsCalendar_SeeMore',
                extendedProps: {
                    firstEventUrlFragment: eventsOnDate[0].extendedProps?.eventUrlFragment,
                    startOfDate: eventsOnDate[0].extendedProps?.startOfDate
                }
            });
        }
    });
    const startOfMonth = new Date();
    startOfMonth.setDate(1);
    const validRange: DateRangeInput = {
        start: startOfMonth
    };

    function onClickEvent(arg: EventClickArg) {
        if (arg.event.classNames.includes('ResultsCalendar_SeeMore')) {
            props.scrollToEvent(arg.event.extendedProps.firstEventUrlFragment, arg.event.extendedProps.startOfDate);
        } else {
            history.push(arg.event.id);
        }
    }

    const eventsByFragment: Record<string, EventSearchResultExtended> = {};
    props.results.forEach(result => {
        eventsByFragment[result.eventUrlFragment] = result;
    });

    function eventRenderHook({ event: eventApi }: { event: any }): JSX.Element | undefined {
        const eventUrlFragment = eventApi._def.extendedProps.eventUrlFragment;
        const event: EventSearchResultExtended = eventsByFragment[eventUrlFragment];
        // Unclear why this would be undefined, but avoid an error just in case.
        // If anyone figures out how this could happen, go ahead and fix properly.
        if (!event) {
            return;
        }
        return (
            <>
                {event.eventIsVirtual && <span title={tCommon('listVirtualIndicator.virtualLabel')}>
                    <CloudCircle className={"VirtualIndicator"} />
                </span>}
                {event.ext.isSelfGuided && <span title={tCommon('listSelfGuidedIndicator.selfGuidedLabel')}>
                    <SelfGuidedEventIndicator className={"SelfGuidedIndicator"} />
                </span>}
                {eventApi.title}
            </>
        )
    }

    useEffect(() => {
        if (ref.current) {
            const date = new Date();
            if (props.month < date.getMonth()) {
                // View months from the next year.
                date.setFullYear(date.getFullYear() + 1);
            }
            date.setDate(1);
            date.setMonth(props.month);
            ref.current.getApi().gotoDate(date);
        }
    }, [ref.current, props.month]);

    function onDateSet(arg: DatesSetArg) {
        // The range may include dates from the previous/next month, so going to the midpoint seems like a simple
        // solution to find the effectively viewed month.
        const date = new Date((+arg.start + +arg.end)/2);
        props.setMonth(date.getMonth());
    }

    function onClickPrevMonth() {
        props.setMonth((props.month + 12 - 1) % 12);
    }

    function onClickNextMonth() {
        props.setMonth((props.month + 1) % 12);
    }

    function onClickToday() {
        props.setMonth(new Date().getMonth());
    }

    return (
        <div className={`ResultsCalendar ${viewingCurrentMonth ? "ViewingCurrentMonth" : ""} ${viewingFinalMonth ? "ViewingFinalMonth" : ""}${props.resultsLoading ? " ResultsCalendarLoading" : ""}`}>
            {!props.results.length && !props.resultsLoading && <NoResults
                usingFilters={props.usingFilters}
                resetFilters={props.resetFilters}
                viewVirtualOnly={props.viewVirtualOnly} />}
            <FullCalendar
                plugins={[ dayGridPlugin ]}
                initialView="dayGridMonth"
                events={events}
                eventDisplay={"block"}
                displayEventTime={false}
                eventClick={onClickEvent}
                ref={ref}
                datesSet={onDateSet}
                validRange={validRange}
                locale={i18n.language}
                eventContent={eventRenderHook}
                customButtons={{
                    toPrevious: {
                        icon: 'chevron-left',
                        hint: t('findAnEvent.previousMonth'),
                        click: onClickPrevMonth
                    },
                    toNext: {
                        icon: 'chevron-right',
                        hint: t('findAnEvent.nextMonth'),
                        click: onClickNextMonth
                    },
                    toToday: {
                        text: t('findAnEvent.today'),
                        click: onClickToday
                    }
                }}
                headerToolbar={{
                    left: 'title',
                    right: 'toToday toPrevious,toNext'
                }}
            />
        </div>
    )
};
