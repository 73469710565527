import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import "./LoggedOutNavigationMobile.scss";
import { UserContext } from "../../contexts/UserContext";
import NavigationElement from "./NavigationElement";
import { useHistory, useLocation } from "react-router-dom";
import { getIgniteRoute } from "../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS } from "../../constants/routes";

interface LoggedOutNavigationMobileProps {
    navigationShown: boolean;
    useClubDirectoryNav: boolean;
    setNavigationShown: Function;
}

function LoggedOutNavigationMobile(props: LoggedOutNavigationMobileProps): JSX.Element {
    const { t } = useTranslation('layout');
    const { user } = useContext(UserContext);
    const [classes, setClasses] = useState("");

    const location = useLocation();
    const history = useHistory();


    useEffect(
        () => {
            let classes = "NavigationMobile";
            if (props.navigationShown) {
                classes += " NavigationMobile_Show";
            }
            setClasses(classes);
        }, [props.navigationShown]);


    return (
        <div className={classes}>
            {user === null &&
                <div className="NavigationMobile_Container">


                    
                    {/* Register member */}
                    <NavigationElement setNavigationShown={props.setNavigationShown}
                        to={"/club-member/corpsite/link/launch"}>
                        {t('loggedOutNavigation.getStarted')}
                    </NavigationElement>

                    {/* LOGIN */}
                    <NavigationElement setNavigationShown={props.setNavigationShown}
                        to={"/login"}>
                        {t('loggedOutNavigation.login')}
                    </NavigationElement>

                </div>}
        </div>
    );
}

export default LoggedOutNavigationMobile;
