import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useLocation, useParams, useHistory, matchPath} from 'react-router-dom'
import Loading from "../../../components/ignite/layout/Loading";
import LeaderboardIcon from '@material-ui/icons/Equalizer';
import useClubById from "../../../hooks/useClubById";

import MoneyInfoPanel from "../../../components/ignite/Dashboard/MoneyInfoPanel";
import {Leaderboard} from "../../../components/ignite/Dashboard/Leaderboard";
import useCheckDevice from '../../../hooks/useCheckDevice'

import useGetLeaderboard from "../../../hooks/useGetLeaderboard";
import useGetMyActivity from "../../../hooks/useGetMyActivity";
import useGetMembers from "../../../hooks/useGetMembersActivity";
import TrackingActivityModal from '../../../components/ignite/TrackingActivityModal/TrackingActivityModal'

import ToDoTab from '../../../components/ignite/LeaderDashboard/ToDoTab'
import GeneralInfoPanel from '../../../components/ignite/LeaderDashboard/GeneralInfoPanel'
import useGetClubStats from "../../../hooks/useGetClubStats";
import useGetInvitedMembers from "../../../hooks/useGetInvitedMembers";
import useGetMissingActivity from "../../../hooks/useGetMissingActivity";
import EligibilityStatusModal from "../../../components/eligibilityStatusModal/EligibilityStatusModal";

import './LeaderDashboard.scss';
import {BODY_TABS, CONTENT_TYPE} from "../../../constants/dashboard";
import MembersTab from "../../../components/ignite/LeaderDashboard/MembersTab";
import useGetNotInvitedMembers from "../../../hooks/useGetNotInvitedMembers";
import useBadge from "../../../hooks/useBadge";
import useGetMyClubs from "../../../hooks/useGetMyClubs";
import SubHeader from "../../../components/ignite/SubHeader/SubHeader";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS } from "../../../constants/routes";
import useQuery from "../../../hooks/useQuery";

export default function LeaderDashboard(): JSX.Element {
    const { t } = useTranslation('ignite/dashboard');
    const [isOpenTrackingActivity, setIsOpenTrackingActivity] = useState(false)
    const [firstBadgeWasShow, setFirstBadgeWasShow] = useState(false)
    const { clubId } = useParams()

    const [club, isClubLoading] = useClubById(clubId)
    const {leaderboardList, isLoading: isLeaderboardLoading, fetchLeaderboard, leaderboardTotalCount} = useGetLeaderboard(clubId)
    const [myActivity, isMyActivityLoading, getMyActivity, prevActivity] = useGetMyActivity(clubId)
    const {shouldShowFirstBadge, setShouldShowFirstBadge} = useBadge(myActivity?.badgesEarned, isMyActivityLoading)
    const {members, clubIncome, isLoading: isMembersActivityLoading, fetchMembers} = useGetMembers(clubId)
    const [clubStats, isStatsLoading] = useGetClubStats(clubId)
    const { invitedMembers = [], isLoading: isMembersInvitedLoading, fetchInvitedMembers } = useGetInvitedMembers(clubId)
    const { notInvitedMembers = [], isLoading: isMembersNotInvitedLoading, fetchNotInvitedMembers } = useGetNotInvitedMembers(clubId)
    const {missingActivityMembers, isLoading: isMissingActivityLoading} = useGetMissingActivity(clubId)
    const [myClubs] = useGetMyClubs()
    const query = useQuery()
    const defaultContentType = query.get('content-type') as CONTENT_TYPE || CONTENT_TYPE.INITIAL

    const isLoading = isClubLoading || isLeaderboardLoading || isMyActivityLoading || isMembersActivityLoading ||
        isStatsLoading || isMembersInvitedLoading || isMissingActivityLoading || isMembersNotInvitedLoading
    const { clubName, clubImage } = club || {}

    const location = useLocation();
    const history = useHistory();
    const [isToDoList, setIsToDoList] = useState(location.pathname.toLowerCase().startsWith("/ignite/leader-dashboard/"));
    const [contentType, setContentType] = useState(defaultContentType);

    const [bodyTabValue, setBodyTabValue] = useState(BODY_TABS.LEADER_TO_DO_TAB);
    const onBodyTabChange = (event: React.ChangeEvent<{}>, newValue: BODY_TABS) => {
        setBodyTabValue(newValue);
        if (newValue === BODY_TABS.MEMBERS_TAB) goToManageMembersRoute()
        if (newValue === BODY_TABS.LEADER_TO_DO_TAB) goToTodoRoute()
    };

    const onContentChange = (newValue: CONTENT_TYPE) => {
        setContentType(newValue);
    };

    useEffect(() => {
        const unsubscribe = history.listen((newRoute) => {
            if (matchPath(newRoute.pathname, IGNITE_ROUTE_PATTERNS.LEADER_MANAGE_MEMBERS)){
                setBodyTabValue(BODY_TABS.MEMBERS_TAB)
            }
            if (matchPath(newRoute.pathname, IGNITE_ROUTE_PATTERNS.LEADER_DASHBOARD)){
                setBodyTabValue(BODY_TABS.LEADER_TO_DO_TAB)
            }
        })
        return unsubscribe
    }, [])

    useEffect(() => {
        if (!club) return

        setIsToDoList(location.pathname.toLowerCase().startsWith("/ignite/leader-dashboard/"));
        setBodyTabValue(location.pathname.toLowerCase().startsWith("/ignite/leader-dashboard/") ?
            BODY_TABS.LEADER_TO_DO_TAB : BODY_TABS.MEMBERS_TAB);

    }, [location.pathname, club]);

    const hasNewBadge = prevActivity && (prevActivity?.badgesEarned?.length !== myActivity?.badgesEarned?.length)

    const additionalActionOnFirstBadgeClick = useCallback(() => {
        if (hasNewBadge && firstBadgeWasShow) {
            setIsOpenTrackingActivity(false)
            setShouldShowFirstBadge(false)
            setFirstBadgeWasShow(false)
        }
    }, [shouldShowFirstBadge, firstBadgeWasShow])

    useEffect(() => {
        if (prevActivity && hasNewBadge && !isOpenTrackingActivity) {
            setIsOpenTrackingActivity(true)
            setFirstBadgeWasShow(true)
        }
    }, [myActivity?.badgesEarned?.length])

    const { isDesktop } = useCheckDevice()

    const shouldShowPanelsInTop = !isDesktop
    const shouldShowAsidePanel = isDesktop
    const goToTodoRoute = () => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, {':clubId': clubId}))
    const goToManageMembersRoute = () => history.push(getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_MANAGE_MEMBERS, {':clubId': clubId}))

    if (isLoading) return <Loading loading={isLoading} />

    if (!club) return <div>{t('noSuchClub')}</div>
    return (
        <div className='leader-dashboard-page'>
            <EligibilityStatusModal retryRequest/>
            <TrackingActivityModal
                isOpen={isOpenTrackingActivity}
                myActivity={myActivity}
                setIsOpenTrackingActivity={setIsOpenTrackingActivity}
                getMyActivity={getMyActivity}
                fetchLeaderboard={fetchLeaderboard}
                fetchMembersActivity={fetchMembers}
                additionalActionOnFirstBadgeClick={additionalActionOnFirstBadgeClick}
            />
            <div className='leader-dashboard-content'>
                <div className="top-content">
                    <SubHeader
                        clubName={clubName}
                        myClubs={myClubs}
                        clubImage={clubImage}
                        location={club.location}
                    />
                </div>
                <div className="bottom-content">
                    <div className="members">
                        {shouldShowPanelsInTop && (
                            <div className="info-panel-wrapper">
                                <div className="info-panel">
                                    <div className="main-content">
                                        <MoneyInfoPanel
                                            income={myActivity?.income}
                                            clubMoney={clubIncome}
                                            clubType={club.type}
                                        />
                                        <GeneralInfoPanel
                                            bonuses={clubStats?.bonusIncome}
                                            activityCount={clubStats?.activityCount}
                                            invited={clubStats?.membersInvited}
                                            eligible={clubStats?.membersEligible}
                                            signedUp={clubStats?.membersSignedUp}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {isToDoList &&
                            <ToDoTab
                                contentType={contentType}
                                onContentChange={onContentChange}
                                bodyTabValue={bodyTabValue}
                                onBodyTabChange={onBodyTabChange}
                                members={members}
                                leaderboardList={leaderboardList}
                                leaderboardTotalCount={leaderboardTotalCount}
                                invitedMembers={invitedMembers}
                                fetchInvitedMembers={fetchInvitedMembers}
                                fetchNotInvitedMembers={fetchNotInvitedMembers}
                                fetchLeaderboard={fetchLeaderboard}
                                missingActivity={missingActivityMembers}
                                club={club}
                            />}
                        {!isToDoList &&
                            <MembersTab
                                invitedMembers={invitedMembers}
                                notInvitedMembers={notInvitedMembers}
                                missingActivity={missingActivityMembers}
                                bodyTabValue={bodyTabValue}
                                onBodyTabChange={onBodyTabChange}
                                leaderboardList={leaderboardList}
                                leaderboardTotalCount={leaderboardTotalCount}
                                members={members}
                                club={club}
                                fetchMembersActivity={fetchMembers}
                                fetchLeaderboard={fetchLeaderboard}
                                getMyActivity={getMyActivity}
                                setIsOpenTrackingActivity={setIsOpenTrackingActivity}
                            />
                        }
                    </div>
                    {shouldShowAsidePanel && <div className="leaderboard">
                        <MoneyInfoPanel
                            income={myActivity?.income}
                            clubMoney={clubIncome}
                            clubType={club.type}
                        />
                        <GeneralInfoPanel
                            bonuses={clubStats?.bonusIncome}
                            activityCount={clubStats?.activityCount}
                            invited={clubStats?.membersInvited}
                            eligible={clubStats?.membersEligible}
                            signedUp={clubStats?.membersSignedUp}
                        />

                        <div className="leaderboard__title">
                            <LeaderboardIcon className="icon" />

                            <div className="text">
                                {club.clubName}&nbsp;{t('clubLeaderboard')}
                            </div>
                        </div>
                        <Leaderboard
                            list={leaderboardList}
                            totalCount={leaderboardTotalCount}
                            fetchLeaderboard={fetchLeaderboard}
                        />
                    </div>}
                </div>
            </div >
        </div>
    );
}
