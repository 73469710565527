import React, { ChangeEvent, useContext, useState } from "react";
import "./SelectPassions.scss";
import { useTranslation } from "react-i18next";
import { getClubImageUrl } from "../../util/Util";
import Checkmark from "../icon/Checkmark";
import SectionHeader from "../common/SectionHeader";
import { UserContext } from "../../contexts/UserContext";
import { ArrowRightAlt } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import Button, { ButtonStyles } from "../common/Button";
import { updateFavoritePassions } from "../../services/MyProfileService";
import { authenticatedFetch } from "../../services/UserService";
import { Link } from "react-router-dom";
import {GetPassionResponseBody} from "../../services/Models";
import HaveQuestions from "../haveQuestions/HaveQuestions";

interface SelectPassionsProps {
    onChangeSelectedPassions: Function;
    selectedPassionIds: number[];
    allPassions: GetPassionResponseBody[];
}

function SelectPassions(props: SelectPassionsProps): JSX.Element {
    const {t} = useTranslation('pages');
    const [currentSelectedPassionIds, setCurrentSelectedPassionIds] = useState<number[]>(props.selectedPassionIds);
    const [saveAsMyPassions, setSaveAsMyPassions] = useState<boolean>(true);
    const {user} = useContext(UserContext);

    function isSelected(passion: GetPassionResponseBody) {
        return currentSelectedPassionIds.includes(passion.id);
    }

    function onClickPassion(passionId: number) {
        if (currentSelectedPassionIds.includes(passionId)) {
            setCurrentSelectedPassionIds(
                currentSelectedPassionIds.filter(id => id !== passionId));
        } else {
            setCurrentSelectedPassionIds([...currentSelectedPassionIds, passionId]);
        }
    }

    function onChangeSaveAsMyPassions(event: ChangeEvent<HTMLInputElement>) {
        setSaveAsMyPassions(event.target.checked)
    }

    async function submit() {
        if (saveAsMyPassions) {
            // When this is awaited, it breaks the Find an Event page in some
            // cases for some reason. Must be a race condition but there isn't
            // enough time to look into it.
            // Note: The specific case it breaks is that if the user goes to
            // the find events page without any passions selected, then picks
            // at least one passion and clicks "Find Events" with "Save as my
            // Passions" checked, then the page will load but the form will not
            // auto-search.
            updateFavoritePassions(authenticatedFetch, currentSelectedPassionIds);
        }
        props.onChangeSelectedPassions(currentSelectedPassionIds, saveAsMyPassions);
    }

    return (
        <div className="SelectPassions">
            <div className={"SelectPassions_WelcomeBack"}>
                {user !== null && t('findAnEvent.welcome', {"name": user.firstName})}
            </div>
            <SectionHeader title={t('findAnEvent.letsFindEvent')}/>
            <div className="SelectPassions_Panel">
                <div
                    className="SelectPassions_Panel_Text">{t('findAnEvent.checkPassionActivities')}</div>
                <Link
                    to='/find-an-event?passionsSelected'>{t('findAnEvent.seeAllEvents')}
                    <ArrowRightAlt/></Link>
            </div>
            <div className="SelectPassions_Passions">
                {props.allPassions.map(passion => {
                    const selected = isSelected(passion);
                    return (
                        <div key={passion.id}
                             className={`${selected ? "selected" : ""} SelectPassions_Passion`}
                             onClick={() => onClickPassion(passion.id)}
                             style={{backgroundImage: `url(${getClubImageUrl(passion).thumbnail})`}}>
                            <div className="SelectPassions_Passion_Top">
                                <div
                                    className="SelectPassions_Passion_Overlay"></div>
                                <Checkmark/>
                            </div>
                            <div className="SelectPassions_Passion_Bottom">
                                <div
                                    className="SelectPassions_Passion_PassionName">{passion.passionName}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="SelectPassions_CheckboxContainer">
                <Checkbox checked={saveAsMyPassions}
                          onChange={onChangeSaveAsMyPassions}/>
                {t('findAnEvent.saveAsMyPassions')}
            </div>
            <Button type="button"
                    buttonStyle={ButtonStyles.FilledPrimary}
                    className="SelectPassions_Submit"
                    clickHandler={submit}>{t('findAnEvent.findEvents')}</Button>
            <HaveQuestions searchPage={true} />
        </div>
    )
}

export default SelectPassions;
