import React, {useContext, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./EventView.scss";
import {ButtonStyles} from "../common/Button";
import PlayButton from "../common/PlayButton";
import {fixQuestionMarkCharacters} from "../../util/Util";
import {UserContext} from "../../contexts/UserContext";
import {Link} from "react-router-dom";
import ConfirmationModal from "../common/ConfirmationModal";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch} from "../../services/UserService";
import Separator from "../layout/Separator";
import EventViewFullDesktop from "./EventViewFullDesktop";
import EventViewFullMobile from "./EventViewFullMobile";


interface EventViewProps {
    startsAtNativeJsDate: string | Date;
    urlFragment: string;
    eventName: string;
    eventDescription: string
    eventType: string | null;
    clubName: string;
    cancelled: boolean;
    eventUrlFragment: string;
    clubImage: string;
    buttonClickHandler: Function
    className?: string
    rsvpCount: number;
    attendeeCount: number;
}


export default function EventView(props: EventViewProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let eventImage = props.clubImage;
    let className = props.className;

    const {user, hasLeadershipRole} = useContext(UserContext);
    const [cancelModalShown, setCancelModalShown] = useState(false);
    const [cancelled, setCancelled] = useState(props.cancelled);

    let classes = 'CCEventView';
    if (className !== undefined) {
        classes += ' ' + className;
    }

    async function cancelEvent() {
        const config = await loadConfig();

        const cancelRequest =
            new Request(
                `${config.apiOrigin}/club-leader/events/cancel/${encodeURIComponent(props.eventUrlFragment)}`,
                {method: "POST", headers: {"Accept": "application/json"}});

        const cancelResponse = await authenticatedFetch(cancelRequest);

        if (cancelResponse === null || !cancelResponse.ok) {
            // dang
            return;
        }

        setCancelled(true);
    }

    function onCancelClick() {
        setCancelModalShown(true);
    }

    function onCancelOkClick() {
        cancelEvent().then(() => {
            setCancelModalShown(false);
            window.scrollTo(0, 0);
        });
    }

    function onCancelCancelClick() {
        setCancelModalShown(false);
    }

    const cancelConfirmationText = (() => {

        let text = t('eventView.cancelConfText');

        if (props.rsvpCount > 1)
            text += " " + t('eventView.multipleRSVPs', {count: props.rsvpCount});
        else if (props.rsvpCount === 1)
            text += " " + t('eventView.singularRSVP');

        if (props.attendeeCount > 1)
            text += " " + t('eventView.multipleAttendees', {count: props.attendeeCount});
        else if (props.attendeeCount === 1)
            text += " " + t('eventView.singularAttendee');

        return text;

    })();

    return (
        <div className={classes}>
            {cancelled &&
            <div className="CCEventView_CancelledWarning">
                <div className="CCEventView_CancelledWarningTitle">{t('eventView.cancelledTitle')}
                </div>
                <div className="CCEventView_CancelledWarningMessage">
                    {t('eventView.cancelledMessage')}
                </div>
            </div>}
            <div className={`CCEventView_Desktop`}>
                <div className={`CCEventView_Desktop_Image_And_Details`}>
                    <img alt="" src={eventImage} className={`CCEventView_Image`}/>
                    <div className={`CCEventView_Details ${classes}`}>
                        <EventViewFullDesktop eventName={props.eventName}
                                              eventDescription={fixQuestionMarkCharacters(props.eventDescription)}
                                              eventUrlFragment={props.urlFragment}
                                              eventDate={props.startsAtNativeJsDate}
                                              clubName={props.clubName}
                                              clubUrlFragment={props.urlFragment}
                                              buttonClickHandler={props.buttonClickHandler}
                                              buttonText={t('eventView.countMeInButtonText')}
                                              eventType={props.eventType}/>
                    </div>
                </div>
                {hasLeadershipRole(props.urlFragment) && <div className="CCEventView_Links">
                    <ConfirmationModal
                        shown={cancelModalShown}
                        okClickHandler={onCancelOkClick}
                        cancelClickHandler={onCancelCancelClick}
                    >{cancelConfirmationText}</ConfirmationModal>
                    {!cancelled && <>
                        <div className={"CCEventView_Cancel"} onClick={onCancelClick}>{t('eventView.cancelEventAction')}
                        </div>
                        <Separator className={"CCEventView_LinksSeparator"}/></>}
                    <Link to={`/edit-activity/${props.eventUrlFragment}`}>{t('eventView.editEvent')}</Link><Separator className={"CCEventView_LinksSeparator"}/>
                    <Link to={`/event/${props.eventUrlFragment}`}>{t('eventView.viewAsMember')}</Link>
                </div>}
            </div>
            <div className={`CCEventView_Mobile`}>
                <div className={`CCEventView_Mobile`}>
                    <div className={`CCEventView_Details`}>
                        <EventViewFullMobile eventName={props.eventName}
                                             eventDescription={fixQuestionMarkCharacters(props.eventDescription)}
                                             eventDate={props.startsAtNativeJsDate}
                                             clubName={props.clubName}
                                             eventType={props.eventType}/>
                    </div>
                </div>
                <img alt="" src={eventImage} className={`CCEventView_Image`}/>
                {!hasLeadershipRole(props.urlFragment) && <PlayButton buttonStyle={ButtonStyles.FilledSecondary}
                                                    clickHandler={props.buttonClickHandler}
                                                    text={t('eventView.countMeInButtonText')}
                                                    className={`CountMeInButton`}/>}
                {hasLeadershipRole(props.urlFragment) && <div className="CCEventView_Links">
                    <ConfirmationModal
                        shown={cancelModalShown}
                        okClickHandler={onCancelOkClick}
                        cancelClickHandler={onCancelCancelClick}
                    >{cancelConfirmationText}</ConfirmationModal>
                    {!cancelled && <>
                        <div className={"CCEventView_Cancel"} onClick={onCancelClick}>{t('eventView.cancelEventAction')}
                        </div>
                        <Separator className={"CCEventView_LinksSeparator"}/></>}
                    <Link to={`/edit-activity/${props.eventUrlFragment}`}>{t('eventView.editEvent')}</Link><Separator className={"CCEventView_LinksSeparator"}/>
                    <Link to={`/event/${props.eventUrlFragment}`}>{t('eventView.viewAsMember')}</Link>
                </div>}
            </div>
        </div>
    );
}