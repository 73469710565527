import React from "react";
import ClubSetupForm from "../../../components/ignite/ClubSetupForm/ClubSetupForm";
import { useTranslation } from 'react-i18next';

import "./ClubSetup.scss";

export default function ClubSetup() {
    const { t } = useTranslation('ignite/leaderOnboarding');

    return (
        <div className="club-setup-page">
            <div
                className="title"
                dangerouslySetInnerHTML={{ __html: t('clubSetup.title') }}
            />
            <ClubSetupForm />
        </div>
    )
}
