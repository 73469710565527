import { useState, useEffect, useMemo } from "react";
import { useHistory } from 'react-router-dom';
import useGetClubs from './useGetClubs';
import { getTokensFromClub, getTokensFromStr } from '../util/ignite/ClubFinder.utils'
import { IClub } from '../types/clubFinder'
import { debounce } from "@material-ui/core";
import useGetMyClubs from "./useGetMyClubs";

const DEBOUNCE_DELAY = 200

const useClubFinder = (): any => {
    const [clubs = []] = useGetClubs()
    const [myClubs] = useGetMyClubs()

    const [tokens, setTokens] = useState<IClub[] | []>([])
    const [selectedTokens, setSelectedTokens] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState('')
    const [filteredClub, setFilteredClub] = useState<IClub[] | []>()

    const myClubIds = myClubs?.map(myClub => myClub.id)

    const history = useHistory()

    const sortedClubs = useMemo(() => {
        const unmappedClubs = clubs.filter(club => !myClubIds?.includes(club.id))

        return unmappedClubs.sort((prevClub, nextClub) => prevClub.clubName.localeCompare(nextClub.clubName));
    }, [clubs, myClubs]);

    useEffect(() => {
        if (sortedClubs) setFilteredClub(sortedClubs)
    }, [sortedClubs])

    useEffect(() => {
        debounce(() => {
            if (searchValue.length) {
                const tokenedSearchValue = getTokensFromStr(searchValue)

                setTokens(sortedClubs.filter(club => tokenedSearchValue.every(token => {
                    const tokenedClub = getTokensFromClub(club)

                    return tokenedClub.some((tokenClub) => tokenClub.includes(token))
                })))
            }
        }, DEBOUNCE_DELAY)()

    }, [sortedClubs, searchValue])

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!searchValue) return
        const tokenedSearchValue: string[] = getTokensFromStr(searchValue)

        const filteredClubs = sortedClubs.filter(club => tokenedSearchValue.every(token => {
            const tokenedClub = getTokensFromClub(club)
            return tokenedClub.some((tokenClub) => tokenClub.includes(token))
        }))

        setSelectedTokens(tokenedSearchValue)
        setFilteredClub(filteredClubs)
    }

    const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>, _: string, reason: string) => {
        const value = e.target?.value
        const newValue = value === undefined ? '' : value.toLocaleLowerCase()
        if (reason === 'clear') {
            setFilteredClub(sortedClubs)
            setSelectedTokens([])
        }
        setSearchValue(newValue)
    }

    const onSelectToken = (selectedToken: IClub) => {
        if (selectedToken) {
            history.push(`/ignite/club-requesting/${selectedToken.id}`)
        }
    }

    const onRemoveToken = (token: string) => {
        const newTokens = selectedTokens.filter(item => item !== token)
        setSelectedTokens(newTokens)
        setSearchValue('');
        setFilteredClub(sortedClubs);
    }

    return {
        onSubmit,
        onRemoveToken,
        onSelectToken,
        onHandleChange,
        tokens,
        selectedTokens,
        filteredClub,
        searchValue,
    }
}

export default useClubFinder
