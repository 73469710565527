import React from "react";
import "./DetailsBox.scss";

interface DetailsBoxProps {
    title?: any,
    main?: any,
    footer?: any,
    children?: any
    className?: string;
}

export default function DetailsBox(props: DetailsBoxProps): JSX.Element {

    let classes = 'CCDetailsBox';
    let className = props.className;
    let main = props.main;
    let title = props.title;
    let footer = props.footer;
    let children = props.children;

    if (className !== undefined) {
        classes += ' ' + className;
    }

    if (children !== undefined) {
        return (
            <div className={classes}>
                <div className={`CCDetailsBox_Content`}>
                    {children}
                </div>
            </div>)
    }

    return (
        <div className={classes}>
            <div className={`CCDetailsBox_Content`}>
                <div className={`CCDetailsBox_Title`}>
                    {title}
                </div>
                <div className={`CCDetailsBox_Main`}>
                    {main}
                </div>
                <div className={`CCDetailsBox_Footer`}>
                    {footer}
                </div>
            </div>
        </div>
    );
}