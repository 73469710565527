import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core'
import PlayIcon from '@material-ui/icons/PlayArrow';
import useReferral from '../../../hooks/useReferral'
import { Link, useHistory, useParams } from 'react-router-dom'
import Loading from "../../../components/ignite/layout/Loading";
import { UserContext } from "../../../contexts/UserContext";
import useMyClubStatus from "../../../hooks/useMyClubStatus";

import './SuccessfulSignup.scss';
import { CLUB_ROLES_STRINGS } from "../../../constants/dashboard";
import { getIgniteRoute } from "../../../util/ignite/routes.utils";
import { IGNITE_ROUTE_KEY } from "../../../constants/routes";
import { getClubLeaderData } from "../../../util/ignite/Dashboard.utils";

const CLUB_CONTACT_METHODS_URL = '/ignite/club-contact-methods/'

export default function SuccessfulSignup() {
    const { t } = useTranslation('ignite/registration');
    const { clubId } = useParams()

    const myClubStatus = useMyClubStatus({ id: Number(clubId) })
    const [club, isLoading, confirmReferral] = useReferral(myClubStatus)
    const { user, syncUser } = useContext(UserContext);

    const history = useHistory()

    if (isLoading) return <Loading loading={isLoading} />

    if (!club) return <div>{t('registrationForm.noSuchClub')}</div>

    const { clubName, location, clubImage } = club
    const { city, countrySubdivision } = location || {}

    const clubLeaderName = getClubLeaderData(club)
    const locationRow = [city, countrySubdivision].filter(Boolean).join(', ')
    const onClick = () => {
        let route = null
        switch (myClubStatus) {
            case CLUB_ROLES_STRINGS.UNKNOWN:
                confirmReferral().finally(() => {
                    syncUser()
                    route = `${CLUB_CONTACT_METHODS_URL}${club.id}`
                    history.push(route)
                })
                break
            case CLUB_ROLES_STRINGS.MEMBER:
                route = getIgniteRoute(IGNITE_ROUTE_KEY.DASHBOARD, { ':clubId': clubId })
                history.push(route)
                break
            default:
                route = getIgniteRoute(IGNITE_ROUTE_KEY.LEADER_DASHBOARD, { ':clubId': clubId })
                history.push(route)
                break
        }
    }

    return (
            <>
                <div className='successful-signup-content'>
                    <div className='successful-signup-club'>
                        <div className='successful-signup-club__title'>
                            <b>{t('successfulSignup.congrats', { firstName: user?.firstName })}</b>
                            <div className='successful-signup-club__status'>
                                {t('successfulSignup.youAreSignUp')}
                            </div>
                        </div>
                        <hr className="successful-signup-club__separator" />
                        <div className='successful-signup-club__description'>
                            <div>{t('successfulSignup.youWereInvited')}</div>
                            <b>{clubName}.</b>
                            <div>{t('successfulSignup.isItRightClub')}</div>
                        </div>
                        <Box className="club-info">
                            <div className="club-info__image-wrapper">
                                <img className="club-info__logo" src={"/images/passions/" + clubImage} alt="" />
                            </div>
                            <div>
                                <b className="club-info__name">{clubName}</b>
                                {locationRow && <div>{locationRow}</div>}
                                {clubLeaderName && <div>{t('successfulSignup.clubLeader')} <b>{clubLeaderName}</b></div>}
                            </div>
                        </Box>
                        <div className='club-button-wrapper'>
                            <Button
                                className='club-button'
                                variant="outlined"
                                onClick={onClick}
                            >
                                {t('successfulSignup.continueButtonText')}
                                <PlayIcon className='club-button__icon' />
                            </Button>
                        </div>
                    </div>
                </div>

                <Box className='not-right-club'>
                    <b>{t('successfulSignup.notTheRightClub')}</b>
                    <div>{t('successfulSignup.letsFindBest')}</div>
                    <Link
                        className="not-right-club__finder"
                        to="/ignite/club-finder"
                    >
                        {t('successfulSignup.clubFinder')}
                    </Link>
                </Box>
            </>
    );
}
