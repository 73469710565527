import React, { useState } from "react";
import { FormControl, Box, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import Button, {
    ButtonStyles
} from "../../common/Button";
import { useTranslation } from 'react-i18next';
import { IClub } from '../../../types/clubFinder'

import "./ClubFinderForm.scss";

interface IClubFinderFormProps {
    handleChange: (event: React.ChangeEvent<{}>) => void
    onSelectToken: Function
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    searchValue: String
    tokens: Array<IClub>
}

const ClubFinderForm = ({
    handleChange,
    onSelectToken,
    onSubmit,
    searchValue,
    tokens,
}: IClubFinderFormProps): JSX.Element => {
    const { t } = useTranslation('ignite/clubFinder');
    const [open, setOpen] = useState(false);
    const closePopper = () => setOpen(false);
    const openPopper = () => setOpen(true);

    return (
        <>
            <Box className="club-finder-box">
                <form onSubmit={onSubmit} role="search">
                    <FormControl variant="standard" className="form-control">
                        <SearchIcon className="search-icon" />
                        <label htmlFor="search-id" hidden aria-hidden="true">{t('search')}</label>
                        <Autocomplete
                            options={tokens}
                            getOptionLabel={(option) => option.clubName || ''}
                            defaultValue={null}
                            className="search-input"
                            filterOptions={x => x}
                            open={open}
                            onOpen={openPopper}
                            onClose={closePopper}
                            freeSolo
                            id="search-id"
                            renderOption={(option) => (<Box className="autocomplete-item">
                                {option.clubName}
                            </Box>)
                            }
                            onChange={(_, newValue) => {
                                onSelectToken(newValue);
                            }}
                            onInputChange={handleChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    placeholder={t('searchPlaceholder')}
                                    size="small"
                                    inputProps={{
                                        ...params.inputProps,
                                        value : searchValue,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                                closePopper()
                                            }
                                        },
                                    }}
                                />

                            )}
                        />
                        <Button
                            type="submit"
                            className='search-button'
                            buttonStyle={ButtonStyles.FilledPrimary}
                        >
                            {t('search')}
                        </Button>
                    </FormControl>
                </form>
            </Box>
        </>
    )
}

export default ClubFinderForm
