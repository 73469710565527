import React from "react";

export interface WrappableEmailAddressProperties {
    emailAddress: string;
}

function WrappableEmailAddress(props: WrappableEmailAddressProperties): JSX.Element {
    const emailAddress = props.emailAddress;
    const emailAddressParts = emailAddress.split("@");

    return (
        <>
            {emailAddressParts.length !== 2 &&
                <>{emailAddress}</>}
            {emailAddressParts.length === 2 &&
                <>
                    <>{emailAddressParts[0]}</>
                    <>&#8203;@</>
                    <>{emailAddressParts[1]}</>
                </>}
        </>
    );
}

export default WrappableEmailAddress;