import React, { useCallback, useState } from 'react';
import './EventSearchResults.scss';
import { useTranslation } from 'react-i18next';
import Chip from '@material-ui/core/Chip';
import LocationView from '../common/LocationView';
import Button, { ButtonStyles } from '../common/Button';
import { Divider } from '@material-ui/core';
import PassionImage from '../passionImage/PassionImage';
import {
  EventSearchResponseBody,
  EventSearchResultExtended,
  EventSearchSort,
} from '../../pages/findAnEvent/FindAnEventPage';
import ListSelfGuidedIndicator from '../common/ListSelfGuidedIndicator';
import SortBySelect from './SortBySelect';
import NoResults from './NoResults';
import { useHistory } from 'react-router-dom';
import { getEventAndClub } from '../../services/EventSearchService';

interface ResultsListProps {
  searchResponseBody: EventSearchResponseBody;
  sortByEventStartsAt: () => void;
  sortByDistance: () => void;
  onSortSelected: (sort: EventSearchSort) => void;
  results: EventSearchResultExtended[];
  usingFilters: boolean;
  resetFilters: Function;
  viewVirtualOnly: Function;
}

export default function ResultsList(props: ResultsListProps): JSX.Element {
  const { t } = useTranslation('pages');
  const { searchResponseBody, results } = props;
  const [eventUrlBeingVerified, setEventUrlBeingVerified] = useState<string>();
  const [forbiddenEventUrlFragments, setForbiddenUrlFragments] = useState<
    string[]
  >([]);
  const history = useHistory();
  const handleEventClick = useCallback(
    (eventUrlFragment: string, extUrl: string) => {
      if (eventUrlBeingVerified) return;
      if (forbiddenEventUrlFragments.includes(eventUrlFragment)) return;
      setEventUrlBeingVerified(eventUrlFragment);
      getEventAndClub(eventUrlFragment)
        .then(({ error }) => {
          if (error && error.includes('unauthorizedError')) {
            setForbiddenUrlFragments((curr) => [...curr, eventUrlFragment]);
          } else {
            history.push(extUrl);
          }
        })
        .finally(() => {
          setEventUrlBeingVerified(undefined);
        });
    },
    [eventUrlBeingVerified],
  );
  return (
    <div className="ResultsList">
      <SortBySelect
        sort={searchResponseBody.sort}
        onChangeSort={props.onSortSelected}
      />
      <Divider className={'ResultsList_Divider'} />
      {!results.length && (
        <NoResults
          usingFilters={props.usingFilters}
          resetFilters={props.resetFilters}
          viewVirtualOnly={props.viewVirtualOnly}
        />
      )}
      {results.map((result) => {
        const isForbidden = forbiddenEventUrlFragments.includes(
          result.eventUrlFragment,
        );
        return (
          <div
            className={`Result ${isForbidden ? 'Result_Forbidden' : ''}`}
            key={result.eventUrlFragment + (result.occurrenceId ?? '')}
          >
            <PassionImage
              passion={result.passion}
              wide={true}
              thumbnail={false}
            />
            <div
              className={
                'Result_Line Result_LineHorizontal Result_LineDateTime'
              }
            >
              <div className={'Result_Date'}>
                {result.ext.dateWithDayOfWeek}
              </div>
              <div className={'Result_Time'}>
                <span className={'Result_Time_Time'}>
                  {result.ext.timeStart}
                </span>{' '}
                -{' '}
                <span className={'Result_Time_Time'}>{result.ext.timeEnd}</span>
              </div>
            </div>
            <div
              className={
                'Result_Line Result_LineVertical Result_LineEventTypeName'
              }
            >
              <div className={'Result_Type'}>{result.passion.passionName}</div>
              <div className={'Result_EventName'}>{result.eventName}</div>
            </div>
            <div className={'Result_Line Result_LineLocation'}>
              <div className={'Result_Location'}>
                {result.eventIsVirtual && (
                  <Chip
                    className={'EventSearchResults_Chip'}
                    label={t('findAnEvent.virtualEvent')}
                  />
                )}
                {!result.eventIsVirtual && (
                  <LocationView
                    location={result.location}
                    inlineMobile={true}
                  />
                )}
              </div>
              {result.ext.isSelfGuided && <ListSelfGuidedIndicator />}
              {/*<div className={"Result_Level"}></div>*/}
            </div>
            <div className={'Result_Line Result_LineVertical'}>
              <Button
                className={'Action_Button'}
                onClick={() =>
                  handleEventClick(result.eventUrlFragment, result.ext.url)
                }
                buttonStyle={ButtonStyles.UnfilledWithBorder}
                disabled={isForbidden}
              >
                {isForbidden
                  ? t('findAnEvent.notCoveredInfo')
                  : eventUrlBeingVerified === result.eventUrlFragment
                  ? t('findAnEvent.verifyingButtonText')
                  : t('findAnEvent.detailsButtonText')}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
