

import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const POST_SETUP_CLUB_URL = '/clubs/unverified'

const postCreateClub = async (body: any): Promise<any> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${POST_SETUP_CLUB_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    try {
        const response = await authenticatedFetch(request);

        if (response?.ok) {
            return response.json()
        }
        const error = await response?.text()

        throw new Error(error)
    }
    catch (e) {
        console.error(e);
    }
}

export default postCreateClub;
