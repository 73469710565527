import React, { useEffect, useRef, useState } from "react";
import {
    Checkbox, TableFooter, Table, TableBody, TableCell,
    TableContainer, TableRow, TablePagination
} from '@material-ui/core'
import EyeIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Create';
import MonitorIcon from '@material-ui/icons/Tv';
import PersonIcon from '@material-ui/icons/Person';
import PaginationActions from "./PaginationActions";
import MembersTableHead from "./MembersTableHead";
import { memberDashboardConfig } from "../../../../configs/memberDashboard";
import SelectionPanel from "../SelectionPanel";
import { IUser, IUseSelectLogicReturn } from "../../../../types/dashboard";
import { CLUB_ROLES_FE_STRINGS, MEMBER_TABS } from "../../../../constants/dashboard";
import { IClub } from "../../../../types/clubFinder";
import cn from "classnames";

// Create a new type for the TablePagination component with 'any' type
// to address a TypeScript problem related to passing the onPageChange prop,
// inexplicably, a TypeScript error is encountered in this scenario.
const TablePaginationAny = TablePagination as any;

enum Order {
    ASC = 'asc',
    DESC = 'desc'
}
const roleField = 'role'
const typeField = 'type'
const fullNameField = 'fullName'

interface IMembersTableProps {
    openMemberInviteModal: Function
    selectionData: IUseSelectLogicReturn
    rows: IUser[]
    memberTabValue: MEMBER_TABS
    onRemoveMembers: Function
    onEditMember: Function
    onUpdateRoles: Function
    club: IClub
    isModalOpen: boolean
    getIsDisabledEditIcon: Function
    fetchInvitedMembers: Function
    fetchNotInvitedMembers: Function
    fetchMissingActivityMembers: Function
    fetchMembers: Function
    isInvitedMembersTab: boolean
    isNotInvitedMembersTab: boolean
    isAllMembersTab: boolean
    isMissingActivityMembersTab: boolean
    getTotalCount: Function
    remindMembers: Function
    setLogActivityId: Function
    isDisabledViewIcon: boolean
    searchValue: string
}

export function MembersTable({
    rows,
    openMemberInviteModal,
    selectionData,
    memberTabValue,
    onRemoveMembers,
    onEditMember,
    onUpdateRoles,
    club,
    isModalOpen,
    getIsDisabledEditIcon,
    fetchInvitedMembers,
    fetchMembers,
    fetchNotInvitedMembers,
    fetchMissingActivityMembers,
    isInvitedMembersTab,
    isNotInvitedMembersTab,
    isAllMembersTab,
    isMissingActivityMembersTab,
    getTotalCount,
    remindMembers,
    setLogActivityId,
    isDisabledViewIcon,
    searchValue
}: IMembersTableProps) {
    const [order, setOrder] = useState<Order>(Order.ASC);
    const [orderBy, setOrderBy] = useState<keyof IUser>(fullNameField);
    const [page, setPage] = useState(0);
    const rowsPerPage = memberDashboardConfig.MEMBERS_AMOUNT_PER_PAGE
    const timerRef = useRef<null | number>(null)
    const {
        selectedCount,
        handleSelectAllClick,
        isSelected,
        handleSelectClick,
        handleSelectAllClickFromCheckbox
    } = selectionData

    // role field on FE means type field on BE
    const getFormattedSortBy = (field: keyof IUser) => field === roleField ? typeField : field

    const fetchMembersCurrentMembers = ({ sortOrder, formattedSortBy, skip = 0, searchValue }: { sortOrder: Order, formattedSortBy: keyof IUser, skip?: number, searchValue?: string }) => {
        if (isInvitedMembersTab && formattedSortBy === fullNameField) fetchInvitedMembers({ sortOrder, sortBy: formattedSortBy, skip, searchValue })
        if (isNotInvitedMembersTab && formattedSortBy === fullNameField) fetchNotInvitedMembers({ sortOrder, sortBy: formattedSortBy, skip, searchValue })
        if (isAllMembersTab) fetchMembers({ sortOrder, sortBy: formattedSortBy, skip, searchValue })
        if (isMissingActivityMembersTab) fetchMissingActivityMembers({ sortOrder, sortBy: formattedSortBy, skip, searchValue })
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof IUser,
    ) => {
        const isAsc = orderBy === property && order === Order.ASC;
        const sortOrder = isAsc ? Order.DESC : Order.ASC
        const formattedSortBy = getFormattedSortBy(property)
        fetchMembersCurrentMembers({ sortOrder, formattedSortBy, searchValue })
        setOrder(sortOrder);
        setOrderBy(property);
        setPage(0)
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        const skip = newPage * rowsPerPage
        const formattedSortBy = getFormattedSortBy(orderBy)

        setPage(newPage);
        fetchMembersCurrentMembers({ sortOrder: order, formattedSortBy, skip, searchValue })
    };

    useEffect(() => {
        setPage(0)
        fetchMembersCurrentMembers({ sortOrder: order, formattedSortBy: fullNameField })
    }, [memberTabValue])

    useEffect(() => {
        setPage(0)
        if (timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = Number(setTimeout(() =>
            fetchMembersCurrentMembers({ sortOrder: order, formattedSortBy: fullNameField, searchValue }), memberDashboardConfig.SEARCH_MEMBERS_INPUT_DEBOUNCE_DELAY))
    }, [searchValue])

    return (
        <TableContainer>
            <Table aria-labelledby="tableTitle">
                <MembersTableHead
                    numSelected={selectedCount}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClickFromCheckbox}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    memberTabValue={memberTabValue}
                />
                {selectedCount > 0 &&
                    <SelectionPanel
                        onSelectAllClick={handleSelectAllClick}
                        amount={selectedCount}
                        openMemberInviteModal={openMemberInviteModal}
                        onRemoveMembers={onRemoveMembers}
                        memberTabValue={memberTabValue}
                        onUpdateRoles={onUpdateRoles}
                        club={club}
                        isModalOpen={isModalOpen}
                        remindMembers={remindMembers}
                    />}
                <TableBody>
                    {rows.map((row, index) => {
                        const isItemSelected = isSelected(row.email);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const isDisabledEditIcon = getIsDisabledEditIcon(row)

                        return (
                            <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.email}
                                selected={isItemSelected}
                            >
                                {memberTabValue !== MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB && (
                                    <TableCell padding="checkbox" className="checkbox-cell">
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            className="checkbox"
                                            onChange={() => handleSelectClick(row.email)}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                )}

                                {/* temporarily hide according AT-548
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    className="table-cell status"
                                >
                                    {row.status}
                                </TableCell> */}
                                {memberTabValue !== MEMBER_TABS.NOT_INVITED_TAB && memberTabValue !== MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB ?
                                    <TableCell className="table-cell">{row.eligibility}</TableCell> : null
                                }
                                <TableCell className="table-cell name">
                                    <div className="cell-content">
                                        {row.fullName}
                                    </div>
                                </TableCell>
                                {memberTabValue !== MEMBER_TABS.NOT_INVITED_TAB && (
                                    <TableCell className="table-cell">{row.role}</TableCell>
                                )}
                                <TableCell className="table-cell last-activity">{row.lastActivity}</TableCell>
                                <TableCell className="table-cell">
                                    <div className="cell-content online">
                                        <div className="value">
                                            {row.activityTotalVirtual}
                                        </div>
                                        <MonitorIcon className="cell-icon" />
                                    </div>
                                </TableCell>
                                <TableCell className="table-cell">
                                    <div className="cell-content">
                                        <div className="value">
                                            {row.activityTotalInPerson}
                                        </div>
                                        <PersonIcon className="cell-icon" />
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <EyeIcon
                                        onClick={() => setLogActivityId(row.userId)}
                                        className={cn("cell-icon view", { disabled: isDisabledViewIcon })}
                                    />
                                </TableCell>
                                <TableCell>
                                    <EditIcon
                                        className={cn("cell-icon edit", { disabled: isDisabledEditIcon })}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onEditMember(row)
                                        }} />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePaginationAny
                            count={getTotalCount()}
                            // installed by npm TablePagination use onPageChange but yarn use onChangePage
                            onPageChange={handleChangePage}
                            onChangePage={handleChangePage}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            ActionsComponent={PaginationActions}
                            classes={{
                                caption: 'display-none',
                                input: 'display-none',
                                spacer: 'display-none',
                                toolbar: 'toolbar',
                                actions: 'actions'
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}
