import React from "react";

const arrowButton = require("../../assets/arrow-button.svg");

interface ArrowButtonProps {
  className?: string
}

export default function ArrowButton(props: ArrowButtonProps): JSX.Element {
  return <img className={`ArrowButton ${props.className || ''}`} src={arrowButton} />
}
