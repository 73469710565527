import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

import {
    LocalDate,
} from "@js-joda/core";

import "./VirtualClubEventLiveNow.scss";
import { GetClubLeaderEventResponseBody } from "../../services/ClubLeaderService";
import VirtualClubEventLive from "../icon/VirtualClubEventLive";
import { isEqual } from "lodash";
import { eventIsLive, eventIsLiveOrStartsWithin15Minutes } from "../../util/Event.utils";
import { UserContext } from "../../contexts/UserContext";
import { getZoomJoinUrl } from "../../api/getZoomJoinUrl";


export interface VirtualClubEventLiveNowProps {
    className?: string
    events: GetClubLeaderEventResponseBody[]
    nextOccurrenceOnOrAfter: LocalDate
    isClubMember: boolean
}

/**
 * VirtualClubEventLiveNow
 * designs: https://www.figma.com/file/0H8N95YwM5mBV1CV4jeDHQ/Virtual-offerings?type=design&node-id=296-3917&mode=design&t=Gs9IdcDyGMIOnhOI-0
 * current uses: see src/pages/VirtualClubsClubDetailPage 
 * 
 * Displays a banner showing Activities that are live or start within 15 minutes
 */

export default function VirtualClubEventLiveNow(props: VirtualClubEventLiveNowProps): JSX.Element {
    const { t } = useTranslation('pages');
    let classes = 'VirtualClub_events_container_checklist flex-columns';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    const [liveEvents, setLiveEvents] = useState<GetClubLeaderEventResponseBody[]>([]);

    useEffect(() => {
        setLiveEvents(filterLiveEvents());
        // In the future it might be good to receive somthing from the back end indicating that an event has started 
        setInterval(() => {
            const filteredEvents = filterLiveEvents();
            if (!isEqual(filteredEvents, liveEvents)) {
                setLiveEvents(filterLiveEvents());
            }
        }, 60000); // Repeat check every minute
    }, [props.events])

    const filterLiveEvents = () => {
        return props.events.filter((event: GetClubLeaderEventResponseBody) => eventIsLiveOrStartsWithin15Minutes(event));
    }

    async function joinZoomMeeting(event : GetClubLeaderEventResponseBody) {
        var url = await getZoomJoinUrl(event.urlFragment);
        window.open(url ?? event.virtualEventDetails?.url, "_blank");
    }

    return (
        <div className="VirtualClub_Event_LiveNow">
            {liveEvents.map((event: any, index: number) => (
                <div className="event_live_now" key={index}>
                    <VirtualClubEventLive />

                    <span className="event_name">
                        {event.eventName} 
                        {eventIsLive(event) 
                            ? t('virtualClubsClubDetailPage.liveEvent.liveNowText') 
                            : t('virtualClubsClubDetailPage.liveEvent.startsSoonText')
                        }
                    </span>
                    {
                        event.virtualEventDetails?.url &&
                        <span className="event_link" onClick={() => {joinZoomMeeting(event)}}>
                            {t('virtualClubsClubDetailPage.liveEvent.linkText')}
                        </span>
                    }
                </div>
            ))}
        </div>
    )
};