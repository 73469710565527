import React, {useContext} from "react";
import {RegistrationFormType} from "../registrationModal/RegistrationForm";
import "./ContactPhoneNumber.scss";
import {UserContext} from "../../contexts/UserContext";
import {getClubCustomerService, User} from "../../services/UserService";
import {UserViewState} from "../../services/MemberService";
import {formatPhoneNumberForDisplay, formatPhoneNumberForTel} from "../../util/ignite/MyInfo.utils";
import { ClubVerificationStatus } from "../../types/club";
import {customEnrollmentRegex} from "../../util/CustomEnrollment";

export enum PhoneNumberOverride {
    ANONYMOUS = 0,
    MEMBER,
    CLUB_LEADER,
    ACBL,
    CLUB_DIRECTORY
}

export interface PhoneNumber {
    display: string;
    tel: string;
}

// NOTE: These 4 numbers are intentionally not exported in order to encourage use of the GetContactPhoneNumber function.
const memberContactPhoneNumber: PhoneNumber = {
    display: '(833) 906-1700',
    tel: '+1-833-906-1700'
}

const clubLeaderContactPhoneNumber: PhoneNumber = {
    display: '(844) 974-0493',
    tel: '+1-844-974-0493'
}

const anonymousContactPhoneNumber: PhoneNumber = {
    display: '(833) 906-1700',
    tel: '+1-833-906-1700'
}

const acblContactPhoneNumber: PhoneNumber = {
    display: '(844) 974-0558',
    tel: '+1-844-974-0558'
}

export function GetContactPhoneNumber(
        override = undefined as PhoneNumberOverride|undefined,
        user = null as User|null,
        viewState = null as UserViewState|null,
        pathname = undefined as string|undefined): PhoneNumber {

    switch (override) {
        case PhoneNumberOverride.ANONYMOUS:
            return anonymousContactPhoneNumber;
        case PhoneNumberOverride.MEMBER:
            return memberContactPhoneNumber;
        case PhoneNumberOverride.CLUB_LEADER:
            return clubLeaderContactPhoneNumber;
        case PhoneNumberOverride.ACBL:
            return acblContactPhoneNumber;
    }

    if (pathname) {
        const acblFlow = pathname.toLowerCase().startsWith("/acbl");
        const customEnrollmentFlow = pathname.toLowerCase().startsWith("/p/");
        if (acblFlow) {
            return acblContactPhoneNumber;
        }
        if (customEnrollmentFlow) {
            const customEnrollmentMatching = pathname.match(customEnrollmentRegex);
            const customEnrollmentShortCode = customEnrollmentMatching ? customEnrollmentMatching[1] : undefined;

            if (customEnrollmentShortCode) {
                const customServiceInfo = getClubCustomerService(customEnrollmentShortCode);
                if (customServiceInfo && customServiceInfo.customerServicePhone) {
                    return {
                        display: formatPhoneNumberForDisplay(customServiceInfo.customerServicePhone),
                        tel: formatPhoneNumberForTel(customServiceInfo.customerServicePhone)
                    }
                }
            }
        }
    }

    // If this club/association has customer support numbers defined, use those.
    if (viewState?.currentClubSupportPhone) {
        return {
            display: formatPhoneNumberForDisplay(viewState.currentClubSupportPhone),
            tel: formatPhoneNumberForTel(viewState.currentClubSupportPhone)
        }
    }

    if (user == null) {
        return anonymousContactPhoneNumber;
    }
    if (user?.hasLeadershipRoles) {
        return clubLeaderContactPhoneNumber;
    } else {
        return memberContactPhoneNumber;
    }
}

interface ContactPhoneNumberProps {
    formType?: RegistrationFormType
}

export default function ContactPhoneNumber(props: ContactPhoneNumberProps) {
    const { user, viewState } = useContext(UserContext);

    function getNumber(): PhoneNumber {
        if (props.formType !== undefined) {
            if (props.formType === RegistrationFormType.MEMBER) {
                return memberContactPhoneNumber;
            }
            if (props.formType === RegistrationFormType.LEADER) {
                return clubLeaderContactPhoneNumber;
            }
        }
        return GetContactPhoneNumber(undefined, user, viewState);
    }

    return (
        <a className={"ContactPhoneNumber_Link"} href={`tel:${getNumber().tel}`}>{getNumber().display}</a>
    )
}
