export enum IGNITE_ROUTE_KEY {
    MEMBER_ONBOARDING = 'MEMBER_ONBOARDING',
    MEMBER_REGISTRATION = 'MEMBER_REGISTRATION',
    SUCCESSFUL_SIGNUP = 'SUCCESSFUL_SIGNUP',
    CLUB_FINDER = 'CLUB_FINDER',
    CLUB_REQUESTING = 'CLUB_REQUESTING',
    CLUB_REQUESTED = 'CLUB_REQUESTED',
    MEDICARE_ADVANTAGE_PAYER_SELECTION = 'MEDICARE_ADVANTAGE_PAYER_SELECTION',
    MEDICARE_ADVANTAGE_PAYER_QUALIFICATION = 'MEDICARE_ADVANTAGE_PAYER_QUALIFICATION',
    CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY',
    VERIFICATION_STATUS = 'VERIFICATION_STATUS',
    CONTACT_METHODS = 'CONTACT_METHODS',
    DASHBOARD = 'DASHBOARD',
    MEMBER_TERMS_OF_USE = 'MEMBER_TERMS_OF_USE',
    LEADER_TERMS_OF_USE = 'LEADER_TERMS_OF_USE',
    MY_CLUBS = 'MY_CLUBS',
    MY_ASSOCIATIONS = 'MY_ASSOCIATIONS',
    PROFILE = 'PROFILE',
    MEMBER_INFO = 'MEMBER_INFO',
    LEADER_DASHBOARD = 'LEADER_DASHBOARD',
    LEADER_MANAGE_MEMBERS = 'LEADER_MANAGE_MEMBERS',
    CLUB_SETUP = 'CLUB_SETUP',
    LEADER_ONBOARDING_WELCOME = 'LEADER_ONBOARDING_WELCOME',
    ERROR = 'ERROR',
    NOTIFICATIONS = 'NOTIFICATIONS',
    HEALTH_PLAN = 'HEALTH_PLAN'
}

export const IGNITE_ROUTE_PATTERNS = {
    [IGNITE_ROUTE_KEY.MEMBER_ONBOARDING]: '/ignite/onboarding/:clubShortCode',
    [IGNITE_ROUTE_KEY.MEMBER_REGISTRATION]: '/ignite/member-registration/:clubShortCode',
    [IGNITE_ROUTE_KEY.SUCCESSFUL_SIGNUP]: '/ignite/successful-signup/:clubId',
    [IGNITE_ROUTE_KEY.CLUB_FINDER]: '/ignite/club-finder',
    [IGNITE_ROUTE_KEY.CLUB_REQUESTING]: '/ignite/club-requesting/:clubId',
    [IGNITE_ROUTE_KEY.CLUB_REQUESTED]: '/ignite/club-requested/:clubId',
    [IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_SELECTION]: '/ignite/health-plan-selection/:clubId',
    [IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_QUALIFICATION]: '/ignite/medicare-health-plan-qualification/:clubId',
    [IGNITE_ROUTE_KEY.CHECK_ELIGIBILITY]: '/ignite/check-eligibility/:clubId/:payerSlug?/:payerId?',
    [IGNITE_ROUTE_KEY.VERIFICATION_STATUS]: '/ignite/verification-status/:clubId/:payerSlug/:id?',
    [IGNITE_ROUTE_KEY.CONTACT_METHODS]: '/ignite/club-contact-methods/:clubId',
    [IGNITE_ROUTE_KEY.DASHBOARD]: '/ignite/dashboard/:clubId',
    [IGNITE_ROUTE_KEY.MEMBER_TERMS_OF_USE]: '/ignite/member-terms-of-use',
    [IGNITE_ROUTE_KEY.LEADER_TERMS_OF_USE]: '/ignite/leader-terms-of-use',
    [IGNITE_ROUTE_KEY.PROFILE]: '/ignite/profile/([a-z-]*)/:clubId?',
    [IGNITE_ROUTE_KEY.MY_CLUBS]: '/ignite/profile/clubs/:clubId?/:join?',
    [IGNITE_ROUTE_KEY.MY_ASSOCIATIONS]: '/ignite/profile/associations/:clubId?',
    [IGNITE_ROUTE_KEY.NOTIFICATIONS]: '/ignite/profile/notifications/:clubId?',
    [IGNITE_ROUTE_KEY.HEALTH_PLAN]: '/ignite/profile/health-plan/:clubId?',
    [IGNITE_ROUTE_KEY.MEMBER_INFO]: '/ignite/profile/member-info/:clubId?',
    [IGNITE_ROUTE_KEY.LEADER_DASHBOARD]: '/ignite/leader-dashboard/:clubId',
    [IGNITE_ROUTE_KEY.LEADER_MANAGE_MEMBERS]: '/ignite/leader-manage-members/:clubId',
    [IGNITE_ROUTE_KEY.LEADER_ONBOARDING_WELCOME]: '/ignite/leader-onboarding/welcome',
    [IGNITE_ROUTE_KEY.CLUB_SETUP]: '/ignite/club-set-up',
    [IGNITE_ROUTE_KEY.ERROR]: '/ignite/error'
}

export const E3_ROUTES = {
    LOGIN: '/login',
    MY_EVENTS: '/my-events',
    FIND_EVENT: '/find-an-event',
    CLUB_DIRECTORY: '/clubs',
    ABOUT: '/about',
    MY_CLUB: '/my-club',
    ROOT: '/'
}
