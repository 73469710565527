import React, {useContext} from "react";
import "./EventSearchResults.scss";
import { useTranslation } from "react-i18next";
import Button, { ButtonStyles } from "../common/Button";
import {useLocation} from "react-router-dom";
import {UserContext} from "../../contexts/UserContext";

interface NoResultsProps {
    usingFilters: boolean;
    resetFilters: Function;
    viewVirtualOnly: Function;
}

export default function NoResults(props: NoResultsProps): JSX.Element {
    const {t} = useTranslation('pages');
    const {getContactPhoneNumber} = useContext(UserContext);
    const location = useLocation();
    const phoneNumber = getContactPhoneNumber({pathname: location.pathname});

    return (
        <div className={"EventSearchResults_NoResultsWrapper"}>
            <div className={"EventSearchResults_NoResults"}>
                <span className={"EventSearchResults_Link"} dangerouslySetInnerHTML={{__html: t('findAnEvent.noResultsFound')}}/>
                <div className={"EventSearchResults_OtherOptions"}>
                    <table className={"EventSearchResults_OtherOptionsTable"}>
                        <tbody>
                            <tr>
                                <td>{t('findAnEvent.checkOutVirtualClubs')}</td>
                                <td><Button
                                    type={"button"}
                                    buttonStyle={ButtonStyles.UnfilledWithBorder}
                                    className={"EventSearchResults_NoResults_VirtualOnly"}
                                    clickHandler={props.viewVirtualOnly}>{t('findAnEvent.go')}</Button></td>
                            </tr>
                            {props.usingFilters && <tr>
                                <td>{t('findAnEvent.removeCurrentFilters')}</td>
                                <td><Button
                                    type={"button"}
                                    buttonStyle={ButtonStyles.UnfilledWithBorder}
                                    className={"EventSearchResults_NoResults_ResetButton"}
                                    clickHandler={props.resetFilters}>{t('findAnEvent.go')}</Button></td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}