import React, { CSSProperties } from 'react';
import { Skeleton } from "@material-ui/lab";

interface ClubCardSkeletonProps {
    style?: CSSProperties;
}

export default function ClubCardSkeleton(props: ClubCardSkeletonProps): JSX.Element{
    return(
        <div {...props}>
            <div style={{display: 'flex', marginRight: 16,}}>
                <div style={{flexGrow: 1, margin: 16}}>
                    <Skeleton width={'20%'}/>
                    <Skeleton width={'30%'}/>
                    <Skeleton width={'30%'}/>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </div>
                <Skeleton variant="rect" width="30%">
                    <div/>
                </Skeleton>
            </div>
        </div>
    );
}