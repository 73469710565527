import React, { useContext, useState } from "react";
import { TextField, MenuItem } from '@material-ui/core'
import { useTranslation } from "react-i18next";
import { ACTION_OPTIONS, MEMBER_TABS } from "../../../constants/dashboard";
import { IClub } from "../../../types/clubFinder";
import { UserContext } from "../../../contexts/UserContext";
import cn from "classnames";

interface ISelectionPanel {
    onSelectAllClick: React.MouseEventHandler
    amount: number
    openMemberInviteModal: Function
    onRemoveMembers: Function
    memberTabValue?: MEMBER_TABS,
    onUpdateRoles?: Function
    club: IClub
    isModalOpen?: boolean
    remindMembers?: Function
}

const SelectionPanel = ({
    amount,
    onSelectAllClick,
    openMemberInviteModal,
    onRemoveMembers,
    memberTabValue,
    onUpdateRoles,
    club,
    isModalOpen,
    remindMembers
}: ISelectionPanel) => {
    const { t } = useTranslation('ignite/dashboard')
    const [currentAction, setCurrentAction] = useState<string>()
    const { user } = useContext(UserContext)

    const isCurrentUserAdmin = club.primaryClubLeaders.some(admin => admin.email === user?.email)

    const onClickAction = (action: string) => {
        if (action === ACTION_OPTIONS.INVITE_MEMBERS) {
            openMemberInviteModal()
        }

        if (action === ACTION_OPTIONS.REMOVE) {
            onRemoveMembers()
        }
        if (action === ACTION_OPTIONS.CHANGE_ROLE) {
            onUpdateRoles && onUpdateRoles()
        }
        if (action === ACTION_OPTIONS.SEND_MISSING_ACTIVITY_REMINDER) {
            remindMembers && remindMembers()
        }
    }

    const getActions = () => {
        if (memberTabValue === MEMBER_TABS.NOT_INVITED_TAB) {
            return [ACTION_OPTIONS.INVITE_MEMBERS]
        }
        if (memberTabValue === MEMBER_TABS.INVITED_NOT_SIGNED_UP_TAB) {
            return []
        }
        if (memberTabValue === MEMBER_TABS.MISSING_ACTIVITY_TAB) {
            if (isCurrentUserAdmin) return [ACTION_OPTIONS.CHANGE_ROLE, ACTION_OPTIONS.REMOVE, ACTION_OPTIONS.SEND_MISSING_ACTIVITY_REMINDER]
            return [ACTION_OPTIONS.REMOVE, ACTION_OPTIONS.SEND_MISSING_ACTIVITY_REMINDER]
        }
        if (isCurrentUserAdmin) return [ACTION_OPTIONS.CHANGE_ROLE, ACTION_OPTIONS.REMOVE]

        return [ACTION_OPTIONS.REMOVE]
    }

    const actions = getActions()

    const shouldHideAction = actions.length === 0

    return (
        <table className={cn('selection-panel', { 'without-dropdown': shouldHideAction })}>
            <div className="content">
                <div className="top-content">
                    <div className="text" dangerouslySetInnerHTML={{ __html: t('membersSelectedText', { amount }) }} />
                    <div className="action" onClick={onSelectAllClick} >
                        {t('membersSelectedAction')}
                    </div>
                </div>
                {shouldHideAction ? null : (
                    <div className="bottom-content">
                        <TextField
                            className='selection-panel__select'
                            variant="outlined"
                            label={t('selectDropdown.placeholder')}
                            value={isModalOpen ? currentAction : ''}
                            onChange={(e) => setCurrentAction(e.target.value)}
                            select={true}
                            size='small'
                        >
                            {actions.map((action) => (
                                <MenuItem
                                    key={action}
                                    value={action}
                                    className="member-actions-menu-item"
                                    onClick={() => onClickAction(action)}
                                >
                                    {t(`selectDropdown.${action}`)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                )}
            </div>
        </table>
    )
}

export default SelectionPanel
