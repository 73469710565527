import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const REMIND_MISSING_ACTIVITY_URL = '/ignite/leader/member-activity-reminder'

const postRemindMissingActivity = async (body: { UserIds?: number[], ClubId: number, Recurring?: boolean }) => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${REMIND_MISSING_ACTIVITY_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.log(err)))
}

export default postRemindMissingActivity
