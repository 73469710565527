import {BorderedRowItem} from "../common/BorderedRow";
import React from "react";
import { useTranslation } from 'react-i18next';
import {EventStatus} from "../../util/EventStatus";


import "./EventListNameItem.scss";
import ListPaidIndicator from "../common/ListPaidIndicator";
import GoingIndicator from "../common/GoingIndicator";
import ListSelfGuidedIndicator from "../common/ListSelfGuidedIndicator";

export interface EventListNameItemProps {
    status: EventStatus;
    isPaid: boolean;
    isSelfGuided: boolean;
    eventName: string;
    clubName?: string;
    isGoing?: boolean;
}

export default function EventListNameItem(props: EventListNameItemProps): JSX.Element {
    const { t } = useTranslation('event');
    return (
        <BorderedRowItem className="EventListNameItem">
            {props.isGoing &&
            <div className="EventListNameItem_IsGoing"><GoingIndicator/></div>}
            {props.isSelfGuided &&
            <div className="EventListNameItem_IsSelfGuided"><ListSelfGuidedIndicator/></div>}
            {props.isPaid &&
            <div className="EventListNameItem_IsPaid"><ListPaidIndicator/></div>}
            {props.eventName}
            {props.clubName !== undefined &&
            <div className="EventListNameItem_ClubName">{props.clubName}</div>}
            {props.status === EventStatus.CANCELLED &&
            <div className="EventListNameItem_IsCancelled">{t('eventListNameItem.cancelledEventIndicator')}</div>}
        </BorderedRowItem>
    );
}