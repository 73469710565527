import React from "react";
import { getClubImageUrl } from "../../util/Util";
import {GetPassionResponseBody} from "../../services/Models";
import "./PassionImage.scss";

interface PassionImageProps {
    wide?: boolean;
    thumbnail?: boolean;
    passion: GetPassionResponseBody;
    className?: string
}

export default function PassionImage(props: PassionImageProps): JSX.Element {
    const passionImageUrlObj = getClubImageUrl(props.passion);
    let passionImageUrl: string;
    if (props.wide && props.thumbnail) {
        passionImageUrl = passionImageUrlObj.thumbnailWide;
    } else if (props.wide) {
        passionImageUrl = passionImageUrlObj.wide;
    } else if (props.thumbnail) {
        passionImageUrl = passionImageUrlObj.thumbnail;
    } else {
        passionImageUrl = passionImageUrlObj.standard;
    }

    return (
        <div className={`PassionImage ${props.wide ? "Wide" : ""} ${props.className || ''}`}
             style={{backgroundImage: `url(${passionImageUrl})`}}/>
    )
}
