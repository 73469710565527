import React from "react";
import { IconButton, Box, useTheme } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowDoubleRight from "../../../../components/ignite/icons/ArrowDoubleRight";
import ArrowDoubleLeft from "../../../../components/ignite/icons/ArrowDoubleLeft";
import { usePagination } from "../../../../hooks/usePagination";
import cn from "classnames";
import { memberDashboardConfig } from "../../../../configs/memberDashboard";

export default function PaginationActions(props: any) {
    const theme = useTheme();
    const { count, page, rowsPerPage } = props;

    const onPageChange = props.onPageChange || props.onChangePage

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const paginationRange: (string | number)[] | undefined = usePagination({
        currentPage: page,
        totalCount: count,
        pageSize: memberDashboardConfig.MEMBERS_AMOUNT_PER_PAGE
    });

    return (
        <Box className="actions">
            <div className="left-content">
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                    classes={{
                        disabled: 'disabled'
                    }}
                >
                    {theme.direction === 'rtl' ? <ArrowDoubleRight className="double-arrow" /> : <ArrowDoubleLeft className="double-arrow" />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight className="arrow" /> : <KeyboardArrowLeft className="arrow" />}
                </IconButton>
            </div>
            <div className="pagination-list">
                {paginationRange?.map(i =>
                    <div onClick={event => onPageChange(event, Number(i) - 1)} className={cn("pagination-item", { active: i == (page + 1) })}>{i}</div>
                )}
            </div>
            <div className="right-content">
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft className="arrow" /> : <KeyboardArrowRight className="arrow" />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    classes={{
                        disabled: 'disabled'
                    }}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <ArrowDoubleLeft className="double-arrow" /> : <ArrowDoubleRight className="double-arrow" />}
                </IconButton>
            </div>
        </Box >
    );
}
