import React from "react"

const CupIcon = ({ className }: { className: string }) => (
    <svg className={className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1260_69355)">
            <path d="M25.3516 16.9539C26.6526 14.3809 27.8443 10.7981 28.2762 5.76506H32.8296C32.2884 10.2395 28.7081 14.1553 25.3464 16.9539H25.3516ZM2.58464 5.76506H7.13804C7.56996 10.8035 8.76165 14.3809 10.0626 16.9539C6.59163 14.1553 3.01657 10.2395 2.57944 5.76506H2.58464ZM28.3907 3.52515C28.5 2.40788 28.5 1.39804 28.5 0.167969H6.8154C6.8154 1.39804 6.8154 2.40788 6.92468 3.52515H0.310547V4.64242C0.310547 14.6012 12.5605 21.6539 15.4903 23.217V27.0254C15.4903 28.9269 14.08 30.3826 12.2378 30.3826H10.0678V34.857H25.2475V30.3826H23.0775C21.2354 30.3826 19.8251 28.9269 19.8251 27.0254V23.2224C22.7497 21.6539 35.0048 14.6065 35.0048 4.64779V3.53052H28.3907V3.52515Z" fill="#0177C0" />
        </g>
        <defs>
            <clipPath id="clip0_1260_69355">
                <rect width="34.6891" height="34.6891" fill="white" transform="translate(0.310547 0.167969)" />
            </clipPath>
        </defs>
    </svg>
)

export default CupIcon
