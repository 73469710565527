import React, { useCallback, useState, useMemo, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import InfoIcon from '../../../components/icon/InfoIcon';
import InfoModal from '../InfoModal';
import { SanityTextObject } from '../../../sanity/SanityUtils';
import '../NewMemberRegistrationWizard.scss';
import { useFormState } from 'react-use-form-state';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import Button, { ButtonStyles } from '../../../components/common/Button';
import Grid from '@material-ui/core/Grid';
import LaunchIcon from '@material-ui/icons/Launch';
import { Link, useLocation } from 'react-router-dom';
import { isValidEmail } from '../../../util/Util';
import { testIfEmailRegistered } from '../../../services/UserService';
import FieldStatusIcon from '../../../components/ignite/registration/FieldStatusIcon';
import CustomEnrollmentStepTracker from './CustomEnrollmentStepTracker';
import { NewMemberRegistrationWizardSteps } from '../NewMemberRegistrationWizard';
import { ClubAssociationEnrollmentConfigurationSanityData } from '../../../sanity/getClubAssociationContentViaSanity';
import Loading from '../../../components/common/Loading';
import { E3_ROUTES } from '../../../constants/routes';
import { formatPhoneNumberForDisplay } from '../../../util/ignite/MyInfo.utils';
import { useConfigurationParser } from '../hooks/UseConfigurationParser';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  // CUSTOM_MESSAGE_VALIDATION_KEYS,
  GHIN_CLUBS,
  // GHIN_VALIDATION_KEY,
  // ValidateGhinMessages,
} from '../../../util/CustomEnrollment';

export type MemberAccount = {
  email: string;
  password: string;
  hearAboutUs: number;
  referralCode: string;
  termsOfUse: boolean;
};

interface StepProp {
  associationFullName?: string;
  associationShortName?: string;
  clubShortCode: string;
  enrollmentConfiguration?: ClubAssociationEnrollmentConfigurationSanityData;
  onContinue: (data: any) => Promise<void>;
  onPrevious: () => void;
}

type RegistrationFormData = {
  confirmEmail: string;
  confirmPassword: string;
  email: string;
  hearAboutUs: number;
  hearAboutUsTextInput: string;
  memberId: string;
  password: string;
  referralCode: string;
  termsOfUse: boolean;
  lastName?: string;
};

// Note: This is also listed out in UserClubRelationship.cs
const hearAboutUsOptions = [
  { id: 1, label: 'Email' },
  { id: 2, label: 'Search Engine: Google' },
  { id: 3, label: 'Blog Post' },
  { id: 4, label: 'Google Ads' },
  { id: 5, label: 'Facebook Ads' },
  { id: 6, label: 'Social Media' },
  { id: 7, label: 'Direct Mail' },
  { id: 8, label: 'Podcast' },
  { id: 9, label: 'Renew Active' },
  { id: 10, label: 'OnePass' },
  { id: 11, label: 'Newspaper' },
  { id: 12, label: 'Word of Mouth' },
  { id: 13, label: 'Other' },
] as const;

export function CustomEnrollmentContactDetailsStep(
  props: StepProp,
): JSX.Element {
  const configuration = useConfigurationParser(props.enrollmentConfiguration);

  const { t } = useTranslation(['pages', 'registrationModal']);
  const location = useLocation();

  const [formState, { text, password, email, checkbox, select }] =
    useFormState<RegistrationFormData>({
      confirmEmail: '',
      confirmPassword: '',
      email: '',
      hearAboutUs: 1,
      hearAboutUsTextInput: '',
      memberId: '',
      password: '',
      referralCode: '',
      termsOfUse: false,
      lastName: '',
    });

  const [hearAboutUsSelection, setHearAboutUsSelection] = useState<number>(1);
  const [hearAboutUsTextInput, setHearAboutUsTextInput] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [onContinueError, setOnContinueError] = useState<string>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  async function testEmailRegistered(email: string) {
    if (email) {
      try {
        const emailIsRegisteredResponse = await testIfEmailRegistered(email);
        if (emailIsRegisteredResponse.isRegistered) {
          formState.setFieldError(
            'email',
            emailIsRegisteredResponse.clubCustomerServicePhone
              ? t(
                  'newMemberRegistration.customEnrollmentDuplicateEmailErrorCustomNumber',
                  {
                    clubCustomerServicePhone: formatPhoneNumberForDisplay(
                      emailIsRegisteredResponse.clubCustomerServicePhone,
                    ),
                  },
                )
              : t('newMemberRegistration.customEnrollmentDuplicateEmailError'),
          );
        }
      } catch (e) {
        formState.setFieldError(
          'email',
          t('newMemberRegistration.unexpectedError'),
        );
      }
    }
  }

  const confirmEmailValidate = (value: string) => {
    if (value.trim() === '') {
      return t('newMemberRegistration.emailError');
    }
    if (value?.toLowerCase() !== formState.values.email?.toLowerCase()) {
      return t('newMemberRegistration.confirmEmailError');
    }
  };

  const confirmPasswordValidate = (value: string) => {
    if (!value.trim()) {
      return t('newMemberRegistration.confirmPasswordError');
    }
    if (value !== formState.values.password) {
      return t('newMemberRegistration.confirmPasswordError');
    }
  };

  const hearAboutUsValidate = () => {
    // hearAboutUsOptions 13 is "Other"
    if (hearAboutUsSelection === 13 && hearAboutUsTextInput.trim() === '') {
      formState.setFieldError(
        'hearAboutUsTextInput',
        t('customEnrollment.hearAboutUsTextInputError'),
      );
    }
  };

  const hearAboutUsTextInputValidate = useCallback(() => {
    const trimmedInput = hearAboutUsTextInput.trim();
    // hearAboutUsOptions 13 is "Other"
    if (!trimmedInput && hearAboutUsSelection === 13) {
      return t('customEnrollment.hearAboutUsTextInputError');
    }
    return '';
  }, [t, hearAboutUsTextInput, hearAboutUsSelection]);

  const elementConfig = {
    email: {
      name: 'email',
      validateOnBlur: true,
      validate: (value: string) => {
        if (formState.touched.confirmEmail) {
          const isInvalid = confirmEmailValidate(formState.values.confirmEmail);

          if (isInvalid) {
            formState.setFieldError('confirmEmail', isInvalid);
          } else {
            formState.setField('confirmEmail', formState.values.confirmEmail);
          }
        }

        if (value.trim() === '' || isValidEmail(value) === false) {
          return t('newMemberRegistration.emailError');
        }
      },
      onBlur: (e: SyntheticEvent) => {
        testEmailRegistered((e.target as HTMLInputElement).value).then(
          () => {},
        );
        return undefined;
      },
    },
    confirmEmail: {
      name: 'confirmEmail',
      validate: confirmEmailValidate,
      validateOnBlur: true,
    },
    password: {
      name: 'password',
      validate: (value: string) => {
        if (formState.touched.confirmPassword) {
          const isInvalid = confirmPasswordValidate(
            formState.values.confirmPassword,
          );

          if (isInvalid) {
            formState.setFieldError('confirmPassword', isInvalid);
          } else {
            formState.setField(
              'confirmPassword',
              formState.values.confirmPassword,
            );
          }
        }

        const trimmedInput = value.trim();
        if (!trimmedInput) {
          return t('newMemberRegistration.passwordError');
        }
        if (trimmedInput.length < 8) {
          return t('newMemberRegistration.passwordError');
        }
        if (
          trimmedInput.search(/[A-Z]/) === -1 ||
          trimmedInput.search(/[0-9]/) === -1
        ) {
          return t('newMemberRegistration.passwordError');
        }
      },
      validateOnBlur: true,
    },
    confirmPassword: {
      name: 'confirmPassword',
      validate: confirmPasswordValidate,
      validateOnBlur: true,
    },
    hearAboutUs: {
      name: 'hearAboutUs',
      validate: hearAboutUsValidate,
      validateOnBlur: true,
    },
    referralCode: {
      name: 'referralCode',
      validate: (value: string) => {
        if (configuration.referralCodeRequired && value.trim() === '') {
          return t('customEnrollment.referralCodeError');
        }
      },
      validateOnBlur: true,
    },
    hearAboutUsTextInput: {
      name: 'hearAboutUsTextInput',
      validate: hearAboutUsTextInputValidate,
      validateOnBlur: true,
    },
    termsOfUse: {
      name: 'termsOfUse',
    },
    memberId: {
      name: 'memberId',
      validate: (value: string) => {
        if (configuration.memberIdRequired) {
          if (value.trim()) {
            return;
          }
          return memberIdFieldLabel && memberIdFieldLabel.length > 0
            ? t('customEnrollment.memberIdCustomError', { memberIdFieldLabel })
            : t('newMemberRegistration.memberIdError');
        }
      },
      validateOnBlur: true,
    },
    lastName: {
      name: 'lastName',
      validate: (value: string) => {
        if (!value.trim()) {
          return 'last name is required';
        }

        return;
      },
      validateOnBlur: true,
    },
  } as const;

  const isContinueEnabled = useMemo((): boolean => {
    const keyNamesToIgnoreForValidation = [
      'hearAboutUs',
      'hearAboutUsTextInput',
    ];

    if (configuration.referralCodeRequired) {
      keyNamesToIgnoreForValidation.push('referralCode');
    }

    if (configuration.memberIdEnabled) {
      if (!configuration.memberIdRequired) {
        keyNamesToIgnoreForValidation.push('memberId');
      }
    }

    const allInputsTouched = Object.entries(formState.pristine).every(
      ([key, value]) => keyNamesToIgnoreForValidation.includes(key) || !value,
    );
    const allValuesValid = Object.entries(formState.validity).every(
      ([key, value]) => keyNamesToIgnoreForValidation.includes(key) || value,
    );
    const hearAboutUsTextInputValidated = hearAboutUsTextInputValidate() === '';
    return allInputsTouched && allValuesValid && hearAboutUsTextInputValidated;
  }, [
    formState.pristine,
    formState.validity,
    hearAboutUsTextInputValidate,
    configuration,
  ]);

  const onContinueSelected = () => {
    setSaving(true);
    setOnContinueError(undefined);

    let data = {
      email: formState.values.email,
      hearAboutUs: 0,
      hearAboutUsTextInput,
      memberId: formState.values.memberId,
      password: formState.values.password,
      referralCode: formState.values.referralCode,
      ...(configuration.memberIdEnabled && {
        lastName: formState.values.lastName,
      }),
    };

    if (configuration.howDidYouHearAboutUsEnabled) {
      data.hearAboutUs = hearAboutUsSelection;
    }

    props
      .onContinue(data)
      .catch((error) => {
        const ns = 'registrationModal';
        const registrationErrorsTranslations =
          'registrationForm.registrationError';
        // if (CUSTOM_MESSAGE_VALIDATION_KEYS.includes(error?.validationKey)) {
        //   switch (error.validationKey) {
        //     case GHIN_VALIDATION_KEY:
        //       setOnContinueError(
        //         t(ValidateGhinMessages(error.message), { ns }),
        //       );
        //       break;
        //     default:
        //       setOnContinueError(t(registrationErrorsTranslations, { ns }));
        //   }
        // } else {
        const messageKey = `registrationForm.validationMessages.${
          error?.validationKey ?? ''
        }`;
        setOnContinueError(
          t([messageKey, registrationErrorsTranslations], { ns }),
        );
        // }
      })
      .finally(() => setSaving(false));
  };

  const memberIdFieldLabel = useMemo(() => {
    if (configuration.memberIdFieldLabel) {
      return configuration.memberIdFieldLabel;
    }

    const associationLabel =
      props.associationShortName ??
      props.associationFullName ??
      t('customEnrollment.associationLabel');

    if (configuration.memberIdRequired) {
      return t('customEnrollment.memberIdPlaceholder', { associationLabel });
    }

    return t('customEnrollment.memberIdOptionalPlaceholder', {
      associationLabel,
    });
  }, [t, configuration, props.associationShortName, props.associationFullName]);

  const onMemberIdInfoButtonClick = () =>
    setInfoModalData(configuration.memberIdHelpOverlayContent);

  const [infoModalData, setInfoModalData] = useState<SanityTextObject[]>([]);

  return (
    <div
      className={
        'NewMemberRegistrationWizard_Wrapper NewMemberAccountStep CustomEnrollmentContactDetailsStep'
      }
    >
      <InfoModal
        isOpen={infoModalData.length > 0}
        setIsOpenInfoModal={() => setInfoModalData([])}
        modalContent={infoModalData}
      />

      <CustomEnrollmentStepTracker
        currentStep={NewMemberRegistrationWizardSteps.Account}
      />
      <h2>{t('customEnrollment.contactDetailsHeader')}</h2>
      <p className={'CustomEnrollmentContactDetailsStep_SubHeader'}>
        {t('customEnrollment.contactDetailsSubheader')}
      </p>
      <div className={'NewMemberRegistrationWizard_Wrapper_InnerContainer'}>
        <Grid container spacing={2} direction="row" justify="flex-start">
          <Grid item xs={12}>
            <span className={'CustomEnrollmentContactDetailsStep_RequiredNote'}>
              {t('customEnrollment.fieldsRequiredText')}
            </span>
          </Grid>
          <Grid item xs={11} sm={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...email<'email'>(elementConfig.email)}
                className="MemberRegistrationWizard_Input"
                label={t('newMemberRegistration.emailPlaceholder')}
                required
                variant={'outlined'}
                error={formState.errors.email !== undefined}
                helperText={formState.errors.email}
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.email}
                isError={formState.errors.email}
              />
            </div>
          </Grid>
          <Grid item xs={11} sm={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...email(elementConfig.confirmEmail)}
                className="MemberRegistrationWizard_Input"
                label={t('newMemberRegistration.confirmEmailPlaceholder')}
                required
                variant={'outlined'}
                error={formState.errors.confirmEmail !== undefined}
                helperText={formState.errors.confirmEmail}
                size="small"
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.confirmEmail}
                isError={formState.errors.confirmEmail}
              />
            </div>
          </Grid>
          <Grid item xs={11} sm={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...password(elementConfig.password)}
                className="MemberRegistrationWizard_Input"
                label={t('newMemberRegistration.passwordPlaceholder')}
                variant="outlined"
                error={formState.errors.password !== undefined}
                helperText={formState.errors.password}
                required
                size="small"
                InputProps={{
                  type: showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.password}
                isError={formState.errors.password}
              />
            </div>
          </Grid>
          <Grid item xs={11} sm={5}>
            <FormControl fullWidth margin="dense">
              <TextField
                {...password(elementConfig.confirmPassword)}
                className="MemberRegistrationWizard_Input"
                label={t('newMemberRegistration.confirmPasswordPlaceholder')}
                variant="outlined"
                error={formState.errors.confirmPassword !== undefined}
                helperText={formState.errors.confirmPassword}
                required
                size="small"
                InputProps={{
                  type: showConfirmPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <div className="field-indicator-wrapper">
              <FieldStatusIcon
                shouldShow={formState.touched.confirmPassword}
                isError={formState.errors.confirmPassword}
              />
            </div>
          </Grid>
          {configuration.memberIdEnabled && !GHIN_CLUBS.includes(
                props.clubShortCode?.toLowerCase() ?? '',
              ) && (
            <>
              <Grid item xs={11} md={5}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    {...text(elementConfig.memberId)}
                    className="MemberRegistrationWizard_Input"
                    label={`${memberIdFieldLabel}`}
                    variant={'outlined'}
                    required={configuration.memberIdRequired}
                    error={formState.errors.memberId !== undefined}
                    helperText={formState.errors.memberId}
                    size="small"
                  />
                  {configuration.memberIdHelpOverlayContent && (
                    <div
                      className={
                        'CustomEnrollmentPersonalInformationStep_MemberIdInfoContainer'
                      }
                    >
                      <InfoIcon
                        className={
                          'CustomEnrollmentPersonalInformationStep_MemberId_InfoButton'
                        }
                        onClick={onMemberIdInfoButtonClick}
                      />
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <div className="field-indicator-wrapper">
                  <FieldStatusIcon
                    shouldShow={formState.touched.memberId}
                    isError={formState.errors.memberId}
                  />
                </div>
              </Grid>

              {/* {GHIN_CLUBS.includes(
                props.clubShortCode?.toLowerCase() ?? '',
              ) && (
                <>
                  <Grid item xs={11} md={5}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        {...text(elementConfig.lastName)}
                        className="MemberRegistrationWizard_Input"
                        label={t('newMemberRegistration.lastNamePlaceholder')}
                        variant={'outlined'}
                        required={true}
                        error={formState.errors.lastName !== undefined}
                        helperText={formState.errors.lastName}
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <div className="field-indicator-wrapper">
                      <FieldStatusIcon
                        shouldShow={formState.touched.lastName}
                        isError={formState.errors.lastName}
                      />
                    </div>
                  </Grid>
                </>
              )} */}
            </>
          )}
          {configuration.howDidYouHearAboutUsEnabled && (
            <>
              <Grid item xs={11} sm={5}>
                <FormControl fullWidth margin="dense">
                  <Select
                    className={
                      'CustomEnrollmentContactDetailsStep_HearAboutUsSelect'
                    }
                    style={{ width: '100%' }}
                    {...select(elementConfig.hearAboutUs)}
                    label={t('customEnrollment.hearAboutUsPlaceholder')}
                    variant="outlined"
                    margin={'dense'}
                    onChange={(e) => {
                      setHearAboutUsSelection(e.target.value as number);
                    }}
                    value={hearAboutUsSelection}
                    required
                  >
                    {hearAboutUsOptions.map(({ id, label }) => (
                      <MenuItem key={id} value={id}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <div className="field-indicator-wrapper">
                  <FieldStatusIcon
                    shouldShow={formState.touched.hearAboutUs}
                    isError={formState.errors.hearAboutUs}
                  />
                </div>
              </Grid>
            </>
          )}
          {configuration.referralCodeEnabled && (
            <>
              <Grid item xs={11} sm={5}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    {...text(elementConfig.referralCode)}
                    className="MemberRegistrationWizard_Input"
                    label={
                      configuration.referralCodeRequired
                        ? t('customEnrollment.referralCodePlaceholder')
                        : t('customEnrollment.referralCodeOptionalPlaceholder')
                    }
                    required={configuration.referralCodeRequired}
                    variant={'outlined'}
                    error={formState.errors.referralCode !== undefined}
                    helperText={formState.errors.referralCode}
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <div className="field-indicator-wrapper">
                  <FieldStatusIcon
                    shouldShow={formState.touched.referralCode}
                    isError={formState.errors.referralCode}
                  />
                </div>
              </Grid>
            </>
          )}
          {configuration.howDidYouHearAboutUsEnabled &&
            hearAboutUsSelection === 13 && (
              <>
                <Grid item xs={11} sm={5}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      {...text(elementConfig.hearAboutUsTextInput)}
                      className="MemberRegistrationWizard_Input"
                      label={t(
                        'customEnrollment.hearAboutUsTextInputPlaceholder',
                      )}
                      required
                      variant={'outlined'}
                      onChange={(e) => {
                        setHearAboutUsTextInput(e.target.value);
                      }}
                      value={hearAboutUsTextInput}
                      error={hearAboutUsTextInputValidate() !== ''}
                      helperText={hearAboutUsTextInputValidate()}
                      size="small"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}>
                  <div className="field-indicator-wrapper">
                    <FieldStatusIcon
                      shouldShow={formState.touched.hearAboutUsTextInput}
                      isError={hearAboutUsTextInputValidate() !== ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}></Grid>
              </>
            )}
          <Grid item xs={12}>
            <FormControl>
              <div
                className={
                  'NewMemberRegistrationWizard_Wrapper_TermsAndAgreements'
                }
              >
                <Checkbox
                  {...checkbox(elementConfig.termsOfUse)}
                  style={{ paddingLeft: 0 }}
                />
                <FormLabel>
                  {t('customEnrollment.acceptText')}{' '}
                  {t('customEnrollment.termsOfUseLinkText')} *{' '}
                  <Link
                    to={{
                      pathname: 'https://hellogrouper.com/app-terms-of-use/',
                    }}
                    target="_blank"
                    className={'RegistrationForm_TermsOfUseLink'}
                  >
                    <LaunchIcon
                      className={
                        'NewMemberRegistrationWizard_ExternalLink_Icon'
                      }
                    />
                  </Link>
                </FormLabel>
              </div>
            </FormControl>
          </Grid>
          {configuration.enrollmentMessage && (
            <Grid item xs={12}>
              <div
                className={
                  'NewMemberRegistrationWizard_Wrapper_EnrollmentMessage'
                }
              >
                {configuration.enrollmentMessage}
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <div
              className={'NewMemberRegistrationWizard_Wrapper_AlreadyLoggedIn'}
            >
              <span className="NewMemberRegistrationWizard_Wrapper_AlreadyLoggedInText">
                {t('newMemberRegistration.alreadyAccount')}
              </span>
              <Link
                className="login"
                to={{
                  pathname: E3_ROUTES.LOGIN,
                  state: { referrer: location.pathname },
                }}
              >
                {t('newMemberRegistration.logIn')}
              </Link>
            </div>
          </Grid>
          {onContinueError ? (
            <Grid item xs={12}>
              <br />
              <Alert severity="error">{onContinueError}</Alert>
            </Grid>
          ) : null}
        </Grid>
        <div className={'NewMemberAccountStep_NextContainer'}>
          {saving ? (
            <Loading
              className={'NewMemberRegistrationWizard_SubmitButtonLoading'}
              loading={saving}
            />
          ) : (
            <Button
              type="button"
              id="AccountSubmit"
              clickHandler={onContinueSelected}
              text={t('newMemberRegistration.nextButton')}
              className="NewMemberRegistrationWizard_SubmitButton"
              buttonStyle={ButtonStyles.UnfilledWithBorder}
              disabled={!isContinueEnabled}
            />
          )}
          <Button
            type="button"
            clickHandler={props.onPrevious}
            text={t('newMemberRegistration.backButton')}
            className="NewMemberRegistrationWizard_SubmitButton"
            buttonStyle={ButtonStyles.UnfilledWithBorder}
          />
        </div>
      </div>
    </div>
  );
}
