import React, {useContext} from "react";
import {useTranslation} from 'react-i18next';
import RegistrationForm from "../../../components/ignite/registration/RegistrationForm";
import {Link} from "react-router-dom";
import {UserContext} from "../../../contexts/UserContext";
import ClearIcon from '@material-ui/icons/Clear';

import "./LeaderOnboardingRegistration.scss";
import {Modal} from "@material-ui/core";
import {PhoneNumberOverride} from "../../../components/common/ContactPhoneNumber";

export default function LeaderOnboardingRegistrationModal({ isOpen, setIsModalOpen }: any) {
    const { t } = useTranslation('ignite/leaderOnboarding');
    const { getContactPhoneNumber } = useContext(UserContext);

    const contactPhoneNumber = getContactPhoneNumber({override: PhoneNumberOverride.CLUB_LEADER});

    return (
        <Modal
            open={isOpen}
            className='leader-registration-modal'
        >
            <div className="onboarding-page leader-onboarding">
                <div className='leader-onboarding-top-wrapper'>
                    <ClearIcon className='icon' onClick={() => setIsModalOpen(false)} />
                    <div className="leader-onboarding-top">
                        <div
                            className='leader-onboarding-top__title'
                            dangerouslySetInnerHTML={{ __html: t('registration.title') }}
                        />
                        <div className='leader-onboarding-top__subtitle'>
                            {t('registration.alreadyRegistered')}
                            <Link
                                className="leader-onboarding-top__link"
                                to="/login"
                            >
                                {t('registration.logIn')}
                            </Link>
                        </ div>
                    </div>
                </div>
                <RegistrationForm isLeader={true} />
                <div className="leader-onboarding-footer">
                    {t('registration.needHelpText')}
                    {' '}
                    <b>
                        {contactPhoneNumber.display}
                    </b>
                </div>
            </div>
        </Modal>
    );
}
