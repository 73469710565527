import i18next from "i18next";
import {SanityTextObject, getSanityResponse} from "./SanityUtils";

export interface ClubBYOCFormSanityResponse {
    ms: number;
    query: string;
    result: ClubBYOCFormSanityData[];
}

export interface ClubBYOCFormSanityData {
    title: SanityTextObject [];
    clubConfirmationMessage: SanityTextObject [];
    emailLabel: string;
    phoneNumberLabel: string;
    cityLabel: string;
    stateLabel: string;
    clubDuesLabel: string;
    clubPlanLabel: string;
    websiteURLLabel: string;
    clubNameLabel: string;
    membershipNumberLabel: string;
    membershipNumberHint: string;
}

const getClubBYOCFormViaSanity = async (formSlug: string): Promise<ClubBYOCFormSanityResponse | undefined> => {
    const LANGUAGE = i18next.language.split('-')[0];
    let query = encodeURIComponent(`
        *[_type == "byoc_form" && slug == "${formSlug}"]
        {
            "title": pageTitle[_key == "${LANGUAGE}"][0].value,
            "clubConfirmationMessage": clubConfirmationMessage[_key == "${LANGUAGE}"][0].value,
            "emailLabel": emailLabel.${LANGUAGE},
            "phoneNumberLabel": phoneNumberLabel.${LANGUAGE},
            "cityLabel": cityLabel.${LANGUAGE},
            "stateLabel": stateLabel.${LANGUAGE},
            "clubDuesLabel": clubDuesLabel.${LANGUAGE},
            "clubPlanLabel": clubPlanLabel.${LANGUAGE},
            "websiteURLLabel": websiteURLLabel.${LANGUAGE},
            "clubNameLabel": clubNameLabel.${LANGUAGE},
            "membershipNumberLabel": membershipNumberLabel.${LANGUAGE},
            "membershipNumberHint": membershipNumberHint.${LANGUAGE},
        }
    `);

    return getSanityResponse(query);
}

export default getClubBYOCFormViaSanity;