import React, {useEffect, useState} from "react";
import "./Footer.scss";
import logo from "../../assets/logo.svg";
import translateLogo from "../../assets/google-translate-attribution.png";
import Copyright from "./Copyright";
import ContactUs from "./ContactUs";
import StaticLinks from "./StaticLinks";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";

interface FooterProps {
    hideLanguageSelection?: boolean
}

export default function Footer(props: FooterProps): JSX.Element {
    const [languageSelection, setLanguageSelection] = useState("");
    const { t } = useTranslation('pages');

    i18n.on('initialized', function(loaded) {
        setLanguageSelection(i18n.language);
    });

    useEffect(() => {}, [languageSelection]);

    return (
        <div>
            <div className={`Footer`}>
                <div className={`FooterContent`}>
                    {(props.hideLanguageSelection === undefined || !props.hideLanguageSelection) && languageSelection !== undefined && languageSelection !== "en" &&
                        <img className={`TranslateLogo`} src={translateLogo} alt="powered by Google Translate"/>}
                    <div className={`LogoAndCopyright`}>
                        <img className={`Logo`} src={logo} alt={t('header.logoAlt')}/>
                        <Copyright/>
                    </div>
                    <StaticLinks/>
                    <ContactUs/>
                </div>
                <div className={`FooterContentMobile`}>
                    <div className={`LogoAndCopyright`}>
                        <img className={`Logo`} src={logo} alt={t('header.logoAlt')}/>
                        <Copyright/>
                    </div>
                    <ContactUs/>
                    <StaticLinks/>
                </div>
            </div>
        </div>
    );
}
