import React from "react";
import { useTranslation } from 'react-i18next';
import { Button, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IConfirmationModalProps {
    isOpen: boolean
    onInPersonClick: (event: React.MouseEvent) => void
    onVirtualClick: (event: React.MouseEvent) => void
    onClose: (event: React.MouseEvent) => void
}

export default function ChooseActivityTypeModal({
    isOpen,
    onInPersonClick,
    onVirtualClick,
    onClose,
}: IConfirmationModalProps) {
    const { t } = useTranslation('ignite/dashboard');

    return (
        <Modal
            open={isOpen}
            className='choose-activity-type-modal'
        >
            <div className="choose-activity-type-modal-wrapper">
                <CloseIcon className="close-icon" onClick={onClose} />
                <div className='content'>
                    <div className="title">
                        {t('whatTypeOfActivity')}
                    </div>

                    <div className="button-wrapper">
                        <Button
                            onClick={onInPersonClick}
                            className='button'
                        >
                            {t('inPerson')}
                        </Button>
                        <Button
                            className='button'
                            onClick={onVirtualClick}
                        >
                            {t('virtual')}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
