import React, {useContext, useEffect} from "react";
import {Link, useRouteMatch, Redirect, useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./PayerMemberLandingPage.scss";
import "../clubLeaderLanding/ClubLeaderLanding.scss";
import clubLeaderLanding from "../../assets/club-leader-landing.jpg";
import PlayButton from "../../components/common/PlayButton";
import {ButtonStyles} from "../../components/common/Button";
import {UserContext} from "../../contexts/UserContext";
import { storeRegistrationParametersToSession } from "../../util/Util";
import {supportedPayerSlugs} from "../payerMemberRegistration/registrationWizard/PayerMemberRegistrationWizard";
import { PayerSlug } from "../../configs/payers";

/**
 * A page with static content that contains a link to register as a member.
 */
export default function PayerMemberLandingPage(): JSX.Element {
    const { t } = useTranslation('pages');
    const history = useHistory();
    const { payerSlug } = useRouteMatch<{payerSlug: PayerSlug}>().params;
    const {user} = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        storeRegistrationParametersToSession(history.location);
    }, []);

    const onClickSignUpButton = () => {
        history.push(`/registration/${payerSlug}`);
    }

    if (!supportedPayerSlugs.includes(payerSlug)) {
        return <Redirect to={"/"}/>
    }

    return (
        <div className="PayerMemberLandingPage">
            <div id="MemberLanding_Banner">
                <div id="MemberLanding_Text">
                    <p id="CCClubLeaderLanding_Subtext" dangerouslySetInnerHTML={{__html: t('payerMemberLandingPage.subText')}}>
                    </p>
                    <div id="CCMemberLanding_Register">
                        <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                            <PlayButton text={t('payerMemberLandingPage.playButton')}
                                        id="MemberGetStarted"
                                        buttonStyle={ButtonStyles.FilledPrimary}
                                        disabled={user !== null}
                                        clickHandler={onClickSignUpButton}
                                        />
                        </div>
                    </div>
                    <div id="MemberLanding_Login">
                        <div id={"CCClubLeaderLanding_RegisterWrapper"}>
                            <p id={"CCClubLeaderLanding_SignInText"}>
                                {t('payerMemberLandingPage.alreadyAccount')}&nbsp;
                                <Link to={"/login"}>{t('payerMemberLandingPage.logIn')}</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div id="MemberLanding_Image_Wrapper">
                    <img src={clubLeaderLanding}
                         alt={t('payerMemberLandingPage.memberLandingAltText')}/>
                </div>
            </div>
            <div id={"CCMemberLanding_FAQ_Wrapper"}>
                <div id={"CCMemberLanding_FAQ"} dangerouslySetInnerHTML={{__html: t('payerMemberLandingPage.faq')}}></div>
            </div>
        </div>
    );
}
