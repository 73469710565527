import React from "react";
import { GetClubLeaderEventResponseBody } from "../../services/ClubLeaderService";
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from "@material-ui/core";

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


export interface GalleryFullscreenModalProps {
    className?: string
    openModal: boolean
    closeModalClickHandler: Function
    galleryImageURL: string
    clubName: string
    children?: any
}

/**
 * GalleryFullscreenModal
* current uses: see src/pages/virtualClubs/VirtualClubsClubDetailPage
 * 
 * Modal displaying the fullsize image for a club's gallery image
 */

export default function GalleryFullscreenModal(props: GalleryFullscreenModalProps): JSX.Element {
    let classes = 'InstructorBioModal';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    let openModal = props.openModal
    let galleryImageURL = props.galleryImageURL;
    let clubName = props.clubName;

    function closeModal(e: any) {
        if (props.closeModalClickHandler !== undefined) {
            props.closeModalClickHandler(e);
        }
    }
    return (
        <Dialog
            className={classes}
            open={openModal}
            onClose={closeModal}
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle disableTypography>
                <IconButton onClick={closeModal}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <div className="galleryImage_Fullsize" style={{textAlign: 'center'}}>
                <img src={galleryImageURL} alt={"Gallery image for " + clubName} style={{margin: '0 auto'}}/>
            </div>
        </Dialog>
    )
};
