export enum NotificationKind {
    EVENT_UPCOMING = 0,
    EVENT_UPDATED = 1,
    EVENT_CANCELLED = 2
}

export enum NotificationStatus {
    NEGATIVE,
    POSITIVE
}

export const ALL_NOTIFICATION_KINDS = [
    NotificationKind.EVENT_UPCOMING,
    NotificationKind.EVENT_UPDATED,
    NotificationKind.EVENT_CANCELLED
];
