import React from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import "./EventViewWithDetails.scss";
import Chip from "@material-ui/core/Chip";
import LocationOn from '@material-ui/icons/LocationOn';
import Email from '@material-ui/icons/Email';
import EventView from "./EventView";
import DetailsBoxContainer from "../common/DetailsBoxContainer";
import DetailsBox from "../common/DetailsBox";
import FullDateView from "../common/FullDateView";
import StartEndTime from "../common/StartEndTime";
import LocationView from "../common/LocationView";
import MapLink from "../common/MapLink";
import SelfGuidedEventText from "../event/SelfGuidedEventText";
import {
    isAvaOrganizationName,
    isEmptyVirtualEventDetails,
    isEventTypeSelfGuided
} from "../../util/Util";
import ImportantDetails from "../event/ImportantDetails";
import {EventFileList} from "../eventFile/EventFile";
import Grade from "@material-ui/icons/Grade";
import VirtualEventDetails, {
    NoVirtualEventDetails,
    VirtualEventDetailsProps
} from "../common/VirtualEventDetails";

import { getClubPageRouteLink } from "../../util/ignite/routes.utils";
import { EventOccurrence, EventRecurrence } from "../../services/ClubLeaderService";
import RecurrenceView from "../common/RecurrenceView";
import { SupportedTimeZone } from "../../util/SupportedTimeZone";
import OccurrenceNextMeetingView from "../common/OccurrenceNextMeetingView";
import OccurrenceLastMeetingView from "../common/OccurrenceLastMeetingView";

interface EventViewWithDetailsProps {
    eventName: string;
    cancelled: boolean;
    eventUrlFragment: string;
    buttonClickHandler: Function;
    clubImage: string;
    eventStartDate: string | Date;
    eventEndDate: string | Date;
    eventDescription: string;
    importantDetails: string;
    clubName: string;
    clubDescription: string;
    className?: string
    organizationName?: string
    eventLeaderFirstName: string;
    eventLeaderLastName: string;
    eventLeaderEmail: string;
    locationName?: string | null;
    locationAddress1?: string | null;
    locationAddress2?: string | null;
    locationCity?: string | null;
    locationState?: string | null;
    locationZip: string;
    isVirtual: boolean;
    isClubVirtual: boolean;
    virtualEventDetails?: VirtualEventDetailsProps;
    isRecurring: boolean;
    recurrence: EventRecurrence | null;
    occurrences: EventOccurrence[];
    timeZone: SupportedTimeZone;
    eventDate: string | Date;
    buttonText: string;
    eventTypeOne: string | null;
    eventTypeTwo: string | null;
    clubUrl: string | null;
    rsvpCount: number;
    attendeeCount: number;
    clubUrlFragment: string;
}

export default function EventViewWithDetails(props: EventViewWithDetailsProps): JSX.Element {
    const { t } = useTranslation('clubLeaderEvent');
    let classes = 'CCEventViewWithDetails';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }
    const isSelfGuided = isEventTypeSelfGuided(props.eventTypeOne);
    const isAvaClub = props.organizationName !== undefined && isAvaOrganizationName(props.organizationName);
    let clubName = props.clubName;
    
    const history = useHistory();
    const route =  getClubPageRouteLink(props.isClubVirtual, props.clubUrlFragment);

    return (
        <div className={classes}>
            <EventView
                eventDescription={props.eventDescription}
                cancelled={props.cancelled}
                eventType={props.eventTypeOne}
                className={``}
                clubImage={props.clubImage}
                clubName={props.clubName}
                eventUrlFragment={props.eventUrlFragment}
                buttonClickHandler={props.buttonClickHandler}
                eventName={props.eventName}
                startsAtNativeJsDate={props.eventDate}
                rsvpCount={props.rsvpCount}
                attendeeCount={props.attendeeCount}
                urlFragment={props.eventUrlFragment}/>
            <DetailsBoxContainer>
                <DetailsBox 
                    main={
                        <div>
                            {
                                props.isRecurring && props.recurrence
                                    ? <RecurrenceView recurrence={props.recurrence}/>
                                    : <FullDateView date={props.eventStartDate}/>
                            }
                            <StartEndTime startsAt={props.eventStartDate} endsAt={props.eventEndDate}/>
                            {
                                props.isRecurring && props.recurrence &&
                                <>
                                    <OccurrenceNextMeetingView timeZone={props.timeZone} occurrences={props.occurrences}/>
                                    <OccurrenceLastMeetingView timeZone={props.timeZone} occurrences={props.occurrences}/>
                                </>
                            }
                        </div>
                    } 
                    title={<div>{t('eventViewWithDetails.whenText')}</div>} 
                    footer={""}/>
                {!props.isVirtual &&
                <DetailsBox main={
                    <LocationView locationName={props.locationName}
                                  locationAddress1={props.locationAddress1}
                                  locationAddress2={props.locationAddress2}
                                  locationCity={props.locationCity}
                                  locationState={props.locationState}
                                  locationZip={props.locationZip}/>
                } title={t('eventViewWithDetails.whereText')} footer={<MapLink className={`WhereMapLink`}
                                                   locationAddress1={props.locationAddress1}
                                                   locationAddress2={props.locationAddress2}
                                                   locationCity={props.locationCity}
                                                   locationState={props.locationState}
                                                   locationZip={props.locationZip}><LocationOn/>{t('eventViewWithDetails.mapItText')}</MapLink>
                }/> 
                }
                {props.isVirtual &&
                <DetailsBox className="VirtualEventDetailsBox" main={
                    props.virtualEventDetails && !isEmptyVirtualEventDetails(props.virtualEventDetails)
                        ? <VirtualEventDetails {...props.virtualEventDetails}/>
                        : <NoVirtualEventDetails/>
                } title={t('eventViewWithDetails.whereText')}/>
                }

                <DetailsBox main={<div>
                    <div onClick={() => history.push(route)}>
                        <span className="EventViewWithDetails_ClubName">{clubName}</span>
                        {
                            props.isClubVirtual && <Chip className={"EventViewWithDetails_Chip"} label={t('eventViewWithDetails.virtualClub')}/>
                        }
                    </div>
                    <div>{props.eventLeaderFirstName} {props.eventLeaderLastName}, {t('eventViewWithDetails.eventLeaderText')}
                    </div>
                </div>} title={t('eventViewWithDetails.whoText')} footer={<a
                    href={`mailto:${props.eventLeaderEmail}`}><Email/>{t('eventViewWithDetails.emailEventLeaderText')}</a>}/>
            </DetailsBoxContainer>
            {(props.importantDetails)
            && <ImportantDetails>{props.importantDetails}</ImportantDetails>}
            {isSelfGuided && isAvaClub && <>
                <div className="SelfGuidedMessage"><SelfGuidedEventText
                    eventClubUrl={props.clubUrl}/></div>
            </>}
            <EventFileList instructions={t('eventViewWithDetails.eventFileListInstructions')}
                           icon={<Grade/>}
                           title={t('eventViewWithDetails.eventFileListTitle')}
                           showAllEventFiles={true}
                           className="WhatYoullNeed"
                           eventUrlFragment={props.eventUrlFragment}/>
        </div>
    );
}

