import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./AcblRegistrationComplete.scss";
import { UserContext } from "../../contexts/UserContext";
import Button, { ButtonStyles } from "../../components/common/Button";
import ArrowButton from "../../components/icon/ArrowButton";
import { AcblBanner } from "../../components/acblBanner/AcblBanner";

interface AcblRegistrationCompleteProps {
}
export default function AcblRegistrationComplete(props: AcblRegistrationCompleteProps): JSX.Element {
    const {t} = useTranslation('pages');

    const { user, logout } = useContext(UserContext);

    useEffect(() => {
        (async () => {
            if (user && !user.isPasswordSet) {
                await logout();
            }
        })();
    }, [user]);

    function onClick() {
        window.open("https://acbl.com/play-bridge/", "_blank");
    }

    return (
        <>
            <AcblBanner/>
            <div className="AcblRegistrationComplete">
                <div className={"AcblRegistrationComplete__Line1"}>{t("acblFinalPage.line1")}</div>
                <div className={"AcblRegistrationComplete__Line2"}>{t("acblFinalPage.line2")}</div>
                <div>
                    <Button
                        className={"AcblRegistrationComplete__Button"}
                        clickHandler={onClick}
                        type={"button"}
                        buttonStyle={ButtonStyles.FilledPrimary}>
                        {t("acblFinalPage.link")}
                        <ArrowButton className={"AcblRegistrationComplete_Button_Icon"}/>
                    </Button>
                </div>
            </div>
        </>
    )
}
