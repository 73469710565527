import { useState, useEffect } from "react";
import getClubById from '../api/getClubById'
import { IClub } from '../types/clubFinder'

const useClubById = (id: number): [IClub | undefined, boolean] => {
    const [club, setClub] = useState<IClub | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        getClubById(id)
            .then(data => setClub(data))
            .finally(() => {
                setIsLoading(false)
            })
    }, [id])

    return [club, isLoading]
}

export default useClubById
