import React from "react"

const UploadFileIcon = ({ className }: { className?: string }) => (
    <svg className={className} width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.75307 5.99414H45.6003C47.2511 5.99414 48.5894 4.69485 48.5894 3.0921C48.5894 1.48935 47.2511 0.190061 45.6003 0.190061H3.75307C2.10225 0.190061 0.763988 1.48935 0.763988 3.0921C0.763988 4.69485 2.10225 5.99414 3.75307 5.99414Z" fill="#555555" />
        <path d="M48.5894 8.89557V3.09149C48.5894 1.48874 47.2511 0.189453 45.6003 0.189453C43.9494 0.189453 42.6112 1.48874 42.6112 3.09149V8.89557C42.6112 10.4983 43.9494 11.7976 45.6003 11.7976C47.2511 11.7976 48.5894 10.4983 48.5894 8.89557Z" fill="#555555" />
        <path d="M6.74268 8.89557V3.09149C6.74268 1.48874 5.40442 0.189453 3.75359 0.189453C2.10276 0.189453 0.764505 1.48874 0.764505 3.09149V8.89557C0.764505 10.4983 2.10276 11.7976 3.75359 11.7976C5.40442 11.7976 6.74268 10.4983 6.74268 8.89557Z" fill="#555555" />
        <path d="M12.7205 29.2114C12.2565 29.2114 11.7988 29.1065 11.3838 28.905C10.9687 28.7035 10.6077 28.411 10.3293 28.0506C10.0937 27.7457 9.92238 27.3987 9.82496 27.0296C9.72754 26.6604 9.70597 26.2762 9.76149 25.8989C9.817 25.5216 9.94851 25.1587 10.1485 24.8308C10.3485 24.5028 10.6131 24.2164 10.9271 23.9877L22.8834 15.2816C23.3953 14.9184 24.0129 14.7227 24.647 14.7227C25.281 14.7227 25.8986 14.9184 26.4105 15.2816L38.3669 23.4653C39.0113 23.9092 39.4485 24.5826 39.5829 25.3385C39.7174 26.0944 39.5381 26.8712 39.0843 27.4992C38.8577 27.813 38.5694 28.0802 38.2359 28.2853C37.9025 28.4904 37.5305 28.6293 37.1414 28.6941C36.7523 28.7589 36.3539 28.7481 35.9691 28.6626C35.5843 28.577 35.2208 28.4183 34.8995 28.1957L24.6769 21.2017L14.514 28.631C13.9966 29.0077 13.3673 29.2114 12.7205 29.2114Z" fill="#555555" />
        <path d="M24.6761 49.5243C23.8833 49.5243 23.1231 49.2186 22.5625 48.6743C22.0019 48.1301 21.687 47.3919 21.687 46.6223V23.4059C21.687 22.6363 22.0019 21.8981 22.5625 21.3539C23.1231 20.8097 23.8833 20.5039 24.6761 20.5039C25.4689 20.5039 26.2291 20.8097 26.7897 21.3539C27.3503 21.8981 27.6652 22.6363 27.6652 23.4059V46.6223C27.6652 47.3919 27.3503 48.1301 26.7897 48.6743C26.2291 49.2186 25.4689 49.5243 24.6761 49.5243Z" fill="#555555" />
    </svg>
)

export default UploadFileIcon
