import React from "react";
import ClubFinderList from '../../../components/ignite/ClubFinderList/ClubFinderList'
import "./ClubFinder.scss";
import ClubFinderForm from '../../../components/ignite/ClubFinderForm/ClubFinderForm'
import { useTranslation } from 'react-i18next';
import useClubFinder from '../../../hooks/useClubFinder'
import ChiplList from '../../../components/ignite/ClubFinderForm/ChipsList'

const ClubFinder = (): JSX.Element => {
    const { t } = useTranslation('ignite/clubFinder');
    const {
        onSelectToken,
        searchValue,
        tokens,
        filteredClub,
        selectedTokens,
        onRemoveToken,
        onHandleChange,
        onSubmit,
    } = useClubFinder()

    return (
        <div className="ignite-page-content club-finder">
            <h1>{t('title')}</h1>
            <ClubFinderForm
                onSelectToken={onSelectToken}
                searchValue={searchValue}
                tokens={tokens}
                onSubmit={onSubmit}
                handleChange={onHandleChange}
            />
            <div className="bottom-content">
                <ChiplList
                    selectedTokens={selectedTokens}
                    removeSelectedToken={onRemoveToken}
                />
                <ClubFinderList clubs={filteredClub} />
            </div>
        </div>
    )
}

export default ClubFinder
