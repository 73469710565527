import React from "react";
import { useTranslation } from 'react-i18next';

import "./VerifyEmail.scss";

export default function VerifyEmailComplete(): JSX.Element {
    const { t } = useTranslation('pages');

    return (
        <div className="VerifyEmailPage">
            <h1>{t('verifyEmailComplete.complete')}</h1>
            <div className="VerifyEmailPage_Success">
                {t('verifyEmailComplete.verified')}
            </div>
        </div>
    );
}
