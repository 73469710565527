import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import {TextField} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

export function FirstNameInput(props: RegistrationInputProps) {
    const { t } = useTranslation('pages');
    const inputConfig: InputConfig<string> = {
        name: "firstName",
        initialValue: "",
        validate: (value: string) => {
            if (!value.trim()) {
                //return 'First Name is required';
                return " ";
            }
        },
        validateOnBlur: true
    };

    const [formState, {text}] = useSubFormInput(inputConfig);

    return (
        <TextField {...text(inputConfig)}
                   className={`RegistrationWizard_Input FirstNameInput ${props.className || ''}`}
                   label={t('registration.firstName.label')}
                   required
                   variant={"outlined"}
                   error={formState.errors.firstName !== undefined}
                   helperText={formState.errors.firstName?.trim()}
                   size="small"/>
    )
}
