import './NewLayout.scss';
import './Layout.scss';
import React, {
  ComponentType,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import EmailVerificationNag from './EmailVerificationNag';
import Footer from './Footer';
import Header from './Header';
import NeedHelpCallout from './NeedHelpCallout';
import { UserContext } from '../../contexts/UserContext';
import Version from '../common/Version';
import cn from 'classnames';
import { refreshClubCustomerService } from '../../services/UserService';
import { useLocation } from 'react-router-dom';
import { emailConfig } from '../../configs/email';

export default function NewLayout({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const location = useLocation();
  const [navigationShown, setNavigationShown] = useState(false);
  const { user } = useContext(UserContext);

  const {
    customEnrollmentShortCode,
    hideLanguageSelection,
    showNeedHelpHeader,
  } = useMemo(() => {
    const acblLanding = /^\/acbl(\/?|\/faq)$/i.test(location.pathname);
    const isIgnite = location.pathname.startsWith('/ignite');
    const customEnrollmentRegex = /^\/p\/(\w*)\/?/i;
    const customEnrollmentLandingRegex = /^\/p\/(\w*)(\/?|\/faq)$/i;
    const isCustomEnrollmentLanding = customEnrollmentLandingRegex.test(
      location.pathname,
    );
    const [match] = location.pathname.match(customEnrollmentRegex) ?? [];

    return {
      customEnrollmentShortCode: match,
      hideLanguageSelection: isIgnite,
      showNeedHelpHeader: !acblLanding && !isCustomEnrollmentLanding,
    };
  }, [location.pathname]);

  useEffect(() => {
    // Recording customer service information for custom enrollment based pages
    if (customEnrollmentShortCode) {
      void refreshClubCustomerService(customEnrollmentShortCode);
    }
  }, [customEnrollmentShortCode, location.pathname]);

  if (location.pathname === '/events') {
    return (
      <>
        <Header
          navigationShown={navigationShown}
          setNavigationShown={setNavigationShown}
        />
        <div>{children}</div>
        <Footer />
        <Version />
      </>
    );
  }

  return (
    <div className="Layout NewLayout">
      {showNeedHelpHeader && (
        <div className="NewLayout-Navigation">
          <div className="TopAccent" />
          <NeedHelpCallout hideLanguageSelection={hideLanguageSelection} />
          <Header
            navigationShown={navigationShown}
            setNavigationShown={setNavigationShown}
            customEnrollmentShortCode={customEnrollmentShortCode}
          />
        </div>
      )}
      <div
        className={cn('TwoColumnPage', {
          Page_Dim: user != null && navigationShown,
        })}
      >
        {children}
      </div>
      <Footer hideLanguageSelection={hideLanguageSelection} />
      <Version />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const withNewLayout = <T extends object>(
  WrappedComponent: ComponentType<T>,
): ((props: T) => JSX.Element) => {
  const ComponentWithNewLayout = (props: T) => {
    const { user } = useContext(UserContext);

    const location = useLocation();
    const { state } = useLocation<
      { ShowDTCWelcomeMessage?: boolean } | undefined
    >();

    const flags = useMemo(() => {
      const urlParams = new URLSearchParams(location.search);

      const showMemberJustRegisteredMessage =
        location.pathname === '/find-an-event' &&
        urlParams.get('justRegistered') === 'true';

      const showMemberJustRegisteredGetActiveCustomMessage =
        showMemberJustRegisteredMessage &&
        urlParams.get('getActive') === 'true';

      return {
        showMemberJustRegisteredMessage,
        showMemberJustRegisteredGetActiveCustomMessage,
      };
    }, [location.pathname, location.search]);

    const showEmailVerificationNag = useMemo<boolean>(() => {
      // TODO - do we not worry about this for 1/1?
      if (!emailConfig.ENABLE_VERIFICATION_NAG) {
        return false;
      }

      if (user === null || user.isEmailVerified) {
        return false;
      }

      if (state?.ShowDTCWelcomeMessage) {
        return false;
      }

      const pathname = location.pathname.toLowerCase();

      const isSpecificPath = [
        pathname.startsWith('/acbl'),
        pathname.startsWith('/p/'),
        pathname.startsWith('/verify-email/'),
        pathname.includes('/register/'),
        pathname.includes('/register-'),
      ].some((x) => x);

      if (isSpecificPath) {
        return false;
      }

      return true;
    }, [state, location.pathname, user]);

    const emailVerificationNag = () => {
      if (showEmailVerificationNag) {
        return (
          <EmailVerificationNag
            showMemberJustRegisteredMessage={
              flags.showMemberJustRegisteredMessage
            }
            getActive={flags.showMemberJustRegisteredGetActiveCustomMessage}
            email={user?.email}
            className="NewLayout_EmailVerification"
          />
        );
      }
    };

    return (
      <NewLayout>
        <>
          <WrappedComponent
            emailVerificationNag={emailVerificationNag()}
            {...props}
          />
        </>
      </NewLayout>
    );
  };

  return ComponentWithNewLayout;
};
