import React from "react";
import { useTranslation } from 'react-i18next';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { ClubType } from "../../../types/club";

interface IMoneyInfoPanelProps {
    income?: number
    clubMoney: number
    clubType: ClubType
}

const MoneyInfoPanel = ({
    income,
    clubMoney,
    clubType
}: IMoneyInfoPanelProps): JSX.Element => {
    const { t } = useTranslation('ignite/dashboard');

    return (
        <div className="leaderboard-money-info">
            <div className="icon-wrap">
            <MonetizationOnIcon className="icon" />
            </div>
            <div className="total">
                <div className="total__amount">
                    {income}
                </div>
                <div className="total__text">
                    {t('myTotal')}
                </div>
            </div>
            <div className='dot'>&#183;</div>
            <div className="club">
                <div className="club__amount">
                    {clubMoney}
                </div>
                <div className="club__text">
                    {clubType === ClubType.CLUB ? t('club') : t('association')}
                </div>
            </div>
        </div>
    )
}

export default MoneyInfoPanel
