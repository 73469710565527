import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { RouteComponentProps, useLocation, useParams, withRouter } from "react-router-dom";

import "./Header.scss";
import Hamburger from "./Hamburger";
import NavigationDesktop from "./NavigationDesktop";
import NavigationMobile from "./NavigationMobile";
import LoggedOutNavigationMobile from "./LoggedOutNavigationMobile";
import LoggedOutNavigationDesktop from "./LoggedOutNavigationDesktop";
import { HeaderLogo } from "./HeaderLogo";
import { UserContext } from "../../contexts/UserContext";
import { IGNITE_ROUTE_KEY, IGNITE_ROUTE_PATTERNS } from "../../constants/routes";
import MyAccount from "./MyAccount";
import { ViewSwitcher } from "../ignite/Dashboard/ViewSwitcher";
import getClubAssociationLogoViaSanity, {
    ClubAssociationLogoSanityData
} from "../../sanity/getClubAssociationLogoViaSanity";
import useGetMyClubs from "../../hooks/useGetMyClubs";
import { CLUB_ROLES_STRINGS } from "../../constants/dashboard";

import { useFeatureFlag } from '../../services/FeatureFlagService';
import { USE_CLUB_DIRECTORY_NAV, USE_EDP_44_VIRTUAL_CLUBS } from '../../constants/flags';

interface HeaderProps extends RouteComponentProps {
    navigationShown: boolean;
    setNavigationShown: Function;
    customEnrollmentShortCode?: string;
}

function Header(props: HeaderProps): JSX.Element {
    const { user, viewState, setUserViewState } = useContext(UserContext);

    const [clubAssociationLogo, setClubAssociationLogo] = useState<string>("");
    const [queryResults, setQueryResults] = useState<ClubAssociationLogoSanityData>();
    const [queryResultsLoading, setQueryResultsLoading] = useState<boolean>(true);

    const location = useLocation();

    const { clubId: clubIdQuery, clubUrlFragment: clubUrlFragmentQuery } = useParams<{ clubId?: string; clubUrlFragment?: string; }>();

    const [myClubs] = useGetMyClubs();

    const useClubDirectoryNav = useFeatureFlag(USE_CLUB_DIRECTORY_NAV, true);
    const useEdp44VirtualClubs = useFeatureFlag(USE_EDP_44_VIRTUAL_CLUBS, false);

    const [
        isLeader,
        isClubIgniteEnabled,
        clubId,
        clubUrlFragment,
        currentClubSupportEmail,
        currentClubSupportPhone
    ] = useMemo(() => {
        let currentClub;
        if (clubIdQuery || clubUrlFragmentQuery) {
            const matchingClub = myClubs.find(club => String(club.id) === clubIdQuery || club.urlFragment === clubUrlFragmentQuery);
            if (matchingClub) {
                currentClub = matchingClub;
            }
        }
        // currentClub should default to View State club if a club when viewing a club that the user is not a member of
        if (!currentClub) {
            currentClub = myClubs.find(club => club.id === viewState?.currentClubId || club.urlFragment === viewState?.currentClubUrlFragment)
        }
        const role = currentClub?.role;
        const hasLeaderRole = role === CLUB_ROLES_STRINGS.LEADER || role === CLUB_ROLES_STRINGS.ADMIN;
        const isClubIgniteEnabled = !!currentClub?.isIgniteEnabled;

        return [
            hasLeaderRole,
            isClubIgniteEnabled,
            currentClub?.id,
            currentClub?.urlFragment,
            currentClub?.customerServiceEmail,
            currentClub?.customerServicePhone
        ];

    }, [myClubs, clubIdQuery, clubUrlFragmentQuery, viewState]);

    const onSwitchViewState = (isLeaderView: boolean) => {
        setUserViewState({
            isLeaderView: isLeaderView,
            currentClubId: clubId,
            currentClubUrlFragment: clubUrlFragment,
            currentClubSupportEmail: currentClubSupportEmail,
            currentClubSupportPhone: currentClubSupportPhone
        });
    };

    const isAcblRegFlow = location.pathname.toLowerCase().startsWith("/acbl");
    const isAetnaRegFlow = location.pathname.toLowerCase().startsWith("/register/aetna");
    const isCustomEnrollmentFlow = location.pathname.toLowerCase().startsWith("/p/");
    const isCustomEnrollmentFlowComplete = location.pathname.toLowerCase().startsWith("/p/")
        && location.pathname.toLowerCase().endsWith("/enrollment-complete");

    const hiddenNavUrls = [
        '/acbl',
        '/p/',
        '/register/',
        '/register-member',
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.MEMBER_ONBOARDING].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.SUCCESSFUL_SIGNUP].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.CLUB_FINDER],
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.CLUB_REQUESTING].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.CLUB_REQUESTED].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_SELECTION].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.MEDICARE_ADVANTAGE_PAYER_QUALIFICATION].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.CONTACT_METHODS].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.CHECK_ELIGIBILITY].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.VERIFICATION_STATUS].replace(/\/:.*/g, ''),
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.CLUB_SETUP],
        IGNITE_ROUTE_PATTERNS[IGNITE_ROUTE_KEY.ERROR]
    ];
    const hideHeaderNav = user === null
        || hiddenNavUrls.find(url => location.pathname.toLowerCase().startsWith(url)) !== undefined;

    const hideAccountInfo = hideHeaderNav;
    const hideSwitcher = !isLeader || hideHeaderNav;

    useEffect(() => {
        if (myClubs.length === 0 || (!clubIdQuery && !clubUrlFragmentQuery)) return;

        const clubIdInt = clubIdQuery ? parseInt(clubIdQuery, 10) : undefined;

        if (clubId && clubUrlFragment
            && (viewState === null || (clubIdInt !== viewState.currentClubId && clubUrlFragmentQuery !== viewState.currentClubUrlFragment))
        ) {
            setUserViewState({
                isLeaderView: isLeader,
                currentClubId: clubId,
                currentClubUrlFragment: clubUrlFragment,
                currentClubSupportEmail: currentClubSupportEmail,
                currentClubSupportPhone: currentClubSupportPhone
            });
        }
    }, [clubIdQuery, clubUrlFragmentQuery, myClubs]);

    useEffect(() => {
        if (props.customEnrollmentShortCode) {
            getClubAssociationLogoViaSanity(props.customEnrollmentShortCode).then(data => {
                setQueryResultsLoading(false);
                setQueryResults(data?.result[0]);
            });
        } else {
            setQueryResultsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (queryResults) {
            setClubAssociationLogo(queryResults.logoImage);
        }
    }, [queryResults]);

    return (
        <div className="Header">
            <div className="HeaderContentMobile">
                <HeaderLogo
                    acblPage={isAcblRegFlow}
                    isCustomEnrollmentFlow={isCustomEnrollmentFlow}
                    isCustomEnrollmentFlowComplete={isCustomEnrollmentFlowComplete}
                    headerLogoImageUrl={clubAssociationLogo}
                />
                <div className="MobileContextualNav" />
                {!hideSwitcher && clubId && clubUrlFragment && <ViewSwitcher
                    isLeaderView={!!viewState?.isLeaderView}
                    isClubIgniteEnabled={isClubIgniteEnabled}
                    currentClubId={clubId}
                    currentClubUrlFragment={clubUrlFragment}
                    onSwitchViewState={onSwitchViewState}
                />}
                {!hideHeaderNav && !queryResultsLoading && <>
                    <NavigationMobile
                        isLeaderView={!!viewState?.isLeaderView}
                        isClubIgniteEnabled={isClubIgniteEnabled}
                        currentClubId={clubId}
                        currentClubUrlFragment={clubUrlFragment}
                        navigationShown={props.navigationShown}
                        useClubDirectoryNav={useClubDirectoryNav}
                        useEdp44VirtualClubs={useEdp44VirtualClubs}
                        setNavigationShown={props.setNavigationShown}
                        hideAccountInfo={hideAccountInfo} />
                    <Hamburger navigationShown={props.navigationShown}
                        setNavigationShown={props.setNavigationShown} />
                </>}

                {!user && !isAetnaRegFlow && <>
                    <LoggedOutNavigationMobile
                        navigationShown={props.navigationShown}
                        setNavigationShown={props.setNavigationShown}
                        useClubDirectoryNav={useClubDirectoryNav}
                    />
                    <Hamburger navigationShown={props.navigationShown}
                        setNavigationShown={props.setNavigationShown} />
                </>
                }
            </div>
            <div className={`HeaderContentContainer${!hideHeaderNav ? ' HeaderBorderBottom' : ''}`}>
                <div className={`HeaderContent`}>
                    <HeaderLogo
                        acblPage={isAcblRegFlow}
                        isCustomEnrollmentFlow={isCustomEnrollmentFlow}
                        isCustomEnrollmentFlowComplete={isCustomEnrollmentFlowComplete}
                        headerLogoImageUrl={clubAssociationLogo}
                    />


                    <div className="Header-Right">
                        {/* TODO - make me a component */}
                        {!user && !isAetnaRegFlow &&
                            <LoggedOutNavigationDesktop
                                useClubDirectoryNav={useClubDirectoryNav}
                            ></LoggedOutNavigationDesktop>
                        }
                        {!hideAccountInfo && user && <MyAccount
                            firstName={user.firstName}
                            currentClubId={clubId}
                        />}
                        {!hideSwitcher && clubId && clubUrlFragment && <ViewSwitcher
                            isLeaderView={!!viewState?.isLeaderView}
                            isClubIgniteEnabled={isClubIgniteEnabled}
                            currentClubId={clubId}
                            currentClubUrlFragment={clubUrlFragment}
                            onSwitchViewState={onSwitchViewState}
                        />}
                        {isAetnaRegFlow && <img
                            className="PayerLogo"
                            alt="PayerLogo"
                            src="/images/ignite/payers/aetna/logo.png" />}
                    </div>
                </div>
            </div>
            {!hideHeaderNav && !queryResultsLoading &&
                <div className="HeaderNavigation">
                    <NavigationDesktop
                        isLeaderView={!!viewState?.isLeaderView}
                        isClubIgniteEnabled={isClubIgniteEnabled}
                        currentClubId={clubId}
                        currentClubUrlFragment={clubUrlFragment}
                        useClubDirectoryNav={useClubDirectoryNav}
                        useEdp44VirtualClubs={useEdp44VirtualClubs}
                    />
                </div>
            }
        </div>
    );
}

export default withRouter(Header);
