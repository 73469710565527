import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import {useTranslation} from "react-i18next";

interface BackButtonProps {
    onClick: () => void;
}

export function BackButton(props: BackButtonProps): JSX.Element {
    const { t } = useTranslation('pages');
    return (
        <IconButton className={"RegistrationWizard_Previous"}
                    aria-label={t('registration.goBackLabel')}
                    onClick={props.onClick}>
            <ArrowBackIcon/>
        </IconButton>
    )
}
