import {InputConfig, RegistrationInputProps, useSubFormInput} from "../../../util/Forms";
import {IconButton, InputAdornment, TextField} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import {useTranslation} from "react-i18next";

export function PasswordInput(props: RegistrationInputProps) {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {t} = useTranslation('pages');
    const handleShowPassword = () => setShowPassword(!showPassword);
    const inputConfig: InputConfig<string> = {
        name: 'password',
        initialValue: "",
        validate: (value: string) => {
            const trimmedInput = value.trim();
            if (!trimmedInput) {
                //return "Password required."
                return " ";
            }
            if (trimmedInput.length < 8) {
                return t('registration.password.errors.length');
            }
            if (trimmedInput.search(/[A-Z]/) === -1 || trimmedInput.search(/[0-9]/) === -1) {
                return t('registration.password.errors.format');
            }
        },
        validateOnBlur: true
    }

    const [formState, {password}] = useSubFormInput(inputConfig);

    return (
        <TextField
            {...password(inputConfig)}
            className={`RegistrationWizard_Input PasswordInput ${props.className || ''}`}
            label={t('registration.password.label')}
            variant="outlined"
            error={formState.errors.password !== undefined}
            required
            helperText={t('registration.password.helperText')}
            FormHelperTextProps={{error: false}}
            size="small"
            InputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
            }}
        />
    )
}