
import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const GET_MEMBERS_ACTIVITY_URL = '/ignite/leader/get-member-activity/'

const getMemberActivity = async (clubId: number, userId: string): Promise<any | undefined> => {
    const config = await loadConfig();

    const request = new Request(`${config.apiOrigin}${GET_MEMBERS_ACTIVITY_URL}${clubId}?userId=${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const response = await authenticatedFetch(request);

    if (response?.ok) {
        return response.json()
    }
}

export default getMemberActivity
