import { POSTAL_CODE_REGEX, PHONE_REGEX, EMAIL_REGEX, MEMBER_ID_REGEX } from '../../../../constants/regex'
import { getPhoneUnmask } from '../../../../util/ignite/MyInfo.utils';

const getElementConfig = (t: Function, formState: any) => {
    return ({
        firstName: {
            name: "firstName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t('editMemberModal.errors.firstName');
                }
            },
            validateOnBlur: true,
            onBlur: () => formState.values.firstName.trim() && formState.setField('firstName', formState.values.firstName.trim())
        },
        lastName: {
            name: "lastName",
            validate: (value: string) => {
                if (!value.trim()) {
                    return t('editMemberModal.errors.lastName');
                }
            },
            validateOnBlur: true,
            onBlur: () => formState.values.lastName.trim() && formState.setField('lastName', formState.values.lastName.trim())
        },
        zipCode: {
            name: 'zipCode',
            validate: (value: string) => {
                if (!value.match(POSTAL_CODE_REGEX)) {
                    return t('editMemberModal.errors.zipCode');
                }
            },
            validateOnBlur: true,
            onBlur: () => {
                const value = formState.values.zipCode.trim()

                value && formState.setField('zipCode', value)
                if (!value.match(POSTAL_CODE_REGEX))
                    formState.setFieldError('zipCode', t('editMemberModal.errors.zipCode'));
            }
        },
        memberId: {
            name: 'memberId'
        },
        phoneNumber: {
            name: 'phoneNumber',
            validate: (value: string) => {
                const formattedValue = getPhoneUnmask(value)

                if (!formattedValue.match(PHONE_REGEX)) {
                    return t('editMemberModal.errors.phoneNumber');
                }
            },
            validateOnBlur: true
        },
        dateOfBirth: {
            name: 'dateOfBirth',
        },
        email: {
            name: 'email',
            validate: (value: string) => {
                if (!value.match(EMAIL_REGEX)) {
                    return t('editMemberModal.errors.email');
                }
            },
            validateOnBlur: true,
            onBlur: function () {
                const value = formState.values.email.trim()
                value && formState.setField('email', value)

                const errorMessage = this.validate(value)

                if (errorMessage) formState.setFieldError('email', errorMessage);
            }
        },
    });
};

export { getElementConfig }
