import React from "react";

const twoCircleUnfilled = require("../../assets/2-circle-unfilled.svg");

interface TwoCircleUnfilledProps {
    className?: string
}

export default function TwoCircleUnfilled(props: TwoCircleUnfilledProps) {
    return <img className={props.className} src={twoCircleUnfilled} alt="circle two icon"/>
}
