import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';

import "./EventList.scss";
import PlayButton from "../common/PlayButton";
import Button, {ButtonStyles} from "../common/Button";
import {GetClubLeaderEventResponseBody} from "../../services/ClubLeaderService";
import BorderedRow, {BorderedRowItem} from "../common/BorderedRow";
import EventListNameItem from "../event/EventListNameItem";
import EventListDateTimeItem from "../event/EventListDateTimeItem";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import {UserContext} from "../../contexts/UserContext";
import {loadConfig} from "../../services/ConfigService";
import Loading from "../common/Loading";
import LoadingMessage from "../common/LoadingMessage";
import {isEventSelfGuided} from "../../util/Util";

interface EventListProps {
    clubUrlFragment: string
    past: boolean
    className?: string
    contentIfEmpty: JSX.Element
}

interface GetPagedEventsResponseBody {
    totalEventCount: number
    events: GetClubLeaderEventResponseBody[]
}

export default function EventList(props: EventListProps): JSX.Element {
    const { t } = useTranslation('club');
    const {user, authenticatedFetch} = useContext(UserContext);

    let classes = 'EventList PagedEventList';
    if (props.className !== undefined) {
        classes += ' ' + props.className;
    }

    const [events, setEvents] = useState<GetClubLeaderEventResponseBody[]>([]);
    const [totalEventCount, setTotalEventCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [initialLoading, setInitialLoading] = useState(true);
    const [previousLoading, setPreviousLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);

    const limit = 10;
    const isPreviousButtonEnabled = skip > 0;
    const isNextButtonEnabled = skip + limit < totalEventCount;
    const loggedOut = user === null;

    function onClickPreviousButton() {
        setPreviousLoading(true);
        setSkip(Math.max(0, skip - limit));
    }

    function onClickNextButton() {
        setNextLoading(true);
        setSkip(skip + limit);
    }

    useEffect(() => {

        const loadEventsFromApi = async () => {
            setLoadingErrorMessage(null);

            const params = new URLSearchParams();
            params.set("clubUrlFragment", props.clubUrlFragment);
            params.set("past", props.past.toString());
            params.set("skip", skip.toString());
            params.set("limit", limit.toString());

            const config = await loadConfig();

            const eventsRequest = new Request(`${config.apiOrigin}/events/paged?${params.toString()}`);

            const eventsResponse = await authenticatedFetch(eventsRequest);

            setInitialLoading(false);
            setPreviousLoading(false);
            setNextLoading(false);

            if (eventsResponse == null) {
                // The user is logged out and will be redirected to the login page.
                setLoadingErrorMessage(null);
                return;
            }

            if (!eventsResponse.ok) {
                const responseBodyText = await eventsResponse.text();
                console.error(
                    `Non-successful response from API: `
                    + `${eventsResponse.status} ${eventsResponse.statusText} `
                    + `from ${eventsResponse.url}\n\n${responseBodyText}`);
                setLoadingErrorMessage(t('eventList.unexpectedError'));
                return;
            }

            const paged: GetPagedEventsResponseBody = await eventsResponse.json();

            setTotalEventCount(paged.totalEventCount);
            setEvents(paged.events);

            setLoadingErrorMessage(null);
        };

        loadEventsFromApi();

    }, [authenticatedFetch, skip, props.clubUrlFragment, props.past, t]);

    return (
        <div className={classes}>
            {<Loading className="EventList_Loading" loading={initialLoading}/>}
            {loadingErrorMessage !== null && (
                <LoadingMessage
                    className={"EventList_LoadingMessage"}
                    showLoginLink={loggedOut}
                    message={loadingErrorMessage}/>
            )}
            {!initialLoading && totalEventCount === 0 && props.contentIfEmpty}
            {events.map((e, i) => (
                <BorderedRow className="EventList_Row" link={`/event-details/${encodeURIComponent(e.urlFragment)}`} key={e.urlFragment} showTopBorder={false}>
                    <EventListNameItem isSelfGuided={isEventSelfGuided(e)}
                                       eventName={e.eventName}
                                       isPaid={e.isPaid}
                                       status={e.status}/>
                    <EventListDateTimeItem endsAtInstant={e.endsAtInstant} startsAtInstant={e.startsAtInstant}/>
                    {!props.past &&
                    <BorderedRowItem className="EventList_RSVPs">
                        {!isEventSelfGuided(e) &&
                        <>{e.rsvpCount} {e.rsvpCount === 1 && t('eventList.singularRSVP')} {e.rsvpCount !== 1 && t('eventList.multipleRSVPs')}</>
                        }
                    </BorderedRowItem>
                    }
                    {(props.past) &&
                    <BorderedRowItem className="EventList_Buttons">
                        {!isEventSelfGuided(e) &&
                        <PlayButton text={t('eventList.reportAttendance', {count: e.attendeeCount})}
                                    to={`/event-details/${encodeURIComponent(e.urlFragment)}`}
                                    buttonStyle={ButtonStyles.FilledPrimary}/>
                        }
                        {isEventSelfGuided(e) &&
                        <PlayButton text={t('eventList.viewAttendees', {count: e.attendeeCount})}
                                    to={`/event-details/${encodeURIComponent(e.urlFragment)}`}
                                    buttonStyle={ButtonStyles.FilledPrimary}/>
                        }
                    </BorderedRowItem>
                    }
                    {(!props.past) &&
                    <BorderedRowItem className="EventList_Buttons">
                        <PlayButton text={t('eventList.viewButtonText')}
                                    to={`/event-details/${encodeURIComponent(e.urlFragment)}`}
                                    buttonStyle={ButtonStyles.FilledPrimary}/>
                    </BorderedRowItem>
                    }
                </BorderedRow>
            ))}
            {(isPreviousButtonEnabled || isNextButtonEnabled) && (
                <div className="EventList_PreviousAndNextButtons">
                    <Button clickHandler={onClickPreviousButton}
                            buttonStyle={ButtonStyles.Unfilled}
                            disabled={!isPreviousButtonEnabled}
                            saving={previousLoading}
                            className="PreviousButton">
                        <SkipPreviousIcon/>
                        {t('eventList.previousButtonText')}
                    </Button>
                    <Button clickHandler={onClickNextButton}
                            buttonStyle={ButtonStyles.Unfilled}
                            disabled={!isNextButtonEnabled}
                            saving={nextLoading}
                            className="NextButton">
                        {t('eventList.nextButtonText')}
                        <SkipNextIcon/>
                    </Button>
                </div>
            )}
        </div>
    );
}
