import { loadConfig } from '../services/ConfigService'
import { authenticatedFetch } from '../services/UserService'

export const PENDING_JOIN_CLUB_URL = '/my-profile/pending-join-club/'

const postPendingJoinClub = async (ClubId: number) => {
    const config = await loadConfig();
    const body = { ClubId }
    
    const request = new Request(`${config.apiOrigin}${PENDING_JOIN_CLUB_URL}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    });

    return authenticatedFetch(request)
        .then(response => response?.json()
            .catch(err => console.error(err)))
}

export default postPendingJoinClub