import React from "react";

const threeCircleFilled = require("../../assets/3-circle-filled.svg");

interface ThreeCircleFilledProps {
    className?: string
}

export default function ThreeCircleFilled(props: ThreeCircleFilledProps) {
    return <img className={props.className} src={threeCircleFilled} alt="circle three icon"/>
}
