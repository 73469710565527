import React, { useCallback, useState } from 'react';
import './EventSearchResults.scss';
import { useTranslation } from 'react-i18next';
import BorderedRow, { BorderedRowItem } from '../common/BorderedRow';
import Chip from '@material-ui/core/Chip';
import LocationView from '../common/LocationView';
import Button, { ButtonStyles } from '../common/Button';
import {
  EventSearchResponseBody,
  EventSearchResultExtended,
  EventSearchSort,
} from '../../pages/findAnEvent/FindAnEventPage';
import ListSelfGuidedIndicator from '../common/ListSelfGuidedIndicator';
import NoResults from './NoResults';
import SortableHeader from './SortableHeader';
import { formatEventRecurrence } from '../../util/Event.utils';
import { getEventAndClub } from '../../services/EventSearchService';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface ResultsTableProps {
  resultsLoading: boolean;
  searchResponseBody: EventSearchResponseBody;
  sortByEventStartsAt: () => void;
  sortByDistance: () => void;
  onSortSelected: (sort: EventSearchSort) => void;
  results: EventSearchResultExtended[];
  usingFilters: boolean;
  resetFilters: Function;
  viewVirtualOnly: Function;
}

export default function ResultsTable(props: ResultsTableProps): JSX.Element {
  const { t } = useTranslation('pages');
  const { searchResponseBody, results } = props;
  const [eventUrlBeingVerified, setEventUrlBeingVerified] = useState<string>();
  const [forbiddenEventUrlFragments, setForbiddenUrlFragments] = useState<
    string[]
  >([]);
  const history = useHistory();

  const handleEventClick = useCallback(
    (eventUrlFragment: string, extUrl: string) => {
      if (eventUrlBeingVerified) return;
      if (forbiddenEventUrlFragments.includes(eventUrlFragment)) return;
      setEventUrlBeingVerified(eventUrlFragment);
      getEventAndClub(eventUrlFragment)
        .then(({ error }) => {
          if (error && error.includes('unauthorizedError')) {
            setForbiddenUrlFragments((curr) => [...curr, eventUrlFragment]);
          } else {
            history.push(extUrl);
          }
        })
        .finally(() => {
          setEventUrlBeingVerified(undefined);
        });
    },
    [eventUrlBeingVerified],
  );

  return (
    <div className="ResultsTable">
      {!props.resultsLoading && (
        <>
          <BorderedRow className="Result ResultHeader" showTopBorder={false}>
            <BorderedRowItem className="Date">
              <SortableHeader
                sortByFunction={props.sortByEventStartsAt}
                sortByEnum={EventSearchSort.EventStartsAt}
                sort={searchResponseBody.sort}
                reverse={searchResponseBody.reverse}
                label={t('findAnEvent.headers.date')}
              />
            </BorderedRowItem>
            <BorderedRowItem className="PassionImageColumn">
              {''}
            </BorderedRowItem>
            <BorderedRowItem className="TypeAndEventName">
              {t('findAnEvent.headers.type')}
            </BorderedRowItem>
            <BorderedRowItem className="Time">
              {t('findAnEvent.headers.time')}
            </BorderedRowItem>
            <BorderedRowItem className="Location">
              <SortableHeader
                sortByFunction={props.sortByDistance}
                sortByEnum={EventSearchSort.Distance}
                sort={searchResponseBody.sort}
                reverse={searchResponseBody.reverse}
                label={t('findAnEvent.headers.location')}
              />
            </BorderedRowItem>
            {/*<BorderedRowItem className="Level">Level</BorderedRowItem>*/}
            <BorderedRowItem className="Action">{''}</BorderedRowItem>
          </BorderedRow>
          {!results.length && (
            <NoResults
              usingFilters={props.usingFilters}
              resetFilters={props.resetFilters}
              viewVirtualOnly={props.viewVirtualOnly}
            />
          )}
          {results.map((result) => {
            const isForbidden = forbiddenEventUrlFragments.includes(
              result.eventUrlFragment,
            );
            return (
              <BorderedRow
                className={`Result ${isForbidden && 'Result_Forbidden'}`}
                key={result.eventUrlFragment + (result.occurrenceId ?? '')}
                showTopBorder={false}
                onClick={() =>
                  handleEventClick(result.eventUrlFragment, result.ext.url)
                }
              >
                <BorderedRowItem className="Date">
                  <div className={'Date_DayOfWeek'}>
                    {result.ext.today && (
                      <Chip
                        className={'EventSearchResults_Chip'}
                        label={t('findAnEvent.today')}
                      />
                    )}
                    {!result.ext.today && result.ext.dayOfWeek}
                  </div>
                  <div className={'Date_Date'}>{result.ext.date}</div>
                  <div className={'Date_Recurrence'}>
                    {result.eventIsRecurring
                      ? formatEventRecurrence(result.recurrence)
                      : ''}
                  </div>
                </BorderedRowItem>
                <BorderedRowItem className="PassionImageColumn">
                  <div
                    className={'PassionImageColumn_Image'}
                    style={{
                      backgroundImage: `url(${result.ext.passionImageThumbnailWideUrl})`,
                    }}
                  />
                </BorderedRowItem>
                <BorderedRowItem className="TypeAndEventName">
                  <div className="TypeAndEventName_Type">
                    {result.passion.passionName}
                  </div>
                  <div className="TypeAndEventName_EventName">
                    {result.eventName}
                  </div>
                  {result.clubIsVirtual && (
                    <Chip
                      className={'EventSearchResults_Chip VirtualClubChip'}
                      label={t('findAnEvent.virtualClub')}
                      size="small"
                    />
                  )}
                  {result.ext.isSelfGuided && <ListSelfGuidedIndicator />}
                </BorderedRowItem>
                <BorderedRowItem className="Time">
                  <span className={'Time_Time'}>{result.ext.timeStart}</span>
                  &nbsp;-&nbsp;
                  <span className={'Time_Time'}>{result.ext.timeEnd}</span>
                </BorderedRowItem>
                <BorderedRowItem className="Location">
                  {result.eventIsVirtual && (
                    <Chip
                      className={'EventSearchResults_Chip Virtual_Full'}
                      label={t('findAnEvent.virtualEvent')}
                    />
                  )}
                  {result.eventIsVirtual && (
                    <Chip
                      className={'EventSearchResults_Chip Virtual_Short'}
                      label={t('findAnEvent.virtualEventShort')}
                    />
                  )}
                  {!result.eventIsVirtual && (
                    <LocationView location={result.location} />
                  )}
                </BorderedRowItem>
                {/*<BorderedRowItem className="Level"></BorderedRowItem>*/}
                <BorderedRowItem className="Action">
                  <Button
                    className="Action_Button"
                    buttonStyle={ButtonStyles.UnfilledWithBorder}
                    onClick={() =>
                      handleEventClick(result.eventUrlFragment, result.ext.url)
                    }
                  >
                    {isForbidden ? (
                      <Tooltip title={`${t('findAnEvent.notCoveredTooltip')}`}>
                        <span>
                          <span className={'Action_Button_Full'}>
                            {t('findAnEvent.notCoveredButtonText')}
                          </span>
                          <span className={'Action_Button_Short'}>
                            {t('findAnEvent.notCoveredButtonTextShort')}
                          </span>
                        </span>
                      </Tooltip>
                    ) : eventUrlBeingVerified === result.eventUrlFragment ? (
                      t('findAnEvent.verifyingButtonText')
                    ) : (
                      <>
                        <span className={'Action_Button_Full'}>
                          {t('findAnEvent.detailsButtonText')}
                        </span>
                        <span className={'Action_Button_Short'}>
                          {t('findAnEvent.detailsButtonTextShort')}
                        </span>
                      </>
                    )}
                  </Button>
                </BorderedRowItem>
              </BorderedRow>
            );
          })}
        </>
      )}
    </div>
  );
}
