import React from "react";
import "./LocationView.scss";
import {GetLocationResponseBody} from "../../services/Models";

interface LocationViewProps {
    locationName?: string | null,
    locationAddress1?: string | null,
    locationAddress2?: string | null,
    locationCity?: string | null,
    locationState?: string | null,
    locationZip?: string,
    className?: string
    location?: GetLocationResponseBody|null;
    inlineMobile?: boolean
}

export default function LocationView(props: LocationViewProps): JSX.Element {
    function check(toCheck: string | null | undefined) {
        return !(toCheck === undefined || toCheck === null || toCheck === '');
    }

    let locationName = props.locationName;
    let locationAddress1 = props.locationAddress1;
    let locationAddress2 = props.locationAddress2;
    let locationCity = props.locationCity;
    let locationState = props.locationState;
    let locationZip = props.locationZip;

    if (props.location !== undefined && props.location !== null)
    {
        locationName = props.location.locationName;
        locationAddress1 = props.location.streetAddressOne;
        locationAddress2 = props.location.streetAddressTwo;
        locationCity = props.location.city;
        locationState = props.location.countrySubdivision;
        locationZip = props.location.postalCode;
    }

    return (
        <div className={`CCLocationView ${props.inlineMobile ? "CCLocationView_InlineMobile" : ""} ${props.className}`}>
            {check(locationName) && <div className={"CCLocationView_Part CCLocationView_LocationName"}>{locationName}</div>}
            {(check(locationAddress1) || check(locationAddress2)) &&
                <>
                    {props.inlineMobile && <span className={"CCLocationView_Comma"}>, </span>}
                    <div className={"CCLocationView_Part CCLocationView_Address"}>{check(locationAddress1) && locationAddress1}{check(locationAddress1) && check(locationAddress2) && " "}{check(locationAddress2) && locationAddress2}</div>
                </>}
            {check(locationCity) && <>
                    {props.inlineMobile && <span className={"CCLocationView_Comma"}>, </span>}
                    <div className={"CCLocationView_Part CCLocationView_CityStateZip"}>
                    {check(locationCity) && locationCity}
                    {check(locationCity) && ","}
                    {check(locationState) && " "}
                    {check(locationState) && locationState}
                    {check(locationState) && " "}
                    {check(locationZip) && locationZip}</div>
                </>
            }
        </div>
    );
}
