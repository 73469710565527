import React from 'react';
import { useTranslation } from 'react-i18next';

import "./SelfGuidedEventText.scss"
import SelfGuidedEventIndicator from "../common/SelfGuidedEventIndicator";

interface SelfGuidedEventTextProps {
    eventClubUrl: string | null
}

export default function SelfGuidedEventText(props: SelfGuidedEventTextProps) {
    const { t } = useTranslation('event');
    let eventClubUrl = props.eventClubUrl;

    return (<>
        <div className={"SelfGuidedEventText"}>
            <div className={"SelfGuidedEventText_Title"}>
                <SelfGuidedEventIndicator/>
                <div className={"SelfGuidedEventText_TitleText"}>
                    {t('selfGuidedEventText.eventTitleText')}
                </div>
            </div>
            <div className={"SelfGuidedEventText_Body"}>
                {t('selfGuidedEventText.eventBodyText')}&nbsp;
                {eventClubUrl !== null ?
                    <span dangerouslySetInnerHTML={{__html: t('selfGuidedEventText.eventBodyTextWithURL')}}></span> :
                    t('selfGuidedEventText.eventBodyTextNoURL')
                }
            </div>
        </div>
    </>);
}