import { useState, useEffect } from "react";
import { IActivityItem } from '../types/dashboard'
import getMembers from "../api/getMembers";

interface IUseGetMissingActivityMembersResult {
    missingActivityMembers: IActivityItem[]
    isLoading: boolean
    fetchMissingActivityMembers: Function
    totalMissingActivityMembersResultCount: number
}

const useGetMissingActivityMembers = (clubId: number): IUseGetMissingActivityMembersResult => {
    const [missingActivityMembers, setMissingActivityMembers] = useState<IActivityItem[]>([])
    const [totalMissingActivityMembersResultCount, setTotalMissingActivityMembersResultCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const fetchMissingActivityMembers = ({ skip = 0, sortBy = 'fullName', sortOrder = 'asc', searchValue = '' } = {}) => {
        return getMembers({ id: clubId, skip, sortBy, sortOrder, withMissingActivitiesOnly: true, searchValue })
            .then(data => {
                setMissingActivityMembers(data?.results)
                setTotalMissingActivityMembersResultCount(data?.totalResultCount)
            })
    }

    useEffect(() => {
        setIsLoading(true)

        fetchMissingActivityMembers().finally(() => {
            setIsLoading(false)
        })
    }, [clubId])

    return { missingActivityMembers, isLoading, fetchMissingActivityMembers, totalMissingActivityMembersResultCount }
}

export default useGetMissingActivityMembers
