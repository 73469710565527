import { useState, useEffect } from "react";

import getLatestEligibilityStatus from "../api/getLatestEligibilityStatus";
import { IEligibility } from "../api/getEligibilityStatus";

const useLatestEligibilityStatus = (shouldDoRequest = true) => {
    const [eligibility, setEligibility] = useState<IEligibility | undefined>();
    const [isLoading, setIsLoading] = useState(true);

    const getLatestEligibility = () => {
        getLatestEligibilityStatus()
            .then(data => setEligibility(data))
            .finally(() => setIsLoading(false))
    };

    useEffect(() => {
        if (shouldDoRequest) {
            getLatestEligibility();
        }
    }, [shouldDoRequest]);

    return [eligibility, isLoading, getLatestEligibility] as const;
}

export default useLatestEligibilityStatus;
