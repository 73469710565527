import { useState, useEffect, useContext } from 'react';
import getMyClubs from '../api/getMyClubs';
import { IClub } from '../types/clubFinder';
import { sortByTextField } from '../util/ignite/Dashboard.utils';
import { UserContext } from '../contexts/UserContext';

const CLUBS_SESSION_STORAGE_KEY = 'CLUBS_SESSION_STORAGE_KEY';

const setClubsToSessionStorage = (clubs: IClub[], userId: number) => {
    sessionStorage.setItem(`${CLUBS_SESSION_STORAGE_KEY}/${userId}`, JSON.stringify(clubs));
}

const getClubsFromSessionStorage = (userId?: number) => {
    if (!userId) return [];

    const clubsSerialized = sessionStorage.getItem(`${CLUBS_SESSION_STORAGE_KEY}/${userId}`);

    return clubsSerialized ? JSON.parse(clubsSerialized) : [];
}

const useGetMyClubs = (): [IClub[], boolean, Function] => {
    const { user } = useContext(UserContext);

    const [clubs, setClubs] = useState<IClub[]>(getClubsFromSessionStorage(user?.id));
    const [isLoading, setIsLoading] = useState(false);

    const fetchClubs = () => getMyClubs()
        .then(data => {
            const sortedClubs = sortByTextField(data?.allClubs || [], true, 'clubName');
            setClubs(sortedClubs as IClub[]);
            setClubsToSessionStorage(sortedClubs, Number(user?.id));
        })

    useEffect(() => {
        if (!user) return;

        setIsLoading(true);

        fetchClubs()
            .finally(() => {
                setIsLoading(false);
            })
    }, [user])

    return [clubs, isLoading, fetchClubs];
}

export default useGetMyClubs;
