import React from "react";
import InitialContent from "./InitialContent";
import Tabs from "./Tabs";
import useCheckDevice from "../../../hooks/useCheckDevice";
import { Leaderboard } from "../../../components/ignite/Dashboard/Leaderboard";
import { BODY_TABS, CONTENT_TYPE } from "../../../constants/dashboard";
import { IActivityItem, IMember } from '../../../types/dashboard'
import { useTranslation } from "react-i18next";
import './ToDoTab.scss';
import InviteContent from "./InviteContent";
import CopyEmailContent from "./CopyEmailContent";
import UploadContent from "./UploadContent";
import { IClub } from "../../../types/clubFinder";
import RemindContent from "./RemindContent";
import useQuery from "../../../hooks/useQuery";
import { useHistory } from "react-router-dom";

interface IToDoTabProps {
    contentType: CONTENT_TYPE
    onContentChange: Function
    onBodyTabChange: (event: React.ChangeEvent<{}>, value: BODY_TABS) => void
    bodyTabValue: string
    leaderboardList: IActivityItem[]
    leaderboardTotalCount: number
    members: IActivityItem[]
    invitedMembers?: IMember[]
    missingActivity?: IActivityItem[]
    club: IClub
    fetchInvitedMembers: Function
    fetchNotInvitedMembers: Function
    fetchLeaderboard: Function
}

const ToDoTab = ({
    contentType,
    onContentChange,
    onBodyTabChange,
    bodyTabValue,
    leaderboardList,
    leaderboardTotalCount,
    members,
    invitedMembers,
    missingActivity,
    club,
    fetchInvitedMembers,
    fetchNotInvitedMembers,
    fetchLeaderboard
}: IToDoTabProps) => {
    const { isDesktop } = useCheckDevice()
    const { t } = useTranslation('ignite/dashboard');
    const query = useQuery()
    const history = useHistory()
    const onBackClick = () => {
        if (query.has('content-type')) {
            query.delete('content-type')
            history.push({search: query.toString()})
        }

        onContentChange(CONTENT_TYPE.INITIAL)
    }

    return (
        <div className="invite-members">
            <div className="title">
               {t('leaderTitle')}
            </div>

            {!isDesktop && (
                <Tabs
                    value={bodyTabValue}
                    onChange={onBodyTabChange}
                    items={[
                        { label: t(`tabs.${BODY_TABS.LEADER_TO_DO_TAB}`), value: BODY_TABS.LEADER_TO_DO_TAB },
                        { label: t(`tabs.${BODY_TABS.MEMBERS_TAB}`), value: BODY_TABS.MEMBERS_TAB },
                        { label: t(`tabs.${BODY_TABS.LEADERBOARD_TAB}`), value: BODY_TABS.LEADERBOARD_TAB }
                    ]}
                />
            )}

            {bodyTabValue === BODY_TABS.LEADER_TO_DO_TAB ? (
                <div className="content">
                    {contentType === CONTENT_TYPE.INITIAL &&
                        <InitialContent
                            invitedMembers={invitedMembers}
                            onContentChange={onContentChange}
                            members={members}
                            missingActivity={missingActivity}
                        />}

                    {contentType === CONTENT_TYPE.INVITE &&
                        <InviteContent
                            onBackClick={onBackClick}
                            club={club}
                            fetchInvitedMembers={fetchInvitedMembers}
                        />}
                    {contentType === CONTENT_TYPE.COPY_EMAIL &&
                        <CopyEmailContent
                            onBackClick={onBackClick}
                            club={club}
                        />}
                    {contentType === CONTENT_TYPE.UPLOAD &&
                        <UploadContent
                            onBackClick={onBackClick}
                            fetchNotInvitedMembers={fetchNotInvitedMembers}
                        />}
                    {contentType === CONTENT_TYPE.REMIND_INVITED_MEMBERS &&
                        <RemindContent
                            onBackClick={onBackClick}
                            club={club}
                            invitedMembers={invitedMembers}
                        />}
                         {contentType === CONTENT_TYPE.REMIND_MISSING_ACTIVITY_MEMBERS &&
                        <RemindContent
                            onBackClick={onBackClick}
                            club={club}
                            missingActivity={missingActivity}
                        />}
                </div>
            ) : <Leaderboard
                    list={leaderboardList}
                    totalCount={leaderboardTotalCount}
                    fetchLeaderboard={fetchLeaderboard}
                />
            }
        </div>
    )
}

export default ToDoTab;
