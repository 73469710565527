import React, {useContext, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import "./ClubInvitation.scss";
import {ButtonStyles} from "../../components/common/Button";
import PlayButton from "../../components/common/PlayButton";
import {RouteComponentProps} from "react-router-dom";
import Loading from "../../components/common/Loading";
import clubLeaderLanding from "../../assets/club-leader-landing.jpg";
import {loadConfig} from "../../services/ConfigService";
import {authenticatedFetch, refreshUsersClubs} from "../../services/UserService";
import {UserContext} from "../../contexts/UserContext";

interface ClubInvitationPageProps {
    inviteToken: string;
}

interface AcceptInviteResponseBody {
    clubUrlFragment: string;
}

interface InviteResponseBody {
    clubUrlFragment: string;
    clubName: string;
    inviterFirstName: string;
    inviteeEmail: string;
}

export default function ClubInvitationPage({match, history}: RouteComponentProps<ClubInvitationPageProps>) {
    const { t } = useTranslation('pages');
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [theInvitation, setTheInvitation] = useState<InviteResponseBody|null>(null);
    const {refreshUser} = useContext(UserContext);

    const inviteToken = match.params.inviteToken;

    async function acceptInviteHandler() {
        const config = await loadConfig();

        const acceptInviteRequest =
            new Request(
                `${config.apiOrigin}/accept-club-invite/${encodeURIComponent(inviteToken)}`,
                {method: "POST", headers: {"Accept": "application/json"}});

        const acceptInviteResponse = await authenticatedFetch(acceptInviteRequest);

        if (acceptInviteResponse === null || !acceptInviteResponse.ok) {
            setErrorMessage(t('clubInvitationPage.errorMessage'));
            return;
        }

        const responseJson: AcceptInviteResponseBody = await acceptInviteResponse.json();
        await refreshUser();
        await refreshUsersClubs();
        history.push(`/my-club/${encodeURIComponent(responseJson.clubUrlFragment)}`);
    }

    useEffect(
        () => {
            const load = async () => {
                const config = await loadConfig();
                const request =
                    new Request(
                        `${config.apiOrigin}/get-club-invite/${encodeURIComponent(inviteToken)}`,
                        {method: "GET", headers: {"Accept": "application/json"}});

                const response = await authenticatedFetch(request);

                if (response === null || !response.ok) {
                    setErrorMessage(t('clubInvitationPage.errorMessage'));
                    setLoading(false);
                    return;
                }

                let invitation:InviteResponseBody = await response.json();

                setTheInvitation(invitation);
                setLoading(false);
            };

            setLoading(true);
            load();
        }, [inviteToken]);

    return (
        <>
            {loading && <Loading className="PageLoading" loading={loading}/>}
            {!loading && errorMessage !== null && (
                <p id={"CCClubInvitation_ErrorMessage"}>{errorMessage}</p>
            )}
            {!loading && errorMessage === null && <div>
                <div id="CCClubInvitation_Banner">
                    <div>
                        <img id="CCClubInvitation_Image_Wrapper" src={clubLeaderLanding}
                             alt={t('clubInvitationPage.altImage')}/>
                    </div>
                    <div id="text">
                        <h1 id="CCClubInvitation_Title">{t('clubInvitationPage.title')}</h1>
                        <p id="CCClubInvitation_Subtext" dangerouslySetInnerHTML={{__html: t('clubInvitationPage.subtext', {"clubLeaderFirstName": theInvitation?.inviterFirstName, "inviteeEmail": theInvitation?.inviteeEmail, "clubName" : theInvitation?.clubName})}}></p>
                    </div>
                </div>
                <div id={"CCClubInvitation_Response_Wrapper"}>
                    <PlayButton text={t('clubInvitationPage.acceptButton')} buttonStyle={ButtonStyles.FilledPrimary}
                                clickHandler={acceptInviteHandler}/>
                </div>
                <div id={"CCClubInvitation_Response_Wrapper"}>
                    <PlayButton text={t('clubInvitationPage.declineButton')} buttonStyle={ButtonStyles.FilledPrimary}
                                to={`/`}/>
                </div>
            </div>}
        </>
    );
}
